import { useCallback, useContext, useMemo } from 'react';
import {
  AptlyUnitTemplateCategorySectionPackage,
  AptlyUnitTemplateCategorySectionProduct,
} from '@aptly-as/types';
import Dialog from '@mui/material/Dialog';
import { DeleteIconButton, OpenInNewIconButton } from '../../components/actions/icons/Icons';
import i18n from '../../libraries/i18n';
import { byOrderIds } from '../../utils/array';
import { useUnitTemplateSortable } from './utils/unit-template.sortable';
import { IPackageRowProps, PackageRow } from './product/ProductRow';
import PackageContent, { IPackageContentProps } from './package/PackageContent';
import ErrorBoundary from '../../components/ErrorBoundary';
import { IUseSimpleDeleteFn } from '../../components/simple/useSimpleDelete';
import { UnitTemplateContext } from './utils/UnitTemplateContext';
import { DownloadOnClickIcon } from '../../components/actions/icons/ExportIcon';

export interface IUnitTemplatePackageProps
  extends Omit<IPackageContentProps, 'onClose' | 'onPackageEdit'>,
    Pick<IPackageRowProps, 'checkbox'> {
  rootPath: string;
  products: AptlyUnitTemplateCategorySectionProduct[];
  disabled?: boolean;
  onPackageDelete: IUseSimpleDeleteFn<AptlyUnitTemplateCategorySectionPackage>;
  onPackageEdit: IUseSimpleDeleteFn<AptlyUnitTemplateCategorySectionPackage>;
  onPatch: (
    pack: AptlyUnitTemplateCategorySectionPackage,
    index: number,
    data: Partial<AptlyUnitTemplateCategorySectionPackage>
  ) => void;
}

export default function UnitTemplatePackage(props: IUnitTemplatePackageProps) {
  const endpoint = `${props.rootPath}/packages/${props.package._id}`;
  const { activePackage, setActivePackage } = useContext(UnitTemplateContext);
  const { index, disabled, onPackageDelete, onPackageEdit, checkbox, onPatch, ...rest } = props;
  const [{ style, ref }, , { attributes, listeners }] = useUnitTemplateSortable(props, true, {
    disabled,
  });
  const sortedProducts = useMemo(() => {
    const ps = props.products.filter((x) => x.partOfPackage === props.package._id);
    return props.package._product_order ? ps.sort(byOrderIds(props.package._product_order)) : ps;
  }, [props.package, props.products]);

  const handleOnPackageDelete = useCallback(() => {
    const i = props.section.packages.findIndex((x) => x._id === props.package._id);
    onPackageDelete(props.package, i);
  }, [onPackageDelete, props.package, props.section.packages]);

  const handleOnPackageEdit = useCallback(
    (pack: Partial<AptlyUnitTemplateCategorySectionPackage>) => {
      const i = props.section.packages.findIndex((x) => x._id === pack._id);
      onPackageEdit({ ...props.section.packages[i], ...pack }, i);
    },
    [onPackageEdit, props.section.packages]
  );

  const handleOnEdit = useCallback(() => {
    setActivePackage(props.package._id);
  }, [setActivePackage, props.package._id]);

  const handleOnClose = useCallback(() => {
    setActivePackage(null);
  }, [setActivePackage]);
  const handleOnStandardToggle = useCallback(() => {
    onPatch(props.package, index, { standard: !props.package.standard });
  }, [props.package, index, onPatch]);

  return (
    <ErrorBoundary>
      <PackageRow
        attributes={attributes}
        listeners={listeners}
        style={style}
        ref={ref}
        pack={props.package}
        disabled={disabled}
        products={sortedProducts}
        standard={props.section.standard}
        checkbox={checkbox}
        onStandardToggle={handleOnStandardToggle}
      >
        <OpenInNewIconButton onClick={handleOnEdit} size="medium" title={i18n.t('actions.open')} />
        <DownloadOnClickIcon endpoint={endpoint} size="medium" />
        <DeleteIconButton onClick={handleOnPackageDelete} size="medium" />
        <Dialog open={activePackage === props.package._id} fullScreen onClose={handleOnClose}>
          <ErrorBoundary>
            <PackageContent
              onClose={handleOnClose}
              index={index}
              onPackageEdit={handleOnPackageEdit}
              {...rest}
            />
          </ErrorBoundary>
        </Dialog>
      </PackageRow>
    </ErrorBoundary>
  );
}
