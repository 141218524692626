import { useContext } from 'react';
import { AptlyDepartment, AptlyFieldType } from '@aptly-as/types';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import { schemaName } from '../../components/crud/schema/fields.schema';
import { getId } from '../../libraries/mongoose';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { SimpleContext } from '../../components/simple/SimpleContext';

export type ICrudDepartmentProject = AptlyDepartment['projects'][0];
export type ICrudDepartmentUser = AptlyDepartment['users'][0];

export const departmentSchema = (): ICrudSchema<AptlyDepartment> => ({
  name: schemaName({
    autoFocus: true,
  }),
});

export const departmentProjectSchema = (): ICrudSchema<ICrudDepartmentProject> => ({
  project: {
    type: AptlyFieldType.Reference,
    defaultValue: null as any,
    label: i18n.t('singles.projects'),
    useReference: () => {
      const { meta } = useContext(SimpleContext);
      const items = meta.data as ICrudDepartmentProject[];
      const _ids = items?.map((x) => getId(x.project)).join(',') || '';
      return {
        endpoint: useApiUrl(SlugLevel.Organization, 'projects'),
        labelKey: 'name',
        queryKey: '$name',
        query: { as: 'array', select: '_id,name', limit: 5, archived: false, sort: 'name', $nin__id: _ids },
      };
    },
  },
});

export const departmentUserSchema = (): ICrudSchema<ICrudDepartmentUser> => ({
  user: {
    type: AptlyFieldType.Reference,
    defaultValue: '',
    label: i18n.t('singles.user'),
    useReference: () => {
      const { meta } = useContext(SimpleContext);
      const items = meta.data as ICrudDepartmentUser[];
      const _ids = items?.map((x) => getId(x.user)).join(',') || '';
      return {
        endpoint: useApiUrl(SlugLevel.Organization, 'users'),
        labelKey: 'fullName',
        queryKey: '$fullName',
        query: { as: 'array', select: '_id fullName', limit: 5, sort: 'fullName', $nin__id: _ids },
      };
    },
  },
});
