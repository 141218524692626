import { IUseSearchRet, useSearch } from '@ewb/reach-react';
import { IUseSearchProps } from '@ewb/reach-react/core/useSearch';
import { AptlyErrorBody, AptlySearchPaginateResponse } from '@aptly-as/types';
import { useMemo } from 'react';

export type IUseSimpleSearchProps<T> = Omit<IUseSearchProps<T, AptlyErrorBody, T[]>, 'responseToData'>;

export const responseToData = <T = any>(res: AptlySearchPaginateResponse<T>) => ({
  items: res.data,
  count: res.count,
  limit: res.limit,
  skip: res.skip,
});

export function useSimpleSearch<T>(
  path: string,
  props?: IUseSimpleSearchProps<T>
): IUseSearchRet<T, AptlyErrorBody, AptlySearchPaginateResponse<T>> {
  const useSearchProps = useMemo(
    () => ({
      ...props,
      responseToData,
    }),
    [props]
  );
  return useSearch<T, AptlyErrorBody, AptlySearchPaginateResponse<T>>(path, useSearchProps);
}
