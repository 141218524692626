import { AptlyScopes, AptlyUnit, AptlyUser } from '@aptly-as/types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import DeleteIconEndpoint from '../../../components/actions/icons/DeleteIconEndpoint';
import { AddIconButton, SendIconButton } from '../../../components/actions/icons/Icons';
import Section from '../../../components/Section';
import CrudUnitEdit from '../../../components/simpleCruds/unit/CrudUnitEdit';
import CrudUnitInvite from '../../../components/simpleCruds/unit/CrudUnitInvite';
import { createModal } from '../../../containers/Modal/ModalContext';
import useGetApiUrl, { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import { intlDateTimeFormat } from '../../../libraries/intl';
import { FragmentScope } from '../../../libraries/scope/ScopeComponets';
import Table, { TableBody, TableCell, TableFooter, TableHead, TableRow } from '../../../mui/Table';

interface Props {
  unit: AptlyUnit;
  refresh: () => void;
}

export default function UnitDashboardUsers({ unit, refresh }: Props) {
  const usersEndpoint = useApiUrl(SlugLevel.Organization, 'users');
  const unitsEndpoint = useGetApiUrl('project') + '/units';
  const unitEndpoint = unitsEndpoint + `/${unit._id}`;
  const users = (unit.users as AptlyUser[]) || [];
  const invites: Partial<AptlyUnit['invites'][0]>[] = useMemo(
    () => [
      ...unit.invites,
      ...unit.queueInvites.map((email) => ({
        _id: 'queue',
        claim: { claimed: false, email },
      })),
      ...unit.invitesQueue.map((queue) => ({
        _id: 'queue',
        claim: { claimed: false, ...queue },
      })),
    ],
    [unit]
  );

  return (
    <Section>
      <Typography variant="subtitle1">{i18n.t('singles.users')}</Typography>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell flex={1}>{i18n.t('singles.name')}</TableCell>
              <TableCell flex={1}>{i18n.t('singles.email')}</TableCell>
              <TableCell flex={1}>{i18n.t('singles.phone')}</TableCell>
              <TableCell width={175}>{i18n.t('paragraphs.loggedIn')}</TableCell>
              <TableCell width={150}>{i18n.t('singles.registered')}</TableCell>
              <FragmentScope scope={AptlyScopes.ProjectUnits} crud="U">
                <TableCell align="right" width={150}>
                  {i18n.t('singles.actions')}
                </TableCell>
              </FragmentScope>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.fullName}</TableCell>
                <TableCell>
                  <a href={`mailto:${user.email}`} target="_blank" rel="noopener noreferrer">
                    {user.email}
                  </a>
                </TableCell>
                <TableCell>
                  {user.phone && (
                    <a href={`tel:${user.phone.replace(' ', '')}`} target="_blank" rel="noopener noreferrer">
                      {user.phone}
                    </a>
                  )}
                </TableCell>
                <TableCell>{intlDateTimeFormat(user.synced)}</TableCell>
                <TableCell>{intlDateTimeFormat(user.created)}</TableCell>
                <TableCell align="right">
                  <FragmentScope scope={AptlyScopes.ProjectUnits} crud="U">
                    <DeleteIconEndpoint
                      endpoint={`${unitEndpoint}/users`}
                      _id={user._id as string}
                      onClick={refresh}
                    />
                  </FragmentScope>
                </TableCell>
              </TableRow>
            ))}
            {invites
              .filter((x) => !x.claim!.claimed)
              .map((invite, index) => {
                const count = invite.sendCount || 1;
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {invite.claim!.fullName}&nbsp;(
                      {invite._id === 'queue' ? (
                        <i>{i18n.t('statuses.inviteNotSent')}</i>
                      ) : (
                        <i>
                          <Trans i18nKey="statuses.invitationSent" count={count}>
                            Invitation sent {{ count }} time
                          </Trans>
                          ...&nbsp;
                          {invite.newOwner ? `(${i18n.t('statuses.newOwner')})` : ''}
                          {!invite.created ? `(${i18n.t('statuses.pending')})` : ''}
                        </i>
                      )}
                      )
                    </TableCell>
                    <TableCell>{invite.claim!.email}</TableCell>
                    <TableCell>{invite.claim!.phone}</TableCell>
                    <TableCell>{i18n.t('statuses.notLoggedInn')}</TableCell>
                    <TableCell>{invite.created ? intlDateTimeFormat(invite.created) : '-'}</TableCell>
                    <TableCell align="right">
                      {invite._id === 'queue' ? (
                        <DeleteIconEndpoint
                          endpoint={`${unitEndpoint}/invites`}
                          _id={invite.claim!.email!}
                          onClick={refresh}
                        />
                      ) : (
                        <DeleteIconEndpoint
                          endpoint={`${unitEndpoint}/invites`}
                          _id={invite._id!}
                          onClick={refresh}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={5} />
              <TableCell align="right">
                <FragmentScope scope={AptlyScopes.ProjectUnitInvites} crud="C">
                  <AddIconButton
                    title={i18n.t('actions.addAdmin')}
                    onClick={() => {
                      createModal(
                        <CrudUnitEdit
                          unit={unit}
                          onCreate={() => refresh()}
                          fields={['users']}
                          usersEndpoint={usersEndpoint}
                        />
                      );
                    }}
                  />
                  <SendIconButton
                    title={i18n.t('actions.sendInvitation')}
                    onClick={() =>
                      createModal(<CrudUnitInvite unit={unit} endpoint={unitsEndpoint} onCreate={refresh} />)
                    }
                  />
                </FragmentScope>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </Section>
  );
}
