import dayjs from 'dayjs';
import { AptlyPeriod } from '@aptly-as/types';

function splitByTiming(periods: AptlyPeriod[]) {
  const closed = [];
  const current = [];
  const upcoming = [];
  const now = dayjs();

  for (let i = 0; i < periods.length; i++) {
    const p = periods[i];

    if (now.isAfter(p.close)) {
      closed.push(p);
    } else if (now.isBefore(p.open)) {
      upcoming.push(p);
    } else {
      current.push(p);
    }
  }

  return {
    closed,
    current,
    upcoming,
  };
}

function firstAndFinal(periods: AptlyPeriod[]) {
  if (periods.length === 0)
    return {
      first: null,
      final: null,
      percent: 0,
    };

  const today = dayjs();
  let first = dayjs('2100-01-01');
  let final = dayjs('1980-01-01');

  for (let i = 0; i < periods.length; i++) {
    const currentOpen = dayjs(periods[i].open);
    const currentClose = dayjs(periods[i].close);

    if (currentOpen.isBefore(first)) {
      first = currentOpen;
    }

    if (currentClose.isAfter(final)) {
      final = currentClose;
    }
  }

  if (first.isAfter(today)) {
    return { first, final, percent: 0 };
  }
  const elapsed = today.diff(first);
  const percent = first && final ? (elapsed / final.diff(first)) * 100 : 0;
  return {
    first,
    final,
    percent: percent > 100 ? 100 : percent,
  };
}

export { splitByTiming, firstAndFinal };
