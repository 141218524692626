import { AptlyScopes, AptlyUnitTemplate } from '@aptly-as/types';
import { useContext, useMemo } from 'react';
import { GoToButtonLinkWithIcon, UnlinkButtonWithIcon } from '../../components/actions/buttons/Buttons';
import Section from '../../components/Section';
import { useConfirmModal } from '../../containers/Modal/ConfirmModal.js';
import useGetApiUrl, { SlugLevel, useApiUrl, useSlugs, useTo } from '../../hooks/useGetApiUrl';
import simpleRequest from '../../libraries/fetch/simpleRequest.js';
import i18n from '../../libraries/i18n';
import { getId } from '../../libraries/mongoose';
import Scope from '../../libraries/scope/Scope';
import Grid from '../../mui/Grid';
import Typography from '../../mui/Typography';
import { useProject } from '../Project/ProjectContext';
import UnitTemplate from '../UnitTemplate/UnitTemplate.js';
import Categories from './Tree/Categories';
import { UnitContext } from './UnitContext';

export function UnitOptionList() {
  const projectData = useProject();
  const { data: unit, actions, setData } = useContext(UnitContext);
  const to = useTo(SlugLevel.Project, `/tilvalg/${getId(unit.unitTemplate)}`);
  const v2Endpoint = useApiUrl(SlugLevel.Unit);
  const baseEndpoint = useGetApiUrl('project', '/units');
  const [organizationSlug, projectID, unitID] = useSlugs();
  const confirmEject = useConfirmModal(
    useMemo(
      () => ({
        actionLabel: i18n.t('actions.eject'),
        children: <Typography>{i18n.t('paragraphs.ejectUnit')}</Typography>,
        onConfirm: () =>
          simpleRequest<AptlyUnitTemplate>({
            method: 'POST',
            endpoint: `${v2Endpoint}/eject`,
            onRequestDone: (data) => {
              setData({ unitTemplate: data._id, unitTemplateEjected: true });
            },
          }),
      }),
      [v2Endpoint]
    )
  );

  if (unit.unitTemplateEjected) {
    return <UnitTemplate id={getId(unit.unitTemplate)} />;
  }

  return (
    <>
      <Section>
        <Grid container justifyContent="space-between">
          <Typography variant="h1">{i18n.t('singles.optionList')}</Typography>
          <Grid container justifyContent="flex-end" gap={2}>
            <UnlinkButtonWithIcon onClick={confirmEject} />
            <GoToButtonLinkWithIcon to={to}>{i18n.t('actions.edit')}</GoToButtonLinkWithIcon>
          </Grid>
        </Grid>
      </Section>
      <Categories
        organizationSlug={organizationSlug}
        projectID={projectID}
        endpoint={`${baseEndpoint}/${unitID}/categories`}
        algorithms={projectData.algorithms}
        selectedOptions={unit.selectedOptions}
        unitEndpoint={`${baseEndpoint}/${unit._id}`}
        categoryOverrides={unit.categoryOverrides}
        productOverrides={unit.productOverrides}
        onSave={actions.read}
        rooms={unit.rooms || []}
        canUpdate={Scope.crud(AptlyScopes.ProjectUnits, 'U')}
      />
    </>
  );
}
