import Button from '@mui/material/Button';
import styled from 'styled-components';

import useChangelogfy from './useChangelogfy';
import i18n from '../i18n';

export default function Changelogfy() {
  const { open, active } = useChangelogfy();

  return (
    <Container className="changelogfy-widget">
      <Button onClick={open} disabled={!active}>
        {i18n.t('singles.news')}
      </Button>
    </Container>
  );
}

const Container = styled.div`
  .changelogfy-badge-container {
    position: absolute;
    top: 2px;
    right: 6px;
  }
`;
