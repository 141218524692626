import { useGet, IReachOptions } from '@ewb/reach-react';
import { useMemo } from 'react';
import { AptlyApp } from '@aptly-as/types';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';

export default function useApp(id: string) {
  const url = useApiUrl(SlugLevel.Base, 'apps');
  const props = useMemo<IReachOptions>(
    () => ({
      query: { select: '_id,name,slug,settings,menus' },
    }),
    []
  );
  return useGet<AptlyApp>(`${url}/${id}`, undefined, props);
}
