import FolderZipOutlined from '@mui/icons-material/FolderZipOutlined';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { IJobItem, IJobItemProp } from '../../containers/Job/job.utils';
import { JobsContext } from '../../containers/Job/Jobs.context';
import ConfirmModal from '../../containers/Modal/ConfirmModal';
import { ModalButton } from '../../containers/Modal/ModalButton';
import simpleRequest from '../../libraries/fetch/simpleRequest';
import i18n from '../../libraries/i18n';
import { Select } from '../../mui/Input';
import Typography from '../../mui/Typography';
import { OrganizationContext } from '../Organization/OrganizationContext';
import { sortedDocumentTypeOptions } from './document.utils';

export interface IZipDocumentsProps extends Omit<IJobItemProp, 'organization'> {
  endpoint: string;
  onClose?: () => void;
}

interface JobQuery {
  _type: string[];
}

export function ZipDocuments(props: IZipDocumentsProps) {
  return (
    <ModalButton label={props.label} variant="outlined" endIcon={<FolderZipOutlined />}>
      <Content {...props} />
    </ModalButton>
  );
}

function Content({ endpoint, onClose, ...rest }: IZipDocumentsProps) {
  const { spawnJob } = useContext(JobsContext);
  const org = useContext(OrganizationContext);
  const [query, setQuery] = useState<JobQuery>({ _type: [] });
  const options = useMemo(sortedDocumentTypeOptions, []);

  const handleOnJobSpawn = useCallback(() => {
    simpleRequest<IJobItem>({
      endpoint: endpoint,
      query: {
        _type: query._type.join(','),
      },
      onRequestDone: (job) => {
        spawnJob(job, {
          organization: org.data._id,
          ...rest,
        });
        if (typeof onClose === 'function') {
          onClose();
        }
      },
    });
  }, [endpoint, spawnJob, rest, org, query, onClose]);

  return (
    <ConfirmModal
      title={rest.label}
      onConfirm={handleOnJobSpawn}
      actionLabel={i18n.t('actions.zip')}
      actionIcon={<FolderZipOutlined />}
    >
      <Typography gutterBottom>
        <Trans i18nKey="paragraphs.zipDocuments">
          Choose type of document to zip. If none is selected, all documents will be included.
        </Trans>
      </Typography>
      <Select
        name="_type"
        label={i18n.t('singles.type')}
        value={query._type}
        fullWidth
        options={options}
        onChange={(e) => setQuery((s) => ({ ...s, _type: e.target.value }))}
        multiple
      />
    </ConfirmModal>
  );
}
