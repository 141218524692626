import {
  AptlyFieldType,
  AptlyQueueDownload,
  AptlyQueueDownloadStatus,
  AptlyQueueDownloadType,
} from '@aptly-as/types';
import { ICrudSchema } from '../utils/crud.utils';
import i18n from '../../../libraries/i18n';

export type ICrudAptlyQueueDownload = AptlyQueueDownload;

export const queueDownloadStatusOptions = () => [
  { value: AptlyQueueDownloadStatus.Queued, label: 'I kø' },
  { value: AptlyQueueDownloadStatus.Downloaded, label: 'Lasted ned' },
  { value: AptlyQueueDownloadStatus.Error, label: 'Error' },
];

export const queueDownloadTypeOptions = () => [
  { value: '', label: i18n.t('singles.all') },
  { value: AptlyQueueDownloadType.Document, label: i18n.t('singles.document') },
  { value: AptlyQueueDownloadType.Image, label: i18n.t('singles.image') },
];

export const queueDownloadSchema = (): ICrudSchema<AptlyQueueDownload> => ({
  url: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('singles.url'),
  },
  status: {
    type: AptlyFieldType.Select,
    defaultValue: AptlyQueueDownloadStatus.Queued,
    label: i18n.t('singles.status'),
    options: queueDownloadStatusOptions(),
  },
});
