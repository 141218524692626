import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { FormEvent } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { createModal } from '../containers/Modal/ModalContext';
import apiRequest from '../libraries/fetch/apiRequest';
import handleError from '../containers/Error/handleError';
import { acceptImages } from '../libraries/react-dropzone/drop-zone.utils';
import createMediaUrl from '../models/Media/createMediaURL';
import { cssSpacing, cssTightSpacing, rawSpacing, tightSpacing } from '../utils/spacing';
import Progresser from '../mui/Progresser';
import StatusText from '../components/StatusText';
import Button, { IconButton } from '../mui/Button';
import { Checkbox, TextField } from '../mui/Input';
import Grid from '../mui/Grid';
import Typography from '../mui/Typography';
import { ModalActions, ModalContent, ModalForm, ModalTitle } from '../mui/Dialog';
import i18n from '../libraries/i18n';
import { FileDropzone } from '../libraries/react-dropzone/DropZone';

const Wrapper = styled.div<{ columns: number }>`
  columns: ${(props) => props.columns};
  column-gap: ${cssSpacing};
  width: 100%;

  > * {
    break-inside: avoid;
  }
`;

const EditableImage = styled.div`
  margin-bottom: calc(${cssSpacing} * 2);
`;

const Figure = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
`;

const EditControls = styled.div<{ disableBackground?: boolean }>`
  position: absolute;
  width: 48px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  right: 4px;
  top: 4px;

  ${(props) =>
    props.disableBackground &&
    css`
      background-color: transparent;
      border-radius: 0;
    `};
`;

const Controls = styled.div`
  margin-top: calc(${cssTightSpacing} / 2);
`;

type ApiResponse = {
  _id: string;
  image: string;
};

type ImageType = {
  id: string;
  file?: File;
  url?: string;
  description?: string;
  featured?: boolean;
  uploading?: boolean;
};

type Props = {
  endpoint: string;
  images?: ImageType[];
  singular?: boolean;
  columns?: number;
  disableEditing?: boolean;
  featurable?: boolean;
  onSave?: () => any;
};

type State = {
  internalImages: ImageType[];
  batchNumber: number;
  doneUpload: number;
};

class ImagesController extends React.Component<Props, State> {
  state = {
    internalImages: this.props.images || [],
    batchNumber: 0,
    doneUpload: 0,
  };

  objectPreviews: string[] = [];

  queueForUpload = async (files: File[]) => {
    const { onSave } = this.props;
    const { internalImages } = this.state;

    try {
      const images: any = [];
      const requests: any = [];

      for (let i = 0; i < files.length; i++) {
        const id = uuid();
        const file = files[i];

        images.push({
          id,
          file,
          alt: `${i18n.t('statuses.uploading')}...`,
          uploading: true,
        });
      }

      this.setState(
        {
          doneUpload: 0,
          batchNumber: images.length,
          internalImages: [...internalImages, ...images],
        },
        async () => {
          for (let i = 0; i < images.length; i++) {
            const image = images[i];
            requests.push(this.upload(image.id, image.file));
          }

          await Promise.all(requests);

          this.setState({
            batchNumber: 0,
            doneUpload: 0,
          });

          if (typeof onSave === 'function') {
            onSave();
          }
        }
      );
    } catch (error) {
      handleError(error);
    }
  };

  upload = async (id: string, image: File) => {
    const { endpoint } = this.props;
    const { internalImages, doneUpload } = this.state;

    try {
      const response: ApiResponse = await apiRequest(endpoint, {
        method: 'POST',
        multipart: true,
        data: {
          image,
        },
      });

      this.setState({
        doneUpload: doneUpload - 1,
        internalImages: internalImages.map((i) => {
          if (i.id === id) {
            i.description = '';
            i.id = response._id;
            i.url = response.image;
            i.uploading = false;
          }

          return i;
        }),
      });
    } catch (e) {
      handleError(e);
      this.setState({
        doneUpload: doneUpload - 1,
        internalImages: internalImages.filter((x) => x.id !== id),
      });
    }
  };

  put = async (image: ImageType) => {
    const { endpoint, onSave } = this.props;

    this.updateImage(image.id, {
      ...image,
      description: `${i18n.t('statuses.saving')}...`,
      uploading: true,
    });

    await apiRequest(`${endpoint}/${image.id}`, {
      method: 'PUT',
      data: image,
    });

    this.updateImage(image.id, {
      ...image,
      uploading: false,
    });

    if (typeof onSave === 'function') {
      onSave();
    }
  };

  delete = async (id: string) => {
    const { endpoint, onSave } = this.props;
    const { internalImages } = this.state;

    this.updateImage(id, {
      description: i18n.t('actions.deleting'),
      uploading: true,
    });

    await apiRequest(`${endpoint}/${id}`, {
      method: 'DELETE',
    });

    this.setState({
      internalImages: internalImages.filter((i) => i.id !== id),
    });

    if (typeof onSave === 'function') {
      onSave();
    }
  };

  updateImage = (id: string, data: {}) => {
    const { internalImages } = this.state;

    this.setState({
      internalImages: internalImages.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            ...data,
          };
        }

        return i;
      }),
    });
  };

  componentWillUnmount() {
    const { objectPreviews } = this;

    for (let i = 0; i < objectPreviews.length; i++) {
      URL.revokeObjectURL(objectPreviews[i]);
    }
  }

  render() {
    const { columns, disableEditing, featurable } = this.props;
    const { internalImages } = this.state;

    return (
      <Wrapper columns={columns || 2}>
        {internalImages.map((i, k) => {
          if (!i.url && !i.file) {
            return null;
          }

          let url = '';

          if (i.file) {
            url = URL.createObjectURL(i.file);
            this.objectPreviews.push(url);
          } else if (i.url) {
            url = createMediaUrl(i.url, {
              height: 200,
            });
          }

          return (
            <EditableImage key={k}>
              <Figure>
                {!i.uploading && !disableEditing && (
                  <EditControls>
                    <IconButton
                      onClick={() =>
                        createModal(
                          <EditModal
                            featurable={Boolean(featurable)}
                            image={i}
                            onSave={this.put}
                            onDelete={this.delete}
                          />
                        )
                      }
                      size="large"
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </EditControls>
                )}
                {i.uploading && (
                  <EditControls disableBackground>
                    <Progresser />
                  </EditControls>
                )}
                <Image src={url} alt={i.description || ''} />
              </Figure>
              <Controls>
                <Grid container spacing={tightSpacing} direction="column" alignItems="center">
                  <Grid item>
                    {i.description ? (
                      <Typography>{i.description}</Typography>
                    ) : (
                      <StatusText>{i18n.t('paragraphs.noDescription')}</StatusText>
                    )}
                  </Grid>
                  {i.featured && <StatusText>{i18n.t('paragraphs.selectedImage')}</StatusText>}
                </Grid>
              </Controls>
            </EditableImage>
          );
        })}
        {!disableEditing && (
          <FileDropzone
            accept={acceptImages}
            onDrop={this.queueForUpload}
            multiple
            label={i18n.t('actions.dragImageOrClickToUpload')}
          />
        )}
      </Wrapper>
    );
  }
}

type EditModalProps = {
  image: ImageType;
  featurable: boolean;
  onSave: (image: ImageType) => any;
  onDelete: (id: string) => any;
  onClose?: () => any;
};

type EditModalState = {
  image: ImageType;
};

class EditModal extends React.Component<EditModalProps, EditModalState> {
  state = {
    image: this.props.image,
  };

  render() {
    const { onClose, onSave, onDelete, featurable } = this.props;
    const { image } = this.state;

    return (
      <ModalForm
        action="#"
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          onSave(image);

          if (typeof onClose === 'function') {
            onClose();
          }
        }}
      >
        <ModalTitle>{i18n.t('actions.editImage')}</ModalTitle>
        <ModalContent>
          <Grid container spacing={rawSpacing} direction="column">
            <Grid item>
              <TextField
                label={i18n.t('singles.description')}
                value={image.description}
                fullWidth
                onChange={(e) =>
                  this.setState({
                    image: {
                      ...image,
                      description: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            {featurable && (
              <Grid item>
                <Checkbox
                  label={i18n.t('singles.mainImage')}
                  color="primary"
                  checked={image.featured}
                  onChange={(_e: any, checked: boolean) =>
                    this.setState({
                      image: {
                        ...image,
                        featured: checked,
                      },
                    })
                  }
                />
              </Grid>
            )}
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button color="primary" onClick={onClose}>
            {i18n.t('actions.cancel')}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (window.confirm(i18n.t('paragraphs.areYouSureToDelete'))) {
                onDelete(image.id);

                if (typeof onClose === 'function') {
                  onClose();
                }
              }
            }}
          >
            {i18n.t('actions.delete')}
          </Button>
          <Button type="submit" color="primary">
            {i18n.t('actions.save')}
          </Button>
        </ModalActions>
      </ModalForm>
    );
  }
}

export default ImagesController;
