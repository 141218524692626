export interface ApiError {
  error: string;
  title: string;
  status: number;
  data?: any;
  details?: string;
}

function createError(error: string, status: number = 500, data?: any, details?: string): ApiError {
  return {
    error,
    title: error,
    status,
    data,
    details,
  };
}

export default createError;
