import { Route, Routes } from 'react-router-dom';
import Section from '../../components/Section';
import BookingCalendarSelect from './BookingCalendarSelect';
import { AvailabilityProvider } from './BookingContext';
import Calendar from './Calendar';

export function Booking() {
  return (
    <AvailabilityProvider>
      <Section>
        <Routes>
          <Route path="" element={<BookingCalendarSelect />} />
          <Route path=":calendarID" element={<Calendar />} />
        </Routes>
      </Section>
    </AvailabilityProvider>
  );
}
