import Delete from '@mui/icons-material/Delete';
import { useCallback, useMemo, useState } from 'react';
import { AptlyDocument } from '@aptly-as/types';
import List, { ListItem, ListItemText } from '../../components/List/List';
import { acceptPdf } from '../../libraries/react-dropzone/drop-zone.utils';
import { IconButton } from '../../mui/Button';
import DropDocuments from './DropDocuments';

interface Props {
  endpoint: string;
  onDrop: (docs: AptlyDocument[]) => void;
  multiple?: boolean;
  path?: string;
}

export default function DocumentsUpload({ endpoint, path, onDrop, multiple }: Props) {
  const [documents, setDocuments] = useState<AptlyDocument[]>([]);

  const handleOnDropDocument = useCallback(
    (documents: AptlyDocument[]) => {
      if (multiple) {
        setDocuments(documents as AptlyDocument[]);
        onDrop(documents as AptlyDocument[]);
        return;
      }
      const document = documents[0] as AptlyDocument;
      if (document) {
        setDocuments([document]);
        onDrop([document]);
      }
    },
    [multiple, onDrop]
  );

  const handleOnDelete = useCallback(
    (i: number) => () => {
      documents.splice(i, 1);
      setDocuments([...documents]);
      onDrop([...documents]);
    },
    [documents, onDrop]
  );

  const list = useMemo(
    () => (
      <List dense>
        {documents.map((file: AptlyDocument, i: number) => (
          <ListItem
            divider
            key={`${file.name}-${i}`}
            secondaryAction={
              <IconButton edge="end" onClick={handleOnDelete(i)}>
                <Delete />
              </IconButton>
            }
          >
            <ListItemText
              primary={file.name}
              secondary={`${Number(file.size / 1000000).toFixed(2)}MB - ${file.mime}`}
            />
          </ListItem>
        ))}
      </List>
    ),
    [documents, handleOnDelete]
  );

  if (!multiple && documents.length > 0) {
    return list;
  }

  return (
    <>
      <DropDocuments
        endpoint={endpoint}
        onDrop={handleOnDropDocument}
        simple
        required
        accept={acceptPdf}
        multiple={multiple}
        path={path}
      />
      {list}
    </>
  );
}
