import {
  AptlyAlgorithm,
  AptlyProducer,
  AptlyProduct,
  AptlyScopes,
  AptlyUnitTemplateCategorySectionAssortment,
  AptlyUnitTemplateCategorySectionPackage,
  AptlyUnitTemplateCategorySectionProduct,
} from '@aptly-as/types';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableRowProps } from '@mui/material/TableRow/TableRow';
import Link from '@mui/material/Link';
import Decimal from 'decimal.js';
import { FocusEvent, forwardRef, MouseEvent, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import StatusText from '../../../components/StatusText';
import i18n from '../../../libraries/i18n';
import { FragmentScope } from '../../../libraries/scope/ScopeComponets';
import { IconButton } from '../../../mui/Button';
import { Checkbox, ICheckboxProps, TextField } from '../../../mui/Input';
import Table from '../../../mui/Table';
import { blurOnEnter, onWheelCaptureBlur } from '../../../utils/input';
import { cozySpacing } from '../../../utils/spacing';
import { getAlgorithmSteps } from '../../Algorithm/algorithm.utils';
import { useAlgorithm } from '../../Algorithm/useAlgorithms';
import createMediaURL from '../../Media/createMediaURL';
import extractFeaturedImage from '../../Product/extractFeaturedImage';
import { getProductsName } from '../../Product/product.utils';
import { sizeParamLabel } from '../sizeParams';
import { ISortableItemProps } from '../utils/unit-template.utils';
import Avatar from '../../../components/Avatar';

export interface IProductRowProps
  extends TableRowProps,
    Pick<ISortableItemProps, 'listeners' | 'attributes'> {
  product: AptlyUnitTemplateCategorySectionProduct;
  assortment?: AptlyUnitTemplateCategorySectionAssortment;
  standard?: boolean;
  checkbox?: ICheckboxProps;
  isPackage?: boolean;
  onStandardToggle?: () => void;
  onEditProduct?: (e: MouseEvent<HTMLAnchorElement>) => void;
  onEditAmount?: (e: FocusEvent<HTMLInputElement>) => void;
  onEditUnitCost?: (unitCost: number) => void;
}

export interface IPackageRowProps
  extends TableRowProps,
    Pick<ISortableItemProps, 'listeners' | 'attributes'> {
  pack: AptlyUnitTemplateCategorySectionPackage;
  disabled?: boolean;
  standard?: boolean;
  products: AptlyUnitTemplateCategorySectionProduct[];
  checkbox?: boolean;
  onStandardToggle?: () => void;
}

const Empty = () => <StatusText>{i18n.t('statuses.nothing')}</StatusText>;
const isStandard = (standard: boolean) => (standard ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlank />);

export const ProductTable = styled(Table).attrs(() => ({ size: 'small' }))`
  table-layout: fixed;
`;

export const ProductHead = ({ checkbox, hideStandard }: { checkbox?: boolean; hideStandard?: boolean }) => {
  return (
    <TableHead>
      <TableRow>
        <ProductOrderCell width={45} />
        {checkbox && <ProductOrderCell width={50} padding="none" />}
        <TableCell width={165}>{i18n.t('singles.actions')}</TableCell>
        <TableCell width={41} padding="none" />
        <TableCell width={150}>{i18n.t('singles.productNumber')}</TableCell>
        <TableCell style={{ minWidth: '50px', flex: 1 }}>{i18n.t('singles.name')}</TableCell>
        <TableCell width={150}>{i18n.t('singles.producer')}</TableCell>
        <FragmentScope scope={AptlyScopes.ProjectAlgorithms}>
          <TableCell width={150} align="right">
            {i18n.t('singles.calculation')}
          </TableCell>
        </FragmentScope>
        {!hideStandard && (
          <TableCell width={100} align="center">
            {i18n.t('singles.standard')}
          </TableCell>
        )}
        <TableCell width={110} align="right">
          {i18n.t('singles.quantity')}
        </TableCell>
        <TableCell width={110} align="right">
          {i18n.t('singles.netCost')}
        </TableCell>
        <FragmentScope scope={AptlyScopes.ProjectAlgorithms}>
          <TableCell width={110} align="right">
            {i18n.t('singles.clientPrice')}
          </TableCell>
        </FragmentScope>
      </TableRow>
    </TableHead>
  );
};

export const ProductRow = forwardRef<HTMLTableRowElement, IProductRowProps>(function ProductRow(
  {
    product,
    listeners,
    attributes,
    children,
    standard,
    checkbox,
    assortment,
    onStandardToggle,
    onEditProduct,
    onEditAmount,
    onEditUnitCost,
    ...tableRowProps
  },
  ref
) {
  const pProduct = product.product as AptlyProduct;
  const name = useMemo(() => getProductsName(product), [product]);
  const productNumbers = useMemo(
    () =>
      product.availableProducts?.map((_id, i) => {
        if (!pProduct) return null;
        if (pProduct._id === _id) return pProduct.productNumber;
        const v = pProduct.variants?.find((x) => x._id === _id);
        return v ? (
          <>
            {i > 0 ? <br /> : ''}
            {v.productNumber}
          </>
        ) : null;
      }),
    [product, pProduct]
  );
  const imageSrc = useMemo(() => {
    if (!product.product) return null;
    const featuredImage = extractFeaturedImage(product.product?.images || []);
    return featuredImage ? createMediaURL(featuredImage.src || featuredImage.image, { width: 80 }) : null;
  }, [product]);

  return (
    <ProductRowWrapper {...tableRowProps} ref={ref}>
      <ProductOrderCell background={assortment?.color}>
        <GrabButton {...listeners} {...attributes} size="small">
          <DragIndicatorIcon fontSize="small" />
        </GrabButton>
      </ProductOrderCell>
      {checkbox && (
        <ProductOrderCell padding="checkbox">
          <Checkbox {...checkbox} />
        </ProductOrderCell>
      )}
      <TableCell width={131}>{children}</TableCell>
      <TableCell padding="none">
        {imageSrc && (
          <Avatar src={imageSrc} imgProps={{ loading: 'lazy' }} alt={name} variant="rounded" contain />
        )}
      </TableCell>
      <TableCell width={125}>{productNumbers}</TableCell>
      <TableCell style={{ minWidth: '50px', flex: 1 }}>
        {(!product.availableProducts || product.availableProducts.length === 1) && onEditProduct ? (
          <Link href="#" onClick={onEditProduct} color="inherit">
            {product.product?.title ? `${product.product?.title} | ` : ''}
            {name}
          </Link>
        ) : (
          name
        )}
        {product.customTitle ? (
          <i>
            <br />
            {product.customTitle}
          </i>
        ) : null}
      </TableCell>
      <TableCell width={100}>{(product.product?.producer as AptlyProducer)?.name || <Empty />}</TableCell>
      <FragmentScope scope={AptlyScopes.ProjectAlgorithms}>
        <TableCell align="right" width={100}>
          {(product.algorithm as AptlyAlgorithm)?.name || '-'}
        </TableCell>
      </FragmentScope>
      {!product.partOfPackage && (
        <TableCell padding="checkbox" align="right" width={60}>
          {onStandardToggle ? (
            <Checkbox checked={standard || product.standard} onChange={onStandardToggle} />
          ) : (
            isStandard(standard || product.standard)
          )}
        </TableCell>
      )}
      {onEditAmount ? (
        <TableCell align="right" width={100}>
          {product.unitSizeParam ? (
            sizeParamLabel(product.unitSizeParam)
          ) : (
            <EditNumberField
              type="number"
              defaultValue={product.amount || 0}
              variant="standard"
              onKeyDown={blurOnEnter}
              onBlur={onEditAmount}
              onWheelCapture={onWheelCaptureBlur}
            />
          )}
        </TableCell>
      ) : (
        <TableCell align="right" width={100}>
          {product.unitSizeParam ? sizeParamLabel(product.unitSizeParam) : product.amount}
        </TableCell>
      )}
      <TableCell align="right" width={100}>
        <AlgorithmCellValue product={product} onEditUnitCost={onEditUnitCost} />
      </TableCell>
      <FragmentScope scope={AptlyScopes.ProjectAlgorithms}>
        <TableCell align="right" width={100}>
          <AlgorithmCellValue product={product} onEditUnitCost={onEditUnitCost} customerPrice />
        </TableCell>
      </FragmentScope>
    </ProductRowWrapper>
  );
});

interface AlgorithmProps {
  product: AptlyUnitTemplateCategorySectionProduct;
  onEditUnitCost?: (unitCost: number) => void;
  customerPrice?: boolean;
}

function AlgorithmCellValue({ product, onEditUnitCost, customerPrice }: AlgorithmProps) {
  if (product.unitCostParam) {
    if (customerPrice) return <>-</>;
    return <>{sizeParamLabel(product.unitCostParam)}</>;
  }
  const value = product.unitCost || 0;
  if (!onEditUnitCost) {
    return <>{value}</>;
  }

  return (
    <AlgorithmCellValueField
      product={product}
      onEditUnitCost={onEditUnitCost}
      customerPrice={customerPrice}
    />
  );
}

interface AlgorithmCellValueFieldProps {
  product: AptlyUnitTemplateCategorySectionProduct;
  onEditUnitCost: (unitCost: number) => void;
  customerPrice?: boolean;
}

function AlgorithmCellValueField({ product, onEditUnitCost, customerPrice }: AlgorithmCellValueFieldProps) {
  const algorithm = useAlgorithm(product.algorithm);
  const reversedAlgorithm = algorithm?.reversed || false;
  const unitCost = product.unitCost || 0;

  const { net, gross } = useMemo(() => {
    const steps = getAlgorithmSteps(algorithm!, unitCost);
    return {
      net: new Decimal(steps[0].price).toDP(2).toNumber(),
      gross: new Decimal(steps[steps.length - 1].price).toDP(2).toNumber(),
    };
  }, [algorithm, unitCost, customerPrice]);

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const unitCost = Number(e.target.value || 0);
      const steps = getAlgorithmSteps(algorithm!, unitCost, !reversedAlgorithm);
      const newNet = new Decimal(steps[0].price).toDP(2).toNumber();
      const newGross = new Decimal(steps[steps.length - 1].price).toDP(2).toNumber();
      if (!customerPrice) {
        if (net === unitCost) return;
        onEditUnitCost(reversedAlgorithm ? newNet : newGross);
      }
      if (customerPrice) {
        if (gross === unitCost) return;
        onEditUnitCost(reversedAlgorithm ? newGross : newNet);
      }
    },
    [algorithm, net, gross, onEditUnitCost, reversedAlgorithm, customerPrice]
  );

  if (!customerPrice && reversedAlgorithm) {
    return <>{net}</>;
  }

  const value = customerPrice ? Math.ceil(gross) : net;
  return (
    <EditNumberField
      type="number"
      key={value}
      defaultValue={value}
      variant="standard"
      onKeyDown={blurOnEnter}
      onBlur={handleOnBlur}
      onWheelCapture={onWheelCaptureBlur}
    />
  );
}

export const PackageRow = forwardRef<HTMLTableRowElement, IPackageRowProps>(function PackageRow(
  {
    pack,
    products,
    listeners,
    attributes,
    children,
    disabled,
    standard,
    checkbox,
    onStandardToggle,
    ...tableRowProps
  },
  ref
) {
  const imageSrc = useMemo(() => {
    if (!pack.image) return null;
    return pack.image ? createMediaURL(pack.image, { width: 80 }) : null;
  }, [pack]);
  const [cost, quantity] = useMemo(() => {
    const assortments: string[] = [];
    let _amount = 0;
    let _cost = 0;
    for (const product of products) {
      if (product.partOfAssortment) {
        if (assortments.includes(product.partOfAssortment)) continue;
        assortments.push(product.partOfAssortment);
      }
      _amount += product.amount || 0;
      _cost += (product.unitCost || 0) * (product.amount || 0);
    }
    return [_cost, _amount];
  }, [products]);

  return (
    <TableRow {...tableRowProps} ref={ref}>
      <ProductOrderCell>
        <GrabButton {...listeners} {...attributes} disabled={disabled} size="medium">
          <DragIndicatorIcon fontSize="small" />
        </GrabButton>
      </ProductOrderCell>
      {checkbox && <TableCell />}
      <TableCell width={140}>{children}</TableCell>
      <TableCell padding="none">
        {imageSrc && (
          <Avatar src={imageSrc} imgProps={{ loading: 'lazy' }} alt={pack.name} variant="rounded" contain />
        )}
      </TableCell>
      <TableCell width={125}>{products.map((x) => x.product?.productNumber).join(', ')}</TableCell>
      <TableCell style={{ minWidth: '50px', flex: 1 }}>
        {pack.name} ({i18n.t('singles.package')})
      </TableCell>
      <TableCell width={50}>-</TableCell>
      <FragmentScope scope={[AptlyScopes.OrganizationAlgorithms, AptlyScopes.ProjectAlgorithms]}>
        <TableCell width={70} align="right">
          -
        </TableCell>
      </FragmentScope>
      <TableCell padding="checkbox" align="right" width={60}>
        {onStandardToggle ? (
          <Checkbox checked={standard || pack.standard} onChange={onStandardToggle} />
        ) : (
          isStandard(standard || pack.standard)
        )}
      </TableCell>
      <TableCell width={100} align="right">
        {quantity}
      </TableCell>
      <FragmentScope scope={AptlyScopes.ProjectAlgorithms}>
        <TableCell />
      </FragmentScope>
      <TableCell width={100} align="right">
        {cost}
      </TableCell>
    </TableRow>
  );
});

export const ProductOrderCell = styled(TableCell)<{ background?: string }>`
  background: ${(props) => props.background || 'initial'};
  > button {
    position: relative;
    right: 8px;
  }
`;

const GrabButton = styled(IconButton)<{ tabIndex?: number }>`
  cursor: ${(props) => (props.tabIndex! > -1 ? 'grab' : 'grabbing')};
  margin-right: ${cozySpacing}px;
`;

const EditNumberField = styled(TextField)`
  input {
    text-align: right;
  }
`;

const ProductRowWrapper = styled(TableRow)`
  td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
