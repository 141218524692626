import { AptlyScopes } from '@aptly-as/types';
import { Category, Param, Section } from '../../../../models/UnitTemplate/unit-template.types';
import Scope from '../../../../libraries/scope/Scope';
import DraggableCrudList, { ItemsToSelect } from '../../../DraggableCrudList';
import i18n from '../../../../libraries/i18n';
import ReferenceInput from '../../../ReferenceInput';
import Select from '../../../../mui/Select';

/**
 * Whomever enters this domain. I feel sorry for you.
 * Breath inn deeply and be ready to cry in a few hours.
 * This sorely needs a rewrite.
 *
 * Adding other stuff than text, textarea or select. I feel sorry for you.
 *
 * If you need to add more logic stuff. Follow these steps:
 * 1: Add param to translations tree.
 * 2: Add logic valid key to array
 * 3: Add item to LOGIC_FIELDS with key 'logic' any value as new type
 * 4: Create component and return value as
 * setFieldValue({ key: value.key, value: _newValueHere_ })
 * Basically copy other components
 * 5: Pray that onCommit={(items) => { ... }} handles your shit
 *
 * Emil 17.10.2020 :) Last added Logic hideSection.
 */

export const paramLogicTranslations = (): ItemsToSelect => ({
  logic: {
    label: i18n.t('singles.logic'),
    fields: [
      {
        key: 'val',
        label: i18n.t('singles.value'),
        type: 'select-object-type',
        options: [
          ...(Scope.crud(AptlyScopes.OrganizationOptionLabels, 'R')
            ? [
                {
                  value: 'hideProducts',
                  label: i18n.t('actions.hideProducts'),
                },
              ]
            : []),
          {
            value: 'hideCategory',
            label: i18n.t('actions.hideCategory'),
          },
          {
            value: 'hideSection',
            label: i18n.t('actions.hideSection'),
          },
        ],
      },
    ],
  },
});

export const paramTranslations = (): ItemsToSelect => ({
  textContent: {
    label: i18n.t('singles.textContent'),
    fields: [
      {
        key: 'val',
        label: i18n.t('singles.value'),
        type: 'textarea',
      },
    ],
  },
  pickTool: {
    label: i18n.t('singles.option'),
    fields: [
      {
        key: 'val',
        label: i18n.t('singles.value'),
        type: 'select',
        options: [
          {
            value: 'comment',
            label: i18n.t('singles.comment'),
          },
          {
            value: 'amount',
            label: i18n.t('singles.quantity'),
          },
          {
            value: 'ncs',
            label: i18n.t('singles.NCSColorCode'),
          },
          ...(Scope.crud(AptlyScopes.ProjectThirdParty, 'U')
            ? [
                {
                  value: 'thirdParty',
                  label: i18n.t('singles.thirdParty') + ` ( ${i18n.t('paragraphs.documentAndCost')} )`,
                },
              ]
            : []),
          {
            value: 'document',
            label: i18n.t('actions.uploadDocument'),
          },
          {
            value: 'draw:dots',
            label: i18n.t('actions.drawPoints'),
          },
        ],
      },
    ],
  },
  ...paramLogicTranslations(),
});

const VALID_LOGIC_KEYS = [
  {
    key: 'hideProducts',
    defaultValue: [],
  },
  {
    key: 'hideCategory',
    defaultValue: { category: '', sections: [] },
  },
  {
    key: 'hideSection',
    defaultValue: '',
  },
];

const LOGIC_FIELDS = (
  organizationSlug: string,
  categories: Pick<Category, '_id' | 'name'>[],
  sections: Pick<Section, '_id' | 'name'>[],
  projectID?: string
) => [
  {
    key: 'logic',
    value: 'hideProducts',
    Field: ({ value, setFieldValue }: any) => {
      let endpoint = `organizations/${organizationSlug}`;
      if (projectID) {
        endpoint += `/projects/${projectID}`;
      }
      endpoint += '/option-labels/search';
      const keyValue = value.value;
      let typeValue = value.optionLabels?.map((x: any) => ({
        _id: x._id,
        label: x.name,
      }));
      if (!typeValue) {
        typeValue =
          keyValue.value && Array.isArray(keyValue.value)
            ? keyValue.value.map((x: any) => ({
                _id: x._id,
                label: x.name,
              }))
            : [];
      }

      return (
        <ReferenceInput<any>
          label={i18n.t('singles.labels')}
          endpoint={endpoint}
          queryKey={'name'}
          labelKey={'name'}
          query={{ limit: '5' }}
          required
          onSelect={(ref) =>
            setFieldValue({
              key: value.key,
              value: ref.map((x) => ({
                _id: x._id,
                name: x.label,
              })),
              optionLabels: ref.map((x) => x._id),
            })
          }
          defaultSelected={typeValue}
        />
      );
    },
  },
  {
    key: 'logic',
    value: 'hideSection',
    Field: ({ value, setFieldValue }: any) => {
      const keyValue = value.value;
      const typeValue = keyValue.value;
      return (
        <Select
          options={sections.map((section) => ({
            value: section._id,
            label: section.name,
          }))}
          value={typeValue}
          onChange={(e) =>
            setFieldValue({
              key: value.key,
              value: e.target.value,
              section: e.target.value,
            })
          }
          label={i18n.t('actions.hideSection')}
          fullWidth
          required
        />
      );
    },
  },
  {
    key: 'logic',
    value: 'hideCategory',
    Field: ({ value, setFieldValue }: any) => {
      const keyValue = value.value;
      const typeValue = keyValue.value;
      // todo: Add option to hide sections with multiple select box.
      // They can be accessed from selected category.sections
      return (
        <Select
          options={categories.map((category) => ({
            value: category._id,
            label: category.name,
          }))}
          value={typeValue?.category}
          onChange={(e) =>
            setFieldValue({
              key: value.key,
              value: { category: e.target.value, sections: [] },
            })
          }
          label={i18n.t('actions.hideCategory')}
          fullWidth
          required
        />
      );
    },
  },
];

interface CustomLogicComponentProps {
  organizationSlug: string;
  projectID?: string;
  categories: Pick<Category, '_id' | 'name'>[];
  sections: Pick<Section, '_id' | 'name'>[];
  items: any[];
  onChange: (items: Param[]) => void;
  itemsToSelect: ItemsToSelect;
}

export function CustomLogicComponent({
  organizationSlug,
  projectID,
  categories,
  sections,
  items,
  onChange,
  itemsToSelect,
}: CustomLogicComponentProps) {
  return (
    <DraggableCrudList
      label={i18n.t('singles.parameters')}
      addActionText={i18n.t('actions.addParameter')}
      disableDrag
      onCommit={(items) => {
        onChange(
          items.map((item) => {
            if (item.key === 'logic') {
              const oldValue = item.values.find((x) => x.key === 'val');
              const newValue = item.values.find((x) => VALID_LOGIC_KEYS.some((y) => x.key === y.key));
              let type = oldValue?.value.type;
              let value = oldValue?.value.value || '';
              let rest = oldValue?.value ? { ...oldValue.value } : {};
              if (newValue) {
                const defaultValue = VALID_LOGIC_KEYS.find((x) => x.key === newValue.key)!.defaultValue;
                type = newValue.key;
                value = newValue?.value.value || defaultValue;
                rest = newValue?.value || {};
              }

              delete rest.key;
              return {
                key: item.key,
                value: { ...rest, type, value },
              };
            }
            return {
              key: item.key,
              value: item.values[0].value,
            };
          })
        );
      }}
      items={items}
      itemsToSelect={itemsToSelect}
      extraItemCruds={LOGIC_FIELDS(organizationSlug, categories, sections, projectID)}
    />
  );
}

export const productParamsToItems = (p: Param) => ({
  key: p.key,
  values: [
    {
      key: 'val',
      value: p.value,
    },
  ],
});
