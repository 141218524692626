import draftJsToHtml from 'draftjs-to-html';
export const safeRawDraft = (text: string) =>
  `{"blocks":[{"key":"foooo","text":"${text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;

export type DraftJsText = string;
export type DraftJsObject = object;
export type HTML = string;

export function isStringDraftJS(value: unknown): value is DraftJsText {
  return typeof value === 'string' && value[0] === '{' && value[value.length - 1] === '}';
}

export function draftJs(richTextOrJustString: string = ''): DraftJsObject {
  if (!richTextOrJustString) {
    return JSON.parse(safeRawDraft(''));
  }
  if (isStringDraftJS(richTextOrJustString)) {
    try {
      return JSON.parse(richTextOrJustString);
    } catch (e) {}
  }

  // Replaces all occurrences of " to \". Will not replace \" to \\".
  const jsonSafeText = richTextOrJustString.replace(/([^\\])(")/g, '$1\\"');

  try {
    return JSON.parse(safeRawDraft(jsonSafeText));
  } catch (e) {
    return JSON.parse(safeRawDraft(''));
  }
}

export function draftToHtml(text: string): HTML {
  const safe = draftJs(text);
  return draftJsToHtml(safe);
}

export function draftToText(richTextOrJustString: string): string {
  const safe = draftJs(richTextOrJustString);
  const div = document.createElement('div');
  div.innerHTML = draftJsToHtml(safe);
  return div.innerText;
}
