import { useCallback, useState } from 'react';
import Delete from '@mui/icons-material/Delete';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Typography from '../../mui/Typography';
import { TextField } from '../../mui/Input';
import Spacer from '../../components/Spacer';
import ActionButtons, { IActionButtonProps } from '../../components/actions/buttons/ActionButtons';
import ApiError, { IApiError } from '../../components/ApiError';
import i18n from '../../libraries/i18n';

export interface IDeleteModalProps {
  title?: string;
  description?: string;
  onClose?: () => void;
  onConfirm: () => Promise<void>;
  children?: JSX.Element | string;
  actionProps?: Omit<IActionButtonProps, 'onClose' | 'onClick'>;
  confirmInput?: string;
}

interface State extends Pick<IDeleteModalProps, 'confirmInput'> {
  error: IApiError | null;
  busy: boolean;
}

export default function DeleteModal({
  title = i18n.t('actions.delete'),
  description = i18n.t('paragraphs.areYouSureToDelete'),
  onConfirm,
  onClose,
  actionProps,
  children,
  confirmInput,
}: IDeleteModalProps) {
  const [state, setState] = useState<State>({
    busy: false,
    error: null,
  });

  const handleOnInputChange = useCallback((e: any) => {
    const value = e.target.value;
    setState((s) => ({ ...s, confirmInput: value }));
  }, []);

  const handleOnConfirm = useCallback(
    async (e: any) => {
      try {
        e.preventDefault();
        setState((s) => ({ ...s, busy: true }));
        await onConfirm();
        if (typeof onClose === 'function') {
          onClose();
        }
      } catch (error: any) {
        setState({ error, busy: false });
      }
    },
    [onClose, onConfirm]
  );

  return (
    <form onSubmit={handleOnConfirm}>
      <ModalTitle>{title}</ModalTitle>
      <ModalContent>
        {description ? description : <Typography variant="h3">{description}</Typography>}
        {children}
        {confirmInput && (
          <Spacer>
            <Typography>
              Skriv inn <i>{confirmInput}</i> for å slette
            </Typography>
            <TextField
              value={state.confirmInput || ''}
              onChange={handleOnInputChange}
              placeholder={confirmInput}
              autoFocus
            />
          </Spacer>
        )}
        {state.error && <ApiError error={state.error} />}
      </ModalContent>
      <ModalActions>
        <ActionButtons
          disabled={state.busy}
          disabledSubmit={state.confirmInput !== confirmInput}
          onClose={onClose}
          actionLabel={title}
          actionIcon={<Delete />}
          {...actionProps}
        />
      </ModalActions>
    </form>
  );
}
