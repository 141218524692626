import { AptlyOrder, AptlyOrderStatus, AptlyUnit, AptlyUnitStatus } from '@aptly-as/types';
import { usePatch } from '@ewb/reach-react';
import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback, useContext } from 'react';
import { Trans } from 'react-i18next';
import ApiError from '../../../components/ApiError';
import Section from '../../../components/Section';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import { UnitContext } from '../UnitContext';
import UnitCreateCompleteReport from '../UnitCreateCompleteReport';

export interface UnitGeneralReportProps {
  unit: AptlyUnit;
}

export function UnitDashboardReport({ unit }: UnitGeneralReportProps) {
  const { setField } = useContext(UnitContext);
  const produced = unit.status === AptlyUnitStatus.Completed;
  const allSigned = unit.confirmedPeriods.every(
    (x) => (x.order as AptlyOrder)?.status === AptlyOrderStatus.Completed
  );
  const path = useApiUrl(SlugLevel.Project);
  const [state, patch] = usePatch<AptlyUnit>(`${path}/units`, {
    onPatch: (unit) => {
      if (unit) {
        setField('status', true)(AptlyUnitStatus.Confirmed);
      }
    },
  });
  const handleOnPatch = useCallback(
    () => patch(unit._id, { status: AptlyUnitStatus.Confirmed }),
    [unit._id, patch]
  );

  if (produced) {
    return (
      <Section>
        {state.error && <ApiError error={state.error} />}
        <Alert
          variant="outlined"
          action={
            <Button variant="text" size="small" disabled={state.busy} onClick={handleOnPatch}>
              {i18n.t('actions.openUnit')}
            </Button>
          }
        >
          {i18n.t('statuses.unitComplete')}
        </Alert>
      </Section>
    );
  }

  if (unit.confirmedPeriods.length === 0 || !allSigned) {
    return null;
  }

  return (
    <Section>
      <Alert severity="info" variant="outlined" action={<UnitCreateCompleteReport unit={unit} />}>
        <Typography variant="subtitle1">{i18n.t('actions.produceFinalReport')}</Typography>
        <Typography>
          <Trans i18nKey="paragraphs.finalReportInfo">
            Sets status to complete and produces final report for customer with options, total sum and and
            receipts.
          </Trans>
        </Typography>
      </Alert>
    </Section>
  );
}
