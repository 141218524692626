import { IUseFieldRet } from '@ewb/reach-react';
import styled from 'styled-components';
import { ISimpleComponentProps } from './simple-components.utils';
import { IApiError } from '../../ApiError';
import { ICrudFieldData } from '../../crud/utils/crud.utils';
import { Documents } from '../../../models/Document/Documents';

interface ISimpleDocumentsProps<T extends { _id?: string }> extends ISimpleComponentProps<T, any> {
  crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>;
}

export default function SimpleDocuments<T extends { _id?: string }>({
  crud,
  field,
}: ISimpleDocumentsProps<T>) {
  const { useDocument, disabled } = field;

  if (!useDocument) {
    throw new Error(`SimpleDocuments was used without useDocument in schema ${JSON.stringify(field)}`);
  }

  const props = useDocument(crud);

  if (!crud.state.data._id) return null;

  return (
    <Wrapper>
      <Documents {...props} simple disabled={disabled} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: -1.5rem;
`;
