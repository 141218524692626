import styled, { css } from 'styled-components';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MUIDialog, { DialogProps } from '@mui/material/Dialog';
import { Form } from './Input';
import ErrorBoundary from '../components/ErrorBoundary';

const ModalContent = DialogContent;
const ModalTitle = DialogTitle;
const ModalActions = DialogActions;

const ModalForm = styled(Form)<{ fullScreen?: boolean }>`
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;

  ${(props) =>
    props.fullScreen &&
    css`
      max-height: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `};
`;

interface Props extends DialogProps {
  onOpen?: () => void;
}

function Dialog(props: Props) {
  const { open, fullScreen, maxWidth, onOpen, onClose, children, scroll } = props;

  return (
    <MUIDialog
      open={open || false}
      onClose={onClose}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth
      scroll={scroll}
      TransitionProps={{
        onEntered: onOpen,
      }}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </MUIDialog>
  );
}

export default Dialog;
export { ModalContent, ModalTitle, ModalActions, ModalForm };
export type { Props as DialogProps };
