import { useContext, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import Main from '../../components/Main';
import { ModalProvider } from '../../containers/Modal/ModalContext';
import Project from '../Project/Project';
import Sidebar from '../../components/Sidebar';
import OrganizationHeader from './OrganizationHeader';
import { ScopeProvider } from '../../libraries/scope/ScopeContext';
import { OrganizationContext, OrganizationProvider } from './OrganizationContext';
import ErrorBoundary from '../../components/ErrorBoundary';
import SidebarMenu from '../../containers/Sidebar/SidebarMenu';
import organizationRoutes from './organization.routes';
import SidebarRoutes from '../../containers/Sidebar/SidebarRoutes';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import Page from '../Page/Page';
import UnitTemplateV2 from '../UnitTemplate/UnitTemplate';
import OrganizationAppsAccept from '../App/OrganizationAppsAccept';

function Organization() {
  const { slug } = useParams<{ slug: string }>();

  return (
    <OrganizationProvider slug={slug}>
      <ScopeProvider path={`organizations/${slug}/scope`}>
        <OrgRoutes />
      </ScopeProvider>
    </OrganizationProvider>
  );
}

function OrgRoutes() {
  const org = useContext(OrganizationContext);
  const routes = useMemo(() => organizationRoutes(org.data.producer), [org.data.producer]);

  return (
    <Routes>
      <Route path="prosjekter/:projectID/*" element={<Project />} />
      <Route path="apps/:app/accept" element={<OrganizationAppsAccept />} />
      <Route
        path="*"
        element={
          <ModalProvider>
            <Layout>
              <Header head={[<OrganizationHeader key="org" />]} />
              <Sidebar>
                <SidebarMenu level={SlugLevel.Organization} injection="organization" routes={routes} />
              </Sidebar>
              <Main>
                <ErrorBoundary>
                  <SidebarRoutes routes={routes}>
                    <Route path={'tilvalg/:unitTemplateID'} element={<UnitTemplateV2 />} />
                    <Route path={'sider/:pageID'} element={<Page />} />
                  </SidebarRoutes>
                </ErrorBoundary>
              </Main>
            </Layout>
          </ModalProvider>
        }
      />
    </Routes>
  );
}

export default Organization;
