import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import { ButtonWithIcon } from '../mui/Button';
import Menu from '../mui/Menu';

type Props = {
  label: string;
  color?: string;
  children: (close: () => any) => React.ReactNode;
};

type State = {
  anchorEl: HTMLElement | null;
};

class ExpandableButton extends React.Component<Props, State> {
  state = {
    anchorEl: null,
  };

  close = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { label, children } = this.props;
    const { anchorEl } = this.state;

    return (
      <>
        <ButtonWithIcon
          color="primary"
          onClick={(e: any) =>
            this.setState({
              anchorEl: e.currentTarget,
            })
          }
        >
          {label} <KeyboardArrowDown />
        </ButtonWithIcon>
        <Menu anchorEl={anchorEl || undefined} open={Boolean(anchorEl)} onClose={this.close}>
          {children(this.close)}
        </Menu>
      </>
    );
  }
}

export default ExpandableButton;
