import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import {
  SortableIcon,
  SortableList,
  SortableListItem,
  SortableProvider,
  useSortableItems,
} from '../../../libraries/dnd/Sortable';
import Grid from '../../../mui/Grid';
import { ListItemText } from '../../List/List';
import { ISimpleComponentProps } from './simple-components.utils';

export interface ISimpleSortProps<T extends object> extends ISimpleComponentProps<T, any> {}

export default function SimpleSort<T extends object>({ field, onChange }: ISimpleSortProps<T>) {
  const { value = [], label } = field;
  const sortableItems = useSortableItems<{ _id: string; name: string }>(value);

  return (
    <Grid container flexDirection="column">
      <Grid item>
        <Typography variant="subtitle1">{label}</Typography>
      </Grid>
      <Grid item>
        <SortableProvider items={sortableItems} onDragEnd={onChange}>
          <SortableList id="list-items" items={sortableItems}>
            {sortableItems.map((item) => (
              <>
                <SortableListItem key={item.id} id={item.id}>
                  <ListItemAvatar>
                    <SortableIcon id={item.id} />
                  </ListItemAvatar>
                  <ListItemText primary={item.name} />
                </SortableListItem>
                <Divider variant="inset" component="li" />
              </>
            ))}
          </SortableList>
        </SortableProvider>
      </Grid>
    </Grid>
  );
}
