import { FormEvent, PropsWithChildren, useCallback, useState } from 'react';
import Typography from '../../mui/Typography';
import { busyNotification, successNotification } from '../Notification/notification.utils';
import ApiError, { IApiError } from '../../components/ApiError';
import ActionButtons, { IActionButtonProps } from '../../components/actions/buttons/ActionButtons';
import i18n from '../../libraries/i18n';
import { ModalActions, ModalContent } from '../../mui/Dialog';
import { createModal } from './ModalContext.js';

export interface IConfirmModalProps extends Omit<IActionButtonProps, 'disabled' | 'onClose' | 'onClick'> {
  onClose?: () => void;
  title?: string;
  onConfirm: () => void | Promise<any>;
  busyLabel?: string;
}

export function useConfirmModal(props: PropsWithChildren<IConfirmModalProps>) {
  return useCallback(() => {
    createModal(<ConfirmModal {...props} />);
  }, [props]);
}

export default function ConfirmModal({
  title = i18n.t('actions.confirm'),
  busyLabel = i18n.t('statuses.saving'),
  onClose,
  onConfirm,
  children,
  ...rest
}: PropsWithChildren<IConfirmModalProps>) {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState<IApiError | null>(null);

  const confirm = useCallback(async () => {
    const busy = busyNotification(busyLabel);
    try {
      setError(null);
      setBusy(true);
      await onConfirm();
      successNotification(i18n.t('statuses.saved'));
      if (typeof onClose === 'function') {
        onClose();
      }
    } catch (e: any) {
      setError(e);
      setBusy(false);
    } finally {
      busy();
    }
  }, [onConfirm, onClose]);

  return (
    <>
      <ModalContent>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        {children as any}
        <ApiError error={error} />
      </ModalContent>
      <ModalActions>
        <ActionButtons {...rest} disabledClose={busy} disabled={busy} onClose={onClose} onClick={confirm} />
      </ModalActions>
    </>
  );
}

export interface IConfirmFormModalProps
  extends JSX.ElementChildrenAttribute,
    Omit<IActionButtonProps, 'disabled' | 'onClose' | 'onClick'> {
  onClose?: () => void;
  title?: string;
  onConfirm: (data: FormData) => void | Promise<any>;
  busyLabel?: string;
}

export function ConfirmFormModal({
  title = i18n.t('actions.confirm'),
  busyLabel = i18n.t('statuses.saving'),
  onClose,
  onConfirm,
  children,
  ...rest
}: IConfirmFormModalProps) {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState<IApiError | null>(null);

  const confirm = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const busy = busyNotification(busyLabel);
      try {
        setError(null);
        setBusy(true);
        await onConfirm(new FormData(e.currentTarget));
        successNotification(i18n.t('statuses.saved'));
        if (typeof onClose === 'function') {
          onClose();
        }
      } catch (e: any) {
        setError(e);
        setBusy(false);
      } finally {
        busy();
      }
    },
    [onConfirm, onClose]
  );

  return (
    <form onSubmit={confirm}>
      <ModalContent>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        {children as any}
        <ApiError error={error} />
      </ModalContent>
      <ModalActions>
        <ActionButtons {...rest} disabledClose={busy} disabled={busy} onClose={onClose} />
      </ModalActions>
    </form>
  );
}
