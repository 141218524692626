import filesSaver from '../../libraries/files-saver';
import i18n from '../../libraries/i18n';
import styled from 'styled-components';

interface Props {
  title?: string;
  token: string;
  fileName?: string;
}

export default function DocumentLink({ title = i18n.t('actions.download'), fileName, token }: Props) {
  return (
    <A
      onClick={async () => {
        await downloadDocument(token, fileName);
      }}
    >
      {title}
    </A>
  );
}

export const downloadDocument = async (token: string, fileName?: string) =>
  filesSaver({
    endpoint: `documents/${token}`,
    fileName,
  });

const A = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;
