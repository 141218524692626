import i18n from '../../../libraries/i18n';
import SimpleCrud from '../../SimpleCrud';

interface Props {
  endpoint: string;
  category: any;
  override: any;
  onCreate: (category: any) => void;
  onClose?: () => void;
}

export default function CrudOverwriteCategory({ endpoint, category, override, onCreate, onClose }: Props) {
  return (
    <SimpleCrud
      title={i18n.t('actions.overwriteCategory')}
      actionLabel={i18n.t('actions.overwrite')}
      id={override ? override._id : undefined}
      endpoint={`${endpoint}/category-overrides`}
      onCreate={onCreate}
      onClose={onClose}
      fields={[
        {
          name: 'category',
          type: 'hidden',
          value: category._id,
          label: '',
          forceSave: true,
        },
        {
          name: 'hide',
          type: 'checkbox',
          value: override ? override.hide : false,
          label: override ? override.hide.toString() : 'false',
          helpField: i18n.t('actions.hide'),
        },
      ]}
    />
  );
}
