import Typography from '../../mui/Typography';
import Card from '../../mui/Card';
import List from '../../components/List/List';
import { PropsWithChildren } from 'react';

export interface SettingsSectionProps {
  title: string;
}

export default function SettingsSection({ title, children }: PropsWithChildren<SettingsSectionProps>) {
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        {title}
      </Typography>
      <Card>
        <List disablePadding>{children}</List>
      </Card>
    </>
  );
}
