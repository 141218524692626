import { AptlyModule } from '@aptly-as/types';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Cost } from '../../components/Cost';
import { EditorText } from '../../components/EditorText';
import { CardHeader } from '../../mui/Card';

export interface ModuleCard {
  module: AptlyModule;
}

export default function ModuleCard({ module, children }: PropsWithChildren<ModuleCard>) {
  return (
    <StyledCard>
      <CardHeader title={module.name} />
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={9}>
            {typeof module.description === 'string' ? (
              <EditorText value={module.description} />
            ) : (
              module.description
            )}
          </Grid>
          <Grid container item xs={3} justifyContent="flex-end">
            <Cost cost={module.cost} />
          </Grid>
        </Grid>
      </CardContent>
      {children && <CardActions>{children}</CardActions>}
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
