import { AptlyPaymentSettlement, AptlyScopes } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import {
  dataGridActionProps,
  DataGridActionWrapper,
} from '../../components/Search/search-data-grid/data-grid.actions.js';
import { SearchPageOverlayProps } from '../../components/Search/SearchPageOverlay.js';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import SearchDataGrid, { ISearchDataGridProps } from '../../components/Search/SearchDataGrid';
import Search, { SearchFieldText } from '../../components/Search/Search';
import { intlDateFormat } from '../../libraries/intl.js';
import { IUsePaginatedSearchProps } from '../../libraries/reach/usePaginatedSearch.js';
import useScopeLevel from '../../libraries/scope/useScopeLevel.js';
import useScopeProps from '../../libraries/scope/useScopeProps.js';
import { DocumentPreviewIcon } from '../Document/DocumentPreview.js';
import { paymentSettlementSchema } from './payment-settlements.schema.js';
import { toSearchPatchPath } from '../../components/Search/search.utils';

const columns = (): GridColDef<AptlyPaymentSettlement>[] => [
  {
    field: 'externalRef',
    headerName: i18n.t('singles.reference'),
    flex: 1,
  },
  {
    field: 'organization',
    headerName: i18n.t('singles.organization'),
    width: 175,
    renderCell: (params) => params.value?.name || params.row.destination,
  },
  {
    field: 'createdAt',
    headerName: i18n.t('statuses.created'),
    width: 110,
    valueGetter: intlDateFormat,
  },
  {
    field: 'transactions',
    headerName: i18n.t('singles.orders'),
    width: 75,
    valueGetter: (v: AptlyPaymentSettlement['transactions']) => v.length,
  },
  {
    field: 'captured',
    type: 'number',
    headerName: i18n.t('statuses.captured'),
    width: 100,
    align: 'right',
  },
  {
    field: 'refunded',
    type: 'number',
    headerName: i18n.t('statuses.refunded'),
    width: 120,
    align: 'right',
  },
  {
    field: 'work',
    type: 'number',
    headerName: i18n.t('statuses.work'),
    width: 100,
    align: 'right',
  },
  {
    field: 'fee',
    type: 'number',
    headerName: i18n.t('statuses.fee'),
    width: 130,
    align: 'right',
  },
  {
    field: 'paid',
    type: 'number',
    headerName: i18n.t('statuses.paid'),
    width: 100,
    align: 'right',
  },
  dataGridActionProps(
    (params) => {
      const payment = params.row as AptlyPaymentSettlement;
      return (
        <DataGridActionWrapper>
          {payment.document && <DocumentPreviewIcon document={payment.document} />}
        </DataGridActionWrapper>
      );
    },
    { minWidth: 180 }
  ),
];

export default function PaymentSettlements() {
  const path = useApiUrl(SlugLevel.Organization, 'payment-settlements');
  const schema = useMemo(() => paymentSettlementSchema(), []);
  const level = useScopeLevel();
  const scope = useScopeProps({
    project: AptlyScopes.ProjectPayment,
    organization: AptlyScopes.OrganizationPayment,
    base: AptlyScopes.Admin,
  });
  const _columns = useMemo(() => columns(), []);
  const reach: IUsePaginatedSearchProps<AptlyPaymentSettlement> = useMemo(() => {
    let $populate = '';
    if (!path.includes('organizations')) {
      $populate += ';organization:_id,name';
    }
    return {
      paginationMode: 'server',
      query: { sort: '-createdAt', ...($populate ? { $populate } : {}) },
    };
  }, [path]);
  const initialState: ISearchDataGridProps['initialState'] = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          organization: !path.includes('organizations'),
        },
      },
    }),
    [path]
  );

  const searchFields: SearchPageOverlayProps['searchFields'] = useMemo(
    () => [
      <SearchFieldText key="externalRef" field="externalRef" label={i18n.t('singles.reference')} autoFocus />,
    ],
    []
  );

  return (
    <Search<AptlyPaymentSettlement>
      path={path}
      patchPath={toSearchPatchPath('payment-settlements')}
      level={level}
      scope={scope}
      header={{ title: i18n.t('singles.paymentSettlements') }}
      post={{ title: i18n.t('singles.paymentSettlements') }}
      patch={{ title: i18n.t('singles.paymentSettlements') }}
      schema={schema}
      fields={[]}
      options={{ disableBulk: true }}
      reach={reach}
      buttonActions={() => []}
      searchFields={searchFields}
    >
      {() => <SearchDataGrid columns={_columns} initialState={initialState} />}
    </Search>
  );
}
