import {
  AptlyOrder,
  AptlyOrganization,
  AptlyProducer,
  AptlyProduct,
  AptlyProject,
  AptlyScopes,
} from '@aptly-as/types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridColDef, GridFooter } from '@mui/x-data-grid';
import Decimal from 'decimal.js';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { EditButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { SearchExportButton } from '../../components/actions/buttons/ExportButton';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { successNotification } from '../../containers/Notification/notification.utils';
import i18n from '../../libraries/i18n';
import Search, { SearchFieldDate } from '../../components/Search/Search';
import {
  searchFieldProducerReference,
  SearchFieldReference,
} from '../../components/Search/search-fields/SearchFields';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { schemaProducer } from '../../components/crud/schema/fields.schema';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import useSimplePost, { IUsePropsCrudProps } from '../../components/simple/useSimplePost';
import { intlCurrencyDecimal } from '../../libraries/intl';
import Scope from '../../libraries/scope/Scope';

type OrderItem = AptlyOrder & AptlyOrder['items'][0];

const columns = (): GridColDef[] => [
  {
    type: 'string',
    field: 'product',
    headerName: i18n.t('singles.product'),
    valueGetter: (product: AptlyProduct, row) => {
      if (!product) return 'N/A';
      if (row.variant && product.variants) {
        const variant = product.variants.find((x) => x._id === row.variant);
        if (variant?.name) return variant.name;
      }
      return product.name || row.text || product;
    },
    flex: 1,
  },
  {
    type: 'string',
    field: 'productNumber',
    headerName: i18n.t('singles.productNumber'),
    valueGetter: (product: AptlyProduct, row) => {
      if (!product) return 'N/A';
      if (row.variant && product.variants) {
        const variant = product.variants.find((x) => x._id === row.variant);
        if (variant?.productNumber) return variant?.productNumber;
      }
      return product.productNumber || 'N/A';
    },
    width: 200,
  },
  {
    type: 'string',
    field: 'producer',
    headerName: i18n.t('singles.producer'),
    valueGetter: (value: AptlyProducer) => value?.name || value || 'N/A',
    width: 150,
  },
  {
    type: 'string',
    field: 'organization',
    headerName: i18n.t('singles.organization'),
    width: 250,
    valueGetter: (value: AptlyOrganization) => value?.name || value,
  },
  {
    type: 'string',
    field: 'project',
    headerName: i18n.t('singles.project'),
    width: 250,
    valueGetter: (value: AptlyProject) => value?.name || value,
  },
  {
    type: 'number',
    field: 'quantity',
    headerName: i18n.t('singles.quantity'),
    width: 100,
  },
  {
    type: 'number',
    field: 'unitCost',
    headerName: i18n.t('singles.totalPrice'),
    width: 200,
  },
];

export default function OrderItems() {
  const path = useApiUrl(SlugLevel.Organization, 'orders/items');

  const query = useMemo(
    () => ({
      $gte_created: dayjs().startOf('month').format('YYYY-MM-DD'),
      $lt_created: dayjs().endOf('month').add(1, 'day').format('YYYY-MM-DD'),
    }),
    []
  );

  const buttonActions = useCallback(() => {
    const actions = [
      <SearchExportButton
        key="export-by-product"
        label={i18n.t('singles.perProduct')}
        endpoint={`${path}/export-per-product`}
      />,
      <SearchExportButton key="export" endpoint={`${path}/export`} />,
    ];
    if (Scope.crud(AptlyScopes.Admin, 'U')) actions.unshift(<UpdateProducerNetCost key="update" />);
    return actions;
  }, [path]);

  return (
    <Search<OrderItem>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.Admin}
      header={{ title: i18n.t('singles.products') }}
      post={{ title: i18n.t('singles.products') }}
      patch={{ title: i18n.t('singles.products') }}
      schema={{}}
      fields={[]}
      options={{ disableBulk: true, defaultShow: true }}
      reach={{ query }}
      buttonActions={buttonActions}
      searchFields={[
        <SearchFieldDate key="$gte_created" field="$gte_created" label={i18n.t('singles.from')} />,
        <SearchFieldDate key="$lt_created" field="$lt_created" label={i18n.t('singles.to')} />,
        <SearchFieldReference key="reference" {...searchFieldProducerReference()} />,
      ]}
    >
      {(items) => <SearchDataGrid columns={columns()} slots={{ footer: footer(items) }} />}
    </Search>
  );
}

const footer = (items: OrderItem[]) => () => {
  const [quantity, total] = useMemo(
    () =>
      items.reduce(
        ([q, t], item) => [q.add(item.quantity), t.add(new Decimal(item.unitCost).mul(item.quantity))],
        [new Decimal(0), new Decimal(0)]
      ),
    []
  );
  return (
    <Grid container justifyContent="space-between" alignItems="center" gap={1}>
      <TotalText variant="subtitle1">
        {i18n.t('singles.quantity')}: {quantity.toNumber()}&nbsp;-&nbsp;
        {i18n.t('singles.total')}: {intlCurrencyDecimal(total.toNumber())}
      </TotalText>
      <GridFooter />
    </Grid>
  );
};

const TotalText = styled(Typography)`
  margin-left: ${(props) => props.theme.spacing()};
`;

function UpdateProducerNetCost() {
  const endpoint = useApiUrl(SlugLevel.Base, 'admin/producers/net-cost');

  const schema: ICrudSchema<AptlyProduct> = useMemo(
    () => ({
      producer: schemaProducer(),
    }),
    []
  );
  const props: IUsePropsCrudProps<AptlyProduct> = useMemo(
    () => ({
      title: i18n.t('singles.producer'),
      fields: ['producer'],
      description: (
        <Typography variant="subtitle1">
          Oppdaterer organisasjons produkter netto kostpris med like produktnummer fra produsentprodukt i
          Aptly.
        </Typography>
      ),
      actionButtonProps: {
        actionLabel: i18n.t('actions.edit'),
      },
    }),
    []
  );
  const post = useSimplePost<AptlyProduct>(endpoint, schema, props);
  const handleOnEdit = useCallback(() => {
    post(() => {
      successNotification(i18n.t('statuses.saved'));
    });
  }, [post]);

  return <EditButtonWithIcon onClick={handleOnEdit} title={`Oppdater ${i18n.t('singles.netCost')}`} />;
}
