import { createContext } from 'react';
import { IUseFieldRet } from '@ewb/reach-react';
import { IApiError } from '../ApiError';
import { ICrudFieldData, ISimpleCrudObject } from '../crud/utils/crud.utils';

export interface ISimpleProviderProps<T extends ISimpleCrudObject> extends JSX.ElementChildrenAttribute {
  crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>;
  meta?: any;
}

export const SimpleContext = createContext<Omit<ISimpleProviderProps<any>, 'children'>>({
  crud: {} as IUseFieldRet<any, IApiError, ICrudFieldData<any>>,
  meta: {},
});

export default function SimpleProvider<T extends ISimpleCrudObject>({
  children,
  crud,
  meta = {},
}: ISimpleProviderProps<T>) {
  return <SimpleContext.Provider value={{ crud, meta }}>{children as any}</SimpleContext.Provider>;
}
