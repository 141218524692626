import styled from 'styled-components';

const Illustration = styled.figure<{ width?: string; height?: string }>`
  display: inline-block;
  margin: 0;
  padding: 0;
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};

  > * {
    width: 100%;
  }

  > img {
    display: block;
  }
`;

export default Illustration;
