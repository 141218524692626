import TextField, { TextFieldProps } from '@mui/material/TextField';
import { ReactNode } from 'react';
import useGooglePlaceAutocomplete, { IGooglePlace } from '../libraries/google/useGooglePlaceAutocomplete';

interface Props extends Omit<TextFieldProps, 'onChange' | 'variant'> {
  label?: ReactNode;
  onChange: (place: IGooglePlace) => void;
}

export default function GooglePlaceAutocomplete({ onChange, label, ...rest }: Props) {
  const ref = useGooglePlaceAutocomplete(onChange);

  return <TextField inputRef={ref} label={label} {...rest} />;
}
