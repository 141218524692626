import { Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { useSearch } from '@ewb/reach-react';
import { AptlyHistoryType, AptlySearchPaginateResponse, AptlyUnitTemplate, AptlyUser } from '@aptly-as/types';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ShowMeMoreIconButton } from '../../../components/actions/icons/Icons';
import { intlDateTimeFormat } from '../../../libraries/intl';
import { UnitTemplateContext } from '../utils/UnitTemplateContext';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import ApiError, { IApiError } from '../../../components/ApiError';
import { ModalTitle, ModalContent, ModalActions } from '../../../mui/Dialog';
import ActionButtons from '../../../components/actions/buttons/ActionButtons';
import i18n from '../../../libraries/i18n';
import { ListItemIcon, ListItemText } from '../../../components/List/List';

type HistoryItem = AptlyUnitTemplate['history'][0];

export interface IHistoryModalProps {
  onClose: () => void;
}

const getIcon = (type: AptlyHistoryType) => {
  switch (type) {
    case AptlyHistoryType.Added:
      return <AddCircleOutlineOutlinedIcon />;
    case AptlyHistoryType.Updated:
      return <EditIcon />;
    case AptlyHistoryType.Deleted:
      return <DeleteIcon />;
    case AptlyHistoryType.Moved:
      return <MoveDownIcon />;
    case AptlyHistoryType.Copied:
      return <ContentCopyIcon />;
    default:
      return null;
  }
};

export default function HistoryModal({ onClose }: IHistoryModalProps) {
  const [open, setOpen] = useState<string | null>(null);

  const { unitTemplate, undo } = useContext(UnitTemplateContext);
  const unitTemplatePath = useApiUrl(SlugLevel.Project, 'unit-templates');
  const path = `${unitTemplatePath}/${unitTemplate._id}/history`;
  const props = useMemo(
    () => ({
      responseToData: (res: AptlySearchPaginateResponse<HistoryItem>) => ({
        items: res.data,
        count: res.count,
        limit: res.limit,
        skip: res.skip,
      }),
      query: { $populate: 'user:_id,fullName' },
    }),
    []
  );
  const [busy, items, searchError, next, info, { search }] = useSearch<
    HistoryItem,
    IApiError,
    AptlySearchPaginateResponse<HistoryItem>
  >(path, props);

  const handleUndo = useCallback(
    (_id: string) => async () => {
      await undo(_id);
      search({});
    },
    [undo, search]
  );

  const handleSetOpen = useCallback(
    (id: string) => () => {
      setOpen((s) => (s === id ? null : id));
    },
    []
  );

  return (
    <>
      <ModalTitle>{i18n.t('singles.history')}</ModalTitle>
      <ModalContent>
        {searchError && <ApiError error={searchError!} />}
        <List>
          {items.map((item) => (
            <Fragment key={item._id}>
              <ListItemButton onClick={handleSetOpen(item._id)} divider>
                <ListItemIcon>{getIcon(item.type)}</ListItemIcon>
                <ListItemText
                  primary={(item.user as AptlyUser).fullName}
                  secondary={intlDateTimeFormat(item.diffDate)}
                />
                {open === item._id ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open === item._id} timeout="auto" unmountOnExit>
                {JSON.stringify(item.diff, null, 2)}
                <Grid container justifyContent="flex-end">
                  <Button onClick={handleUndo(item._id)} variant="outlined">
                    {i18n.t('actions.revert')}
                  </Button>
                </Grid>
              </Collapse>
            </Fragment>
          ))}
        </List>
        {items.length < info.count && (
          <Grid container justifyContent="center">
            <ShowMeMoreIconButton onClick={() => next()} disabled={busy} />
          </Grid>
        )}
      </ModalContent>
      <ModalActions>
        <ActionButtons
          submitType="button"
          onClose={onClose}
          cancelLabel={i18n.t('actions.close')}
          disabled={busy}
        />
      </ModalActions>
    </>
  );
}
