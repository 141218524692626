import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Translate from '@mui/icons-material/Translate';
import Button from '@mui/material/Button';
import React from 'react';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export default function LanguageSelect() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement | null>(null);

  const handleClose = React.useCallback((event: any) => {
    if (anchorRef.current && anchorRef.current!.contains(event.target)) {
      return;
    }
    setOpen(false);
  }, []);

  const handleListKeyDown = React.useCallback((event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }, []);

  const handleLanguageSelect = React.useCallback(
    (locale: 'en' | 'nb') => async () => {
      await i18n.changeLanguage(locale);
      navigate(0);
    },
    [i18n, navigate]
  );

  return (
    <div>
      <StyledButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        startIcon={<Translate />}
        endIcon={<KeyboardArrowDown />}
        color="inherit"
      >
        {getActiveLanguage(i18n.language)}
      </StyledButton>
      {/* @ts-ignore */}
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal style={{ zIndex: 2 }}>
        {({ TransitionProps, placement }: any) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem
                    onClick={handleLanguageSelect('nb')}
                    selected={['nb', 'no'].includes(i18n.language)}
                  >
                    Norsk
                  </MenuItem>
                  <MenuItem onClick={handleLanguageSelect('en')} selected={i18n.language === 'en'}>
                    English
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

function getActiveLanguage(locale: string) {
  switch (locale) {
    case 'nb':
      return 'Norsk';
    case 'en':
      return 'English';
    default:
      return '';
  }
}

const StyledButton = styled(Button)`
  &.MuiButton-root {
    padding: 3px;
    min-width: 25px;
  }
  .MuiButton-startIcon {
    margin: 0;
  }
  .MuiButton-endIcon {
    margin: 0;
  }
`;
