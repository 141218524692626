import { successNotification } from '../containers/Notification/notification.utils';
import { CLIENT_URL } from '../env';
import i18n from '../libraries/i18n';

export function getUrlQueryParam(param: string) {
  if (typeof window === 'undefined') {
    return null;
  }
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
}

export function openPreview(path: string) {
  const url = CLIENT_URL + '/' + path;
  const tab = window.open(url, '_blank');
  tab && tab.focus();
}

export async function copyToClipboard(text: string) {
  if (typeof navigator !== 'undefined') {
    await navigator.clipboard.writeText(text).then(() => {
      successNotification(i18n.t('singles.copied'));
    });
  }
}

export function getCookieValue(key: string) {
  if (typeof document === 'undefined') return '';
  const b = document.cookie.match('(^|[^;]+)\\s*' + key + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}
