import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useCallback } from 'react';
import useCrudState from '../../../hooks/useCrudState';
import ReferenceInput, { toReferenceItem, toReferenceValue } from '../../ReferenceInput';
import { ISimpleCrudFieldProps } from '../utils/crud.utils';

interface Props<T extends object> extends ISimpleCrudFieldProps<T, any>, Pick<FormControlProps, 'variant'> {
  disabled?: boolean;
}

export function CrudReferenceComponent<T extends object>({
  field,
  onChange,
  showLabel,
  variant = 'standard',
  disabled,
}: Props<T>) {
  const { id, defaultValue, label, edited, pattern, required, useReference, ...rest } = field;
  const [value, setValue, save] = useCrudState(field.value, onChange, true);
  if (!useReference) {
    throw new Error(`SimpleReference was used without reference field ${JSON.stringify(field)}`);
  }
  const referenceProps = useReference();
  const handleOnSelect = useCallback(
    (is: any) => {
      if (referenceProps.multiple && Array.isArray(is)) {
        setValue(is.map(toReferenceValue));
      } else if (Array.isArray(is) && is[0]) {
        setValue(toReferenceValue(is[0]));
      } else {
        setValue(null);
      }
    },
    [setValue, referenceProps.multiple, save]
  );
  const _value = value || defaultValue || (referenceProps.multiple ? [] : null);
  const arrayValue = Array.isArray(_value) ? _value : [_value];

  return (
    <form onSubmit={save}>
      <FormControl variant={variant} fullWidth required={required} disabled={disabled}>
        {showLabel && label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        <ReferenceInput<any>
          onSelect={handleOnSelect}
          defaultSelected={arrayValue
            .filter(Boolean)
            .map((x: string) => toReferenceItem(x, referenceProps.labelKey))}
          singular={!referenceProps.multiple}
          {...rest}
          {...referenceProps}
        />
      </FormControl>
    </form>
  );
}
