import { ISimpleComponentProps } from './simple-components.utils';
import { Checkbox } from '../../../mui/Input';

export interface ISimpleCheckboxProps<T extends object> extends ISimpleComponentProps<T, any> {}

export default function SimpleCheckbox<T extends object>({ field, onChange }: ISimpleCheckboxProps<T>) {
  const { id, value = false, defaultValue, edited, gridProps, ...rest } = field;

  return <Checkbox id={id} checked={value} onChange={(_e, checked) => onChange(checked)} {...rest} />;
}
