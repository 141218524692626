import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useRef, useState } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import DateTimePicker from '../../../mui/x-date-picker/DateTimePicker';

export function useDataGridDateTimeText(params: GridRenderEditCellParams) {
  return <DataGridDateTimeEdit params={params} />;
}

interface DataGridTextEditProps {
  params: GridRenderEditCellParams;
}

export function DataGridDateTimeEdit({ params }: DataGridTextEditProps) {
  const { id, api, field } = params;
  const ref = useRef<Dayjs | null>(params.value ? dayjs(params.value) : null);
  const [value, setValue] = useState<Dayjs | null>(ref.current);

  const handleOnClose = useCallback(() => {
    api.setEditCellValue({ id, field, value: ref.current?.toDate() || null });
    api.stopCellEditMode({ id, field });
  }, [api, id, field]);

  const handleOnChange = useCallback((date: Dayjs | null) => {
    ref.current = date;
    setValue(date);
  }, []);

  return (
    <Grid container alignItems="center" style={{ padding: '0 10px' }}>
      <DateTimePicker
        value={value}
        onChange={handleOnChange}
        onClose={handleOnClose}
        open
        textFieldProps={{
          variant: 'standard',
        }}
      />
    </Grid>
  );
}
