import Alert from '@mui/material/Alert';
import { Reach } from '@ewb/reach-react';
import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IS_DEV } from '../env';
import handleError from '../containers/Error/handleError';
import { reachService } from '../libraries/reach/reach';

type ErrorBoundaryState = {
  error: Error | null;
};

type ErrorBoundaryProps = PropsWithChildren<object>;

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: null,
  };

  componentDidCatch(error: Error, info: any) {
    this.setState({ error });
    if (!IS_DEV) {
      sendErrorToApi(error, info).then();
    }
  }

  handleOnChange() {
    this.setState({ error: null });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <Alert severity="error">{error.message}</Alert>;
    }

    return (
      <Content error={error} onChange={this.handleOnChange.bind(this)}>
        {children}
      </Content>
    );
  }
}

function Content({ error, onChange, children }: PropsWithChildren<{ error: any; onChange: () => void }>) {
  const location = useLocation();
  useEffect(() => {
    if (error) {
      onChange();
    }
  }, [error, location, onChange]);

  return <>{children}</>;
}

async function sendErrorToApi(error: any, info: any) {
  try {
    const reach = new Reach(reachService);
    await reach.api('api/error', {
      method: 'POST',
      body: {
        error: error.message,
        stack: info.componentStack,
        href: window.location.href,
      },
    });
  } catch (e) {
    await handleError(e);
  }
}

export default ErrorBoundary;
