import { useCallback, useContext } from 'react';
import { SortIconButton } from '../../components/actions/icons/Icons';

import { SearchCrudContext } from '../../components/Search/search.utils';
import { IGridActionProps } from '../../components/Search/search-data-grid/data-grid.actions';

export default function DataGridVariantOrder({ params }: IGridActionProps) {
  const { id } = params;
  const { items, actions } = useContext(SearchCrudContext);
  const { spawnPatch } = actions;

  const handleOnSpawn = useCallback(() => {
    const index = items.findIndex((x) => x._id === id);
    if (index > -1) {
      spawnPatch(items[index], index, ['variants']);
    }
  }, [id, items, spawnPatch]);

  return <SortIconButton onClick={handleOnSpawn} />;
}
