import { AptlyIcon } from '@aptly-as/types';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MapIcon from '@mui/icons-material/Map';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';

/**
 * @deprecated. Use from @aptly-as/types instead
 */
export type Page = {
  _id: string;
  project: string;
  showInMenu: boolean;
  name: string;
  summary: string;
  icon?: AptlyIcon;
  legacy: string;
  image: string;
  designs: string[];
  upsellTemplate: any;
};

/**
 * @deprecated. Use from @aptly-as/types instead
 */
interface PageIcon {
  value: AptlyIcon | '';
  label: React.ReactNode;
}

export const pageIcons: PageIcon[] = [
  {
    value: '',
    label: <ViewQuiltOutlinedIcon />,
  },
  {
    value: AptlyIcon.Welcome,
    label: <EmojiPeople />,
  },
  {
    value: AptlyIcon.Contact,
    label: <PhoneEnabledOutlinedIcon />,
  },
  {
    value: AptlyIcon.Help,
    label: <HelpOutlineOutlinedIcon />,
  },
  {
    value: AptlyIcon.Important,
    label: <PriorityHighIcon />,
  },
  {
    value: AptlyIcon.Info,
    label: <InfoOutlinedIcon />,
  },
  {
    value: AptlyIcon.Map,
    label: <MapIcon />,
  },
];

/**
 * @deprecated. Use from @aptly-as/types instead
 */
type SectionRecommendation = 'recommendation';

/**
 * @deprecated. Use from @aptly-as/types instead
 */
type SectionText = 'text';

/**
 * @deprecated. Use from @aptly-as/types instead
 */
type SectionImage = 'image';

/**
 * @deprecated. Use from @aptly-as/types instead
 */
type SectionGallery = 'gallery';
/**
 * @deprecated. Use from @aptly-as/types instead
 */
type SectionCard = 'card';

/**
 * @deprecated. Use from @aptly-as/types instead
 */
type SectionLinks = 'links';

/**
 * @deprecated. Use from @aptly-as/types instead
 */
type SectionSpacer = 'spacer';

/**
 * @deprecated. Use from @aptly-as/types instead
 */
type SectionIFrame = 'iframe';

/**
 * @deprecated. Use from @aptly-as/types instead
 */
export type PageSectionTypes =
  | SectionRecommendation
  | SectionCard
  | SectionText
  | SectionImage
  | SectionGallery
  | SectionLinks
  | SectionSpacer
  | SectionIFrame;
