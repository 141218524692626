import {
  AptlyAppImplementation,
  AptlyFieldType,
  AptlyOrganization,
  AptlyOrganizationInvite,
  AptlyOrganizationMember,
  AptlyOrganizationMemberPosition,
  AptlyOrganizationRoles,
  AptlyOrganizationStatus,
  AptlyScopes,
} from '@aptly-as/types';
import { DotObject } from '@ewb/reach-react';
import {
  schemaAddressCity,
  schemaAddressCountry,
  schemaAddressLine1,
  schemaAddressLine2,
  schemaAddressZip,
} from '../../components/crud/schema/extends.schemas';
import {
  schemaEmail,
  schemaMediaImage,
  schemaName,
  schemaPhone,
  schemaProducer,
  schemaReferenceName,
} from '../../components/crud/schema/fields.schema';
import { schemaAppReference } from '../../components/crud/schema/reference.schemas.js';
import { ICrudField, ICrudFieldMedia, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { acceptImages } from '../../libraries/react-dropzone/drop-zone.utils';
import Scope from '../../libraries/scope/Scope';
import { SelectOption } from '../../mui/Select';
import {
  organizationRoleTranslation,
  organizationStatusOptions,
  organizationTypeOptions,
} from './organization.utils';

export interface ICrudOrganizationInvite extends AptlyOrganizationInvite {
  email: string;
  filterProjects?: boolean;
  filterDepartments?: boolean;
}

const address = {
  line1: '',
  line2: '',
  street: '',
  zip: '',
  city: '',
  country: '',
};

export const organizationRolesOptions = (): SelectOption[] => [
  {
    value: AptlyOrganizationRoles.ThirdParty,
    label: organizationRoleTranslation()['thirdParty'],
  },
  {
    value: AptlyOrganizationRoles.Support,
    label: organizationRoleTranslation()['support'],
  },
  {
    value: AptlyOrganizationRoles.Reports,
    label: organizationRoleTranslation()['reports'],
  },
  {
    value: AptlyOrganizationRoles.Orders,
    label: organizationRoleTranslation()['orders'],
  },
  {
    value: AptlyOrganizationRoles.Content,
    label: organizationRoleTranslation()['content'],
  },
  {
    value: AptlyOrganizationRoles.ProjectAdmin,
    label: organizationRoleTranslation()['projectAdmin'],
  },
  {
    value: AptlyOrganizationRoles.OrganizationContent,
    label: organizationRoleTranslation()['organizationContent'],
  },
  {
    value: AptlyOrganizationRoles.Admin,
    label: organizationRoleTranslation()['admin'],
  },
  {
    value: AptlyOrganizationRoles.TestingAdmin,
    label: organizationRoleTranslation()['testingAdmin'],
  },
];

type DotFields = 'address';
export type IOrganizationSchema = DotObject<Pick<AptlyOrganization, DotFields>> &
  Omit<AptlyOrganization, DotFields>;

const fieldAddressStreet = (): ICrudField<IOrganizationSchema, string> => ({
  type: AptlyFieldType.Text,
  defaultValue: '',
  label: i18n.t('singles.street'),
  autoComplete: 'address-line1',
});

const fieldAddressZip = (): ICrudField<IOrganizationSchema, string> => ({
  type: AptlyFieldType.Text,
  defaultValue: '',
  label: i18n.t('singles.zipCode'),
  autoComplete: 'address-line2',
});

const fieldAddressCity = (): ICrudField<IOrganizationSchema, string> => ({
  type: AptlyFieldType.Text,
  defaultValue: '',
  label: i18n.t('singles.place'),
  autoComplete: 'address-line3',
});

const defaultLogoImageOpts = (): ICrudFieldMedia<IOrganizationSchema> => ({
  width: 320,
  accept: acceptImages,
  opts: {
    disableFullWidth: true,
    style: { maxHeight: '50px', maxWidth: '250px', width: 'auto' },
    transforms: 'c_fit',
  },
});

const organizationSchema = (): ICrudSchema<IOrganizationSchema> => ({
  name: schemaName({ autoFocus: true }),
  email: schemaEmail({ required: false }),
  invoiceEmail: schemaEmail({ required: false, label: i18n.t('singles.invoiceEmail') }),
  tripletexCustomerId: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    required: false,
    label: i18n.t('singles.tripletexCustomerId'),
  },
  phone: schemaPhone(),
  number: schemaName({ label: i18n.t('singles.organizationCode'), required: false }),
  vat: schemaName({ label: i18n.t('singles.organizationNumber'), required: false }),
  slug: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: 'Slug / url',
    required: true,
    pattern: '^[a-z0-9]+(?:-[a-z0-9]+)*$',
  },
  address: {
    type: AptlyFieldType.Object,
    defaultValue: {
      billing: address,
      visit: address,
      post: address,
    },
    label: '',
  },
  logo: {
    type: AptlyFieldType.Image,
    defaultValue: null,
    label: i18n.t('singles.logo'),
    image: {
      ...defaultLogoImageOpts(),
      mediaKey: 'logoMedia',
    },
  },
  logoMedia: schemaMediaImage(),
  negativeLogoMedia: schemaMediaImage({
    label: i18n.t('singles.negativeLogo'),
    image: { ...defaultLogoImageOpts(), dark: true },
  }),
  projectLogoMedia: schemaMediaImage({
    label: i18n.t('singles.projectLogo'),
    image: defaultLogoImageOpts(),
  }),
  'address.billing.line1': schemaAddressLine1(),
  'address.billing.line2': schemaAddressLine2(),
  'address.billing.zip': schemaAddressZip(),
  'address.billing.city': schemaAddressCity(),
  'address.billing.country': schemaAddressCountry(),
  'address.visit.street': fieldAddressStreet(),
  'address.visit.zip': fieldAddressZip(),
  'address.visit.city': fieldAddressCity(),
  'address.post.street': fieldAddressStreet(),
  'address.post.zip': fieldAddressZip(),
  'address.post.city': fieldAddressCity(),
  producer: schemaProducer(),
  status: {
    type: AptlyFieldType.Select,
    label: i18n.t('singles.status'),
    defaultValue: AptlyOrganizationStatus.NewBusiness,
    options: organizationStatusOptions(),
  },
  type: {
    type: AptlyFieldType.Select,
    defaultValue: [],
    label: i18n.t('singles.type'),
    multiple: true,
    options: organizationTypeOptions(),
  },
  paymentApp: schemaAppReference(AptlyAppImplementation.Payment),
  signApp: schemaAppReference(AptlyAppImplementation.Sign),
  signMembers: {
    type: AptlyFieldType.Reference,
    defaultValue: [],
    label: i18n.t('singles.users'),
    useReference: () => ({
      multiple: true,
      endpoint: useApiUrl(SlugLevel.Organization, 'users'),
      queryKey: '$fullName',
      labelKey: 'fullName',
      query: { select: '_id,fullName', limit: 5, as: 'array' },
    }),
  },
});

export interface IOrganizationMemberSchema extends AptlyOrganizationMember {
  email?: string;
  filterProjects?: boolean;
  filterDepartments?: boolean;
}

export const organizationMemberSchemaFields: (keyof IOrganizationMemberSchema)[] = [
  'user',
  'permissions',
  'position',
  'filterProjects',
  'projects',
];
export const organizationMemberSchema = (): ICrudSchema<IOrganizationMemberSchema> => ({
  user: {
    type: AptlyFieldType.Reference,
    defaultValue: '',
    label: i18n.t('singles.user'),
    required: true,
    useReference: () => ({
      endpoint: 'users/search',
      queryKey: 'fullName',
      labelKey: 'fullName',
      query: { select: '_id,fullName', limit: 5 },
    }),
  },
  position: {
    type: AptlyFieldType.Select,
    defaultValue: AptlyOrganizationMemberPosition.User,
    label: i18n.t('singles.position'),
    options: [
      {
        value: AptlyOrganizationMemberPosition.Owner,
        label: i18n.t('singles.owner'),
      },
      {
        value: AptlyOrganizationMemberPosition.Admin,
        label: i18n.t('singles.admin'),
      },
      {
        value: AptlyOrganizationMemberPosition.Accounting,
        label: i18n.t('singles.accounting'),
      },
      {
        value: AptlyOrganizationMemberPosition.User,
        label: i18n.t('singles.user'),
      },
    ],
  },
  permissions: {
    type: AptlyFieldType.Select,
    defaultValue: [AptlyOrganizationRoles.Admin],
    label: i18n.t('singles.accessLevel'),
    multiple: true,
    options: organizationRolesOptions,
  },
  filterProjects: {
    type: AptlyFieldType.Switch,
    defaultValue: false,
    label: i18n.t('singles.projects'),
    renderValidate: () => Scope.crud(AptlyScopes.OrganizationMembers, 'U'),
    preOnChange: (filterProjects, crud) => {
      if (filterProjects) {
        crud.setField('projects')([]);
      } else {
        crud.setField('projects')(null);
      }
      return filterProjects;
    },
  },
  projects: schemaReferenceName(
    'projects',
    {
      label: i18n.t('singles.projects'),
      renderValidate: (crud) =>
        Scope.crud(AptlyScopes.OrganizationMembers, 'U') && Boolean(crud.getField('filterProjects').value),
    },
    {
      multiple: true,
    },
    SlugLevel.Organization
  ),
  filterDepartments: {
    type: AptlyFieldType.Switch,
    defaultValue: false,
    label: i18n.t('singles.departments'),
    renderValidate: () => Scope.crud(AptlyScopes.OrganizationDepartments, 'U'),
    preOnChange: (filterProjects, crud) => {
      if (filterProjects) {
        crud.setField('departments')([]);
      } else {
        crud.setField('departments')(null);
      }
      return filterProjects;
    },
  },
  departments: schemaReferenceName(
    'departments',
    {
      label: i18n.t('singles.departments'),
      renderValidate: (crud) =>
        Scope.crud(AptlyScopes.OrganizationDepartments, 'U') &&
        Boolean(crud.getField('filterDepartments').value),
    },
    {
      multiple: true,
    },
    SlugLevel.Organization
  ),
});
export const organizationInviteSchemaFields: (keyof ICrudOrganizationInvite)[] = [
  'email',
  'permissions',
  'position',
  'filterProjects',
  'projects',
  'filterDepartments',
  'departments',
];
export const organizationInviteSchema = (): ICrudSchema<ICrudOrganizationInvite> => ({
  email: schemaEmail(),
  ...(organizationMemberSchema() as ICrudSchema<ICrudOrganizationInvite>),
});

export default organizationSchema;
