import {
  AptlyProduct,
  AptlyUnitTemplateCategorySection,
  AptlyUnitTemplateCategorySectionAssortment,
  AptlyUnitTemplateCategorySectionProduct,
} from '@aptly-as/types';
import { getId } from '../../../libraries/mongoose';
import { IUnitTemplateState, IUseSortableData, unitTemplateUpdateWarning } from './unit-template.utils';
import { patchUnitTemplateMove, patchUnitTemplateOrder } from './unit-template.api';
import { ISortableDataProps } from './unit-template.sortable';
import {
  addSectionOrProducts,
  orderSectionOrProduct,
  sliceSectionOrProduct,
  toSliceProps,
} from './unit-template.handlers';

export function onUnitTemplateOver(from: IUseSortableData, to: IUseSortableData) {
  return (s: IUnitTemplateState): IUnitTemplateState => {
    const [newTemplate, items, pack, updated, assortments] = sliceSectionOrProduct(
      s.data!,
      toSliceProps(from)
    );
    unitTemplateUpdateWarning(updated, 'onUnitTemplateOver find');
    if (items.length > 0) {
      const saveTemplate = addSectionOrProducts(
        newTemplate,
        toSliceProps(to),
        items,
        to.sortable.index,
        updated,
        pack,
        assortments
      );
      unitTemplateUpdateWarning(updated, 'onUnitTemplateOver update');
      return { ...s, data: { ...saveTemplate } };
    }
    return s;
  };
}

export function hasMovedFromOrigin(origin: IUseSortableData, to: IUseSortableData) {
  if ((origin.package || origin.product) && origin.section && to.section) {
    return getId(origin.section) !== getId(to.section);
  }
  if (origin.section && origin.category && to.category) {
    return getId(origin.category) !== getId(to.category);
  }
  return false;
}

export function onUnitTemplateOrder(
  path: string,
  from: IUseSortableData,
  to: IUseSortableData,
  origin?: IUseSortableData
) {
  return (s: IUnitTemplateState): IUnitTemplateState => {
    const newState = orderSectionOrProduct(s.data!, from, to);

    // Temp fix for issues in DND. Gives error and no data is given.
    if (newState) {
      const [newTemplate, ids, key] = newState;
      if (origin && hasMovedFromOrigin(origin, to)) {
        patchUnitTemplateMove(path, origin, {
          category: to.category._id,
          ...((origin.product || origin.package) && { section: to.section?._id }),
          index: to.sortable.index,
        });
      } else {
        patchUnitTemplateOrder(path, from, { [key]: ids });
      }
      return { ...s, data: newTemplate };
    }

    return { ...s };
  };
}

interface IOnUnitTemplateSliceProps extends Partial<ISortableDataProps> {
  from: number;
  end: number;
  level?: 'category' | 'section' | 'package' | 'product';
  products?: AptlyUnitTemplateCategorySectionProduct[];
}

export function onUnitTemplateSlice({
  category,
  section,
  product,
  level,
  package: pack,
  from,
  end,
}: IOnUnitTemplateSliceProps) {
  return (s: IUnitTemplateState) => {
    if (level === 'category') {
      if (category) {
        const oldCategory = s.data!.categories[from];
        s.data!.categories.splice(from, end, {
          ...category,
          sections: oldCategory ? oldCategory.sections : category.sections,
        });
      } else {
        s.data!.categories.splice(from, end);
      }
      return { ...s, data: { ...s.data!, categories: [...s.data!.categories] } };
    }
    return {
      ...s,
      data: {
        ...s.data!,
        categories: s.data!.categories.map((c) => {
          if (c._id === category?._id) {
            if (level === 'section') {
              if (section) {
                const oldSection = c.sections[from];
                c.sections.splice(from, end, {
                  ...section,
                  products: oldSection ? oldSection.products : section.products,
                });
              } else {
                c.sections.splice(from, end);
              }
              return { ...c, sections: [...c.sections] };
            } else {
              c.sections = c.sections.map((s) => {
                if (s._id === section?._id) {
                  if (level === 'package') {
                    if (pack) {
                      s.packages.splice(from, end, pack);
                    } else {
                      const removePack = s.packages[from];
                      s.packages.splice(from, end);
                      s.products = removePack
                        ? s.products.filter((x) => x.partOfPackage !== removePack._id)
                        : s.products;
                    }
                    return { ...s, packages: [...s.packages] };
                  } else if (product) {
                    s.products.splice(from, end, product);
                  } else {
                    s.products.splice(from, end);
                  }
                  return { ...s, products: [...s.products] };
                }
                return s;
              });
            }
            return { ...c };
          }
          return c;
        }),
      },
    };
  };
}

export type IOnUnitTemplatePushProps = Omit<IOnUnitTemplateSliceProps, 'from' | 'end'>;
export function onUnitTemplatePush({
  category,
  section,
  product,
  package: pack,
  products,
  level,
}: IOnUnitTemplatePushProps) {
  return (s: IUnitTemplateState): IUnitTemplateState => {
    if (!category) return { ...s };
    if (!section) {
      return { ...s, data: { ...s.data!, categories: [...s.data!.categories, category] } };
    }
    return {
      ...s,
      data: {
        ...s.data!,
        categories: s.data!.categories.map((c) => {
          if (c._id === category?._id) {
            if (level === 'section') {
              return { ...c, sections: [...c.sections, section] };
            }

            const sections = c.sections.map((s) => {
              if (s._id === section?._id) {
                if (product) {
                  return { ...s, products: [...s.products, product] };
                } else if (level === 'product' && products) {
                  return { ...s, products: [...s.products, ...products] };
                } else if (level === 'package' && pack) {
                  const packProducts = pack.products || products || [];
                  const packAssortments = pack.assortments || [];
                  delete pack.products;
                  return {
                    ...s,
                    packages: [...s.packages, pack],
                    products: [...s.products, ...packProducts],
                    assortments: [...(s.assortments || []), ...packAssortments],
                  };
                }
              }
              return { ...s };
            });

            return { ...c, sections };
          }
          return { ...c };
        }),
      },
    };
  };
}
export type IOnUnitTemplatePatchProps = Partial<Omit<ISortableDataProps, 'package'>> & {
  updateProduct?: AptlyProduct;
};
export function onUnitTemplatePatch({
  category,
  section,
  product,
  updateProduct,
}: IOnUnitTemplatePatchProps) {
  return (s: IUnitTemplateState): IUnitTemplateState => {
    if (product) {
      return {
        ...s,
        data: {
          ...s.data!,
          categories: s.data!.categories.map((c) => ({
            ...c,
            sections: c.sections.map((s) => ({
              ...s,
              products: s.products.map((p) => {
                if (updateProduct && p.product && p.product._id === updateProduct._id) {
                  p.product = updateProduct;
                  return { ...p };
                }
                return p._id === product._id ? { ...p } : p;
              }),
            })),
          })),
        },
      };
    } else if (section) {
      return {
        ...s,
        data: {
          ...s.data!,
          categories: s.data!.categories.map((c) => ({
            ...c,
            sections: c.sections.map((s) => (s._id === section!._id ? section : s)),
          })),
        },
      };
    } else if (category)
      return {
        ...s,
        data: {
          ...s.data,
          categories: s.data!.categories.map((c) => (c._id === category!._id ? category : c)),
        } as any,
      };
    return s;
  };
}

export function unitTemplateAssortment(
  section: AptlyUnitTemplateCategorySection,
  assortment: AptlyUnitTemplateCategorySectionAssortment,
  products: string[],
  remove?: boolean
) {
  return (s: IUnitTemplateState): IUnitTemplateState => {
    const data = {
      ...s.data!,
      categories: s.data!.categories.map((category) => ({
        ...category,
        sections: category.sections.map((_section) => {
          if (section._id !== _section._id) return _section;

          return {
            ..._section,
            assortments: [
              ...(section.assortments || []).filter((x) => x._id !== assortment._id),
              ...(!remove ? [assortment] : []),
            ],
            products: section.products.map((product) => ({
              ...product,
              partOfAssortment: products.includes(product._id)
                ? assortment._id
                : product.partOfAssortment === assortment._id
                ? undefined
                : product.partOfAssortment,
            })),
          };
        }),
      })),
    };
    return { ...s, data };
  };
}
