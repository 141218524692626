import { ReactElement, useCallback, useContext } from 'react';
import { ModalContext } from './ModalContext';

export function useModal<T>(fn: (data: T) => ReactElement) {
  const { createModal } = useContext(ModalContext);
  return useCallback(
    (data: T) => {
      createModal(fn(data));
    },
    [fn]
  );
}
