import { Component } from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MUISelect, { SelectProps as MUISelectProps } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { TextField } from './Input';
import { MenuItem } from './Menu';
import { MenuProps } from '@mui/material/Menu';

export type SelectOption = {
  label: string | any;
  value: string | any;
  disabled?: boolean;
};

export type ISelectProps = {
  options: SelectOption[];
  ignorechange?: boolean;
  fullWidth?: any;
  label?: any;
  multiple?: boolean;
} & TextFieldProps;

type State = {
  value: Array<string | number | boolean> | string | number | boolean;
};

export default class Select extends Component<ISelectProps, State> {
  state = {
    value: this.props.value || this.props.defaultValue || ('' as any),
  };

  handleValueChange = (e: any) => {
    if (this.props.ignorechange) {
      return;
    }
    this.setState({ value: e.target.value });
  };

  render() {
    const { options, onChange, ...rest } = this.props;
    const { value } = this.state;
    return (
      <TextField
        select
        {...rest}
        value={value}
        onChange={(e) => {
          this.handleValueChange(e);
          if (onChange) {
            onChange(e);
          }
        }}
      >
        {options &&
          options.map((option: SelectOption) => (
            <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
    );
  }
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const StandardMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
};

interface ISelectMultipleProps extends Omit<MUISelectProps, 'value' | 'onChange' | 'renderValue'> {
  value: string[];
  options: { value: string | number; label: string }[];
  onChange: (value: string[]) => void;
  label: string;
  MenuProps?: Partial<MenuProps>;
}

export function SelectMultiple({
  value,
  options,
  label,
  onChange,
  MenuProps,
  ...rest
}: ISelectMultipleProps) {
  return (
    <FormControl fullWidth={rest.fullWidth}>
      <InputLabel id="mutiple-checkbox-label">{label}</InputLabel>
      <MUISelect
        labelId="mutiple-checkbox-label"
        id="mutiple-checkbox"
        multiple
        value={value}
        onChange={(e) => onChange(e.target.value as string[])}
        renderValue={(selected: any) =>
          options
            .filter((x) => (selected as string[]).some((y) => x.value === y))
            .map((x) => x.label)
            .join(', ')
        }
        MenuProps={{ ...StandardMenuProps, ...MenuProps }}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={value.some((x) => option.value === x)} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
}
