import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { IS_DEV } from '../env';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: IS_DEV,
    ns: ['common', 'translation'],
    supportedLngs: ['nb', 'en'],
    fallbackLng: ['nb', 'en'],
    missingKeyNoValueFallbackToKey: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      requestOptions: {
        cache: 'no-cache',
      },
    },
  });

export default i18n;
