import { PropsWithChildren } from 'react';
import Grid from '../../mui/Grid';
import Typography, { TypographyProps } from '../../mui/Typography';

export interface SearchHeaderProps {
  title: string;
  variant?: TypographyProps['variant'];
}

export default function SearchHeader({ title, variant, children }: PropsWithChildren<SearchHeaderProps>) {
  return (
    <Grid item xs={7} md={6} lg={4}>
      <Typography variant={variant || 'h1'} gutterBottom>
        {title}
      </Typography>
      {children}
    </Grid>
  );
}
