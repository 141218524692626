import Typography from '@mui/material/Typography';
import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import i18n from '../libraries/i18n';
import Button from '../mui/Button';
import Section from './Section';

export default class NotFound extends Component {
  render() {
    return (
      <Section>
        <Typography variant="h1" gutterBottom>
          {i18n.t('statuses.notFound')}
        </Typography>
        <Button component={NavLink} to="/" variant="contained">
          {i18n.t('paragraphs.backToHomePage')}
        </Button>
      </Section>
    );
  }
}
