import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';

const PREFIX = 'Drawer';

const classes = {
  paper: `${PREFIX}-paper`,
};

const StyledDrawer = styled(Drawer)((): any => ({
  [`& .${classes.paper}`]: {
    position: 'relative',
    height: 'calc(100vh - 4rem)',
  },
}));

const MyDrawer = ({ children, ...rest }: any) => (
  <StyledDrawer
    variant="permanent"
    classes={{
      paper: classes.paper,
    }}
    {...rest}
  >
    {children}
  </StyledDrawer>
);

const RelativeDrawer = MyDrawer;

export { RelativeDrawer };
