import { AptlyScopes } from '@aptly-as/types';
import { Alert } from '@mui/material';
import { useContext } from 'react';
import { EditButtonWithIcon } from '../../components/actions/buttons/Buttons';
import ClientIFrame from '../../containers/Client/ClientIFrame';
import { createModal } from '../../containers/Modal/ModalContext';
import { LayoutScope } from '../../libraries/scope/ScopeComponets';
import Section from '../../components/Section';
import Typography from '../../mui/Typography';
import { rawSpacing, tightSpacing } from '../../utils/spacing';
import Grid from '../../mui/Grid';
import i18n from '../../libraries/i18n';
import CrudUnitEdit from '../../components/simpleCruds/unit/CrudUnitEdit';
import CrudOverridePeriod from '../../components/simpleCruds/unit/CrudOverridePeriod';
import { useProject } from '../Project/ProjectContext';
import { UnitContext } from './UnitContext';
import { useSlugs } from '../../hooks/useGetApiUrl';
import UnitDashboardPeriods from './unitGeneral/UnitDashboardPeriods.js';
import { UnitDashboardReport } from './unitGeneral/UnitDashboardReport.js';
import UnitDashboardUsers from './unitGeneral/UnitDashboardUsers.js';

function UnitDashboard() {
  const projectData = useProject();
  const [organizationSlug, projectID, unitID] = useSlugs();
  const {
    data: unit,
    actions: { read: refresh },
  } = useContext(UnitContext);

  if (!projectData) return null;

  const baseEndpoint = `organizations/${organizationSlug}/projects/${projectID}/units`;

  return (
    <>
      <Section>
        <Grid container spacing={rawSpacing} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h1">
              {i18n.t('singles.unit')} {unit.name}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={tightSpacing} alignItems="center">
              <LayoutScope item scope={AptlyScopes.ProjectUnits} crud="U">
                <ClientIFrame endpoint={`${unitID}/velkommen/`} newWindow isUnit />
              </LayoutScope>
              {unit.overridePeriod && (
                <LayoutScope item scope={AptlyScopes.ProjectUnits} crud="U">
                  <EditButtonWithIcon
                    title={i18n.t('actions.overridePeriod')}
                    onClick={() => {
                      createModal(
                        <CrudOverridePeriod
                          endpoint={baseEndpoint}
                          unit={unit}
                          onCreate={() => refresh()}
                          periods={projectData.periods}
                        />
                      );
                    }}
                  />
                </LayoutScope>
              )}
              <LayoutScope item scope={AptlyScopes.ProjectUnits} crud="U">
                <EditButtonWithIcon
                  onClick={() =>
                    createModal(
                      <CrudUnitEdit
                        unit={unit}
                        onCreate={() => refresh()}
                        fields={['name', 'unitTemplate', 'status']}
                      />
                    )
                  }
                />
              </LayoutScope>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      {unit.unitTemplate && <UnitDashboardReport unit={unit} />}
      {unit.unitTemplateEjected && (
        <Section>
          <Alert color="info">{i18n.t('paragraphs.unitEjected')}</Alert>
        </Section>
      )}
      <UnitDashboardUsers unit={unit} refresh={refresh} />
      {unit.unitTemplate && <UnitDashboardPeriods project={projectData} unit={unit} refresh={refresh} />}
      {unit.unitTemplate && (
        <Section>
          <Alert color="info">Rom er flyttet til venstre i menyen.</Alert>
        </Section>
      )}
      {unit.unitTemplate && (
        <Section>
          <Alert color="info">Tilvalgliste er flyttet til venstre i menyen.</Alert>
        </Section>
      )}
    </>
  );
}

export default UnitDashboard;
