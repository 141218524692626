import { ReactNode } from 'react';
import { ListItem, ListItemProps } from './List/List';
import Grid from '../mui/Grid';
import { rawSpacing } from '../utils/spacing';

export interface ISettingsListItemProps extends ListItemProps {
  label: ReactNode;
}
export const SettingsListItem = ({ label, children, ...rest }: ISettingsListItemProps) => (
  <ListItem {...rest}>
    <Grid container spacing={rawSpacing} alignItems="center">
      <Grid item sm={3}>
        {label}
      </Grid>
      <Grid item sm={9}>
        {children}
      </Grid>
    </Grid>
  </ListItem>
);
