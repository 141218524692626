import { PaletteMode } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const IBMFont = ['"IBM Plex Sans"', '"Roboto"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',');
const nunitoFont = ['"Roboto"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',');

export const primary = '#00B8B3';
export const secondary = '#344b54';
export const error = '#B80005';
export const success = '#61B800';
export const info = '#00B8B3';
export const lightGray = '#E1E1E1';
export const gray = '#D8D8D8';
export const darkerGray = '#979797';

export const MOBILE_BREAK = 900;
export const SIDE_MENU_DESKTOP_SIZE = '14rem';
export const SIDE_MENU_MODILE_SIZE = '48px';

function aptlyTheme(mode: PaletteMode): ThemeOptions {
  return {
    palette: mode === 'light' ? lightThemePalette : darkThemePalette,
    typography,
  };
}

const lightThemePalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#00B8B3',
    light: '#4DFBEE',
    dark: '#0C5455',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#344b54',
    light: '#406d7a',
    dark: '#1C2930',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#61B800',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#B80005',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#00B8B3',
    contrastText: '#FFFFFF',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
};
const darkThemePalette: PaletteOptions = {
  ...lightThemePalette,
  mode: 'dark',
  primary: {
    main: '#00B8B3',
    dark: '#4DFBEE',
    light: '#4DFBEE',
    contrastText: '#131B1F',
  },
  secondary: {
    main: '#ACC1C9',
    contrastText: '#131B1F',
  },
  background: {
    default: '#202124',
    paper: '#2e2f31',
  },
};

const typography: TypographyOptions = {
  fontSize: 16,
  fontFamily: IBMFont,
  h1: {
    fontSize: '1.75rem',
    fontWeight: 600,
    color: '#00B8B3',
  },
  h2: {
    fontSize: '1.5625rem',
    fontWeight: 600,
  },
  h3: {
    fontSize: '1.5625rem',
    fontWeight: 500,
  },
  h4: {
    fontSize: '1.125rem',
    fontWeight: 500,
  },
  h5: {
    fontSize: '1.125rem',
    fontWeight: 400,
  },
  h6: {
    fontWeight: 400,
  },
  subtitle1: {
    fontWeight: 600,
  },
  subtitle2: {
    fontSize: '.875rem',
    fontWeight: 600,
  },
  body1: {
    fontFamily: nunitoFont,
    fontWeight: 300,
    fontSize: '1rem',
  },
  body2: {
    fontFamily: nunitoFont,
    fontWeight: 400,
  },
  button: {
    fontFamily: nunitoFont,
  },
};

export { aptlyTheme };
