import { createContext, Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import { AptlyProduct, AptlyProductVariant } from '@aptly-as/types';
import i18n from '../../libraries/i18n';
import { OrganizationContext } from '../Organization/OrganizationContext';

interface Context {
  active: number;
  path: string;
  setActive: Dispatch<SetStateAction<number>>;
  product: AptlyProduct;
  addVariant: (variant?: AptlyProductVariant) => void;
  deleteVariant: (i: number) => () => void;
  variantChange: (i: number) => (variant: AptlyProductVariant) => void;
  productChange: (product: AptlyProduct) => void;
  deleteProduct?: () => void;
}

export const ProductContext = createContext<Context>({
  active: 0,
  path: '',
  setActive: () => {},
  product: {} as AptlyProduct,
  addVariant: () => {},
  productChange: () => () => {},
  variantChange: () => () => {},
  deleteVariant: () => () => {},
  deleteProduct: () => {},
});

interface Props extends JSX.ElementChildrenAttribute {
  product: AptlyProduct;
  onChange: (product: AptlyProduct) => void;
  onDelete?: () => void;
}

export default function ProductProvider({ children, product, onChange, onDelete }: Props) {
  const org = useContext(OrganizationContext);
  const path = useMemo(() => {
    let path = 'api/v1';
    if (product.organization) path += `/organizations/${product.organization}`;
    else if (org.data?.producer) path += `/organizations/${org.data._id}`;
    if (product.project) path += `/projects/${product.project}`;
    return `${path}/products`;
  }, [product.project, product.organization, org.data]);
  const [active, setActive] = useState<number>(0);

  const productChange = useCallback(
    (updated: AptlyProduct) => {
      onChange({ ...updated, variants: product.variants });
    },
    [product, onChange]
  );
  const variantChange = useCallback(
    (i: number) => (variant: AptlyProductVariant) => {
      const variants = [...product.variants];
      variants[i] = variant;
      onChange({ ...product, variants });
    },
    [product, onChange]
  );
  const deleteVariant = useCallback(
    (i: number) => () => {
      const variants = [...product.variants];
      variants.splice(i, 1);
      onChange({ ...product, variants });
    },
    [product, onChange]
  );

  const addVariant = useCallback(
    (variant?: AptlyProductVariant) => {
      onChange({
        ...product,
        variants: [
          ...product.variants,
          variant ? variant : ({ name: i18n.t('singles.variant') } as AptlyProductVariant),
        ],
      });
      setActive(product.variants.length + 1);
    },
    [onChange, product]
  );

  return (
    <ProductContext.Provider
      value={{
        active,
        path,
        setActive,
        product,
        productChange,
        addVariant,
        deleteVariant,
        variantChange,
        deleteProduct: onDelete,
      }}
    >
      {children as any}
    </ProductContext.Provider>
  );
}
