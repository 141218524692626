import { AptlyPeriod, AptlyUnit } from '@aptly-as/types';
import i18n from '../../../libraries/i18n';
import SimpleCrud from '../../SimpleCrud';

interface Props {
  endpoint: string;
  onCreate: (unit: AptlyUnit) => void;
  onClose?: () => void;
  unit: AptlyUnit;
  periods: AptlyPeriod[];
}

export default function CrudOverridePeriod({ endpoint, onCreate, onClose, periods, unit }: Props) {
  const activePeriod = unit.overridePeriod ? periods.find((x) => x._id === unit.overridePeriod) : null;
  const value = activePeriod ? activePeriod._id : null;

  return (
    <SimpleCrud
      title="Skal bare brukes for å slette perioder. Bruk ny mulighet under perioder for å overskrive per periode"
      actionLabel={i18n.t('actions.edit')}
      id={unit._id}
      endpoint={endpoint}
      onCreate={onCreate as any}
      onClose={onClose}
      fields={[
        {
          type: 'select',
          name: 'overridePeriod',
          value: value || '',
          label: value || '',
          helpField: i18n.t('singles.deadlines'),
          options: [
            {
              value: null as any,
              label: '-- ' + i18n.t('actions.delete') + ' --',
            },
          ],
        },
      ]}
    />
  );
}
