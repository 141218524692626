import { AptlyFieldType } from '@aptly-as/types';
import { IUseFieldRet } from '@ewb/reach-react';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AccordionDetails } from '../../mui/Accordion';
import { ModalActions, ModalContent, ModalForm, ModalTitle } from '../../mui/Dialog';
import Grid from '../../mui/Grid';
import Accordion, { IAccordionProps } from '../Accordion';
import ActionButtons from '../actions/buttons/ActionButtons';
import ApiError, { IApiError } from '../ApiError';
import {
  ICrudFieldCustomGroupField,
  ICrudFieldData,
  ICrudSchema,
  ISimpleCrudObject,
} from '../crud/utils/crud.utils';
import { CSSGrid, CSSGridItem } from '../CSSGrid';
import SimpleBulkIds from './simple-components/SimpleBulkIds';
import SimpleCheckbox from './simple-components/SimpleCheckbox';
import SimpleColor from './simple-components/SimpleColor';
import SimpleDate from './simple-components/SimpleDate';
import SimpleDateTime from './simple-components/SimpleDateTime';
import SimpleDocuments from './simple-components/SimpleDocuments';
import SimpleFiles from './simple-components/SimpleFiles';
import SimpleGooglePlace from './simple-components/SimpleGooglePlace';
import SimpleImages from './simple-components/SimpleImages';
import SimpleMarkdown from './simple-components/SimpleMarkdown';
import SimpleMediaUpload from './simple-components/SimpleMediaUpload';
import SimpleImageMediaUpload from './simple-components/SimpleImageMediaUpload';
import SimpleReference from './simple-components/SimpleReference';
import SimpleSelect from './simple-components/SimpleSelect';
import SimpleSort from './simple-components/SimpleSort';
import SimpleStringArray from './simple-components/SimpleStringArray';
import SimpleSwitch from './simple-components/SimpleSwitch';
import SimpleTextArea from './simple-components/SimpleTextArea';
import SimpleTextField from './simple-components/SimpleTextField';
import SimpleTime from './simple-components/SimpleTime';
import SimpleProvider, { ISimpleProviderProps } from './SimpleContext';
import {
  ISimpleCrudGroupSchema,
  IUseSimpleCrudFieldsProps,
  SimpleCrudModalProps,
  useSimpleCrud,
  useSimpleCrudFields,
} from './useSimpleCrud';

export interface ISimpleModalProps<T extends ISimpleCrudObject, RET = T> {
  path: string;
  data: Partial<T>;
  schema: ICrudSchema<T>;
  fields: (keyof T)[];
  title: string;
  onEdit: (data: RET) => void;
  useFieldsProps?: IUseSimpleCrudFieldsProps<T>;
  onClose?: () => void;
  disableFields?: (keyof T)[];
  contentProps?: Omit<ISimpleCrudContentProps<T>, 'crud' | 'schema'>;
}

export function SimpleModal<T extends ISimpleCrudObject, RET = T>(props: ISimpleModalProps<T, RET>) {
  const { path, data, schema, fields, useFieldsProps, onClose, contentProps } = props;

  const editedFields = useMemo(() => {
    if (!props.disableFields) return schema;
    const copy = { ...schema };
    for (const key of props.disableFields) {
      if (key in copy && copy[key]) {
        copy[key] = { ...copy[key], disabled: true };
      }
    }
    return copy;
  }, [props.disableFields, schema]);

  const [crud, crudFields] = useSimpleCrudFields<T, keyof T, RET>(
    path,
    data,
    editedFields,
    fields,
    useFieldsProps
  );

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const data = await crud.save();
      if (data) {
        props.onEdit(data);
        props.onClose!();
      }
    },
    [crud, props]
  );

  const actionButtonProps: SimpleCrudModalProps<T>['actionButtonProps'] = useMemo(
    () => ({
      disabled: crud.state.busy,
    }),
    [crud.state.busy]
  );
  return (
    <SimpleCrudForm
      onSubmit={handleSubmit}
      title={props.title}
      actionButtonProps={actionButtonProps}
      onClose={onClose}
    >
      <SimpleCrudContent<T> crud={crud as any} schema={crudFields} {...contentProps} />
      {crud.state.error && <ApiError error={crud.state.error} />}
    </SimpleCrudForm>
  );
}

export interface ISimpleCrudModalProps<T extends ISimpleCrudObject> extends SimpleCrudModalProps<T> {
  onCreate?: (data: T) => void;
  data?: Partial<T>;
  useFieldProps?: IUseSimpleCrudFieldsProps<T>;
  meta?: object;
  description?: JSX.Element | string;
}

export function SimpleCrudModal<T extends ISimpleCrudObject>({
  crud,
  meta,
  fields: propsFields,
  onCreate,
  onClose,
  title,
  data,
  actionButtonProps = {},
  useFieldProps,
  description,
}: ISimpleCrudModalProps<T>) {
  const [simpleCrud, fields] = useSimpleCrud<T, keyof T>(crud, propsFields, data, useFieldProps);

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const data = await simpleCrud.save();
      if (data) {
        if (typeof onCreate === 'function') {
          onCreate(data);
        }
        onClose!();
      }
    },
    [simpleCrud, onClose, onCreate]
  );

  return (
    <SimpleCrudForm
      onSubmit={handleSubmit}
      title={title}
      actionButtonProps={actionButtonProps}
      disabled={simpleCrud.state.busy}
      onClose={onClose}
      description={description}
    >
      <SimpleCrudContent crud={simpleCrud} schema={fields} meta={meta} />
      {simpleCrud.state.error && <ApiError error={simpleCrud.state.error} />}
    </SimpleCrudForm>
  );
}

interface SimpleCrudFormProps<T extends ISimpleCrudObject>
  extends Pick<ISimpleCrudModalProps<T>, 'title' | 'actionButtonProps' | 'onClose'>,
    JSX.ElementChildrenAttribute {
  onSubmit: (e: FormEvent) => void;
  description?: JSX.Element | string;
  disabled?: boolean;
}

export function SimpleCrudForm<T extends ISimpleCrudObject>({
  onSubmit,
  title,
  description,
  children,
  actionButtonProps,
  onClose,
  disabled,
}: SimpleCrudFormProps<T>) {
  return (
    <ModalForm onSubmit={onSubmit}>
      {title && <ModalTitle>{title}</ModalTitle>}
      <ModalContent style={{ maxWidth: '100%' }}>
        {description && (
          <Grid container item>
            {description}
          </Grid>
        )}
        {children as any}
      </ModalContent>
      <ModalActions>
        <ActionButtons disabled={disabled} {...actionButtonProps} onClose={onClose} />
      </ModalActions>
    </ModalForm>
  );
}

export type ISimpleCrudContentGroupProps<T extends ISimpleCrudObject> = {
  [key: string]: {
    title?: (data: T) => string;
    subtitle?: (data: T) => string;
  };
};

export interface ISimpleCrudContentProps<T extends ISimpleCrudObject>
  extends Omit<ISimpleProviderProps<T>, 'children'> {
  schema: ISimpleCrudGroupSchema<T, keyof T>;
  accordionProps?: Omit<IAccordionProps, 'children' | 'key'>;
  groupProps?: ISimpleCrudContentGroupProps<T>;
}

export function SimpleCrudContent<T extends ISimpleCrudObject>({
  crud,
  meta,
  schema,
  accordionProps,
  groupProps,
}: ISimpleCrudContentProps<T>) {
  const [active, setActive] = useState('');
  const handleOnChange = useCallback(
    <K extends keyof T>(groupItem: ICrudFieldCustomGroupField<T, K>) =>
      async (value: T[K], autoSave?: boolean) => {
        if (typeof groupItem.preOnChange === 'function') {
          await crud.setField(groupItem.field)(groupItem.preOnChange(value, crud));
        } else {
          await crud.setField(groupItem.field)(value);
        }
        if (crud.state.data._id && autoSave && groupItem.saveAfterSet) {
          await crud.save();
        }
      },
    [crud]
  );
  return (
    <SimpleProvider crud={crud} meta={meta}>
      <Wrapper>
        {schema.map(([group, fields]) => {
          const groupFields = fields
            .filter((groupItem) => {
              if (typeof groupItem.renderValidate === 'function') {
                return groupItem.renderValidate(crud, groupItem);
              }
              return true;
            })
            .map((field) => {
              const props =
                field.gridProps && typeof field.gridProps === 'function'
                  ? field.gridProps(crud)
                  : field.gridProps;
              let component;
              if (field.requiredValidate) {
                field.required = field.requiredValidate(crud, field);
              }
              if (field.customRender) {
                component = field.customRender(crud, field);
              } else if (field.CustomComponent) {
                component = <field.CustomComponent crud={crud} field={field} />;
              } else {
                component = (
                  <SimpleCrudFields<T, keyof T> crud={crud} field={field} onChange={handleOnChange(field)} />
                );
              }

              return (
                <CSSGridItem key={field.id} {...(props || {})}>
                  {component}
                </CSSGridItem>
              );
            });

          if (group) {
            const props = groupProps ? groupProps[group] : null;
            const isActive = active === group;
            return (
              <Accordion
                key={group}
                expanded={isActive}
                onChange={() => setActive(isActive ? '' : group)}
                title={props?.title ? props?.title(crud.state.data) : group}
                subtitle={props?.subtitle ? props?.subtitle(crud.state.data) : ''}
                {...accordionProps}
              >
                {() => (
                  <AccordionDetails>
                    <CSSGrid>{groupFields}</CSSGrid>
                  </AccordionDetails>
                )}
              </Accordion>
            );
          }

          return <CSSGrid key={group}>{groupFields}</CSSGrid>;
        })}
      </Wrapper>
    </SimpleProvider>
  );
}

const Wrapper = styled.div`
  display: grid;
  gap: 0.5rem;
`;

interface SimpleCrudFieldsProps<T extends object, K extends keyof T> {
  crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>;
  field: ICrudFieldCustomGroupField<T, K>;
  onChange: (v: T[K], autoSave?: boolean) => void;
}

function SimpleCrudFields<T extends object, K extends keyof T>({
  crud,
  field,
  onChange,
}: SimpleCrudFieldsProps<T, K>) {
  switch (field.type) {
    case AptlyFieldType.Text:
    case AptlyFieldType.Email:
    case AptlyFieldType.Number:
    case AptlyFieldType.Password:
      return <SimpleTextField field={field} onChange={onChange} />;
    case AptlyFieldType.StringArray:
      return <SimpleStringArray field={field} onChange={onChange} />;
    case AptlyFieldType.File:
      return <SimpleFiles field={field} onChange={onChange} />;
    case AptlyFieldType.Files:
      return <SimpleFiles field={field} onChange={onChange} multiple />;
    case AptlyFieldType.TextArea:
      return <SimpleTextArea field={field} onChange={onChange} />;
    case AptlyFieldType.Markdown:
      return <SimpleMarkdown field={field} onChange={onChange} />;
    case AptlyFieldType.Select:
      return <SimpleSelect crud={crud} field={field} onChange={onChange} />;
    case AptlyFieldType.Image:
      return (
        <SimpleImageMediaUpload crud={crud} field={field} fieldKey={field.field} onChange={onChange} simple />
      );
    case AptlyFieldType.Media:
      return (
        <SimpleMediaUpload crud={crud} field={field} fieldKey={field.field} onChange={onChange} simple />
      );
    case AptlyFieldType.Images:
      return <SimpleImages field={field} onChange={onChange} simple />;
    case AptlyFieldType.Documents:
      return <SimpleDocuments crud={crud} field={field} onChange={onChange} />;
    case AptlyFieldType.Reference:
      return <SimpleReference crud={crud} field={field} onChange={onChange} />;
    case AptlyFieldType.Color:
      return <SimpleColor field={field} onChange={onChange} />;
    case AptlyFieldType.BulkIds:
      return <SimpleBulkIds crud={crud} field={field} onChange={onChange} />;
    case AptlyFieldType.Date:
      return <SimpleDate field={field} onChange={onChange} />;
    case AptlyFieldType.DateTime:
      return <SimpleDateTime field={field} onChange={onChange} />;
    case AptlyFieldType.Time:
      return <SimpleTime field={field} onChange={onChange} />;
    case AptlyFieldType.Sort:
      return <SimpleSort field={field} onChange={onChange} />;
    case AptlyFieldType.Switch:
      return <SimpleSwitch field={field} onChange={onChange} />;
    case AptlyFieldType.Checkbox:
      return <SimpleCheckbox field={field} onChange={onChange} />;
    case AptlyFieldType.GooglePlace:
      return <SimpleGooglePlace field={field} onChange={onChange} />;
    case AptlyFieldType.Hidden:
      return null;
    default:
      return <>Not implemented {field.type}</>;
  }
}
