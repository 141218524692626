import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SearchViewComposer from '../../deprecated/Search/SearchViewComposer';
import Table, { TableHead, TableBody, TableRow, TableCell, TableFooter } from '../../mui/Table';
import Paper from '../../mui/Paper';
import { Export } from '../../models/Unit/Units';
import i18n from '../../libraries/i18n';

interface BillingReport {
  name: string;
  members: number;
  projects: number;
  units: number;
}

export default function AdminBilling() {
  const endpoint = `admin/billing`;

  return (
    <SearchViewComposer<BillingReport>
      title={i18n.t('singles.billing')}
      description={
        <>
          {i18n.t('info.adminPanel.billing')}
          <br />
        </>
      }
      limit={999}
      endpoint={`${endpoint}/report`}
      query={{
        dataAs: 'billing',
      }}
      searchFields={[]}
      additionalActions={() => [<Export key="export" baseEndpoint={endpoint} />]}
      emptyStateIcon={<InsertChartOutlinedIcon />}
      dontUseTable
      initialQuery
    >
      {(report) => {
        const getTotal = (arr: BillingReport[], key: keyof Omit<BillingReport, 'name'>) =>
          arr.reduce((tot, item) => item[key] + tot, 0);

        return (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{i18n.t('singles.organizations')}</TableCell>
                  <TableCell>{i18n.t('singles.members')}</TableCell>
                  <TableCell>{i18n.t('singles.projects')}</TableCell>
                  <TableCell>{i18n.t('singles.units')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {report.map((r, k) => {
                  return (
                    <TableRow key={k}>
                      <TableCell>{r.name}</TableCell>
                      <TableCell>{r.members}</TableCell>
                      <TableCell>{r.projects}</TableCell>
                      <TableCell>{r.units}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>{i18n.t('singles.total')}</TableCell>
                  <TableCell>{getTotal(report, 'members')}</TableCell>
                  <TableCell>{getTotal(report, 'projects')}</TableCell>
                  <TableCell>{getTotal(report, 'units')}</TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        );
      }}
    </SearchViewComposer>
  );
}
