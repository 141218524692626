import { useCallback } from 'react';
import { AddButtonWithIcon } from '../../components/actions/buttons/Buttons';
import i18n from '../../libraries/i18n';

import { createModal } from '../Modal/ModalContext';
import { successNotification } from '../Notification/notification.utils';
import CrudCreateUnitProduct from '../../components/simpleCruds/product/CrudCreateUnitProduct';

interface Props {
  code: string;
  unitID: string;
  refresh: () => void;
}

export default function ThirdPartyAddExtraProduct({ code, unitID, refresh }: Props) {
  const onSave = useCallback(() => {
    successNotification(i18n.t('statuses.saved'));
    refresh();
  }, [refresh]);

  const onAdd = useCallback(() => {
    createModal(<AddProductModal code={code} unitID={unitID} onSave={onSave} />);
  }, [code, unitID, onSave]);

  return <AddButtonWithIcon onClick={onAdd}>{i18n.t('actions.addProduct')}</AddButtonWithIcon>;
}

interface AddProductModalProps {
  code: string;
  unitID: string;
  onClose?: () => void;
  onSave: () => void;
}

function AddProductModal({ code, unitID, onClose, onSave }: AddProductModalProps) {
  return (
    <CrudCreateUnitProduct
      endpoint={`third-party/units/${unitID}/extra-options`}
      customAuth={code}
      onClose={onClose}
      onSave={onSave}
      disableAlgorithms
    />
  );
}
