import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LOG_ID } from '../../env';
import { UserContext } from '../../models/User/UserContext';

declare const changelogfy: any;
interface ChangelogfyConfig {
  selector: string;
  app_id: string;
  init?: boolean;
  data?: ChangelogfyData;
}
interface ChangelogfyData {
  user_id: string;
  user_email: string;
}

const URL = 'https://widget.changelogfy.com/index.js';
const config: ChangelogfyConfig = {
  selector: '.changelogfy-widget',
  app_id: LOG_ID,
  init: false,
};

interface State {
  selector: '.changelogfy-widget';
  app_id: string;
  callbacks: object;
  nps: object;
  start: boolean;
  launcher: boolean;
  showAlertBadge: boolean;
  position: 'right';
  bottom: number;
  right: number;
  left: null;
  user: object;

  getUnreadCount: () => number;
  open: () => void;
  close: () => void;
}

// @ts-ignore
window.CLF_config = config;

export default function useChangelogfy() {
  const hasOpened = useRef(false);
  const hasInit = useRef(false);
  const [state, setState] = useState<State | null>(null);
  const { data } = useContext(UserContext);

  useEffect(() => {
    if (!hasInit.current && LOG_ID) {
      hasInit.current = true;
      const scripts = document.getElementsByTagName('script');
      for (let i = scripts.length; i--; ) {
        if (!state) {
          // @ts-ignore
          setState(window.changelogfy);
        }
        if (scripts[i].src === URL) {
          hasOpened.current = true;
          return;
        }
      }
      loadScript(URL).then(() => {
        init(setState, {
          user_id: data._id,
          user_email: data.email,
        });
      });
    }
  }, [state, data]);

  const open = useCallback(() => {
    if (state) {
      state.open();
    }
  }, [state]);

  const close = useCallback(() => {
    if (state) {
      state.close();
    }
  }, [state]);

  useEffect(() => {
    if (state && !hasOpened.current) {
      hasOpened.current = true;
      setTimeout(() => {
        const unreadCount = state.getUnreadCount();
        if (unreadCount > 0) {
          open();
        }
      }, 5000);
    }
  }, [state, open]);

  return { open, close, active: Boolean(state) };
}

function init(setState: any, data: ChangelogfyData) {
  if (typeof changelogfy !== 'undefined') {
    config.data = data;
    changelogfy.init(config);
    setState(changelogfy);
  } else {
    setTimeout(init, 250);
  }
}

function loadScript(src: string) {
  return new Promise(function (resolve, reject) {
    const s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
}
