import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import apiRequest from '../../libraries/fetch/apiRequest';
import Button from '../../mui/Button';
import { ModalActions, ModalTitle } from '../../mui/Dialog';
import SimpleCrud from '../../components/SimpleCrud';
import i18n from '../../libraries/i18n';
import { createModal } from '../Modal/ModalContext';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 20rem;
  padding: 10rem;
  margin: 0 auto;
`;

type Unit = {
  _id: string;
  name: string;
};

type State = {
  units: Unit[];
};

function UploadThirdParty() {
  const location = useLocation();
  const code = useRef('');
  const [state, setState] = useState<State>({
    units: [],
  });

  useEffect(() => {
    const fetch = async (customAuth: string) => {
      try {
        const units: Unit[] = await apiRequest('third-party/units', {
          customAuth,
        });

        setState({ units });
      } catch (error) {
        alert(i18n.t('statuses.linkNotActive'));
      }
    };
    const params = new URLSearchParams(location.search);
    const _code = params.get('kode') || '';
    if (code.current !== _code) {
      code.current = _code;
      fetch(_code);
    }
  }, [location]);

  const { units } = state;

  return (
    <Wrapper>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          createModal(
            <SimpleCrud
              title={i18n.t('actions.uploadDocument')}
              actionLabel={i18n.t('actions.add')}
              endpoint={'third-party/documents'}
              customAuth={code.current}
              multipart
              onCreate={() => {
                createModal(<FinalizeModal />);
              }}
              fields={[
                {
                  helpField: i18n.t('singles.unit'),
                  name: 'unit',
                  type: 'select',
                  referenceName: 'unit',
                  reference: {
                    endpoint: 'third-party/units',
                    labelKey: 'name',
                    queryKey: 'name',
                  },
                  value: '',
                  label: '',
                  options: units.map((u) => ({
                    value: u._id,
                    label: u.name,
                  })),
                },
                {
                  helpField: i18n.t('singles.type'),
                  name: '_type',
                  type: 'select',
                  value: 'document',
                  label: i18n.t('singles.document'),
                  options: [
                    {
                      label: i18n.t('singles.document'),
                      value: 'document',
                    },
                    {
                      label: i18n.t('singles.floorPlan'),
                      value: 'unitPlan',
                    },
                    {
                      label: i18n.t('singles.elDrawing'),
                      value: 'elDrawing',
                    },
                    {
                      label: i18n.t('singles.fdvDocument'),
                      value: 'fdv',
                    },
                    {
                      label: i18n.t('singles.agreementAttachment'),
                      value: 'orderAttachment',
                    },
                  ],
                  required: true,
                },
                {
                  type: 'file',
                  name: 'file',
                  value: null,
                  label: '',
                  helpField: i18n.t('singles.file'),
                  required: true,
                },
              ]}
            />
          );
        }}
      >
        {i18n.t('actions.uploadDocument')}
      </Button>
    </Wrapper>
  );
}

type FinalizeModalProps = {
  onClose?: () => any;
};

function FinalizeModal(props: FinalizeModalProps) {
  const { onClose } = props;

  return (
    <React.Fragment>
      <ModalTitle>{i18n.t('statuses.documentIsUploaded')}!</ModalTitle>
      <ModalActions>
        <Button color="primary" onClick={onClose}>
          {i18n.t('actions.close')}
        </Button>
      </ModalActions>
    </React.Fragment>
  );
}

export default UploadThirdParty;
