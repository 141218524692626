import { useCrud } from '@ewb/reach-react';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { AptlyUserClient } from '@aptly-as/types';
import { useParams } from 'react-router-dom';
import { SaveButtonWithIcon } from '../../components/actions/buttons/Buttons';
import ApiError from '../../components/ApiError';
import ScopeEdit from '../../components/ScopeEdit';
import Section from '../../components/Section';
import BusyState from '../../components/BusyState';
import useApiV1 from '../../hooks/useApiV1';
import i18n from '../../libraries/i18n';
import Grid from '../../mui/Grid';
import { TextField } from '../../mui/Input';

export default function UserAPIClient() {
  const { clientID } = useParams();
  const endpoint = useApiV1('base', '/oauth2/clients');
  const id = clientID !== 'new' ? clientID : '';
  const [state, setField, save] = useCrud<AptlyUserClient>(
    endpoint,
    {
      _id: id,
      scopes: {
        organization: 'R',
      },
    },
    useMemo(
      () => ({
        idKey: '_id',
        initWithGet: !!id,
        disableAutoSave: true,
      }),
      [id]
    )
  );

  const disabled = !!state.data._id;

  if (state.busy) return <BusyState />;

  return (
    <Section>
      <form onSubmit={save}>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Grid container justifyContent="space-between" spacing={2}>
              <Typography variant="h1">{i18n.t('singles.permissions')}</Typography>
              {!disabled && <SaveButtonWithIcon type="submit" />}
            </Grid>
          </Grid>
          {state.error && (
            <Grid item>
              <ApiError error={state.error} />
            </Grid>
          )}
          <Grid item>
            <TextField
              label={i18n.t('singles.name')}
              value={state.data.name}
              onChange={setField('name')}
              fullWidth
              required
              disabled={disabled}
            />
          </Grid>
          {state.data.clientId ? (
            <Grid item>
              {i18n.t('singles.clientId')}: {state.data.clientId}
              {state.data.clientSecret && (
                <>
                  <br />
                  <br />
                  Ta vare på secret, den kan ikke vises igjen!
                  <br />
                  {i18n.t('singles.clientSecret')}: {state.data.clientSecret}
                </>
              )}
            </Grid>
          ) : null}
          <Grid item>
            <ScopeEdit
              scope={state.data.scopes!}
              disabled={disabled}
              onChange={async (key, value) => {
                state.data.scopes![key] = value;
                await setField('scopes')({ ...state.data.scopes! });
              }}
            />
          </Grid>
        </Grid>
      </form>
    </Section>
  );
}
