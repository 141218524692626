import { AptlyScopes, AptlyTag } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import {
  dataGridActions,
  dataGridSearchNameColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import Search, { SearchFieldText } from '../../components/Search/Search';
import { tagSchema } from './tag.schema';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import { toSearchPatchPath } from '../../components/Search/search.utils';

const columns = (): GridColDef[] => [
  dataGridSearchNameColumn(),
  dataGridActions({ checkIfDisabledInProject: true }),
];

function Tags() {
  const path = useApiUrl(SlugLevel.Base, 'tags');

  return (
    <Search<AptlyTag>
      path={path}
      patchPath={toSearchPatchPath('tags')}
      scope={AptlyScopes.AdminTags}
      header={{ title: i18n.t('singles.tags') }}
      post={{ title: i18n.t('singles.tags') }}
      patch={{ title: i18n.t('singles.tags') }}
      schema={tagSchema()}
      fields={['name']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name' } }}
      buttonActions={() => [<SearchCreateAction key="create" />]}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns()} />}
    </Search>
  );
}

export default Tags;
