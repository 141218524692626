import { CssBaseline } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { JobsProvider } from './containers/Job/Jobs.context';
import Login from './containers/Login/Login';
import LoginCallback from './containers/Login/LoginCallback';
import { ModalProvider } from './containers/Modal/ModalContext';
import Notifications from './containers/Notification/Notifications';
import Organizations from './models/Organization/Organizations';
import UploadThirdParty from './containers/ThirdParty/UploadThirdParty';
import OrdersThirdParty from './models/Order/OrdersThirdParty';
import ErrorBoundary from './components/ErrorBoundary';
import NotFound from './components/NotFound';
import User from './models/User/User';
import { aptlyTheme } from './utils/themes';
import { GlobalStyles } from './components/Layout';
import { ScopeProvider } from './libraries/scope/ScopeContext';
import { UserContext } from './models/User/UserContext';
import Admin from './containers/Admin/Admin';
import Organization from './models/Organization/Organization';

dayjs.locale('nb');

function App() {
  const { loggedIn, mode } = useContext(UserContext);
  const [locale] = React.useState('nb');
  const t = useTranslation();

  const theme = useMemo(() => createTheme(aptlyTheme(mode)), [mode]);

  if (!t.ready) return null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <JobsProvider>
              <ErrorBoundary>
                {!loggedIn ? (
                  <ModalProvider>
                    <Routes>
                      <Route path="/last-opp" element={<UploadThirdParty />} />
                      <Route path="/varelinjer" element={<OrdersThirdParty />} />
                      <Route path="/login/callback" element={<LoginCallback />} />
                      <Route path="*" element={<Login />} />
                    </Routes>
                  </ModalProvider>
                ) : (
                  <ScopeProvider path="users/scope">
                    <Routes>
                      <Route path="organisasjoner/:slug/*" element={<Organization />} />
                      <Route
                        path="*"
                        element={
                          <ModalProvider>
                            <Routes>
                              <Route path="/" element={<Organizations />} />
                              <Route path="admin/*" element={<Admin />} />
                              <Route path="organisasjoner" element={<Organizations />} />
                              <Route path="login/callback" element={<LoginCallback />} />
                              <Route path="last-opp" element={<UploadThirdParty />} />
                              <Route path="varelinjer" element={<OrdersThirdParty />} />
                              <Route path="bruker/*" element={<User />} />
                              <Route path="*" element={<NotFound />} />
                            </Routes>
                          </ModalProvider>
                        }
                      />
                    </Routes>
                  </ScopeProvider>
                )}
              </ErrorBoundary>
              <GlobalStyles />
              <Notifications />
            </JobsProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
}

export default App;
