import { useCallback } from 'react';
import { AptlyEmail } from '@aptly-as/types';
import styled from 'styled-components';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { PreviewIconButton } from '../../components/actions/icons/Icons';
import { createModal } from '../../containers/Modal/ModalContext';
import { API_URL } from '../../env';
import { SlugLevel, SlugValues, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { getId } from '../../libraries/mongoose';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';

interface Props extends Pick<SlugValues, 'projectID'> {
  email: AptlyEmail | string;
  title?: string;
}

export default function EmailPreview({ email, projectID, title }: Props) {
  const handleOnPreview = useCallback(() => {
    createModal(<Preview email={email} projectID={projectID} title={title} />, { width: 'md' });
  }, [email, projectID, title]);

  return <PreviewIconButton onClick={handleOnPreview} />;
}

interface PreviewProps extends Props {
  onClose?: () => void;
  title?: string;
}
function Preview({ onClose, email, projectID, title }: PreviewProps) {
  const previewUrl = useApiUrl(SlugLevel.Project, `emails/${getId(email)}/preview`, { projectID });
  const _title = title || (email && typeof email === 'object' ? email.name : email);

  return (
    <>
      {_title && <ModalTitle>{_title}</ModalTitle>}
      <ModalContent>
        <StyledIframe src={`${API_URL}/${previewUrl}`} allow="" />
      </ModalContent>
      <ModalActions>
        <ActionButtons onClose={onClose} submitType="button" cancelLabel={i18n.t('actions.close')} />
      </ModalActions>
    </>
  );
}

const StyledIframe = styled.iframe`
  width: 99%;
  height: 80vh;
`;
