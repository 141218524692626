import { AptlyUnit } from '@aptly-as/types';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import React, { Dispatch, SetStateAction } from 'react';
import Typography from '@mui/material/Typography';
import { UploadButtonWithIcon } from '../../../components/actions/buttons/Buttons';
import { createModal } from '../../../containers/Modal/ModalContext';
import { dataGridNameCol } from '../../../mui/x-data-grid/dataGrid.cols';
import Documents_old from '../../Document/Documents_old';
import Section from '../../../components/Section';
import Tabs, { Tab } from '../../../mui/Tabs';
import i18n from '../../../libraries/i18n';
import useGetApiUrl from '../../../hooks/useGetApiUrl';
import DataGrid, { GridColDef, memoSetDataGridIds, GridRowsProp } from '../../../mui/x-data-grid/DataGrid';
import BusyState from '../../../components/BusyState';
import { useOrganization } from '../../Organization/OrganizationContext';
import ErrorState from '../../../containers/Error/ErrorState';
import {
  dataGridUnitBoligmappaNumberCol,
  dataGridUnitIntegrationBoligmappaDocumentErrors,
  dataGridUnitIntegrationBoligmappaDocumentUploaded,
  dataGridUnitIntegrationBoligmappaItemsDocumentsErrors,
  dataGridUnitIntegrationBoligmappaItemsDocumentsUploaded,
  dataGridUnitIntegrationPlantIdCol,
} from '../../../mui/x-data-grid/unit.cols';
import apiRequest from '../../../libraries/fetch/apiRequest';
import Grid from '../../../mui/Grid';
import { IconButton } from '../../../mui/Button';
import { ModalContent } from '../../../mui/Dialog';
import handleError from '../../../containers/Error/handleError';
import { ApiError } from '../../../containers/Error/createError';
import { busyNotification } from '../../../containers/Notification/notification.utils';
import { useProject } from '../ProjectContext';

export default function ProjectIntegrationBoligmappa() {
  const [active, setActive] = React.useState(0);
  const organization = useOrganization();
  const project = useProject();

  if (!organization || !project) return null;

  return (
    <>
      <Section>
        <Typography variant="h1">Boligmappa</Typography>
      </Section>
      <Tabs value={active} indicatorColor="primary" textColor="primary" onChange={(_e, v) => setActive(v)}>
        <Tab label={i18n.t('singles.documents')} />
        <Tab label={i18n.t('singles.units')} />
      </Tabs>
      {active === 0 && (
        <Documents_old
          organizationSlug={organization.slug}
          projectID={project._id}
          canCreate={false}
          canDelete={false}
          query={{
            thirdPartyUploader: 'boligmappa',
          }}
        />
      )}
      {active === 1 && <BoligmappaUnits />}
    </>
  );
}

const columns = (): GridColDef[] => [
  dataGridNameCol(),
  dataGridUnitBoligmappaNumberCol(),
  dataGridUnitIntegrationPlantIdCol(),
  dataGridUnitIntegrationBoligmappaDocumentUploaded(),
  dataGridUnitIntegrationBoligmappaDocumentErrors(),
  dataGridUnitIntegrationBoligmappaItemsDocumentsUploaded(),
  dataGridUnitIntegrationBoligmappaItemsDocumentsErrors(),
];

const effectGetUnits = (endpoint: string, setState: Dispatch<SetStateAction<State>>) => {
  setState((s) => ({ ...s, busy: true }));
  const data = { limit: 999, populateDocuments: true, populateItems: true };
  apiRequest<AptlyUnit[]>(endpoint, { data })
    .then((units) => {
      setState((s) => ({ ...s, busy: false, units }));
    })
    .catch((error) => {
      setState((s) => ({ ...s, error, busy: false }));
      handleError(error);
    });
};

interface State {
  error?: ApiError;
  busy?: boolean;
  units: AptlyUnit[];
  active: string[];
  upload: {
    unit: string;
    error?: string;
    status?: string;
    errorResponses: Response[];
  }[];
}

interface Response {
  documentId: string;
  documentTitle: string;
  uploadStatusCode: number;
  errorCode: number;
}

function BoligmappaUnits() {
  const endpoint = useGetApiUrl('project', '/units');
  const [state, setState] = React.useState<State>({
    units: [],
    busy: false,
    active: [],
    upload: [],
  });
  const { busy, units, active, error } = state;

  React.useEffect(() => effectGetUnits(endpoint, setState), [endpoint]);

  const onChange = React.useCallback((params: GridRowSelectionModel) => {
    setState((s) => ({ ...s, active: (params as string[]) || [] }));
  }, []);
  const onUpload = React.useCallback(async () => {
    setState((s) => ({ ...s, busy: true, upload: [] }));
    const busy = busyNotification();
    for (const active of state.active) {
      const unit = units.find((x) => x._id === active);
      if (!unit) continue;
      try {
        const files = await apiRequest<Response[]>(
          `${endpoint}/${unit._id}/integrations/jobs/boligmappa/upload-all-unit-documents-to-plant`,
          { method: 'POST' }
        );
        const errorResponses = files.filter((x) => x.errorCode);

        setState((s) => ({
          ...s,
          upload: [
            ...s.upload,
            {
              unit: unit.name,
              status: `${files.length - errorResponses.length} / ${files.length}`,
              errorResponses,
            },
          ],
        }));
      } catch (e: any) {
        setState((s) => ({
          ...s,
          upload: [...s.upload, { unit: unit.name, error: e.error, errorResponses: [] }],
          busy: false,
        }));
      }
    }
    effectGetUnits(endpoint, setState);
    busy();
  }, [endpoint, state.active, units]);

  const rows = React.useMemo<GridRowsProp>(() => memoSetDataGridIds(units), [units]);
  const grid = React.useMemo(
    () => (
      <DataGrid
        autoHeight
        columns={columns()}
        rows={rows}
        onRowSelectionModelChange={onChange}
        hideFooter
        checkboxSelection
      />
    ),
    [rows, onChange]
  );

  if (error) return <ErrorState error={error} />;
  if (busy && rows.length === 0) return <BusyState />;

  return (
    <Section>
      <Grid container direction="column" spacing={2}>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle2">{i18n.t('singles.units')}</Typography>
          </Grid>
          <Grid container item justifyContent="flex-end" spacing={2}>
            <UploadButtonWithIcon onClick={onUpload} disabled={busy || active.length === 0} />
          </Grid>
        </Grid>
        {state.upload.length > 0 && (
          <Grid item>
            {state.upload.map((x, i) => (
              <Grid container key={i} spacing={1} alignItems="center">
                <Grid item>
                  <Typography variant="subtitle1">
                    {x.unit}: {x.error ? `Error: ${x.error}` : x.status}
                  </Typography>
                </Grid>
                {x.errorResponses.length > 0 && (
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        createModal(
                          <ModalContent>
                            <Grid container direction="column">
                              <Typography variant="subtitle1">{i18n.t('singles.errorMessages')}</Typography>
                              {x.errorResponses.map((error, ii) => (
                                <Typography variant="subtitle2" key={ii}>
                                  {error.errorCode}: {error.documentTitle}
                                </Typography>
                              ))}
                            </Grid>
                          </ModalContent>
                        );
                      }}
                      size="large"
                    >
                      <VisibilityOutlinedIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        )}
        <Grid item>{grid}</Grid>
      </Grid>
    </Section>
  );
}
