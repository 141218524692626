import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeEvent, useCallback, useMemo } from 'react';
import Grid from '../../../mui/Grid';
import { Label } from '../../../mui/Input';
import Paper from '../../../mui/Paper';
import { AddIconButton, DeleteIconButton } from '../../actions/icons/Icons';
import List, { ListItem, ListSubheader } from '../../List/List';
import { ISimpleComponentProps } from './simple-components.utils';

export interface ISimpleStringArrayProps<T extends object> extends ISimpleComponentProps<T, any> {}

export default function SimpleStringArray<T extends object>({ field, onChange }: ISimpleStringArrayProps<T>) {
  const { value, label } = field;

  const stringArray = useMemo(() => (Array.isArray(value) ? (value as string[]) : []), [value]);

  const handleOnAdd = useCallback(() => {
    stringArray.push('');
    onChange([...stringArray]);
  }, [onChange, stringArray]);
  const handleOnChange: ItemProps['onChange'] = useCallback(
    (value, index) => {
      stringArray[index] = value;
      onChange([...stringArray]);
    },
    [onChange, stringArray]
  );
  const handleOnDelete: ItemProps['onDelete'] = useCallback(
    (index) => {
      stringArray.splice(index, 1);
      onChange([...stringArray]);
    },
    [onChange, stringArray]
  );

  return (
    <Paper component={Grid} container direction="column">
      <List
        subheader={
          <ListSubheader component={Grid} container justifyContent="space-between" alignItems="center">
            <Label>{label}</Label>
            <AddIconButton onClick={handleOnAdd} />
          </ListSubheader>
        }
      >
        {stringArray.map((x, i) => (
          <Item value={x} index={i} onChange={handleOnChange} onDelete={handleOnDelete} />
        ))}
      </List>
    </Paper>
  );
}

interface ItemProps {
  value: string;
  index: number;
  onChange: (value: string, index: number) => void;
  onDelete: (index: number) => void;
}

function Item({ value, index, onChange, onDelete }: ItemProps) {
  const handleOnDelete = useCallback(() => {
    onDelete(index);
  }, [onDelete, index]);
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value, index);
    },
    [onChange, index]
  );

  return (
    <ListItem>
      <Input
        defaultValue={value}
        onChange={handleOnChange}
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <DeleteIconButton onClick={handleOnDelete} size="small" />
          </InputAdornment>
        }
      />
    </ListItem>
  );
}
