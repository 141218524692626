import { useMemo } from 'react';
import { ISimpleCrudComponentProps } from './simple-components.utils';
import { Select } from '../../../mui/Input';

export interface ISimpleSelectProps<T extends object> extends ISimpleCrudComponentProps<T, any> {}

export default function SimpleSelect<T extends object>({ crud, field, onChange }: ISimpleSelectProps<T>) {
  const { id, value, defaultValue, edited, options, ...rest } = field;

  const selectOptions = useMemo(() => {
    if (!options) return null;
    return typeof options === 'function' ? options(crud, field) : options;
  }, [options, crud, field]);

  if (!selectOptions) {
    throw new Error(`SimpleSelect was used with no options ${JSON.stringify(field)}`);
  }

  return (
    <Select
      value={value}
      name={id}
      options={selectOptions}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      disabled={selectOptions.length === 1 || rest.disabled}
      {...rest}
    />
  );
}
