import i18n from '../../libraries/i18n';
import {
  AptlyOrganizationMemberPosition,
  AptlyOrganizationRoles,
  AptlyOrganizationStatus,
  AptlyOrganizationType,
} from '@aptly-as/types';
import { SelectOption } from '../../mui/Select';

export const organizationRoleTranslation = (): {
  [key in AptlyOrganizationRoles]: string;
} => ({
  [AptlyOrganizationRoles.Admin]: i18n.t('singles.administrator'),
  [AptlyOrganizationRoles.Content]: `${i18n.t('singles.project')} ${i18n.t('singles.content')}`,
  [AptlyOrganizationRoles.Default]: i18n.t('singles.standard'),
  [AptlyOrganizationRoles.Orders]: i18n.t('singles.orders'),
  [AptlyOrganizationRoles.OrganizationContent]: `${i18n.t('singles.organization')} ${i18n.t(
    'singles.content'
  )}`,
  [AptlyOrganizationRoles.ProjectAdmin]: `${i18n.t('singles.project')} ${i18n.t('singles.administrator')}`,
  [AptlyOrganizationRoles.Reports]: i18n.t('singles.reporting'),
  [AptlyOrganizationRoles.Support]: i18n.t('singles.support'),
  [AptlyOrganizationRoles.TestingAdmin]: i18n.t('singles.administratorTester'),
  [AptlyOrganizationRoles.ThirdParty]: i18n.t('singles.thirdParty'),
});

export const toPermissionTranslation = (p: AptlyOrganizationRoles) => organizationRoleTranslation()[p];

export const organizationMemberPosition = (): {
  [key in AptlyOrganizationMemberPosition]: string;
} => ({
  [AptlyOrganizationMemberPosition.Owner]: i18n.t('singles.owner'),
  [AptlyOrganizationMemberPosition.Admin]: i18n.t('singles.admin'),
  [AptlyOrganizationMemberPosition.Accounting]: i18n.t('singles.accounting'),
  [AptlyOrganizationMemberPosition.User]: i18n.t('singles.user'),
});
export const toOrganizationMemberPosition = (p: AptlyOrganizationMemberPosition) =>
  organizationMemberPosition()[p];
export const organizationMemberPositionOptions = (): SelectOption[] => {
  const labels = organizationMemberPosition();
  return [
    {
      value: AptlyOrganizationMemberPosition.Owner,
      label: labels[AptlyOrganizationMemberPosition.Owner],
    },
    {
      value: AptlyOrganizationMemberPosition.Admin,
      label: labels[AptlyOrganizationMemberPosition.Admin],
    },
    {
      value: AptlyOrganizationMemberPosition.Accounting,
      label: labels[AptlyOrganizationMemberPosition.Accounting],
    },
    {
      value: AptlyOrganizationMemberPosition.User,
      label: labels[AptlyOrganizationMemberPosition.User],
    },
  ];
};

export const organizationTypeOptionsAll = (): SelectOption[] => {
  const options = organizationTypeOptions();
  options.unshift({ value: '', label: i18n.t('singles.all') });
  return options;
};
export const organizationTypeOptions = (): SelectOption[] => [
  { value: AptlyOrganizationType.Carpenter, label: i18n.t('singles.carpenter') },
  { value: AptlyOrganizationType.Contractor, label: i18n.t('singles.contractor') },
  { value: AptlyOrganizationType.Electrician, label: i18n.t('singles.electrician') },
  { value: AptlyOrganizationType.Plumber, label: i18n.t('singles.plumber') },
  { value: AptlyOrganizationType.Producer, label: i18n.t('singles.producer') },
];
export const organizationStatusOptionsAll = (): SelectOption[] => {
  const options = organizationStatusOptions();
  options.unshift({ value: '', label: i18n.t('singles.all') });
  return options;
};
export const organizationStatusOptions = (): SelectOption[] => [
  { value: AptlyOrganizationStatus.NewBusiness, label: i18n.t('statuses.newBusiness') },
  { value: AptlyOrganizationStatus.Active, label: i18n.t('statuses.active') },
  { value: AptlyOrganizationStatus.Paused, label: i18n.t('statuses.paused') },
  { value: AptlyOrganizationStatus.Inactive, label: i18n.t('statuses.inactive') },
  { value: AptlyOrganizationStatus.Closed, label: i18n.t('statuses.closed') },
];
