import { useContext } from 'react';
import { CreateButtonWithIcon } from '../../actions/buttons/Buttons';
import { SearchCrudContext } from '../search.utils';

export interface ISearchCreateActionProps<T extends object> {
  title?: string;
  keys?: (keyof T)[];
}

export default function SearchCreateAction<T extends object>({ keys, title }: ISearchCreateActionProps<T>) {
  const { actions, scope } = useContext(SearchCrudContext);

  if (!scope.create) return null;

  return (
    <CreateButtonWithIcon onClick={() => actions.spawnPost(actions.unshift, {}, keys)}>
      {title}
    </CreateButtonWithIcon>
  );
}
