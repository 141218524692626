import { AptlyUnit, AptlyUnitStatus, AptlyUnitTemplate } from '@aptly-as/types';
import i18n from '../../../libraries/i18n';
import { unitStatusLabel } from '../../../models/Unit/unit.utils';
import SimpleCrud from '../../SimpleCrud';
import { UnitEditData } from '../../../models/Unit/unit.types';
import { Field } from '../../../deprecated/inputs';
import useGetApiUrl from '../../../hooks/useGetApiUrl';

interface Props {
  unit: AptlyUnit;
  onCreate: (unit: AptlyUnit) => void;
  fields: Array<keyof AptlyUnit>;
  onClose?: () => void;
  title?: string;
  usersEndpoint?: string;
}

export const unitStatusOptions = (): Field['options'] => [
  { value: AptlyUnitStatus.NotStarted, label: i18n.t('statuses.notStarted') },
  { value: AptlyUnitStatus.Started, label: i18n.t('statuses.started') },
  { value: AptlyUnitStatus.Confirmed, label: i18n.t('statuses.confirmed') },
  { value: AptlyUnitStatus.Signed, label: i18n.t('statuses.signed') },
  { value: AptlyUnitStatus.Payed, label: i18n.t('statuses.payed') },
  { value: AptlyUnitStatus.Completed, label: i18n.t('statuses.completed') },
];

export default function CrudUnitEdit({
  unit,
  onCreate,
  onClose,
  fields,
  title = i18n.t('actions.edit'),
  usersEndpoint,
}: Props) {
  const endpoint = useGetApiUrl('project');
  const unitEndpoint = `${endpoint}/units`;
  const unitTemplateEndpoint = `${endpoint}/unit-templates`;
  const unitID = unit ? unit._id : undefined;
  const unitFields = getUnitFields(unit, usersEndpoint);

  return (
    <SimpleCrud
      title={title}
      id={unitID}
      endpoint={unitEndpoint}
      onCreate={onCreate}
      onClose={onClose}
      fields={fields.map((x) => {
        switch (x) {
          case 'unitTemplate':
            return unitFields[x](unitTemplateEndpoint);
          default:
            return unitFields[x] as Field;
        }
      })}
    />
  );
}

type UnitFields = {
  [key in keyof Omit<AptlyUnit, 'unitTemplate'>]?: Field;
} & {
  unitTemplate: (unitTemplateEndpoint: string) => Field;
};

function getTextField(key: keyof AptlyUnit, unit?: AptlyUnit): Field {
  return {
    type: 'text',
    name: key,
    value: unit && unit[key] ? unit[key] || '' : '',
    label: String(unit && unit[key] ? unit[key] || '' : ''),
    helpField: translateUnitKeys()[key as keyof UnitEditData],
  };
}

const getUnitFields = (unit?: AptlyUnit, usersEndpoint?: string): UnitFields => ({
  name: {
    type: 'text',
    name: 'name',
    value: unit ? unit.name : '',
    label: unit ? unit.name : '',
    helpField: i18n.t('singles.name'),
    required: true,
    autoFocus: true,
  },
  unitTemplate: (unitTemplateEndpoint) => ({
    type: 'reference',
    name: 'unitTemplate',
    value: unit && unit.unitTemplate ? (unit.unitTemplate as AptlyUnitTemplate)._id : null,
    label: unit && unit.unitTemplate ? (unit.unitTemplate as AptlyUnitTemplate).name : '',
    helpField: i18n.t('singles.optionList'),
    required: true,
    reference: {
      endpoint: `${unitTemplateEndpoint}/search`,
      queryKey: 'name',
      labelKey: 'name',
    },
  }),
  status: {
    type: 'select',
    name: 'status',
    value: unit?.status || AptlyUnitStatus.NotStarted,
    label: unitStatusLabel(unit?.status || AptlyUnitStatus.NotStarted),
    helpField: i18n.t('singles.status'),
    options: unitStatusOptions(),
  },
  users: {
    type: 'reference',
    name: 'users',
    value: unit?.users || [],
    label: '',
    helpField: i18n.t('singles.users'),
    extra: { singular: false },
    reference: {
      endpoint: usersEndpoint || '',
      queryKey: '$fullName',
      labelKey: 'fullName',
      query: { as: 'array' },
    },
  },
  ...Object.keys(translateUnitKeys()).reduce((obj, x: any) => ({ ...obj, [x]: getTextField(x, unit) }), {}),
});

type TranslateKeys = {
  [key in keyof UnitEditData]: string;
};
export const translateUnitKeys = (): TranslateKeys => ({
  address: i18n.t('singles.address'),
  houseNumber: i18n.t('singles.number'),
  zipCode: i18n.t('singles.zipCode'),
  city: i18n.t('singles.city'),
  entrance: i18n.t('singles.entrance'),
  floor: i18n.t('singles.floor'),
  oldAddress: i18n.t('singles.oldAddress'),

  unitNumber: i18n.t('singles.unitNumber'),
  holdingNumber: i18n.t('singles.holdingNumber'),
  subHoldingNumber: i18n.t('singles.subHoldingNumber'),
  leaseNumber: i18n.t('singles.leaseNumber'),
  sectionNumber: i18n.t('singles.sectionNumber'),
  boligmappaNumber: i18n.t('singles.boligmappaNumber'),

  garageNumber: i18n.t('singles.garageNumber'),
  garagePortNumber: i18n.t('singles.garagePortNumber'),
  garagePowerID: i18n.t('singles.garagePowerID'),
  storageNumber: i18n.t('singles.storageNumber'),
  electricCar: i18n.t('singles.electricCar'),

  tapWaterID: i18n.t('singles.tapWaterID'),
  remoteHeatID: i18n.t('singles.remoteHeatID'),
  gasID: i18n.t('singles.gasID'),
  powerCabinetID: i18n.t('singles.powerCabinetNumber'),
  powerCabinetMeasureID: i18n.t('singles.powerCabinetMeasureID'),
});
