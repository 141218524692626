import { AptlyUnitTemplate } from '@aptly-as/types';
import handleError from '../../../containers/Error/handleError';
import { reach } from '../../../libraries/reach/reach';
import { IUseSortableData } from './unit-template.utils';

interface ToBody {
  index: number;
  category: string;
  section?: string;
}

const createMovePath = (unitTemplatePath: string, from: IUseSortableData) => {
  let path = unitTemplatePath;
  if (from.category) {
    path += `/categories/${from.category._id}`;
  }
  if (from.section) {
    path += `/sections/${from.section._id}`;
  }
  if (from.package) {
    path += `/packages/${from.package._id}`;
  } else if (from.product) {
    path += `/products/${from.product._id}`;
  }
  return path;
};

export async function patchUnitTemplateMove(unitTemplatePath: string, from: IUseSortableData, body: ToBody) {
  try {
    const path = createMovePath(unitTemplatePath, from);
    return await reach.api<AptlyUnitTemplate>(`${path}/move`, { method: 'PATCH', body });
  } catch (e) {
    handleError(e);
  }
}

interface OrderBody {
  _order_categories?: string[];
  _order_sections?: string[];
  _order_products?: string[];
  _product_order?: string[];
}

const createOrderPath = (unitTemplatePath: string, from: IUseSortableData) => {
  let path = unitTemplatePath;
  if (from.category && from.section) {
    path += `/categories/${from.category._id}`;
  }
  if (from.section && (from.package || from.product)) {
    path += `/sections/${from.section._id}`;
  }
  if (from.package && from.product) {
    path += `/packages/${from.package._id}`;
  }
  return path;
};

export async function patchUnitTemplateOrder(
  unitTemplatePath: string,
  from: IUseSortableData,
  body: OrderBody
) {
  try {
    const path = createOrderPath(unitTemplatePath, from);
    return await reach.api<AptlyUnitTemplate>(path, { method: 'PATCH', body });
  } catch (e) {
    handleError(e);
  }
}
