import { useCallback, useContext, useState } from 'react';
import { AptlyProduct } from '@aptly-as/types';
import { EditIconButton, OpenInNewIconButton } from '../../components/actions/icons/Icons';
import { IGridActionProps } from '../../components/Search/search-data-grid/data-grid.actions';
import Dialog from '../../mui/Dialog';
import { SearchCrudContext } from '../../components/Search/search.utils';
import ProductEdit from './ProductEdit';

interface State {
  product: null | AptlyProduct;
  index: number;
}

interface Props extends IGridActionProps {
  update?: boolean;
}

export function DataGridEditProduct({ params, update }: Props) {
  const {
    items,
    actions: { splice },
  } = useContext(SearchCrudContext);
  const { id } = params;
  const [state, setState] = useState<State>({
    product: null,
    index: -1,
  });

  const handleOnClosed = useCallback(
    (product: AptlyProduct, index: number) => {
      splice(index, 1, product);
    },
    [splice]
  );

  const handleOnDelete = useCallback(() => {
    setState((s) => {
      if (s.index > -1) {
        splice(s.index, 1);
      }
      return { product: null, index: -1 };
    });
  }, [splice]);

  const handleOnShow = useCallback(() => {
    setState((s) => {
      if (s.product) {
        handleOnClosed(s.product, s.index);
        return { product: null, index: -1 };
      }
      const index = items.findIndex((x) => x._id === id);
      return { product: items[index], index };
    });
  }, [handleOnClosed, items, id]);

  const handleOnChange = useCallback((product: any) => {
    setState((s) => ({ ...s, product }));
  }, []);

  return (
    <>
      {update ? <EditIconButton onClick={handleOnShow} /> : <OpenInNewIconButton onClick={handleOnShow} />}
      <Dialog open={Boolean(state.product)} maxWidth="md" onClose={handleOnShow}>
        {state.product && (
          <ProductEdit
            product={state.product}
            onChange={handleOnChange}
            onDelete={handleOnDelete}
            onClose={handleOnShow}
            update={update}
          />
        )}
      </Dialog>
    </>
  );
}
