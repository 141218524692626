import { IUseFieldRet } from '@ewb/reach-react';
import Typography from '@mui/material/Typography';
import useCrudImageMedia from '../../../hooks/useCrudImageMedia';
import { ISimpleComponentProps } from './simple-components.utils';
import DropZone from '../../../libraries/react-dropzone/DropZone';
import { IApiError } from '../../ApiError';
import { ICrudFieldData } from '../../crud/utils/crud.utils';

export interface SimpleMediaProps<T extends object> extends ISimpleComponentProps<T, any> {
  crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>;
  fieldKey: keyof T;
  simple?: boolean;
}

/**
 * @deprecated use SimpleMediaUpload instead
 */
export default function SimpleImageMediaUpload<T extends object>({
  crud,
  field,
  onChange,
  simple,
  fieldKey,
}: SimpleMediaProps<T>) {
  const { value, label, image } = field;
  if (!image) {
    throw new Error('SimpleMediaUpload was used with media without values');
  }

  const src = value && typeof value === 'object' ? value.src : value;
  const { media, handleOnChange, handleOnCrop, handleOnDelete } = useCrudImageMedia<any>(
    crud,
    field as any,
    onChange,
    fieldKey,
    image.mediaKey
  );

  return (
    <>
      {simple && <Typography variant="subtitle1">{label}</Typography>}
      <DropZone
        src={src}
        media={media}
        onDelete={handleOnDelete}
        onDrop={handleOnChange}
        onCrop={handleOnCrop}
        {...image}
      />
    </>
  );
}
