import { useCallback, useState } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { ITextFieldProps, TextField } from '../../../mui/Input';

export function useDataGridEditText(params: GridRenderEditCellParams) {
  return <DataGridTextEdit params={params} />;
}

export type IDataGridTextEditTextProps = Omit<
  ITextFieldProps,
  'variant' | 'value' | 'onChange' | 'onBlur' | 'autoFocus' | 'onKeyDown' | 'fullWidth'
>;
interface DataGridTextEditProps extends IDataGridTextEditTextProps {
  params: GridRenderEditCellParams;
}

export function DataGridTextEdit({ params, ...rest }: DataGridTextEditProps) {
  const { id, api, field } = params;
  const [value, setValue] = useState(params.value || '');

  const handleOnChange = useCallback((e: any) => {
    setValue(e.target.value);
  }, []);

  const handleOnBlur = useCallback(() => {
    api.setEditCellValue({ id, field, value });
    api.stopCellEditMode({ id, field });
  }, [api, id, field, value]);

  const handleOnKeyDown = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        handleOnBlur();
      }
    },
    [handleOnBlur]
  );

  return (
    <Grid container alignItems="center" style={{ padding: '0 10px' }}>
      <TextField
        type="text"
        variant="standard"
        size="small"
        value={value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        autoFocus
        onKeyDown={handleOnKeyDown}
        fullWidth
        {...rest}
      />
    </Grid>
  );
}
