import {
  AptlyDocument,
  AptlyDocumentAccess,
  AptlyDocumentType,
  AptlyErrorBody,
  AptlyScopes,
} from '@aptly-as/types';
import { ReachError } from '@ewb/reach-react';
import React, { useCallback, useMemo, useState } from 'react';
import Accordion from '../../components/Accordion';
import createError from '../../containers/Error/createError';
import { createModal } from '../../containers/Modal/ModalContext';
import { busyNotification, successNotification } from '../../containers/Notification/notification.utils';
import i18n from '../../libraries/i18n';
import { reach } from '../../libraries/reach/reach';
import { FileDropzone, IFileDropzoneProps } from '../../libraries/react-dropzone/DropZone';
import { FragmentScope } from '../../libraries/scope/ScopeComponets';
import { AccordionDetails } from '../../mui/Accordion';
import Grid from '../../mui/Grid';
import Select, { SelectOption } from '../../mui/Select';
import Typography from '../../mui/Typography';
import { documentAccessOptions, DocumentTypeOption, sortedDocumentTypeOptions } from './document.utils';
import DocumentError from './DocumentError';

interface Props extends Omit<IFileDropzoneProps, 'onDrop'> {
  title?: string;
  endpoint: string;
  access?: AptlyDocumentAccess;
  simple?: boolean;
  path?: string;
  options?: DocumentTypeOption[];
  accessOptions?: SelectOption[];
  onClose?: () => void;
  onDrop?: (docs: AptlyDocument[]) => void;
  defaultType?: AptlyDocumentType;
}

type FileError = File & { error: string; detail?: string; path?: string };

export default function DropDocuments({
  label = i18n.t('actions.upload'),
  onDrop = () => {
    throw createError('DropDocuments: Missing on drop function');
  },
  endpoint,
  simple,
  path = '/documents',
  options,
  defaultType = options ? options[0].value : AptlyDocumentType.Document,
  access: propsAccess = AptlyDocumentAccess.User,
  accessOptions = documentAccessOptions(),
  onClose,
  ...rest
}: Props) {
  const [failedFiles, setFailedFiles] = useState<FileError[]>([]);
  const [left, setLeft] = useState(0);
  const [type, setType] = useState<AptlyDocumentType>(defaultType);
  const [access, setAccess] = useState<AptlyDocumentAccess>(propsAccess);
  const _options = useMemo(() => options || sortedDocumentTypeOptions(), [options]);

  const handleOnDrop = useCallback(
    (files: File[]) => {
      setFailedFiles([]);
      setLeft(files.length);
      handleDrop(endpoint, {
        _type: type,
        access,
        cb: onDrop,
        onUpload: (failed, error) => {
          if (error) {
            const eMessage = error.body?.title || error.message;
            const detail = error.body?.detail || '';
            setFailedFiles((f) => [...f, { ...failed, error: eMessage, detail }]);
            if (eMessage.match(/(cantOpenPDFToWrite|fileNotValid)/)) {
              createModal(<DocumentError fileName={failed.name} />, {
                width: 'sm',
              });
            }
          }
          setLeft((n) => n - 1);
        },
        onClose,
        path,
      })(files);
    },
    [endpoint, type, access, onDrop, path, onClose]
  );

  const dropLabel = label + ' ' + _options.find((x) => x.value === type)!.label;

  const dropZone = React.useMemo(() => {
    return <FileDropzone disabled={left !== 0} {...rest} onDrop={handleOnDrop} label={dropLabel} />;
  }, [left, rest, handleOnDrop, dropLabel]);

  const errors = React.useMemo(
    () => (
      <>
        <Typography variant="subtitle1">{i18n.t('statuses.failedToUpload')}:</Typography>
        <Typography>
          {failedFiles.map((x, i) => (
            <React.Fragment key={i}>
              {x.name || x.path}: {x.error} {x.detail}
              <br />
            </React.Fragment>
          ))}
        </Typography>
      </>
    ),
    [failedFiles]
  );

  if (simple) {
    return (
      <div>
        {dropZone}
        {failedFiles.length > 0 && errors}
      </div>
    );
  }

  return (
    <Accordion title={i18n.t('actions.uploadDocuments')}>
      {() => (
        <AccordionDetails>
          <Grid container flexDirection="column" gap={2}>
            {_options.length > 0 && (
              <Select
                label={i18n.t('singles.type')}
                value={type}
                options={_options}
                onChange={(e) => setType(e.target.value as AptlyDocumentType)}
                fullWidth
              />
            )}
            {accessOptions.length > 0 && (
              <FragmentScope scope={AptlyScopes.Admin}>
                <Select
                  label={i18n.t('singles.accessLevel')}
                  value={access}
                  options={accessOptions}
                  onChange={(e) => setAccess(Number(e.target.value) as AptlyDocumentAccess)}
                  fullWidth
                />
              </FragmentScope>
            )}
            {dropZone}
            {left ? `${i18n.t('statuses.uploading')} ${left}` : null}
            {failedFiles.length > 0 && errors}
          </Grid>
        </AccordionDetails>
      )}
    </Accordion>
  );
}

interface HandleDropProps {
  _type: AptlyDocumentType;
  access: AptlyDocumentAccess;
  cb: (docs: AptlyDocument[]) => void;
  onUpload: (failToUploadFile: File, e?: ReachError<AptlyErrorBody>) => void;
  onClose?: () => void;
  path?: string;
}

function handleDrop(
  url: string,
  { _type, access, cb, onUpload, onClose, path = '/documents' }: HandleDropProps
) {
  return async (files: File[]) => {
    const busy = busyNotification(i18n.t('statuses.uploading'));
    const docs: AptlyDocument[] = [];
    let hasError = false;
    try {
      for (const file of files) {
        try {
          const doc = await reach.api<AptlyDocument>(`${url}${path}`, {
            method: 'POST',
            type: 'multipart/form-data',
            body: { file, access, _type },
          });
          docs.push(doc);
          onUpload(file);
        } catch (e: any) {
          hasError = true;
          onUpload(file, e);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      busy();
      successNotification(i18n.t('statuses.documentIsUploaded'));
      if (docs.length > 0) {
        cb(docs);
      }
      if (!hasError && typeof onClose === 'function') {
        onClose();
      }
    }
  };
}
