import SearchViewComposer from '../../deprecated/Search/SearchViewComposer';
import useGetApiUrl from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { intlDateTimeFormat } from '../../libraries/intl';
import { TableCell, TableHead, TableRow } from '../../mui/Table';
import { TextField } from '../../mui/Input';

export default function ProjectEmailsStatus() {
  const endpoint = useGetApiUrl('project') + '/emails';

  return (
    <SearchViewComposer
      title={i18n.t('singles.emails')}
      searchFields={[
        {
          type: 'textfield',
          name: 'email',
          element: <TextField type="email" label={i18n.t('singles.email')} fullWidth />,
        },
      ]}
      tableHead={
        <TableHead>
          <TableRow>
            <TableCell>{i18n.t('singles.status')}</TableCell>
            <TableCell>{i18n.t('singles.email')}</TableCell>
            <TableCell>{i18n.t('statuses.created')}</TableCell>
            <TableCell>Template</TableCell>
          </TableRow>
        </TableHead>
      }
      endpoint={endpoint}
    >
      {(emails: any[]) => {
        return emails.map((email) => (
          <TableRow key={email.id}>
            <TableCell>{email.event}</TableCell>
            <TableCell>{email.recipient}</TableCell>
            <TableCell>{intlDateTimeFormat(email.timestamp * 1000)}</TableCell>
            <TableCell>{email.template?.name || 'N/A'}</TableCell>
          </TableRow>
        ));
      }}
    </SearchViewComposer>
  );
}
