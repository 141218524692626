import { AptlyProject } from '@aptly-as/types';
import { useCallback } from 'react';
import simpleRequest from '../../libraries/fetch/simpleRequest';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';

export function useProjectFavorite() {
  const endpoint = useApiUrl(SlugLevel.Organization, 'projects');

  return useCallback(
    async (project: AptlyProject) => {
      await simpleRequest({
        endpoint: `${endpoint}/${project._id}/favorite`,
        method: 'POST',
      });
    },
    [endpoint]
  );
}
