import styled from 'styled-components';
import { useMobile } from '../hooks/useMobile';
import UserMenu from '../models/User/UserMenu';
import { cssSpacing } from '../utils/spacing';
import { intenseShadow } from '../utils/shadows';
import ComponentLink from '../libraries/router/ComponentLink';
import { MOBILE_BREAK } from '../utils/themes';
import AptlyLogo from './AptlyLogo';
import Changelogfy from '../libraries/changelogfy/Changelogfy';
import LanguageSelect from './LanguageSelect';
import { Fragment, ReactNode } from 'react';

type HeaderProps = {
  head: ReactNode[];
};

export default function Header(props: HeaderProps) {
  const isMobile = useMobile();
  const { head } = props;

  return (
    <Wrapper>
      <HeaderColumn>
        {(!isMobile || head.length <= 1) && (
          <>
            <ComponentLink to="/" key="tp">
              <AptlyLogo small isMobile={isMobile} />
            </ComponentLink>
            <Divider />
          </>
        )}
        {head.map((child, index) => (
          <Fragment key={index}>
            {child}
            {index + 1 < head.length && <Divider key={index} />}
          </Fragment>
        ))}
      </HeaderColumn>
      <HeaderColumn>
        {/*<Recruit />*/}
        {!isMobile && <LanguageSelect />}
        {!isMobile && <Changelogfy />}
        <UserMenu />
      </HeaderColumn>
    </Wrapper>
  );
}

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  grid-area: header;
  align-items: center;
  box-shadow: ${intenseShadow};
  background: ${(props) => props.theme.palette.background.default};
  position: fixed;
  z-index: 1250;
  left: 0;
  top: 0;
  right: 0;
  height: 64px;
`;

const HeaderColumn = styled.div`
  display: flex;
  padding: 0 calc(${cssSpacing} / 2);
  align-items: center;

  > * {
    padding: 0 calc(${cssSpacing} / 2);
  }
  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    > * {
      padding: 0 calc(${cssSpacing} / 4);
    }
  }
`;

const Divider = styled.div`
  height: 2rem;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  padding-left: 0;
  margin-left: calc(${cssSpacing} / 2);
`;
