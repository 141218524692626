import { AptlyRecommendation, AptlyScopes } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import useScopeLevel from '../../libraries/scope/useScopeLevel';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import {
  dataGridLevelColumn,
  dataGridSearchNameColumn,
  dataGridSearchImageColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import { recommendationSchema } from './recommendation.schema';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridCopyAction,
  DataGridDeleteAction,
  DataGridEditAction,
} from '../../components/Search/search-data-grid/data-grid.actions';
import useScopeProps from '../../libraries/scope/useScopeProps';

const columns = (): GridColDef[] => [
  dataGridSearchImageColumn('image'),
  dataGridSearchNameColumn(),
  dataGridLevelColumn(),
  dataGridActionProps((params) => (
    <DataGridActionWrapper>
      <DataGridEditAction params={params} checkIfDisabledInProject />
      <DataGridCopyAction<AptlyRecommendation>
        params={params}
        preCopy={(data) => ({
          name: `${data.name} - kopi`,
          title: data.title,
          color: data.color,
          image: data.image,
          imageMedia: data.imageMedia,
          text: data.text,
        })}
      />
      <DataGridDeleteAction params={params} checkIfDisabledInProject />
    </DataGridActionWrapper>
  )),
];

export default function Recommendations() {
  const path = useApiUrl(SlugLevel.Project, 'recommendations');
  const scope = useScopeProps({
    project: AptlyScopes.ProjectRecommendations,
    organization: AptlyScopes.OrganizationRecommendations,
    base: AptlyScopes.Admin,
  });

  return (
    <Search
      path={path}
      patchPath={toSearchPatchPath('recommendations')}
      level={useScopeLevel()}
      scope={scope}
      header={{ title: i18n.t('singles.recommendations') }}
      post={{ title: i18n.t('singles.recommendations') }}
      patch={{ title: (data) => String(data.name) }}
      schema={recommendationSchema()}
      fields={['name', 'color', 'title', 'text', 'image']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name', archived: false, level: 'project' } }}
      buttonActions={() => [<SearchCreateAction key="create" />]}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns()} />}
    </Search>
  );
}
