import { AptlyAddress, AptlyFieldType } from '@aptly-as/types';
import i18n from '../../../libraries/i18n';
import { acceptJson } from '../../../libraries/react-dropzone/drop-zone.utils';
import { ICrudField, ICrudSchema } from '../utils/crud.utils';
import { schemaFile, schemaName } from './fields.schema';

export interface ICrudImport {
  name: string;
  file?: File | null;
}

export const importSchema = (): ICrudSchema<ICrudImport> => ({
  name: schemaName({ autoFocus: true }),
  file: schemaFile(acceptJson, { required: true }),
});

export interface AptlyAddressBillingSchema {
  'billing.line1'?: string;
  'billing.line2'?: string;
  'billing.zip'?: string;
  'billing.city'?: string;
  'billing.country'?: string;
}

export interface AptlyAddressSchema extends AptlyAddressBillingSchema {
  _id: string;
  'shipping.line1'?: string;
  'shipping.line2'?: string;
  'shipping.zip'?: string;
  'shipping.city'?: string;
  'shipping.country'?: string;
}

export const schemaAddressLine1 = <T extends object>(
  override: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string> => ({
  type: AptlyFieldType.Text,
  label: i18n.t('address.line1'),
  defaultValue: '',
  autoComplete: 'address-line1',
  ...override,
});
export const schemaAddressLine2 = <T extends object>(
  override: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string> => ({
  type: AptlyFieldType.Text,
  label: i18n.t('address.line2'),
  defaultValue: '',
  autoComplete: 'address-line2',
  ...override,
});
export const schemaAddressZip = <T extends object>(
  override: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string> => ({
  type: AptlyFieldType.Text,
  label: i18n.t('address.zip'),
  defaultValue: '',
  autoComplete: 'postal-code',
  ...override,
});
export const schemaAddressCity = <T extends object>(
  override: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string> => ({
  type: AptlyFieldType.Text,
  label: i18n.t('address.city'),
  defaultValue: '',
  autoComplete: 'address-level1',
  ...override,
});
export const schemaAddressCountry = <T extends object>(
  override: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string> => ({
  type: AptlyFieldType.Text,
  label: i18n.t('address.country'),
  defaultValue: '',
  autoComplete: 'country',
  ...override,
});

export const addressSchema = (): ICrudSchema<AptlyAddressSchema> => ({
  'shipping.line1': schemaAddressLine1(),
  'shipping.line2': schemaAddressLine2(),
  'shipping.zip': schemaAddressZip(),
  'shipping.city': schemaAddressCity(),
  'shipping.country': schemaAddressCountry(),
  'billing.line1': schemaAddressLine1(),
  'billing.line2': schemaAddressLine2(),
  'billing.zip': schemaAddressZip(),
  'billing.city': schemaAddressCity(),
  'billing.country': schemaAddressCountry(),
});

export const toAddressSchemaData = (
  _id: string,
  address?: AptlyAddress,
  key = 'billing'
): AptlyAddressSchema => ({
  _id,
  [`${key}.line1`]: address?.line1 || '',
  [`${key}.line2`]: address?.line2 || '',
  [`${key}.zip`]: address?.zip || '',
  [`${key}.city`]: address?.city || '',
  [`${key}.country`]: address?.country || '',
});
