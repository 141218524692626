import { AptlyDraftJS, AptlyMarkdown } from '@aptly-as/types';
import { IS_DEV } from '../env';
import { draftToHtml, isStringDraftJS } from '../libraries/draft-js';
import { parseMarkdown } from '../libraries/markdown';

export function setDangerouslyHtml(text: AptlyMarkdown | AptlyDraftJS): { __html: string } {
  try {
    if (isStringDraftJS(text)) {
      return { __html: draftToHtml(text) };
    }
    return {
      __html: parseMarkdown(text) as string,
    };
  } catch (e) {
    if (IS_DEV) {
      return { __html: text };
    }
    return { __html: '' };
  }
}
