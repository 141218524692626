import { AptlyProducer, AptlyProducerTrademark, AptlyScopes } from '@aptly-as/types';
import MenuIcon from '@mui/icons-material/Menu';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { EditIconButton } from '../../components/actions/icons/Icons';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  DataGridEditAction,
  IGridActionProps,
} from '../../components/Search/search-data-grid/data-grid.actions';
import { useDataGridEditModal } from '../../components/Search/search-data-grid/data-grid.hooks';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import {
  dataGridSearchNameColumn,
  dataGridSearchTagsColumn,
  dataGridSearchTextColumn,
  dataGridSearchImageColumn,
  dataGridActions,
  dataGridSearchMediaColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import Dialog, { ModalActions } from '../../mui/Dialog';
import { producerSchema, producerTrademarkSchema } from './producer.schema';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import { toSearchPatchPath } from '../../components/Search/search.utils';

const columns = (): GridColDef[] => [
  dataGridSearchImageColumn('logo'),
  dataGridSearchNameColumn(),
  dataGridSearchTextColumn('email', i18n.t('singles.email'), { type: 'email' }),
  dataGridSearchTagsColumn(),
  dataGridActionProps((params) => <ProducerActions params={params} />, {
    width: 300,
  }),
];

function Producers() {
  const path = useApiUrl(SlugLevel.Base, 'producers');

  return (
    <Search
      path={path}
      patchPath={toSearchPatchPath('producers')}
      scope={AptlyScopes.AdminProducers}
      header={{ title: i18n.t('singles.producers') }}
      post={{ title: i18n.t('singles.producers') }}
      patch={{ title: i18n.t('singles.producers') }}
      bulk={{ title: i18n.t('singles.producers'), fields: ['tags'] }}
      schema={producerSchema()}
      fields={['name', 'email', 'url', 'tags', 'logo', 'featured']}
      reach={{ query: { sort: 'name', $populate: 'tags:_id,name', archived: false } }}
      buttonActions={() => [<SearchCreateAction key="create" />]}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns()} />}
    </Search>
  );
}
function ProducerActions({ params }: { params: GridRenderCellParams }) {
  return (
    <DataGridActionWrapper>
      <ProducerTrademarks params={params} />
      <DataGridEditAction params={params} />
      <DataGridDeleteAction params={params} />
    </DataGridActionWrapper>
  );
}
function ProducerTrademarks({ params }: IGridActionProps) {
  const { state, onShow, onChange } = useDataGridEditModal<AptlyProducer>(params);
  const handleOnChange = useCallback(
    (trademarks: AptlyProducerTrademark[]) => {
      onChange((data) => ({ ...(data as AptlyProducer), trademarks }));
    },
    [onChange]
  );

  return (
    <>
      <EditIconButton onClick={onShow} icon={<MenuIcon />} title={i18n.t('singles.trademarks')} />
      <Dialog open={Boolean(state.data)} maxWidth="md" onClose={onShow}>
        {state.data && <ModalContent producer={state.data} onChange={handleOnChange} />}
        <ModalActions>
          <ActionButtons onClose={onShow} submitType="button" cancelLabel={i18n.t('actions.close')} />
        </ModalActions>
      </Dialog>
    </>
  );
}

interface IModalContentProps {
  producer: AptlyProducer;
  onChange: (trademarks: AptlyProducerTrademark[]) => void;
}

function ModalContent({ producer, onChange }: IModalContentProps) {
  const path = useApiUrl(SlugLevel.Base, `producers/${producer._id}/trademarks`);
  const schema = useMemo(producerTrademarkSchema, []);
  const columns = useMemo(
    (): GridColDef[] => [
      dataGridSearchMediaColumn('logo'),
      {
        type: 'string',
        field: 'name',
        headerName: i18n.t('singles.name'),
        flex: 1,
      },
      dataGridActions(),
    ],
    []
  );

  return (
    <Search<AptlyProducerTrademark>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.AdminProducers}
      header={{ title: i18n.t('singles.trademarks') }}
      post={{ title: i18n.t('singles.trademarks') }}
      patch={{ title: i18n.t('singles.trademarks') }}
      schema={schema}
      fields={['name', 'logo']}
      options={{ disableBulk: true }}
      reach={{ limit: 999 }}
      buttonActions={() => [<SearchCreateAction key="create" />]}
      onChange={onChange}
    >
      {() => <SearchDataGrid columns={columns} pageSize={10} />}
    </Search>
  );
}

export default Producers;
