import FileSaver from 'file-saver';
import apiRequest from './fetch/apiRequest';
import i18n from './i18n';
import handleError from '../containers/Error/handleError';
import { successNotification } from '../containers/Notification/notification.utils';

export async function downloadDocument(document: {
  name?: string;
  downloadToken?: string;
  external_url?: string;
}) {
  if (document.downloadToken) {
    return filesSaver({
      endpoint: `documents/${document.downloadToken || ''}`,
      fileName: document.name,
    });
  } else if (document.external_url) {
    window.open(document.external_url, '_blank');
  }
}

type Opts = {
  endpoint: string;
  fileName?: string;
  data?: object;
};

async function filesSaver(opts: Opts) {
  try {
    const resource: Response = await apiRequest(opts.endpoint, {
      noJSON: true,
      notificationMessage: `${i18n.t('statuses.fetchingFile')}...`,
      data: opts.data,
    });

    const fileName = getFilenameFromContentType(resource.headers.get('Content-Type'));

    FileSaver.saveAs(await resource.blob(), fileName || opts.fileName || 'File');

    successNotification(i18n.t('statuses.downloadingFile'));
  } catch (e) {
    handleError(e);
  }
}

function getFilenameFromContentType(contentType?: string | null) {
  if (contentType) {
    const filenameRegex = /name[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentType);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return '';
}

export default filesSaver;
