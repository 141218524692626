import {
  AptlyAppImplementation,
  AptlyFieldType,
  AptlyProject,
  AptlyProjectStatus,
  AptlyScopes,
  AptlyUser,
} from '@aptly-as/types';
import { DotObject } from '@ewb/reach-react';
import {
  schemaEmail,
  schemaFile,
  schemaMediaImage,
  schemaName,
  schemaPhone,
} from '../../components/crud/schema/fields.schema';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { acceptImages, acceptJson } from '../../libraries/react-dropzone/drop-zone.utils';
import Scope from '../../libraries/scope/Scope';
import { SelectOption } from '../../mui/Select';

export const projectStatusOptions = (withNone?: boolean) => {
  const opts: SelectOption[] = [
    {
      value: AptlyProjectStatus.Template,
      label: i18n.t('singles.template'),
    },
    {
      value: AptlyProjectStatus.NotStarted,
      label: i18n.t('statuses.notStarted'),
    },
    {
      value: AptlyProjectStatus.Started,
      label: i18n.t('statuses.started'),
    },
    {
      value: AptlyProjectStatus.Completed,
      label: i18n.t('statuses.completed'),
    },
  ];
  if (withNone) {
    opts.unshift({
      value: '',
      label: i18n.t('singles.all'),
    });
  }
  return opts;
};

type DotFields = 'theme';
export type IProjectSchema = DotObject<Pick<AptlyProject, DotFields>> &
  Omit<AptlyProject, DotFields> & {
    template?: string | null;
    users?: string[];
    'address.street'?: string;
    'address.zip'?: string;
    'address.city'?: string;
  };

export const projectSchema = (user?: AptlyUser): ICrudSchema<IProjectSchema> => ({
  name: schemaName({ autoFocus: true }),
  status: {
    type: AptlyFieldType.Select,
    defaultValue: AptlyProjectStatus.NotStarted,
    label: i18n.t('singles.status'),
    options: projectStatusOptions(),
  },
  myPageText: {
    type: AptlyFieldType.Markdown,
    defaultValue: '',
    label: i18n.t('paragraphs.myPageText'),
  },
  inviteText: {
    type: AptlyFieldType.TextArea,
    defaultValue: '',
    label: i18n.t('paragraphs.inviteText'),
  },
  theme: {
    type: AptlyFieldType.Object,
    defaultValue: {
      contact: user
        ? {
            name: user.fullName,
            phone: user.phone,
            mail: user.email,
          }
        : {},
      fonts: {},
      images: {},
      palette: {},
    },
    label: '',
  },
  gdprActive: {
    type: AptlyFieldType.Switch,
    defaultValue: false,
    label: 'GDPR',
  },
  pages: {
    type: AptlyFieldType.Reference,
    defaultValue: [],
    label: i18n.t('singles.informationPages'),
    useReference: () => {
      const endpoint = useApiUrl(SlugLevel.Project, 'pages');
      return {
        endpoint,
        multiple: true,
        queryKey: '$name',
        labelKey: 'name',
        query: { as: 'array', select: '_id,name', limit: 5, sort: 'name', level: 'project', archived: false },
      };
    },
  },
  'theme.contact.name': schemaName({ defaultValue: user?.fullName || '', required: false }),
  'theme.contact.mail': schemaEmail({ defaultValue: user?.email || '', required: false }),
  'theme.contact.phone': schemaPhone({ defaultValue: user?.phone || '' }),
  'theme.contact.complaintEmail': schemaEmail({
    label: i18n.t('singles.complaint'),
    required: false,
  }),
  'theme.images.logo': {
    type: AptlyFieldType.Image,
    defaultValue: null,
    label: `${i18n.t('paragraphs.projectLogo')} (2:1)`,
    image: { mediaKey: 'theme.images.logoMedia', width: 160, height: 100, accept: acceptImages },
  },
  'theme.images.logoMedia': schemaMediaImage(),
  'theme.images.hero': {
    type: AptlyFieldType.Image,
    defaultValue: null,
    label: i18n.t('singles.backgroundImage') + ' (3:1)',
    image: { mediaKey: 'theme.images.heroMedia', width: 540, height: 320, accept: acceptImages },
  },
  'theme.images.heroMedia': schemaMediaImage(),
  'theme.images.fallback': {
    type: AptlyFieldType.Image,
    defaultValue: null,
    label: `${i18n.t('paragraphs.productsWithoutImage')} (1:2)`,
    image: { mediaKey: 'theme.images.fallbackMedia', width: 320, accept: acceptImages },
  },
  'theme.images.fallbackMedia': schemaMediaImage(),
  template: {
    type: AptlyFieldType.Reference,
    defaultValue: null,
    label: i18n.t('singles.template'),
    useReference: () => ({
      endpoint: useApiUrl(SlugLevel.Organization, 'projects'),
      queryKey: '$name',
      labelKey: 'name',
      query: { as: 'array', select: '_id,name', limit: 5, sort: 'name', archived: false },
    }),
  },
  users: {
    type: AptlyFieldType.Reference,
    label: i18n.t('singles.users'),
    defaultValue: [],
    renderValidate: () => Scope.crud(AptlyScopes.OrganizationMembers, 'R'),
    useReference: () => ({
      endpoint: useApiUrl(SlugLevel.Organization, 'users'),
      queryKey: '$fullName',
      labelKey: 'fullName',
      multiple: true,
      query: { as: 'array', select: '_id,fullName', limit: 5, sort: 'fullName' },
    }),
  },
  number: {
    type: AptlyFieldType.Text,
    label: i18n.t('singles.projectNumber'),
    defaultValue: '',
  },
  developer: {
    type: AptlyFieldType.Text,
    label: i18n.t('singles.housingDeveloper'),
    defaultValue: '',
  },
  field: {
    type: AptlyFieldType.Text,
    label: i18n.t('singles.constructionField'),
    defaultValue: '',
  },
  address: {
    type: AptlyFieldType.Object,
    label: i18n.t('singles.address'),
    defaultValue: {},
  },
  'address.street': {
    type: AptlyFieldType.Text,
    label: i18n.t('singles.street'),
    defaultValue: '',
  },
  'address.zip': {
    type: AptlyFieldType.Text,
    label: i18n.t('singles.zipCode'),
    defaultValue: '',
  },
  'address.city': {
    type: AptlyFieldType.Text,
    label: i18n.t('singles.city'),
    defaultValue: '',
  },
  invoiceEmail: schemaEmail({ required: false, label: i18n.t('singles.invoiceEmail') }),
  paymentApp: {
    type: AptlyFieldType.Reference,
    label: i18n.t('singles.paymentApp'),
    defaultValue: null,
    useReference: () => ({
      endpoint: useApiUrl(SlugLevel.Organization, 'apps'),
      queryKey: '$name',
      labelKey: 'name',
      query: {
        as: 'array',
        select: '_id,name',
        limit: 5,
        sort: 'name',
        implements: AptlyAppImplementation.Payment,
      },
    }),
  },
  signApp: {
    type: AptlyFieldType.Reference,
    defaultValue: null,
    label: i18n.t('singles.app'),
    useReference: () => ({
      endpoint: 'api/v1/apps',
      queryKey: '$name',
      labelKey: 'name',
      query: { select: '_id,name', limit: 5, as: 'array', implements: AptlyAppImplementation.Sign },
    }),
  },
  signMembers: {
    type: AptlyFieldType.Reference,
    defaultValue: [],
    label: i18n.t('singles.users'),
    useReference: () => ({
      multiple: true,
      endpoint: useApiUrl(SlugLevel.Organization, 'users'),
      queryKey: '$fullName',
      labelKey: 'fullName',
      query: { select: '_id,fullName', limit: 5, as: 'array' },
    }),
  },
});

export const projectImportSchema = (): ICrudSchema<IProjectSchema & { file: File | null }> => ({
  name: schemaName(),
  file: schemaFile(acceptJson),
});

export default projectSchema;
