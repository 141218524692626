import { useContext, useEffect } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { productGroupProps } from '../../../models/Product/product.utils';
import { SearchCrudContext } from '../search.utils';

interface Props<T extends object> {
  params: GridRenderEditCellParams;
  keys: (keyof T)[];
}

export function DataGridFieldClickEdit<T extends object>({ params, keys }: Props<T>) {
  const { id, api, field } = params;
  const {
    items,
    actions: { spawnPatch },
  } = useContext(SearchCrudContext);

  useEffect(() => {
    const index = items.findIndex((x) => x._id === id);
    spawnPatch(items[index], index, keys, {
      contentProps: {
        accordionProps: { expanded: true },
        groupProps: productGroupProps,
      },
    });
    api.startCellEditMode({ id, field });
  }, [api, items, id, field]);

  return null;
}
