import styled, { createGlobalStyle, css } from 'styled-components';
import { MOBILE_BREAK, SIDE_MENU_DESKTOP_SIZE, SIDE_MENU_MODILE_SIZE } from '../utils/themes';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'IBM Plex Sans', Roboto, Arial, sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: subpixel-antialiased;

    &.freeze {
      overflow: hidden;
    }
  }
  
  a {
      color: ${(props) => props.theme.palette.text.primary};
  }
  
  .MuiDialogContent-root {
    padding: 12px 24px !important;
  }

  .MuiPickersToolbarText-toolbarTxt {
    font-size: 2rem !important;
  }

  .MuiInputBase-inputMultiline {
    padding: 0 !important;
  }

  .pac-container {
    z-index: 10000000;
  }
  
  .toastui-editor-contents table {
    width: 100% !important;
  }
  .toastui-editor-defaultUI-toolbar {
      padding: 0 !important;
  }
  .toastui-editor-main p {
      font-size: 1rem;
  }
`;

const Layout = styled.div<{ noSidebar?: boolean }>`
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  display: grid;
  grid-template-columns: ${SIDE_MENU_DESKTOP_SIZE} 1fr;
  grid-template-rows: 64px auto;
  grid-template-areas:
    'header  header'
    'sidebar main';

  ${(props) =>
    props.noSidebar &&
    css`
      grid-template-areas:
        'header  header'
        'main main';
    `};

  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    grid-template-columns: ${SIDE_MENU_MODILE_SIZE} 1fr;
  }
`;

export default Layout;
export { GlobalStyles };
