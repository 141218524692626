import { ImgHTMLAttributes } from 'react';
import { AptlyMediaSrc } from '@aptly-as/types';

export const PLACEHOLDER_IMAGE: AptlyMediaSrc = {
  _id: 'iamge-id',
  url: 'https://res.cloudinary.com/oyvindhermansen/image/upload/v1561016972/empty_preview.png',
  alt: 'Mangler bilde',
  width: 100,
  height: 100,
  name: 'Mangler bilde',
  mime: 'image/png',
  src: 'v1561016972',
};

export type ImageSrcSet = Pick<
  ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'srcSet' | 'sizes' | 'loading' | 'alt' | 'width' | 'height' | 'style' | 'title'
>;
export type IMediaWidth = 40 | 80 | 160 | 320 | 540 | 720 | 1080;
export interface ImageSize {
  width: number;
  width2x: number; // For css media pixel density
  maxWidth?: number;
}

const IMAGE_SIZES_LARGE: ImageSize[] = [
  { width: 540, width2x: 1080, maxWidth: 500 },
  { width: 720, width2x: 1440, maxWidth: 720 },
  { width: 1080, width2x: 2160, maxWidth: 1080 },
  { width: 1440, width2x: 2880, maxWidth: 1440 },
  { width: 1920, width2x: 3840, maxWidth: 1920 },
  { width: 2560, width2x: 3840 },
];

const IMAGE_SIZES_MEDIUM: ImageSize[] = [
  { width: 540, width2x: 1080, maxWidth: 500 },
  { width: 640, width2x: 1280, maxWidth: 720 },
  { width: 720, width2x: 1440 },
];

const IMAGE_SIZES_SMALL: ImageSize[] = [
  { width: 320, width2x: 540, maxWidth: 500 },
  { width: 540, width2x: 1080 },
];

const IMAGE_SIZES_TINY: ImageSize[] = [{ width: 320, width2x: 640 }];
const IMAGE_SIZES_PREVIEW: ImageSize[] = [{ width: 160, width2x: 320 }];
const IMAGE_SIZES_LARGE_ICON: ImageSize[] = [{ width: 80, width2x: 160 }];
const IMAGE_SIZES_ICON: ImageSize[] = [{ width: 40, width2x: 80 }];

export function getImageSet(width: IMediaWidth): ImageSize[] {
  switch (width) {
    case 1080:
      return IMAGE_SIZES_LARGE;
    case 720:
      return IMAGE_SIZES_MEDIUM;
    case 540:
      return IMAGE_SIZES_SMALL;
    case 320:
      return IMAGE_SIZES_TINY;
    case 160:
      return IMAGE_SIZES_PREVIEW;
    case 80:
      return IMAGE_SIZES_LARGE_ICON;
    case 40:
      return IMAGE_SIZES_ICON;
  }
}
