import Tooltip from '@mui/material/Tooltip';
import { useCallback, useContext } from 'react';
import { Trans } from 'react-i18next';
import { AptlyJobQueue, AptlyUnit, AptlyUnitStatus } from '@aptly-as/types';
import { JobExportDocument } from '../../containers/Job/JobExportDocument';
import useGetApiUrl from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import Grid from '../../mui/Grid';
import { Switch } from '../../mui/Input';
import Typography from '../../mui/Typography';
import { UnitContext } from './UnitContext';

interface Props {
  unit: AptlyUnit;
  disabled?: boolean;
}

export default function UnitCreateCompleteReport({ unit, disabled }: Props) {
  const { setField } = useContext(UnitContext);
  const endpoint = `${useGetApiUrl('project')}/units/${unit._id}/orders/final-report-job`;
  const label = i18n.t('actions.produceCompletedOrder');
  const title =
    unit.status === 'completed' ? i18n.t('statuses.completed') : i18n.t('statuses.missingSignature');

  const handleOnComplete = useCallback(() => {
    setField('status', true)(AptlyUnitStatus.Completed);
  }, [setField]);

  const button = (
    <JobExportDocument
      endpoint={endpoint}
      queue={AptlyJobQueue.Documents}
      label={label}
      jobLabel={label}
      onComplete={handleOnComplete}
      disabled={disabled}
      confirm={
        <Grid container flexDirection="column">
          <Typography gutterBottom>
            <Trans i18nKey="paragraphs.finalReportConfirm">
              Select the documents you want to include in the final report.
            </Trans>
          </Typography>
          <Switch defaultChecked name="reportPdf" label={i18n.t('singles.report') + '.pdf'} />
          <Switch defaultChecked name="reportPerProductCsv" label={i18n.t('singles.report') + '.csv'} />
          <Switch defaultChecked name="reportOrderListCsv" label={i18n.t('singles.orderList') + '.csv'} />
        </Grid>
      }
    />
  );

  if (disabled) {
    return (
      <Tooltip title={title} placement="bottom">
        {button}
      </Tooltip>
    );
  }

  return button;
}
