import { AptlyAlgorithm } from '@aptly-as/types';
import { useContext, useMemo } from 'react';
import { OrganizationContext } from '../Organization/OrganizationContext';
import { ProjectContext } from '../Project/ProjectContext';

export function useAlgorithms(): AptlyAlgorithm[] {
  const organization = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  return useMemo(() => {
    const algorithms: AptlyAlgorithm[] = [];
    if (project.data?.algorithms) {
      algorithms.push(...project.data.algorithms);
    }
    if (organization.data?.algorithms) {
      algorithms.push(...organization.data.algorithms);
    }
    return algorithms;
  }, [organization, project]);
}

export function useAlgorithm(algorithm?: string | AptlyAlgorithm | null): AptlyAlgorithm | undefined {
  const algorithms = useAlgorithms();
  if (!algorithm) return undefined;
  if (typeof algorithm === 'object') {
    return algorithm;
  }
  return algorithms.find((x) => x._id === algorithm) || undefined;
}
