import { AptlyUnitTemplateCategorySectionProduct } from '@aptly-as/types';
import { FocusEvent, MouseEvent, useCallback, useContext } from 'react';
import { CopyIconButton, DeleteIconButton, EditIconButton } from '../../components/actions/icons/Icons';
import ErrorBoundary from '../../components/ErrorBoundary';
import { IProductRowProps, ProductRow } from './product/ProductRow';
import { IUseSimpleDeleteFn } from '../../components/simple/useSimpleDelete';
import { IUseSimplePatchFn } from '../../components/simple/useSimplePatch';
import { ISortableDataProps, useUnitTemplateSortable } from './utils/unit-template.sortable';
import { UnitTemplateContext } from './utils/UnitTemplateContext';

export interface IUnitTemplateProductProps
  extends Omit<ISortableDataProps, 'package'>,
    Pick<IProductRowProps, 'checkbox' | 'assortment'> {
  onDelete: IUseSimpleDeleteFn<AptlyUnitTemplateCategorySectionProduct>;
  onEdit: IUseSimplePatchFn<AptlyUnitTemplateCategorySectionProduct>;
  index: number;
  onCopy: (product: AptlyUnitTemplateCategorySectionProduct, index: number) => void;
  onPatch: (
    product: AptlyUnitTemplateCategorySectionProduct,
    index: number,
    data: Partial<AptlyUnitTemplateCategorySectionProduct>
  ) => void;
}

export function UnitTemplateProduct(props: IUnitTemplateProductProps) {
  const { index, onDelete, onEdit, onCopy, product, section, checkbox, assortment, onPatch } = props;
  const { editProduct } = useContext(UnitTemplateContext);
  const [{ style, ref }, , { attributes, listeners }] = useUnitTemplateSortable(props);
  const handleOnStandardToggle = useCallback(() => {
    onPatch(product, index, { standard: !product.standard });
  }, [product, index, onPatch]);
  const handleOnEditProduct = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      editProduct(product);
    },
    [editProduct, product]
  );
  const handleOnEditAmount = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const amount = Number(e.target.value);
      if (amount === product.amount) return;
      onPatch(product, index, { amount });
    },
    [onPatch, index, product]
  );
  const handleOnEditUnitCost = useCallback(
    (unitCost: number) => {
      if (unitCost === product.unitCost) return;
      onPatch(product, index, { unitCost });
    },
    [onPatch, index, product]
  );

  return (
    <ErrorBoundary>
      <ProductRow
        attributes={attributes}
        listeners={listeners}
        style={style}
        ref={ref}
        product={product}
        standard={section.standard}
        checkbox={checkbox}
        assortment={assortment}
        onEditProduct={handleOnEditProduct}
        onEditAmount={handleOnEditAmount}
        onEditUnitCost={handleOnEditUnitCost}
        onStandardToggle={handleOnStandardToggle}
      >
        <EditIconButton onClick={() => onEdit(product, index)} size="medium" />
        <CopyIconButton onClick={() => onCopy(product, index)} size="medium" />
        <DeleteIconButton onClick={() => onDelete(product, index)} size="medium" />
      </ProductRow>
    </ErrorBoundary>
  );
}
