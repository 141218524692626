import { AptlyEmailType } from '@aptly-as/types';
import i18n from '../../libraries/i18n';
import { SelectOption } from '../../mui/Select';

export const notificationOptions = (): SelectOption[] => [
  {
    value: AptlyEmailType.InviteAccepted,
    label: `${i18n.t('singles.invitation')}: ${i18n.t('singles.accepted')}`,
  },
];
