import { AptlyUnitTemplateCategory, AptlyUnitTemplateCategorySection } from '@aptly-as/types';
import i18n from '../../../libraries/i18n';
import getSectionDisplayTypes from '../getSectionDisplayTypes';

export const getUnitTemplateCategorySubtitle = (category: AptlyUnitTemplateCategory) => {
  let label = category.isRoom ? i18n.t('singles.room') : '';
  if (category.upsellTemplate && typeof category.upsellTemplate === 'object') {
    label += ` - ${category.upsellTemplate.name}`;
  }
  return label;
};
export const getUnitTemplateSectionSubtitle = (section: AptlyUnitTemplateCategorySection) => {
  const labels: string[] = [];
  if (section.period && typeof section.period === 'object') {
    labels.push(section.period.name);
  }
  if (section.standard) {
    labels.push(i18n.t('singles.standard'));
  }
  if (section.multiSelect) {
    labels.push(i18n.t('singles.multipleChoice'));
  }
  if (section.displayType && section.displayType !== 'default') {
    const option = getSectionDisplayTypes().find((x) => x.value === section.displayType);
    if (option) {
      labels.push(option.label);
    }
  }
  return labels.join(' - ');
};
