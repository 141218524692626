import React, { PropsWithChildren, useCallback } from 'react';
import { AptlyDocumentType } from '@aptly-as/types';
import i18n from '../../../libraries/i18n';
import { DocumentTypeOption, sortedDocumentTypeOptions } from '../../../models/Document/document.utils';
import DocumentError from '../../../models/Document/DocumentError';
import { useSimpleCrudFields } from '../../simple/useSimpleCrud';
import { SimpleCrudContent, SimpleCrudForm } from '../../simple/SimpleCrud';
import ApiError from '../../ApiError';
import {
  documentThirdPartyUploaderSchema,
  IDocumentSchema,
  schemaDocumentType,
} from '../../../models/Document/document.schema';
import { ICrudFieldFile, ICrudSchema } from '../../crud/utils/crud.utils';
import { schemaFile } from '../../crud/schema/fields.schema';

interface Props<T extends IDocumentSchema> {
  endpoint: string;
  onCreate: (doc: T) => void;
  options?: DocumentTypeOption[];
  onClose?: () => void;
  customAuth?: string;
  accept?: ICrudFieldFile['accept'];
  fields?: ICrudSchema<T>;
  hideType?: boolean;
  hideSource?: boolean;
  title?: string;
}

export default function CrudUploadDocument<T extends IDocumentSchema>({
  endpoint,
  onCreate,
  options = sortedDocumentTypeOptions(),
  customAuth,
  onClose,
  accept,
  fields: propsFields = {},
  hideType,
  hideSource,
  title = i18n.t('actions.uploadDocument'),
  children,
}: PropsWithChildren<Props<T>>) {
  const keys: (keyof T)[] = [...(Object.keys(propsFields) as (keyof T)[]), 'file'];
  if (!hideSource) {
    keys.push('thirdPartyUploader');
  }
  const fields = React.useMemo(() => {
    const _fields: ICrudSchema<T> = { ...propsFields };
    _fields['file'] = schemaFile(accept, { required: true });

    if (!hideType) {
      _fields['_type'] = schemaDocumentType(options);
    }
    if (!hideSource) {
      _fields['thirdPartyUploader'] =
        documentThirdPartyUploaderSchema() as ICrudSchema<T>['thirdPartyUploader'];
    }
    return _fields;
  }, [hideType, hideSource, accept, options, propsFields]);

  const [crud, crudFields] = useSimpleCrudFields<T, keyof T>(
    endpoint,
    {
      _type: AptlyDocumentType.Document,
    } as Partial<T>,
    fields,
    keys,
    {
      reachOptions: {
        type: 'multipart/form-data',
        fileKeys: ['file'],
        bearerToken: customAuth,
      },
    }
  );

  const handleOnSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const res = await crud.save();
      if (res) {
        onCreate(res as any);
        if (typeof onClose === 'function') {
          onClose();
        }
      }
    },
    [crud, onCreate, onClose]
  );

  return (
    <SimpleCrudForm
      title={title}
      onClose={onClose}
      actionButtonProps={{ disabled: crud.state.busy }}
      onSubmit={handleOnSubmit}
    >
      {children}
      <SimpleCrudContent crud={crud} schema={crudFields} />
      {crud.state.error && (
        <>
          <DocumentError />
          <ApiError error={crud.state.error} />
        </>
      )}
    </SimpleCrudForm>
  );
}
