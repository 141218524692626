import Typography from '@mui/material/Typography';
import Progresser from '../mui/Progresser';
import i18n from '../libraries/i18n';
import Grid from '../mui/Grid';

interface Props {
  title?: string;
}

export default function BusyState({ title }: Props) {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <Progresser />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">
          {i18n.t('statuses.loading')}
          {title ? ' ' + title : ''}
        </Typography>
      </Grid>
    </Grid>
  );
}
