import styled from 'styled-components';
import { AptlyUnit } from '@aptly-as/types';
import apiRequest from '../../libraries/fetch/apiRequest';
import { createModal } from '../Modal/ModalContext';
import { successNotification } from '../Notification/Notification';
import i18n from '../../libraries/i18n';
import handleError from '../Error/handleError';
import { ApiError } from '../Error/createError';
import Grid from '../../mui/Grid';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../mui/Table';
import Typography from '../../mui/Typography';
import Button from '../../mui/Button';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import { UserData } from '../../models/User/user.types';

type Response = {
  url: string;
};

export interface ISignOpts {
  orderEndpoint: string;
  initializeSigning?: boolean;
  unit: ErrorModalProps['unit'];
  user?: ErrorModalProps['user'];
  refresh?: ErrorModalProps['refresh'];
}

async function sign(opts: ISignOpts) {
  try {
    if (opts.initializeSigning) {
      await apiRequest(`${opts.orderEndpoint}/signage/start`, {
        method: 'POST',
        notificationMessage: `${i18n.t('statuses.prepareOrderForSigning')}...`,
      });
    }

    const response: Response | ApiError = await apiRequest(`${opts.orderEndpoint}/signage/sign`, {
      method: 'POST',
      notificationMessage: `${i18n.t('statuses.fetchingDocumentToSign')}...`,
    });

    if ('status' in response && response.status === 404) {
      createModal(<ErrorModal unit={opts.unit} user={opts.user} refresh={opts.refresh} />);
    } else {
      successNotification(i18n.t('paragraphs.ordersReadyToSign'));

      window.location.href = (response as Response).url;
    }
  } catch (e: any) {
    if ('status' in e && e.status === 404) {
      createModal(<ErrorModal unit={opts.unit} user={opts.user} refresh={opts.refresh} />);
    } else {
      handleError(e);
    }
  }
}

export default sign;

interface ErrorModalProps {
  unit: Pick<AptlyUnit, '_id' | 'name'>;
  user?: Pick<UserData, 'email'>;
  onClose?: () => void;
  refresh?: () => void;
}

function ErrorModal({ user, unit, onClose, refresh }: ErrorModalProps) {
  return (
    <>
      <ModalContent>
        <Grid container>
          <Grid item>
            <StyledModalTitle>{i18n.t('statuses.documentFailure')}</StyledModalTitle>
            <Typography variant="body1">
              {i18n.t('info.order.documentExpired.one')}
              <br />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{i18n.t('singles.unit')}</TableCell>
                    {user && <TableCell>{i18n.t('singles.email')}</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{unit.name}</TableCell>
                    {user && <TableCell>{user.email}</TableCell>}
                  </TableRow>
                </TableBody>
              </Table>
              <br />
            </Typography>
            <Typography variant="body1">
              {i18n.t('info.order.documentExpired.two')}
              <br />
              <br />
            </Typography>
            <Typography variant="body1">
              {i18n.t('info.order.documentExpired.three')}
              <br />
              <br />
            </Typography>
            <Typography variant="body1">{i18n.t('info.order.documentExpired.four')}</Typography>
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <Button
          color="primary"
          onClick={() => {
            onClose!();
            if (typeof refresh === 'function') {
              refresh();
            }
          }}
        >
          {i18n.t('actions.close')}
        </Button>
      </ModalActions>
    </>
  );
}

const StyledModalTitle = styled(ModalTitle)`
  text-align: center;
`;
