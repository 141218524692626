import { FormEvent, useCallback, useMemo, useState } from 'react';
import { AptlyDocumentType } from '@aptly-as/types';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import i18n from '../../libraries/i18n';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import Select from '../../mui/Select';
import Typography from '../../mui/Typography';
import handleError from '../../containers/Error/handleError';
import useGetApiUrl from '../../hooks/useGetApiUrl';
import apiRequest from '../../libraries/fetch/apiRequest';
import { busyNotification, successNotification } from '../../containers/Notification/notification.utils';
import { documentTypeOptions } from './document.utils';

interface Props {
  onClose?: () => void;
}

const validDeleteDocs: AptlyDocumentType[] = [
  AptlyDocumentType.Document,
  AptlyDocumentType.UnitPlan,
  AptlyDocumentType.ElDrawing,
  AptlyDocumentType.KitchenDrawing,
  AptlyDocumentType.FDV,
];

export default function DeleteMultipleDocuments({ onClose }: Props) {
  const url = useGetApiUrl('project');
  const [busy, setBusy] = useState(false);
  const [_type, setType] = useState<string>(AptlyDocumentType.Document);
  const validDeleteOpts = useMemo(
    () => documentTypeOptions().filter((x) => validDeleteDocs.some((y) => x.value === y)),
    []
  );

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const busy = busyNotification();
      try {
        if (window.confirm(i18n.t('paragraphs.areYouSure'))) {
          setBusy(true);
          await apiRequest(`${url}/documents/many`, {
            method: 'DELETE',
            data: { _type },
          });
          successNotification(i18n.t('statuses.deleted'));

          if (typeof onClose === 'function') {
            onClose();
          }
        }
      } catch (e) {
        handleError(e);
      } finally {
        busy();
        setBusy(false);
      }
    },
    [_type, url, onClose]
  );

  return (
    <form onSubmit={handleSubmit}>
      <ModalTitle>{i18n.t('actions.delete')}</ModalTitle>
      <ModalContent>
        <Typography gutterBottom>{i18n.t('paragraphs.deleteAllUnitDocumentsWithType')}</Typography>
        <Select
          fullWidth
          value={_type}
          onChange={(e) => setType(e.target.value)}
          options={validDeleteOpts}
          label={i18n.t('singles.type')}
          disabled={busy}
        />
      </ModalContent>
      <ModalActions>
        <ActionButtons disabled={busy} submitType="submit" onClose={onClose} />
      </ModalActions>
    </form>
  );
}
