import { useCallback, useMemo, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { AccordionSubtitle, AccordionTitle } from '../../mui/Accordion';
import { IUnitTemplateProductsProps, UnitTemplateProducts } from './Products';
import { UnitTemplateAccordionSummary, UnitTemplateGrabButton } from './utils/DragOverlays';
import { useUnitTemplateSortable } from './utils/unit-template.sortable';
import { getUnitTemplateSectionSubtitle } from './utils/unit-template.data';

export interface IUnitTemplateSectionProps extends IUnitTemplateProductsProps {}

export function UnitTemplateSection(props: IUnitTemplateSectionProps) {
  const [expanded, setExpanded] = useState(false);
  const [{ ref, style }, grabProps] = useUnitTemplateSortable(props, expanded);
  const handleOnToggle = useCallback(() => setExpanded((s) => !s), []);
  const subtitle = useMemo(() => getUnitTemplateSectionSubtitle(props.section), [props.section]);
  const styles = useMemo(
    () => ({ ...style, width: '100%', margin: expanded ? '.5rem 0' : '0' }),
    [style, expanded]
  );

  return (
    <Accordion expanded={expanded} onChange={handleOnToggle} ref={ref} disableGutters style={styles}>
      <UnitTemplateAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <UnitTemplateGrabButton {...grabProps} size="small">
          <DragIndicatorIcon />
        </UnitTemplateGrabButton>
        <AccordionTitle>{props.section.name}</AccordionTitle>
        <AccordionSubtitle>{subtitle}</AccordionSubtitle>
      </UnitTemplateAccordionSummary>
      {expanded && <UnitTemplateProducts {...props} />}
    </Accordion>
  );
}
