import { AptlyDocument } from '@aptly-as/types';
import { API_URL } from '../../../env';
import { DownloadIconButtonHref } from './Icons';

interface DownloadDocumentLinkIconProps {
  title?: string;
  document: AptlyDocument;
}
export const DownloadHrefIcon = ({ title, document }: DownloadDocumentLinkIconProps) => {
  const href = document.downloadToken
    ? `${API_URL}/documents/${document.downloadToken || ''}`
    : document.external_url;
  return (
    <DownloadIconButtonHref href={href} download={document.name}>
      {title}
    </DownloadIconButtonHref>
  );
};
