import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import deleteResource from '../../containers/Notification/deleteResource';
import useGetApiUrl, { SlugLevel, useTo } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { ButtonWithIcon } from '../../mui/Button';
import { AvailabilityContext, useCalendar } from './BookingContext';

export default function BookingCalendarDelete() {
  const availability = useContext(AvailabilityContext);
  const calendar = useCalendar();
  const navigate = useNavigate();
  const to = useTo(SlugLevel.Project, '/motebooking');
  const path = useGetApiUrl('project', `/bookings/${availability.data!._id}/${calendar._id}`);
  const handleOnDelete = useCallback(async () => {
    await deleteResource(path, () => {
      availability.get();
      navigate(to);
    });
  }, [navigate, availability, to]);
  return (
    <ButtonWithIcon color="warning" onClick={handleOnDelete}>
      {i18n.t('actions.delete')}
      <DeleteOutlinedIcon />
    </ButtonWithIcon>
  );
}
