import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Area, Point } from 'react-easy-crop/types';
import Cropper from 'react-easy-crop';
import { AptlyMediaSrc } from '@aptly-as/types';
import Slider from '@mui/material/Slider';
import { CancelButtonWithIcon, SaveButtonWithIcon } from '../../components/actions/buttons/Buttons';
import Grid from '../../mui/Grid';
import Media from './Media';
import { tightSpacing } from '../../utils/spacing';
import DeleteResourceButton from '../../components/actions/buttons/DeleteResourceButton';

const minZoom = 0.8;
const maxZoom = 3;
const zoomStep = 0.05;

export interface IImageCropperProps {
  media: AptlyMediaSrc;
  aspect: number;
  location?: Point;
  area?: Area;
  onCrop: (area?: Area, location?: Point) => void;
}
export function ImageCropper({
  media,
  location = { x: 0, y: 0 },
  area: defaultArea,
  onCrop,
  aspect,
}: IImageCropperProps) {
  const [state, setState] = useState({
    initial: { ...location },
    location: { ...location },
  });
  const [zoom, setZoom] = useState(1);
  const [area, setArea] = useState<Area | null>(defaultArea || null);

  useEffect(() => {
    if (state.initial.x !== location.x || state.initial.y !== location.y) {
      setState({ initial: location, location });
    }
  }, [state.initial, location]);

  const handleOnDelete = useCallback(
    (e: any) => {
      e.stopPropagation();
      onCrop(undefined, undefined);
    },
    [onCrop]
  );

  const handleOnSave = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (area) {
        onCrop(area, state.location);
      }
    },
    [onCrop, area, state.location]
  );

  const handleOnClose = useCallback(
    (e: any) => {
      e.stopPropagation();
      onCrop(defaultArea, state.initial);
    },
    [onCrop, defaultArea, state.initial]
  );

  return (
    <Fixed
      container
      flexDirection="column"
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
      }}
    >
      <CropperContent>
        <Cropper
          minZoom={minZoom}
          image={Media.url(media, {
            transforms: 'c_fit',
            dontCrop: true,
          })}
          crop={state.location}
          onCropChange={(l) => setState((s) => ({ ...s, location: l }))}
          aspect={aspect}
          onCropComplete={(_croppedArea, croppedAreaPixels) => {
            setArea(croppedAreaPixels);
          }}
          zoom={zoom}
          zoomSpeed={1}
          zoomWithScroll={true}
          onZoomChange={setZoom}
          restrictPosition={false}
        />
      </CropperContent>
      <Grid container item justifyContent="center">
        <Grid item xs={8}>
          <Slider
            value={zoom}
            min={minZoom}
            max={maxZoom}
            step={zoomStep}
            onChange={(e, zoom) => {
              e.preventDefault();
              setZoom(zoom as number);
            }}
          />
        </Grid>
      </Grid>
      <Grid container item justifyContent="center" gap={tightSpacing}>
        <Grid item>
          <CancelButtonWithIcon onClick={handleOnClose} />
        </Grid>
        <Grid item>
          <DeleteResourceButton onClick={handleOnDelete} />
        </Grid>
        <Grid item>
          <SaveButtonWithIcon onClick={handleOnSave} />
        </Grid>
      </Grid>
    </Fixed>
  );
}

const Fixed = styled(Grid)`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  background-color: black;
  opacity: 0.99;
  z-index: 2;
`;

const CropperContent = styled.div`
  position: relative;
  width: 70%;
  height: 70%;
  background-color: grey;

  img {
    width: 100% !important;
    height: auto !important;
  }
`;
