import { Trans } from 'react-i18next';
import Section from '../../components/Section';
import Centered from '../../components/Centered';
import Grid from '../../mui/Grid';
import { cozySpacing } from '../../utils/spacing';
import Illustration from '../../components/Illustration';
import Typography from '../../mui/Typography';

export default function OrganizationsEmpty() {
  return (
    <Section>
      <Centered maxWidth="52rem" marginTop="7.5rem">
        <Grid container spacing={cozySpacing} direction="column" alignItems="center">
          <Grid item sm={6}>
            <Grid container direction="column" spacing={cozySpacing}>
              <Grid item>
                <Typography>
                  <Trans i18nKey="info.noOrganizations.one">
                    You're not part of any organizations. Try the invitation you received on your e-mail
                    again, or copy the invitationlink into the url field in this tab.
                  </Trans>
                </Typography>
                <Typography>
                  <Trans i18nKey="info.noOrganizations.two">
                    If you're still having problems, reply to the invitation or send an e-mail to{' '}
                    <a href="mailto: support@aptly.no">support@aptly.no</a> and we'll help you out.
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <Illustration>
              <img src="/logo.png" alt="wip" />
            </Illustration>
          </Grid>
        </Grid>
      </Centered>
    </Section>
  );
}
