import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from './Typography';
import StatusText from '../components/StatusText';

const Subtitle = styled(StatusText)`
  line-height: 1.8em;
`;

const AccordionBodyNegator = styled.div`
  flex: 1;
  margin: -8px -24px -24px;
`;

const AccordionBody = styled.div`
  flex: 1;
`;

const AccordionTitle = styled(Typography as any)`
  font-size: ${(props) => props.theme.typography.pxToRem(15)};
  flex-basis: 33.33%;
  flex-shrink: 0;
`;
const AccordionSubtitle = Subtitle;

export default Accordion;

export {
  AccordionSummary,
  AccordionDetails,
  AccordionTitle,
  AccordionSubtitle,
  AccordionBodyNegator,
  AccordionBody,
  AccordionActions,
};
