import { AptlyFieldType, AptlyProject } from '@aptly-as/types';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import { schemaName } from '../../components/crud/schema/fields.schema';
import i18n from '../../libraries/i18n';

export type ICrudPeriod = AptlyProject['periods'][0];

export const periodSchema = (): ICrudSchema<ICrudPeriod> => ({
  name: schemaName({
    autoFocus: true,
  }),
  open: {
    type: AptlyFieldType.DateTime,
    defaultValue: '',
    label: i18n.t('singles.from'),
    required: true,
    autoComplete: 'off',
  },
  close: {
    type: AptlyFieldType.DateTime,
    defaultValue: '',
    label: i18n.t('singles.to'),
    required: true,
    autoComplete: 'off',
  },
});
