import styled, { css } from 'styled-components';
import { IconButton } from '../../mui/Button';

import { primary } from '../../utils/themes';

export const StyledIconButton = styled(({ active, ...props }: any) => (
  <IconButton {...props} size="large" />
))<{
  active: boolean;
}>`
  &.MuiIconButton-root {
    border: 1px solid transparent;
    ${(props) =>
      props.active &&
      css`
        border: 1px solid ${primary};
      `};
  }
`;
