import {
  AptlyDocument,
  AptlyDocumentAccess,
  AptlyDocumentType,
  AptlyJobQueue,
  AptlyScopes,
} from '@aptly-as/types';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useContext, useMemo } from 'react';
import { CreateButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { ICrudFieldDocument } from '../../components/crud/utils/crud.utils';
import Search, {
  ISearchSubActions,
  SearchFieldSelect,
  SearchFieldText,
} from '../../components/Search/Search';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  DataGridDownloadDocumentAction,
  DataGridPatchAction,
} from '../../components/Search/search-data-grid/data-grid.actions';
import DataGridSelectEdit from '../../components/Search/search-data-grid/DataGridSelectEdit';
import { useDataGridEditText } from '../../components/Search/search-data-grid/DataGridTextEdit';
import { ISearchCrudActions, SearchCrudContext, SubAction } from '../../components/Search/search.utils';
import SearchDataGrid, {
  ISearchDataGridProps,
  SearchDataGridToolbar,
} from '../../components/Search/SearchDataGrid';
import SimpleCrud from '../../components/SimpleCrud';
import Spacer from '../../components/Spacer';
import { createModal } from '../../containers/Modal/ModalContext';
import { FORMAT_DATE } from '../../env';
import { SlugLevel, useApiUrl, useSlugs } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { IUseCrudSearchPatchProps } from '../../libraries/reach/useCrudSearch';
import Scope from '../../libraries/scope/Scope';
import useScopeModel from '../../libraries/scope/useScopeModel';
import Button, { ButtonWithIcon } from '../../mui/Button';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import { TextField } from '../../mui/Input';
import { dataGridDateTimeColumn } from '../../mui/x-data-grid/dataGrid.cols';
import DeleteMultipleDocuments from './DeleteMultipleDocuments';
import documentSchema, { DOCUMENT_SELECT, documentFields, IDocumentSchema } from './document.schema';
import {
  documentAccessOptions,
  documentAccessTranslations,
  documentTypeOptions,
  documentTypeTranslations,
  sortedDocumentTypeOptions,
} from './document.utils';
import DropDocuments from './DropDocuments';
import LinkDocumentsBot from './LinkDocumentsBot';
import LinkDocumentToUnit from './LinkDocumentToUnit';
import { ZipDocuments } from './ZipDocuments';

export interface IDocumentsProps extends ICrudFieldDocument {
  simple?: boolean;
  disabled?: boolean;
}

const columns = (): GridColDef[] => [
  {
    field: 'name',
    headerName: i18n.t('singles.name'),
    flex: 1,
    editable: true,
    renderEditCell: useDataGridEditText,
  },
  {
    field: '_type',
    headerName: i18n.t('singles.type'),
    minWidth: 150,
    renderCell: (params) => documentTypeTranslations(params.value),
    editable: true,
    renderEditCell: (params) => <DataGridSelectEdit params={params} options={sortedDocumentTypeOptions()} />,
  },
  {
    field: 'thirdPartyUploader',
    headerName: i18n.t('singles.source'),
    minWidth: 100,
    renderCell: (params) => params.value || 'Aptly',
  },
  dataGridDateTimeColumn('createdAt', { minWidth: 100 }, FORMAT_DATE),
  dataGridDateTimeColumn('expires', { minWidth: 125, headerName: i18n.t('singles.expires') }),
  {
    field: 'access',
    headerName: i18n.t('singles.accessLevel'),
    align: 'right',
    minWidth: 115,
    renderCell: (params) => documentAccessTranslations(params.value),
    editable: true,
    renderEditCell: (params) => <DataGridSelectEdit params={params} options={documentAccessOptions()} />,
  },
  dataGridActionProps((params) => (
    <DataGridActionWrapper>
      <DataGridDownloadDocumentAction params={params} />
      <DataGridPatchAction params={params} />
      <DataGridDeleteAction params={params} />
    </DataGridActionWrapper>
  )),
];

export function Documents({
  level,
  overridePath,
  simple,
  scope: scopeProps,
  types,
  maxLength,
  disabled,
}: IDocumentsProps) {
  const scope = useScopeModel(scopeProps);
  if (disabled) {
    scope.create = false;
    scope.update = false;
    scope.delete = false;
  }
  const [organizationSlug, projectID] = useSlugs();
  const path = useApiUrl(level, overridePath ? overridePath : 'documents');
  const initialState: ISearchDataGridProps['initialState'] = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          access: !simple && Scope.crud(AptlyScopes.Admin, 'R'),
          thirdPartyUploader: !simple,
          expires: !simple,
        },
      },
    }),
    [simple]
  );
  const baseEndpoint = `organizations/${organizationSlug}/projects/${projectID}`;
  const [documentAccess, accessOptionsInfo] = useMemo(() => {
    const optionsWithInfo = documentAccessOptions(true);
    if (level === SlugLevel.Unit) {
      return [AptlyDocumentAccess.User, optionsWithInfo.slice(1)];
    }
    return [AptlyDocumentAccess.User, optionsWithInfo];
  }, [level]);
  const schema = useMemo(
    () =>
      documentSchema(
        types
          ? types.map((x) => documentTypeOptions().find((y) => y.value === x)!).filter(Boolean)
          : sortedDocumentTypeOptions(),
        accessOptionsInfo
      ),
    [types, accessOptionsInfo]
  );
  const limit = simple ? 5 : 20;

  const buttonActions = useCallback(
    (documents: IDocumentSchema[], { spawnPost, unshift, refresh }: ISearchCrudActions<IDocumentSchema>) => {
      if (disabled) return [];
      const actions = [];
      if (simple) {
        if (scope.create && (!maxLength || documents.length < maxLength)) {
          if (!types || types.includes(AptlyDocumentType.Document)) {
            actions.push(
              <CreateButtonWithIcon
                onClick={() => spawnPost(unshift, { _type: AptlyDocumentType.Document }, ['_type', 'file'])}
              >
                {i18n.t('singles.document')}
              </CreateButtonWithIcon>
            );
          }
          if (!types || types.includes(AptlyDocumentType.FDV)) {
            actions.push(
              <CreateButtonWithIcon
                onClick={() => spawnPost(unshift, { _type: AptlyDocumentType.FDV }, ['_type', 'file'])}
              >
                {i18n.t('singles.fdvDocument')}
              </CreateButtonWithIcon>
            );
          }
        }
      } else {
        actions.push(
          <ZipDocuments
            key="zip"
            queue={AptlyJobQueue.Documents}
            label={
              level === SlugLevel.Unit ? `${i18n.t('singles.unit')}.zip` : `${i18n.t('singles.project')}.zip`
            }
            endpoint={`${path}/zip`}
          />
        );

        if (level === SlugLevel.Project) {
          actions.push(
            <ButtonWithIcon
              variant="outlined"
              color="primary"
              onClick={() => createModal(<LinkDocumentsBot refresh={refresh} />)}
            >
              {i18n.t('actions.copyToUnits')}
              <FileCopyOutlinedIcon />
            </ButtonWithIcon>
          );
        }
      }
      return actions;
    },
    [simple, scope, level, types, maxLength, disabled]
  );

  const searchFields = useMemo(() => {
    if (simple) return undefined;
    return [
      <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} />,
      <SearchFieldSelect
        key="_type"
        field="_type"
        options={[{ value: '', label: i18n.t('singles.all') }, ...sortedDocumentTypeOptions()]}
        label={i18n.t('singles.type')}
      />,
    ];
  }, [simple]);

  const subActions: ISearchSubActions<AptlyDocument> = useCallback(() => {
    const actions: SubAction<IDocumentSchema>[] = [];

    if (!simple && level === SlugLevel.Project) {
      if (scope.delete) {
        actions.push({
          label: i18n.t('actions.delete'),
          onClick: () => {
            createModal(<DeleteMultipleDocuments />);
          },
          icon: <DeleteOutlinedIcon />,
        });

        if (Scope.crud(AptlyScopes.ProjectThirdParty, 'U')) {
          actions.push({
            label: i18n.t('paragraphs.linkForThirdPartyUploads'),
            onClick: () => {
              createModal(
                <SimpleCrud<{ url: string }>
                  title={i18n.t('paragraphs.linkForThirdPartyUploads')}
                  description={
                    <div>
                      {i18n.t('info.thirdPartyDocument')}
                      <br />
                      <br />
                      <strong>{i18n.t('paragraphs.linkExpiresOneWeek')}</strong>
                    </div>
                  }
                  actionLabel={i18n.t('actions.create')}
                  endpoint={`${baseEndpoint}/create-third-party-upload-link`}
                  onCreate={(data) => createModal(<ShareLinkModal url={data.url} />)}
                  fields={[
                    {
                      helpField: i18n.t('paragraphs.uploaderName'),
                      name: 'uploaderName',
                      type: 'text',
                      value: '',
                      label: '',
                      required: true,
                    },
                  ]}
                />
              );
            },
            icon: <ArrowForwardIosIcon />,
          });
        }
      }
    }

    return actions;
  }, [level, scope, baseEndpoint]);
  const patch: IUseCrudSearchPatchProps<IDocumentSchema> = useMemo(
    () => ({
      title: (item) => item.name || '',
      modalProps: { width: 'sm' },
      fields: documentFields,
    }),
    []
  );

  const Toolbar = useMemo(() => {
    if (level === SlugLevel.Project) return ProjectDocumentToolbar;
    return undefined;
  }, [level]);

  return (
    <Search<IDocumentSchema>
      path={path}
      patchPath={() => path}
      scope={scope}
      header={{ title: i18n.t('singles.documents') }}
      patch={patch}
      post={{
        title: i18n.t('actions.create'),
        useFieldsProps: {
          reachOptions: { type: 'multipart/form-data', fileKeys: ['file'] },
        },
      }}
      bulk={{ title: '', fields: ['_type'] }}
      schema={schema}
      fields={documentFields}
      reach={{
        limit,
        query: { select: DOCUMENT_SELECT },
      }}
      searchFields={searchFields}
      buttonActions={buttonActions}
      subActions={subActions}
    >
      {(documents, actions) => (
        <Grid container direction="column">
          {!simple && scope.create && (!maxLength || documents.length < maxLength) && (
            <>
              <DropDocuments
                endpoint={path}
                path=""
                access={documentAccess}
                accessOptions={accessOptionsInfo}
                onDrop={(docs) => actions.unshift(...(docs as any[]))}
              />
              <Spacer hugTop />
            </>
          )}
          <Grid item>
            <SearchDataGrid
              initialState={initialState}
              columns={columns()}
              pageSizeOptions={simple ? [5] : undefined}
              pageSize={limit}
              Toolbar={Toolbar}
            />
          </Grid>
        </Grid>
      )}
    </Search>
  );
}

function ProjectDocumentToolbar() {
  const { actions } = useContext(SearchCrudContext);
  const { refresh } = actions;

  return (
    <SearchDataGridToolbar<IDocumentSchema>>
      {(active, getDocuments) => {
        return (
          <>
            <Button
              size="small"
              disabled={active.size === 0}
              onClick={() => {
                createModal(
                  <LinkDocumentToUnit name={''} documents={getDocuments() as any[]} refresh={refresh} />
                );
              }}
            >
              {i18n.t('actions.copyToUnit')}
            </Button>
          </>
        );
      }}
    </SearchDataGridToolbar>
  );
}

interface ShareLinkModalProps {
  url: string;
  onClose?: () => void;
}

function ShareLinkModal(props: ShareLinkModalProps) {
  const { url, onClose } = props;

  return (
    <React.Fragment>
      <ModalTitle>{i18n.t('paragraphs.linkForThirdPartyUploads')}</ModalTitle>
      <ModalContent>
        <p>{i18n.t('info.thirdPartyDocumentShare')}</p>
        <TextField value={url} fullWidth onFocus={(e) => e.target.select()} />
      </ModalContent>
      <ModalActions>
        <Button color="primary" onClick={onClose}>
          {i18n.t('actions.close')}
        </Button>
      </ModalActions>
    </React.Fragment>
  );
}
