import { useCallback, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import { AddButtonWithIcon } from '../../../components/actions/buttons/Buttons';
import { createModal } from '../../../containers/Modal/ModalContext';
import SearchViewComposer, { SearchField } from '../../../deprecated/Search/SearchViewComposer';
import { TextField } from '../../../mui/Input';
import i18n from '../../../libraries/i18n';
import useGetApiUrl from '../../../hooks/useGetApiUrl';
import CrudCreateUnitProduct from '../../../components/simpleCruds/product/CrudCreateUnitProduct';
import { useAlgorithms } from '../../Algorithm/useAlgorithms';
import { ExtraOption } from '../../Unit/unit.types';
import UnitProductTable from '../../Unit/UnitProductTable';

export default function ProjectExtraOptions() {
  const algorithms = useAlgorithms();
  const endpoint = useGetApiUrl('project') + '/extra-options';

  const addProduct = useCallback(
    (inject: any) => {
      createModal(
        <CrudCreateUnitProduct
          endpoint={endpoint}
          onSave={inject}
          showPrerequisites
          showDBProduct
          simpleProductInit
        />
      );
    },
    [endpoint]
  );

  const editProduct = useCallback(
    (put: any) => (product: any, i: number) => {
      createModal(
        <CrudCreateUnitProduct
          endpoint={endpoint}
          unitProduct={product}
          onSave={(p) => put(i, p)}
          showPrerequisites
          showDBProduct
          simpleProductInit
        />
      );
    },
    [endpoint]
  );

  const actions = useCallback(
    (inject: any) => [<AddButtonWithIcon key="add" onClick={() => addProduct(inject)} />],
    [addProduct]
  );

  const searchFields = useMemo(
    (): SearchField[] => [
      {
        name: 'name',
        type: 'textfield',
        autoFocus: true,
        element: <TextField label={i18n.t('singles.name')} />,
      },
    ],
    []
  );

  return (
    <SearchViewComposer<ExtraOption>
      title={i18n.t('singles.fees')}
      endpoint={endpoint}
      searchFields={searchFields}
      additionalActions={actions}
      dontUseTable
    >
      {(extraOptions: ExtraOption[], _f: any, put: any, inject: any, remove: any) => {
        return (
          <Paper>
            <UnitProductTable
              endpoint={endpoint}
              products={extraOptions}
              algorithms={algorithms}
              addProduct={() => addProduct(inject)}
              onDelete={(_p, i) => remove(i)}
              onEdit={editProduct(put)}
              showPrerequisite
            />
          </Paper>
        );
      }}
    </SearchViewComposer>
  );
}
