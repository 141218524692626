import Button from '@mui/material/Button';
import { useCallback, useContext, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { IUseSearchProps, useSearch } from '@ewb/reach-react';
import {
  AptlyApp,
  AptlyErrorBody,
  AptlyModuleItem,
  AptlyOrganization,
  AptlyScopes,
  AptlySearchPaginateResponse,
} from '@aptly-as/types';
import { ShowMeMoreIconButton } from '../../components/actions/icons/Icons';
import ConfirmModal from '../../containers/Modal/ConfirmModal';
import { useModal } from '../../containers/Modal/Modal';
import { getId } from '../../libraries/mongoose';
import { reach } from '../../libraries/reach/reach';
import { FragmentScope } from '../../libraries/scope/ScopeComponets';
import { OrganizationContext } from '../Organization/OrganizationContext';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { responseToData } from '../../libraries/reach/usePaginatedSearch';
import ApiError from '../../components/ApiError';
import BusyState from '../../components/BusyState';
import i18n from '../../libraries/i18n';
import Section from '../../components/Section';
import AppCard, { AppCardActionLink } from './AppCard';
import { API_URL } from '../../env';

export default function AppsInstallList() {
  const org = useContext(OrganizationContext);
  const orgApps = org.data.apps || [];
  const url = useApiUrl(SlugLevel.Base, 'apps');
  const installUrl = useApiUrl(SlugLevel.Organization, 'apps');
  const props: IUseSearchProps<AptlyApp, AptlyErrorBody, AptlySearchPaginateResponse<AptlyApp>> = useMemo(
    () => ({
      limit: 12,
      responseToData,
    }),
    []
  );
  const [busy, apps, error, next, info] = useSearch(url, props);

  const handleOnContact = useModal<AptlyApp>(
    useCallback(
      (app) => (
        <ConfirmModal
          title={app.name}
          actionLabel={i18n.t('actions.contactUs')}
          onConfirm={async () => {
            await reach.api<AptlyModuleItem>(`${installUrl}/${app._id}/contact`, {
              method: 'POST',
            });
          }}
        >
          <Typography>
            Dette vil sende en mail til support@aptly.no om at du er interessert i integrasjonen {app.name}.
            Denne eposten inneholder navn på integrasjon, organisasjon, ditt navn og e-post.
            <br />
            Vi vil kontakte deg så fort som mulig for mer info.
          </Typography>
        </ConfirmModal>
      ),
      [installUrl]
    )
  );

  if (error) {
    return <ApiError error={error} />;
  }

  if (busy) {
    return <BusyState title={i18n.t('singles.apps')} />;
  }

  return (
    <>
      <Section>
        <Grid container>
          <Typography variant="h1">{i18n.t('singles.apps')}</Typography>
        </Grid>
      </Section>
      <Section>
        <Grid container spacing={2} alignItems="stretch">
          {apps.map((app) => (
            <AppItem
              key={app._id}
              orgApps={orgApps}
              app={app}
              installUrl={installUrl}
              onContact={handleOnContact}
            />
          ))}
        </Grid>
        {apps.length < info.count && (
          <Grid container justifyContent="center">
            <ShowMeMoreIconButton onClick={next} disabled={busy} />
          </Grid>
        )}
      </Section>
    </>
  );
}

interface AppItemProps {
  orgApps: AptlyOrganization['apps'];
  app: AptlyApp;
  installUrl: string;
  onContact: (app: AptlyApp) => void;
}

function AppItem({ orgApps, app, installUrl, onContact }: AppItemProps) {
  const handleOnContact = useCallback(() => {
    onContact(app);
  }, [onContact]);
  const installed = orgApps.some((x) => getId(x.app) === app._id);
  return (
    <Grid key={app._id} item xl={4} md={6} xs={12}>
      <AppCard title={app.name} image={app.logo} description={app.description} cost={app.cost}>
        {installed ? (
          <>
            <AppCardActionLink to={app._id}>{i18n.t('singles.settings')}</AppCardActionLink>
            <FragmentScope scope={AptlyScopes.Admin}>
              <AppCardActionLink to={`${app._id}/uninstall`}>
                {i18n.t('actions.deactivate')}
              </AppCardActionLink>
            </FragmentScope>
          </>
        ) : (
          <Button size="small" onClick={handleOnContact}>
            {i18n.t('actions.contactUs')}
          </Button>
        )}
        <FragmentScope scope={AptlyScopes.Admin}>
          <AppCardActionLink href={`${API_URL}/${installUrl}/${app._id}/install`}>
            {installed ? i18n.t('actions.reinstall') : i18n.t('actions.activate')}
          </AppCardActionLink>
        </FragmentScope>
      </AppCard>
    </Grid>
  );
}
