import { AptlyDocument, AptlyDocumentType, AptlyJobQueue, AptlyScopes } from '@aptly-as/types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import React, { useState } from 'react';
import ActionBar from '../../components/ActionBar';
import { UploadButtonWithIcon } from '../../components/actions/buttons/Buttons';
import DeleteIconEndpoint from '../../components/actions/icons/DeleteIconEndpoint';
import { CopyIconButton, DownloadIconButton, EditIconButton } from '../../components/actions/icons/Icons';
import SimpleCrud from '../../components/SimpleCrud';
import CrudUploadDocument from '../../components/simpleCruds/document/CrudUploadDocument';
import Spacer from '../../components/Spacer';
import { createModal } from '../../containers/Modal/ModalContext';
import { Field } from '../../deprecated/inputs';
import { Query } from '../../deprecated/Search/Search';
import SearchViewComposer from '../../deprecated/Search/SearchViewComposer';
import { API_URL } from '../../env';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { downloadDocument } from '../../libraries/files-saver';
import i18n from '../../libraries/i18n';
import { intlDateTimeFormat } from '../../libraries/intl';
import { acceptPdf } from '../../libraries/react-dropzone/drop-zone.utils';
import Scope from '../../libraries/scope/Scope';
import Button, { ButtonWithIcon } from '../../mui/Button';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Grid from '../../mui/Grid';
import { TextField } from '../../mui/Input';
import Paper from '../../mui/Paper';
import Select from '../../mui/Select';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../mui/Table';
import DeleteMultipleDocuments from './DeleteMultipleDocuments';
import { documentTypeOptions, sortedDocumentTypeOptions } from './document.utils';
import DropDocuments from './DropDocuments';
import LinkDocumentsBot from './LinkDocumentsBot';
import LinkDocumentToUnit from './LinkDocumentToUnit';
import { ZipDocuments } from './ZipDocuments';

interface Props {
  h2?: boolean;
  noTitle?: boolean;
  organizationSlug?: string;
  projectID?: string;
  endpointSuffix?: string;
  onSave?: () => void;
  addable?: boolean;
  canCreate?: boolean;
  canDelete?: boolean;
  thirdParty?: boolean;
  hideSearch?: boolean;
  showDrop?: boolean;
  showCopyAndDelete?: boolean;
  query?: Query;
  isProduct?: boolean;
}

interface State {
  hash: string;
}

const PUBLIC_DOC_TYPES: AptlyDocumentType[] = [
  AptlyDocumentType.Public,
  AptlyDocumentType.FDV,
  AptlyDocumentType.Prospect,
  AptlyDocumentType.Gdpr,
];

const typeField = (d?: AptlyDocument): Field => ({
  helpField: i18n.t('singles.type'),
  name: '_type',
  type: 'select',
  value: d ? d._type : 'document',
  label: i18n.t('singles.document'),
  options: sortedDocumentTypeOptions(),
});

const textField = (d?: { uploaderName: string }): Field => ({
  helpField: i18n.t('paragraphs.uploaderName'),
  name: 'uploaderName',
  type: 'text',
  value: d ? d.uploaderName : '',
  label: '',
  required: true,
});

const nameField = (d?: AptlyDocument): Field => ({
  helpField: i18n.t('singles.name'),
  name: 'name',
  type: 'text',
  value: d ? d.name : '',
  label: d ? d.name : '',
  required: true,
});

const sourceField = (d?: AptlyDocument): Field => ({
  helpField: i18n.t('singles.source'),
  name: 'thirdPartyUploader',
  type: 'text',
  value: d ? d.thirdPartyUploader || '' : '',
  label: d ? d.thirdPartyUploader || '' : '',
});

/**
 * @deprecated Use Documents instead
 */
export default function Documents_old({
  organizationSlug,
  projectID,
  endpointSuffix,
  h2,
  noTitle,
  onSave,
  canDelete,
  canCreate,
  addable,
  thirdParty,
  hideSearch,
  showDrop,
  showCopyAndDelete,
  query,
  isProduct,
}: Props) {
  const [{ hash }, setState] = useState<State>({ hash: 'init' });
  const v1Path = useApiUrl(SlugLevel.Unit, 'documents');

  const baseEndpoint = `${
    organizationSlug && projectID ? `organizations/${organizationSlug}/projects/${projectID}` : ''
  }${endpointSuffix || ''}`;
  const documentEndpoint = `${baseEndpoint}/documents`;
  const documentCreate = () => setState({ hash: Date.now().toString() });

  return (
    <SearchViewComposer
      key={hash}
      h2={h2}
      noTitle={noTitle}
      title={i18n.t('singles.documents')}
      endpoint={`${baseEndpoint}/documents`}
      hideSearch={hideSearch}
      query={query}
      searchFields={[
        {
          name: 'name',
          element: <TextField label={i18n.t('singles.name')} fullWidth />,
          autoFocus: true,
        },
        {
          type: 'select',
          name: '_type',
          element: (
            <Select
              label={i18n.t('singles.type')}
              fullWidth
              options={[{ label: i18n.t('singles.type'), value: '' }, ...sortedDocumentTypeOptions()]}
            />
          ),
        },
      ]}
      additionalActions={(_injectRow, refresh) => {
        const actions: any[] = [
          <ZipDocuments
            key="zip"
            endpoint={`${v1Path}/zip`}
            queue={AptlyJobQueue.Documents}
            label={`${i18n.t('singles.unit')}.zip`}
          />,
        ];

        if (canCreate && addable) {
          actions.push(
            <ButtonWithIcon
              variant="outlined"
              color="primary"
              onClick={() => {
                createModal(
                  <SimpleCrud
                    title={i18n.t('actions.addDocument')}
                    actionLabel={i18n.t('actions.add')}
                    endpoint={`${baseEndpoint}/documents`}
                    onCreate={() => {
                      setState({
                        hash: Date.now().toString(),
                      });

                      if (typeof onSave === 'function') {
                        onSave();
                      }
                    }}
                    fields={[
                      {
                        helpField: i18n.t('singles.document'),
                        name: 'document',
                        type: 'reference',
                        reference: {
                          endpoint: `organizations/${organizationSlug || ''}/projects/${
                            projectID || ''
                          }/documents`,
                          labelKey: 'name',
                          queryKey: 'name',
                        },
                        value: null,
                        label: '',
                        required: true,
                      },
                    ]}
                  />
                );
              }}
            >
              {i18n.t('actions.add')}
              <FileCopyOutlinedIcon />
            </ButtonWithIcon>
          );
        }

        if (!showDrop && canCreate) {
          actions.push(
            <UploadButtonWithIcon
              onClick={() => {
                createModal(<CrudUploadDocument endpoint={documentEndpoint} onCreate={documentCreate} />);
              }}
            />
          );
        }

        if (isProduct) {
          actions.push(
            <UploadButtonWithIcon
              title={i18n.t('singles.fdvDocument')}
              onClick={() => {
                createModal(
                  <CrudUploadDocument
                    options={documentTypeOptions().filter((x) => x.value === AptlyDocumentType.FDV)}
                    endpoint={documentEndpoint}
                    onCreate={documentCreate}
                    accept={acceptPdf}
                  />
                );
              }}
            />
          );
          actions.push(
            <UploadButtonWithIcon
              title={i18n.t('singles.manual')}
              onClick={() => {
                createModal(
                  <CrudUploadDocument
                    options={documentTypeOptions().filter((x) => x.value === AptlyDocumentType.Manual)}
                    endpoint={documentEndpoint}
                    onCreate={documentCreate}
                    accept={acceptPdf}
                  />
                );
              }}
            />
          );
        }

        if (showCopyAndDelete && canCreate) {
          actions.push(
            <ButtonWithIcon
              variant="outlined"
              color="primary"
              onClick={() => createModal(<LinkDocumentsBot refresh={refresh} />)}
            >
              {i18n.t('actions.copyToUnits')}
              <FileCopyOutlinedIcon />
            </ButtonWithIcon>
          );
        }
        return actions;
      }}
      menuActions={(_injectRow, refresh, _putRow, results, query) => {
        const menuActions = [];

        if (showCopyAndDelete && canCreate) {
          menuActions.push({
            label: i18n.t('actions.copyToUnit'),
            onClick: () =>
              createModal(
                <LinkDocumentToUnit name={query ? query.name : ''} documents={results} refresh={refresh} />
              ),
            icon: <FileCopyOutlinedIcon />,
          });
        }

        if (showCopyAndDelete && canDelete) {
          menuActions.push({
            label: i18n.t('actions.delete'),
            onClick: () => createModal(<DeleteMultipleDocuments />),
            icon: <DeleteOutlinedIcon />,
          });
        }

        if (Scope.crud(AptlyScopes.ProjectThirdParty, 'U') && thirdParty) {
          menuActions.push({
            label: i18n.t('paragraphs.linkForThirdPartyUploads'),
            onClick: () => {
              createModal(
                <SimpleCrud<{ url: string }>
                  title={i18n.t('paragraphs.linkForThirdPartyUploads')}
                  description={
                    <div>
                      {i18n.t('info.thirdPartyDocument')}
                      <br />
                      <br />
                      <strong>{i18n.t('paragraphs.linkExpiresOneWeek')}</strong>
                    </div>
                  }
                  actionLabel={i18n.t('actions.create')}
                  endpoint={`${baseEndpoint}/create-third-party-upload-link`}
                  onCreate={(data) => createModal(<ShareLinkModal url={data.url} />)}
                  fields={[textField()]}
                />
              );
            },
            icon: <ArrowForwardIosIcon />,
          });
        }

        return menuActions;
      }}
      dontUseTable
      emptyStateIcon={<FileCopyOutlinedIcon />}
      initialQuery
      alwaysRender
    >
      {(documents: AptlyDocument[], _outputFilter, putRow, injectRow, deleteRow) => (
        <Grid container direction="column">
          {canCreate && showDrop && (
            <>
              <DropDocuments endpoint={baseEndpoint} onDrop={injectRow} />
              <Spacer hugTop />
            </>
          )}
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{i18n.t('singles.name')}</TableCell>
                  <TableCell>{i18n.t('singles.type')}</TableCell>
                  <TableCell>{i18n.t('singles.source')}</TableCell>
                  <TableCell>{i18n.t('statuses.created')}</TableCell>
                  <TableCell align="right">{i18n.t('singles.actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map((d, k) => (
                  <TableRow key={d._id}>
                    <TableCell>{d.name}</TableCell>
                    <TableCell>{documentTypeTranslations(d._type)}</TableCell>
                    <TableCell>{documentSourceTranslation(d.thirdPartyUploader)}</TableCell>
                    <TableCell>{d.created ? intlDateTimeFormat(d.created) : i18n.t('singles.NA')}</TableCell>
                    <TableCell align="right">
                      <ActionBar>
                        {!d.external_url && PUBLIC_DOC_TYPES.some((x) => x === d._type) && (
                          <CopyIconButton
                            onClick={() => {
                              createModal(<CopyLinkModal url={d.downloadToken} />);
                            }}
                          >
                            {i18n.t('actions.copyLink')}
                          </CopyIconButton>
                        )}
                        <DownloadIconButton
                          onClick={() => {
                            downloadDocument(d);
                          }}
                        />
                        {!d.external_url && canCreate && (
                          <EditIconButton
                            onClick={() =>
                              createModal(
                                <SimpleCrud
                                  title={i18n.t('actions.edit')}
                                  id={d._id}
                                  endpoint={`${baseEndpoint}/documents`}
                                  fields={[typeField(d), nameField(d), sourceField(d)]}
                                  onCreate={(data) => putRow(k, data)}
                                />
                              )
                            }
                          />
                        )}
                        {d._type !== 'public' && d._type !== 'fdv' && !d.external_url && canDelete && (
                          <DeleteIconEndpoint
                            _id={d._id}
                            endpoint={`${baseEndpoint}/documents`}
                            onClick={() => deleteRow(k)}
                          />
                        )}
                      </ActionBar>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      )}
    </SearchViewComposer>
  );
}

type ShareLinkModalProps = {
  url: any;
  onClose?: () => any;
};

function ShareLinkModal(props: ShareLinkModalProps) {
  const { url, onClose } = props;

  return (
    <React.Fragment>
      <ModalTitle>{i18n.t('paragraphs.linkForThirdPartyUploads')}</ModalTitle>
      <ModalContent>
        <p>{i18n.t('info.thirdPartyDocumentShare')}</p>
        <TextField value={url} fullWidth onFocus={(e) => e.target.select()} />
      </ModalContent>
      <ModalActions>
        <Button color="primary" onClick={onClose}>
          {i18n.t('actions.close')}
        </Button>
      </ModalActions>
    </React.Fragment>
  );
}

function CopyLinkModal(props: ShareLinkModalProps) {
  const { url, onClose } = props;

  const link = `${API_URL || ''}/documents/${url}`;

  return (
    <React.Fragment>
      <ModalTitle>{i18n.t('singles.link')}</ModalTitle>
      <ModalContent>
        <TextField value={link} fullWidth onFocus={(e) => e.target.select()} />
      </ModalContent>
      <ModalActions>
        <Button color="primary" onClick={onClose}>
          {i18n.t('actions.close')}
        </Button>
      </ModalActions>
    </React.Fragment>
  );
}

function documentTypeTranslations(type: string): string {
  const typeName = documentTypeOptions().find((x) => x.value === type);
  return typeName ? typeName.label : type;
}

function documentSourceTranslation(source: AptlyDocument['thirdPartyUploader']): string {
  switch (source) {
    case 'checkd':
      return 'CheckD';
    case 'boligmappa':
      return 'Boligmappa';
    default:
      return source || 'Aptly';
  }
}

export { documentTypeTranslations };
