import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AptlyMediaSrc } from '@aptly-as/types';
import { SlugLevel, useApiUrl } from './useGetApiUrl';
import { API_URL } from '../env';

interface State {
  percent: number;
  startTime: Date;
  endTime: Date | null;
}

const HALFWAY_PERCENT = 33;
const defaultState = (): State => ({
  percent: 0,
  startTime: new Date(),
  endTime: null,
});

type fn = (files: File[]) => void;

export type FileUploaderValue<M extends boolean> = M extends true ? AptlyMediaSrc[] : AptlyMediaSrc;
export type FileUploaderFn<M extends boolean> = (files: FileUploaderValue<M>) => void;
export default function useFileUploader<M extends boolean>(
  multiple: M,
  onSelect: FileUploaderFn<M>
): [number, fn] {
  const interval = useRef<number | null>(null);
  const requestRef = useRef<XMLHttpRequest | null>(null);
  const [progress, setProgress] = useState(defaultState());
  const endpoint = useApiUrl(SlugLevel.Organization, 'media');

  useEffect(() => {
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
      if (requestRef.current) {
        requestRef.current.abort();
      }
    };
  }, []);

  const apiTimer = useCallback((state: any) => {
    const totalTimeToWait = state.endTime - state.startTime;
    interval.current = setInterval(() => {
      const now: Date = new Date();
      // @ts-ignore
      const waited = now - state.endTime;
      const percent = Math.round((waited * 100) / totalTimeToWait) + HALFWAY_PERCENT;
      setProgress((s) => ({ ...s, percent }));
      if (percent > 100) {
        if (interval.current) {
          clearInterval(interval.current);
        }
      }
    }, 100);
  }, []);

  const onProgress = useCallback(
    (files: File[]) =>
      (function(this: XMLHttpRequest, e: ProgressEvent) {
        const size = files.map((x) => x.size).reduce((tot, x) => tot + x, 0);
        if (e.loaded <= size) {
          const percent = Math.round((e.loaded / size) * 100);
          setProgress((s) => ({ ...s, percent: percent / 2 }));
        } else if (e.loaded === e.total) {
          setProgress((s) => {
            const newState = {
              ...s,
              percent: HALFWAY_PERCENT,
              endTime: new Date(),
            };
            apiTimer(newState);
            return newState;
          });
        }
      }),
    [apiTimer]
  );

  const onLoad = useCallback(
    function (this: XMLHttpRequest) {
      const json = JSON.parse(this.response);
      if (this.status < 400) {
        if (Array.isArray(json)) {
          onSelect(multiple ? json : json[0]);
        }
      } else {
        alert(json);
      }
      if (interval.current) {
        clearInterval(interval.current);
      }
      setProgress((s) => ({ ...s, percent: 0 }));
    },
    [onSelect, multiple]
  );

  const onError = useCallback(function (this: XMLHttpRequest) {
    setProgress((s) => ({ ...s, percent: 0, endTime: new Date() }));
  }, []);

  const onDrop = useCallback(
    async (files: File[]) => {
      if (!files || (Array.isArray(files) && files.length === 0)) {
        // @ts-ignore
        return onSelect(multiple ? [] : null);
      }
      // @ts-ignore
      if (files[0] === '') {
        // @ts-ignore
        return onSelect(multiple ? [] : null);
      }
      try {
        setProgress(defaultState());
        const formData = new FormData();

        files.forEach((file) => formData.append('files', file));
        const request = new XMLHttpRequest();
        request.open('post', `${API_URL}/${endpoint}`);

        request.withCredentials = true;
        request.upload.onprogress = onProgress(files);
        request.upload.onerror = onError;
        request.onerror = onError;
        request.onload = onLoad;
        request.timeout = 450000;
        request.send(formData);

        requestRef.current = request;
      } catch (e) {
        setProgress((s) => ({ ...s, percent: 0 }));
      }
    },
    [onSelect, multiple, endpoint, onError, onProgress, onLoad]
  );

  return useMemo(() => [progress.percent, onDrop], [progress.percent, onDrop]);
}
