import Add from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AptlyAvailability } from '@aptly-as/types';
import { SimpleModal } from '../../components/simple/SimpleCrud';
import ConfirmModal from '../../containers/Modal/ConfirmModal';
import { ModalButton } from '../../containers/Modal/ModalButton';
import deleteResource from '../../containers/Notification/deleteResource';
import useGetApiUrl, { SlugLevel, useTo } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { useOrganization } from '../Organization/OrganizationContext';
import { useProject } from '../Project/ProjectContext';
import { availabilitySchema } from './booking.schema';
import { AvailabilityContext } from './BookingContext';

function AvailabilityAdd() {
  const organization = useOrganization();
  const availability = useContext(AvailabilityContext);
  const project = useProject();
  const endpoint = useGetApiUrl('project') + '/bookings';
  const onEdit = useCallback(
    (data: AptlyAvailability) => availability.setState((s) => ({ ...s, data })),
    [availability]
  );

  return (
    <ModalButton color="primary" variant="contained" endIcon={<Add />} label={i18n.t('actions.startBooking')}>
      <SimpleModal
        title={i18n.t('actions.startBooking')}
        onEdit={onEdit}
        path={endpoint}
        data={{
          organization: organization._id,
          project: project._id,
        }}
        schema={availabilitySchema()}
        fields={[]}
      />
    </ModalButton>
  );
}

export function RemoveAvailability() {
  const navigate = useNavigate();
  const { data, setState } = useContext(AvailabilityContext);
  const path = useGetApiUrl('project', `/bookings/${data!._id}`);
  const to = useTo(SlugLevel.Project, '/motebooking');
  const handleDelete = useCallback(async () => {
    await deleteResource(path, () => {
      setState((s) => ({ ...s, data: undefined }));
      navigate(to);
    });
  }, [setState, navigate, to]);
  return (
    <ModalButton color="warning" endIcon={<DeleteOutlinedIcon />} label={i18n.t('actions.removeAppointment')}>
      <ConfirmModal
        onConfirm={handleDelete}
        actionIcon={<DeleteOutlinedIcon />}
        actionLabel={i18n.t('actions.removeAppointment')}
      >
        {i18n.t('paragraphs.areYouSureToDelete')}
      </ConfirmModal>
    </ModalButton>
  );
}

export default AvailabilityAdd;
