import { AptlyAlgorithm, AptlyProduct, AptlyScopes } from '@aptly-as/types';
import { createModal } from '../../../../containers/Modal/ModalContext';
import i18n from '../../../../libraries/i18n';
import { SectionProduct, UnitProduct } from '../../../../models/UnitTemplate/unit-template.types';
import { Field } from '../../../../deprecated/inputs';
import Scope from '../../../../libraries/scope/Scope';
import { SelectOption } from '../../../../mui/Select';
import CrudProductAlgorithm, { getAlgorithm } from './CrudProductAlgorithm';
import { Select, TextField } from '../../../../mui/Input';
import ChooseProductOld from '../../../../models/Product/ChooseProductOld';

export * from './logic';
export * from './CrudProductAlgorithm';

export const crudProductAlgorithmFields = (
  algorithms: AptlyAlgorithm[],
  sectionProduct?: SectionProduct,
  setDefault = true
): Field[] => [
  {
    name: 'algorithm',
    type: 'select',
    value: getAlgorithm(algorithms, sectionProduct, setDefault).value,
    label: getAlgorithm(algorithms, sectionProduct, setDefault).label,
    helpField: i18n.t('singles.calculation'),
    forceSave: !sectionProduct || (sectionProduct && !sectionProduct.algorithm),
    options: [
      { value: null as any, label: i18n.t('statuses.nothing') },
      ...algorithms.map((a) => ({
        label: a.name,
        value: a._id,
      })),
    ],
    hidden: !Scope.crud(AptlyScopes.ProjectUnitTemplatesAlgorithms, 'U'),
  },
  {
    name: '__previewCost',
    type: 'customComponent',
    value: null,
    label: '',
    renderComponent: (_setValue, getField) => {
      const algorithm = algorithms.find((a) => a._id === getField('algorithm').value);

      if (!algorithm) {
        return null;
      }

      return (
        <CrudProductAlgorithm
          baseCost={parseFloat(getField('unitCost').value as string)}
          algorithm={algorithm}
        />
      );
    },
    hidden: !Scope.crud(AptlyScopes.ProjectUnitTemplatesAlgorithms, 'U'),
  },
];

export const crudProductFromDB = (sectionProduct?: SectionProduct, defaultValue: boolean = true): Field => ({
  name: '__productFromDB',
  type: 'switch',
  value: sectionProduct ? Boolean(sectionProduct.product) : defaultValue,
  label: '',
  helpField: i18n.t('paragraphs.useProductFromDB'),
});

export const crudProductProductField = (
  orgSlug: string,
  projectID: string,
  sectionProduct?: SectionProduct
): Field => ({
  name: 'product',
  type: 'customComponent',
  showIfSet: '__productFromDB',
  value: sectionProduct?.product?._id || sectionProduct?.product || null,
  meta: sectionProduct?.product || {},
  label: sectionProduct?.product ? sectionProduct.product.name : '',
  renderComponent: (setValue, getField) => (
    <TextField
      fullWidth
      label={i18n.t('singles.product')}
      type="text"
      required
      value={getField('product').label}
      onClick={() =>
        createModal(
          <ChooseProductOld
            organizationSlug={orgSlug}
            projectID={projectID}
            onSelect={async (p) => {
              await setValue('availableProducts', [p._id], '');
              await setValue('product', p._id, p.name, p);
              if (p.unitCost) {
                await setValue('unitCost', p.unitCost, String(p.unitCost));
                const variantWithUnitCost = p.variants && p.variants.filter((x) => x.unitCost);
                if (variantWithUnitCost.length > 0) {
                  const variantPrices = variantWithUnitCost.map((x) => ({
                    variant: x._id,
                    price: x.unitCost,
                  }));
                  await setValue('variantPrices', variantPrices, '');
                }
              }
            }}
          />,
          {
            fullScreen: true,
          }
        )
      }
    />
  ),
});

export const crudProductSelectVariantField = (product?: UnitProduct): Field => ({
  name: 'variant',
  type: 'customComponent',
  value: product?.variant ?? null,
  label: '',
  showIfSet: 'product',
  options: [],
  renderComponent: (set, get) => {
    const variant = (get('variant').value as string) || '';
    const product = get('product').meta as AptlyProduct;
    const variants = product?.variants ?? [];
    const setValue = (value: string) => set('variant', value, value);
    if (variants.length === 0) return null;

    return (
      <Select
        value={variant}
        options={[
          { value: null, label: product?.name },
          ...variants.map((x: any) => ({ value: x._id, label: x.name })),
        ]}
        onChange={(e) => setValue(e.target.value)}
        name="variant"
        fullWidth
        label={i18n.t('singles.variant')}
      />
    );
  },
});

export const crudProductSimpleProductText = (sectionProduct?: SectionProduct): Field => ({
  name: 'text',
  type: 'text',
  value: sectionProduct && sectionProduct.text ? sectionProduct.text : null,
  label: sectionProduct && sectionProduct.text ? sectionProduct.text : '',
  helpField: 'Egen tekst',
  hideIfSet: '__productFromDB',
  required: true,
});
export const crudProductCustomTextDescription = (sectionProduct?: SectionProduct): Field => ({
  name: 'customDescription',
  type: 'text',
  helpField: `${i18n.t('singles.custom')} ${i18n.t('singles.description').toLowerCase()}`,
  value: sectionProduct && sectionProduct.customDescription ? sectionProduct.customDescription : '',
  label: sectionProduct && sectionProduct.customDescription ? sectionProduct.customDescription : '',
});

export const crudProductDocument = (unitProduct?: UnitProduct): Field => ({
  type: 'file',
  name: 'document',
  helpField: i18n.t('actions.upload'),
  label: '',
  value: unitProduct ? unitProduct.document : (null as any),
});

export const crudProductQuantity = (unitProduct?: UnitProduct): Field => ({
  name: 'quantity',
  type: 'number',
  value: unitProduct?.quantity ?? 1,
  label: unitProduct && unitProduct.quantity ? unitProduct.quantity.toString() : '1',
  helpField: i18n.t('singles.quantity'),
});

export const crudProductProducer = (unitProduct?: UnitProduct): Field => ({
  helpField: i18n.t('singles.producer'),
  name: 'producer',
  type: 'reference',
  value: unitProduct && unitProduct.producer ? unitProduct.producer.name : (null as any),
  label: unitProduct && unitProduct.producer ? unitProduct.producer.name : '',
  reference: {
    endpoint: 'producers/search',
    queryKey: 'name',
    labelKey: 'name',
  },
});

export const crudUnitTemplateCategory = (categories: SelectOption[], unitProduct?: UnitProduct): Field => ({
  helpField: i18n.t('singles.category'),
  name: 'unitTemplateCategory',
  type: 'select',
  options: [{ value: null as any, label: i18n.t('statuses.nothing') }, ...categories],
  value: unitProduct?.unitTemplateCategory || null,
  label: unitProduct?.unitTemplateCategory
    ? categories.find((x) => x.value === unitProduct.unitTemplateCategory)?.label || ''
    : '',
});

export const crudProductUnitCost = (unitProduct?: UnitProduct): Field => ({
  helpField: i18n.t('singles.price'),
  name: 'unitCost',
  type: 'number',
  value: unitProduct ? unitProduct.unitCost || 0 : 0,
  label: String(unitProduct ? unitProduct.unitCost : 0),
});

export const crudProductPrerequisitesTotalCost = (unitProduct?: UnitProduct): Field => ({
  helpField: i18n.t('paragraphs.triggerAtCost'),
  name: 'prerequisites.totalCost',
  type: 'number',
  value: unitProduct?.prerequisites?.totalCost || null,
  label: String(unitProduct?.prerequisites?.totalCost),
});
