import { useMemo } from 'react';
import { IUseCrudProps, IUseFieldRet, IUseFieldSchema, useFields } from '@ewb/reach-react';
import {
  ICrudFieldCustomGroupField,
  ICrudFieldData,
  ICrudFieldSimpleGroup,
  ICrudFieldsProps,
  ISimpleCrudObject,
} from '../crud/utils/crud.utils';
import { IApiError } from '../ApiError';
import { IActionButtonProps } from '../actions/buttons/ActionButtons';

export type ISimpleCrudGroupSchema<T extends ISimpleCrudObject, K extends keyof T> = [
  [group: string, fields: ICrudFieldCustomGroupField<T, K>[], options?: ICrudFieldSimpleGroup<T>],
];

export interface SimpleCrudModalProps<T extends ISimpleCrudObject> extends ICrudFieldsProps<T> {
  title?: string;
  onClose?: () => void;
  actionButtonProps?: Omit<IActionButtonProps, 'onClose'>;
}

export function useSimpleCrud<T extends ISimpleCrudObject, K extends keyof T>(
  crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>,
  fields: K[],
  data: Partial<T> = crud.state.data,
  useFieldProps?: IUseSimpleCrudFieldsProps<T>
): [IUseFieldRet<T, IApiError, ICrudFieldData<T>>, ISimpleCrudGroupSchema<T, K>] {
  const { state } = crud;
  return useSimpleCrudFields<T, K>(state.path, data, state.schema, fields, useFieldProps);
}

export type IUseSimpleCrudFieldsProps<T extends ISimpleCrudObject> = Partial<
  Omit<IUseCrudProps<T>, 'disableAutoSave'>
>;

export function useSimpleCrudFields<T extends ISimpleCrudObject, K extends keyof T, RET = T>(
  path: string,
  data: Partial<T>,
  schema: IUseFieldSchema<T, ICrudFieldData<T>>,
  fields: K[],
  useFieldProps?: IUseSimpleCrudFieldsProps<T>
): [IUseFieldRet<T, IApiError, ICrudFieldData<T>, RET>, ISimpleCrudGroupSchema<T, K>] {
  const crudFields = useFields<T, IApiError, ICrudFieldData<T>, RET>(path, data, schema, {
    idKey: '_id',
    disableAutoSave: true,
    initWithGet: false,
    ...useFieldProps,
  });

  const activeFields = useMemo(
    () => fields.reduce(toFieldGrouping<T>(crudFields as any), [['', []]] as ISimpleCrudGroupSchema<T, K>),
    [fields, crudFields]
  );
  return [crudFields, activeFields];
}

function toFieldGrouping<T extends ISimpleCrudObject>(crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>) {
  let index = 0;
  return <K extends keyof T>(groupFields: ISimpleCrudGroupSchema<T, K>, key: K) => {
    const f = crud.getField(key);
    const value = f.preValue ? f.preValue(crud, f.value) : f.value;
    const row: ICrudFieldCustomGroupField<T, K> = { ...f, field: key, value };
    const groupProps = typeof f.group === 'function' ? f.group(crud, row) : f.group;
    if (groupProps) {
      const groupField = groupFields.find((x) => x[0] === groupProps.name);
      if (groupField) {
        groupField[1].push(row);
      } else {
        groupFields.push([groupProps.name, [row], groupProps]);
        groupFields.push(['', []]);
        index = groupFields.length - 1;
      }
    } else {
      groupFields[index][1].push(row);
    }

    return groupFields;
  };
}
