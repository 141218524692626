import styled, { css } from 'styled-components';

interface WrapperProps {
  marginTop?: string;
  centerText?: boolean;
  maxWidth?: string;
  height?: string;
  width?: string;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};

  ${(props) =>
    props.centerText &&
    css`
      text-align: center;
    `};

  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};

  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `};

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

const Element = styled.div<{ maxWidth?: string }>`
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};
`;

type Props = {
  children: React.ReactNode;
  maxWidth?: string;
  height?: string;
  width?: string;
  centerText?: boolean;
  marginTop?: string;
};

function Centered(props: Props) {
  const { children, maxWidth, height, width, centerText, marginTop } = props;

  return (
    <Wrapper height={height} width={width} centerText={centerText} marginTop={marginTop}>
      <Element maxWidth={maxWidth}>{children}</Element>
    </Wrapper>
  );
}

export default Centered;
