import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined';
import { Alert } from '@mui/material';
import i18n from '../libraries/i18n';
import Button from '../mui/Button';

interface LinkDocumentProps {
  label: string;
  href: string;
}
export default function LinkDocument({ label, href }: LinkDocumentProps) {
  return (
    <Alert
      severity="info"
      action={
        <Button
          startIcon={<OpenInNewOutlined />}
          href={href}
          target="_blank"
          rel="noreferer noopener"
          size="small"
        >
          {i18n.t('actions.openInWindow')}
        </Button>
      }
    >
      {label}
    </Alert>
  );
}
