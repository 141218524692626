import { useCallback } from 'react';
import { ICreateModelOptions } from '../../containers/Modal/modal.types';
import { createModal } from '../../containers/Modal/ModalContext';
import { ICrudSchema, ISimpleCrudObject } from '../crud/utils/crud.utils';
import { ISimpleModalProps, SimpleModal } from './SimpleCrud';
import { AptlyFieldType } from '@aptly-as/types';

export type IUseSimpleBulkPatchProps<T extends ISimpleCrudObject> = Omit<
  ISimpleModalProps<T, T[]>,
  'path' | 'schema' | 'data' | 'disableFields' | 'onEdit'
> &
  Partial<Pick<ISimpleModalProps<T, T[]>, 'onEdit'>>;
export type IUseSimpleBulkPatchFn<T extends ISimpleCrudObject> = (
  items: Partial<T>[],
  data?: Partial<T>,
  fields?: (keyof T)[]
) => void;

export default function useSimpleBulkPatch<T extends ISimpleCrudObject>(
  path: string,
  schema: ICrudSchema<T>,
  map: (fn: (item: T) => T) => void,
  props: IUseSimpleBulkPatchProps<T>,
  modalOptions?: ICreateModelOptions
): IUseSimpleBulkPatchFn<T> {
  return useCallback(
    (items: Partial<T>[], data: Partial<T> = {}, fields: (keyof T)[] = props.fields) => {
      const handleOnEdit = (newData: T[]) => {
        map((x) => {
          const found = newData.find((data) => data._id === x._id);
          if (found) return { ...x, ...found };
          return x;
        });
        if (props.onEdit) props.onEdit(newData);
      };

      return createModal(
        <SimpleModal<T, T[]>
          {...props}
          path={path}
          schema={{ ...schema, bulkData: { type: AptlyFieldType.Hidden } }}
          data={{ ...data, ids: items.map((x) => x._id), bulkData: items } as any}
          fields={['ids' as keyof T, ...fields]}
          onEdit={handleOnEdit}
        />,
        modalOptions
      );
    },
    [path, schema, props, map, modalOptions]
  );
}
