import GooglePlaceAutocomplete from '../../GooglePlaceAutocomplete';
import { ISimpleComponentProps } from './simple-components.utils';

export interface ISimpleGooglePlaceProps<T extends object> extends ISimpleComponentProps<T, any> {}

export default function SimpleGooglePlace<T extends object>({ field, onChange }: ISimpleGooglePlaceProps<T>) {
  const { value, defaultValue, edited, options, ...rest } = field;

  return (
    <GooglePlaceAutocomplete
      onChange={onChange}
      defaultValue={value?.formattet_address || defaultValue.formatted_address}
      fullWidth
      {...rest}
    />
  );
}
