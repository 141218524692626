import styled from 'styled-components';
import { gutter } from '../../utils/spacing';
import { notifications } from './notification.utils';

export default function Notifications() {
  return <Wrapper>{notifications.value.map((x) => x.element)}</Wrapper>;
}

const Wrapper = styled.div`
  position: fixed;
  top: ${gutter};
  right: ${gutter};
  z-index: 4000;
  display: flex;
  flex-direction: column;
  margin: calc(-${gutter} / 4) 0;

  > * {
    margin: calc(${gutter} / 4) 0;
  }
`;
