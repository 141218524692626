import { useCallback, useMemo } from 'react';
import { AptlyOrganizationMember, AptlyScopes, AptlyUser } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import { AddButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import {
  dataGridActions,
  dataGridSearchImageColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import SearchDataGrid, { ISearchDataGridProps } from '../../components/Search/SearchDataGrid';
import Search from '../../components/Search/Search';
import {
  IOrganizationMemberSchema,
  organizationInviteSchema,
  organizationInviteSchemaFields,
  organizationMemberSchema,
  organizationMemberSchemaFields,
  organizationRolesOptions,
} from './organization.schema';
import createMediaURL from '../Media/createMediaURL';
import Scope from '../../libraries/scope/Scope';
import { toOrganizationMemberPosition, toPermissionTranslation } from './organization.utils';
import { DataGridSelectMultipleEdit } from '../../components/Search/search-data-grid/DataGridSelectEdit';
import useSimplePost from '../../components/simple/useSimplePost';

const columns = (): GridColDef[] => [
  {
    ...dataGridSearchImageColumn('image', {}, (params) => {
      const member = params.row as AptlyOrganizationMember;
      const url = (member.user as AptlyUser).profileImage;
      return url ? createMediaURL(url) : undefined;
    }),
    editable: false,
  },
  {
    type: 'string',
    field: 'user',
    headerName: i18n.t('singles.name'),
    valueGetter: (value: AptlyUser) => value.fullName,
    flex: 1,
  },
  {
    type: 'string',
    field: 'email',
    headerName: i18n.t('singles.email'),
    valueGetter: (_, row) => (row.user as AptlyUser).email,
    flex: 1,
  },
  {
    type: 'string',
    field: 'position',
    headerName: i18n.t('singles.position'),
    valueGetter: (value) => toOrganizationMemberPosition(value),
    flex: 1,
  },
  {
    type: 'string',
    field: 'phone',
    headerName: i18n.t('singles.phone'),
    valueGetter: (_, row) => (row.user as AptlyUser).phone,
    flex: 1,
  },
  {
    type: 'string',
    field: 'permissions',
    headerName: i18n.t('singles.permissions'),
    renderCell: (params) => params.value.map(toPermissionTranslation).join(', '),
    flex: 1,
    editable: true,
    renderEditCell: (params) => (
      <DataGridSelectMultipleEdit params={params} options={organizationRolesOptions()} />
    ),
  },
  {
    type: 'number',
    field: 'projects',
    headerName: i18n.t('singles.projects'),
    valueGetter: (value: string[]) => value?.length ?? i18n.t('singles.all'),
    width: 150,
  },
  dataGridActions(),
];

function OrganizationMembers() {
  const path = useApiUrl(SlugLevel.Organization, 'members');
  const invitePath = useApiUrl(SlugLevel.Organization, 'invites');
  const gridColumns = useMemo(() => columns(), []);
  const schema = useMemo(() => organizationMemberSchema(), []);
  const initialState: ISearchDataGridProps['initialState'] = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          projects: Scope.crud(AptlyScopes.OrganizationMembers, 'U'),
        },
      },
    }),
    []
  );
  const inviteUser = useSimplePost(invitePath, organizationInviteSchema(), {
    fields: organizationInviteSchemaFields,
    title: i18n.t('actions.addUser'),
    useFieldsProps: {
      dontSetStateOnPost: true,
    },
  });

  const buttonActions = useCallback(() => {
    const ret = [];

    if (Scope.crud(AptlyScopes.OrganizationMembers, 'C')) {
      ret.push(
        <AddButtonWithIcon onClick={() => inviteUser()}>{i18n.t('actions.inviteClient')}</AddButtonWithIcon>
      );
      if (Scope.crud(AptlyScopes.AdminUsers, 'R')) {
        ret.push(<SearchCreateAction title={i18n.t('actions.addUser')} />);
      }
    }

    return ret;
  }, [inviteUser]);

  return (
    <Search<IOrganizationMemberSchema>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.OrganizationMembers}
      header={{ title: i18n.t('singles.members') }}
      post={{ title: i18n.t('singles.members') }}
      patch={{
        fields: organizationMemberSchemaFields.slice(1),
        title: (data) => (data.user as AptlyUser).fullName,
        preData: (data) => ({ ...data, filterProjects: Boolean(data.projects) }),
      }}
      schema={schema}
      fields={organizationMemberSchemaFields}
      options={{ disableBulk: true }}
      reach={{
        query: { sort: 'name', $populate: 'projects:_id,name;user:_id,fullName,email,phone,profileImage' },
      }}
      buttonActions={buttonActions}
    >
      {() => <SearchDataGrid columns={gridColumns} initialState={initialState} />}
    </Search>
  );
}

export default OrganizationMembers;
