import { AptlyScopes } from '@aptly-as/types';
import { useMemo } from 'react';
import { IScopeLevelItem } from './useScopeLevel';
import useScopeProps, { IUseScopePropsProps } from './useScopeProps';
import Scope from './Scope';

export interface IUseScope {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export type IUseScopeProps = AptlyScopes | IUseScope;

export default function useScopeModel(props: IUseScopeProps): IUseScope {
  return useMemo(
    () =>
      typeof props === 'string'
        ? {
            create: Scope.crud(props, 'C'),
            read: Scope.crud(props, 'R'),
            update: Scope.crud(props, 'U'),
            delete: Scope.crud(props, 'D'),
          }
        : props,
    [props]
  );
}

export const useScopePropsModel = (props: IUseScopePropsProps, item?: IScopeLevelItem) => {
  const scope = useScopeProps(props, item);
  return useScopeModel(scope);
};
