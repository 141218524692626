import { useMemo } from 'react';
import { AptlyProject } from '@aptly-as/types';
import createMediaURL from '../Media/createMediaURL';

export function useProjectSubheader(project: AptlyProject) {
  return useMemo(() => {
    const _subheader = [];
    if (project.number) _subheader.push(project.number);
    if (project.developer) _subheader.push(project.developer);
    if (project.field) _subheader.push(project.field);
    return _subheader.join(' - ');
  }, [project]);
}

export function useProjectLogo(project: AptlyProject) {
  return useMemo(() => {
    if (project.theme?.images?.logo) {
      return createMediaURL(project.theme.images.logo, {
        width: 80,
        height: 80,
        crop: 'fit',
      });
    }
    return null;
  }, [project]);
}
