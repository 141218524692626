import { AptlyProduct, AptlyScopes } from '@aptly-as/types';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Grid from '@mui/material/Grid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useContext, useMemo } from 'react';
import { SearchExportButton } from '../../components/actions/buttons/ExportButton';
import ColorPalette from '../../components/ColorPalette';
import Search, { ISearchSwitchProps, SearchFieldText, SearchSwitch } from '../../components/Search/Search';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import SearchImportAction from '../../components/Search/search-actions/SearchImportAction';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridCopyAction,
  DataGridDeleteAction,
} from '../../components/Search/search-data-grid/data-grid.actions';
import {
  dataGridSearchNameColumn,
  dataGridSearchReferenceColumn,
  dataGridSearchTagsColumn,
  dataGridSearchTextColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import { DataGridFieldClickEdit } from '../../components/Search/search-data-grid/DataGridFieldClickEdit';
import { useDataGridEditText } from '../../components/Search/search-data-grid/DataGridTextEdit';
import {
  searchFieldProducerReference,
  SearchFieldReference,
  searchFieldTagsReference,
} from '../../components/Search/search-fields/SearchFields';
import {
  ISearchHeaderProps,
  ISearchOptions,
  SearchCrudContext,
  toSearchPatchPath,
} from '../../components/Search/search.utils';
import SearchDataGrid, { ISearchDataGridProps } from '../../components/Search/SearchDataGrid';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import {
  IUseCrudSearchBulkProps,
  IUseCrudSearchPatchProps,
  IUseCrudSearchPostProps,
} from '../../libraries/reach/useCrudSearch';
import { IUsePaginatedSearchProps } from '../../libraries/reach/usePaginatedSearch';
import { dataGridColorColumn, dataGridImagesColumn } from '../../mui/x-data-grid/dataGrid.cols';
import { OrganizationContext } from '../Organization/OrganizationContext';
import { DataGridEditProduct } from './DataGridEditProduct';
import DataGridVariantOrder from './DataGridVariantOrder';
import {
  IProductSchema,
  productBulkKeys,
  productEditKeys,
  productImportSchema,
  productNewKeys,
  productSchema,
} from './product.schema';
import { copyProduct, getProductScope, productGroupProps } from './product.utils';
import { ProductToolbar } from './ProductsToolbar';

interface Props {
  level?: SlugLevel;
}

const renderValue = (value: string): string | JSX.Element | null => `${value} `;
const getVariantValues = <T extends AptlyProduct, K extends keyof (T | AptlyProduct['variants'][0])>(
  product: T,
  key: K,
  render = renderValue
) => {
  const values = new Set(typeof product[key] === 'string' ? [product[key] as string] : []);
  if (product.variants) {
    for (const variant of product.variants) {
      if (key in variant && typeof variant[key] === 'string') {
        values.add(variant[key] as string);
      }
    }
  }
  return [...values].map(render);
};

const printColorValues = (product: AptlyProduct) => {
  const values = getVariantValues(product, 'color', (x) => (x ? <ColorPalette key={x} color={x} /> : null));
  return values.length === 0 ? product.colorLabel || '' : values;
};

const productColumns = (): GridColDef[] => [
  dataGridImagesColumn('images', {
    editable: true,
    renderEditCell: (params) => <DataGridFieldClickEdit params={params} keys={['images']} />,
  }),
  dataGridSearchNameColumn(),
  dataGridSearchTextColumn('productNumber', i18n.t('singles.productNumber')),
  dataGridSearchReferenceColumn('producer', i18n.t('singles.producer'), () => ({
    endpoint: 'api/v1/producers',
    query: { select: '_id,name', sort: '-featured,name', as: 'array', limit: '5' },
    singular: true,
  })),
  dataGridSearchTagsColumn(),
  {
    type: 'string',
    field: 'products',
    headerName: i18n.t('singles.variants'),
    minWidth: 150,
    sortable: false,
    renderCell: (params) => {
      if (params.row.products && params.row.products.length > 0) {
        return `(${params.row.products.length}) ${params.row.products
          .map((x: any) => x.productNumber)
          .join('|')}`;
      }
      return 'N/A';
    },
    renderEditCell: useDataGridEditText,
  },
  dataGridColorColumn('color', {
    renderCell: (params) => printColorValues(params.row),
  }),
  {
    type: 'string',
    field: 'organization',
    headerName: i18n.t('statuses.active'),
    display: 'flex',
    renderCell: (params) => (params.row.organization ? <CheckBoxOutlineBlank /> : <CheckBoxOutlinedIcon />),
  },
  dataGridActionProps((params) => <ProductActions params={params} />, {
    minWidth: 230,
  }),
];

export default function Products({ level = SlugLevel.Base }: Props) {
  const org = useContext(OrganizationContext);
  const path = useApiUrl(level, 'products');
  const schema = useMemo(() => productSchema(org.data.producer, true), [org.data.producer]);
  const patchPath = useMemo(() => toSearchPatchPath('products', org.data), [org.data]);

  const [scopeModel, searchSwitchProps] = useMemo<
    [AptlyScopes, Omit<ISearchSwitchProps, 'field'> | null]
  >(() => {
    if (org.data.producer) {
      return [AptlyScopes.OrganizationProducts, null];
    }
    if (level === SlugLevel.Project) {
      return [
        AptlyScopes.ProjectProducts,
        { offValue: 'project', label: i18n.t('paragraphs.showMeMoreProjectProducts') },
      ];
    }
    if (level === SlugLevel.Organization) {
      return [
        AptlyScopes.OrganizationProducts,
        { offValue: 'organization', label: i18n.t('paragraphs.showMeMoreOrganizationProducts') },
      ];
    }
    return [AptlyScopes.AdminProducts, null];
  }, [level, org]);

  const reachProps: IUsePaginatedSearchProps<AptlyProduct> = useMemo(
    () => ({
      paginationMode: 'server',
      query: {
        select:
          '_id,name,title,productNumber,description,images,length,width,height,organization,project,variants,series,style,material,color,colorLabel',
        sort: 'name',
        $populate: 'producer:_id,name;tags:_id,name;products:_id,name,productNumber',
        archived: false,
        level: '',
      },
    }),
    []
  );

  const searchFields = useMemo(
    () => [
      <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      <SearchFieldText
        key="productNumber"
        field="productNumber"
        queryKey="$productNumber"
        label={i18n.t('singles.productNumber')}
      />,
      ...(org.data.producer
        ? []
        : [<SearchFieldReference key="producer" {...searchFieldProducerReference()} />]),
      <SearchFieldReference key="tags" {...searchFieldTagsReference()} />,
      searchSwitchProps ? (
        [
          { xs: 12 },
          <Grid key="level" container justifyContent="center">
            <SearchSwitch field="level" onValue="" {...searchSwitchProps} />
          </Grid>,
        ]
      ) : (
        <></>
      ),
    ],
    [scopeModel, searchSwitchProps, org.data.producer]
  );
  const buttonActions = useCallback(
    () => [
      <SearchExportButton key="export" endpoint={`${path}/export`} />,
      <SearchImportAction
        key="import"
        path={`${path}/import`}
        schema={productImportSchema(org.data.producer)}
        disableUnshift
      />,
      <SearchCreateAction key="create" keys={productNewKeys} />,
    ],
    [path]
  );

  const header: ISearchHeaderProps = useMemo(
    () => ({
      title: i18n.t('singles.products'),
    }),
    []
  );
  const options: ISearchOptions = useMemo(
    () => ({
      defaultShow: true,
    }),
    []
  );

  const post: IUseCrudSearchPostProps<AptlyProduct> = useMemo(
    () => ({
      title: i18n.t('actions.newProduct'),
    }),
    []
  );
  const patch: IUseCrudSearchPatchProps<AptlyProduct> = useMemo(
    () => ({
      title: (item: any) => String(item.name),
      contentProps: { groupProps: productGroupProps },
    }),
    []
  );
  const bulk: IUseCrudSearchBulkProps<AptlyProduct> = useMemo(
    () => ({
      title: i18n.t('actions.edit'),
      fields: productBulkKeys,
    }),
    []
  );

  return (
    <Search<IProductSchema>
      path={path}
      patchPath={patchPath}
      scope={scopeModel}
      fields={productEditKeys}
      header={header}
      options={options}
      post={post}
      patch={patch}
      bulk={bulk}
      schema={schema}
      reach={reachProps}
      searchFields={searchFields}
      buttonActions={buttonActions}
      level={level}
    >
      {() => <ProductDataGrid />}
    </Search>
  );
}

function ProductDataGrid() {
  const org = useContext(OrganizationContext);
  const { query, level } = useContext(SearchCrudContext);
  const columns = useMemo(() => productColumns(), []);
  const isCellEditable = useCallback((cell: any) => getProductScope(cell.row, org.data).update, [org.data]);
  const initialState: ISearchDataGridProps['initialState'] = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          organization: !!org.data?.producer && level === SlugLevel.Organization,
        },
      },
    }),
    [level, org]
  );
  const props: Partial<ISearchDataGridProps> = useMemo(
    () =>
      !query.level
        ? {
            isCellEditable,
            isRowSelectable: (cell) => getProductScope(cell.row as any, org.data).update,
            Toolbar: ProductToolbar,
          }
        : { isCellEditable, checkboxSelection: false, Toolbar: () => null },
    [query.level, isCellEditable]
  );

  return <SearchDataGrid columns={columns} initialState={initialState} {...props} />;
}

interface ProductActionsProps {
  params: GridRenderCellParams;
}

function ProductActions({ params }: ProductActionsProps) {
  const org = useContext(OrganizationContext);
  const product = params.row;
  const scope = getProductScope(product, org.data);

  return (
    <DataGridActionWrapper>
      {scope.update && product.variants?.length > 1 && <DataGridVariantOrder params={params} />}
      {scope.update ? (
        <DataGridEditProduct params={params} update />
      ) : (
        <DataGridEditProduct params={params} />
      )}
      {scope.update && <DataGridCopyAction<AptlyProduct> params={params} preCopy={copyProduct} />}
      {scope.delete && <DataGridDeleteAction params={params} />}
    </DataGridActionWrapper>
  );
}
