import { API_URL } from '../../env';
import createError from '../../containers/Error/createError';
import { busyNotification } from '../../containers/Notification/Notification';
import i18n from '../i18n';
import { handleSessionTimeout } from '../../models/User/user.utils';
import { getCookieValue } from '../../utils/window';

export type RequestData = {
  [key: string]: any;
};

type RequestHeaders = {
  [key: string]: string;
};

export interface RequestOpts<T = RequestData> {
  method?: string;
  data?: T;
  headers?: RequestHeaders;
  noAuth?: boolean;
  multipart?: boolean;
  notificationMessage?: string;
  noJSON?: boolean;
  customAuth?: string;
  dontFetch?: boolean;
}

/**
 * @deprecated should not be used anymore. Use reach.api instead
 */
async function apiRequest<T>(endpoint: string, opts?: RequestOpts): Promise<T> {
  opts = opts || { method: 'GET' };

  if (!('method' in opts)) {
    opts.method = 'GET';
  }

  const queryParams = opts.method === 'GET' ? `/?${new URLSearchParams(opts.data).toString()}` : '';

  const headers = opts.headers || {};
  let body;

  if (opts.method !== 'GET') {
    if (!opts.multipart) {
      headers['Content-Type'] = 'application/json';
    }

    if (opts && opts.data !== undefined) {
      body = opts.multipart ? createForm(opts.data) : JSON.stringify(opts.data);
    }
  }

  if (opts.customAuth) {
    headers['Authorization'] = `Bearer ${opts.customAuth}`;
  }

  const csrfCookie = getCookieValue('cid');
  if (csrfCookie) {
    headers['X-CSRF-Token'] = csrfCookie;
  }

  let busy: null | (() => void) = null;
  if (opts.notificationMessage) {
    busy = busyNotification(opts.notificationMessage);
  }

  try {
    const url = `${API_URL}/${endpoint}${queryParams}`;
    let responseData: any = {};

    const response = await fetch(url, {
      method: opts.method,
      credentials: 'include',
      mode: 'cors',
      headers,
      body,
    });

    try {
      responseData = opts.noJSON ? response : await response.json();
    } catch (e) {}

    switch (response.status) {
      case 200:
      case 201:
      case 202:
        return responseData;
      case 302:
        return responseData;
      case 400:
        // eslint-disable-next-line
        throw {
          message: 400,
          status: 400,
          data: responseData.error,
        };
      case 403:
        // eslint-disable-next-line
        throw {
          message: 403,
          status: 403,
        };
      case 404:
        // eslint-disable-next-line
        throw {
          message: 404,
          status: 404,
        };
      case 440:
        handleSessionTimeout(true);
        // eslint-disable-next-line
        throw {
          message: 'Session timout',
          status: 440,
        };
      default:
        // eslint-disable-next-line
        throw {
          message: responseData ? responseData.title : response.statusText,
          status: response.status,
          data: responseData ? responseData.error : undefined,
          detail: responseData ? responseData.detail : undefined,
        };
    }
  } catch (e: any) {
    switch (e.message) {
      case 'Failed to fetch':
        throw createError('Tidsavbrudd', 408);
      case 400:
        throw createError('Feil input til server: ' + e.data, 400);
      case 403:
        throw createError(i18n.t('statuses.noAccess'), 403);
      case 404:
        throw createError('Server kjente ikke igjen url: ' + e.data, 404);
      default:
        throw createError(e.message, e.status, e.data, e.detail);
    }
  } finally {
    if (busy) {
      busy();
    }
  }
}

function createForm(data: RequestData): FormData {
  const formData = new FormData();
  for (const k in data) {
    if (data.hasOwnProperty(k)) {
      if (Array.isArray(data[k]) && typeof data[k] !== 'string') {
        data[k].map((file: any) => formData.append(k, file));
      } else {
        formData.append(k, data[k]);
      }
    }
  }

  return formData;
}

export default apiRequest;
