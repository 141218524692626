import { AptlyInquiryType } from '@aptly-as/types';
import i18n from '../../libraries/i18n';

/**
 * @deprecated Use AptlyIntegration instead
 */
export type IIntegrations = 'checkd' | 'boligmappa';

export const translateInquiryType = (): { [key in AptlyInquiryType]: string } => ({
  question: i18n.t('singles.question'),
  basic: i18n.t('singles.basic'),
  complaint: i18n.t('singles.complaint'),
  system: i18n.t('singles.system'),
});
