import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React from 'react';
import styled from 'styled-components';
import { CLIENT_URL } from '../../env';
import { useProject } from '../../models/Project/ProjectContext';
import { ButtonWithIcon } from '../../mui/Button';
import i18n from '../../libraries/i18n';

const IFrame = styled.iframe`
  width: 100%;
  height: 500px;
  border: 0;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-radius: 3px;
`;

interface Props {
  endpoint: string;
  newWindow?: boolean;
  isUnit?: boolean;
  label?: string;
}

export default function ClientIFrame({
  endpoint,
  newWindow,
  isUnit,
  label = i18n.t('actions.openPreview'),
}: Props) {
  const project = useProject();
  const url = isUnit
    ? `${CLIENT_URL}/${endpoint}`
    : `${CLIENT_URL}/dashboard/${endpoint}${project?._id ? `?projectId=${project?._id}` : ''}`;

  return (
    <React.Fragment>
      {newWindow ? (
        <ButtonWithIcon
          color="secondary"
          variant="outlined"
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.open(url, '_blank');
            }
          }}
        >
          {label} <VisibilityOutlinedIcon />
        </ButtonWithIcon>
      ) : (
        <IFrame src={url} />
      )}
    </React.Fragment>
  );
}
