import { AptlyOrder, AptlyOrderPaymentStatus, AptlyOrderStatus } from '@aptly-as/types';
import i18n from '../../libraries/i18n';

export const orderStatusOptions = () => [
  {
    label: i18n.t('singles.all'),
    value: '',
  },
  { value: AptlyOrderStatus.New, label: i18n.t('statuses.new') },
  { value: AptlyOrderStatus.ReadyToSign, label: i18n.t('statuses.readyToSign') },
  { value: AptlyOrderStatus.Signed, label: i18n.t('singles.creatingSignedReceipt') },
  { value: AptlyOrderStatus.FileReceived, label: i18n.t('statuses.signed') },
  { value: AptlyOrderStatus.Payment, label: i18n.t('singles.payment') },
  { value: AptlyOrderStatus.Completed, label: i18n.t('statuses.completed') },
  { value: AptlyOrderStatus.Invalid, label: i18n.t('statuses.annulled') },
];

export const orderStatusLabel = (status: AptlyOrderStatus) => {
  const option = orderStatusOptions().find((x) => x.value === status);
  return option?.label || status;
};

export const orderStatusPaymentOptions = () => [
  { value: AptlyOrderPaymentStatus.Pending, label: i18n.t('statuses.pendingPayment') },
  { value: AptlyOrderPaymentStatus.Authorized, label: i18n.t('statuses.reserved') },
  { value: AptlyOrderPaymentStatus.Captured, label: i18n.t('statuses.paid') },
];
export const orderStatusPaymentLabel = (status?: AptlyOrderPaymentStatus) => {
  const option = orderStatusPaymentOptions().find((x) => x.value === status);
  return option ? option.label : '';
};

export function orderCompletedAt(order: AptlyOrder) {
  if (order.paymentSession?.capturedAt) {
    return order.paymentSession.capturedAt;
  }
  const signedAt = orderSignedAt(order);
  if (signedAt) return signedAt;
  if (order.status === AptlyOrderStatus.Completed && order.approved) {
    return order.approved?.at || '';
  }
  return '';
}

export function orderSignedAt(order: AptlyOrder) {
  let _completedAt =
    order.signage.customerSignedAt || order.signage.organizationSignedAt || order.signage?.signedAt;
  if (!_completedAt && order.status === AptlyOrderStatus.Completed && order.signees && order.signees.length) {
    _completedAt = order.signees[0].signedAt || '';
  }
  return _completedAt || '';
}
