import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { InputProps } from '@mui/material/Input/Input';
import Palette from '@mui/icons-material/Palette';
import { IconButton } from '../mui/Button';
import { ncsGetHex } from '../libraries/ncs-color';

interface IColorPickerProps extends Omit<InputProps, 'onChange'> {
  value: string;
  label: ReactNode;
  onChange?: (color: string) => void;
}

export default function ColorPicker({ value, label, onChange, type, ...rest }: IColorPickerProps) {
  const debounceRef = useRef<any>(null);
  const colorRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState(
    useMemo(() => {
      const ncs = ncsGetHex(value, true);
      return {
        value,
        hex: ncs || value,
      };
    }, [value])
  );

  const handleInternalState = useCallback((value: any) => {
    const ncs = ncsGetHex(value, true);
    if (value[0] === '#' && (value.length === 4 || value.length === 7)) {
      setState({ value, hex: value });
    } else if (ncs) {
      setState({ value, hex: ncs });
    } else {
      setState({ value, hex: '' });
    }
  }, []);

  const handleChange = useCallback(
    (e: any) => {
      handleInternalState(e.target.value);
      if (typeof onChange === 'function') {
        onChange(e);
      }
    },
    [handleInternalState, onChange]
  );

  const handleColorChange = useCallback(
    (e: any) => {
      e.currentTarget = e.target;
      e.persist();
      if (debounceRef.current) {
        debounceRef.current.cancel();
      }
      debounceRef.current = debounce(handleChange, 250);
      debounceRef.current(e);
    },
    [handleChange]
  );

  const handleColorSelect = useCallback(() => {
    if (colorRef.current) {
      colorRef.current.click();
    }
  }, [colorRef]);

  return (
    <FormControl fullWidth variant="outlined" disabled={rest.disabled}>
      <InputLabel htmlFor={rest.id}>{label}</InputLabel>
      <OutlinedInput
        ref={inputRef}
        type="text"
        value={value}
        onChange={handleChange}
        endAdornment={
          rest.disabled ? null : (
            <InputAdornment position="end">
              <IconButton onClick={handleColorSelect} style={{ backgroundColor: 'lightgrey' }} size="large">
                <Palette style={{ fill: state.hex || undefined }} />
              </IconButton>
            </InputAdornment>
          )
        }
        fullWidth
        label={label}
        {...rest}
      />
      <input
        defaultValue={state.value}
        type="color"
        ref={colorRef}
        onChange={handleColorChange}
        style={{ visibility: 'hidden', position: 'absolute' }}
        disabled={rest.disabled}
      />
    </FormControl>
  );
}
