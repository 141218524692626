import { Reach, ReachService } from '@ewb/reach-react';
import { API_URL } from '../../env';

export const reachService = new ReachService(API_URL, {
  options: {
    mode: 'cors',
    credentials: 'include',
    logoutOptions: {
      status: 440,
    },
  },
  csrf: { cookie: 'cid' },
  logout: (response) => {
    if (response.status === 440) {
      window.localStorage.setItem('redirect', window.location.pathname);
      localStorage.removeItem('state');
      window.location.href = API_URL + '/auth/sso/logout';
    }
  },
});

export const reach = new Reach(reachService);
