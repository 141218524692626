import { useCallback, useMemo } from 'react';
import { AptlyMediaSrc } from '@aptly-as/types';
import { IUseFieldRet } from '@ewb/reach-react';
import { IApiError } from '../components/ApiError';
import { CrudModelSchemaRetValue, ICrudFieldData } from '../components/crud/utils/crud.utils';
import useDotState from './useDotState';

export default function useCrudImageMedia<T extends AptlyMediaSrc>(
  crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>,
  _field: CrudModelSchemaRetValue<T, AptlyMediaSrc>,
  onChange: (value: string | AptlyMediaSrc | null) => void,
  key: keyof T,
  mediaKey?: keyof T
) {
  const [media, setMediaValue] = useDotState<AptlyMediaSrc | null>(crud.state.data, String(mediaKey || key));
  const { setField } = crud;

  const handleOnChange = useCallback(
    async (newMedia: AptlyMediaSrc) => {
      if (mediaKey) {
        // @ts-ignore This is a middleground to save src values to another field to use them if available.
        await setField(mediaKey)(newMedia);
        onChange(newMedia.src);
      } else {
        onChange(newMedia);
      }

      setMediaValue(newMedia);
    },
    [setField, mediaKey, onChange, setMediaValue]
  );

  const handleOnCrop = useCallback(
    async (area: any, location: any) => {
      if (media) {
        setMediaValue({ ...(media || {}), area, location });
      }
      if (mediaKey) {
        // @ts-ignore This is a middleground to save src values to another field to use them if available.
        await setField(mediaKey)({ ...(media || {}), area, location });
      }
    },
    [setField, mediaKey, media, setMediaValue]
  );

  const handleOnDelete = useCallback(async () => {
    if (mediaKey) {
      // @ts-ignore This is a middleground to save src values to another field to use them if available.
      await setField(mediaKey)(null);
    }
    setMediaValue(null);
    return onChange(null);
  }, [setField, mediaKey, onChange, setMediaValue]);

  return useMemo(
    () => ({
      media,
      handleOnChange,
      handleOnCrop,
      handleOnDelete,
    }),
    [media, handleOnChange, handleOnCrop, handleOnDelete]
  );
}
