import { useMemo } from 'react';
import styled from 'styled-components';
import { AptlyUnitTemplateCategory, AptlyUnitTemplateCategorySection } from '@aptly-as/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import TableBody from '@mui/material/TableBody';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Table from '@mui/material/Table';
import { CopyIconButton, DeleteIconButton, EditIconButton } from '../../../components/actions/icons/Icons';
import { AccordionSubtitle, AccordionTitle } from '../../../mui/Accordion';
import { IconButton } from '../../../mui/Button';
import { IPackageRowProps, IProductRowProps, PackageRow, ProductRow } from '../product/ProductRow';
import { cozySpacing } from '../../../utils/spacing';
import { getUnitTemplateCategorySubtitle, getUnitTemplateSectionSubtitle } from './unit-template.data';

interface CategoryDragOverlayProps {
  category: AptlyUnitTemplateCategory;
}

export function CategoryDragOverlay({ category }: CategoryDragOverlayProps) {
  const subtitle = useMemo(() => getUnitTemplateCategorySubtitle(category), [category]);
  return (
    <Accordion disableGutters>
      <UnitTemplateAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <UnitTemplateGrabButton size="small" grabbing>
          <DragIndicatorIcon />
        </UnitTemplateGrabButton>
        <AccordionTitle>{category.name}</AccordionTitle>
        <AccordionSubtitle>{subtitle}</AccordionSubtitle>
      </UnitTemplateAccordionSummary>
    </Accordion>
  );
}

interface SectionDragOverlayProps {
  section: AptlyUnitTemplateCategorySection;
}
export function SectionDragOverlay({ section }: SectionDragOverlayProps) {
  const subtitle = useMemo(() => getUnitTemplateSectionSubtitle(section), [section]);
  return (
    <Accordion disableGutters>
      <UnitTemplateAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <UnitTemplateGrabButton size="small" grabbing>
          <DragIndicatorIcon />
        </UnitTemplateGrabButton>
        <AccordionTitle>{section.name}</AccordionTitle>
        <AccordionSubtitle>{subtitle}</AccordionSubtitle>
      </UnitTemplateAccordionSummary>
    </Accordion>
  );
}

export function ProductDragOverlay(props: IProductRowProps) {
  return (
    <Table size="small">
      <TableBody>
        <ProductRow {...props}>
          <EditIconButton size="medium" />
          <CopyIconButton size="medium" />
          <DeleteIconButton size="medium" />
        </ProductRow>
      </TableBody>
    </Table>
  );
}
export function PackageDragOverlay(props: IPackageRowProps) {
  return (
    <Table size="small">
      <TableBody>
        <PackageRow {...props}>
          <EditIconButton size="medium" />
          <CopyIconButton size="medium" />
          <DeleteIconButton size="medium" />
        </PackageRow>
      </TableBody>
    </Table>
  );
}

export const UnitTemplateAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
  }
`;

export const UnitTemplateGrabButton = styled(({ grabbing, ...props }: any) => <IconButton {...props} />)<{
  grabbing?: boolean;
}>`
  cursor: ${(props) => (props.grabbing ? 'grabbing' : 'grab')};
  margin-right: ${cozySpacing}px;
  padding: 0;
`;
