import React, { useMemo } from 'react';
import Edit from '@mui/icons-material/Edit';
import { createModal } from '../../../containers/Modal/ModalContext';

import {
  IIntegrationProps,
  IntegrationButton,
  integrationChange,
  integrationCreate,
  integrationFields,
} from './orgnaizationSettings.utils';
import useGetApiUrl from '../../../hooks/useGetApiUrl';
import Card from '../../../mui/Card';
import List, { ListItem } from '../../../components/List/List';
import { IconButton } from '../../../mui/Button';
import SimpleCrud from '../../../components/SimpleCrud';
import Grid from '../../../mui/Grid';
import { rawSpacing } from '../../../utils/spacing';
import StatusText from '../../../components/StatusText';

export default function BoligmappenIntegration({ integrations, setIntegrations }: IIntegrationProps) {
  const endpoint = useGetApiUrl('organization') + '/integrations';
  const boligmappa = integrations.find((x) => x.integration === 'boligmappa');
  const onChange = React.useMemo(
    () => integrationChange('boligmappa', integrations, setIntegrations),
    [integrations, setIntegrations]
  );
  const onCreate = useMemo(() => integrationCreate('boligmappa', setIntegrations), [setIntegrations]);

  return (
    <Card>
      {boligmappa ? (
        <List disablePadding>
          <ListItem
            divider
            secondaryAction={
              <IconButton
                onClick={() => {
                  createModal(
                    <SimpleCrud
                      title={'Boligmappa (Basic auth)'}
                      endpoint={endpoint}
                      id={boligmappa._id}
                      fields={integrationFields('boligmappa', boligmappa)}
                      onCreate={onChange}
                      deleteAction
                      onDestroy={(organization) => {
                        setIntegrations(organization.integrations);
                      }}
                    />
                  );
                }}
                size="large"
              >
                <Edit />
              </IconButton>
            }
          >
            <Grid container spacing={rawSpacing}>
              <Grid item sm={3}>
                Boligmappa
              </Grid>
              <Grid item sm={9}>
                <StatusText>{boligmappa.organization}</StatusText>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      ) : (
        <IntegrationButton
          label={' Boligmappa'}
          name={'boligmappa'}
          endpoint={endpoint}
          onCreate={onCreate}
        />
      )}
    </Card>
  );
}
