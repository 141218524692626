import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSSOLogin } from '@aptly-as/sso-login-react';
import { AptlyLanguage } from '@aptly-as/types';
import ErrorBoundary from '../../components/ErrorBoundary';
import { UserContext } from '../../models/User/UserContext';
import Button from '../../mui/Button';
import Centered from '../../components/Centered';
import Typography from '../../mui/Typography';
import Grid from '../../mui/Grid';
import { cssSpacing, rawSpacing, tightSpacing } from '../../utils/spacing';
import AptlyLogo from '../../components/AptlyLogo';
import { API_URL } from '../../env';

function Login() {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { ready, i18n } = useTranslation();
  const onLogin = React.useCallback(async () => {
    const data = await user.refresh();
    if (data && data.language && Object.values(AptlyLanguage).includes(data.language)) {
      await i18n.changeLanguage(data.language);
    }
    const redirect = window.localStorage.getItem('redirect');
    if (redirect) {
      window.localStorage.removeItem('redirect');
      navigate(redirect);
    } else {
      navigate('/');
    }
  }, [navigate, user, i18n]);

  const login = useSSOLogin(`${API_URL}/auth/admin`, onLogin);

  if (!ready) return null;

  return (
    <ErrorBoundary>
      <Centered maxWidth="60rem" marginTop={`calc(${cssSpacing} * 4)`}>
        <Grid container spacing={rawSpacing} alignItems="center">
          <Grid item sm={6}>
            <AptlyLogo />
            <Typography gutterBottom>{i18n.t('paragraphs.welcomeAptlyPortal')}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Centered height="500px" width="500px">
              <Grid container direction="column" alignItems="center" spacing={tightSpacing}>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={login}>
                    {i18n.t('actions.loginToAptly')}
                  </Button>
                </Grid>
              </Grid>
            </Centered>
          </Grid>
        </Grid>
      </Centered>
    </ErrorBoundary>
  );
}

export default Login;
