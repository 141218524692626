import { useCallback } from 'react';
import { AptlyMediaSrc } from '@aptly-as/types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { ImageList, ImageListItem, ImageListItemBar, Tooltip } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import Star from '@mui/icons-material/Star';

import { ISimpleComponentProps } from './simple-components.utils';
import DropZone from '../../../libraries/react-dropzone/DropZone';
import AptlyMedia from '../../AptlyMedia';
import { IconButton } from '../../../mui/Button';
import i18n from '../../../libraries/i18n';

interface SimpleImagesProps<T extends object> extends ISimpleComponentProps<T, any> {
  simple?: boolean;
}

export default function SimpleImagesUpload<T extends object>({
  field,
  onChange,
  simple,
}: SimpleImagesProps<T>) {
  const { value = [], label, image } = field;

  if (!image) {
    throw new Error('SimpleImagesUpload was used with media without image in field');
  }

  const { srcKey, maxLength, ...imageRest } = image;
  const disabled = field.disabled || Boolean(maxLength && maxLength <= value.length);

  const handleOnDrop = useCallback(
    (medias: AptlyMediaSrc[] | AptlyMediaSrc) => {
      onChange([...value, ...(Array.isArray(medias) ? medias : [medias])], true);
    },
    [value, onChange]
  );

  const handleOnFeature = useCallback(
    (media: AptlyMediaSrc, index: number) => {
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange([media, ...newValue]);
    },
    [value, onChange]
  );

  const handleOnDelete = useCallback(
    (_media: AptlyMediaSrc, index: number) => {
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange(newValue);
    },
    [value, onChange]
  );

  return (
    <Grid container direction="column">
      {simple && <Typography variant="subtitle1">{label}</Typography>}
      <ImageList cols={4} gap={8} rowHeight={193}>
        {value.map((media: AptlyMediaSrc, i: number) => (
          <ImageListItem key={media._id || media.src}>
            {i !== 0 && (
              <ImageListItemBar
                position="top"
                actionPosition="left"
                actionIcon={
                  !field.disabled ? (
                    <Tooltip title={i18n.t('singles.featured')} placement="top">
                      <IconButton onClick={() => handleOnFeature(media, i)}>
                        <Star color="success" />
                      </IconButton>
                    </Tooltip>
                  ) : null
                }
              />
            )}
            <AptlyMedia
              src={String(media[(srcKey as keyof AptlyMediaSrc) || 'src'])}
              media={media}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                width: '100%',
                height: 'auto',
                margin: 'auto auto',
                objectFit: 'contain',
              }}
              {...imageRest}
            />
            <ImageListItemBar
              subtitle={media.alt || ''}
              actionIcon={
                !field.disabled ? (
                  <Tooltip title={i18n.t('actions.delete')}>
                    <IconButton onClick={() => handleOnDelete(media, i)}>
                      <Delete color="error" />
                    </IconButton>
                  </Tooltip>
                ) : null
              }
            />
          </ImageListItem>
        ))}
        {!disabled && (
          <ImageListItem>
            <DropZone
              multiple={!maxLength}
              onDrop={handleOnDrop}
              aspect={image.cropAspect}
              accept={image.accept}
            />
          </ImageListItem>
        )}
      </ImageList>
    </Grid>
  );
}
