type DocOrId = string | { _id?: string };

export const getId = (objOrId?: DocOrId): string =>
  (typeof objOrId === 'string' ? objOrId : objOrId?._id) || '';

export const byId = (id?: string | DocOrId) => (doc?: string | DocOrId) => {
  if (!id || !doc) return false;
  return getId(doc) === getId(id);
};
export function matchId<L extends DocOrId, R extends DocOrId>(a: L, b: R) {
  if (!a || !b) return false;
  return getId(a) === getId(b);
}

export const getPopulatedValue = <T extends object, K extends keyof T>(key: K, objOrId?: string | T) => {
  if (!objOrId) return undefined;
  if (typeof objOrId === 'string') return undefined;
  if (key in objOrId) return objOrId[key];
  return undefined;
};
