import { onWheelCaptureBlur } from '../../../utils/input';
import { ISimpleComponentProps } from './simple-components.utils';
import { ITextFieldProps, TextField } from '../../../mui/Input';

export type ICrudTextFieldProps<T extends object> = ISimpleComponentProps<T, any> &
  Partial<Omit<ITextFieldProps, 'value' | 'onChange'>>;

export default function SimpleTextField<T extends object>({
  field,
  onChange,
  ...props
}: ICrudTextFieldProps<T>) {
  const { id, value, defaultValue, edited, gridProps, renderValidate, ...rest } = field;

  if (rest.type === 'number') {
    return (
      <TextField
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        variant="outlined"
        fullWidth
        onWheelCapture={onWheelCaptureBlur}
        shrink
        {...rest}
        {...props}
      />
    );
  }

  return (
    <TextField
      id={id}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      fullWidth
      onWheelCapture={onWheelCaptureBlur}
      {...rest}
      {...props}
    />
  );
}
