import { AptlyOrder, AptlyOrderStatus, AptlyProject, AptlyScopes, AptlyUnit } from '@aptly-as/types';
import DrawOutlined from '@mui/icons-material/DrawOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useCallback, useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { FavoriteIconToggle } from '../../components/actions/icons/FavoriteIconToggle';
import { LoadMoreButton } from '../../components/actions/icons/LoadMoreButton';
import ApiError from '../../components/ApiError';
import Avatar from '../../components/Avatar';
import Dashboard, { DashboardElement } from '../../components/Dashboard';
import List, { ListItem, ListItemButton, ListItemIcon, ListItemText } from '../../components/List/List';
import Section from '../../components/Section';
import StatusText from '../../components/StatusText';
import { createModal } from '../../containers/Modal/ModalContext';
import sign from '../../containers/Sign/sign';
import useGetApiUrl, { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl';
import { useMobile } from '../../hooks/useMobile';
import { formatDate } from '../../libraries/dayjs';
import i18n from '../../libraries/i18n';
import { getId } from '../../libraries/mongoose';
import usePaginatedSearch from '../../libraries/reach/usePaginatedSearch';
import { useSimpleSearch } from '../../libraries/reach/useSimpleSearch';
import Scope from '../../libraries/scope/Scope';
import Button from '../../mui/Button';
import Card, { CardActions, CardContent, CardHeader } from '../../mui/Card';
import Typography from '../../mui/Typography';
import { Bookings } from '../Booking/Bookings';
import InquiryRecent from '../Inquiry/InquiryRecent';
import HandleOrder, { handleOrderModelOptions } from '../Order/HandleOrder/HandleOrder';
import { HandleAptlyOrder } from '../Order/HandleOrder/HandleOrder.utils';
import { orderStatusLabel } from '../Order/order.utils';
import { useProjectLogo, useProjectSubheader } from '../Project/project.hooks';
import { useProjectFavorite } from '../Project/useProjectFavorite';
import { UserContext } from '../User/UserContext';
import { OrganizationContext } from './OrganizationContext';
import ContractInfo from './organizationDashboard/ContractInfo';

function OrganizationDashboard() {
  const isMobile = useMobile();
  const org = useContext(OrganizationContext);
  const { data } = org;

  if (!data) return null;

  const contractElement = {
    renderFn: () => <ContractInfo data={data} />,
  };

  const elements: DashboardElement[] = [
    {
      renderFn: () => <Projects />,
    },
  ];

  if (!isMobile && Scope.crud(AptlyScopes.OrganizationOrders, 'R')) {
    elements.push({
      renderFn: () => <Orders />,
    });
  }

  if (!isMobile && Scope.crud(AptlyScopes.ProjectInquiry, 'R')) {
    elements.push({
      renderFn: () => <InquiryRecent limit={5} level={SlugLevel.Organization} />,
    });
  }
  if (!isMobile && Scope.crud(AptlyScopes.ProjectBooking, 'R')) {
    elements.push({
      renderFn: () => <Bookings level={SlugLevel.Organization} />,
    });
  }

  if (!isMobile && data.contractSign && data.contractSign.document) {
    if (!data.contractSign.signed) {
      elements.unshift(contractElement);
    } else {
      elements.push(contractElement);
    }
  } else if (!isMobile && Scope.crud(AptlyScopes.Admin, 'U')) {
    elements.push(contractElement);
  }

  return (
    <>
      <Section>
        <Typography variant="h1">{i18n.t('singles.dashboard')}</Typography>
      </Section>
      <Section>
        <Dashboard config={{ elements }} />
      </Section>
    </>
  );
}

function Projects() {
  const endpoint = useApiUrl(SlugLevel.Organization, 'projects/search');
  const favorite = useProjectFavorite();
  const [, projects, error, , info] = usePaginatedSearch<AptlyProject>(
    endpoint,
    useMemo(() => ({ limit: 10, query: { archived: false } }), [])
  );

  if (error) return <ApiError error={error} />;

  return (
    <Card key="pr">
      <CardHeader title={`${i18n.t('singles.projects')} ( ${info.count} )`} />
      {projects.length > 0 ? (
        <List disablePadding dense>
          {projects.map((p) => (
            <ProjectItem key={p._id} project={p} onFavorite={favorite} />
          ))}
        </List>
      ) : (
        <CardContent>
          <StatusText>{i18n.t('paragraphs.noProjects')}</StatusText>
        </CardContent>
      )}
      <CardActions>
        <Button size="small" color="primary" component={NavLink} to="prosjekter">
          {i18n.t('singles.goTo')} {i18n.t('singles.projects')}
        </Button>
      </CardActions>
    </Card>
  );
}

interface ProjectItemProps {
  project: AptlyProject;
  onFavorite: (p: AptlyProject) => void;
}

function ProjectItem({ project, onFavorite }: ProjectItemProps) {
  const user = useContext(UserContext);
  const handleOnFavorite = useCallback(() => onFavorite(project), [project, onFavorite]);
  const logo = useProjectLogo(project);
  const subheader = useProjectSubheader(project);
  const to = useTo(SlugLevel.Organization);

  return (
    <ListItem
      key={project._id}
      button
      icon={logo ? <Avatar src={logo} variant="square" contain /> : <HomeWorkIcon />}
      component={NavLink}
      to={`${to}/prosjekter/${project._id}`}
      secondaryAction={
        <FavoriteIconToggle
          favorite={project.userFavorites?.includes(user.data._id)}
          onClick={handleOnFavorite}
        />
      }
    >
      <ListItemText primary={project.name} secondary={subheader} />
    </ListItem>
  );
}

type Order = HandleAptlyOrder & Pick<AptlyOrder, 'signees'>;
function Orders() {
  const user = useContext(UserContext);
  const baseEndpoint = useGetApiUrl('organization');
  const endpoint = useApiUrl(SlugLevel.Organization, 'orders');
  const [busy, orders, error, next, info, { search }] = useSimpleSearch<Order>(endpoint, {
    limit: 5,
    query: {
      $in_status: [AptlyOrderStatus.New, AptlyOrderStatus.ReadyToSign, AptlyOrderStatus.SignedByCustomer],
      select: '_id status project unit orderNumber signees receipt signedReceipt status createdAt',
      $populate: 'project:name;unit:name',
      sort: '-created',
    },
  });

  const handleOnSign = useCallback(
    (order: Order) => () =>
      sign({
        orderEndpoint: `${baseEndpoint}/projects/${getId(order.project)}/orders/${order._id}`,
        initializeSigning: order.status === 'notSigned',
        unit: order.unit as AptlyUnit,
        user: user.data,
        refresh: () => search({}),
      }),
    [baseEndpoint, search, user]
  );

  const handleOrder = useCallback(
    (order: HandleAptlyOrder) => {
      if (order.status === AptlyOrderStatus.ReadyToSign) {
        return handleOnSign(order)();
      }
      createModal(<HandleOrder order={order} onChange={() => search({})} />, handleOrderModelOptions);
    },
    [handleOnSign, search]
  );

  if (!orders) return null;
  return (
    <>
      <Card key="or">
        <CardHeader title={`${i18n.t('statuses.ordersReady')} ( ${info.count} )`} />
        {error ? <ApiError error={error} /> : null}
        {orders.length > 0 ? (
          <List disablePadding>
            {orders.map((order) => {
              if (order.status === AptlyOrderStatus.SignedByCustomer) {
                return (
                  <ListItem disablePadding dense key={order._id}>
                    <ListItemButton onClick={handleOnSign(order)}>
                      <ListItemIcon>
                        <ReceiptIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={order.orderNumber}
                        secondary={
                          <>
                            <Typography>{(order.project as AptlyProject).name}</Typography>
                            <Typography>{formatDate(order.createdAt)}</Typography>
                          </>
                        }
                      />
                      <DrawOutlined fontSize="large" />
                    </ListItemButton>
                  </ListItem>
                );
              }
              return <OrderItem key={order._id} order={order} onClick={handleOrder} />;
            })}
          </List>
        ) : (
          <CardContent>
            <StatusText>{i18n.t('statuses.noOrders')}</StatusText>
          </CardContent>
        )}
      </Card>
      {orders.length < info.count && <LoadMoreButton next={() => next()} disabled={busy} />}
    </>
  );
}

interface OrderItemProps {
  order: Order;
  onClick: (order: Order) => void;
}

function OrderItem({ order, onClick }: OrderItemProps) {
  const handleOnClick = useCallback(() => onClick(order), [onClick, order]);
  const canSign = order.status === AptlyOrderStatus.ReadyToSign;

  return (
    <ListItem disablePadding dense key={order._id}>
      <ListItemButton onClick={handleOnClick}>
        <ListItemIcon>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText
          primary={order.orderNumber}
          secondary={
            <>
              <Typography variant="body2">{(order.project as AptlyProject).name}</Typography>
              <Typography variant="caption">
                {orderStatusLabel(order.status)}: {formatDate(order.createdAt)} -{' '}
                {(order.unit as AptlyUnit)?.name}
              </Typography>
            </>
          }
        />
        {canSign ? <DrawOutlined /> : <EditOutlinedIcon color="secondary" />}
      </ListItemButton>
    </ListItem>
  );
}

export default OrganizationDashboard;
