import { useMemo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Arguments } from '@dnd-kit/sortable/dist/hooks/useSortable';
import {
  AptlyUnitTemplateCategory,
  AptlyUnitTemplateCategorySection,
  AptlyUnitTemplateCategorySectionPackage,
  AptlyUnitTemplateCategorySectionProduct,
} from '@aptly-as/types';

export type IUseSortableRet = ReturnType<typeof useSortable>;

export interface ISortableDataProps {
  category: AptlyUnitTemplateCategory;
  section: AptlyUnitTemplateCategorySection;
  product: AptlyUnitTemplateCategorySectionProduct;
  package: AptlyUnitTemplateCategorySectionPackage;
}

export interface ISortableStyleProps {
  transition?: string;
  transform?: string;
  opacity?: number;
  zIndex?: number;
}

type IDragProps = IUseSortableRet['listeners'] & IUseSortableRet['attributes'];
interface IToSortableRet {
  ref: IUseSortableRet['setNodeRef'];
  style: ISortableStyleProps;
}
export type IUseUnitTemplateSortableRet = [IToSortableRet, IDragProps, IUseSortableRet];

export function useUnitTemplateSortable(
  props: Partial<ISortableDataProps>,
  expanded?: boolean,
  useProps?: Omit<Arguments, 'id' | 'data'>
): IUseUnitTemplateSortableRet {
  const sortableProps = useSortable(toSortableProps(props, expanded, useProps));
  return useMemo(() => toSortableItemProps(sortableProps), [sortableProps]);
}

const getSortableId = (props: Partial<ISortableDataProps>) => {
  if (props.product) return `product-${props.product._id}`;
  if (props.package) return `package-${props.package._id}`;
  if (props.section) return `section-${props.section._id}`;
  if (props.category) return `category-${props.category._id}`;
  return 'none';
};

export const toSortableProps = (
  props: Partial<ISortableDataProps>,
  expanded?: boolean,
  useProps?: Omit<Arguments, 'id' | 'data'>
) => ({
  id: getSortableId(props),
  data: {
    category: props.category,
    section: props.section,
    product: props.product,
    package: props.package,
    expanded,
  },
  ...useProps,
});

export const toSortableStyles = (p: IUseSortableRet): ISortableStyleProps => ({
  transition: p.transition || '',
  transform: CSS.Transform.toString(p.transform),
  opacity: p.isDragging ? 0.5 : 1,
  zIndex: p.isDragging ? 1300 : undefined,
});

export const toSortableItemProps = (props: IUseSortableRet): IUseUnitTemplateSortableRet => [
  {
    ref: props.setNodeRef,
    style: toSortableStyles(props),
  },
  { ...props.listeners, ...props.attributes } as IDragProps,
  props,
];
