import { AptlyScopes } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import { dataGridTextColumn } from '../../mui/x-data-grid/dataGrid.cols';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import {
  dataGridActions,
  dataGridSearchNameColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import Search, { SearchFieldText } from '../../components/Search/Search';
import { aptlyModulesLabels, IAptlyModules, moduleSchema } from './module.schema';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import { useMemo } from 'react';

const columns = (): GridColDef[] => [
  {
    type: 'number',
    field: 'featuredWeight',
    headerName: i18n.t('singles.weight'),
    width: 50,
  },
  dataGridSearchNameColumn(),
  dataGridTextColumn('modules', i18n.t('singles.modules'), {
    renderCell: (params) => params.value?.map((x: IAptlyModules) => aptlyModulesLabels()[x]).join(', '),
  }),
  dataGridActions({ checkIfDisabledInProject: true }),
];

export default function AdminModules() {
  const path = useApiUrl(SlugLevel.Base, 'modules');
  const schema = useMemo(() => moduleSchema(), []);

  return (
    <Search
      path={path}
      patchPath={toSearchPatchPath('modules')}
      scope={AptlyScopes.AdminModules}
      header={{ title: i18n.t('singles.modules') }}
      post={{ title: i18n.t('singles.modules') }}
      patch={{ title: i18n.t('singles.modules') }}
      schema={schema}
      fields={['name', 'cost', 'description', 'featuredWeight', 'modules']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: '-featuredWeight name' } }}
      buttonActions={() => [<SearchCreateAction key="create" />]}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns()} />}
    </Search>
  );
}
