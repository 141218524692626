import { useGet } from '@ewb/reach-react';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import ReadIcon from '@mui/icons-material/Visibility';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Fragment, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AptlyApp, AptlyAppScopeTranslations, AptlyAppScope } from '@aptly-as/types';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { API_URL } from '../../env';
import i18n from '../../libraries/i18n';
import ApiError from '../../components/ApiError';
import useApp from './useApp';
import AppCard, { AppCardActionLink } from './AppCard';

export default function OrganizationAppsAccept() {
  const params = useParams<{ app: string }>();
  const [app] = useApp(params.app!);
  if (app.error) return <ApiError error={app.error} />;
  if (!app.data) return null;
  return <Content app={app.data} />;
}

function Content({ app }: { app: AptlyApp }) {
  const location = useLocation();
  const installUrl = useApiUrl(SlugLevel.Organization, 'apps');
  const search = new URLSearchParams(location.search);
  const path = `scopes?scope=${encodeURIComponent(search.get('scope') || '')}`;
  const [translations] = useGet<AptlyAppScopeTranslations>(path);

  const description = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const scope = searchParams.get('scope') || '';
    if (!translations.data) return <></>;
    return (
      <div>
        <Typography variant="body1">{i18n.t('paragraphs.appAuthorize')}</Typography>
        <List
          dense
          subheader={<ListSubheader disableGutters>{i18n.t('singles.permissions')}</ListSubheader>}
          disablePadding
        >
          <Divider component="li" />
          {(scope.split(' ') as AptlyAppScope[]).map((scope, i) => {
            const [, model, action = 'write'] = scope.split('.');
            if (!model) return null;
            const scopeTranslation = translations.data![scope];
            const info = scopeTranslation ? scopeTranslation.description : '';
            const label = scopeTranslation ? scopeTranslation.label : `${action}: ${model}`;
            return (
              <Fragment key={i}>
                <ListItem
                  disablePadding
                  secondaryAction={
                    info ? (
                      <Tooltip title={info} placement="top">
                        <InfoIcon width={30} height={30} />
                      </Tooltip>
                    ) : undefined
                  }
                >
                  <ListItemIcon>{action === 'read' ? <ReadIcon /> : <EditIcon />}</ListItemIcon>
                  <ListItemText primary={label} />
                </ListItem>
                <Divider component="li" />
              </Fragment>
            );
          })}
        </List>
      </div>
    );
  }, [location.search, translations]);

  if (!app) return null;
  if (translations.busy) {
    return null;
  }

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
      <Grid item xs={4} md={3}>
        <AppCard title={app.name} description={description}>
          <AppCardActionLink href={`${API_URL}/${installUrl}/${app._id}/install/accept${location.search}`}>
            {i18n.t('actions.confirm')}
          </AppCardActionLink>
          <AppCardActionLink href="javascript:history.back()">{i18n.t('actions.cancel')}</AppCardActionLink>
        </AppCard>
      </Grid>
    </Grid>
  );
}
