import {
  AptlyAlgorithmPipelineApplyTo,
  AptlyAlgorithmPipelineOperation,
  AptlyFieldType,
  AptlyProject,
} from '@aptly-as/types';
import { Alert } from '@mui/material';
import { Trans } from 'react-i18next';
import { schemaName } from '../../components/crud/schema/fields.schema';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import DraggableCrudList, { Field, ItemsToSelect } from '../../components/DraggableCrudList';
import i18n from '../../libraries/i18n';
import { intlCurrencyDecimal } from '../../libraries/intl';
import Typography from '../../mui/Typography';
import { getAlgorithmVisual } from './algorithm.utils';

export type ICrudAlgorithm = AptlyProject['algorithms'][0] & { __example: undefined };

const label = (): Field => ({
  key: 'label',
  label: i18n.t('singles.name'),
  type: 'text',
});

const value = (): Field => ({
  key: 'value',
  label: i18n.t('singles.value'),
  type: 'number',
});

const applyTo = (isRevered: boolean): Field => {
  const options = [
    {
      value: 'previousStep',
      label: i18n.t('actions.previousStep'),
    },
  ];
  if (!isRevered) {
    options.push({
      value: 'base',
      label: i18n.t('singles.netCost'),
    });
  }
  return {
    key: 'applyTo',
    label: i18n.t('paragraphs.appliesTo'),
    type: 'select',
    options,
  };
};

const algorithmTranslations = (isRevered: boolean): ItemsToSelect => ({
  base: {
    label: i18n.t('singles.netCost'),
    fields: [label()],
  },
  add: {
    label: i18n.t('actions.add'),
    fields: [label(), value()],
  },
  subtract: {
    label: i18n.t('actions.subtract'),
    fields: [label(), value()],
  },
  multiply: {
    label: i18n.t('actions.multiply'),
    fields: [label(), value(), applyTo(isRevered)],
  },
});

export const algorithmEditFields: (keyof ICrudAlgorithm)[] = [
  'name',
  'pipeline',
  'fee',
  'vat',
  'reversed',
  '__example',
];

export const algorithmSchema = (): ICrudSchema<ICrudAlgorithm> => ({
  name: schemaName({
    autoFocus: true,
  }),
  pipeline: {
    type: AptlyFieldType.Custom,
    defaultValue: [
      { operation: AptlyAlgorithmPipelineOperation.Base, label: i18n.t('singles.netCost') } as any,
    ],
    label: '',
    customRender: (crud, field) => {
      const pipeline = field.value as any[];
      const isRevered = !!crud.getField('reversed').value;
      return (
        <>
          <DraggableCrudList
            label={i18n.t('singles.pipeline')}
            addActionText={i18n.t('actions.addToPipeline')}
            valueAsLabel="label"
            onCommit={(items) => {
              crud.setField('pipeline')(
                items.map((i) => {
                  const operation = i.key;
                  const label = i.values.find((i) => i.key === 'label');
                  const value = i.values.find((i) => i.key === 'value');
                  const applyTo = i.values.find((i) => i.key === 'applyTo');
                  const defaultApplyTo =
                    operation === AptlyAlgorithmPipelineOperation.Multiply
                      ? AptlyAlgorithmPipelineApplyTo.PreviousStep
                      : undefined;

                  return {
                    operation,
                    label: label ? label.value : undefined,
                    value: value ? value.value : undefined,
                    applyTo: applyTo ? applyTo.value : defaultApplyTo,
                  };
                }) as any[]
              );
            }}
            items={pipeline.map((p) => ({
              key: p.operation,
              disabled: p.operation === AptlyAlgorithmPipelineOperation.Base,
              values: [
                {
                  key: 'label',
                  value: p.label,
                },
                {
                  key: 'value',
                  value: p.value,
                },
                {
                  key: 'applyTo',
                  value: p.applyTo,
                },
              ],
            }))}
            itemsToSelect={algorithmTranslations(isRevered) as any}
          />
          {isRevered && (
            <Alert color="info">
              <Trans i18nKey="paragraphs.algorithmReversedAlert">
                <strong>Applies to &apos;Base&apos;</strong> is disabled if you use reversed calculations
              </Trans>
            </Alert>
          )}
        </>
      );
    },
  },
  vat: {
    type: AptlyFieldType.Select,
    label: i18n.t('singles.vat'),
    defaultValue: 0,
    options: [
      { value: 25, label: '25%' },
      { value: 15, label: '15%' },
      { value: 12, label: '12%' },
      { value: 0, label: '0%' },
    ],
  },
  fee: {
    type: AptlyFieldType.Number,
    label: `${i18n.t('singles.fee')} ${i18n.t('singles.percent').toLocaleLowerCase()} ( ${i18n.t(
      'paragraphs.aptlyFee'
    )} )`,
    defaultValue: 0,
  },
  reversed: {
    type: AptlyFieldType.Switch,
    label: i18n.t('paragraphs.useNetAsGross'),
    defaultValue: false,
    renderValidate: (crud) =>
      !crud.getField('pipeline').value.some((x) => x.applyTo === AptlyAlgorithmPipelineApplyTo.Base),
  },
  __example: {
    disabled: true,
    type: AptlyFieldType.Custom,
    label: '',
    defaultValue: undefined,
    customRender: (crud) => {
      const baseCost = 4000;
      const calculated = getAlgorithmVisual(crud.state.data, baseCost);
      return (
        <Typography>
          {i18n.t('singles.example')} ({intlCurrencyDecimal(baseCost)}): {calculated}
        </Typography>
      );
    },
  },
});
