import { AptlyErrorBody, AptlyNote, AptlyScopes, AptlyUser } from '@aptly-as/types';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import React, { FormEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BackButtonWithIcon, SaveButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { CrudContactNotifyButton } from '../../components/simpleCruds/settings/CrudContactNotifyAdd';
import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl';
import EmptyState from '../../containers/Error/EmptyState';
import { busyNotification, successNotification } from '../../containers/Notification/notification.utils';
import { useMobile } from '../../hooks/useMobile';
import i18n from '../../libraries/i18n';
import ApiError from '../../components/ApiError';
import Typography from '../../mui/Typography';
import { NoteListItem } from './Notes';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import { SearchCrudContext } from '../../components/Search/search.utils';
import { noteSchema, noteSelect } from './note.schema';
import { useOnBeforeUnload } from '../../hooks/useOnBeforeUnload';
import { SimpleCrudContent } from '../../components/simple/SimpleCrud';
import { IUseSimpleCrudFieldsProps, useSimpleCrudFields } from '../../components/simple/useSimpleCrud';

export function UnitNotes() {
  const { note } = useParams();
  const path = useApiUrl(SlugLevel.Unit, 'notes');
  const schema = useMemo(() => noteSchema(), []);
  const reach = useMemo(
    () => ({
      query: { archived: false, sort: '-updatedAt', select: noteSelect, $populate: 'author:_id,fullName' },
    }),
    []
  );

  return (
    <Search<AptlyNote>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.UnitNotes}
      header={{ title: i18n.t('singles.notes') }}
      post={{ title: i18n.t('singles.notes') }}
      patch={{ title: i18n.t('singles.notes') }}
      schema={schema}
      fields={['name', 'content']}
      options={{ disableBulk: true }}
      reach={reach}
      buttonActions={() => [
        <CrudContactNotifyButton key="notification" defaultExpandGroup="note" />,
        <SearchCreateAction key="create" />,
      ]}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <Notes id={note!} />}
    </Search>
  );
}

interface NotesProps {
  id?: string;
}

function Notes({ id }: NotesProps) {
  const navigate = useNavigate();
  const { items, actions, info } = useContext(SearchCrudContext);
  const [activeNote, setActiveNote] = useState<AptlyNote | null>(null);
  const toPath = useTo(SlugLevel.Unit, '/notater');
  const isMobile = useMobile();

  useEffect(() => {
    if (id && !activeNote) {
      const active = items.find((x) => x._id === id);
      setActiveNote(active || { _id: id });
    }
    if (id && activeNote && activeNote._id !== id) {
      const active = items.find((x) => x._id === id);
      setActiveNote(active || items[0]);
    } else if (!id && !activeNote && items.length > 0) {
      if (isMobile) {
        return;
      }
      navigate(`${toPath}/${items[0]._id}`);
      setActiveNote(items[0]);
    } else if (!id) {
      setActiveNote(null);
    }
  }, [items, id, activeNote, isMobile]);

  const back = useCallback(() => {
    setActiveNote(null);
    navigate(toPath);
  }, [toPath]);

  if (items.length === 0 && info.hasFetched) {
    return <EmptyState>{i18n.t('paragraphs.noNotes')}</EmptyState>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={4}>
        {!isMobile || !activeNote ? (
          <List disablePadding>
            {items.map((note, i) => {
              return (
                <React.Fragment key={note._id}>
                  <NoteListItem
                    note={note}
                    i={i}
                    onDelete={actions.spawnDelete}
                    selected={activeNote?._id === note._id}
                  />
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              );
            })}
          </List>
        ) : (
          <BackButtonWithIcon onClick={back} />
        )}
      </Grid>
      <Grid item xs={12} lg={8}>
        {activeNote && <Note key={activeNote._id} note={activeNote} />}
      </Grid>
    </Grid>
  );
}

interface NoteProps {
  note: AptlyNote;
}

function Note({ note }: NoteProps) {
  const [error, setError] = useState<AptlyErrorBody | null>(null);
  const { items, actions } = useContext(SearchCrudContext);
  const path = useApiUrl(SlugLevel.Unit, 'notes');
  const options: IUseSimpleCrudFieldsProps<AptlyNote> = useMemo(
    () => ({
      initWithGet: true,
      reachOptions: {
        query: { $populate: 'author:_id,fullName' },
      },
    }),
    []
  );
  const [crud, fields] = useSimpleCrudFields(
    path,
    note,
    noteSchema(),
    ['name', 'content', 'reminderDate', 'addToReceipt', 'unitTemplateCategory'],
    options
  );
  useOnBeforeUnload(crud.state.dirty);

  const handleOnSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const busy = busyNotification();
      try {
        const saved = await crud.save();
        if (saved) {
          const i = items.findIndex((x) => x._id === saved._id);
          await actions.splice(i, 1, saved);
          successNotification(i18n.t('statuses.saved'));
          setError(null);
        }
      } catch (e: any) {
        setError(e);
      } finally {
        busy();
      }
    },
    [crud, items, actions]
  );

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container direction="column" component={Paper}>
        {error && <ApiError error={error} />}
        <PaddedGrid container item justifyContent="space-between">
          <Typography>
            {note.author && (
              <>
                {i18n.t('singles.author')}: {(note.author as AptlyUser)?.fullName}
              </>
            )}
          </Typography>
          <SaveButtonWithIcon type="submit" />
        </PaddedGrid>
        <Divider variant="fullWidth" />
        <PaddedGrid item>
          <SimpleCrudContent schema={fields} crud={crud} />
        </PaddedGrid>
      </Grid>
    </form>
  );
}

const PaddedGrid = styled(Grid)`
  padding: 1rem;
`;
