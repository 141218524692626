import { useContext } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Alert from '@mui/material/Alert';
import { AptlyOrganization } from '@aptly-as/types';
import useGetApiUrl from '../../../hooks/useGetApiUrl';
import Card, { CardContent, CardHeader } from '../../../mui/Card';
import { ButtonWithIcon } from '../../../mui/Button';
import { API_URL } from '../../../env';
import DropDocuments from '../../Document/DropDocuments';
import Grid from '../../../mui/Grid';
import StatusText from '../../../components/StatusText';
import i18n from '../../../libraries/i18n';
import { OrganizationContext } from '../OrganizationContext';

export type ContractInfoProps = {
  data: AptlyOrganization;
};

export default function ContractInfo({ data }: ContractInfoProps) {
  const path = useGetApiUrl('organization', '/contract');
  const location = useLocation();

  const errors = React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    const signType = params.get('signType');

    if (signType === 'success') {
      return <Alert severity="success">{i18n.t('statuses.signed')}</Alert>;
    } else if (signType === 'cancel') {
      return <Alert severity="warning">{i18n.t('statuses.canceled')}</Alert>;
    } else if (signType === 'error') {
      const signError = params.get('signError');
      return (
        <Alert severity="error">
          {i18n.t('statuses.error')}: {signError}
        </Alert>
      );
    }
    return null;
  }, [location.search]);

  const content = React.useMemo(() => {
    if (!data.contractSign || !data.contractSign.document) {
      return <UploadDocument data={data} />;
    }

    const linkProps = (props: any): any => ({
      component: 'a',
      target: '_blank',
      variant: 'outlined',
      color: 'primary',
      rel: 'noreferrer noopener',
      ...props,
    });

    if (data.contractSign?.signed) {
      if (data.contractSign.signedFile) {
        return (
          <ButtonWithIcon
            startIcon={<CloudDownloadOutlinedIcon />}
            {...linkProps({ download: true })}
            href={`${API_URL}/${path}/signed`}
          >
            {i18n.t('actions.downloadSignedDataContract')}
          </ButtonWithIcon>
        );
      }

      return <StatusText>{i18n.t('statuses.waitingForBankId')}</StatusText>;
    }

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <ButtonWithIcon
            startIcon={<CloudDownloadOutlinedIcon />}
            {...linkProps({ download: true })}
            href={`${API_URL}/${path}`}
            color="secondary"
          >
            {i18n.t('actions.downloadDataContract')}
          </ButtonWithIcon>
        </Grid>
        <Grid item>
          <ButtonWithIcon
            startIcon={<EditOutlinedIcon />}
            {...linkProps({ target: '' })}
            href={`${API_URL}/${path}/sign`}
          >
            {i18n.t('actions.signDataContract')}
          </ButtonWithIcon>
        </Grid>
      </Grid>
    );
  }, [data, path]);

  return (
    <Card key="contract">
      {errors}
      <CardHeader avatar={<ReceiptIcon />} title="Databehandleravtale" />
      <CardContent>{content}</CardContent>
    </Card>
  );
}

function UploadDocument({ data }: ContractInfoProps) {
  const { setData } = useContext(OrganizationContext);
  const path = useGetApiUrl('organization', '/contract');

  const handleOneDrop = React.useCallback(
    (files: any) => {
      setData({
        contractSign: {
          ...(data.contractSign || { hasReceivedFile: false }),
          document: files[0]._id,
        } as any,
      });
    },
    [data.contractSign, setData]
  );

  return <DropDocuments endpoint={path} onDrop={handleOneDrop} simple path="/" />;
}
