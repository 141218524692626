import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React from 'react';
import styled from 'styled-components';
import Add from '@mui/icons-material/Add';
import { createModal } from '../../../containers/Modal/ModalContext';
import Paper from '../../../mui/Paper';
import Button from '../../../mui/Button';
import List, { ListItem } from '../../List/List';
import CrudContactNotifyAdd from './CrudContactNotifyAdd';
import { ModalActions, ModalTitle } from '../../../mui/Dialog';
import i18n from '../../../libraries/i18n';
import { AptlyProject, AptlyProjectNotify, AptlyUser } from '@aptly-as/types';

const StyledPaper = styled(Paper)`
  padding: 0 1rem;
`;

export type CrudContactNotifyProps<T, U = T> = {
  endpoint: string;
  value?: any;
  data?: T;
  onSubmit?: (data?: U) => void;
  onDestroy?: (_id: string | T) => void;
  onClose?: () => void;
  actionLabel?: string;
};

type IState = {
  notify: AptlyProjectNotify[];
};

export default class CrudContactNotify extends React.Component<
  CrudContactNotifyProps<AptlyProjectNotify[], AptlyProject>,
  IState
> {
  state = {
    notify: this.props.data || [],
  };

  static getDerivedStateFromProps(
    nextProps: CrudContactNotifyProps<AptlyProjectNotify[]>,
    prevState: IState
  ) {
    if (nextProps.data && !prevState.notify) {
      return {
        notify: nextProps.data,
      };
    }
    return {};
  }

  render() {
    const { endpoint, onClose, actionLabel } = this.props;

    const { notify } = this.state;
    const notifications = notify || [];

    return (
      <StyledPaper>
        <ModalTitle>{i18n.t('singles.notifications')}</ModalTitle>
        <Paper>
          <List disablePadding>
            {notifications.map((notify) => (
              <ListItem
                key={notify._id}
                icon={<EditOutlinedIcon />}
                button
                divider
                onClick={() => {
                  createModal(
                    <CrudContactNotifyAdd
                      data={notify}
                      onSubmit={(project) => {
                        if (project) {
                          this.setState({ notify: project.notify });
                        }
                      }}
                      onDestroy={(project: any) => {
                        if (project) {
                          this.setState({ notify: project.notify });
                        }
                      }}
                      actionLabel={i18n.t('actions.save')}
                      endpoint={endpoint}
                    />
                  );
                }}
              >
                {notify.user && (notify.user as AptlyUser).fullName}
              </ListItem>
            ))}
            <ListItem
              icon={<Add />}
              button
              divider
              onClick={() => {
                createModal(
                  <CrudContactNotifyAdd
                    onSubmit={(project) => {
                      if (project) {
                        this.setState({ notify: project.notify });
                      }
                    }}
                    actionLabel={i18n.t('actions.add')}
                    endpoint={endpoint}
                  />
                );
              }}
            >
              {i18n.t('actions.add')}
            </ListItem>
          </List>
        </Paper>
        <ModalActions>
          <Button
            color="primary"
            onClick={() => {
              if (typeof onClose === 'function') {
                onClose();
              }
            }}
          >
            {actionLabel}
          </Button>
        </ModalActions>
      </StyledPaper>
    );
  }
}
