import apiRequest from '../../libraries/fetch/apiRequest';
import { busyNotification, errorNotification, successNotification } from './Notification';
import i18n from '../../libraries/i18n';

async function deleteResource(endpoint: string, onDelete?: () => any, noDeleteAction = false) {
  if (noDeleteAction || window.confirm(i18n.t('paragraphs.areYouSureToDelete'))) {
    const busy = busyNotification();
    try {
      await apiRequest(endpoint, {
        method: 'DELETE',
        notificationMessage: i18n.t('actions.deleting'),
      });

      successNotification(i18n.t('statuses.deleted'));

      if (typeof onDelete === 'function') {
        onDelete();
      }
    } catch (e: any) {
      errorNotification(e.data ? e.data : e.error);
    } finally {
      busy();
    }
  }
}

export default deleteResource;
