import { useCallback, useEffect, useRef } from 'react';
import { GOOGLE_API_KEY } from '../../env';

const url = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;

export interface IGooglePlace {
  formatted_address: string;
  name: string;
  geometry?: {
    location: {
      lat: string;
      lng: string;
    };
  };
}

const DEBOUNCE_TIMEOUT = 1000;

export default function useGooglePlaceAutocomplete(onSelectPlace: (place: IGooglePlace) => void) {
  const interval = useRef<number | null>(null);
  const lastInputTime = useRef(0);
  const timeout = useRef<number | null>(null);
  const ref = useRef<HTMLInputElement & { _autocomplete: any }>(null);

  const init = useCallback(() => {
    // @ts-ignore
    const google = window.google;
    if (interval.current) {
      clearInterval(interval.current);
    }
    if (!ref.current) {
      return;
    }

    const options = {
      componentRestrictions: {
        country: 'no',
      },
    };
    const { places } = google.maps;
    const input = ref.current;
    input.addEventListener('input', function (e) {
      const lastEvent = lastInputTime.current;
      const now = +new Date();

      if (!lastEvent || now - lastEvent < DEBOUNCE_TIMEOUT) {
        e.stopImmediatePropagation();
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
          if (input) {
            input.dispatchEvent(new Event('input'));
          }
        }, DEBOUNCE_TIMEOUT);
      }
      lastInputTime.current = now;
    });

    if (!input._autocomplete) {
      input._autocomplete = new places.Autocomplete(input, options);
      input._autocomplete.setFields(['name', 'formatted_address', 'geometry.location']);

      input._autocomplete.addListener(
        'place_changed',
        function () {
          const place = input._autocomplete.getPlace();
          input.style.backgroundColor = '#c8e6c9';
          onSelectPlace({
            ...place,
            geometry: place.geometry
              ? {
                  location: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  },
                }
              : undefined,
          });
          // eslint-disable-next-line
        }.bind(input._autocomplete)
      );
    }
  }, [onSelectPlace]);

  const initGoogle = useCallback(
    async (init: () => void) => {
      if (ref.current) {
        // @ts-ignore
        if (!window.google) {
          const s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = url;
          const x = document.getElementsByTagName('head')[0];
          x.appendChild(s);
          interval.current = setInterval(() => {
            // @ts-ignore
            if (window.google) init();
          }, 250);
        } else {
          init();
        }
      }
    },
    [ref]
  );

  useEffect(() => {
    if (GOOGLE_API_KEY) {
      initGoogle(init).then();
    }
  }, [initGoogle, init]);

  return ref;
}
