import { AptlyAddress } from '@aptly-as/types';
import { useMemo } from 'react';

export function useAddressString(address?: AptlyAddress) {
  return useMemo(() => {
    if (!address) return '';
    let _address = address.street || '';
    if (address.zip) {
      _address += _address ? `, ${address.zip}` : address.zip;
    }
    if (address.city) {
      _address += !address.zip ? `, ${address.city}` : ` ${address.city}`;
    }
    return _address;
  }, [address]);
}
