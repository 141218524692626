import { AptlyErrorBody, AptlyErrorBodySimple } from '@aptly-as/types';
import { ReachError } from '@ewb/reach-react';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SlugLevel, useTo } from '../hooks/useGetApiUrl';
import { TextLink } from '../libraries/router/ComponentLink';
import i18n from '../libraries/i18n';

export type IApiError = ReachError<AptlyErrorBody> | AptlyErrorBody | Error | string;

interface Props<T = IApiError> {
  error: T;
}

export default function ApiError({ error }: Partial<Props<IApiError | null>>) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);

  if (!error) return null;
  return (
    <div ref={ref}>
      <Content error={error} />
    </div>
  );
}

function Content({ error }: Props) {
  if (typeof error === 'string') {
    return <ErrorAlert title="Error" message={error} />;
  }

  if (error instanceof ReachError) {
    if (error.status === 409) {
      return <ConflictAlert error={error} />;
    }
    return <AptlyErrorFC error={error} />;
  }

  if (error instanceof Error) {
    return <ErrorAlert title={error.name} message={error.message} />;
  }
  return <ErrorAlert title={error.title || error.status} message={error.detail || error.code} />;
}

const AptlyErrorFC = ({ error }: Props<ReachError<AptlyErrorBody>>) => (
  <ErrorAlert
    title={`${error.status}: ${error.body?.title || error.message}`}
    message={error.body?.detail || error.message}
  />
);

const ErrorAlert = ({ title, message }: { title: string | number; message?: string | number }) => (
  <Alert severity="error">
    <AlertTitle>{title}</AlertTitle>
    {message}
  </Alert>
);

const modelToName = (model: string) => {
  switch (model) {
    case 'Unit':
      return i18n.t('singles.units');
    case 'Page':
      return i18n.t('singles.pages');
    case 'UnitTemplate':
      return i18n.t('singles.optionLists');
    default:
      return model;
  }
};
const modelToLink = (model: string, link: string) => {
  const to = useTo(SlugLevel.Project);
  switch (model) {
    case 'Unit':
      return `${to}/enheter/${link}`;
    case 'Page':
      return `${to}/sider/${link}`;
    case 'UnitTemplate':
      return `${to}/tilvalg/${link}`;
    default:
      return '';
  }
};
type ConflictErrors = { [key: string]: AptlyErrorBodySimple[] };
const ConflictAlert = ({ error }: Props<ReachError<AptlyErrorBody>>) => {
  const errors: ConflictErrors = useMemo(() => {
    if (!error.body) return {};
    if (!error.body.errors) return {};
    return error.body.errors.reduce<ConflictErrors>((obj, item) => {
      if (!obj[item.title]) {
        obj[item.title] = [];
      }
      obj[item.title].push(item);
      return obj;
    }, {});
  }, [error]);

  if (!error.body) return <AptlyErrorFC error={error} />;
  if (error.body.title === 'locked') {
    return <LockedError errors={errors} />;
  }
  return (
    <>
      <AptlyErrorFC error={error} />
      <ErrorsList errors={errors} />
    </>
  );
};

function LockedError({ errors }: Pick<IErrorsListProps, 'errors'>) {
  return (
    <>
      <Alert severity="error">
        <AlertTitle>{i18n.t('statuses.locked')}</AlertTitle>
        {i18n.t('paragraphs.lockedError')}
      </Alert>
      <ErrorsList errors={errors} />
    </>
  );
}

interface IErrorsListProps {
  title?: string;
  errors: ConflictErrors;
}
function ErrorsList({ title = i18n.t('statuses.conflict'), errors }: IErrorsListProps) {
  const [activeError, setActiveError] = useState(Object.keys(errors).length === 1 ? 0 : -1);
  const handleOnChange = useCallback((i: number) => () => setActiveError((s) => (s === i ? -1 : i)), []);
  return (
    <>
      {Object.keys(errors).map((modelName, i) => {
        const items = errors[modelName] as AptlyErrorBodySimple[];
        return (
          <Accordion
            key={modelName}
            expanded={activeError === i}
            onChange={handleOnChange(i)}
            variant="outlined"
          >
            <AccordionSummary>
              <Typography variant="subtitle2">
                {title}: {items.length} x {modelToName(modelName)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul style={{ margin: 0 }}>
                {items.map((item, ii) => {
                  const to = item.link ? modelToLink(modelName, item.link) : '';
                  const label = <Typography>{item.detail}</Typography>;
                  return <li key={ii}>{to ? <TextLink to={to}>{label}</TextLink> : label}</li>;
                })}
              </ul>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}
