import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MUITableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TableContainer from '@mui/material/TableContainer';
import styled from 'styled-components';

const PREFIX = 'Table';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledMUITableCell = styled(MUITableCell)({
  [`& .${classes.root}`]: {
    position: 'relative',
  },
});

const StylesMUITableCell = styled((props: any) => <StyledMUITableCell {...props} />)`
  .MuiIconButton-root {
    padding: 6px;
    margin: -6px 6px;
  }
`;

const TableCell: any = StylesMUITableCell;

export default Table;
export { TableContainer, TableHead, TableRow, TableBody, TableCell, TableFooter };
