import React, { SyntheticEvent } from 'react';
import { EditorText } from '../components/EditorText';
import createMediaURL from '../models/Media/createMediaURL';
import { Checkbox, Label, Switch, TextField, Select as SelectMultiple } from '../mui/Input';
import ReferenceInput from '../components/ReferenceInput';
import Select from '../mui/Select';
import Typography from '../mui/Typography';
import Chip from '../mui/Chip';
import StatusText from '../components/StatusText';
import Grid from '../mui/Grid';
import ImageUpload from '../models/Media/ImageUpload';
import { tightSpacing } from '../utils/spacing';
import { Query } from './Search/Search';

import {
  AddToSubdocument,
  DeleteFromSubdocument,
  Field as UpdateDeleteField,
  GetField,
  GetSubdocument,
  SetValue,
  Subdocument as UpdateDeleteSubdocument,
  Value,
} from './UpdateDelete';
import ReferenceSelect from './ReferenceSelect';
import i18n from '../libraries/i18n';
import GooglePlaceAutocomplete from '../components/GooglePlaceAutocomplete';
import ChipSelect from '../components/ChipSelect';
import DatePicker from '../mui/x-date-picker/DatePicker';
import DateTimePicker from '../mui/x-date-picker/DateTimePicker';

export type FieldType =
  | 'text'
  | 'email'
  | 'number'
  | 'file'
  | 'image'
  | 'avatarImage'
  | 'select'
  | 'select-multiple'
  | 'select-reference'
  | 'reference'
  | 'checkbox'
  | 'switch'
  | 'customComponent'
  | 'date-picker'
  | 'date-time-picker'
  | 'rich'
  | 'googlePlace'
  | 'color'
  | 'hidden'
  | 'chip-select';

type UpdateDeleteFieldMethods = {
  getField: GetField;
  setValue: SetValue;
};

type UpdateDeleteSubdocumentMethods = {
  getSubdocument: GetSubdocument;
  addToSubdocument: AddToSubdocument;
  deleteFromSubdocument: DeleteFromSubdocument;
};

type Reference = {
  endpoint: string;
  queryKey: string;
  labelKey: string;
  query?: Query;
};

export type FieldSetValue = (name: string, value: Value, label: string, meta?: any) => any;
export type FieldGetField = (name: string) => UpdateDeleteField;
export type Field = UpdateDeleteField & {
  type: FieldType;
  helpField?: string;
  required?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  reference?: Reference;
  referenceName?: string;
  emptyState?: React.ReactNode;
  hideIfSet?: string;
  showIfSet?: string;
  group?: string;
  renderComponent?: (setValue: FieldSetValue, getField: FieldGetField) => React.ReactNode;
  options?: {
    label: string;
    value: string;
  }[];
  extra?: {
    [key: string]: any;
  };
};

export type RenderableFields = {
  [key: string]: (writeable?: boolean) => React.ReactNode;
};

export type Subdocument = UpdateDeleteSubdocument & {
  reference: Reference;
  helpField?: string;
  required?: boolean;
  autoFocus?: boolean;
  emptyState?: React.ReactNode;
};

export type RenderWriteFieldOpts = {
  type: FieldType;
  name: string;
  value: Value;
  label: string;
  setValue: (name: string, value: Value, label: string) => any;
  getField: (name: string) => UpdateDeleteField;
  onChange: (event: SyntheticEvent<HTMLInputElement>) => any;
  onChangeReference: (v: Value, l: any, meta: any) => any;
  reference?: Reference;
  referenceName?: string;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  helpField?: string;
  options?: {
    label: string;
    value: string;
  }[];
  extra?: {
    [key: string]: any;
  };
};

function createRenderableFields(
  fields: Field[],
  methods: UpdateDeleteFieldMethods,
  allWriteable?: boolean
): RenderableFields {
  const renderableFields = {};

  for (let i = 0; i < fields.length; i++) {
    const f = fields[i];

    // @ts-ignore
    renderableFields[f.name] = (writeable) => {
      const updateDeleteField = methods.getField(f.name);

      if (f.hideIfSet || f.showIfSet) {
        const queryString = f.hideIfSet || f.showIfSet || '';
        const targetValue = methods.getField(queryString).value;

        if ((f.hideIfSet && Boolean(targetValue)) || (f.showIfSet && !Boolean(targetValue))) {
          if (updateDeleteField.value) {
            methods.setValue(f.name, null, '');
          }

          return null;
        }
      }

      if (f.type === 'customComponent' && typeof f.renderComponent === 'function') {
        return f.renderComponent(methods.setValue, methods.getField);
      }

      return f.type === 'hidden' ? null : (
        <React.Fragment key={i}>
          {allWriteable || writeable
            ? renderWriteField({
                type: f.type,
                name: f.name,
                value: updateDeleteField.value,
                label: updateDeleteField.label,
                reference: f.reference,
                referenceName: f.referenceName,
                required: f.required,
                disabled: f.disabled,
                autoFocus: f.autoFocus,
                options: f.options,
                helpField: f.helpField,
                setValue: methods.setValue,
                getField: methods.getField,
                extra: f.extra || {},
                onChange: (e: any) => {
                  const targetValue = e.currentTarget ? e.currentTarget.value : e.target.value;
                  let value = targetValue;

                  if (f.type === 'file' && e.currentTarget.files && e.currentTarget.files.length > 0) {
                    value = e.currentTarget.files[0] as any;
                  }

                  methods.setValue(f.name, value, targetValue);
                },
                onChangeReference: (v, l, m) => methods.setValue(f.name, v, l, m),
              })
            : renderReadField(f.type, updateDeleteField.label, f.helpField, f.emptyState)}
        </React.Fragment>
      );
    };
  }

  return renderableFields;
}

function createRenderableSubdocuments(
  subdocuments: Subdocument[],
  methods: UpdateDeleteSubdocumentMethods,
  allWriteable?: boolean
): RenderableFields {
  const renderableSubdocuments = {};

  for (let i = 0; i < subdocuments.length; i++) {
    const doc = subdocuments[i];

    // @ts-ignore
    renderableSubdocuments[doc.name] = (writeable) => (
      <React.Fragment>
        {allWriteable || writeable ? (
          <ReferenceInput<any>
            label={doc.helpField}
            endpoint={doc.reference.endpoint}
            queryKey={doc.reference.queryKey}
            labelKey={doc.reference.labelKey}
            onAdd={(i) =>
              methods.addToSubdocument(doc.name, {
                _id: i._id,
                value: i._id,
                label: i.label,
              })
            }
            onRemove={(id) => methods.deleteFromSubdocument(doc.name, id)}
            defaultSelected={methods.getSubdocument(doc.name).previews.map((p) => ({
              _id: p._id,
              label: p.label,
            }))}
            required={doc.required}
            autoFocus={doc.autoFocus}
          />
        ) : (
          <React.Fragment>
            {doc.helpField && <Label>{doc.helpField}</Label>}
            {renderReadChips(
              methods.getSubdocument(doc.name).previews.map((p) => ({
                _id: p._id,
                label: p.label,
              })),
              doc.emptyState
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return renderableSubdocuments;
}

function renderWriteField(opts: RenderWriteFieldOpts) {
  switch (opts.type) {
    case 'hidden':
      return null;
    case 'reference':
      if (!opts.reference) {
        throw new Error('Missing reference opts');
      }

      return (
        <ReferenceInput<any>
          label={opts.helpField}
          endpoint={opts.reference.endpoint}
          queryKey={opts.reference.queryKey}
          labelKey={opts.reference.labelKey}
          query={opts.reference.query}
          required={opts.required}
          disabled={opts.disabled}
          autoFocus={opts.autoFocus}
          onSelect={(is) => {
            if (!opts.extra || !('singular' in opts.extra) || opts.extra.singular !== false) {
              if (is.length === 1) {
                opts.onChangeReference(is[0]._id, is[0].label, is[0]);
              } else {
                opts.onChangeReference(null, '', {});
              }
            } else {
              opts.onChangeReference(
                is.map((x) => x._id),
                is.map((x) => x.label),
                is
              );
            }
          }}
          singular={opts?.extra?.singular !== false}
          defaultSelected={
            !opts.value
              ? ([] as any)
              : typeof opts.value === 'string'
                ? [
                    {
                      _id: opts.value || '',
                      label: opts.label,
                    },
                  ]
                : opts.value
          }
          {...opts.extra}
        />
      );
    case 'select':
      const value = opts.getField(opts.name).value;

      return (
        <Select
          name={opts.name}
          label={opts.helpField}
          value={typeof value === 'string' ? value : ''}
          onChange={(e) => {
            opts.setValue(opts.name, e.target.value, e.target.value);
          }}
          options={opts.options || []}
          disabled={opts.disabled}
          fullWidth
          {...opts.extra}
        />
      );
    case 'select-multiple':
      return (
        <SelectMultiple
          name={opts.name}
          label={opts.helpField}
          value={opts.getField(opts.name).value as string[]}
          onChange={(e) => {
            opts.setValue(opts.name, e.target.value, e.target.value);
          }}
          options={opts.options || []}
          disabled={opts.disabled}
          fullWidth
          multiple
          {...opts.extra}
        />
      );
    case 'select-reference':
      if (!opts.reference) {
        throw new Error('Missing reference opts');
      }
      if (!opts.referenceName) {
        throw new Error('Missing referenceName opts');
      }
      const selectValue = opts.getField(opts.referenceName).value;
      const reference: any = opts.reference;

      return (
        <ReferenceSelect
          value={typeof selectValue === 'string' ? selectValue : ''}
          endpoint={reference.endpoint}
          queryKey={reference.queryKey}
          labelKey={reference.labelKey}
          label={opts.helpField}
          onChange={(e: any) => {
            opts.setValue(opts.name, e.target.value, e.target.value);
          }}
          fullWidth
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          checked={Boolean(opts.value)}
          onChange={(_e: any, checked: any) => {
            opts.setValue(opts.name, checked, checked.toString());
          }}
          label={opts.helpField}
          disabled={opts.disabled}
          color="primary"
        />
      );
    case 'switch':
      return (
        <Switch
          checked={Boolean(opts.value)}
          onChange={(_e, checked) => {
            opts.setValue(opts.name, checked, checked.toString());
          }}
          label={opts.helpField}
          disabled={opts.disabled}
          color="primary"
        />
      );
    case 'avatarImage':
    case 'image':
      return (
        <Grid container spacing={tightSpacing} direction="column">
          {opts.helpField && (
            <Grid item>
              <Label>{opts.helpField}</Label>
            </Grid>
          )}
          <Grid item>
            <ImageUpload
              label={i18n.t('actions.uploadImage')}
              onComplete={(image) => {
                opts.setValue(opts.name, image, '');
              }}
              onDelete={(images) => {
                opts.setValue(
                  opts.name,
                  images.length > 0
                    ? (images as []).map((image: any) => {
                        if (Object.keys(image).length > 1) {
                          return image;
                        } else {
                          const imageId = image.preview.match(/media\/(.*)\//);
                          return imageId && imageId[1] ? imageId[1] : image;
                        }
                      })
                    : opts.extra && 'multiple' in opts.extra && Boolean(opts.extra.multiple)
                      ? []
                      : 'null',
                  ''
                );
              }}
              profile={opts.type === 'avatarImage'}
              disabled={opts.disabled}
              required={opts.required}
              images={
                Array.isArray(opts.value)
                  ? (opts.value.map((image) => ({
                      preview: createMediaURL(image, { width: 200 }),
                    })) as any)
                  : undefined
              }
              defaultImage={typeof opts.value === 'string' ? createMediaURL(opts.value, { width: 200 }) : ''}
              {...opts.extra}
            />
          </Grid>
        </Grid>
      );
    case 'date-picker':
      return (
        <DatePicker
          label={opts.helpField}
          value={(opts.value as string) || (null as any)}
          onChange={(date) => {
            const value: any = {
              currentTarget: { value: date },
            };
            opts.onChange(value);
          }}
          {...opts.extra}
        />
      );
    case 'date-time-picker':
      return (
        <DateTimePicker
          label={opts.helpField}
          value={(opts.value as string) || (null as any)}
          ampm={false}
          onChange={(date: any) => {
            const value: any = {
              currentTarget: { value: date },
            };
            opts.onChange(value);
          }}
          {...opts.extra}
        />
      );
    case 'googlePlace':
      return (
        <GooglePlaceAutocomplete
          onChange={(place) => opts.setValue(opts.name, place, place.name)}
          defaultValue={
            opts.value && typeof opts.value === 'object' && 'formatted_address' in opts.value
              ? opts.value.formatted_address || opts.value.name
              : ''
          }
          label={opts.helpField}
          disabled={opts.disabled}
          autoFocus={opts.autoFocus}
          required={opts.required}
          fullWidth
          {...opts.extra}
        />
      );
    case 'chip-select':
      return (
        <ChipSelect
          defaultValue={(opts.value as string[]) || []}
          onChange={(v) => opts.setValue(opts.name, v, v.join(', '))}
          hasInputField
        />
      );
    default:
      return (
        <TextField
          type={opts.type}
          label={opts.helpField}
          value={opts.label}
          onChange={opts.onChange as any}
          required={opts.required}
          autoFocus={opts.autoFocus}
          disabled={opts.disabled}
          InputLabelProps={
            opts.type === 'file'
              ? {
                  shrink: true,
                }
              : {}
          }
          fullWidth
          {...opts.extra}
          multiple
        />
      );
  }
}

function renderReadField(type: FieldType, label: string, helpField?: string, emptyState?: React.ReactNode) {
  let elem;

  if (label && label !== '') {
    if (type === 'rich') {
      elem = <EditorText value={label} />;
    } else {
      elem = <Typography>{label}</Typography>;
    }
  } else {
    elem = renderStatusText(emptyState);
  }

  return (
    <React.Fragment>
      {helpField && <Label>{helpField}</Label>}
      {elem}
    </React.Fragment>
  );
}

function renderStatusText(emptyState?: React.ReactNode) {
  return <StatusText block>{emptyState || i18n.t('statuses.missing')}</StatusText>;
}

function renderReadChips(
  chips: {
    _id: string;
    label: string;
  }[],
  emptyState?: React.ReactNode
) {
  if (chips.length > 0) {
    return (
      <Grid container spacing={tightSpacing}>
        {chips.map((c) => (
          <Grid key={c._id} item>
            <Chip label={c.label} />
          </Grid>
        ))}
      </Grid>
    );
  }

  return renderStatusText(emptyState);
}

export { createRenderableFields, createRenderableSubdocuments };
