import Typography, { TypographyProps } from '@mui/material/Typography';
import styled, { css } from 'styled-components';

export default Typography;
export type { TypographyProps };

export const TypographyEllipsis = styled(({ ...props }) => <Typography {...props} />)<{ lines?: number }>`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
  width: 100%;
  ${(props) =>
    props.lines &&
    css`
      display: -webkit-box;
      white-space: normal;
      -webkit-line-clamp: ${props.lines};
      -webkit-box-orient: vertical;
    `};
`;
