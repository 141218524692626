import { AptlyInquiry, AptlyInquiryStatus, AptlyFieldType } from '@aptly-as/types';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { inquiryStatusOptions } from './InquiryUtils';

export interface IInquirySchema extends AptlyInquiry {
  message?: string;
  files?: File[];
}

export const inquirySchema = (): ICrudSchema<IInquirySchema> => ({
  status: {
    type: AptlyFieldType.Select,
    defaultValue: AptlyInquiryStatus.Closed,
    label: i18n.t('singles.status'),
    options: inquiryStatusOptions(),
  },
  unit: {
    type: AptlyFieldType.Reference,
    defaultValue: '',
    label: i18n.t('singles.unit'),
    required: true,
    useReference: () => {
      const endpoint = useApiUrl(SlugLevel.Project, 'units');
      return {
        endpoint,
        queryKey: '$name',
        labelKey: 'name',
        query: { as: 'array', select: '_id,name', limit: 5 },
      };
    },
  },
  title: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('singles.title'),
    required: true,
  },
  message: {
    type: AptlyFieldType.Markdown,
    defaultValue: '',
    label: i18n.t('singles.message'),
    required: true,
  },
  files: {
    type: AptlyFieldType.Files,
    defaultValue: [],
    label: i18n.t('singles.files'),
  },
});

export default inquirySchema;
