import React from 'react';
import Section from './Section';
import Grid from '../mui/Grid';
import Typography from '../mui/Typography';

interface Props extends JSX.ElementChildrenAttribute {
  title: string;
  description: React.ReactNode;
  headerActions?: JSX.Element;
}

export default function StyledIntroDetailed({ title, headerActions, description, children }: Props) {
  return (
    <React.Fragment>
      <Section>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">{title}</Typography>
          </Grid>
          {headerActions && <Grid item>{headerActions}</Grid>}
        </Grid>
        <Grid container>{description}</Grid>
      </Section>
      <Section>{children as any}</Section>
    </React.Fragment>
  );
}
