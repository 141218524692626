import { PropsWithChildren, useCallback } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import { fileListToArray } from '../libraries/react-dropzone/DropZone';
import useFileUploader, { FileUploaderFn } from './useFileUploader';

interface UseScreenDropZoneProps extends Pick<DropzoneOptions, 'accept' | 'multiple' | 'disabled'> {
  onDrop: FileUploaderFn<true>;
}

export default function useScreenMediaDropzone({
  onDrop,
  ...rest
}: PropsWithChildren<UseScreenDropZoneProps>) {
  const [, handleOnDrop] = useFileUploader(Boolean(rest.multiple), onDrop);

  const handleDrop = useCallback(
    async (acceptedFiles: any) => {
      handleOnDrop(fileListToArray(acceptedFiles));
    },
    [handleOnDrop]
  );

  return useDropzone({
    onDrop: handleDrop,
    noClick: true,
    ...rest,
  });
}

export interface ScreenDropzoneProps {
  active?: boolean;
}

export const ScreenDropzone = styled.div<ScreenDropzoneProps>`
  display: none;
  ${(props) =>
    props.active &&
    css`
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background: rgba(0, 0, 0, 0.5);
    `}
`;
