import i18n from '../../../libraries/i18n';
import { tightSpacing } from '../../../utils/spacing';
import Grid from '../../../mui/Grid';
import { BaseButtonWithIcon, CancelButtonWithIcon, DeleteButtonWithIcon } from './Buttons';
import { MouseEvent, PropsWithChildren } from 'react';
import Save from '@mui/icons-material/Save';

export interface IActionButtonProps {
  actionLabel?: string;
  cancelLabel?: string;
  actionIcon?: React.ReactNode;
  onClose?: () => void;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onDelete?: () => void;
  submitType?: 'button' | 'submit';
  disabled?: boolean;
  disabledClose?: boolean;
  disabledSubmit?: boolean;
  autoFocus?: boolean;
}

export default function ActionButtons({
  onClose,
  onClick,
  onDelete,
  submitType = 'submit',
  disabled,
  disabledClose,
  disabledSubmit,
  actionLabel = i18n.t('actions.save'),
  actionIcon = <Save />,
  cancelLabel,
  autoFocus,
  children,
}: PropsWithChildren<IActionButtonProps>) {
  return (
    <Grid container justifyContent="flex-end" spacing={tightSpacing}>
      {onDelete && (
        <Grid item>
          <DeleteButtonWithIcon onClick={onDelete} />
        </Grid>
      )}
      {onClose && (
        <Grid item>
          <CancelButtonWithIcon onClick={onClose} disabled={disabled || disabledClose}>
            {cancelLabel}
          </CancelButtonWithIcon>
        </Grid>
      )}
      {children}
      {(onClick || submitType === 'submit') && (
        <Grid item>
          <BaseButtonWithIcon
            type={onClick ? 'button' : submitType}
            onClick={onClick}
            disabled={disabled || disabledSubmit}
            endIcon={actionIcon}
            autoFocus={autoFocus}
            color="primary"
            variant="contained"
          >
            {actionLabel}
          </BaseButtonWithIcon>
        </Grid>
      )}
    </Grid>
  );
}
