import { useCallback, useMemo } from 'react';
import { AptlyApp, AptlyAppWebhook, AptlyScopes } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import WebhookIcon from '@mui/icons-material/Webhook';
import ActionButtons from '../../../components/actions/buttons/ActionButtons';
import { EditIconButton } from '../../../components/actions/icons/Icons';
import Search from '../../../components/Search/Search';
import SearchCreateAction from '../../../components/Search/search-actions/SearchCreateAction';
import { IGridActionProps } from '../../../components/Search/search-data-grid/data-grid.actions';
import { dataGridActions } from '../../../components/Search/search-data-grid/data-grid.fields';
import { useDataGridEditModal } from '../../../components/Search/search-data-grid/data-grid.hooks';
import SearchDataGrid from '../../../components/Search/SearchDataGrid';
import { ICreateModelOptions } from '../../../containers/Modal/modal.types';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import Dialog, { ModalActions } from '../../../mui/Dialog';
import { appWebhookSchema, appWebhookSchemaFields } from '../app.schema';

export default function AppsWebhooks({ params }: IGridActionProps) {
  const { state, onShow, onChange } = useDataGridEditModal<AptlyApp>(params);
  const handleOnChange = useCallback(
    (webhooks: AptlyAppWebhook[]) => {
      onChange((data) => ({ ...(data as AptlyApp), webhooks }));
    },
    [onChange]
  );

  return (
    <>
      <EditIconButton onClick={onShow} icon={<WebhookIcon />} title={i18n.t('singles.webhooks')} />
      <Dialog open={Boolean(state.data)} maxWidth="md" onClose={onShow}>
        {state.data && <ModalContent app={state.data} onChange={handleOnChange} />}
        <ModalActions>
          <ActionButtons onClose={onShow} submitType="button" cancelLabel={i18n.t('actions.close')} />
        </ModalActions>
      </Dialog>
    </>
  );
}

interface IModalContentProps {
  app: AptlyApp;
  onChange: (webhooks: AptlyAppWebhook[]) => void;
}

const modalProps: ICreateModelOptions = { width: 'sm' };

const appMenuColumns = (): GridColDef[] => [
  {
    type: 'string',
    field: 'types',
    headerName: i18n.t('singles.types'),
    flex: 1,
  },
  {
    type: 'string',
    field: 'url',
    headerName: i18n.t('singles.url'),
    flex: 1,
  },
  dataGridActions(),
];

function ModalContent({ app, onChange }: IModalContentProps) {
  const path = useApiUrl(SlugLevel.Organization, `apps/${app._id}/webhooks`);
  const schema = useMemo(appWebhookSchema, []);
  const columns = useMemo(appMenuColumns, []);

  return (
    <Search<AptlyAppWebhook>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.AdminApps}
      header={{ title: i18n.t('singles.webhooks') }}
      post={{ title: i18n.t('singles.webhooks') }}
      patch={{ title: i18n.t('singles.webhooks') }}
      schema={schema}
      fields={appWebhookSchemaFields}
      options={{ disableBulk: true }}
      reach={{ limit: 999 }}
      buttonActions={() => [<SearchCreateAction key="create" />]}
      modalProps={modalProps}
      onChange={onChange}
    >
      {() => <SearchDataGrid columns={columns} pageSize={10} />}
    </Search>
  );
}
