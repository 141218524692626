import { useGet } from '@ewb/reach-react';
import MuiCheckbox from '@mui/material/Checkbox/Checkbox';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography';
import { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';
import {
  AptlyAppImplementationSetting,
  AptlyAppSetting,
  AptlyOrderActionItem,
  AptlyOrderActionItemSignSettings,
  AptlyUnit,
  AptlyUser,
} from '@aptly-as/types';
import ApiError from '../../../components/ApiError';
import ReferenceInput from '../../../components/ReferenceInput';
import { CrudFormTextField, useCrudFormField } from '../../../components/simple/CrudForm';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import { getId } from '../../../libraries/mongoose';
import Paper from '../../../mui/Paper';
import Select from '../../../mui/Select';
import Table, { TableCell, TableHead, TableRow } from '../../../mui/Table';
import { useAppSetting } from '../../App/app.utils';
import { UserContext } from '../../User/UserContext';
import { EmailContent, HandleAptlyOrder, HandleOrderBody, HandleOrderUser } from './HandleOrder.utils';

interface SignProps {
  order: HandleAptlyOrder;
  action: AptlyOrderActionItem<AptlyOrderActionItemSignSettings>;
}

export function HandleOrderSign({ order, action }: SignProps) {
  const { data } = useContext(UserContext);
  const users = useCrudFormField<HandleOrderBody, 'users'>('users');

  const [defaultSignMethod, signSettings] = useAppSetting(
    action.app,
    action.appSettings,
    AptlyAppImplementationSetting.SignMethod
  );
  const [usersList, setUsersList] = useState<HandleOrderUser[]>([
    {
      user: data,
      signMethod: defaultSignMethod,
      isAdmin: true,
    },
  ]);
  const membersEndpoint = useApiUrl(SlugLevel.Organization, 'users');
  const endpoint = useApiUrl(SlugLevel.Project, `units/${getId(order.unit)}`, {
    projectID: getId(order.project),
  });
  const path = `${endpoint}?${new URLSearchParams({
    select: 'users',
    populate: 'users:_id,fullName,email',
  })}`;

  const [unit] = useGet<AptlyUnit>(
    path,
    useMemo(
      () => ({
        onGet: (res) => {
          if (res) {
            setUsersList((s) => {
              const newState = [
                ...(res.users as AptlyUser[])
                  .filter((x) => x._id !== data._id)
                  .map((x) => ({ user: x, signMethod: defaultSignMethod })),
                ...s,
              ];
              users.onChange(newState.map((u) => ({ ...u, user: u.user._id })));
              return newState;
            });
          }
        },
      }),
      [data, users, defaultSignMethod]
    )
  );

  const handleOnClick = useCallback(
    (item: HandleOrderUser) => {
      const data = users.field.value;
      const i = data.findIndex((x) => x.user === item.user._id);
      if (i > -1) {
        users.onChange(data.filter((x) => x.user !== item.user._id));
        return;
      }
      users.onChange([...data, { ...item, user: item.user._id }]);
    },
    [users]
  );
  const handleOnMethodChange = useCallback(
    (item: HandleOrderUser, method: string) => {
      const data = users.field.value;
      const i = data.findIndex((x) => x.user === item.user._id);
      if (i > -1) {
        data[i].signMethod = method;
        users.onChange([...data]);
      }
    },
    [users]
  );

  const handleOnSelectAdminUsers = useCallback(
    (newUsers: AptlyUser[]) => {
      setUsersList((s) => {
        const newStateUsers = newUsers.map((x) => ({
          user: x,
          signMethod: defaultSignMethod,
          isAdmin: true,
        }));
        const newState = [...s, ...newStateUsers];
        users.onChange([...users.field.value, ...newStateUsers.map((x) => ({ ...x, user: x.user._id }))]);
        return newState;
      });
    },
    [users, defaultSignMethod]
  );

  const membersQuery = useMemo(
    () => ({
      as: 'array',
      select: '_id,fullName,email',
      $nin__id: usersList.map((u) => u.user._id).join(','),
    }),
    [usersList]
  );

  if (!unit.data) {
    return <ApiError error={unit.error} />;
  }

  return (
    <Grid container flexDirection="column" gap={2}>
      {unit.error && <ApiError error={unit.error} />}
      <Grid container item flexDirection="column" gap={2}>
        <Grid item>
          <CrudFormTextField name="deadlineInDays" />
        </Grid>
        <Grid item>
          <Typography variant="h3">{i18n.t('actions.selectUsersToSign')}</Typography>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{i18n.t('singles.user')}</TableCell>
                  {signSettings && <TableCell>{i18n.t('singles.method')}</TableCell>}
                  <TableCell width={40} padding="checkbox">
                    {i18n.t('singles.active')}
                  </TableCell>
                </TableRow>
              </TableHead>
              {usersList.map((item) => (
                <UserItemRow
                  key={item.user._id}
                  item={item}
                  checked={users.field.value.some((x) => x.user === item.user._id)}
                  onActive={handleOnClick}
                  onMethodChange={handleOnMethodChange}
                  defaultSignMethod={defaultSignMethod}
                  signSettings={signSettings}
                />
              ))}
            </Table>
          </Paper>
        </Grid>
        <Grid item>
          <ReferenceInput<AptlyUser>
            endpoint={membersEndpoint}
            query={membersQuery}
            onSelect={handleOnSelectAdminUsers}
            queryKey="fullName"
            labelKey="fullName"
            label={i18n.t('actions.addUser')}
            noInternalState
          />
        </Grid>
      </Grid>
      <Grid container></Grid>
      <Grid item>
        <EmailContent />
      </Grid>
    </Grid>
  );
}

interface UserItemProps {
  item: HandleOrderUser;
  checked: boolean;
  onActive: (item: HandleOrderUser) => void;
  onMethodChange: (item: HandleOrderUser, method: string) => void;
  defaultSignMethod?: string;
  signSettings?: AptlyAppSetting;
}

function UserItemRow({
  item,
  checked,
  onActive,
  onMethodChange,
  defaultSignMethod,
  signSettings,
}: UserItemProps) {
  const handleOnClick = useCallback(() => onActive(item), [onActive, item]);
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onMethodChange(item, e.target.value);
    },
    [onMethodChange, item]
  );
  return (
    <TableRow key={item.user._id}>
      <TableCell>
        <Typography variant="subtitle1">{item.user.fullName}</Typography>
        <Typography variant="subtitle2">{item.user.email}</Typography>
      </TableCell>
      {signSettings && signSettings.options && (
        <TableCell>
          <Select
            defaultValue={defaultSignMethod}
            options={signSettings.options}
            onChange={handleOnChange}
            fullWidth
          />
        </TableCell>
      )}
      <TableCell padding="checkbox">
        <MuiCheckbox edge="end" checked={checked} onClick={handleOnClick} />
      </TableCell>
    </TableRow>
  );
}
