import { AptlyScopes } from '@aptly-as/types';
import Add from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Alert } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { PositionParent } from '../../components/Position';
import { ModalButton } from '../../containers/Modal/ModalButton';
import i18n from '../../libraries/i18n';
import { intlDateTimeFormat } from '../../libraries/intl';
import { getId } from '../../libraries/mongoose';
import { LayoutScope } from '../../libraries/scope/ScopeComponets';
import Button from '../../mui/Button';
import Grid from '../../mui/Grid';
import Paper from '../../mui/Paper';
import { LinearProgresser } from '../../mui/Progresser';
import Typography from '../../mui/Typography';
import { rawSpacing, tightSpacing } from '../../utils/spacing';
import { useUser } from '../User/UserContext';
import BookingCalendarDelete from './BookingCalendarDelete';
import {
  BookingContext,
  BookingProvider,
  CalendarProvider,
  useAvailability,
  useCalendar,
} from './BookingContext';
import BookingDatePicker, { AvailableDot, BookedDot } from './BookingDatePicker';
import BookingList from './BookingList';
import { BookingTimeAdd } from './BookingTimeAdd';
import BookingTimes from './BookingTimes';
import CalendarAddOrEdit from './CalendarAddOrEdit';

export default function Calendar() {
  const availability = useAvailability();
  const { calendarID } = useParams();
  if (!availability) {
    return null;
  }
  return (
    <CalendarProvider id={calendarID!}>
      <BookingProvider>
        <Content />
      </BookingProvider>
    </CalendarProvider>
  );
}

function Content() {
  const availability = useAvailability();
  const calendar = useCalendar();
  const user = useUser();
  const navigate = useNavigate();
  const { state, setDate, addUserAvailability, deleteUserAvailability } = useContext(BookingContext);

  const handleDateChange = useCallback(
    (date: Dayjs | null) => {
      if (!date) {
        return;
      }
      setDate(date);
    },
    [setDate]
  );

  const handleCalendarDelete = useCallback(() => {
    navigate('../motebooking');
  }, []);

  if (!availability || !calendar) {
    return null;
  }

  const isUserConnected = user ? calendar.userAvailability?.some((x) => getId(x) === user._id) : false;

  return (
    <Grid container direction="column" spacing={tightSpacing}>
      {state.busy && (
        <PositionParent>
          <LinearProgresser offset />
        </PositionParent>
      )}
      <Grid container item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">{calendar.name}</Typography>
            <Typography>{calendar.description}</Typography>
          </Grid>
          <LayoutScope item scope={AptlyScopes.ProjectBooking} crud="U">
            <Grid container spacing={tightSpacing}>
              <Grid item>
                <BookingCalendarDelete />
              </Grid>
              <Grid item>
                <ModalButton
                  label={i18n.t('actions.edit')}
                  color="primary"
                  variant="outlined"
                  endIcon={<EditOutlinedIcon />}
                >
                  <CalendarAddOrEdit calendar={calendar} onDestroy={handleCalendarDelete} />
                </ModalButton>
              </Grid>
              <Grid item>
                <ModalButton label={i18n.t('actions.addTime')} variant="outlined" endIcon={<Add />}>
                  <BookingTimeAdd />
                </ModalButton>
              </Grid>
            </Grid>
          </LayoutScope>
        </Grid>
      </Grid>
      <Grid container item>
        {isUserConnected ? (
          <Alert severity="info">
            {i18n.t('info.booking.deleteUserFromCalendar')}
            <Button color="warning" onClick={deleteUserAvailability}>
              {i18n.t('actions.removeConnection')}
            </Button>
          </Alert>
        ) : (
          <Alert severity="warning">
            {i18n.t('info.booking.connectUserToCalendar')}
            <Button onClick={addUserAvailability}>{i18n.t('actions.confirm')}</Button>
          </Alert>
        )}
      </Grid>
      <Grid item>
        <Grid container spacing={rawSpacing} justifyContent="space-between">
          <Grid item xs={12} lg={4}>
            <StyledPaper>
              <Grid container direction="column" spacing={tightSpacing}>
                {calendar.endAvailabilityAt && (
                  <Grid item>
                    <Alert severity="info">
                      {i18n.t('info.booking.endAvailabilityAtActive')}{' '}
                      <strong>{intlDateTimeFormat(calendar.endAvailabilityAt)}</strong>
                    </Alert>
                  </Grid>
                )}
                {!calendar.endAvailabilityAt &&
                  calendar.userAvailability &&
                  calendar.userAvailability.length > 0 &&
                  isUserConnected && (
                    <Grid item>
                      <Alert severity="warning">
                        {i18n.t('info.booking.endAvailabilityAtInactive')}{' '}
                        <ModalButton label={i18n.t('actions.edit')}>
                          <CalendarAddOrEdit calendar={calendar} onDestroy={handleCalendarDelete} />
                        </ModalButton>
                      </Alert>
                    </Grid>
                  )}
                <Grid item>
                  <Grid container alignItems={'center'}>
                    <BookedDot />
                    <StyledTypography>{i18n.t('paragraphs.meetingBookedThatDay')}</StyledTypography>
                  </Grid>
                  <Grid container alignItems={'center'}>
                    <AvailableDot />
                    <StyledTypography>{i18n.t('paragraphs.availableMeetingSlots')}</StyledTypography>
                  </Grid>
                </Grid>
                <Grid item>
                  <BookingDatePicker onChange={handleDateChange} onMonthChange={handleDateChange} />
                </Grid>
                <LayoutScope item scope={AptlyScopes.ProjectBooking} crud="U">
                  <Grid container direction={'column'} spacing={tightSpacing}>
                    <Grid item>
                      <Typography>{i18n.t('actions.addMeetingSlotsToday')}</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="center">
                        <ModalButton label={i18n.t('actions.addTime')} variant="outlined" endIcon={<Add />}>
                          <BookingTimeAdd />
                        </ModalButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </LayoutScope>
              </Grid>
            </StyledPaper>
          </Grid>
          <Grid container item xs={12} lg={8} spacing={rawSpacing}>
            <Grid item xs={12} xl={6}>
              <CardTitles variant="body2">{i18n.t('paragraphs.todaysMeetings')}</CardTitles>
              <StyledPaper>
                <BookingList />
              </StyledPaper>
            </Grid>
            <Grid item xs={12} xl={6}>
              <CardTitles variant="body2">{i18n.t('paragraphs.availableSlotsThisDay')}</CardTitles>
              <StyledPaper>
                <BookingTimes />
              </StyledPaper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const StyledPaper = styled(Paper)`
  min-height: 250px;
  padding: 1rem;
`;

const StyledTypography = styled(Typography)`
  margin-left: 0.5rem !important;
`;

const CardTitles = styled(Typography)`
  margin-bottom: 1rem !important;
`;
