import { LinearProgresser } from '../../mui/Progresser';
import { PositionParent } from '../Position';

export default function SearchLoader() {
  return (
    <PositionParent>
      <LinearProgresser offset />
    </PositionParent>
  );
}
