import { AptlyScopes, AptlyScopeCrud, AptlyScopeSchema } from '@aptly-as/types';

export type ScopeModel = Partial<AptlyScopeSchema>;

class Scope {
  private _scopes: ScopeModel = {};

  constructor(models?: ScopeModel) {
    if (models) {
      this._scopes = models;
    }
  }

  set scopes(scopes: ScopeModel) {
    this._scopes = scopes;
  }

  public crud(scope: AptlyScopes | AptlyScopes[], permission: AptlyScopeCrud = 'R', every?: boolean) {
    const models: AptlyScopes[] = Array.isArray(scope) ? scope : [scope];
    if (every) {
      return models.every((x) => this._scopes[x] && this._scopes[x]!.includes(permission));
    }
    return models.some((x) => this._scopes[x] && this._scopes[x]!.includes(permission));
  }
}

let instance: Scope | null = null;

if (!instance) {
  instance = new Scope();
}

export default instance as Scope;
