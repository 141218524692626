import { arrayMove } from '@dnd-kit/sortable';
import {
  AptlyUnitTemplate,
  AptlyUnitTemplateCategorySection,
  AptlyUnitTemplateCategorySectionAssortment,
  AptlyUnitTemplateCategorySectionPackage,
  AptlyUnitTemplateCategorySectionProduct,
} from '@aptly-as/types';
import { byOrderIds } from '../../../utils/array';
import { IUseSortableData } from './unit-template.utils';

export type IUnitTemplateLevel = 'category' | 'section' | 'product' | 'package' | 'package-product' | 'error';

interface SliceProps {
  category: string;
  section?: string;
  product?: string;
  package?: string;
}

export const toSliceProps = (data: IUseSortableData): SliceProps => ({
  category: data.category._id,
  section: data.section?._id,
  product: data.product?._id,
  package: data.package?._id,
});

export type IUpdatedType = 'section' | 'product' | 'package' | null;
export function sliceSectionOrProduct<L extends IUnitTemplateLevel>(
  unitTemplate: AptlyUnitTemplate,
  from: SliceProps
): [
  AptlyUnitTemplate,
  L extends 'product' ? AptlyUnitTemplateCategorySectionProduct[] : AptlyUnitTemplateCategorySection[],
  AptlyUnitTemplateCategorySectionPackage | undefined,
  IUpdatedType,
  AptlyUnitTemplateCategorySectionAssortment[],
] {
  const sliced: any[] = [];
  const assortments: AptlyUnitTemplateCategorySectionAssortment[] = [];
  let pack: AptlyUnitTemplateCategorySectionPackage | undefined = undefined;
  let updated: IUpdatedType = null;

  const newUnitTemplate: AptlyUnitTemplate = {
    ...unitTemplate,
    categories: unitTemplate.categories.map((category) => {
      const sections = category.sections
        .map((section) => {
          if (updated) return { ...section };
          if (from.package && !pack) {
            pack = section.packages?.find((x) => x._id === from.package);
            if (pack) {
              updated = 'package';
              sliced.push(...section.products.filter((x) => x.partOfPackage === from.package));
              if (section.assortments) {
                assortments.push(...section.assortments.filter((x) => x.package === from.package));
              }
              return {
                ...section,
                assortments: section.assortments?.filter((x) => x.package !== from.package) || [],
                packages: section.packages.filter((x) => x._id !== pack!._id),
                products: section.products.filter((x) => x.partOfPackage !== from.package),
              };
            }
            return section;
          } else if (!from.product && from.section) {
            if (section._id === from.section) {
              updated = 'section';
              sliced.push(section);
              return null;
            }
            return { ...section };
          } else {
            const products = from.product
              ? (section.products
                  .map((product) => {
                    if (product._id === from.product) {
                      updated = 'product';
                      product.partOfAssortment = undefined;
                      sliced.push(product);
                      return null;
                    }
                    return { ...product };
                  })
                  .filter(Boolean) as AptlyUnitTemplateCategorySectionProduct[])
              : [];
            return { ...section, products };
          }
        })
        .filter(Boolean) as AptlyUnitTemplateCategorySection[];
      return { ...category, sections };
    }),
  };
  return [newUnitTemplate, sliced as any, pack, updated, assortments];
}

export function addSectionOrProducts<L extends IUnitTemplateLevel>(
  unitTemplate: AptlyUnitTemplate,
  to: SliceProps,
  items: L extends 'product' ? AptlyUnitTemplateCategorySectionProduct[] : AptlyUnitTemplateCategorySection[],
  index: number,
  updated: IUpdatedType,
  pack?: AptlyUnitTemplateCategorySectionPackage,
  assortments?: AptlyUnitTemplateCategorySectionAssortment[]
): AptlyUnitTemplate {
  const newState = {
    ...unitTemplate,
    categories: unitTemplate.categories.map((category) => {
      if (category._id === to.category) {
        if (updated === 'section') {
          const newCategory = { ...category, sections: [...category.sections] };
          items.forEach((item: any, i: number) => {
            newCategory.sections.splice(index + i, 0, item);
          });
          return newCategory;
        }
        return {
          ...category,
          sections: category.sections.map((section) => {
            if ((updated === 'product' || updated === 'package') && section._id === to.section) {
              const newSection = { ...section, products: [...section.products] };
              items.forEach((item: any, i: number) => {
                newSection.products.splice(index + i, 0, item);
              });
              if (pack) {
                newSection.packages = [...newSection.packages, pack];
              }
              if (assortments && assortments.length > 0) {
                if (newSection.assortments) {
                  newSection.assortments = [...newSection.assortments, ...assortments];
                } else {
                  newSection.assortments = assortments;
                }
              }
              return newSection;
            }
            return section;
          }),
        };
      }
      return category;
    }),
  };

  return newState;
}

const bySinglePackageProducts = () => {
  const packages: string[] = [];
  return (product: AptlyUnitTemplateCategorySectionProduct) => {
    if (product.partOfPackage) {
      if (!packages.includes(product.partOfPackage)) {
        packages.push(product.partOfPackage);
        return true;
      } else {
        return false;
      }
    }
    return true;
  };
};

export function orderSectionOrProduct(
  unitTemplate: AptlyUnitTemplate,
  from: IUseSortableData,
  to: IUseSortableData
): [AptlyUnitTemplate, string[], string] | null {
  const categoryID = from.category?._id;
  const sectionID = from.section?._id;
  const productID = from.product?._id;
  const packageID = from.package?._id;
  let orderKey = '';
  let ids: string[] = [];
  let categories = unitTemplate.categories;
  // Temp fix for issues in DND. Gives error and no data is given. Probably fixed in newer versions.
  if (!to.sortable || !from.sortable) {
    return null;
  }

  if (categoryID && sectionID) {
    categories = categories.map((category) => {
      if (ids.length > 0) return category;
      if (category._id !== categoryID) return category;
      if (productID || packageID) {
        category.sections = category.sections.map((section) => {
          if (section._id !== sectionID) return section;
          if (packageID && productID) {
            const packIndex = section.packages.findIndex((x) => x._id === packageID);
            const pack = section.packages[packIndex];
            const product = section.products.find((x) => x._id === productID);
            if (pack && product) {
              pack._product_order = section.products
                .filter((x) => x.partOfPackage === packageID)
                .sort(byOrderIds(pack._product_order || []))
                .map((x) => x._orderID);
              pack._product_order = arrayMove(pack._product_order, from.sortable.index, to.sortable.index);
              ids = pack._product_order;
              orderKey = '_product_order';
              section.packages[packIndex] = { ...pack };
            }
          } else if (packageID) {
            const packProducts = section.products.filter(
              (x) => x.partOfPackage === (from.package?._id || packageID)
            );
            section._order_products = section.products
              .sort(byOrderIds(section._order_products))
              .filter(bySinglePackageProducts())
              .map((x) => x._orderID);
            if (packProducts[0]) {
              const index = section._order_products.findIndex((x) => x === packProducts[0]._orderID);
              section._order_products = arrayMove(section._order_products, index, to.sortable.index);
            }
            ids = [...section._order_products];
            orderKey = '_order_products';
          } else {
            section._order_products = section.products
              .sort(byOrderIds(section._order_products))
              .map((x) => x._orderID);
            section._order_products = arrayMove(
              section._order_products,
              from.sortable.index,
              to.sortable.index
            );
            ids = section._order_products;
            orderKey = '_order_products';
          }
          return { ...section };
        });
      } else {
        category._order_sections = category.sections
          .sort(byOrderIds(category._order_sections))
          .map((x) => x._orderID);
        category._order_sections = arrayMove(
          category._order_sections,
          from.sortable.index,
          to.sortable.index
        );
        ids = category._order_sections;
        orderKey = '_order_sections';
      }
      return { ...category };
    });
  } else {
    unitTemplate._order_categories = categories
      .sort(byOrderIds(unitTemplate._order_categories))
      .map((x) => x._orderID);
    unitTemplate._order_categories = arrayMove(
      unitTemplate._order_categories,
      from.sortable.index,
      to.sortable.index
    );
    ids = unitTemplate._order_categories;
    orderKey = '_order_categories';
  }

  return [{ ...unitTemplate, categories }, ids, orderKey];
}
