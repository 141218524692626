import { GridProps } from '@mui/material/Grid';
import Grid, { GridSize } from '../../mui/Grid';
import { rawSpacing } from '../../utils/spacing';
import PageOverlay from '../PageOverlay';
import Section from '../Section';

export interface SearchPageOverlayProps {
  show: boolean;
  searchFields: (object | [GridProps, object])[];
}

export default function SearchPageOverlay({ show, searchFields }: SearchPageOverlayProps) {
  if (searchFields.length === 0) {
    return null;
  }
  return (
    <PageOverlay open={show}>
      <Section>
        <Grid container spacing={rawSpacing} alignItems="center">
          {searchFields.map((Item, i) => {
            const Child = Array.isArray(Item) ? Item[1] : Item;
            const childProps = Array.isArray(Item)
              ? Item[0]
              : {
                  md: Math.ceil(12 / searchFields.length) as GridSize,
                };
            return (
              <Grid item key={i} xs={12} {...childProps}>
                {Child}
              </Grid>
            );
          })}
        </Grid>
      </Section>
    </PageOverlay>
  );
}
