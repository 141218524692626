import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useCallback, useMemo } from 'react';
import { AptlyProduct } from '@aptly-as/types';
import { createModal } from '../../../containers/Modal/ModalContext';
import useScopeLevel from '../../../libraries/scope/useScopeLevel';
import { rawSpacing } from '../../../utils/spacing';
import Typography from '../../../mui/Typography';
import createMediaURL from '../../../models/Media/createMediaURL';
import extractFeaturedImage from '../../../models/Product/extractFeaturedImage';
import i18n from '../../../libraries/i18n';
import { ProductSearch } from '../../../models/Product/ProductSearch';
import type { IProductSchema } from '../../../models/Product/product.schema';
import { AddButtonWithIcon } from '../../actions/buttons/Buttons';
import { DeleteIconButton } from '../../actions/icons/Icons';
import { ICustomRenderComponentProps } from '../utils/crud.utils';
import Avatar from '../../Avatar';

export function CustomProductProducts({
  field,
  crud,
}: ICustomRenderComponentProps<IProductSchema, 'products'>) {
  const _id = crud.state.data._id;
  const level = useScopeLevel(crud.state.data);

  const products = useMemo(() => (field.value as AptlyProduct[]) || [], [field.value]);

  const handleOnSave = useCallback(
    (newProducts: AptlyProduct[]) => {
      crud.setField(field.field)([...products, ...newProducts]);
    },
    [crud, products]
  );

  const spawnProductsModal = useCallback(() => {
    createModal(<ProductSearch products={[_id, ...products]} onSave={handleOnSave} level={level} />, {
      width: 'xl',
    });
  }, [products, _id, handleOnSave, level]);

  const handleOnDelete = useCallback(
    (product: AptlyProduct) => () => {
      crud.setField(field.field)(products.filter((x) => x._id !== product._id));
    },
    [crud, products]
  );

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" spacing={rawSpacing}>
        <Grid item>
          <Typography variant="h1">{i18n.t('singles.products')}</Typography>
        </Grid>
        {field.disabled ? null : (
          <Grid item>
            <AddButtonWithIcon onClick={spawnProductsModal} />
          </Grid>
        )}
      </Grid>
      <List dense>
        {products.map((product) => {
          const featuredImage = extractFeaturedImage(product.images || []);
          const image = featuredImage ? createMediaURL(featuredImage.image, { width: 80 }) : undefined;
          return (
            <ListItem
              disablePadding
              key={product._id}
              secondaryAction={<DeleteIconButton onClick={handleOnDelete(product)} />}
            >
              <ListItemAvatar>
                <Avatar
                  alt={featuredImage?.description || 'product.name'}
                  src={image}
                  imgProps={{ loading: 'lazy' }}
                  variant="rounded"
                />
              </ListItemAvatar>
              <ListItemText primary={product.name} secondary={product.productNumber} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
