import { AptlyPaymentStatus } from '@aptly-as/types';
import i18n from '../../libraries/i18n.js';
import { SelectOption } from '../../mui/Input.js';

export const paymentStatusOptions = (): SelectOption[] => [
  { value: AptlyPaymentStatus.Authorized, label: i18n.t('statuses.reserved') },
  { value: AptlyPaymentStatus.PendingDelivery, label: i18n.t('statuses.delivery') },
  { value: AptlyPaymentStatus.PendingCapture, label: i18n.t('actions.capture') },
  { value: AptlyPaymentStatus.Captured, label: i18n.t('statuses.paid') },
];

export function paymentStatusLabel(status: AptlyPaymentStatus) {
  const option = paymentStatusOptions().find((x) => x.value === status);
  return option ? option.label : status;
}
