import { API_URL, CLOUDINARY_KEY } from '../../env';

export type IMediaTransformations = {
  width?: number;
  height?: number;
  crop?: 'fill' | 'fit' | 'pad';
};

const validKeys = ['width', 'height', 'crop'];

function createMediaURL(id: string, transformations?: IMediaTransformations): string {
  if (!id) return '';
  if (id.startsWith('http')) return id;
  if (CLOUDINARY_KEY) return createCloudinaryUrl(id, transformations);

  const stringedTransformations = [];

  if (transformations) {
    for (const k in transformations) {
      if (!validKeys.includes(k)) continue;
      // @ts-ignore
      stringedTransformations.push(`${k}_${transformations[k]}`);
    }
  }

  return `${API_URL}/media/${encodeURIComponent(id)}/${stringedTransformations.join(',')}`;
}

function createCloudinaryUrl(id: string, transformations?: IMediaTransformations) {
  const stringedTransformations = ['f_auto'];

  if (transformations) {
    for (const k in transformations) {
      if (!validKeys.includes(k)) continue;
      // @ts-ignore
      stringedTransformations.push(`${k[0]}_${transformations[k]}`);
    }
  }

  return `https://res.cloudinary.com/${CLOUDINARY_KEY}/image/upload/${stringedTransformations.join(
    ','
  )}/${id}`;
}

export default createMediaURL;
