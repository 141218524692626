import { AptlyOrder, AptlyUnit } from '@aptly-as/types';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchExportButton, SearchStartJobButton } from '../../components/actions/buttons/ExportButton';
import ReferenceInput from '../../components/ReferenceInput';
import { CrudContactNotifyButton } from '../../components/simpleCruds/settings/CrudContactNotifyAdd';
import SearchViewComposer, { SearchField } from '../../deprecated/Search/SearchViewComposer';
import { SlugLevel, useApiUrl, useSlugs } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { TextField } from '../../mui/Input';
import Select from '../../mui/Select';
import { orderStatusOptions } from './order.utils';
import OrdersTableRow, { OrdersTableHead } from './OrdersTable';

/**
 * @deprecated. Not sure if this should be used.
 * Definitely needs some refactoring from using the SearchViewComposer.
 */
function OrdersV1() {
  const location = useLocation();
  const [organizationSlug, projectID] = useSlugs();
  const search = new URLSearchParams(location.search);
  const unit = search.get('unit');
  const status = search.get('status');
  const query = React.useMemo(
    () => ({
      ...(unit ? { unit } : {}),
      ...(status ? { status } : {}),
      archived: 'false',
    }),
    [unit, status]
  );

  const v1Endpoint = useApiUrl(SlugLevel.Project, 'orders');
  const baseEndpoint = `organizations/${organizationSlug}/projects/${projectID}`;
  const ordersEndpoint = `${baseEndpoint}/orders/search`;

  const searchFields = useMemo(
    (): SearchField[] => [
      {
        name: 'orderNumber',
        element: <TextField label={i18n.t('singles.orderNumber')} fullWidth />,
        autoFocus: true,
      },
      {
        type: 'reference',
        name: 'unit',
        element: (
          <ReferenceInput<any>
            label={i18n.t('singles.unit')}
            endpoint={`${baseEndpoint}/units/search`}
            labelKey="name"
            queryKey="name"
            singular
          />
        ),
      },
      {
        type: 'select',
        name: 'status',
        element: (
          <Select
            defaultValue={status}
            label={i18n.t('singles.status')}
            fullWidth
            options={orderStatusOptions()}
          />
        ),
      },
    ],
    [baseEndpoint, status]
  );

  const additionalActions = useCallback(
    () => [
      <CrudContactNotifyButton key="notify" defaultExpandGroup="order" />,
      <SearchExportButton
        key="export"
        endpoint={`${v1Endpoint}/per-unit-export`}
        label={i18n.t('singles.perUnit') + '.csv'}
      />,
      <SearchStartJobButton
        key="export"
        endpoint={`${ordersEndpoint}/zip`}
        jobLabel={`${i18n.t('singles.orders')}.zip`}
        label={i18n.t('singles.orders') + '.zip'}
      />,
    ],
    [v1Endpoint, ordersEndpoint]
  );

  return (
    <SearchViewComposer
      query={query}
      title={i18n.t('singles.order')}
      endpoint={ordersEndpoint}
      searchFields={searchFields}
      additionalActions={additionalActions}
      tableHead={<OrdersTableHead />}
      emptyStateIcon={<ShoppingCartOutlinedIcon />}
      initialQuery
      searching
    >
      {(orders: AptlyOrder[], _2, _3, _4, _5, refresh) =>
        orders.map((o) => (
          <OrdersTableRow key={o._id} order={o} unit={o.unit as AptlyUnit} refresh={refresh} showDelete />
        ))
      }
    </SearchViewComposer>
  );
}
export default OrdersV1;
