import Accordion from '../../../components/Accordion';
import Products, { ProductProps } from './Products';
import { Section } from '../../UnitTemplate/unit-template.types';
import i18n from '../../../libraries/i18n';

export interface SectionsProps extends Omit<ProductProps, 'section'> {
  sections: Section[];
}

function Sections({ sections, ...props }: SectionsProps) {
  return (
    <>
      {sections.map((section) => {
        const isStandard = section.standard ? i18n.t('singles.standard') : i18n.t('singles.notStandard');
        const isMultiSelect = section.multiSelect
          ? i18n.t('singles.multipleChoice')
          : i18n.t('singles.singleChoice');

        return (
          <Accordion
            key={section._id}
            title={section.name}
            subtitle={`${section.period.name} - (${isStandard}, ${isMultiSelect})`}
          >
            {() => <Products {...props} section={section} />}
          </Accordion>
        );
      })}
    </>
  );
}

export default Sections;
