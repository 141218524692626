import { GridProps } from '@mui/material/Grid';
import { ListProps } from '@mui/material/List';
import { PropsWithChildren } from 'react';
import Grid from '../../mui/Grid';
import List, { ListItem, ListItemProps } from '../../components/List/List';
import Scope from './Scope';
import { ScopeProps } from './scope.utils';

export function FragmentScope({ scope, crud = 'R', every, children }: PropsWithChildren<ScopeProps>) {
  return Scope.crud(scope, crud, every) ? <>{children}</> : null;
}

export function ListScope({ scope, crud, children, ...props }: ScopeProps & ListProps) {
  return Scope.crud(scope, crud) ? <List {...props}>{children}</List> : null;
}

export function ListItemScope({ scope, crud = 'R', every, children, ...props }: ListItemProps & ScopeProps) {
  return Scope.crud(scope, crud, every) ? <ListItem {...props}>{children}</ListItem> : null;
}

export function LayoutScope({ scope, crud, ...props }: GridProps & ScopeProps) {
  return Scope.crud(scope, crud) ? <Grid {...props} /> : null;
}
