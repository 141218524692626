import { Fragment, ReactElement } from 'react';
import styled from 'styled-components';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { gutter } from '../utils/spacing';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BreadcrumbsItem = styled.div`
  &,
  a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const Divider = styled.div`
  margin: 0 calc(${gutter} / 4);
  display: flex;
  align-items: center;

  svg {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

type BreadcrumbItemType = ReactElement<typeof BreadcrumbsItem>;

type Props = {
  children: BreadcrumbItemType | BreadcrumbItemType[];
};

export default function Breadcrumbs(props: Props) {
  let { children } = props;

  if (!Array.isArray(children)) {
    children = [children];
  }

  return (
    <Wrapper>
      {children.map((child, index) => (
        <Fragment key={index}>
          {child}
          {index + 1 < children.length && (
            <Divider>
              <ChevronRight />
            </Divider>
          )}
        </Fragment>
      ))}
    </Wrapper>
  );
}

export { BreadcrumbsItem };
