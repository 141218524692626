import i18n from '../../libraries/i18n';

type SectionDisplayType = {
  value: string;
  label: string;
};

function getSectionDisplayTypes(): SectionDisplayType[] {
  return [
    {
      value: 'default',
      label: i18n.t('singles.standard'),
    },
    {
      value: 'compact',
      label: i18n.t('singles.compact'),
    },
  ];
}

export default getSectionDisplayTypes;
