import { AptlyProjectAlgorithm, AptlyScopes } from '@aptly-as/types';
import i18n from '../../../../libraries/i18n';
import { AccordionDetails } from '../../../../mui/Accordion';
import Grid from '../../../../mui/Grid';
import { onWheelCaptureBlur } from '../../../../utils/input';
import { minSpacing } from '../../../../utils/spacing';
import Accordion from '../../../Accordion';
import { TextField } from '../../../../mui/Input';
import { LayoutScope } from '../../../../libraries/scope/ScopeComponets';
import ReferenceInput from '../../../ReferenceInput';
import { IOptionLabel } from '../../../../models/OptionLabel/option-label.types';
import { useSlugs } from '../../../../hooks/useGetApiUrl';
import CrudProductAlgorithm from './CrudProductAlgorithm';
import React from 'react';

interface Props {
  unitCost: number;
  variants: any[];
  variantPrices: any[];
  onChange: (variantPrices: any[]) => void;
  showLabels?: boolean;
  algorithm?: AptlyProjectAlgorithm;
}

export default function ProductVariantPrices({
  variants,
  variantPrices,
  unitCost,
  onChange,
  showLabels,
  algorithm,
}: Props) {
  const [organizationSlug, projectID] = useSlugs();
  const handleChange = React.useCallback(
    (variant: any, key: 'price' | 'labels', value: any, price: number) => {
      const dataval = Array.isArray(variantPrices) ? [...variantPrices] : [];
      const datavalIndex = dataval.findIndex((d) => d.variant === variant._id);

      const userData = {
        ...(key === 'price' ? { labels: [] } : { price }),
        ...(datavalIndex > -1 ? dataval[datavalIndex] : {}),
        variant: variant._id,
        [key]: value,
      };

      if (datavalIndex !== -1) {
        dataval[datavalIndex] = userData;
      } else {
        dataval.push(userData);
      }

      onChange(dataval);
    },
    [onChange, variantPrices]
  );

  if (variants.length === 0) return null;
  const endpoint = `organizations/${organizationSlug}/projects/${projectID}/option-labels/search`;

  return (
    <Accordion title={i18n.t('singles.variants')}>
      {() => (
        <AccordionDetails>
          <Grid container direction="row" spacing={minSpacing}>
            {variants.map((variant) => {
              const elem = Array.isArray(variantPrices)
                ? variantPrices.find((d) => d.variant === variant._id)
                : null;
              const variantCost = elem?.price ?? unitCost;

              return (
                <Grid key={variant._id} item sm={12}>
                  <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                    <Grid item style={{ flex: 1 }}>
                      {variant.name}
                    </Grid>
                    {showLabels && (
                      <LayoutScope scope={AptlyScopes.OrganizationOptionLabels} item sm={4}>
                        <ReferenceInput<any>
                          endpoint={endpoint}
                          labelKey="name"
                          queryKey="name"
                          label={i18n.t('singles.labels')}
                          onSelect={(labels) => handleChange(variant, 'labels', labels, variantCost)}
                          defaultSelected={
                            elem?.labels?.map((x: IOptionLabel) => ({
                              _id: x._id,
                              label: x.name,
                            })) || []
                          }
                        />
                      </LayoutScope>
                    )}
                    <Grid item sm={3}>
                      <TextField
                        type="number"
                        label={i18n.t('singles.price')}
                        fullWidth
                        value={variantCost}
                        onChange={(e) => handleChange(variant, 'price', e.target.value, variantCost)}
                        onWheelCapture={onWheelCaptureBlur}
                      />
                    </Grid>
                  </Grid>
                  {elem?.price && algorithm && (
                    <Grid container justifyContent="flex-end">
                      <CrudProductAlgorithm baseCost={parseFloat(String(elem.price))} algorithm={algorithm} />
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      )}
    </Accordion>
  );
}
