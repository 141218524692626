import { AptlyEmail, AptlyEmailType, AptlyFieldType } from '@aptly-as/types';
import { schemaName } from '../../components/crud/schema/fields.schema';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import i18n from '../../libraries/i18n';

export const emailTypeOptions = () => [
  { value: AptlyEmailType.Invite, label: i18n.t('singles.invitation') },
  { value: AptlyEmailType.NewOwner, label: i18n.t('statuses.newOwner') },
  { value: AptlyEmailType.OrderSign, label: `${i18n.t('singles.order')} - ${i18n.t('singles.sign')}` },
  { value: AptlyEmailType.OrderSigned, label: `${i18n.t('singles.order')} - ${i18n.t('statuses.signed')}` },
  {
    value: AptlyEmailType.OrderSignedManually,
    label: `${i18n.t('singles.order')} - ${i18n.t('statuses.signedManually')}`,
  },
  {
    value: AptlyEmailType.OrderCompleted,
    label: `${i18n.t('singles.order')} - ${i18n.t('statuses.completed')}`,
  },
  { value: AptlyEmailType.OrderPay, label: `${i18n.t('singles.order')} - ${i18n.t('singles.pay')}` },
  {
    value: AptlyEmailType.OrderPayDelivery,
    label: `${i18n.t('singles.order')} - ${i18n.t('actions.delivery')}`,
  },
  {
    value: AptlyEmailType.OrderPayDeliver,
    label: `${i18n.t('singles.order')} - ${i18n.t('statuses.delivery')}`,
  },
  {
    value: AptlyEmailType.OrderPayed,
    label: `${i18n.t('singles.order')} - ${i18n.t('singles.confirmation')}`,
  },
  { value: AptlyEmailType.OrderReceipt, label: `${i18n.t('singles.order')} - ${i18n.t('singles.receipt')}` },
];
export const emailTypeLabel = (type: AptlyEmailType) => {
  const found = emailTypeOptions().find((option) => option.value === type);
  return found ? found.label : '';
};

export const emailSchema = (): ICrudSchema<AptlyEmail> => ({
  name: schemaName({ autoFocus: true }),
  type: {
    type: AptlyFieldType.Select,
    defaultValue: AptlyEmailType.Invite,
    label: i18n.t('singles.type'),
    options: emailTypeOptions(),
  },
  subject: {
    type: AptlyFieldType.Text,
    label: i18n.t('statuses.emailSubject'),
    defaultValue: '',
  },
  content: {
    type: AptlyFieldType.Markdown,
    label: i18n.t('statuses.emailText'),
    defaultValue: '',
  },
  actionLabel: {
    type: AptlyFieldType.Text,
    label: i18n.t('actions.confirmLabel'),
    defaultValue: '',
  },
});
