import styled, { css } from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { PositionParent } from '../components/Position';

const Progresser = CircularProgress;

const LinearProgresser = styled(({ offset, ...rest }: any) => <LinearProgress {...rest} />)<{
  offset?: boolean;
  variant?: string;
  value?: number;
}>`
  ${(props) =>
    props.offset &&
    css`
      position: absolute !important;
      left: 0;
      top: 0;
      right: 0;
    `};
`;

export function HeaderProgresser() {
  return (
    <PositionParent>
      <LinearProgresser offset />
    </PositionParent>
  );
}

export default Progresser;
export { LinearProgresser };
