import { AptlyProject, AptlyScopes } from '@aptly-as/types';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { boligmappaIcon } from '../../components/svg/icons';
import ProjectIntegrationCheckD from './projectIntegrations/ProjectIntegrationCheckD';
import ProjectIntegrationBoligmappa from './projectIntegrations/ProjectIntegrationBoligmappa';
import { ISidebarListItem } from '../../containers/Sidebar/sidebar.utils';

function getIntegrations(project?: AptlyProject) {
  const checked = (project?.integrations || []).find((x) => x.integration === 'checkd');
  const boligmappa = (project?.integrations || []).find((x) => x.integration === 'boligmappa');
  return { checked, boligmappa };
}

export const toProjectIntegrationItems = (projectData: AptlyProject): ISidebarListItem[] => {
  const { checked, boligmappa } = getIntegrations(projectData);
  const items: ISidebarListItem[] = [];
  if (checked) {
    items.push({
      scope: AptlyScopes.ProjectDocuments,
      label: 'CheckD',
      path: '/checkd',
      icon: <FileCopyOutlinedIcon />,
      element: <ProjectIntegrationCheckD />,
      nested: true,
    });
  }
  if (boligmappa) {
    items.push({
      scope: AptlyScopes.ProjectDocuments,
      label: 'Boligmappa',
      path: '/boligmappa',
      icon: boligmappaIcon,
      element: <ProjectIntegrationBoligmappa />,
      nested: true,
    });
  }
  return items;
};
