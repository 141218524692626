import { DropzoneOptions } from 'react-dropzone';
import { css } from 'styled-components';
import { gutter } from '../../utils/spacing';
import { short } from '../../utils/animations';
import { lightGray } from '../../utils/themes';

export interface DropZoneProps<T extends object = File> {
  label?: string;
  multiple?: boolean;
  accept?: string;
  onDrop?: (files: T[]) => void;
  disabled?: boolean;
}

export interface DropZoneCssProps<T extends object = File> extends DropZoneProps<T> {
  width?: string;
  height?: string;
  profile?: boolean;
  onDrop?: any;
  multiple?: any;
}

export const dropZoneCss = css<DropZoneCssProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid ${lightGray};
  padding: ${gutter};
  border-radius: 2px;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  min-height: ${(props) => (props.height ? props.height : '16rem')};
  box-sizing: border-box;
  cursor: pointer;
  transition: all ${short} ease-in-out;
`;

export const acceptImages: DropzoneOptions['accept'] = {
  'image/*': [],
};
export const acceptCsv: DropzoneOptions['accept'] = {
  'application/csv': ['.csv'],
};
export const acceptJson: DropzoneOptions['accept'] = { 'application/json': ['.json'] };
export const acceptPdf: DropzoneOptions['accept'] = { 'application/pdf': ['.pdf'] };
