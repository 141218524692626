import { AptlyDocument, AptlyUnit } from '@aptly-as/types';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { AddIconButton } from '../../components/actions/icons/Icons';
import { createModal } from '../../containers/Modal/ModalContext';
import i18n from '../../libraries/i18n';
import { IUnitIntegration, SelectedOption } from '../../models/Unit/unit.types';
import { addOrEditBoligmappaNumber } from '../../models/Unit/unitBoligmappa/unitBoligmappa.utils';
import { GridColDef } from './DataGrid';
import { ModalContent } from '../Dialog';
import Grid from '../Grid';
import Typography from '@mui/material/Typography';
import { IconButton } from '../Button';

export const dataGridUnitBoligmappaNumberCol = (override: Partial<GridColDef> = {}): GridColDef => ({
  type: 'string',
  headerName: i18n.t('singles.boligmappaNumber'),
  field: 'boligmappaNumber',
  width: 200,
  renderCell: (params) => {
    const unit = params.row as AptlyUnit & { id: string };
    if (unit.boligmappaNumber) return <>{unit.boligmappaNumber}</>;
    return (
      <AddIconButton
        onClick={addOrEditBoligmappaNumber(unit, (unit) => {
          params.api.updateRows([{ id: unit._id, ...unit }]);
        })}
      />
    );
  },
  ...override,
});

export const dataGridUnitIntegrationPlantIdCol = (override: Partial<GridColDef> = {}): GridColDef => ({
  type: 'string',
  headerName: i18n.t('singles.plantId'),
  field: 'integrations',
  valueGetter: (_, row) => {
    const integrations = row.integrations as IUnitIntegration[];
    if (!integrations) return '';
    const integration = integrations.find((x) => x.integration === 'boligmappa');
    return integration?.plantId || '';
  },
  ...override,
});

const totalDocumentsUploaded = (unit: AptlyUnit, documents: AptlyDocument[]) => {
  const uploaded = documents.filter((x) => x.boligmappaStatus?.some((y) => y.unit === unit._id && y.id));
  return (
    <>
      {uploaded.length} / {documents.length}
    </>
  );
};

export const dataGridUnitIntegrationBoligmappaDocumentUploaded = (
  override: Partial<GridColDef> = {}
): GridColDef => ({
  align: 'right',
  field: 'documents-uploaded',
  headerName: i18n.t('statuses.uploaded'),
  width: 135,
  renderCell: (params) => {
    const unit = params.row as AptlyUnit & { id: string };
    if (!unit.documents || typeof unit.documents[0] === 'string') return <></>;
    return totalDocumentsUploaded(unit, unit.documents as AptlyDocument[]);
  },
  ...override,
});

const toItemProductDocuments = (arr: AptlyDocument[], item: SelectedOption) => {
  if (item.product && typeof item.product === 'object') {
    arr.push(...(item.product.documents as AptlyDocument[]));
  }
  return arr;
};
export const dataGridUnitIntegrationBoligmappaItemsDocumentsUploaded = (
  override: Partial<GridColDef> = {}
): GridColDef => ({
  align: 'right',
  field: 'items-documents-uploaded',
  headerName: `${i18n.t('singles.products')} ${i18n.t('statuses.uploaded')}`,
  width: 150,
  renderCell: (params) => {
    const unit = params.row as AptlyUnit & { id: string };
    const documents = unit.items?.reduce(toItemProductDocuments, []) || [];
    if (!unit.documents || typeof unit.documents[0] === 'string') return <></>;
    return totalDocumentsUploaded(unit as any, documents);
  },
  ...override,
});

const totalDocumentsError = (unit: AptlyUnit, documents: AptlyDocument[]) => {
  const documentErrors = documents.filter((x) =>
    x.boligmappaStatus?.some((y) => y.unit === unit._id && y.errorCode)
  );

  return documentErrors.length > 0 ? (
    <IconButton
      onClick={() => {
        createModal(
          <ModalContent>
            <Grid container direction="column">
              <Typography variant="subtitle1">{i18n.t('singles.errorMessages')}</Typography>
              {documentErrors.map((doc, i) => {
                const status = doc.boligmappaStatus?.find((x) => x.unit === unit._id);
                return status ? (
                  <Typography variant="subtitle2" key={i}>
                    {status.errorCode}: {doc.name}
                  </Typography>
                ) : null;
              })}
            </Grid>
          </ModalContent>
        );
      }}
      size="large"
    >
      <VisibilityOutlinedIcon />
    </IconButton>
  ) : (
    <></>
  );
};

export const dataGridUnitIntegrationBoligmappaDocumentErrors = (
  override: Partial<GridColDef> = {}
): GridColDef => ({
  field: 'documents-errors',
  headerName: i18n.t('singles.errorMessages'),
  width: 135,
  renderCell: (params) => {
    const unit = params.row as AptlyUnit & { id: string };
    if (!unit.documents || typeof unit.documents[0] === 'string') return <></>;
    return totalDocumentsError(unit, unit.documents as AptlyDocument[]);
  },
  ...override,
});

export const dataGridUnitIntegrationBoligmappaItemsDocumentsErrors = (
  override: Partial<GridColDef> = {}
): GridColDef => ({
  field: 'items-documents-errors',
  headerName: `${i18n.t('singles.products')} ${i18n.t('singles.errorMessages')}`,
  width: 150,
  renderCell: (params) => {
    const unit = params.row as AptlyUnit & { id: string };
    const documents = unit.items?.reduce(toItemProductDocuments, []) || [];
    if (!unit.documents || typeof unit.documents[0] === 'string') return <></>;
    return totalDocumentsError(unit, documents);
  },
  ...override,
});
