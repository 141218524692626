import styled from 'styled-components';

export interface CSSGridProps {
  gridTemplateColumns?: string;
}

export const CSSGrid = styled.div<CSSGridProps>`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: ${(props) => props.gridTemplateColumns || 'repeat(12, 1fr)'};
`;

export interface CSSGridItemProps {
  gridColumn?: string;
  gridRow?: string;
  alignSelf?: string;
}
export const CSSGridItem = styled.div<CSSGridItemProps>`
  grid-column: ${(props) => props.gridColumn || 'span 12'};
  ${(props) => (props.gridRow ? `grid-row: ${props.gridRow};` : '')};
  ${(props) => (props.alignSelf ? `align-self: ${props.alignSelf};` : '')};
`;
