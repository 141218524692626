import Grid from '@mui/material/Grid';
import { ShowMeMoreIconButton } from './Icons';

interface ILoadMoreButtonProps {
  next: () => void;
  disabled: boolean;
}

export function LoadMoreButton({ next, disabled }: ILoadMoreButtonProps) {
  return (
    <Grid container justifyContent="center">
      <ShowMeMoreIconButton onClick={next} disabled={disabled} />
    </Grid>
  );
}
