import { Snackbar } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import { SnackbarCloseReason, SnackbarOrigin } from '@mui/material/Snackbar/Snackbar';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AptlyJob } from '@aptly-as/types';

import { DownloadHrefIconButton } from '../../components/actions/icons/ExportIcon';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { reach } from '../../libraries/reach/reach';
import { errorNotification } from '../Notification/notification.utils';
import { IJobItem } from './job.utils';

interface JobProps {
  initialJob: IJobItem;
  index: number;
  onRemove: (index: number) => void;
}

export function Job({ initialJob, index, onRemove }: JobProps) {
  const [job, setJob] = useState<AptlyJob>(initialJob);
  const progress = useRef(initialJob.progress);
  const handleOnRemove = useCallback(() => onRemove(index), [onRemove, index]);
  const orgEndpoint = useApiUrl(SlugLevel.Base, `organizations/${initialJob.organization}`);
  const style = useMemo(
    () => ({ transform: `translateY(-${index * 60}px)`, transition: 'transform 250ms ease-in-out' }),
    [index]
  );

  useEffect(() => {
    let interval = 0;
    let timeout = 250;
    const addToTimeout = 250;
    async function getJob() {
      try {
        const job = await reach.api<AptlyJob>(`${orgEndpoint}/jobs/${initialJob.queue}/${initialJob.id}`);
        if (job.progress === 100 || job.returnvalue) {
          clearInterval(interval);
          if (typeof initialJob.onComplete === 'function') {
            initialJob.onComplete(job.returnvalue);
          }
          setJob(job);
        } else if (job.progress > progress.current) {
          timeout = 250;
          setJob(job);
        } else {
          throw new Error('Wait');
        }
        progress.current = job.progress;
      } catch (e: any) {
        if (timeout > 10000) {
          errorNotification(e.message);
          clearInterval(interval);
          handleOnRemove();
          return;
        }
        clearInterval(interval);
        timeout += addToTimeout;
        interval = setInterval(getJob, timeout);
      }
    }
    interval = setInterval(getJob, timeout);
    return () => clearInterval(interval);
  }, [orgEndpoint, initialJob, handleOnRemove]);

  const action = useMemo(() => {
    if (job.returnvalue) {
      const href = getActionFromReturnValue(job, orgEndpoint);
      if (typeof initialJob.action === 'function') {
        return initialJob.action({
          job,
          onClose: handleOnRemove,
        });
      }
      if (href) {
        return <DownloadHrefIconButton href={href} onClick={handleOnRemove} />;
      }
      return null;
    }
    return (
      <CircularProgress variant={job.progress === 0 ? 'indeterminate' : 'determinate'} value={job.progress} />
    );
  }, [initialJob, job, orgEndpoint, handleOnRemove]);

  const message = useMemo(() => {
    const status = getJobStatus(initialJob, job);
    return initialJob.label + (status ? ` (${status})` : '');
  }, [initialJob, job]);

  const handleOnClose = useCallback(
    (_e: unknown, reason: SnackbarCloseReason) => {
      if (reason === 'timeout') {
        handleOnRemove();
      }
    },
    [handleOnRemove]
  );

  const autoHideDuration = job.progress === 100 && !action ? 5000 : 30000;
  return (
    <Snackbar
      style={style}
      open
      message={message}
      onClose={handleOnClose}
      autoHideDuration={autoHideDuration}
      action={action}
      anchorOrigin={anchorOrigin}
      TransitionComponent={Slide}
    />
  );
}

function getJobStatus(initialJob: IJobItem, job: AptlyJob) {
  if (job.progress === 100) {
    return i18n.t('statuses.completed');
  }

  if (job.progress > 0 && initialJob.progressSteps) {
    const step = initialJob.progressSteps.find((step) => step.progress <= job.progress);
    if (step) return step.label;
  }

  return '';
}

function getActionFromReturnValue(job: AptlyJob, orgEndpoint: string) {
  if (job.returnvalue && typeof job.returnvalue === 'object') {
    if ('document' in job.returnvalue && job.returnvalue.document) {
      return `${orgEndpoint}/documents/${String(job.returnvalue.document)}/download`;
    }
  }
  return '';
}

const anchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};
