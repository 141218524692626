import { AptlyAlgorithm, AptlyAlgorithmPipelineOperation } from '@aptly-as/types';
import Decimal from 'decimal.js';
import i18n from '../../libraries/i18n';
import { intlCurrencyDecimal } from '../../libraries/intl';

interface AlgorithmStep {
  label: string;
  price: number;
}

export function getAlgorithmFinalCost(algorithm?: AptlyAlgorithm | string, baseCost = 0, reverse?: boolean) {
  if (!algorithm || typeof algorithm === 'string') return baseCost;
  const steps = getAlgorithmSteps(algorithm, baseCost, reverse);
  const cost = steps.length > 0 ? steps[steps.length - 1].price : baseCost;
  return reverse ? new Decimal(cost).toDP(2).toNumber() : Math.ceil(cost);
}

export function getAlgorithmVisual(algorithm: AptlyAlgorithm, baseCost: number) {
  const steps = getAlgorithmSteps(algorithm, baseCost);
  return steps
    .map((x) => {
      return `${intlCurrencyDecimal(x.price)} (${x.label})`;
    })
    .join(' > ');
}

export function getAlgorithmSteps(algorithm?: AptlyAlgorithm, baseCost: number = 0, reverse?: boolean) {
  if (!algorithm) {
    return getAlgorithmSteps(
      {
        _id: '',
        reversed: true,
        vat: 25,
        fee: 0,
        name: '25% MVA',
        pipeline: [],
      },
      baseCost
    );
  }
  const bc = new Decimal(baseCost || 0);
  const reversed = reverse ? !algorithm.reversed : algorithm.reversed;
  const feePercent = algorithm.fee ? new Decimal(algorithm.fee).dividedBy(100).plus(1).toDP(2) : null;
  const vatPercent = algorithm.vat ? new Decimal(algorithm.vat).dividedBy(100).plus(1).toDP(2) : null;
  let price = new Decimal(baseCost || 0);
  const steps: AlgorithmStep[] = [
    { label: reversed ? i18n.t('singles.grossCost') : i18n.t('singles.netCost'), price: baseCost },
  ];

  if (reversed) {
    if (vatPercent) {
      price = percent(price, vatPercent, true);
      steps.push({
        label: i18n.t('singles.vat'),
        price: price.toNumber(),
      });
    }
    if (feePercent) {
      price = percent(price, feePercent, true);
      steps.push({
        label: i18n.t('singles.fee'),
        price: price.toNumber(),
      });
    }
  }

  if (algorithm.pipeline) {
    for (let i = 0; i < algorithm.pipeline.length; i++) {
      const pipe = algorithm.pipeline[!reversed ? i : algorithm.pipeline.length - 1 - i];

      switch (pipe.operation) {
        case AptlyAlgorithmPipelineOperation.Add:
          price = !reversed ? add(price, pipe) : subtract(price, pipe);
          break;
        case AptlyAlgorithmPipelineOperation.Subtract:
          price = !reversed ? subtract(price, pipe) : add(price, pipe);
          break;
        case AptlyAlgorithmPipelineOperation.Multiply:
          price = !reversed ? multiply(price, pipe, bc) : divide(price, pipe, bc);
          break;
        default:
          continue;
      }

      steps.push({
        label: pipe.label,
        price: price.toNumber(),
      });
    }
  }

  if (!reversed) {
    if (feePercent) {
      price = percent(price, feePercent);
      steps.push({
        label: i18n.t('singles.fee'),
        price: price.toNumber(),
      });
    }
    if (vatPercent) {
      price = percent(price, vatPercent);
      steps.push({
        label: i18n.t('singles.vat'),
        price: price.toNumber(),
      });
    }
  }

  if (reversed) {
    steps.reverse();
  }

  return steps;
}

function add(price: Decimal, pipe: AptlyAlgorithm['pipeline'][0]) {
  return price.plus(pipe.value || 0);
}

function subtract(price: Decimal, pipe: AptlyAlgorithm['pipeline'][0]) {
  return price.minus(pipe.value || 0);
}

function multiply(price: Decimal, pipe: AptlyAlgorithm['pipeline'][0], bc: Decimal) {
  if (pipe.applyTo === 'base') {
    return price.plus(bc.mul(pipe.value || 1).minus(bc)).toDP(2);
  } else {
    return price.mul(pipe.value || 1).toDP(2);
  }
}

function percent(price: Decimal, percent: Decimal.Value, reversed = false) {
  if (reversed) {
    return price.dividedBy(percent).toDP(2);
  }
  return price.mul(percent).toDP(2);
}

function divide(price: Decimal, pipe: AptlyAlgorithm['pipeline'][0], _bc: Decimal) {
  if (!pipe.value) return price;
  if (pipe.applyTo === 'base') {
    return price.dividedBy(pipe.value || 0).toDP(2);
  } else {
    return price.dividedBy(pipe.value || 0).toDP(2);
  }
}
