import {
  AptlyProduct,
  AptlyUnit,
  AptlyUnitTemplateCategory,
  AptlyUnitTemplateCategorySection,
} from '@aptly-as/types';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import SimpleCrud, { ISimpleCrudProps } from '../../../components/SimpleCrud';
import { Field } from '../../../deprecated/inputs';
import useApiRequest from '../../../hooks/useApiRequest';
import useGetApiUrl from '../../../hooks/useGetApiUrl';
import { ExtraOption, PickedItem } from '../unit.types';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../../mui/Table';
import i18n from '../../../libraries/i18n';
import { Checkbox, TextField } from '../../../mui/Input';
import Grid from '../../../mui/Grid';

interface Props extends Pick<ISimpleCrudProps<any>, 'onClose' | 'onCreate'> {
  unit: AptlyUnit;
  filterItems: string[];
}

export default function CrudReturnProduct({ unit, onClose, onCreate, filterItems }: Props) {
  const projectEndpoint = useGetApiUrl('project');
  const rootEndpoint = `${projectEndpoint}/units/${unit._id}`;
  const [name, setName] = useState(new RegExp('', 'i'));
  const [, data] = useApiRequest<PickedItem[]>(`${rootEndpoint}/confirmed-items`);
  const filtered = useMemo(
    () => (data ? data.filter((x) => !filterItems.some((y) => x.pickRef === y)) : []),
    [data, filterItems]
  );

  const renderComponent = useCallback(
    (set: any, get: any) => {
      const value = get('items').value as ExtraOption[];
      const setValue = (value: ExtraOption[]) => set('items', value, '');

      return (
        <Grid container direction="column">
          <TextField
            onChange={(e) => setName(new RegExp(e.target.value, 'i'))}
            label={i18n.t('singles.search')}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{i18n.t('singles.name')}</TableCell>
                <TableCell>{i18n.t('singles.category')}</TableCell>
                <TableCell>{i18n.t('singles.section')}</TableCell>
                <TableCell>{i18n.t('singles.price')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filtered.map((item) => {
                const checked = value.some((x) => x.pickRef === item.pickRef);
                const set = (_e: SyntheticEvent, c: boolean) =>
                  c
                    ? setValue([
                        ...value,
                        {
                          ...item,
                          quantity: -item.quantity,
                          revertItemRef: item.pickRef || item._id,
                          algorithm: undefined,
                          customDescription: 'Reversert tilvalg',
                          confirmDate: undefined,
                          addToNextOrder: undefined,
                          projectItemRef: undefined,
                        },
                      ])
                    : setValue(value.filter((x) => x.pickRef !== item.pickRef));

                const category = item.category ? (item.category as AptlyUnitTemplateCategory) : null;
                const section = item.section ? (item.section as AptlyUnitTemplateCategorySection) : null;
                const productDoc = item.product ? (item.product as AptlyProduct) : null;
                const categoryName = category ? category.name : i18n.t('singles.NA');
                const sectionName = section ? section.name : i18n.t('singles.NA');
                const product =
                  item.variant && productDoc?.variants
                    ? productDoc.variants.find((x) => x._id === item.variant) || productDoc
                    : productDoc;
                const productName = product ? product.name || '' : item.text || '';
                const isMatch =
                  categoryName.match(name) || sectionName.match(name) || productName.match(name);

                return isMatch ? (
                  <TableRow key={item.pickRef}>
                    <TableCell>
                      <Checkbox checked={checked} onChange={set} />
                    </TableCell>
                    <TableCell>{productName}</TableCell>
                    <TableCell>{categoryName}</TableCell>
                    <TableCell>{sectionName}</TableCell>
                    <TableCell>{item.unitCost}</TableCell>
                  </TableRow>
                ) : null;
              })}
            </TableBody>
          </Table>
        </Grid>
      );
    },
    [filtered, name]
  );

  const fields: Field[] = [
    {
      type: 'customComponent',
      name: 'items',
      value: [],
      label: '',
      renderComponent,
    },
  ];

  return (
    <SimpleCrud
      title={i18n.t('actions.revertOption')}
      endpoint={`${rootEndpoint}/extra-options`}
      fields={fields}
      onClose={onClose}
      onCreate={onCreate}
    />
  );
}
