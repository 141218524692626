import { useCallback } from 'react';
import { AptlyDepartment, AptlyProject, AptlyScopes } from '@aptly-as/types';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { GridColDef } from '@mui/x-data-grid';
import { EditIconButton } from '../../components/actions/icons/Icons';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  IGridActionProps,
} from '../../components/Search/search-data-grid/data-grid.actions';
import { useDataGridEditModal } from '../../components/Search/search-data-grid/data-grid.hooks';
import { ICreateModelOptions } from '../../containers/Modal/modal.types';
import Dialog, { ModalActions } from '../../mui/Dialog';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import Search from '../../components/Search/Search';
import i18n from '../../libraries/i18n';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { departmentProjectSchema, ICrudDepartmentProject } from './department.schema';

export default function OrganizationDepartmentProjects({ params }: IGridActionProps) {
  const { state, onShow, onChange } = useDataGridEditModal<AptlyDepartment>(params);
  const handleOnChange = useCallback(
    (projects: AptlyDepartment['projects']) => {
      onChange((data) => ({ ...(data as AptlyDepartment), projects }));
    },
    [onChange]
  );

  return (
    <>
      <EditIconButton onClick={onShow} icon={<HomeWorkIcon />} title={i18n.t('singles.projects')} />
      <Dialog open={Boolean(state.data)} maxWidth="md" onClose={onShow}>
        {state.data && <ModalContent department={state.data} onChange={handleOnChange} />}
        <ModalActions>
          <ActionButtons onClose={onShow} submitType="button" cancelLabel={i18n.t('actions.close')} />
        </ModalActions>
      </Dialog>
    </>
  );
}

interface IModalContentProps {
  department: AptlyDepartment;
  onChange: (users: AptlyDepartment['projects']) => void;
}

const modalProps: ICreateModelOptions = { width: 'sm' };

const columns = (): GridColDef<AptlyProject>[] => [
  {
    type: 'string',
    field: 'project',
    headerName: i18n.t('singles.name'),
    valueGetter: (value) => (value as AptlyProject).name,
    flex: 1,
  },
  dataGridActionProps((params) => (
    <DataGridActionWrapper>
      <DataGridDeleteAction params={params} />
    </DataGridActionWrapper>
  )),
];

function ModalContent({ department, onChange }: IModalContentProps) {
  const path = useApiUrl(SlugLevel.Organization, `departments/${department._id}/projects`);

  return (
    <Search<ICrudDepartmentProject>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.OrganizationDepartments}
      header={{ title: `${department.name} - ${i18n.t('singles.projects')}` }}
      post={{ title: i18n.t('singles.projects') }}
      patch={{ title: (data) => (data.project as AptlyProject).name || 'N/A' }}
      schema={departmentProjectSchema()}
      fields={['project']}
      options={{ disableBulk: true }}
      reach={{ limit: 999, query: { $populate: 'project:_id,name' } }}
      buttonActions={() => [<SearchCreateAction key="create" />]}
      modalProps={modalProps}
      onChange={onChange}
    >
      {() => <SearchDataGrid columns={columns()} pageSize={10} />}
    </Search>
  );
}
