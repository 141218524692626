import React from 'react';
import List, { ListItem } from '../../../components/List/List';
import { createModal } from '../../../containers/Modal/ModalContext';
import { IconButton } from '../../../mui/Button';
import SimpleCrud from '../../../components/SimpleCrud';
import Edit from '@mui/icons-material/Edit';
import Grid from '../../../mui/Grid';
import { rawSpacing } from '../../../utils/spacing';
import StatusText from '../../../components/StatusText';
import Card from '../../../mui/Card';
import {
  IIntegrationProps,
  IntegrationButton,
  integrationChange,
  integrationCreate,
  integrationFields,
} from './orgnaizationSettings.utils';
import useGetApiUrl from '../../../hooks/useGetApiUrl';

export default function CheckDIntegration({ integrations, setIntegrations }: IIntegrationProps) {
  const endpoint = useGetApiUrl('organization') + '/integrations';
  const checkd = integrations.find((x) => x.integration === 'checkd');
  const onChange = React.useMemo(
    () => integrationChange('checkd', integrations, setIntegrations),
    [integrations, setIntegrations]
  );
  const onCreate = React.useMemo(() => integrationCreate('checkd', setIntegrations), [setIntegrations]);

  return (
    <Card>
      {checkd ? (
        <List disablePadding>
          <ListItem
            divider
            secondaryAction={
              <IconButton
                onClick={() => {
                  createModal(
                    <SimpleCrud
                      endpoint={endpoint}
                      id={checkd._id}
                      fields={integrationFields('checkd', checkd)}
                      onCreate={onChange}
                      deleteAction
                      onDestroy={(organization) => {
                        setIntegrations(organization.integrations);
                      }}
                    />
                  );
                }}
                size="large"
              >
                <Edit />
              </IconButton>
            }
          >
            <Grid container spacing={rawSpacing}>
              <Grid item sm={3}>
                CheckD
              </Grid>
              <Grid item sm={9}>
                <StatusText>{checkd.organization}</StatusText>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      ) : (
        <IntegrationButton label={' CheckD'} name={'checkd'} endpoint={endpoint} onCreate={onCreate} />
      )}
    </Card>
  );
}
