import { AptlyUnit } from '@aptly-as/types';
import { MouseEvent } from 'react';
import CrudUnitEdit from '../../../components/simpleCruds/unit/CrudUnitEdit';
import { createModal } from '../../../containers/Modal/ModalContext';
import i18n from '../../../libraries/i18n';

export const addOrEditBoligmappaNumber =
  (
    unit: AptlyUnit,
    refresh: (unit: AptlyUnit) => void,
    fields: Array<keyof AptlyUnit> = ['boligmappaNumber'],
    title = i18n.t('singles.boligmappaNumber')
  ) =>
  (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    createModal(<CrudUnitEdit title={title} unit={unit} onCreate={refresh} fields={fields} />);
  };
