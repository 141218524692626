import { useCallback, useContext, useMemo } from 'react';
import { Reach, ReachContext } from '@ewb/reach-react';
import { createModal } from '../../containers/Modal/ModalContext';
import { AccordionDeleteList } from '../AccordionDeleteList';
import { IPaginatedSearchObject } from '../../libraries/reach/usePaginatedSearch';
import DeleteModal, { IDeleteModalProps } from '../../containers/Modal/DeleteModal';

export type IUseSimpleBulkDeleteFn<T extends IPaginatedSearchObject> = (data: T[]) => void;

export default function useSimpleBulkDelete<T extends IPaginatedSearchObject>(
  path: string,
  filter: (fn: (items: T) => boolean) => void,
  props: Omit<IDeleteModalProps, 'onConfirm'> = {}
): IUseSimpleBulkDeleteFn<T> {
  const service = useContext(ReachContext);
  const reach = useMemo(() => new Reach(service), [service]);

  const handleOnDelete = useCallback(
    async (ids: string[]) => {
      await reach.api(`${path}/bulk`, { method: 'DELETE', body: { ids } });
      filter((item) => !ids.includes(item._id));
    },
    [reach, path, filter]
  );

  return useCallback(
    (data: T[]) => {
      const ids = data.map((item) => item._id);
      createModal(
        <DeleteModal onConfirm={() => handleOnDelete(data.map((x) => x._id))} {...props}>
          <AccordionDeleteList ids={ids} data={data as any[]} expanded />
        </DeleteModal>
      );
    },
    [handleOnDelete, props]
  );
}
