import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Link = styled(NavLink)`
  display: block;
  text-decoration: none;
`;

const Figure = styled.figure`
  height: 2rem;
  margin: 0 0.5rem 0 0;
  display: flex;
  align-items: center;

  span {
    color: #000;
  }
`;

const Image = styled.img`
  display: block;
  height: 100%;
`;

type LogoProps = {
  gfx: string | React.ReactNode;
  link?: string;
};

function Logo(props: LogoProps) {
  const { gfx, link } = props;

  const logo = <Figure>{typeof gfx === 'string' ? <Image src={gfx} /> : gfx}</Figure>;

  return link !== undefined ? <Link to={link}>{logo}</Link> : logo;
}

export default Logo;
