import Icon from '../../mui/SvgIcon';

export const boligmappaIcon = (
  <Icon viewBox="0 0 239.997 192.292">
    <g transform="translate(-120 -143.868)">
      <path
        fill="#eb6160"
        fillRule="evenodd"
        d="M147,336.16H305.81c3,0,6.81-2.45,8.48-5.44L357.08,254c6.21-11.15,2.18-20.27-9-20.27H189.32c-3,0-6.81,2.45-8.48,5.44l-42.79,76.72c-6.21,11.15-2.18,20.27,9,20.27"
      />
      <path
        fill="#eb6160"
        fillRule="evenodd"
        d="M209.38,148.51l-81.53,64.16c-4.56,3.59-7.85,8.64-7.85,17.36v71l42.06-75.41c4.8-8.61,14.61-14.89,24.55-14.89H315l-79-62.19a21.24,21.24,0,0,0-26.58,0"
      />
    </g>
  </Icon>
);
