import Add from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { createModal } from '../containers/Modal/ModalContext';
import List, { ListItem } from './List/List';
import Paper from '../mui/Paper';
import Grid from '../mui/Grid';
import { rawSpacing, tightSpacing } from '../utils/spacing';
import StatusText from './StatusText';
import Button from '../mui/Button';
import { ModalActions, ModalContent, ModalForm, ModalTitle } from '../mui/Dialog';
import { Label, TextField } from '../mui/Input';
import Select from '../mui/Select';
import React, { FormEvent } from 'react';
import i18n from '../libraries/i18n';

export type Field = {
  key: string;
  type: 'text' | 'number' | 'textarea' | 'select' | 'select-object-type';
  label: string;
  options?: {
    value: string;
    label: string;
  }[];
};

type ExtraItemCrud = {
  key: string;
  value: string;
  Field: any;
};

export type ItemsToSelect = {
  [key: string]: ItemToSelectValue;
};

export type ItemToSelectValue = {
  label: string;
  fields: Field[];
};

type Value = {
  key: string;
  value: any;
  label?: string;
};

type Item = {
  key: string;
  values: Value[];
};

type Props = {
  label: string;
  addActionText: string;
  disableDrag?: boolean;
  valueAsLabel?: string;
  items: Item[];
  extraItemCruds?: ExtraItemCrud[];
  itemsToSelect: ItemsToSelect;
  onCommit: (items: Item[]) => any;
};

type State = {
  items: Item[];
};

class DraggableCrudList extends React.Component<Props, State> {
  state = {
    items: this.props.items || [],
  };

  setItem = (index: number, key: string, values: Value[]) => {
    const { onCommit } = this.props;
    const { items } = this.state;

    this.setState(
      {
        items: items.map((i, k) => {
          if (k === index) {
            i.key = key;
            i.values = values;
          }

          return i;
        }),
      },
      () => {
        onCommit(this.state.items);
      }
    );
  };

  render() {
    const { onCommit, label, itemsToSelect, addActionText, valueAsLabel, extraItemCruds } = this.props;
    const { items } = this.state;

    return (
      <Grid container spacing={tightSpacing} direction="column">
        <Grid item>
          <Label>{label}</Label>
        </Grid>
        <Grid item>
          <Paper>
            <List disablePadding>
              {items.map((p, k) => {
                const itemToSelect = itemsToSelect[p.key];
                let label = itemToSelect.label;
                const labelValues = p.values;

                if (valueAsLabel) {
                  const valueLabelValue = p.values.find((v) => v.key === valueAsLabel);

                  if (valueLabelValue) {
                    label = valueLabelValue.value;
                  }
                }

                return (
                  <ListItem
                    key={k}
                    icon={<EditOutlinedIcon />}
                    divider
                    button
                    onClick={() => {
                      createModal(
                        <ItemHandler
                          addActionText={addActionText}
                          itemsToSelect={itemsToSelect}
                          extraItemCruds={extraItemCruds}
                          item={p}
                          onDelete={() => {
                            this.setState(
                              {
                                items: items.filter((_p, i) => i !== k),
                              },
                              () => {
                                onCommit(this.state.items);
                              }
                            );
                          }}
                          onSave={(key, value) => this.setItem(k, key, value)}
                        />,
                        {
                          width: 'xs',
                        }
                      );
                    }}
                  >
                    <Grid container spacing={rawSpacing}>
                      <Grid item sm={5}>
                        {label}
                      </Grid>
                      <Grid item sm={7}>
                        <StatusText>
                          {valueAsLabel && itemToSelect.label}
                          {labelValues
                            .filter((v) => Boolean(v.value))
                            .filter((v) => {
                              if (valueAsLabel && v.key === valueAsLabel) {
                                return false;
                              }

                              return true;
                            })
                            .map((v, k) => {
                              const field = itemToSelect.fields.find((f) => f.key === v.key);

                              if (!field) {
                                return '';
                              }

                              const options = field.options || [];

                              if (field.type === 'select-object-type') {
                                const optionLabel = options.find((o) => o.value === v.value.type);
                                return optionLabel ? optionLabel.label : '';
                              }

                              const optionLabel = options.find((o) => o.value === v.value);

                              const spacer = valueAsLabel && k === 0 ? ', ' : '';

                              return field.type === 'select' && optionLabel
                                ? `${spacer}${optionLabel.label}`
                                : `${spacer}${v.value}`;
                            })
                            .join(', ')}
                        </StatusText>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
              <ListItem
                button
                icon={<Add />}
                onClick={() => {
                  createModal(
                    <ItemHandler
                      itemsToSelect={itemsToSelect}
                      addActionText={addActionText}
                      extraItemCruds={extraItemCruds}
                      onSave={(key, values) =>
                        this.setState(
                          {
                            items: [
                              ...items,
                              {
                                key,
                                values,
                              },
                            ],
                          },
                          () => {
                            onCommit(this.state.items);
                          }
                        )
                      }
                    />,
                    {
                      width: 'xs',
                    }
                  );
                }}
              >
                {i18n.t('actions.add')}
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

type ItemHandlerProps = {
  item?: Item;
  addActionText: string;
  itemsToSelect: ItemsToSelect;
  extraItemCruds?: ExtraItemCrud[];
  onSave: (key: string, value: any) => any;
  onDelete?: () => any;
  onClose?: () => any;
};

type ItemHandlerState = {
  key: string;
  values: Value[];
};

class ItemHandler extends React.Component<ItemHandlerProps, ItemHandlerState> {
  state = {
    key: this.props.item ? this.props.item.key : '',
    values: this.props.item ? this.props.item.values : [],
  };

  setValue = (key: string, value: any) => {
    const { values } = this.state;

    const exists = values.some((v) => v.key === key);

    if (exists) {
      this.setState({
        values: values.map((v) => {
          if (v.key === key) {
            v.value = value;
          }

          return v;
        }),
      });
    } else {
      this.setState({
        values: [
          ...values,
          {
            key,
            value,
          },
        ],
      });
    }
  };

  renderField = (field: Field, values: Value[]) => {
    const value = values.find((v) => v.key === field.key);

    switch (field.type) {
      case 'select-object-type':
        return (
          <Select
            name="value"
            label={field.label}
            required
            fullWidth
            value={value && value.value ? value.value.type : undefined}
            onChange={(e) => this.setValue(field.key, { ...value, type: e.target.value })}
            options={field.options || []}
          />
        );
      case 'select':
        return (
          <Select
            name="value"
            label={field.label}
            required
            fullWidth
            value={value ? value.value : undefined}
            onChange={(e) => this.setValue(field.key, e.target.value)}
            options={field.options || []}
          />
        );
      default:
        return (
          <TextField
            name="value"
            type={field.type}
            label={field.label}
            required
            fullWidth
            step={field.type === 'number' ? '.01' : undefined}
            multiline={field.type === 'textarea'}
            rows={4}
            value={value ? value.value : undefined}
            onChange={(e) => this.setValue(field.key, e.target.value)}
          />
        );
    }
  };

  renderExtraField = (crud: any, i: number) => {
    const { key, values } = this.state;
    const { key: crudKey, value: crudValue, Field } = crud;

    if (key !== crudKey) {
      return null;
    }

    if (
      values.some((value) => value.value && (value.value === crudValue || value.value.type === crudValue))
    ) {
      const value = values.find((v) => v.key === crudValue) || values[0];
      return (
        <Grid key={String(i)} item>
          <Field
            value={value}
            setFieldValue={(value: any) => {
              this.setValue(crudValue, value);
            }}
          />
        </Grid>
      );
    }
  };

  render() {
    const { onSave, onClose, onDelete, item, itemsToSelect, addActionText, extraItemCruds } = this.props;
    const { key, values } = this.state;

    return (
      <ModalForm
        onSubmit={(e: FormEvent) => {
          e.preventDefault();

          if (!key) {
            alert('FIXME');
          } else {
            onSave(key, values);

            if (typeof onClose === 'function') {
              onClose();
            }
          }
        }}
      >
        <ModalTitle>{item ? i18n.t('actions.edit') : addActionText}</ModalTitle>
        <ModalContent>
          <Grid container spacing={tightSpacing} direction="column">
            <Grid item>
              <Select
                autoFocus
                name="key"
                label={i18n.t('singles.type')}
                required
                fullWidth
                value={key}
                onChange={(e) =>
                  this.setState({
                    key: e.target.value,
                  })
                }
                options={Object.keys(itemsToSelect).map((p) => ({
                  label: itemsToSelect[p].label,
                  value: p,
                }))}
              />
            </Grid>
            {key !== '' &&
              itemsToSelect[key].fields.map((f) => (
                <Grid key={f.key} item>
                  {this.renderField(f, values)}
                </Grid>
              ))}
            {extraItemCruds && extraItemCruds.map((f, i) => this.renderExtraField(f, i))}
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button color="primary" onClick={onClose}>
            {i18n.t('actions.cancel')}
          </Button>
          {item && typeof onDelete === 'function' && (
            <Button
              color="primary"
              onClick={() => {
                onDelete();

                if (typeof onClose === 'function') {
                  onClose();
                }
              }}
            >
              {i18n.t('actions.delete')}
            </Button>
          )}
          <Button color="primary" type="submit">
            {item ? i18n.t('actions.save') : i18n.t('actions.add')}
          </Button>
        </ModalActions>
      </ModalForm>
    );
  }
}

export default DraggableCrudList;
