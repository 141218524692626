import { AptlyFieldType, AptlyNote, AptlyUnitTemplateCategory } from '@aptly-as/types';
import { useSearch } from '@ewb/reach-react';
import { ChangeEvent, useCallback, useMemo } from 'react';
import useGetApiUrl from '../../hooks/useGetApiUrl';
import { safeRawDraft } from '../../libraries/draft-js';
import i18n from '../../libraries/i18n';
import { ICrudSchema, ICustomRenderComponentProps } from '../../components/crud/utils/crud.utils';
import { schemaName } from '../../components/crud/schema/fields.schema';
import Select from '../../mui/Select';

export const noteSelect = '_id unit name content reminderDate author';

export const noteSchema = (): ICrudSchema<AptlyNote> => ({
  name: schemaName({
    autoFocus: true,
    label: i18n.t('singles.title'),
  }),
  content: {
    type: AptlyFieldType.Markdown,
    defaultValue: safeRawDraft(''),
    label: i18n.t('singles.content'),
  },
  reminderDate: {
    type: AptlyFieldType.Date,
    defaultValue: null,
    label: i18n.t('singles.reminder'),
  },
  addToReceipt: {
    type: AptlyFieldType.Switch,
    defaultValue: false,
    label: i18n.t('actions.addToReceipt'),
  },
  unitTemplateCategory: {
    type: AptlyFieldType.Custom,
    defaultValue: null,
    label: i18n.t('singles.category'),
    CustomComponent: UnitTemplateCategory,
    renderValidate: (crud) => !!crud.getField('addToReceipt').value,
  },
});

export function UnitTemplateCategory<T extends object, K extends keyof T>(
  props: ICustomRenderComponentProps<T, K>
) {
  const {
    crud: { getField, setField },
    field,
  } = props;
  const fieldName = field.field;
  const path = useGetApiUrl('unit', '/categories');
  const opts = useMemo(() => ({}), []);
  const [, categories] = useSearch<AptlyUnitTemplateCategory>(path, opts);

  const options = useMemo(
    () => [
      { value: null as T[K], label: i18n.t('actions.selectCategory') },
      ...categories.map((x) => ({ value: x._id, label: x.name })),
    ],
    [categories]
  );
  const value = useMemo(() => String(getField(fieldName).value), [getField, fieldName]);
  const handleOnSelect = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setField(fieldName)(e.target.value as T[K]),
    [setField, fieldName]
  );
  if (options.length === 1) return null;

  return <Select value={value} options={options} onChange={handleOnSelect} label={field.label} fullWidth />;
}
