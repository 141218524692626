import React from 'react';
import apiRequest, { RequestData } from '../libraries/fetch/apiRequest';
import handleError from '../containers/Error/handleError';

export type FetchOpts<T> = {
  background?: boolean;
  endpoint?: string;
  cb: (response: T) => void;
};

type Props<T> = {
  endpoint: string;
  children: (props: {
    busy: boolean;
    data: T | null;
    refresh: (opts?: FetchOpts<T>) => void;
  }) => React.ReactNode;
  params?: RequestData;
  dontFetch?: boolean;
  onFetched?: (data: T) => void;
  customAuth?: string;
};

type State<T> = {
  busy: boolean;
  data: T | null;
};

/**
 * @deprecated. Old system and should use useGet to get data.
 */
class Resource<T> extends React.Component<Props<T>, State<T>> {
  state = {
    busy: false,
    data: null,
  };

  componentDidMount() {
    const { dontFetch } = this.props;

    if (!dontFetch) {
      this.fetch();
    }
  }

  componentDidUpdate(prevProps: Props<T>) {
    const { endpoint, dontFetch } = this.props;

    if (!dontFetch && prevProps.endpoint !== endpoint) {
      this.fetch();
    }
  }

  fetch = async (opts?: FetchOpts<T>) => {
    const { endpoint, params, onFetched, customAuth } = this.props;

    const background = opts ? opts.background : false;
    const cb = opts ? opts.cb : undefined;
    const _endpoint = opts ? opts.endpoint || endpoint : endpoint;

    try {
      if (!background) {
        this.setState({
          busy: true,
        });
      }

      const response: T = await apiRequest(_endpoint, {
        data: params,
        customAuth,
      });

      this.setState({
        busy: false,
        data: response,
      });

      if (typeof cb === 'function') {
        cb(response);
      }

      if (typeof onFetched === 'function') {
        onFetched(response);
      }
    } catch (error) {
      handleError(error);
    }
  };

  render() {
    const { children } = this.props;
    const { busy, data } = this.state;

    return children({
      busy,
      data,
      refresh: this.fetch,
    });
  }
}

export default Resource;
