import { AptlyScopeSchema } from '@aptly-as/types';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import apiRequest from '../fetch/apiRequest';
import handleError from '../../containers/Error/handleError';
import ApiError from '../../components/ApiError';
import Scope from './Scope';

interface Props {
  path: string;
}

interface State<M extends object> {
  busy: boolean;
  error: any;
  models: M;
}

const ScopeContext = React.createContext<Partial<AptlyScopeSchema>>({});

function ScopeProvider({ path, children }: PropsWithChildren<Props>) {
  const init = useRef(false);
  const [state, setState] = useState<State<Partial<AptlyScopeSchema>>>({
    busy: true,
    error: null,
    models: {},
  });

  useEffect(() => {
    async function fetchPermissions() {
      try {
        const models = await apiRequest<object>(path);
        Scope.scopes = models;
        setState((s) => ({ ...s, busy: false, models, error: null }));
      } catch (error) {
        setState((s) => ({ ...s, busy: false, error: null }));
        handleError(error);
      }
    }
    if (!init.current) {
      init.current = true;
      fetchPermissions().then();
    }
  }, [path]);

  if (state.busy) {
    return null;
  }

  if (state.error) {
    return <ApiError error={state.error} />;
  }

  return <ScopeContext.Provider value={state.models}>{children}</ScopeContext.Provider>;
}

export { ScopeContext, ScopeProvider };
