import Button, { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import styled, { css } from 'styled-components';

const ButtonWithIcon = styled(Button)`
  svg {
    margin-left: ${(props) => props.theme.spacing()};
  }
  ${(props) =>
    props.variant === 'outlined' &&
    props.color === 'primary' &&
    css`
      color: ${(props) => props.theme.palette.primary.dark};
      border-color: ${(props) => props.theme.palette.primary.dark};
    `}
`;

export default Button;
export { IconButton, ButtonWithIcon };
export type { ButtonProps };
