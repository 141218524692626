import Save from '@mui/icons-material/Save';
import { AptlyProduct } from '@aptly-as/types';
import { useContext } from 'react';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import i18n from '../../libraries/i18n';
import { ModalActions } from '../../mui/Dialog';
import { OrganizationContext } from '../Organization/OrganizationContext';
import Product from './Product';
import { getProductScope } from './product.utils';
import ProductProvider from './ProductContext';

export interface ProductEditProps {
  product: AptlyProduct;
  update?: boolean;
  onChange: (product: AptlyProduct) => void;
  onDelete?: () => void;
  onClose?: () => void;
  disableIfBase?: boolean;
}

export default function ProductEdit({
  product,
  onChange,
  onDelete,
  onClose,
  update,
  disableIfBase,
}: ProductEditProps) {
  const org = useContext(OrganizationContext);
  const scope = getProductScope(product, org.data);

  let canUpdate = update && scope.update;
  if (disableIfBase) {
    canUpdate = !!product.organization;
  }

  return (
    <ProductProvider product={product} onChange={onChange} onDelete={onDelete}>
      <Product update={canUpdate}>
        {(crud, onDelete) => {
          return (
            <ModalActions>
              <ActionButtons
                onClose={onClose}
                onDelete={canUpdate && onDelete && scope.delete ? onDelete : undefined}
                actionIcon={<Save />}
                submitType={canUpdate ? 'submit' : 'button'}
                actionLabel={crud.state.data._id ? i18n.t('actions.save') : i18n.t('actions.createVariant')}
                cancelLabel={i18n.t('actions.close')}
                disabled={crud.state.busy || !canUpdate}
                disabledClose={crud.state.busy}
              />
            </ModalActions>
          );
        }}
      </Product>
    </ProductProvider>
  );
}
