import { AptlyProject } from '@aptly-as/types';
import React, { useCallback, useContext } from 'react';
import { EditIconButton } from '../../../components/actions/icons/Icons';
import { createModal } from '../../../containers/Modal/ModalContext';
import i18n from '../../../libraries/i18n';
import { ListItemEdit } from '../../../components/List/List';
import CrudModules from '../../../components/simpleCruds/settings/CrudModules';
import useGetApiUrl from '../../../hooks/useGetApiUrl';
import { OrganizationContext } from '../../Organization/OrganizationContext';

interface Props {
  project: AptlyProject;
  onSave: () => void;
}

export default function ProjectSettingsModules({ project, onSave }: Props) {
  const { data: organization } = useContext(OrganizationContext);
  const orgModules = React.useMemo(() => organization?.modules || [], [organization]);
  const endpoint = useGetApiUrl('organization');
  const modules = React.useMemo(() => project.disabledModules || [], [project.disabledModules]);
  const status = React.useMemo(
    () => orgModules.filter((x) => !modules.some((y) => x === y)).join(', '),
    [orgModules, modules]
  );

  const spawnModal = useCallback(() => {
    createModal(
      <CrudModules
        endpoint={`${endpoint}/projects`}
        id={project._id}
        modulesList={orgModules}
        modules={modules}
        onSave={onSave}
        name="disabledModules"
        reverse
      />
    );
  }, [endpoint, project._id, orgModules, modules, onSave]);

  if (!orgModules || orgModules.length === 0) return null;

  return (
    <ListItemEdit
      action={<EditIconButton title={i18n.t('singles.modules')} onClick={spawnModal} />}
      status={status}
    >
      {i18n.t('singles.modules')}
    </ListItemEdit>
  );
}
