import { CSSProperties } from 'react';
import { AptlyMediaSrc } from '@aptly-as/types';
import { getImageSet, IMediaWidth, ImageSrcSet, PLACEHOLDER_IMAGE } from './media.utils';

interface CreateUrlOpts {
  transforms?: string;
  fileType?: string;
  dontAutoFormat?: boolean;
  dontCrop?: boolean;
}

const srcSetTransform = (w: number, transforms?: CreateUrlOpts['transforms']) =>
  transforms ? `w_${w},${transforms}` : `w_${w}`;

export interface MediaSrcSetOpts {
  transforms?: CreateUrlOpts['transforms'];
  disableFullWidth?: boolean;
  style?: CSSProperties;
}

export default class Media {
  /**
   * Create img props for srcset. Spread return into img component
   * Should be run with useMemo for better performance
   */
  public static srcSet(
    image: AptlyMediaSrc,
    width: IMediaWidth,
    height: number = width,
    { transforms = '', disableFullWidth = false, style }: MediaSrcSetOpts = {}
  ): ImageSrcSet {
    if (!image) return { src: '#', width, height, alt: 'No image' };

    transforms = transforms ? `${transforms},h_${height}` : `h_${height}`;
    if (!transforms.match(/c_/)) transforms += `,c_pad`;

    const set = getImageSet(width);
    const src = this.url(image);
    const loading = 'lazy';
    const srcSet = set
      .map(
        (x) =>
          `${this.url(image, {
            transforms: srcSetTransform(x.width, transforms),
          })} ${x.width}w`
      )
      .join(',');
    const sizes = set
      .map((x, i) => {
        const min = set[i - 1] ? set[i - 1].maxWidth : 0;
        const max = x.maxWidth;
        return max
          ? `(min-width: ${min}px) and (max-width: ${max}px) ${x.width}px`
          : `(min-width: ${min}px) ${x.width}px`;
      })
      .join(',');
    const ret: ImageSrcSet = {
      src,
      srcSet,
      sizes,
      loading,
      alt: image.alt,
      title: image.title,
      width,
      height,
    };
    if (style) {
      ret.style = style;
    } else if (!disableFullWidth) {
      ret.style = { width: '100%', height: 'auto' };
    }
    return ret;
  }

  /**
   * Create CSS Media queries for background images and styled-components css
   * Should be run with useMemo for better performance
   */
  public static css(
    image: AptlyMediaSrc,
    size: IMediaWidth,
    transforms?: CreateUrlOpts['transforms']
  ): string {
    const set = getImageSet(size);
    return set.map((x, i) => {
      const url = this.url(image, {
        transforms: srcSetTransform(x.width, transforms),
      });
      const url2x = this.url(image, {
        transforms: srcSetTransform(x.width2x, transforms),
      });
      const min = set[i - 1] ? set[i - 1].maxWidth : 0;
      const max = x.maxWidth;
      return max
        ? `
        @media all and (min-width: ${min}px) and (max-width: ${max}px) {
          background-image: url("${url}");
        }
        @media all and (min-width: ${min}px) and (max-width: ${max}px)
        and (-webkit-min-device-pixel-ratio: 2) {
          background-image: url("${url2x}");
        }
      `
        : `
        background-image: url("${url}");
        @media all and (min-width: ${min}px) and (-webkit-min-device-pixel-ratio: 2) {
          background-image: url("${url2x}");
        }
      `;
    }).join(`
    `);
  }

  public static url(media: AptlyMediaSrc, opts: CreateUrlOpts = {}): string {
    let url: string = PLACEHOLDER_IMAGE.url;
    const cropOps: string[] = [];

    if (!media) {
      return url;
    } else if (typeof media.url === 'string') {
      url = media.url;
    }

    if (url.endsWith('.gif')) {
      return url;
    }

    if (!opts.dontCrop && media.area) {
      const crop = media.area;
      cropOps.push(`x_${crop.x},y_${crop.y},w_${crop.width},h_${crop.height},c_crop`);
    }

    if (opts.transforms) {
      cropOps.push(opts.transforms);
    }

    if (!opts.dontAutoFormat && !opts.fileType && !url.endsWith('.svg')) {
      cropOps.push('f_auto');
    }

    if (cropOps.length > 0) {
      url = url.replace(/\/upload\/([^/]*)/, '/upload/' + cropOps.join('/'));
    }

    if (opts.fileType) {
      url = url.substr(0, url.lastIndexOf('.')) + '.' + opts.fileType;
    }

    return url;
  }
}
