import Lock from '@mui/icons-material/Lock';
import EmptyState from '../containers/Error/EmptyState';
import Typography from '../mui/Typography';
import i18n from '../libraries/i18n';

export default function ModuleLocked() {
  return (
    <EmptyState icon={<Lock />}>
      <Typography variant="body1">{i18n.t('paragraphs.module.locked')}</Typography>
    </EmptyState>
  );
}
