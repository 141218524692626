import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import { gutter } from '../utils/spacing';
import { gray } from '../utils/themes';

const HorizontalDivider = styled.div`
  width: 1px;
  height: ${gutter};
  background-color: ${gray};
  margin: 0 calc(${gutter} / 2);
`;

export default Divider;
export { HorizontalDivider };
