import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const ComponentLink = styled(NavLink)`
  display: block;
  text-decoration: none;
`;

export const TextLink = styled(NavLink)`
  display: block;
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

export default ComponentLink;
