interface OrderItem {
  _id?: string;
  _orderID?: string;
}

const getOrderId = (x?: OrderItem | string) => (x ? (typeof x === 'string' ? x : x._orderID) : '');
const byOrderId = (a: OrderItem | string) => (b: OrderItem | string) => getOrderId(a) === getOrderId(b);

export function byOrderIds(ruleset: string[]) {
  return (a: OrderItem, b: OrderItem) => {
    if (!a._orderID && !b._orderID) return 0;
    if (!a._orderID) return 1;
    if (!b._orderID) return -1;
    const aIndex = ruleset.findIndex(byOrderId(a._orderID));
    const bIndex = ruleset.findIndex(byOrderId(b._orderID));
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  };
}

export const byCreated = (a: { created: string }, b: { created: string }) => {
  if (a.created > b.created) {
    return 1;
  } else if (a.created < b.created) {
    return -1;
  }
  return 0;
};
