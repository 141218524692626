import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { ReactNode } from 'react';
import styled from 'styled-components';
import MUITextField, { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemText from '@mui/material/ListItemText';
import MUISelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Label from '@mui/material/InputLabel';
import MUICheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import MUISwitch from '@mui/material/Switch';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';
import { OutlinedInput } from '@mui/material';

const Form = styled.form`
  margin: 0;
  padding: 0;
`;

const FieldWrapper = styled.div`
  position: relative;
`;

const TextFieldIcon = styled.div`
  margin: 15px 8px 0 0;
  width: 24px;
`;

export type ITextFieldProps = MUITextFieldProps & {
  head?: ReactNode;
  tail?: ReactNode;
  multiple?: boolean;
  accept?: boolean;
  shrink?: boolean;
  step?: string;
};

function TextField({ accept, head, tail, shrink, step, ...props }: ITextFieldProps) {
  return (
    <MUITextField
      {...props}
      inputProps={{ accept, step, autoFocus: props.autoFocus }}
      InputProps={{
        startAdornment: head ? head : null,
        endAdornment: tail ? tail : null,
        autoFocus: props.autoFocus,
      }}
      InputLabelProps={{ shrink }}
    />
  );
}

type SelectProps = {
  name: string;
  value: string | any[] | null;
  onChange: SelectInputProps<any>['onChange'];
  options: SelectOption[];
  label?: ReactNode;
  fullWidth?: boolean;
  multiple?: boolean;
  required?: boolean;
  disabled?: boolean;
};

export interface SelectOption {
  label: ReactNode;
  value: string;
}

function Select(props: SelectProps) {
  const { name, label, value, onChange, options, fullWidth, multiple, required, disabled } = props;

  return (
    <FormControl fullWidth={fullWidth} required={required}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <MUISelect
        value={value || undefined}
        onChange={onChange}
        multiple={multiple}
        input={<OutlinedInput label={label} />}
        inputProps={{
          id: name,
        }}
        renderValue={(selected) =>
          multiple
            ? options
                .filter((o) => (Array.isArray(selected) ? selected.includes(o.value) : true))
                .map((o) => o.label)
                .join(', ')
            : (options.find((o) => o.value === selected) || ({} as any)).label || selected
        }
        disabled={disabled}
      >
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {multiple && (
              <MUICheckbox color="primary" checked={Array.isArray(value) && value.includes(o.value)} />
            )}
            <ListItemText primary={o.label} />
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
}

interface MyCheckboxProps {
  label?: ReactNode;
}

export type ICheckboxProps = Omit<FormControlLabelProps, 'control' | 'label'> &
  Pick<CheckboxProps, 'required' | 'color'> &
  MyCheckboxProps;

function Checkbox(props: ICheckboxProps) {
  const { label, checked, color, onChange, disabled, required } = props;

  return (
    <FormControlLabel
      control={<MUICheckbox color={color} required={required} />}
      label={label || ''}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

function Switch(props: ICheckboxProps) {
  const { label, checked, defaultChecked, color, onChange, disabled, name, required } = props;

  return (
    <FormControlLabel
      control={<MUISwitch defaultChecked={defaultChecked} color={color} required={required} />}
      label={label!}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      name={name}
    />
  );
}

export { Form, Label, TextField, Checkbox, Switch, FieldWrapper, Select, TextFieldIcon };
