import { Route, Routes } from 'react-router-dom';
import { PropsWithChildren, useMemo } from 'react';
import NotFound from '../../components/NotFound';
import { ISidebarListItem, SidebarList } from './sidebar.utils';

interface IOrganizationRoutesProps {
  routes: SidebarList<any>[];
}

const toItem = (itemOrList: ISidebarListItem | SidebarList<any>): ISidebarListItem[] =>
  'items' in itemOrList ? itemOrList.items.map(toItem).flat() : [itemOrList];

export default function SidebarRoutes({ routes, children }: PropsWithChildren<IOrganizationRoutesProps>) {
  const flatRoutes = useMemo(() => routes.map(toItem).flat(), [routes]);

  return (
    <Routes>
      {flatRoutes.map((route, i) => (
        <Route key={route.path + i} path={route.path} element={route.element} />
      ))}
      {children}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
