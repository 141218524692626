import Palette from '@mui/icons-material/Palette';
import { Tooltip } from '@mui/material';
import { ncsGetHex } from '../libraries/ncs-color';

interface Props {
  color?: string;
  label?: string;
}

export default function ColorPalette({ color, label }: Props) {
  if (!color) return <></>;
  const ncs = color && color[0] !== '#' ? ncsGetHex(color) : color;
  return (
    <Tooltip title={label || color} placement="top">
      <Palette
        style={{
          fill: ncs || color,
          background: '#ccc',
          borderRadius: '50%',
        }}
      />
    </Tooltip>
  );
}
