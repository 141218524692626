import dayjs from 'dayjs';
import DateTimePicker from '../../../mui/x-date-picker/DateTimePicker';
import { ISimpleComponentProps } from './simple-components.utils';

export interface ICrudDateProps<T extends object> extends ISimpleComponentProps<T, any> {}

export default function SimpleDateTime<T extends object>({ field, onChange }: ICrudDateProps<T>) {
  const { id, value, defaultValue, edited, type, ...rest } = field;
  return <DateTimePicker value={dayjs(value)} onChange={(date: any) => onChange(date)} {...rest} />;
}
