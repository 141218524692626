import { Dispatch, SetStateAction } from 'react';
import { IUseGetState } from '@ewb/reach-react';
import {
  AptlyUnitTemplate,
  AptlyUnitTemplateCategory,
  AptlyUnitTemplateCategorySection,
  AptlyUnitTemplateCategorySectionPackage,
  AptlyUnitTemplateCategorySectionProduct,
} from '@aptly-as/types';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { IApiError } from '../../../components/ApiError';
import { IUpdatedType } from './unit-template.handlers';

export const unitTemplateUpdateWarning = (updated: IUpdatedType, place: string) => {
  if (!updated) {
    console.warn(place, 'Failed to find content to move to', {
      from: JSON.parse(JSON.stringify(event)),
      to: JSON.parse(JSON.stringify(event)),
    });
  }
};

export interface IUnitTemplateContext {
  unitTemplate: AptlyUnitTemplate;
  setState: Dispatch<SetStateAction<IUnitTemplateState>>;
  activePackage: string | null;
  setActivePackage: Dispatch<SetStateAction<string | null>>;
  path: string;
  onOver: (from: IUseSortableData, to: IUseSortableData) => void;
  onOrder: (from: IUseSortableData, to: IUseSortableData, origin?: IUseSortableData) => void;
  read: () => void;
  undo: (_id?: string) => Promise<AptlyUnitTemplate | void>;
  editProduct: (sectionProduct: AptlyUnitTemplateCategorySectionProduct) => void;
}

export type IUnitTemplateState = IUseGetState<AptlyUnitTemplate, IApiError>;
export interface IUseSortableData {
  expanded?: boolean;
  sortable: { containerId: string; index: number; items: string[] };
  category: AptlyUnitTemplateCategory;
  section?: AptlyUnitTemplateCategorySection;
  product?: AptlyUnitTemplateCategorySectionProduct;
  package?: AptlyUnitTemplateCategorySectionPackage;
}

export interface ISortableAttributes {
  role: string;
  tabIndex: number;
  'aria-pressed': boolean | undefined;
  'aria-roledescription': string;
  'aria-describedby': string;
}

export interface ISortableItemProps {
  id: string;
  listeners?: DraggableSyntheticListeners;
  attributes?: ISortableAttributes;
  isOverlay?: boolean;
  isDragging?: boolean;
  dragging?: boolean;
}
