import { IUseCrudProps } from '@ewb/reach-react/core/useCrud';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { AptlyUnit } from '@aptly-as/types';
import { IUseFieldRet, useFields } from '@ewb/reach-react';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import ApiError, { IApiError } from '../../components/ApiError';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils';
import { unitSchema, ICrudUnit } from './unit.schema';

type Context = IUseFieldRet<ICrudUnit, IApiError, ICrudFieldData<ICrudUnit>> & {
  data: AptlyUnit;
};
export const UnitContext = createContext<Context>({ data: {} } as any);

export function useUnit() {
  const { data } = useContext(UnitContext);
  return data;
}

export function UnitProvider({ id, children }: PropsWithChildren<{ id?: string }>) {
  const path = useApiUrl(SlugLevel.Project, 'units');
  const unit = useFields<ICrudUnit, IApiError, ICrudFieldData<ICrudUnit>>(
    path,
    useMemo(() => ({ _id: id }), [id]),
    useMemo(() => unitSchema(), []),
    useMemo(
      (): IUseCrudProps<ICrudUnit> => ({
        idKey: '_id',
        initWithGet: true,
        reachOptions: { query: { $populate: 'users;unitTemplate:_id,name' } },
      }),
      []
    )
  );

  const value = useMemo(() => ({ data: unit.state.data, ...unit }), [unit]);

  if (unit.state.error) return <ApiError error={unit.state.error} />;
  if (!unit.state.data.name) return null;

  return <UnitContext.Provider value={value}>{children}</UnitContext.Provider>;
}
