import React from 'react';

export const onWheelCaptureBlur = (e: React.WheelEvent<HTMLInputElement>) => {
  (e.target as HTMLInputElement).blur();
};

export const blurOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key !== 'Enter') return;
  (e.target as HTMLInputElement).blur();
};
