import { useContext, useMemo } from 'react';
import { Route } from 'react-router-dom';
import Header from '../../components/Header';
import Layout from '../../components/Layout';
import Main from '../../components/Main';
import Sidebar from '../../components/Sidebar';
import SidebarMenu from '../../containers/Sidebar/SidebarMenu';
import SidebarRoutes from '../../containers/Sidebar/SidebarRoutes';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import { userRoutes } from './user.routes';
import UserAPIClient from './UserAPIClient';
import { UserContext } from './UserContext';

function User() {
  const user = useContext(UserContext);
  const routes = useMemo(() => userRoutes(), []);

  return (
    <Layout>
      <Header head={[<div key="fn">{user.data.fullName}</div>]} />
      <Sidebar>
        <SidebarMenu routes={routes} level={SlugLevel.User} injection="user" />
      </Sidebar>
      <Main>
        <SidebarRoutes routes={routes}>
          <Route path="clients/new" element={<UserAPIClient />} />
          <Route path="clients/:clientID" element={<UserAPIClient />} />
        </SidebarRoutes>
      </Main>
    </Layout>
  );
}

export default User;
