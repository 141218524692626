import { useMemo } from 'react';
import { IUseSearchProps, useSearch } from '@ewb/reach-react';
import { AptlyErrorBody, AptlySearchPaginateResponse } from '@aptly-as/types';
import { IApiError } from '../../components/ApiError';

export interface IPaginatedSearchObject {
  _id: string;
}

export type IUsePaginatedSearchProps<T extends object> = Omit<
  IUseSearchProps<T, AptlyErrorBody, AptlySearchPaginateResponse<T>>,
  'responseToData'
>;

export const responseToData = <T>(res: AptlySearchPaginateResponse<T>) => ({
  items: res.data,
  count: res.count,
  limit: res.limit,
  skip: res.skip,
});

export default function usePaginatedSearch<T extends IPaginatedSearchObject>(
  path: string,
  opts: IUsePaginatedSearchProps<T> = {}
) {
  return useSearch<T, IApiError, AptlySearchPaginateResponse<T>>(
    path,
    useMemo(
      () => ({
        limit: 20,
        ...opts,
        responseToData: (res) => ({
          items: res.data,
          count: res.count,
          limit: res.limit,
          skip: res.skip,
        }),
      }),
      [opts]
    )
  );
}
