import { AptlyProduct } from '@aptly-as/types';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { SearchExportButton } from '../../components/actions/buttons/ExportButton';
import { OpenInNewIconButton } from '../../components/actions/icons/Icons';
import { SearchFieldDate, SearchFieldText } from '../../components/Search/search-fields/SearchFields';
import SimpleSearch, { SimpleSearchProps } from '../../components/Search/SimpleSearch';
import { createModal } from '../../containers/Modal/ModalContext';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../mui/Table';
import Paper from '../../mui/Paper';
import Typography from '../../mui/Typography';

interface ProductUse {
  _id: string;
  organizations: string[];
  projects: string[];
  unitTemplates: number;
  products: ProductUseProduct[];
}

interface ProductUseProduct extends Pick<AptlyProduct, '_id' | 'name' | 'productNumber'> {
  categories: { name: string; count: number }[];
  unitTemplates: number;
  count: number;
  units: number;
}

export default function OrganizationProducerDashboard() {
  const path = useApiUrl(SlugLevel.Organization, 'producer/product-use');
  const query = useMemo(
    () => ({
      $gte_createdAt: dayjs().startOf('year').format('YYYY-MM-DD'),
    }),
    []
  );

  const header: SimpleSearchProps<ProductUse>['header'] = useMemo(
    () => ({
      title: 'Produsent',
    }),
    []
  );

  const searchFields: SimpleSearchProps<ProductUse>['searchFields'] = useMemo(() => {
    const _fields = [
      <SearchFieldText key="productNumber" field="productNumber" label={i18n.t('singles.productNumber')} />,
      <SearchFieldDate key="$gte_createdAt" field="$gte_createdAt" label={i18n.t('singles.from')} />,
      <SearchFieldDate key="$lt_createdAt" field="$lt_createdAt" label={i18n.t('singles.to')} />,
    ];

    return _fields;
  }, []);

  const buttonActions: SimpleSearchProps<ProductUse>['buttonActions'] = useCallback(() => {
    return [<SearchExportButton key="export" endpoint={`${path}/export`} />];
  }, [path]);

  const handleOnOpen = useCallback((item: ProductUseProduct) => {
    createModal(<RowModalDetails item={item} />);
  }, []);

  return (
    <>
      <SimpleSearch<ProductUse>
        path={path}
        query={query}
        header={header}
        searchFields={searchFields}
        buttonActions={buttonActions}
      >
        {(state) => {
          if (!state.data) return null;
          return (
            <>
              <Typography gutterBottom>
                {state.data.organizations.length} x {i18n.t('singles.organizations')}
                <br />
                {state.data.projects.length} x {i18n.t('singles.projects')}
                <br />
                {state.data.unitTemplates} x {i18n.t('singles.optionLists')}
              </Typography>
              <Paper>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Produktnummer</TableCell>
                      <TableCell>Produkt</TableCell>
                      <TableCell>Tilvalglister</TableCell>
                      <TableCell>Brukt</TableCell>
                      <TableCell>Enheter</TableCell>
                      <TableCell align="right">Handling</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.data.products.map((item) => (
                      <Row key={item._id} item={item} onClick={handleOnOpen} />
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </>
          );
        }}
      </SimpleSearch>
    </>
  );
}

interface RowProps {
  item: ProductUseProduct;
  onClick: (item: ProductUseProduct) => void;
}

function Row({ item, onClick }: RowProps) {
  const handleShowInfo = useCallback(() => onClick(item), [onClick, item]);

  return (
    <TableRow key={item._id}>
      <TableCell>{item.productNumber || '-'}</TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.unitTemplates}</TableCell>
      <TableCell>{item.count}</TableCell>
      <TableCell>{item.units}</TableCell>
      <TableCell align="right">
        <OpenInNewIconButton onClick={handleShowInfo} title={i18n.t('actions.open')} />
      </TableCell>
    </TableRow>
  );
}

interface RowModalDetailsProps {
  onClose?: () => void;
  item: ProductUseProduct;
}

function RowModalDetails({ onClose, item }: RowModalDetailsProps) {
  return (
    <>
      <ModalTitle>{item.name}</ModalTitle>
      <ModalContent>
        <Typography variant="subtitle1">{i18n.t('singles.category')}</Typography>
        {item.categories.map((c) => (
          <div key={c.name}>
            {c.count} x {c.name}
          </div>
        ))}
      </ModalContent>
      <ModalActions>
        <ActionButtons onClose={onClose} submitType="button" />
      </ModalActions>
    </>
  );
}
