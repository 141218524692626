import { ChangeEvent, useCallback, useState } from 'react';
import { AptlyScopeSchemaValue, AptlyScopeSchema, AptlyScopes } from '@aptly-as/types';
import { Typography } from '@mui/material';
import i18n from '../libraries/i18n';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../mui/Table';
import { Checkbox } from '../mui/Input';

export interface ScopeEditProps {
  scope: Partial<AptlyScopeSchema>;
  onChange: (key: keyof AptlyScopeSchema, value: AptlyScopeSchemaValue) => void;
  disabled?: boolean;
}

export default function ScopeEdit({ scope, onChange, disabled }: ScopeEditProps) {
  const headerRow = useCallback(
    (title: string) => (
      <TableRow>
        <TableCell>
          <Typography variant="subtitle1">{title}</Typography>
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    ),
    []
  );
  const row = useCallback(
    (key: AptlyScopes, si: string) => (
      <PermissionRow
        value={scope[key]}
        label={i18n.t(si)}
        onChange={(v) => onChange(key, v)}
        disabled={disabled}
      />
    ),
    [scope, onChange, disabled]
  );

  return (
    <Table>
      <TableHead style={{ position: 'sticky', top: '50px' }}>
        <TableRow>
          <TableCell>{i18n.t('singles.model')}</TableCell>
          <TableCell>{i18n.t('actions.read')}</TableCell>
          <TableCell>{i18n.t('actions.write')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {headerRow(i18n.t('singles.admin'))}
        {row(AptlyScopes.Organization, 'singles.organizations')}
      </TableBody>
    </Table>
  );
}

interface PermissionRowProps {
  label: string;
  value?: AptlyScopeSchemaValue | '';
  onChange: (value: AptlyScopeSchemaValue) => void;
  disabled?: boolean;
}

function PermissionRow({ label, value = '', onChange, disabled }: PermissionRowProps) {
  const [set, setSet] = useState(new Set(value.split('')));

  function handleChange(key: 'C' | 'R' | 'U' | 'D') {
    return (_e: ChangeEvent<any>, checked: boolean) => {
      if (checked) set.add(key);
      else set.delete(key);

      onChange(Array.from(set).join('') as AptlyScopeSchemaValue);
      setSet(new Set(set));
    };
  }

  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>
        <Checkbox checked={set.has('R')} onChange={handleChange('R')} disabled={disabled} />
      </TableCell>
      <TableCell>
        <Checkbox checked={set.has('U')} onChange={handleChange('U')} disabled={disabled} />
      </TableCell>
    </TableRow>
  );
}
