import { AptlyFieldType, AptlyProduct, AptlyScopes } from '@aptly-as/types';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { GridRowId, GridRowModel } from '@mui/x-data-grid';
import { useCallback, useContext, useMemo } from 'react';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import Button from '../../mui/Button';
import i18n from '../../libraries/i18n';
import { schemaBulkIds } from '../../components/crud/schema/fields.schema';
import { OrganizationContext } from '../Organization/OrganizationContext';
import { IProductSchema } from './product.schema';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import useSimpleBulkPatch from '../../components/simple/useSimpleBulkPatch';
import useSimplePost from '../../components/simple/useSimplePost';
import Scope from '../../libraries/scope/Scope';
import { AccordionDeleteList } from '../../components/AccordionDeleteList';
import { SearchCrudContext } from '../../components/Search/search.utils';
import { SearchDataGridToolbar } from '../../components/Search/SearchDataGrid';

export function ProductToolbar() {
  const { options, level } = useContext(SearchCrudContext);
  const org = useContext(OrganizationContext);

  return (
    <SearchDataGridToolbar<IProductSchema>>
      {(active, getItems) => {
        const buttons = [];

        if (options.disableBulk) return null;
        const update =
          Scope.crud(AptlyScopes.AdminProducts, 'U') ||
          (level === SlugLevel.Organization && Scope.crud(AptlyScopes.OrganizationProducts, 'U')) ||
          (level === SlugLevel.Project && Scope.crud(AptlyScopes.ProjectProducts, 'U'));

        if (update) {
          buttons.push(<ConnectVariants key="variants" active={active} getItems={getItems} />);
          if (
            level === SlugLevel.Project ||
            (level === SlugLevel.Organization &&
              (Scope.crud(AptlyScopes.AdminProducts, 'U') || org.data.producer))
          ) {
            buttons.push(<BulkEdit key="bulk" active={active} getItems={getItems} />);
          }
        }
        return <>{buttons}</>;
      }}
    </SearchDataGridToolbar>
  );
}

interface ButtonProps {
  active: Map<GridRowId, GridRowModel>;
  getItems: () => AptlyProduct[];
}

function BulkEdit({ active, getItems }: ButtonProps) {
  const { actions, path, level } = useContext(SearchCrudContext);

  const bulkPatch = useSimpleBulkPatch<any>(
    `${path}/bulk`,
    useMemo(() => ({ ids: schemaBulkIds() }), []),
    actions.map,
    useMemo(
      () => ({
        fields: [],
        title: i18n.t('paragraphs.areYouSureToMoveProduct'),
        useFieldsProps: { alwaysPatch: true, forcePatch: ['ids', 'organization', 'project'] },
        onEdit: actions.refresh,
      }),
      [actions.refresh]
    )
  );

  const handleOnMove = useCallback(
    (products: AptlyProduct[]) => {
      const data = level === SlugLevel.Project ? { project: null } : { project: null, organization: null };
      return bulkPatch(products, data);
    },
    [bulkPatch, level]
  );
  const title =
    level === SlugLevel.Project ? i18n.t('actions.moveToOrganization') : i18n.t('actions.moveToAptly');
  return (
    <Button size="small" disabled={active.size === 0} onClick={() => handleOnMove(getItems())}>
      {title}
    </Button>
  );
}

function ConnectVariants({ active, getItems }: ButtonProps) {
  const { actions, path } = useContext(SearchCrudContext);

  const handleOnEdit = useCallback(() => actions.refresh(), [actions]);

  const listPost = useSimplePost(
    `${path}/products`,
    useMemo<ICrudSchema<any>>(
      () => ({
        products: {
          type: AptlyFieldType.Custom,
          label: '',
          defaultValue: [],
          customRender: (crud, field) => {
            const value = field.value || [];
            return (
              <AccordionDeleteList
                ids={value.map((x: AptlyProduct) => x._id)}
                data={value}
                onChange={(_ids, data) => crud.setField(field.field)(data)}
              />
            );
          },
        },
      }),
      []
    ),
    useMemo(
      () => ({
        fields: ['products'],
        title: i18n.t('actions.connectVariants'),
        actionButtonProps: {
          actionLabel: i18n.t('actions.save'),
          actionIcon: <SaveOutlinedIcon />,
        },
      }),
      []
    ),
    useMemo(() => ({ width: 'sm' }), [])
  );

  const handleOnMove = useCallback(
    (products: AptlyProduct[]) =>
      listPost(handleOnEdit, { products: products.map((x: AptlyProduct) => ({ _id: x._id, name: x.name })) }),
    [listPost, handleOnEdit]
  );

  return (
    <Button size="small" disabled={active.size === 0} onClick={() => handleOnMove(getItems())}>
      {i18n.t('actions.connectVariants')}
    </Button>
  );
}
