import { AptlyApp, AptlyScopes } from '@aptly-as/types';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useMemo } from 'react';
import Search, { SearchFieldText } from '../../../components/Search/Search';
import SearchCreateAction from '../../../components/Search/search-actions/SearchCreateAction';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  DataGridEditAction,
} from '../../../components/Search/search-data-grid/data-grid.actions';
import { dataGridSearchNameColumn } from '../../../components/Search/search-data-grid/data-grid.fields';
import { toSearchPatchPath } from '../../../components/Search/search.utils';
import SearchDataGrid from '../../../components/Search/SearchDataGrid';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import { appSchema, appSchemaFields } from '../app.schema';
import AppsMenus from './AppsMenus';
import AppsSettings from './AppsSettings';
import AppsWebhooks from './AppsWebhooks';

const appColumns = (): GridColDef[] => [
  dataGridSearchNameColumn(),
  dataGridActionProps((params) => <AppActions params={params} />, {
    width: 300,
  }),
];

export function Apps() {
  const path = useApiUrl(SlugLevel.Base, 'apps');
  const columns = useMemo(appColumns, []);
  const schema = useMemo(appSchema, []);

  return (
    <Search<AptlyApp>
      path={path}
      patchPath={toSearchPatchPath('apps')}
      scope={AptlyScopes.AdminApps}
      header={{ title: i18n.t('singles.apps') }}
      post={{ title: i18n.t('singles.apps') }}
      patch={{ title: i18n.t('singles.apps') }}
      schema={schema}
      fields={appSchemaFields}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name' } }}
      buttonActions={() => [<SearchCreateAction key="create" />]}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns} />}
    </Search>
  );
}

function AppActions({ params }: { params: GridRenderCellParams }) {
  return (
    <DataGridActionWrapper>
      <AppsMenus params={params} />
      <AppsSettings params={params} />
      <AppsWebhooks params={params} />
      <DataGridEditAction params={params} />
      <DataGridDeleteAction params={params} />
    </DataGridActionWrapper>
  );
}
