import { useCallback } from 'react';
import { AptlyDepartment, AptlyScopes } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import { dataGridSearchNameColumn } from '../../components/Search/search-data-grid/data-grid.fields';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import Search from '../../components/Search/Search';
import { departmentSchema } from './department.schema';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
} from '../../components/Search/search-data-grid/data-grid.actions';
import OrganizationDepartmentUsers from './OrganizationDepartmentUsers';
import OrganizationDepartmentProjects from './OrganizationDepartmentProjects';

const columns = (): GridColDef[] => [
  dataGridSearchNameColumn(),
  {
    type: 'number',
    field: 'projects',
    headerName: i18n.t('singles.projects'),
    valueGetter: (value: string[]) => (value as any[]).length,
    width: 150,
  },
  {
    type: 'number',
    field: 'users',
    headerName: i18n.t('singles.users'),
    valueGetter: (value: string[]) => (value as any[]).length,
    width: 100,
  },
  dataGridActionProps((params) => (
    <DataGridActionWrapper>
      <OrganizationDepartmentProjects params={params} />
      <OrganizationDepartmentUsers params={params} />
      <DataGridDeleteAction params={params} />
    </DataGridActionWrapper>
  )),
];

function OrganizationDepartments() {
  const path = useApiUrl(SlugLevel.Organization, 'departments');

  const buttonActions = useCallback(
    () => [<SearchCreateAction key="create" title={i18n.t('actions.add')} />],
    []
  );

  return (
    <Search<AptlyDepartment>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.OrganizationDepartments}
      header={{ title: i18n.t('singles.departments') }}
      post={{ title: i18n.t('singles.departments') }}
      patch={{
        title: (data) => data.name || '',
        preData: (data) => ({ ...data, filterProjects: Boolean(data.projects) }),
      }}
      delete={{ confirmInput: (data) => data.name }}
      schema={departmentSchema()}
      fields={['name']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name' } }}
      buttonActions={buttonActions}
    >
      {() => <SearchDataGrid columns={columns()} />}
    </Search>
  );
}

export default OrganizationDepartments;
