import { useCallback, useMemo } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import ReferenceInput, { IReferenceInputProps } from '../../ReferenceInput';
import { IPaginatedSearchObject } from '../../../libraries/reach/usePaginatedSearch';

interface Object extends IPaginatedSearchObject {
  name: string;
}

export interface IDataGridReferenceEditProps
  extends Omit<IReferenceInputProps<any>, 'variant' | 'onSelect' | 'onRemove' | 'autoFocus'> {
  params: GridRenderEditCellParams;
}

type Value = Object | Object[] | null;

/**
 * todo: rewrite this. So bs with array and stuff
 */
export default function DataGridReferenceEdit({ params, endpoint, ...rest }: IDataGridReferenceEditProps) {
  const { id, api, field } = params;
  const fieldValue = useMemo(
    () => (params.value || (rest.singular ? null : [])) as Value,
    [params.value, rest.singular]
  );
  const label = useMemo(
    () =>
      Array.isArray(fieldValue)
        ? fieldValue.map((x) => x.name).join(', ')
        : fieldValue
          ? fieldValue.name
          : 'N/A',
    [fieldValue]
  );

  const handleOnChange = useCallback(
    (value: any) => {
      api.setEditCellValue({ id, field, value: rest.singular ? value[0] : value });
      api.stopCellEditMode({ id, field });
    },
    [api, id, field, rest.singular]
  );

  const handleOnRemove = useCallback(
    (itemId: any) => {
      if (!rest.singular && Array.isArray(fieldValue)) {
        return api.setEditCellValue({ id, field, value: fieldValue.filter((x) => x._id === itemId) });
      }
      return api.setEditCellValue({ id, field, value: rest.singular ? null : [] });
    },
    [api, fieldValue, rest.singular, id, field]
  );

  return (
    <Grid container>
      <ReferenceInput
        label={label}
        variant="standard"
        endpoint={endpoint}
        defaultSelected={Array.isArray(fieldValue) ? fieldValue : fieldValue ? [fieldValue] : []}
        onSelect={handleOnChange}
        onRemove={handleOnRemove}
        queryKey="$name"
        labelKey="name"
        size="small"
        itemLabelKey="name"
        {...rest}
      />
    </Grid>
  );
}
