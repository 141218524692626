import { ISearchFieldActionProps, useSearchQueryState } from './search-field.utils';
import ReferenceInput, { IReferenceInputProps, toReferenceItem } from '../../ReferenceInput';
import i18n from '../../../libraries/i18n';

type ReferenceProps = Omit<IReferenceInputProps<any>, 'onChange' | 'singular' | 'defaultSelected'>;
type SearchReferenceProps = ISearchFieldActionProps & {
  referenceProps: ReferenceProps;
};

const toValue = (item: any) => item._id;

export function SearchFieldReference({ field, queryKey, referenceProps }: SearchReferenceProps) {
  const { labelKey = 'name', ...rest } = referenceProps;
  const [value, setValue] = useSearchQueryState(field, queryKey);
  return (
    <ReferenceInput<any>
      onSelect={(is) => {
        if (Array.isArray(is) && is[0]) {
          setValue(toValue(is[0]));
        } else {
          setValue(undefined);
        }
      }}
      labelKey={labelKey}
      defaultSelected={value ? [toReferenceItem(value, String(labelKey))] : []}
      singular
      {...rest}
    />
  );
}

export const searchFieldProducerReference = (): SearchReferenceProps => ({
  field: 'producer',
  referenceProps: {
    endpoint: 'api/v1/producers',
    queryKey: '$name',
    labelKey: 'name',
    query: { as: 'array', sort: '-featured,name', limit: '5', select: '_id,name', archived: 'false' },
    label: i18n.t('singles.producer'),
  },
});

export const searchFieldTagsReference = (): SearchReferenceProps => ({
  field: 'tags',
  referenceProps: {
    endpoint: 'api/v1/tags',
    queryKey: '$name',
    labelKey: 'name',
    query: { as: 'array', limit: '5' },
    label: i18n.t('singles.tags'),
  },
});
