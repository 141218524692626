import { AptlyScopes } from '@aptly-as/types';
import Add from '@mui/icons-material/Add';
import { Fragment, useContext } from 'react';
import styled from 'styled-components';
import Section from '../../components/Section';
import { CrudContactNotifyButton } from '../../components/simpleCruds/settings/CrudContactNotifyAdd';
import EmptyState from '../../containers/Error/EmptyState';
import { ModalButton } from '../../containers/Modal/ModalButton';
import { SlugLevel, useTo } from '../../hooks/useGetApiUrl';
import { HideMobileFragment } from '../../hooks/useMobile';
import i18n from '../../libraries/i18n';
import ComponentLink from '../../libraries/router/ComponentLink';
import { LayoutScope } from '../../libraries/scope/ScopeComponets';
import Card, { CardContent } from '../../mui/Card';
import Divider from '../../mui/Divider';
import Grid from '../../mui/Grid';
import Typography from '../../mui/Typography';
import { rawSpacing } from '../../utils/spacing';
import AvailabilityAdd, { RemoveAvailability } from './AvailabilityAdd';
import CalendarAddOrEdit from './CalendarAddOrEdit';
import { AvailabilityContext } from './BookingContext';

const LayoutContainer = styled(Grid)`
  width: 100%;
`;

function BookingCalendarSelect() {
  const availability = useContext(AvailabilityContext);
  const to = useTo(SlugLevel.Unit, '/motebooking');

  if (!availability.data && availability.state.busy) {
    return null;
  }
  if (!availability.data) {
    return (
      <Fragment>
        <Section>
          <EmptyState>{i18n.t('statuses.noBooking')}</EmptyState>
          <Grid container justifyContent="center">
            <AvailabilityAdd />
          </Grid>
        </Section>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Section>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">{i18n.t('singles.calendars')}</Typography>
          </Grid>
          <LayoutScope item scope={AptlyScopes.ProjectBooking} crud="C">
            <Grid container direction="row" justifyContent="flex-end" gap={2}>
              <HideMobileFragment>
                <Grid item>
                  <RemoveAvailability />
                </Grid>
              </HideMobileFragment>
              <Grid item>
                <CrudContactNotifyButton defaultExpandGroup="booking" />
              </Grid>
              {availability && (
                <Grid item>
                  <ModalButton variant="contained" endIcon={<Add />} label={i18n.t('actions.add')}>
                    <CalendarAddOrEdit />
                  </ModalButton>
                </Grid>
              )}
            </Grid>
          </LayoutScope>
        </Grid>
      </Section>
      <Section>
        <LayoutContainer container spacing={rawSpacing} justifyContent="center">
          {availability.data?.calendars.map((calendar) => (
            <Grid item xs={12} md={4} key={calendar._id}>
              <Card component={ComponentLink} to={`${to}/${calendar._id}`}>
                <CardContent>
                  <Typography variant="subtitle1">{calendar.name}</Typography>
                  <Divider />
                  <Typography>{calendar.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </LayoutContainer>
      </Section>
    </Fragment>
  );
}

export default BookingCalendarSelect;
