import React from 'react';
import { useSlugs } from './useGetApiUrl';

/**
 * @deprecated use useApiUrl
 * @param level
 * @param path
 * @param version
 */
export default function useApiV1(
  level: 'base' | 'organization' | 'project' | 'unit' = 'base',
  path: string = '',
  version = 'v1'
) {
  const [organizationSlug, projectID, unitID] = useSlugs();

  return React.useMemo(() => {
    let url = 'api';

    if (level !== 'base' && organizationSlug) {
      url += `/${version}/organizations/${organizationSlug}`;
    }

    if (['project', 'unit'].includes(level) && projectID) {
      url += `/projects/${projectID}`;
    }

    if (level === 'unit' && unitID) {
      url += `/units/${unitID}`;
    }

    return url + path;
  }, [level, organizationSlug, projectID, unitID, path, version]);
}
