import { AptlyScopes } from '@aptly-as/types';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useContext } from 'react';
import styled from 'styled-components';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { Link } from 'react-router-dom';
import createMediaURL from '../Media/createMediaURL';
import Divider from '../../mui/Divider';
import Avatar from '../../components/Avatar';
import List, { ListItem } from '../../components/List/List';
import ExpandableAvatar from '../../components/ExpandableAvatar';
import Block from '../../components/Block';
import Grid from '../../mui/Grid';
import Typography from '../../mui/Typography';
import i18n from '../../libraries/i18n';
import { tightSpacing } from '../../utils/spacing';
import { ListScope } from '../../libraries/scope/ScopeComponets';
import { UserContext } from './UserContext';

const Wrapper = styled.div`
  min-width: 18rem;
`;

function UserMenu() {
  const { data: userData, logout } = useContext(UserContext);

  return (
    <ExpandableAvatar alt={userData.fullName} image={userData.profileImage || ''}>
      <Wrapper>
        <Block>
          <Grid container spacing={tightSpacing}>
            <Grid item>
              <Avatar
                alt={userData.fullName}
                src={
                  userData.profileImage
                    ? createMediaURL(userData.profileImage || '', {
                        width: 65,
                        height: 65,
                        crop: 'fill',
                      })
                    : null
                }
              />
            </Grid>
            <Grid item>
              <Typography variant="h1">{userData.fullName}</Typography>
              <Typography>{userData.email}</Typography>
            </Grid>
          </Grid>
        </Block>
        <Divider />
        <ListScope scope={AptlyScopes.Admin}>
          <ListItem button icon={<BuildOutlinedIcon />} component={Link} to="/admin">
            {i18n.t('singles.adminPanel')}
          </ListItem>
        </ListScope>
        <Divider />
        <List>
          <ListItem button component={Link} to="/bruker/instillinger" icon={<SettingsOutlinedIcon />}>
            {i18n.t('actions.editAccount')}
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button icon={<ExitToApp />} onClick={logout}>
            {i18n.t('actions.signOut')}
          </ListItem>
        </List>
      </Wrapper>
    </ExpandableAvatar>
  );
}

export default UserMenu;
