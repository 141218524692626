import { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';
import AttachFile from '@mui/icons-material/AttachFile';
import { AptlyMediaSrc } from '@aptly-as/types';
import Media, { MediaSrcSetOpts } from '../models/Media/Media';
import { IMediaWidth } from '../models/Media/media.utils';
import { ImageMediaCloud, Img } from '../models/Media/Image';

export interface IAptlyMediaProps extends ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
  media?: AptlyMediaSrc | null;
  width?: IMediaWidth;
  height?: number;
  crop?: 'fit' | 'fill' | 'pad';
  opts?: MediaSrcSetOpts;
}

export default function AptlyMedia({
  src,
  media,
  width = 540,
  height = width,
  opts = {},
  ...props
}: IAptlyMediaProps) {
  if (media && typeof media === 'string') {
    return <ImageMediaCloud src={media} width={width} height={height} {...props} />;
  }

  if (media && media.mime?.match('image')) {
    return <Img alt="" {...Media.srcSet(media, width, height, opts)} {...props} />;
  }

  if (media && media.src && media.mime) {
    return (
      <MediaFile>
        <AttachFile />
        {media.name}
      </MediaFile>
    );
  }

  if (src) {
    return <ImageMediaCloud src={src} width={width} height={height} {...props} />;
  }

  return null;
}

const MediaFile = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid black;
`;
