import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useCallback } from 'react';
import Accordion from './Accordion';
import { DeleteIconButton } from './actions/icons/Icons';
import { ListItemText } from './List/List';
import i18n from '../libraries/i18n';

export interface IAccordionDeleteListProps {
  ids: string[];
  data: AccordionDeleteItem[];
  onChange?: (ids: string[], data: AccordionDeleteItem[]) => void;
  expanded?: boolean;
}

export interface AccordionDeleteItem {
  _id: string;
  name: string;
}

export function AccordionDeleteList({ ids, data, onChange, expanded }: IAccordionDeleteListProps) {
  const handleOnDelete = useCallback(
    (i: number) => () => {
      const byI = (_: any, ii: number) => ii !== i;
      if (onChange) onChange(ids.filter(byI), data.filter(byI));
    },
    [ids, data, onChange]
  );

  return (
    <Accordion
      expanded={expanded ?? ids.length <= 5}
      title={i18n.t('singles.affects')}
      subtitle={`${ids.length} ${i18n.t('singles.rows')}`}
    >
      {() => (
        <List>
          {ids.map((_id: string, i: number) => {
            const itemData = data.find((x) => x._id === _id);
            return (
              <ListItem
                key={_id}
                secondaryAction={
                  onChange ? <DeleteIconButton onClick={handleOnDelete(i)} edge="end" /> : undefined
                }
              >
                <ListItemText primary={itemData?.name || _id} />
              </ListItem>
            );
          })}
        </List>
      )}
    </Accordion>
  );
}
