import { AptlyProjectPeriod } from '@aptly-as/types';
import { useContext, useMemo } from 'react';
import { ProjectContext } from '../Project/ProjectContext';

export function usePeriods(): AptlyProjectPeriod[] {
  const project = useContext(ProjectContext);
  return useMemo(() => {
    if (project.data) {
      return project.data.periods || [];
    }
    return [];
  }, [project]);
}
