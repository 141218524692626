import Resource from './Resource';
import Grid from '../mui/Grid';
import { rawSpacing } from '../utils/spacing';
import BusyWrapper from './BusyWrapper';

type Data = {
  [key: string]: any;
};

export type DashboardElement = {
  data?: Data | Data[] | void;
  endpoint?: string;
  renderFn: (data: Data | Data[]) => any;
  query?: object;
};

export type DashboardConfig = {
  elements: DashboardElement[];
};

type Props = {
  config: DashboardConfig;
};

function renderElement(element: DashboardElement, key: number) {
  return (
    <Grid item xs={12} md={6} lg={4} key={key}>
      {element.endpoint ? (
        <Resource endpoint={element.endpoint} params={element.query}>
          {({ busy, data }) => <BusyWrapper busy={busy}>{data && element.renderFn(data)}</BusyWrapper>}
        </Resource>
      ) : (
        element.renderFn(element.data || {})
      )}
    </Grid>
  );
}

function Dashboard(props: Props) {
  const { config } = props;

  return (
    <Grid container spacing={rawSpacing}>
      {config.elements.map(renderElement)}
    </Grid>
  );
}

export default Dashboard;
