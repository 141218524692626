import { useCallback, useState } from 'react';
import { AptlyOrganization } from '@aptly-as/types';
import Typography from '../../../mui/Typography';
import i18n from '../../../libraries/i18n';
import CheckDIntegration from './CheckDIntegration';
import BoligmappenIntegration from './BoligmappenIntegration';

interface Props {
  organization: AptlyOrganization;
}

export default function OrganizationIntegrations({ organization }: Props) {
  const [integrations, setIntegrations] = useState(organization ? organization.integrations || [] : []);

  const handleSave = useCallback((int: any) => {
    setIntegrations(int);
  }, []);

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        {i18n.t('singles.integrations')}
      </Typography>
      <CheckDIntegration integrations={integrations} setIntegrations={handleSave} />
      <BoligmappenIntegration integrations={integrations} setIntegrations={handleSave} />
    </div>
  );
}
