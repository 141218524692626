import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AptlyInquiry, AptlyInquiryMessage, AptlyUser } from '@aptly-as/types';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { EditorText } from '../../components/EditorText.js';
import { useModal } from '../../containers/Modal/Modal';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { intlDateTimeFormat } from '../../libraries/intl';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Divider from '../../mui/Divider';
import Paper from '../../mui/Paper';
import styled from 'styled-components';
import Grid from '../../mui/Grid';
import { tightSpacing } from '../../utils/spacing';
import { ImageWithModal } from '../Media/Image';
import { useInquiry } from './InquiryContext';

const PaperPad = styled(Paper)`
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`;

type Props = {
  message: AptlyInquiryMessage;
};

function InquiryMessage({ message }: Props) {
  const inquiry = useInquiry();
  const showOriginal = useModal(() => <OriginalMessage inquiry={inquiry} message={message} />);
  const orgEndpoint = useApiUrl(SlugLevel.Organization);

  const files = [...(message.images || []), ...(message.files || [])];

  return (
    <PaperPad>
      <Grid container direction="column" spacing={tightSpacing}>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>{(message.author as AptlyUser)?.fullName || message.email}</Grid>
            <Grid item>{intlDateTimeFormat(message.sentTime)}</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <EditorText value={message.message} />
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            {files.map((image, i) => {
              if (typeof image === 'string') {
                return (
                  <Grid item key={i}>
                    <ImageWithModal src={image} height={50} crop="fit" />
                  </Grid>
                );
              }
              if (image.resource_type === 'image') {
                return (
                  <Grid item key={i}>
                    <ImageWithModal src={image.public_id} height={50} crop="fit" />
                  </Grid>
                );
              }
              let href = image.url;
              if (image.document) {
                href = `${orgEndpoint}/documents/${image.document}/download`;
              }
              return (
                <Grid item key={i}>
                  <Button
                    variant="outlined"
                    component="a"
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {image.filename}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item>
          {message.bodyHtml && <Button onClick={showOriginal}>{i18n.t('actions.showWholeEmail')}</Button>}
        </Grid>
      </Grid>
    </PaperPad>
  );
}

function OriginalMessage({
  inquiry,
  message,
  onClose,
}: Pick<Props, 'message'> & { inquiry: AptlyInquiry; onClose?: () => void }) {
  return (
    <>
      <ModalTitle>
        <Typography variant="h3">{inquiry.title}</Typography>
      </ModalTitle>
      <ModalContent>
        <div dangerouslySetInnerHTML={{ __html: message.bodyHtml || '' }} />
      </ModalContent>
      <ModalActions>
        <ActionButtons onClose={onClose} submitType="button" cancelLabel={i18n.t('actions.close')} />
      </ModalActions>
    </>
  );
}

export default InquiryMessage;
