import { AptlyScopes, AptlyUpsellTemplate } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import useScopeLevel from '../../libraries/scope/useScopeLevel';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import {
  dataGridLevelColumn,
  dataGridSearchNameColumn,
  dataGridSearchImageColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import { ICrudUpsellTemplate, upsellImportSchema, upsellTemplateSchema } from './upsell-template.schema';
import SearchImportAction from '../../components/Search/search-actions/SearchImportAction';
import { useCallback } from 'react';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridCopyAction,
  DataGridDeleteAction,
  DataGridEditAction,
  DataGridExportAction,
} from '../../components/Search/search-data-grid/data-grid.actions';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import useScopeProps from '../../libraries/scope/useScopeProps';

const columns = (): GridColDef[] => [
  dataGridSearchImageColumn('image'),
  dataGridSearchNameColumn(),
  {
    type: 'string',
    minWidth: 250,
    field: 'videoEmbed',
    headerName: i18n.t('paragraphs.videoEmbedCode'),
  },
  {
    field: 'links',
    minWidth: 150,
    headerName: i18n.t('singles.links'),
    renderCell: (params) => params.value.map((x: any) => x.name).join(', '),
  },
  dataGridLevelColumn(),
  dataGridActionProps(
    (params) => (
      <DataGridActionWrapper>
        <DataGridEditAction params={params} checkIfDisabledInProject />
        <DataGridCopyAction<AptlyUpsellTemplate>
          params={params}
          preCopy={(data) => ({
            name: `${data.name} - kopi`,
            image: data.image,
            imageMedia: data.imageMedia,
            links: data.links,
            text: data.text,
            videoEmbed: data.videoEmbed,
          })}
        />
        <DataGridExportAction params={params} />
        <DataGridDeleteAction params={params} checkIfDisabledInProject />
      </DataGridActionWrapper>
    ),
    { width: 235 }
  ),
];

export default function UpsellTemplates() {
  const path = useApiUrl(SlugLevel.Project, 'upsell-templates');
  const scope = useScopeProps({
    project: AptlyScopes.ProjectUpsellTemplates,
    organization: AptlyScopes.OrganizationUpsellTemplates,
    base: AptlyScopes.Admin,
  });

  const buttonActions = useCallback(
    () => [
      <SearchImportAction key="import" path={`${path}/import`} schema={upsellImportSchema} />,
      <SearchCreateAction key="create" />,
    ],
    [path]
  );

  return (
    <Search<ICrudUpsellTemplate>
      path={path}
      patchPath={toSearchPatchPath('upsell-templates')}
      level={useScopeLevel()}
      scope={scope}
      header={{ title: i18n.t('singles.upsellTemplate') }}
      post={{ title: i18n.t('singles.upsellTemplate') }}
      patch={{ title: (data) => String(data.name) }}
      schema={upsellTemplateSchema()}
      fields={['name', 'text', 'image', 'videoEmbed', 'links']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name', archived: false, level: 'project', $populate: 'links:_id,name' } }}
      buttonActions={buttonActions}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns()} />}
    </Search>
  );
}
