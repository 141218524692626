import { useCallback, useContext, useMemo } from 'react';
import { Reach, ReachContext } from '@ewb/reach-react';
import { ICreateModelOptions } from '../../containers/Modal/modal.types';
import { createModal } from '../../containers/Modal/ModalContext';
import { IPaginatedSearchObject } from '../../libraries/reach/usePaginatedSearch';
import DeleteModal, { IDeleteModalProps } from '../../containers/Modal/DeleteModal';
import { IToSearchPathPathFn } from '../Search/search.utils';

export type IUseSimpleDeleteFn<T extends IPaginatedSearchObject> = (data: T, i: number) => void;

export interface IUseSimpleDeleteProps<T extends IPaginatedSearchObject>
  extends Omit<IDeleteModalProps, 'onConfirm' | 'confirmInput'> {
  confirmInput?: (data: T) => string | undefined;
}

export default function useSimpleDelete<T extends IPaginatedSearchObject>(
  path: IToSearchPathPathFn,
  splice: (index: number, end: number) => void,
  props: IUseSimpleDeleteProps<T> = {},
  modalOptions: ICreateModelOptions = { width: 'sm' }
): IUseSimpleDeleteFn<T> {
  const service = useContext(ReachContext);
  const reach = useMemo(() => new Reach(service), [service]);

  const handleOnDelete = useCallback(
    async (data: T, i: number) => {
      await reach.api(`${path(data)}/${data._id}`, { method: 'DELETE' });
      splice(i, 1);
    },
    [reach, path, splice]
  );

  return useCallback(
    (data: T, i: number) => {
      createModal(
        <DeleteModal
          onConfirm={() => handleOnDelete(data, i)}
          {...props}
          confirmInput={props.confirmInput ? props.confirmInput(data) : undefined}
        />,
        modalOptions
      );
    },
    [handleOnDelete, props, modalOptions]
  );
}
