import { AptlyInquiry, AptlyInquiryStatus } from '@aptly-as/types';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { ICrudFieldOption } from '../../components/crud/utils/crud.utils';
import i18n from '../../libraries/i18n';
import { error, info, primary, secondary } from '../../utils/themes';

type MessageStatus = {
  icon?: any;
} & ICrudFieldOption<AptlyInquiryStatus>;

const flagIcon = (color: string) => <FlagOutlinedIcon style={{ color: color }} />;

export const inquiryStatusOptions = (): MessageStatus[] => [
  {
    icon: flagIcon(info),
    value: AptlyInquiryStatus.Open,
    label: i18n.t('statuses.open'),
  },
  {
    icon: flagIcon(secondary),
    value: AptlyInquiryStatus.Pending,
    label: i18n.t('statuses.pending'),
  },
  {
    icon: flagIcon(primary),
    value: AptlyInquiryStatus.Resolved,
    label: i18n.t('statuses.solved'),
  },
  {
    icon: flagIcon(error),
    value: AptlyInquiryStatus.Closed,
    label: i18n.t('statuses.closed'),
  },
];

export const getStatus = (inquiry: AptlyInquiry) => {
  const statusObj = inquiryStatusOptions().find((x) => inquiry.status && x.value === inquiry.status);
  if (statusObj) {
    return statusObj;
  } else {
    return inquiryStatusOptions()[0];
  }
};
