import { GridColDef, DataGrid as MuiDataGrid, DataGridProps, GridRowsProp } from '@mui/x-data-grid';
import { nbNO } from '@mui/x-data-grid/locales';

type Doc = { _id: string };

export const setDataGridId = (x: Doc) => ({ ...x, id: x._id });
export const memoSetDataGridIds = (docs?: Doc[] | null) => (docs ? docs.map(setDataGridId) : []);

export default function DataGrid(props: DataGridProps) {
  return (
    <MuiDataGrid
      pageSizeOptions={[10, 20, 50, 100]}
      localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
      {...props}
    />
  );
}

export type { GridColDef, GridRowsProp, DataGridProps };
