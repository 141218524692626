import { AptlyProject, AptlyProjectIntegration } from '@aptly-as/types';
import { Dispatch, SetStateAction } from 'react';
import Edit from '@mui/icons-material/Edit';
import SimpleCrud from '../../../components/SimpleCrud';
import { createModal } from '../../../containers/Modal/ModalContext';
import i18n from '../../../libraries/i18n';
import Button, { IconButton } from '../../../mui/Button';
import { Field } from '../../../deprecated/inputs';
import Grid from '../../../mui/Grid';
import { rawSpacing } from '../../../utils/spacing';
import StatusText from '../../../components/StatusText';
import { ListItem } from '../../../components/List/List';
import { IIntegrations } from '../../Inquiry/inquiry.types';

interface Props {
  label: string;
  name: IIntegrations;
  endpoint: string;
  integrations: AptlyProject['integrations'];
  setIntegrations: Dispatch<SetStateAction<AptlyProject['integrations']>>;
}

export const projectIntegrationFields = (type: IIntegrations, data?: AptlyProjectIntegration): Field[] => [
  {
    type: 'text',
    name: 'integration',
    value: type,
    label: type,
    hidden: true,
  },
  {
    type: 'text',
    name: 'project',
    value: data ? data.project : '',
    label: data ? data.project : '',
    helpField: i18n.t('singles.project'),
  },
];

export const ProjectIntegrationItem = (props: Props) => {
  const { integrations, setIntegrations, endpoint, name, label } = props;
  const integration = integrations.find((x) => x.integration === name);
  if (!integration) return <ProjectIntegrationButton {...props} />;

  return (
    <ListItem
      divider
      secondaryAction={
        <IconButton
          onClick={() => {
            createModal(
              <SimpleCrud
                endpoint={endpoint}
                id={integration._id}
                fields={projectIntegrationFields(name, integration)}
                onCreate={(retProj: AptlyProject) => {
                  const data = retProj.integrations.find((x: any) => x.integration === name);
                  const index = integrations.findIndex((x) => x.integration === name);
                  integrations[index] = data as AptlyProject['integrations'][0];
                  setIntegrations([...integrations]);
                }}
                deleteAction
                onDestroy={(project) => {
                  setIntegrations(project.integrations);
                }}
              />
            );
          }}
          size="large"
        >
          <Edit />
        </IconButton>
      }
    >
      <Grid container spacing={rawSpacing}>
        <Grid item sm={3}>
          {label}
        </Grid>
        <Grid item sm={9}>
          <StatusText>{integration.project}</StatusText>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export const ProjectIntegrationButton = ({ name, endpoint, setIntegrations, label }: Props) => (
  <Button
    onClick={() => {
      createModal(
        <SimpleCrud<AptlyProject['integrations'][0]>
          title={i18n.t('singles.integrations') + ` ${label}`}
          endpoint={endpoint}
          fields={projectIntegrationFields(name)}
          onCreate={(integration) => {
            setIntegrations((s) => [...s, integration]);
          }}
        />
      );
    }}
  >
    {i18n.t('actions.add')} {label}
  </Button>
);
