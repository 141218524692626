import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import React, { Dispatch, PropsWithChildren, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';
import { useMobile } from '../../hooks/useMobile';
import i18n from '../../libraries/i18n';
import { IconButton } from '../../mui/Button';
import Grid from '../../mui/Grid';
import Menu from '../../mui/Menu';
import { tightSpacing } from '../../utils/spacing';
import { SearchButtonWithIcon } from '../actions/buttons/Buttons';
import { SearchIconButton } from '../actions/icons/Icons';
import SubMenu, { SubMenuProps } from '../SubMenu';

export interface SearchActionsProps<T> {
  showSearch?: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  subMenuOptions: SubMenuProps<T>['options'];
}

export default function SearchActions<T>({
  showSearch,
  subMenuOptions,
  setShow,
  children,
}: PropsWithChildren<SearchActionsProps<T>>) {
  const isMobile = useMobile();
  const handleOnSetShow = useCallback(() => setShow((s) => !s), [setShow]);

  return (
    <Grid container spacing={tightSpacing} alignItems="center" justifyContent="flex-end">
      {isMobile ? (
        <Grid item>
          <ShowMoreActionsMobile>{children}</ShowMoreActionsMobile>
        </Grid>
      ) : (
        children
      )}
      {showSearch && (
        <Grid item>
          {isMobile ? (
            <SearchIconButton onClick={handleOnSetShow} />
          ) : (
            <SearchButtonWithIcon onClick={handleOnSetShow} />
          )}
        </Grid>
      )}
      {subMenuOptions && subMenuOptions.length > 0 && (
        <Grid item>
          <SubMenu<T> options={subMenuOptions} />
        </Grid>
      )}
    </Grid>
  );
}

function ShowMoreActionsMobile({ children }: PropsWithChildren<{}>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    []
  );
  return (
    <>
      <IconButton
        aria-label={i18n.t('actions.showMeMore')}
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ExpandCircleDownIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={() => setAnchorEl(null)}>
        <StyledGrid container flexDirection="column-reverse" spacing={1}>
          {children}
        </StyledGrid>
      </Menu>
    </>
  );
}

const StyledGrid = styled(Grid)`
  padding: 0 0.5rem;
  button {
    width: 100%;
  }
`;
