import i18n from '../../libraries/i18n';

const labelTranslations = () => ({
  admin: i18n.t('singles.admin'),
  produsenter: i18n.t('singles.producers'),
  produkter: i18n.t('singles.products'),
  bookings: i18n.t('singles.bookAMeeting'),
});

type Crumb = {
  label: string;
  url: string;
};

function createCrumbs(path: string): Crumb[] {
  const splitPath = path.split('/').filter((p) => p.length > 0);
  const crumbs = [];
  let currentPath = '';
  const translations = labelTranslations();

  for (let i = 0; i < splitPath.length; i++) {
    const p = splitPath[i];
    currentPath = `${currentPath}/${p}`;

    if (p in translations) {
      crumbs.push({
        // @ts-ignore
        label: translations[p],
        url: currentPath,
      });
    }
  }

  return crumbs;
}

export default createCrumbs;
