import { AptlyFieldType, AptlyRecommendation } from '@aptly-as/types';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import { schemaColor, schemaName } from '../../components/crud/schema/fields.schema';
import i18n from '../../libraries/i18n';
import { acceptImages } from '../../libraries/react-dropzone/drop-zone.utils';

export const recommendationSchema = (): ICrudSchema<AptlyRecommendation> => ({
  name: schemaName({ autoFocus: true }),
  color: schemaColor({ required: true }),
  title: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('singles.title'),
    required: true,
  },
  text: {
    type: AptlyFieldType.Markdown,
    defaultValue: '',
    label: i18n.t('singles.description'),
  },
  image: {
    type: AptlyFieldType.Image,
    defaultValue: null,
    label: i18n.t('singles.image'),
    image: { mediaKey: 'imageMedia', width: 720, height: 350, accept: acceptImages },
  },
});
