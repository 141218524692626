import { AptlyOrder, AptlyPayment, AptlyScopes } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import { useContext, useMemo } from 'react';
import { GoToIconButtonLink } from '../../components/actions/icons/Icons.js';
import {
  dataGridActionProps,
  DataGridActionWrapper,
} from '../../components/Search/search-data-grid/data-grid.actions.js';
import { SearchPageOverlayProps } from '../../components/Search/SearchPageOverlay.js';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl';
import SearchDataGrid, { ISearchDataGridProps } from '../../components/Search/SearchDataGrid';
import Search, { SearchFieldSelect, SearchFieldText } from '../../components/Search/Search';
import { intlCurrency, intlDateTimeFormat } from '../../libraries/intl.js';
import { getId } from '../../libraries/mongoose.js';
import { IUsePaginatedSearchProps } from '../../libraries/reach/usePaginatedSearch.js';
import useScopeLevel from '../../libraries/scope/useScopeLevel.js';
import useScopeProps from '../../libraries/scope/useScopeProps.js';
import { DocumentPreviewIcon } from '../Document/DocumentPreview.js';
import { PaymentDeliveryAction } from './PaymentDelivery.js';
import { PaymentCaptureAction } from './PaymentCapture.js';
import { paymentSchema } from './payment.schema.js';
import { SearchContext, toSearchPatchPath } from '../../components/Search/search.utils';
import { paymentStatusLabel, paymentStatusOptions } from './payment.utils.js';

const columns = (): GridColDef<AptlyPayment>[] => [
  {
    field: 'number',
    headerName: i18n.t('singles.number'),
    width: 100,
  },
  {
    field: 'order',
    headerName: i18n.t('singles.orderNumber'),
    flex: 1,
    renderCell: (params) => params.value?.orderNumber,
  },
  {
    field: 'organization',
    headerName: i18n.t('singles.organization'),
    width: 175,
    renderCell: (params) => params.value?.name,
  },
  {
    field: 'project',
    headerName: i18n.t('singles.project'),
    width: 175,
    renderCell: (params) => params.value?.name,
  },
  {
    field: 'unit',
    headerName: i18n.t('singles.unit'),
    width: 175,
    renderCell: (params) => params.value?.name,
  },
  {
    field: 'status',
    headerName: i18n.t('singles.status'),
    width: 150,
    renderCell: (params) => paymentStatusLabel(params.value),
  },
  {
    field: 'shippingDate',
    headerName: i18n.t('statuses.delivery'),
    width: 150,
    valueGetter: (v) => (v ? intlDateTimeFormat(v) : null),
  },
  {
    field: 'splits',
    type: 'number',
    headerName: 'Aptly',
    width: 100,
    align: 'right',
    renderCell: (params) => {
      const split = (params.value as AptlyPayment['splits'])?.find((x) => x.destination === 'platform');
      if (split) {
        return intlCurrency(split.amount / 100);
      }
      return intlCurrency(0);
    },
  },
  {
    field: 'totalCost',
    type: 'number',
    headerName: i18n.t('singles.total'),
    width: 100,
    align: 'right',
    renderCell: (params) => intlCurrency(params.value),
  },
  dataGridActionProps(
    (params) => {
      const { refresh } = useContext(SearchContext);
      const payment = params.row as AptlyPayment;
      const order = payment.order as AptlyOrder;
      const document = payment.document || order?.receipt;
      const to = useTo(SlugLevel.Project, '/ordre', { project: getId(payment.project) });
      return (
        <DataGridActionWrapper>
          {document && <DocumentPreviewIcon document={document} />}
          <PaymentDeliveryAction payment={payment} onSave={refresh} />
          <PaymentCaptureAction payment={payment} onSave={refresh} />
          <GoToIconButtonLink to={`${to}/${getId(params.row.order)}`} />
        </DataGridActionWrapper>
      );
    },
    { minWidth: 180 }
  ),
];

export default function Payments() {
  const path = useApiUrl(SlugLevel.Project, 'payments');
  const schema = useMemo(() => paymentSchema(), []);
  const level = useScopeLevel();
  const scope = useScopeProps({
    project: AptlyScopes.ProjectOrders,
    organization: AptlyScopes.OrganizationOrders,
    base: AptlyScopes.Admin,
  });
  const _columns = useMemo(() => columns(), []);
  const reach: IUsePaginatedSearchProps<AptlyPayment> = useMemo(() => {
    let $populate = 'order:id,orderNumber,paymentSession,receipt';
    if (!path.includes('organizations')) {
      $populate += ';organization:_id,name';
    }
    if (!path.includes('projects')) {
      $populate += ';project:_id,name';
    }
    if (path.includes('organizations')) {
      $populate += ';unit:_id,name';
    }
    return {
      paginationMode: 'server',
      query: { sort: '-number', $populate },
    };
  }, [path]);
  const initialState: ISearchDataGridProps['initialState'] = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          organization: !path.includes('organizations'),
          project: !path.includes('projects'),
          unit: path.includes('organizations'),
          splits: !path.includes('organizations'),
        },
      },
    }),
    [path]
  );

  const searchFields: SearchPageOverlayProps['searchFields'] = useMemo(
    () => [
      <SearchFieldText
        key="number"
        field="number"
        queryKey="number"
        label={i18n.t('singles.number')}
        autoFocus
      />,
      <SearchFieldSelect
        key="status"
        field="status"
        options={[{ value: '', label: i18n.t('singles.all') }, ...paymentStatusOptions()]}
        label={i18n.t('singles.status')}
      />,
    ],
    []
  );

  return (
    <Search<AptlyPayment>
      path={path}
      patchPath={toSearchPatchPath('payments')}
      level={level}
      scope={scope}
      header={{ title: i18n.t('singles.payments') }}
      post={{ title: i18n.t('singles.payments') }}
      patch={{ title: i18n.t('singles.payments') }}
      schema={schema}
      fields={[]}
      options={{ disableBulk: true }}
      reach={reach}
      buttonActions={() => []}
      searchFields={searchFields}
    >
      {() => <SearchDataGrid columns={_columns} initialState={initialState} />}
    </Search>
  );
}
