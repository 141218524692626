import Typography from '@mui/material/Typography';
import Documents_old from '../../Document/Documents_old';
import Section from '../../../components/Section';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../../mui/Table';
import i18n from '../../../libraries/i18n';
import { TableContainer } from '@mui/material';
import { useOrganization } from '../../Organization/OrganizationContext';
import { useProject } from '../ProjectContext';

export default function ProjectIntegrationCheckD() {
  const organization = useOrganization();
  const project = useProject();

  if (!organization || !project) return null;

  const integration = project.integrations.find((x) => x.integration === 'checkd');
  const errors = integration && integration.failures;

  return (
    <>
      <Section>
        <Typography variant="h1">CheckD</Typography>
      </Section>
      {errors && (
        <Section>
          <TableContainer>
            <Typography variant="h1">{i18n.t('singles.errorMessages')}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>{i18n.t('singles.name')}</TableCell>
                  <TableCell>{i18n.t('singles.message')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {errors.map((error, i) => (
                  <TableRow key={i}>
                    <TableCell>{error._id}</TableCell>
                    <TableCell>{error.name}</TableCell>
                    <TableCell>{error.error}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Section>
      )}
      <Documents_old
        organizationSlug={organization.slug}
        projectID={project._id}
        canCreate={false}
        canDelete={false}
        query={{
          thirdPartyUploader: 'checkd',
        }}
      />
    </>
  );
}
