import {
  AptlyFieldType,
  AptlyOrder,
  AptlyOrderActionPostBody,
  AptlyOrderActionPostBodyUser,
  AptlyUser,
} from '@aptly-as/types';
import Grid from '@mui/material/Grid/Grid';
import TextField from '@mui/material/TextField';
import { ICrudSchema } from '../../../components/crud/utils/crud.utils';
import MarkdownEditor from '../../../components/MarkdownEditor';
import { useCrudFormField } from '../../../components/simple/CrudForm';
import i18n from '../../../libraries/i18n';

export type HandleOrderBody = AptlyOrderActionPostBody;

export interface HandleOrderUser extends Omit<AptlyOrderActionPostBodyUser, 'user'> {
  user: AptlyUser;
}

export const handleOrderBodySchema = (): ICrudSchema<AptlyOrderActionPostBody> => ({
  action: {
    type: AptlyFieldType.Custom,
    label: i18n.t('singles.action'),
    defaultValue: null,
  },
  document: {
    type: AptlyFieldType.Documents,
    label: i18n.t('singles.document'),
    defaultValue: null,
  },
  users: {
    type: AptlyFieldType.Custom,
    label: i18n.t('singles.users'),
    defaultValue: [],
  },
  deadlineInDays: {
    type: AptlyFieldType.Number,
    label: i18n.t('statuses.deadlineInDays'),
    defaultValue: 30,
  },
  emailSubject: {
    type: AptlyFieldType.Text,
    label: i18n.t('statuses.emailSubject'),
    defaultValue: '',
  },
  emailText: {
    type: AptlyFieldType.Text,
    label: i18n.t('statuses.emailText'),
    defaultValue: '',
  },
  emailMarkdown: {
    type: AptlyFieldType.Markdown,
    label: i18n.t('statuses.emailText'),
    defaultValue: '',
  },
});
export type HandleAptlyOrder = Pick<
  AptlyOrder,
  '_id' | 'project' | 'unit' | 'orderNumber' | 'receipt' | 'signedReceipt' | 'status' | 'createdAt'
>;

export function EmailContent() {
  const emailSubject = useCrudFormField<HandleOrderBody, 'emailSubject'>('emailSubject');
  const emailText = useCrudFormField<HandleOrderBody, 'emailText'>('emailText');
  const emailMarkdown = useCrudFormField<HandleOrderBody, 'emailMarkdown'>('emailMarkdown');

  return (
    <Grid container flexDirection="column" gap={2} item xs={12}>
      <TextField
        label={emailSubject.field.label}
        value={emailSubject.field.value}
        onChange={(e) => emailSubject.onChange(e.target.value)}
        InputLabelProps={{ shrink: true }}
        fullWidth
        autoComplete="off"
      />
      {emailMarkdown.field.value ? (
        <MarkdownEditor
          label={emailMarkdown.field.label as string}
          value={emailMarkdown.field.value}
          onChange={emailMarkdown.onChange}
        />
      ) : (
        <TextField
          label={emailText.field.label}
          value={emailText.field.value}
          onChange={(e) => emailText.onChange(e.target.value)}
          InputLabelProps={{ shrink: true }}
          multiline
          minRows={3}
          fullWidth
          autoComplete="off"
        />
      )}
    </Grid>
  );
}
