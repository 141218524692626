import dayjs from 'dayjs';
import { useCallback, useContext, useState, MouseEvent } from 'react';
import styled from 'styled-components';
import {
  AptlyAvailabilityCalendarAvailableTime,
  AptlyFieldType,
  AptlyScopes,
  AptlyUnit,
  AptlyUser,
} from '@aptly-as/types';
import { DeleteIconButton, PersonIconButton } from '../../components/actions/icons/Icons';
import CrudForm, { CrudFormContext } from '../../components/simple/CrudForm';
import deleteResource from '../../containers/Notification/deleteResource';
import SearchAndSelect from '../../deprecated/Search/SearchAndSelect';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { LayoutScope } from '../../libraries/scope/ScopeComponets';
import Grid from '../../mui/Grid';
import Popover from '../../mui/Popover';
import { BookingContext } from './BookingContext';
import { tightSpacing } from '../../utils/spacing';
import Typography from '../../mui/Typography';
import { FORMAT_DATE, FORMAT_TIME } from '../../env';

type Props = {
  hideDate?: boolean;
};

export default function BookingTimes({ hideDate }: Props) {
  const {
    state: { date, times },
  } = useContext(BookingContext);
  return (
    <Grid container direction={'column'} spacing={tightSpacing}>
      {!hideDate && (
        <Grid item>
          <Header variant={'body2'}>{date.format(FORMAT_DATE)}</Header>
        </Grid>
      )}
      <Grid item>
        <Grid container direction={'column'}>
          {times.map((time) => (
            <BookingTime key={time._id} time={time} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

const Header = styled(Typography)`
  font-weight: bold !important;
  text-decoration: underline;
`;

interface BookingTimeProps {
  time: AptlyAvailabilityCalendarAvailableTime;
}

function BookingTime({ time }: BookingTimeProps) {
  const { calendarPath, getBookings, getTimes } = useContext(BookingContext);

  const handleOnDelete = useCallback(() => {
    deleteResource(`${calendarPath}/${time._id}`, () => {
      getTimes(dayjs(time.from).toISOString());
    });
  }, [time, getTimes]);

  const handleOnSave = useCallback(() => {
    const date = dayjs(time.from).toISOString();
    getTimes(date);
    getBookings(date, date);
  }, [time]);

  return (
    <Grid container justifyContent="space-between">
      <LayoutItem item>
        {dayjs(time.from).format(FORMAT_TIME)}-{dayjs(time.to).format(FORMAT_TIME)}
        {time.user && (
          <>
            <br />
            {(time.user as AptlyUser).fullName}
          </>
        )}
      </LayoutItem>
      <LayoutScope item scope={AptlyScopes.ProjectBooking} crud="U">
        {!time.booked && (
          <CrudForm<any>
            path={calendarPath}
            data={time}
            schema={{
              unit: { type: AptlyFieldType.Reference, label: i18n.t('singles.unit'), defaultValue: null },
            }}
          >
            <FindUnitAndBook onEdit={handleOnSave} />
          </CrudForm>
        )}
        <DeleteIconButton onClick={handleOnDelete} />
      </LayoutScope>
    </Grid>
  );
}

function FindUnitAndBook({ onEdit }: { onEdit: () => void }) {
  const [target, setTarget] = useState<HTMLButtonElement | null>(null);
  const unitsPath = useApiUrl(SlugLevel.Project, 'units');
  const { setBusy } = useContext(BookingContext);
  const { setField, save } = useContext(CrudFormContext);

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setTarget(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setTarget(null);
  }, []);

  const handleOnBook = useCallback(
    async (units: AptlyUnit[]) => {
      setBusy(true);
      setTarget(null);
      setField('unit')(units[0]._id);
      await save();
      onEdit();
      setBusy(false);
    },
    [setField, save, onEdit, setBusy]
  );
  return (
    <>
      <PersonIconButton
        onClick={handleClick}
        aria-owns={target ? 'simple-popper' : undefined}
        aria-haspopup="true"
        size="large"
      >
        {i18n.t('actions.bookMeetingWithUnit')}
      </PersonIconButton>
      <Popover open={Boolean(target)} anchorEl={target || undefined} onClose={handleClose}>
        <SearchAndSelect<AptlyUnit>
          endpoint={unitsPath}
          labelKey={'name'}
          queryKey={'name'}
          query={{ as: 'array', select: '_id name', limit: '5' }}
          searchHint={i18n.t('actions.searchUnit')}
          onCommit={handleOnBook}
          singular
        />
      </Popover>
    </>
  );
}

const LayoutItem = styled(Grid)`
  padding-top: 0.85rem;
`;
