import * as ncsColor from 'ncs-color';

type IStandard = 'NCS S' | 'NCS';

export function ncsGetHex(text: string, standard?: boolean) {
  if (!text) return '';
  const ncs: IStandard = standard ? 'NCS S' : 'NCS';

  let textNcs = text.charAt(0) === 'S' ? text.substring(1, text.length) : text;
  if (textNcs.charAt(0) === ' ') {
    textNcs = textNcs.substr(1, textNcs.length);
  }

  return ncsColor.hex(`${ncs} ${textNcs}`);
}
