import { useCallback } from 'react';
import { AptlyDocument } from '@aptly-as/types';
import styled from 'styled-components';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { PreviewIconButton } from '../../components/actions/icons/Icons';
import { createModal } from '../../containers/Modal/ModalContext';
import { API_URL } from '../../env';
import { SlugLevel, SlugValues, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { getId } from '../../libraries/mongoose';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';

interface Props extends Pick<SlugValues, 'projectID'> {
  document?: AptlyDocument | string;
}

export function DocumentPreviewIcon(props: Props) {
  const handleOnPreview = useDocumentPreview(props);
  return <PreviewIconButton onClick={handleOnPreview} />;
}

export function useDocumentPreview({ document, projectID }: Props) {
  return useCallback(() => {
    createModal(<DocumentPreview document={document} projectID={projectID} />, { width: 'md' });
  }, [document, projectID]);
}

interface PreviewProps extends Props {
  onClose?: () => void;
}

export function DocumentPreview({ onClose, document, projectID }: PreviewProps) {
  const previewUrl = useApiUrl(SlugLevel.Project, `documents/${getId(document)}/preview`, { projectID });
  const title = document && typeof document === 'object' ? document.name : document;

  return (
    <>
      {title && <ModalTitle>{title}</ModalTitle>}
      <ModalContent>
        <StyledIframe src={`${API_URL}/${previewUrl}`} />
      </ModalContent>
      <ModalActions>
        <ActionButtons onClose={onClose} submitType="button" cancelLabel={i18n.t('actions.close')} />
      </ModalActions>
    </>
  );
}

const StyledIframe = styled.iframe`
  width: 99%;
  height: 75vh;
`;
