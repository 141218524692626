import { GridColDef } from '@mui/x-data-grid';
import { AptlyOptionLabel, AptlyScopes } from '@aptly-as/types';
import useScopeLevel from '../../libraries/scope/useScopeLevel';

import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import {
  dataGridActions,
  dataGridLevelColumn,
  dataGridSearchNameColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import { optionLabelSchema } from './option-label.schema';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import useScopeProps from '../../libraries/scope/useScopeProps';

const columns = (): GridColDef[] => [
  dataGridSearchNameColumn(),
  dataGridLevelColumn(),
  dataGridActions({ checkIfDisabledInProject: true }),
];

export default function OptionLabels() {
  const path = useApiUrl(SlugLevel.Project, 'option-labels');
  const scope = useScopeProps({
    base: AptlyScopes.Admin,
    organization: AptlyScopes.OrganizationOptionLabels,
  });

  return (
    <Search<AptlyOptionLabel>
      path={path}
      patchPath={toSearchPatchPath('option-labels')}
      level={useScopeLevel()}
      scope={scope}
      header={{ title: i18n.t('singles.labels') }}
      post={{ title: i18n.t('singles.labels') }}
      patch={{ title: (data) => String(data.name) }}
      schema={optionLabelSchema()}
      fields={['name']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name', archived: false, level: 'project' } }}
      buttonActions={() => [<SearchCreateAction key="create" />]}
      searchFields={[
        <SearchFieldText key="1" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns()} />}
    </Search>
  );
}
