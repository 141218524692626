import { AptlyErrorBody, AptlySearchPaginateResponse } from '@aptly-as/types';
import { useMemo } from 'react';
import {
  IUsePatchFn,
  IUsePatchProps,
  IUseSearchActions,
  IUseSearchInfo,
  IUseSearchNextFn,
  usePatch,
} from '@ewb/reach-react';
import { ICreateModelOptions } from '../../containers/Modal/modal.types';
import usePaginatedSearch, { IPaginatedSearchObject, IUsePaginatedSearchProps } from './usePaginatedSearch';
import useSimplePost, { IUsePostCrudFn, IUsePropsCrudProps } from '../../components/simple/useSimplePost';
import useSimplePatch, {
  IUseSimplePatchFn,
  IUseSimplePatchProps,
} from '../../components/simple/useSimplePatch';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import { IApiError } from '../../components/ApiError';
import useSimpleDelete, {
  IUseSimpleDeleteFn,
  IUseSimpleDeleteProps,
} from '../../components/simple/useSimpleDelete';
import useSimpleBulkPatch, {
  IUseSimpleBulkPatchFn,
  IUseSimpleBulkPatchProps,
} from '../../components/simple/useSimpleBulkPatch';
import useSimpleBulkDelete, { IUseSimpleBulkDeleteFn } from '../../components/simple/useSimpleBulkDelete';
import handleError from '../../containers/Error/handleError';
import { successNotification } from '../../containers/Notification/notification.utils';
import i18n from '../i18n';
import { IToSearchPathPathFn } from '../../components/Search/search.utils';

export interface IUseCrudSearchProps<T extends IPaginatedSearchObject> {
  searchPath?: string;
  fields?: (keyof T)[];
  post?: IUseCrudSearchPostProps<T>;
  patch?: IUseCrudSearchPatchProps<T>;
  copy?: IUsePropsCrudProps<T>;
  delete?: IUseSimpleDeleteProps<T>;
  bulk?: IUseCrudSearchBulkProps<T>;
  reach?: IUsePaginatedSearchProps<T>;
}

export type IUseCrudSearchBulkProps<T extends IPaginatedSearchObject> = Omit<
  IUseSimpleBulkPatchProps<T>,
  'fields'
> &
  Partial<Pick<IUseSimpleBulkPatchProps<T>, 'fields'>>;
export type IUseCrudSearchPostProps<T extends IPaginatedSearchObject> = Omit<IUsePropsCrudProps<T>, 'fields'>;
export type IUseCrudSearchPatchProps<T extends IPaginatedSearchObject> = Omit<
  IUseSimplePatchProps<T>,
  'fields'
> &
  Partial<Pick<IUseSimplePatchProps<T>, 'fields'>>;

export interface IUseCrudSearchActions<T extends IPaginatedSearchObject> extends IUseSearchActions<T> {
  spawnPost: IUsePostCrudFn<T>;
  spawnPatch: IUseSimplePatchFn<T>;
  spawnCopy: IUsePostCrudFn<T>;
  spawnDelete: IUseSimpleDeleteFn<T>;
  spawnBulkPatch: IUseSimpleBulkPatchFn<T>;
  spawnBulkDelete: IUseSimpleBulkDeleteFn<T>;

  patchRow: IUsePatchFn<T>;
}

export type ICrudSearchRet<T extends IPaginatedSearchObject, E> = [
  busy: boolean,
  data: T[],
  error: E | null | undefined,
  next: IUseSearchNextFn<T>,
  info: IUseSearchInfo<AptlySearchPaginateResponse<T>>,
  actions: IUseCrudSearchActions<T>,
];

export default function useCrudSearch<T extends IPaginatedSearchObject>(
  path: string,
  patchPath: IToSearchPathPathFn,
  schema: ICrudSchema<T>,
  props: IUseCrudSearchProps<T>,
  modalProps?: ICreateModelOptions
): ICrudSearchRet<T, IApiError> {
  const [busy, data, error, next, info, paginateActions] = usePaginatedSearch<T>(
    path + (props.searchPath || ''),
    props.reach
  );

  const meta = useMemo(() => ({ data }), [data]);

  const spawnPostProps = useMemo(
    () => ({
      title: '',
      fields: props.fields || [],
      ...props.post,
      meta,
    }),
    [props.fields, props.post, meta]
  );
  const spawnPost = useSimplePost(path, schema, spawnPostProps, modalProps);

  const spawnBulkPatchProps = useMemo(
    () => ({
      fields: props.fields || [],
      useFieldsProps: {
        alwaysPatch: true,
        forcePatch: ['ids' as keyof T],
        reachOptions: {
          query: props.reach?.query || {},
        },
      },
      ...(props.bulk || { title: '' }),
    }),
    [props.fields, props.reach, props.bulk]
  );
  const spawnBulkPatch = useSimpleBulkPatch(
    `${path}/bulk`,
    schema,
    paginateActions.map,
    spawnBulkPatchProps,
    modalProps
  );

  const spawnProps: IUseSimplePatchProps<T> = useMemo(
    () => ({
      title: '',
      fields: props.fields || [],
      useFieldsProps: { initWithGet: true },
      ...props.patch,
      contentProps: { ...props.patch?.contentProps, meta },
      modalProps,
    }),
    [props.fields, props.patch, modalProps, meta]
  );
  const spawnPatch = useSimplePatch(patchPath, schema, paginateActions.splice, spawnProps);
  const copyProps: IUsePropsCrudProps<T> = useMemo(
    () =>
      props.copy
        ? props.copy
        : {
            title: i18n.t('actions.copy'),
            fields: props.fields || [],
          },
    [props.copy, props.fields]
  );
  const spawnCopy = useSimplePost(path, schema, copyProps);
  const spawnDelete = useSimpleDelete(patchPath, paginateActions.splice, props.delete);
  const spawnBulkDelete = useSimpleBulkDelete(path, paginateActions.filter);
  const patchProps: IUsePatchProps<T, AptlyErrorBody> = useMemo(
    () => ({
      onError: (e) => {
        handleError(e);
      },
      onPatch: (patch) => {
        if (patch) {
          paginateActions.map((item) => {
            if (item._id === patch._id) return patch;
            return item;
          });
          successNotification(i18n.t('statuses.saved'));
        }
      },
    }),
    [paginateActions.map]
  );

  const [, patchRow] = usePatch(path, patchProps);

  const actions: IUseCrudSearchActions<T> = useMemo(
    () => ({
      ...paginateActions,
      spawnPost,
      spawnPatch,
      spawnCopy,
      spawnBulkPatch,
      spawnDelete,
      spawnBulkDelete,
      patchRow,
    }),
    [
      paginateActions,
      spawnPost,
      spawnPatch,
      spawnCopy,
      spawnBulkPatch,
      spawnDelete,
      spawnBulkDelete,
      patchRow,
    ]
  );

  return useMemo(() => [busy, data, error, next, info, actions], [busy, data, error, next, info, actions]);
}
