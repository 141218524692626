import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { AptlyOrganization } from '@aptly-as/types';
import { IUseFieldRet, useFields } from '@ewb/reach-react';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import ApiError, { IApiError } from '../../components/ApiError';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils';
import organizationSchema, { IOrganizationSchema } from './organization.schema';

type Context = IUseFieldRet<IOrganizationSchema, IApiError, ICrudFieldData<IOrganizationSchema>> & {
  data: AptlyOrganization;
};
export const OrganizationContext = createContext<Context>({ data: {} } as any);

export function useOrganization() {
  const { data } = useContext(OrganizationContext);
  return data;
}

export function OrganizationProvider({ slug, children }: PropsWithChildren<{ slug?: string }>) {
  const path = useApiUrl(SlugLevel.Base, 'organizations');
  const org = useFields<IOrganizationSchema, IApiError, ICrudFieldData<IOrganizationSchema>>(
    path,
    useMemo(() => ({ _id: slug, slug }), [slug]),
    useMemo(() => organizationSchema(), []),
    useMemo(() => ({ idKey: '_id', initWithGet: true }), [])
  );

  const value = useMemo(() => ({ data: org.state.data, ...org }), [org]);

  if (org.state.error) return <ApiError error={org.state.error} />;
  if (!org.state.data.name) return null;

  return <OrganizationContext.Provider value={value}>{children}</OrganizationContext.Provider>;
}
