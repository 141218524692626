import { AptlyAvailability, AptlyAvailabilityCalendar, AptlyFieldType, AptlyScopes } from '@aptly-as/types';
import { schemaDescription, schemaName } from '../../components/crud/schema/fields.schema';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import Scope from '../../libraries/scope/Scope';

export const availabilitySchema = (): ICrudSchema<AptlyAvailability> => ({
  organization: {
    type: AptlyFieldType.Hidden,
    defaultValue: '',
    label: '',
  },
  project: {
    type: AptlyFieldType.Hidden,
    defaultValue: '',
    label: '',
  },
});

export const calendarSchema = (): ICrudSchema<AptlyAvailabilityCalendar> => ({
  name: schemaName(),
  description: schemaDescription(),
  location: {
    type: AptlyFieldType.GooglePlace,
    defaultValue: { formatted_address: '', name: '', geometry: { location: { lat: 0, lng: 0 } } },
    label: i18n.t('singles.address'),
  },
  attendees: {
    type: AptlyFieldType.Reference,
    label: i18n.t('singles.attendees'),
    defaultValue: [],
    renderValidate: () => Scope.crud(AptlyScopes.OrganizationMembers, 'R'),
    useReference: () => ({
      endpoint: useApiUrl(SlugLevel.Organization, 'users'),
      queryKey: '$fullName',
      labelKey: 'fullName',
      multiple: true,
      query: { as: 'array', select: '_id,fullName', limit: 5, sort: 'fullName' },
    }),
  },
  userAvailability: {
    type: AptlyFieldType.Reference,
    label: i18n.t('info.booking.userAvailability'),
    defaultValue: [],
    renderValidate: () => Scope.crud(AptlyScopes.OrganizationMembers, 'R'),
    useReference: () => ({
      endpoint: useApiUrl(SlugLevel.Organization, 'users'),
      queryKey: '$fullName',
      labelKey: 'fullName',
      multiple: true,
      query: { as: 'array', select: '_id,fullName', limit: 5, sort: 'fullName' },
    }),
  },
  endAvailabilityAt: {
    type: AptlyFieldType.DateTime,
    defaultValue: null,
    label: i18n.t('info.booking.endAvailabilityAt'),
  },
  fee: {
    type: AptlyFieldType.Number,
    defaultValue: 0,
    label: i18n.t('paragraphs.userFee'),
  },
});
