import deleteResource from '../../../containers/Notification/deleteResource';
import i18n from '../../../libraries/i18n';
import { DeleteIconButton, TooltipIconButtonProps } from './Icons';
import { useCallback, MouseEvent } from 'react';

interface Props extends Partial<TooltipIconButtonProps> {
  endpoint?: string;
  _id: string;
  path?: string;
}

function DeleteIconEndpoint({
  endpoint,
  _id,
  onClick,
  title = i18n.t('actions.delete'),
  path = '',
  ...props
}: Props) {
  const handleOnClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      if (endpoint) {
        await deleteResource(`${endpoint}/${_id}${path}`, () => {
          if (typeof onClick === 'function') {
            onClick(e);
          }
        });
      } else if (typeof onClick === 'function') {
        onClick(e);
      }
    },
    [endpoint, _id, path, onClick]
  );

  return (
    <DeleteIconButton {...props} onClick={handleOnClick}>
      {title}
    </DeleteIconButton>
  );
}

export default DeleteIconEndpoint;
