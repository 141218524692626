import { useContext, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import Main from '../../components/Main';
import { ModalProvider } from '../../containers/Modal/ModalContext';
import { Order } from '../Order/Order.js';
import Unit from '../Unit/Unit';
import Sidebar from '../../components/Sidebar';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/Breadcrumbs';
import OrganizationHeader from '../Organization/OrganizationHeader';
import { ScopeProvider } from '../../libraries/scope/ScopeContext';
import ProjectHeader from './ProjectHeader';
import { OrganizationContext } from '../Organization/OrganizationContext';
import ErrorBoundary from '../../components/ErrorBoundary';
import { ProjectContext, ProjectProvider } from './ProjectContext';
import SidebarMenu from '../../containers/Sidebar/SidebarMenu';
import projectRoutes from './project.routes';
import SidebarRoutes from '../../containers/Sidebar/SidebarRoutes';
import AppProject from '../App/AppProject';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import UnitTemplateV2 from '../UnitTemplate/UnitTemplate';
import Page from '../Page/Page';

function Project() {
  const { slug, projectID } = useParams<{ slug: string; projectID: string }>();

  const endpoint = `organizations/${slug}/projects/${projectID}`;

  return (
    <ProjectProvider key={projectID} id={projectID!}>
      <ScopeProvider path={`${endpoint}/scope`}>
        <Content />
      </ScopeProvider>
    </ProjectProvider>
  );
}

function Content() {
  const org = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  const routes = useMemo(() => projectRoutes(org.data, project.data), [org, project]);

  return (
    <Routes>
      <Route path="enheter/:unitID/*" element={<Unit />} />
      <Route
        path="*"
        element={
          <ModalProvider>
            <Layout>
              <Header
                head={[
                  <Breadcrumbs key="breadcrumbs">
                    <BreadcrumbsItem>
                      <OrganizationHeader />
                    </BreadcrumbsItem>
                    <BreadcrumbsItem>
                      <ProjectHeader />
                    </BreadcrumbsItem>
                  </Breadcrumbs>,
                ]}
              />
              <Sidebar>
                <SidebarMenu level={SlugLevel.Project} routes={routes} injection="project" />
              </Sidebar>
              <Main>
                <ErrorBoundary>
                  <SidebarRoutes routes={routes}>
                    <Route path="tilvalg/:unitTemplateID" element={<UnitTemplateV2 />} />
                    <Route path="sider/:pageID" element={<Page />} />
                    <Route path="apps/:app/*" element={<AppProject />} />
                    <Route path="ordre/:order/*" element={<Order />} />
                  </SidebarRoutes>
                </ErrorBoundary>
              </Main>
            </Layout>
          </ModalProvider>
        }
      />
    </Routes>
  );
}

export default Project;
