import { useCallback, useContext, useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { AptlyAlgorithm, AptlyScopes } from '@aptly-as/types';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { algorithmEditFields, algorithmSchema } from './algorithm.schema';
import {
  dataGridLevelColumn,
  dataGridSearchNameColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import { ProjectContext } from '../Project/ProjectContext';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  DataGridEditAction,
} from '../../components/Search/search-data-grid/data-grid.actions';
import useScopeProps from '../../libraries/scope/useScopeProps';

const columnsFn = (project?: any): GridColDef[] => [
  dataGridSearchNameColumn(),
  {
    type: 'string',
    field: 'pipeline',
    flex: 1,
    headerName: i18n.t('singles.steps'),
    renderCell: (params) => params.value.map((x: any) => x.label).join(', '),
  },
  dataGridLevelColumn(),
  dataGridActionProps((params) =>
    !project || params.row.project ? (
      <DataGridActionWrapper>
        <DataGridEditAction params={params} />
        <DataGridDeleteAction params={params} />
      </DataGridActionWrapper>
    ) : null
  ),
];

export default function Algorithms() {
  const project = useContext(ProjectContext);
  const path = useApiUrl(SlugLevel.Project, 'algorithms');
  const scope = useScopeProps({
    project: AptlyScopes.ProjectAlgorithms,
    organization: AptlyScopes.OrganizationAlgorithms,
    base: AptlyScopes.Admin,
  });

  const schema = useMemo(() => algorithmSchema(), []);
  const isCellEditable = useCallback((x: any) => (project.data ? x.row.project : true), [project]);
  const buttonActions = useCallback(() => [<SearchCreateAction key="create" />], []);
  const columns = useMemo(() => columnsFn(project.data), [project]);
  const { setData } = project;

  const handleOnChange = useCallback(
    (algorithms: AptlyAlgorithm[] | null) => {
      if (project && algorithms) {
        if (setData) {
          setData({ algorithms });
        }
      }
    },
    [setData]
  );

  return (
    <Search
      path={path}
      patchPath={() => path}
      scope={scope}
      header={{ title: i18n.t('singles.calculations') }}
      post={{ title: i18n.t('singles.calculations') }}
      patch={{ title: (data) => String(data.name) }}
      schema={schema}
      fields={algorithmEditFields}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name' } }}
      buttonActions={buttonActions}
      onChange={handleOnChange}
      searchFields={[
        <SearchFieldText key={0} field="name" queryKey="name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns} isCellEditable={isCellEditable} />}
    </Search>
  );
}
