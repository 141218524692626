import { AptlyPeriod } from '@aptly-as/types';
import { Field } from '../../../deprecated/inputs';
import i18n from '../../../libraries/i18n';

export const crudFieldPeriod = (
  periods: AptlyPeriod[],
  data?: { period?: string | AptlyPeriod },
  extra: Partial<Field> = {}
): Field => {
  const options = periods.map((p) => ({ value: p._id || '', label: p.name }));
  const _id = data?.period && typeof data.period !== 'string' ? data?.period._id : null;
  let period = _id && periods.find((x) => x._id === _id);
  if (extra.forceSave && !data?.period) {
    period = periods[0];
  } else {
    options.push({ value: '', label: i18n.t('statuses.nothing') });
  }
  return {
    name: 'period',
    type: 'select',
    value: period ? period._id || period : null,
    label: period ? period.name : '',
    helpField: i18n.t('singles.deadline'),
    options,
    ...extra,
  };
};
