import dayjs from 'dayjs';
import { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { AptlyBooking } from '@aptly-as/types';
import { DeleteIconButton } from '../../components/actions/icons/Icons';
import deleteResource from '../../containers/Notification/deleteResource';
import useGetApiUrl from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { intlDateFormat, intlTimeFormat } from '../../libraries/intl';
import { BookingContext } from './BookingContext';
import Grid from '../../mui/Grid';
import Typography from '../../mui/Typography';
import { tightSpacing } from '../../utils/spacing';

const Header = styled(Typography)`
  font-weight: bold !important;
  text-decoration: underline;
`;

export default function BookingList() {
  const {
    state: { bookings, date },
    getTimes,
    getBookings,
  } = useContext(BookingContext);

  const handleDelete = useCallback(
    (booking: AptlyBooking) => {
      const date = dayjs(booking.from).toISOString();
      getBookings(date, date);
      getTimes(date);
    },
    [getTimes, getBookings]
  );

  return (
    <Grid container direction={'column'} spacing={tightSpacing}>
      <Grid item>
        <Header variant={'body2'}>{intlDateFormat(date.toDate())}</Header>
      </Grid>
      <Grid item>
        <Grid container direction={'column'} spacing={tightSpacing}>
          {bookings.map((booking) => (
            <Grid item key={booking._id}>
              <BookingListItem booking={booking} onDelete={handleDelete} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

type BookingListItemProps = {
  booking: AptlyBooking;
  onDelete: (booking: AptlyBooking) => void;
};

function BookingListItem({ booking, onDelete }: BookingListItemProps) {
  const path = useGetApiUrl('project', '/bookings/confirmed-times/' + booking._id);
  const handleDelete = useCallback(() => {
    deleteResource(path, () => onDelete(booking));
  }, [path, onDelete, booking]);

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <BookingListItemHeader variant="body2">
          {i18n.t('singles.unit')} {typeof booking.unit !== 'string' ? booking.unit.name : ''}
        </BookingListItemHeader>
        <Typography variant="body1">
          kl: {intlTimeFormat(booking.from)}- {intlTimeFormat(booking.to)}
        </Typography>
      </Grid>
      <Grid item>
        <DeleteIconButton onClick={handleDelete}>{i18n.t('actions.delete')}</DeleteIconButton>
      </Grid>
    </Grid>
  );
}

const BookingListItemHeader = styled(Typography)`
  font-weight: bold !important;
`;
