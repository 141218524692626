import AddIcon from '@mui/icons-material/Add';
import DrawOutlined from '@mui/icons-material/DrawOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import Crop from '@mui/icons-material/Crop';
import AttachMoney from '@mui/icons-material/AttachMoney';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DoneOutline from '@mui/icons-material/DoneOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Search from '@mui/icons-material/Search';
import StarBorder from '@mui/icons-material/StarBorder';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Sort from '@mui/icons-material/Sort';
import Send from '@mui/icons-material/Send';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { ReactNode, forwardRef, AnchorHTMLAttributes } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import i18n from '../../../libraries/i18n';

export interface TooltipIconButtonProps
  extends Omit<IconButtonProps, 'title'>,
    Pick<TooltipProps, 'title' | 'placement'> {
  tooltipProps?: TooltipProps;
  icon?: ReactNode;
}

export type TooltipIconButtonLinkProps = Partial<TooltipIconButtonProps> & NavLinkProps;
export type TooltipIconButtonHrefProps = Partial<TooltipIconButtonProps> &
  Omit<AnchorHTMLAttributes<HTMLButtonElement>, 'children' | 'title'>;

const TooltipIconButton = forwardRef<HTMLButtonElement, TooltipIconButtonProps>(function TooltipIconButton(
  {
    title,
    placement = 'top',
    tooltipProps,
    size = 'large',
    color = 'secondary',
    icon,
    children,
    ...iconButtonProps
  },
  ref
) {
  return (
    <MuiTooltip title={title || children} placement={placement} arrow disableInteractive {...tooltipProps}>
      <IconButton size={size} color={color} {...iconButtonProps} ref={ref}>
        {icon}
      </IconButton>
    </MuiTooltip>
  );
});

export const GoToIconButtonLink = (props: TooltipIconButtonLinkProps) => (
  <TooltipIconButton title={i18n.t('singles.goTo')} icon={<ArrowForward />} component={NavLink} {...props} />
);
export const AddIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.add')} icon={<AddIcon />} {...props} />
);
export const CopyIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.copy')} icon={<FileCopyOutlined />} {...props} />
);
export const CropIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.crop')} icon={<Crop />} {...props} />
);
export const DeleteIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.delete')} icon={<DeleteOutlinedIcon />} {...props} />
);
export const DoneIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.complete')} color="primary" icon={<DoneOutline />} {...props} />
);
export const SearchIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.search')} icon={<Search />} color="secondary" {...props} />
);
export const DownloadIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.download')} icon={<CloudDownloadOutlinedIcon />} {...props} />
);
export const SignIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton
    title={i18n.t('actions.startSigning')}
    color="primary"
    icon={<DrawOutlined />}
    {...props}
  />
);
export const DownloadIconButtonHref = (props: TooltipIconButtonHrefProps) => (
  <TooltipIconButton
    title={i18n.t('actions.download')}
    icon={<CloudDownloadOutlinedIcon />}
    component="a"
    {...props}
  />
);
export const EditIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.edit')} icon={<EditOutlinedIcon />} {...props} />
);
export const OpenInNewIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.openInWindow')} icon={<OpenInNew />} {...props} />
);
export const PreviewIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.openPreview')} icon={<VisibilityOutlinedIcon />} {...props} />
);
export const SaveIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.save')} icon={<SaveOutlinedIcon />} {...props} />
);
export const SendIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.send')} icon={<Send />} {...props} />
);
export const ShowMeMoreIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.showMeMore')} icon={<KeyboardArrowDown />} {...props} />
);
export const SortIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.sort')} icon={<Sort />} {...props} />
);
export const UploadIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.upload')} icon={<CloudUploadOutlinedIcon />} {...props} />
);
export const FavoriteIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.favorite')} icon={<StarBorder />} size="medium" {...props} />
);
export const PersonIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton
    title={i18n.t('actions.favorite')}
    icon={<PersonAddOutlinedIcon />}
    size="medium"
    {...props}
  />
);
export const OpenIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton
    title={i18n.t('actions.favorite')}
    icon={<ArrowDropDownIcon />}
    size="medium"
    {...props}
  />
);
export const SetPriceIconButton = (props: Partial<TooltipIconButtonProps>) => (
  <TooltipIconButton title={i18n.t('actions.setPrice')} icon={<AttachMoney />} size="medium" {...props} />
);
