import React, { ReactNode } from 'react';
import StatusText from '../../components/StatusText';
import { Param } from './unit-template.types';
import i18n from '../../libraries/i18n';
import DocumentLink from '../Document/DocumentLink';

function renderParams(params: Param[]) {
  return params.length > 0 ? (
    params.map((p) => {
      let value: ReactNode;

      switch (p.key) {
        case 'document':
          value = <DocumentLink key={p._id} token={p.downloadToken!} />;
          break;
        default:
          value = p.value;
      }

      return (
        <React.Fragment key={p._id}>
          {translateKey(p.key)}: {value}
          <br />
        </React.Fragment>
      );
    })
  ) : (
    <StatusText>{i18n.t('singles.NA')}</StatusText>
  );
}

function translateKey(key: string) {
  switch (key) {
    case 'document':
      return i18n.t('singles.document');
    case 'comment':
      return i18n.t('singles.comment');
    case 'ncs':
      return 'NCS-S';
    case 'image':
      return i18n.t('singles.image');
    default:
      return key;
  }
}

export default renderParams;
