import {
  AptlyDocument,
  AptlyDocumentAccess,
  AptlyDocumentType,
  AptlyFieldType,
  AptlyScopes,
} from '@aptly-as/types';
import { schemaBulkIds, schemaFile, schemaName } from '../../components/crud/schema/fields.schema';
import { IBulkEdit, ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import i18n from '../../libraries/i18n';
import Scope from '../../libraries/scope/Scope';
import { SelectOption } from '../../mui/Select';
import { documentAccessOptions, DocumentTypeOption, sortedDocumentTypeOptions } from './document.utils';

export const DOCUMENT_SELECT = '_id,_type,name,downloadToken,thirdPartyUploader,createdAt,expires,access';

export interface IDocumentSchema extends AptlyDocument, IBulkEdit {
  file?: File | null;
}

export const schemaDocumentType = <T extends IDocumentSchema>(
  options: DocumentTypeOption[] = sortedDocumentTypeOptions()
): ICrudField<T, AptlyDocumentType> => ({
  type: AptlyFieldType.Select,
  defaultValue: AptlyDocumentType.Document,
  label: i18n.t('singles.type'),
  options,
});

export const documentThirdPartyUploaderSchema = (): ICrudSchema<IDocumentSchema>['thirdPartyUploader'] => ({
  type: AptlyFieldType.Text,
  defaultValue: '',
  label: i18n.t('singles.source'),
});

export const documentFields: (keyof IDocumentSchema)[] = ['name', '_type', 'access', 'thirdPartyUploader'];
const documentSchema = (
  typeOptions?: DocumentTypeOption[],
  accessOptions?: SelectOption[]
): ICrudSchema<IDocumentSchema> => ({
  ids: schemaBulkIds(),
  name: schemaName({}),
  access: {
    type: AptlyFieldType.Select,
    defaultValue: AptlyDocumentAccess.User,
    label: i18n.t('singles.accessLevel'),
    options: accessOptions || documentAccessOptions(),
    renderValidate: () => Scope.crud(AptlyScopes.Admin),
  },
  _type: schemaDocumentType(typeOptions),
  file: schemaFile(),
  thirdPartyUploader: documentThirdPartyUploaderSchema(),
});

export default documentSchema;
