import { useCallback, useMemo } from 'react';
import { AptlyFieldType, AptlyOrganizationMemberPosition } from '@aptly-as/types';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import CrudForm, {
  CrudFormMarkdown,
  CrudFormSelect,
  CrudFormTextField,
} from '../../components/simple/CrudForm';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { organizationMemberPositionOptions } from '../../models/Organization/organization.utils';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Grid from '../../mui/Grid';

interface Props {
  onClose?: () => void;
}

interface Body {
  positions: AptlyOrganizationMemberPosition[];
  subject: string;
  text: string;
}

export default function AdminSendMessage({ onClose }: Props) {
  const path = useApiUrl(SlugLevel.Base, 'admin/messages/send');
  const data = useMemo(() => ({}), []);
  const schema = useMemo(() => {
    const _schema: ICrudSchema<Body> = {
      positions: {
        type: AptlyFieldType.Select,
        label: 'Posisjoner',
        defaultValue: [AptlyOrganizationMemberPosition.Owner],
        options: organizationMemberPositionOptions(),
        required: true,
        multiple: true,
      },
      subject: {
        type: AptlyFieldType.Text,
        label: 'Emne',
        defaultValue: '',
        required: true,
      },
      text: {
        type: AptlyFieldType.Markdown,
        label: 'Melding',
        defaultValue: '',
        required: true,
      },
    };
    return _schema;
  }, []);
  const handleOnEdit = useCallback(
    (data?: any) => {
      if (data && onClose) {
        onClose();
      }
    },
    [onClose]
  );
  return (
    <CrudForm path={path} data={data} schema={schema} onEdit={handleOnEdit}>
      <ModalTitle>Send melding til brukere</ModalTitle>
      <ModalContent>
        <Grid container flexDirection="column" gap={2}>
          <CrudFormSelect name="positions" />
          <CrudFormTextField name="subject" />
          <CrudFormMarkdown name="text" />
        </Grid>
      </ModalContent>
      <ModalActions>
        <ActionButtons onClose={onClose} />
      </ModalActions>
    </CrudForm>
  );
}
