import { Dispatch, SetStateAction } from 'react';
import { AptlyOrganization, AptlyOrganizationIntegrations } from '@aptly-as/types';
import { createModal } from '../../../containers/Modal/ModalContext';
import { OrganizationIntegration } from '../organization.types';
import { Field } from '../../../deprecated/inputs';
import i18n from '../../../libraries/i18n';
import SimpleCrud from '../../../components/SimpleCrud';
import Button from '../../../mui/Button';

export interface IIntegrationProps {
  integrations: AptlyOrganization['integrations'];
  setIntegrations: Dispatch<SetStateAction<AptlyOrganization['integrations']>>;
}

type IOnCreate = Dispatch<SetStateAction<AptlyOrganization['integrations']>>;
type IName = AptlyOrganization['integrations'][0]['integration'];

export const integrationFields = (type: IName, data?: AptlyOrganizationIntegrations): Field[] => [
  {
    type: 'text',
    name: 'integration',
    value: type,
    label: type,
    hidden: true,
  },
  {
    type: 'text',
    name: 'apiKey',
    value: data ? data.apiKey || '*******' : '',
    label: data ? data.apiKey || '*******' : '',
    helpField: i18n.t('singles.apiKey'),
  },
  {
    type: 'text',
    name: 'organization',
    value: data ? data.organization : '',
    label: data ? data.organization : '',
    helpField: i18n.t('singles.organization'),
  },
];

export const IntegrationButton = ({
  name,
  label,
  endpoint,
  onCreate,
}: {
  name: IName;
  endpoint: string;
  onCreate: (integration: OrganizationIntegration) => void;
  label: string;
}) => (
  <Button
    onClick={() => {
      createModal(
        <SimpleCrud
          title={i18n.t('singles.integrations') + label}
          endpoint={endpoint}
          fields={integrationFields(name)}
          onCreate={onCreate}
        />
      );
    }}
  >
    {i18n.t('actions.add')}
    {label}
  </Button>
);

export const integrationChange =
  (name: IName, integrations: AptlyOrganization['integrations'], setIntegrations: IOnCreate) =>
  (retOrg: any) => {
    const data = retOrg.integrations.find((x: any) => x.integration === name);
    const index = integrations.findIndex((x) => x.integration === name);
    integrations[index] = data as OrganizationIntegration;
    setIntegrations([...integrations]);
  };

export const integrationCreate =
  (_name: IName, setIntegrations: IOnCreate) => (integration: OrganizationIntegration) => {
    setIntegrations((s) => [...s, integration]);
  };
