import { v4 as uuid } from 'uuid';
import debounce from 'lodash.debounce';
import apiRequest from '../../libraries/fetch/apiRequest';
import handleError from '../../containers/Error/handleError';
import createError from '../../containers/Error/createError';
import React from 'react';

export type Query = {
  [key: string]: string;
};

export type SearchInterface<T = any> = {
  busy: boolean;
  query: (key: string, value: string) => any;
  hasQueried: boolean;
  results: T[];
  paginate: () => any;
  hasMore: boolean;
  outputFilter: (filter: (arg0: T) => boolean) => any;
  clear: () => any;
  resultSpec: Query;
  putRow: (index: number, response: T) => any;
  injectRow: (response: T | T[]) => any;
  deleteRow: (index: number) => any;
  refresh: () => any;
};

type Props<T = any> = {
  endpoint: string;
  children: (props: SearchInterface<T>) => React.ReactNode;
  limit?: number;
  query?: Query;
  onQueryComplete?: () => any;
  initSearch?: boolean;
};

type State<T = any> = {
  query: Query;
  hasQueried: boolean;
  busy: boolean;
  results: T[];
  hasMore: boolean;
  operationID: string;
};

/**
 * @deprecated use useSearch instead
 */
class Search<T = any> extends React.Component<Props<T>, State<T>> {
  state = {
    query: this.props.query || {},
    hasQueried: false,
    busy: false,
    results: [],
    hasMore: true,
    operationID: uuid(),
  };

  requestCounter = 0;

  constructQuery = (key: string, value: string) => {
    let { query } = this.state;

    query = {
      ...query,
      [key]: value,
    };

    for (const k in query) {
      if (query.hasOwnProperty(k) && !query[k]) {
        delete query[k];
      }
    }

    this.setState(
      {
        hasQueried: Object.keys(query).length > 0,
        query,
      },
      () => this.search(false)
    );
  };

  search = debounce(async (paginate?: boolean) => {
    let { endpoint, limit, onQueryComplete } = this.props;
    const { query, busy, results } = this.state;

    limit = limit || 20;
    const currentRequest = this.requestCounter + 1;

    if (busy) {
      return false;
    }

    this.setState({
      busy: true,
    });

    if (paginate) {
      query['skip'] = results.length.toString();
    } else {
      query['skip'] = '0';
    }

    try {
      const response: T[] = await apiRequest(endpoint, {
        data: query,
      });

      if (!Array.isArray(response)) throw createError('Search response not typeof array');

      if (currentRequest <= this.requestCounter) {
        return;
      }

      this.setState({
        busy: false,
        results: paginate ? [...results, ...response] : response,
        hasMore: response.length === limit,
      });

      this.requestCounter = currentRequest;

      if (typeof onQueryComplete === 'function') {
        onQueryComplete();
      }
    } catch (e) {
      handleError(e);
      this.setState({ busy: false, hasQueried: true, hasMore: false });
    }
  }, 500);

  paginate = () => {
    this.search(true);
  };

  outputFilter = (filter: (props: T) => boolean) => {
    this.setState({
      results: this.state.results.filter(filter),
    });
  };

  clear = () => {
    this.setState(
      {
        query: {},
        hasQueried: false,
        operationID: uuid(),
      },
      this.search as any
    );
  };

  createResultSpec = (): Query => {
    const { query, results } = this.state;

    return {
      ...query,
      skip: '0',
      limit: results.length.toString(),
    };
  };

  putRow = (index: number, response: T) => {
    if (index === -1) {
      console.warn('putRow got index -1 with response: ', response);
      return;
    }

    const newResult = [...this.state.results];
    // @ts-ignore
    newResult[index] = { ...(newResult[index] || []), ...response } as any;

    this.setState({
      results: newResult,
    });
  };

  injectRow = (response: T | T[], push?: boolean) => {
    const { results } = this.state;

    const insert = Array.isArray(response) ? response : [response];
    this.setState({
      results: push ? [...results, ...insert] : [...insert, ...results],
    });
  };

  deleteRow = (index: number) => {
    const { results } = this.state;

    this.setState({
      results: results.filter((_, k) => k !== index),
    });
  };

  refresh = () => {
    this.search(false);
  };

  componentDidMount() {
    const { initSearch } = this.props;
    if (initSearch) {
      this.refresh();
    }
  }

  render() {
    const { children } = this.props;
    const { busy, hasQueried, results, hasMore } = this.state;
    const { outputFilter, constructQuery, paginate, clear, putRow, injectRow, deleteRow, refresh } = this;

    return children({
      busy,
      query: constructQuery,
      hasQueried,
      results,
      hasMore,
      paginate,
      outputFilter,
      clear,
      resultSpec: this.createResultSpec(),
      putRow,
      injectRow,
      deleteRow,
      refresh,
    });
  }
}

export default Search;
