import { useCallback, useState } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Select, { SelectMultiple, SelectOption } from '../../../mui/Select';

interface DataGridSelectEditProps {
  params: GridRenderEditCellParams;
  options: SelectOption[];
}

export default function DataGridSelectEdit({ params, options }: DataGridSelectEditProps) {
  const { id, api, field, value } = params;
  const [open, setOpen] = useState(true);

  const handleOnChange = useCallback(
    (e: any) => {
      const value = e.target.value;
      api.setEditCellValue({ id, field, value });
      api.stopCellEditMode({ id, field });
    },
    [api, id, field]
  );

  const handleOnClose = useCallback(() => {
    setOpen(false);
    api.startCellEditMode({ id, field });
  }, [api, id, field]);

  return (
    <Grid container alignItems="center" style={{ paddingLeft: '10px' }}>
      <Select
        variant="standard"
        value={value}
        onChange={handleOnChange}
        autoFocus
        fullWidth
        options={options}
        SelectProps={{
          open,
          onClose: handleOnClose,
        }}
      />
    </Grid>
  );
}

export function DataGridSelectMultipleEdit({ params, options }: DataGridSelectEditProps) {
  const { id, api, field, value } = params;
  const [open, setOpen] = useState(true);
  const [state, setState] = useState<string[]>((value as string[]) || []);

  const handleOnClose = useCallback(() => {
    api.setEditCellValue({ id, field, value: state });
    api.stopCellEditMode({ id, field });
    setOpen(false);
  }, [api, id, field, state]);

  return (
    <Grid container alignItems="center" style={{ paddingLeft: '10px' }}>
      <SelectMultiple
        variant="standard"
        label=""
        value={state}
        onChange={setState}
        autoFocus
        fullWidth
        options={options}
        onClose={handleOnClose}
        MenuProps={{
          open,
          onClose: handleOnClose,
        }}
      />
    </Grid>
  );
}
