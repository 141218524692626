import { AptlyAlgorithm, AptlyFieldType, AptlyMediaSrc, AptlyScopes } from '@aptly-as/types';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import { acceptImages } from '../../../libraries/react-dropzone/drop-zone.utils';
import Scope from '../../../libraries/scope/Scope';
import { ICrudField, ICrudFieldData, ICrudFieldFile, ICrudFieldReference } from '../utils/crud.utils';
import { preValueToId } from './schema.utils';

export const schemaBulkIds = <T extends object>(): ICrudField<T, string[] | undefined> => ({
  type: AptlyFieldType.BulkIds,
  defaultValue: undefined,
  label: '',
});

export const schemaName = <T extends object>(
  p: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string> => ({
  type: AptlyFieldType.Text,
  defaultValue: '',
  label: i18n.t('singles.name'),
  required: true,
  ...p,
});

export const schemaDescription = <T extends object>(
  p: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string> => ({
  type: AptlyFieldType.Markdown,
  defaultValue: '',
  label: i18n.t('singles.description'),
  ...p,
});

export const schemaEmail = <T extends object>({
  label = i18n.t('singles.email'),
  required = true,
  ...rest
}: Partial<ICrudField<T, string>> = {}): ICrudField<T, string> => ({
  type: AptlyFieldType.Email,
  defaultValue: '',
  autoComplete: 'email',
  label,
  required,
  ...rest,
});

export const schemaPhone = <T extends object>(
  p: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string> => ({
  type: AptlyFieldType.Text,
  defaultValue: '',
  label: i18n.t('singles.phoneNumber'),
  autoComplete: 'tel',
  ...p,
});

export const schemaMediaImage = <T extends object>(
  p: Partial<ICrudField<T, AptlyMediaSrc | null>> = {}
): ICrudField<T, AptlyMediaSrc | null> => ({
  type: AptlyFieldType.Image,
  defaultValue: null,
  label: i18n.t('singles.image'),
  ...p,
});

export const schemaMediaLogo = <T extends object>(
  p: Partial<ICrudField<T, AptlyMediaSrc | null>> = {}
): ICrudField<T, AptlyMediaSrc | null> => ({
  type: AptlyFieldType.Media,
  defaultValue: null,
  label: i18n.t('singles.logo'),
  image: {
    width: 320,
    accept: acceptImages,
    opts: {
      disableFullWidth: true,
      style: { maxHeight: '50px', maxWidth: '250px', width: 'auto' },
      transforms: 'c_fit',
    },
  },
  ...p,
});

export const schemaMediaIcon = <T extends object>(
  p: Partial<ICrudField<T, AptlyMediaSrc | null>> = {}
): ICrudField<T, AptlyMediaSrc | null> => ({
  type: AptlyFieldType.Media,
  defaultValue: null,
  label: i18n.t('singles.icon'),
  image: {
    width: 160,
    accept: acceptImages,
    opts: {
      disableFullWidth: true,
      style: { maxHeight: '160px', maxWidth: '160px', width: 'auto' },
      transforms: 'c_fit',
    },
  },
  ...p,
});

export const schemaFile = <T extends object>(
  accept?: ICrudFieldFile['accept'],
  props: Partial<ICrudField<T, File | null>> = {}
): ICrudField<T, File | null> => ({
  type: AptlyFieldType.File,
  defaultValue: null,
  label: i18n.t('singles.file'),
  file: { accept },
  ...props,
});

export const tagsSchema = <T extends object>(
  p: Partial<ICrudField<T, string[]>>
): ICrudField<T, string[]> => ({
  type: AptlyFieldType.Reference,
  defaultValue: [],
  label: i18n.t('singles.tags'),
  useReference: () => ({
    multiple: true,
    endpoint: 'api/v1/tags',
    queryKey: '$name',
    labelKey: 'name',
    query: { select: '_id,name', sort: 'name', as: 'array', limit: 5 },
  }),
  ...p,
});

export const schemaColor = <T extends object>(p: Partial<ICrudField<T, string>>): ICrudField<T, string> => ({
  label: i18n.t('singles.NCSColorCode'),
  defaultValue: '',
  ...p,
  type: AptlyFieldType.Color,
});

export const schemaReferenceName = <T extends object>(
  path: string,
  props: Omit<ICrudFieldData<T>, 'type' | 'useReference'>,
  useReferenceProps: Partial<ICrudFieldReference> = {},
  level: SlugLevel = SlugLevel.Project
): ICrudField<T, string | string[] | null | any> => ({
  type: AptlyFieldType.Reference,
  defaultValue: null,
  ...props,
  useReference: () => ({
    endpoint: useApiUrl(level, path),
    labelKey: 'name',
    queryKey: '$name',
    query: { as: 'array', select: '_id,name', limit: 5, archived: false, sort: 'name' },
    ...useReferenceProps,
  }),
});

export const schemaProducer = <T extends object>(
  p: Partial<ICrudField<T, string | undefined | null>> = {},
  useRefProps: Partial<ICrudFieldReference> = {}
): ICrudField<T, string | undefined | null> => ({
  type: AptlyFieldType.Reference,
  defaultValue: null,
  label: i18n.t('singles.producer'),
  useReference: () => ({
    endpoint: 'api/v1/producers',
    queryKey: '$name',
    labelKey: 'name',
    query: { select: '_id,name', sort: '-featured,name', as: 'array', limit: 5, archived: false },
    ...useRefProps,
  }),
  ...p,
});

export const schemaSelectAlgorithm = <T extends object>(
  algorithms: AptlyAlgorithm[],
  props: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string | null> => ({
  type: AptlyFieldType.Select,
  defaultValue: null,
  options: [
    { value: null, label: i18n.t('statuses.nothing') },
    ...algorithms.map((x) => ({ value: x._id, label: x.name })),
  ],
  label: i18n.t('singles.calculation'),
  preValue: preValueToId,
  renderValidate: () => Scope.crud([AptlyScopes.OrganizationAlgorithms, AptlyScopes.ProjectAlgorithms], 'R'),
  ...props,
});
