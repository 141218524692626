import { AptlyEmailType, AptlyScopes, AptlyUser } from '@aptly-as/types';
import { useContext } from 'react';
import { createModal } from '../../../containers/Modal/ModalContext';
import { Field } from '../../../deprecated/inputs';
import useGetApiUrl from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import { matchId } from '../../../libraries/mongoose';
import Scope from '../../../libraries/scope/Scope';
import { notificationOptions } from '../../../models/Project/project.utils';
import { ProjectContext, useProject } from '../../../models/Project/ProjectContext';
import { useUser } from '../../../models/User/UserContext';
import { NotificationButtonWithIcon } from '../../actions/buttons/Buttons';
import SimpleCrud from '../../SimpleCrud';

interface ContactGroups {
  booking: string;
  order: string;
  support: string;
  note: string;
}

const groups = (): ContactGroups => ({
  booking: i18n.t('singles.booking'),
  order: i18n.t('singles.orders'),
  support: i18n.t('singles.support'),
  note: i18n.t('singles.notes'),
});

interface CrudContactProps {
  endpoint: string;
  value?: any;
  data?: any;
  onSubmit?: (data?: any) => void;
  onDestroy?: (_id: string | any) => void;
  disableUserSearch?: boolean;
  defaultExpandGroup?: keyof ContactGroups;
  actionLabel?: string;
  onClose?: () => void;
}

export default function CrudContact({
  endpoint,
  data,
  onClose,
  onSubmit,
  actionLabel,
  onDestroy,
  disableUserSearch,
  defaultExpandGroup,
}: CrudContactProps) {
  const match = endpoint.match(/organizations\/([^/]*)/);
  const userEndpoint = match ? `organizations/${match[1]}/users/search` : 'users/search';
  const user = data && (data.user as AptlyUser);
  const fields: Field[] = [
    {
      type: 'reference',
      name: 'user',
      value: user && user._id ? user._id : null,
      label: user && user.fullName ? user.fullName : '',
      reference: {
        endpoint: userEndpoint,
        queryKey: 'fullName',
        labelKey: 'fullName',
      },
      required: true,
      helpField: i18n.t('singles.user'),
      disabled: disableUserSearch,
    },
    {
      name: 'types',
      type: 'select-multiple',
      helpField: i18n.t('paragraphs.notifyOnEmailTemplates'),
      value: data?.types ? data.types : [],
      label: '',
      options: notificationOptions(),
    },
    {
      name: 'order',
      type: 'switch',
      helpField: i18n.t('singles.all'),
      value: data?.order || false,
      label: '',
      group: groups().order,
    },
    {
      name: AptlyEmailType.OrderCreated,
      type: 'switch',
      helpField: i18n.t('statuses.created'),
      value: data ? Boolean(data['order-created']) : false,
      label: '',
      group: groups().order,
      hideIfSet: 'order',
    },
    {
      name: AptlyEmailType.OrderSigned,
      type: 'switch',
      helpField: i18n.t('statuses.signed'),
      value: data ? Boolean(data['order-signed']) : false,
      label: '',
      group: groups().order,
      hideIfSet: 'order',
    },
    {
      name: AptlyEmailType.OrderPayed,
      type: 'switch',
      helpField: i18n.t('statuses.paid'),
      value: data ? Boolean(data[AptlyEmailType.OrderPayed]) : false,
      label: '',
      group: groups().order,
      hideIfSet: 'order',
    },
    {
      name: 'order-fileReceived',
      type: 'switch',
      helpField: i18n.t('singles.signedReceipt'),
      value: data ? Boolean(data['order-fileReceived']) : false,
      label: '',
      group: groups().order,
      hideIfSet: 'order',
    },
    {
      type: 'switch',
      name: 'inquiry',
      value: data ? data.inquiry : false,
      label: '',
      helpField: i18n.t('paragraphs.newMessages'),
      group: groups().support,
      hidden: !Scope.crud(AptlyScopes.ProjectInquiry),
    },
    {
      type: 'switch',
      name: 'complaint',
      value: data ? data.complaint : false,
      label: '',
      helpField: i18n.t('paragraphs.newComplaints'),
      group: groups().support,
      hidden: !Scope.crud(AptlyScopes.ProjectInquiryComplaint),
    },
    {
      name: 'booking',
      type: 'switch',
      helpField: i18n.t('paragraphs.newBookings'),
      value: data ? data.booking : false,
      label: '',
      group: groups().booking,
      hidden: !Scope.crud(AptlyScopes.ProjectBooking),
    },
    {
      name: 'unit-note-reminder',
      type: 'switch',
      helpField: `${i18n.t('singles.all')} ${i18n.t('singles.reminders')}`,
      value: data ? Boolean(data['unit-note-reminder']) : false,
      label: '',
      group: groups().note,
    },
  ];

  return (
    <SimpleCrud<any>
      id={data && data._id}
      title={data ? i18n.t('actions.changeUserNotifications') : i18n.t('actions.addUser')}
      endpoint={endpoint}
      onClose={onClose}
      onCreate={onSubmit}
      onDestroy={onDestroy}
      fields={fields}
      actionLabel={actionLabel}
      defaultExpandGroup={defaultExpandGroup ? groups()[defaultExpandGroup] : undefined}
      deleteAction={Boolean(data)}
    />
  );
}

export function CrudContactNotifyButton({
  defaultExpandGroup,
}: Pick<CrudContactProps, 'defaultExpandGroup'>) {
  const { setField } = useContext(ProjectContext);
  const endpoint = useGetApiUrl('project') + '/notify';
  const user = useUser();
  const project = useProject();
  const projectNotifies = project?.notify || [];
  const projectNotify = projectNotifies.find((x) => matchId(x.user, user._id)) || {
    _id: '',
    types: [],
    user: user,
    booking: false,
    inquiry: false,
    complaint: false,
  };

  return (
    <NotificationButtonWithIcon
      onClick={() =>
        createModal(
          <CrudContact
            onSubmit={(project) => project && setField('notify', true)(project.notify)}
            actionLabel={i18n.t('actions.add')}
            endpoint={endpoint}
            data={projectNotify}
            disableUserSearch
            defaultExpandGroup={defaultExpandGroup}
          />
        )
      }
    />
  );
}
