import { AptlyScopes } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import useScopeLevel from '../../libraries/scope/useScopeLevel';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { dataGridLevelColumn } from '../../components/Search/search-data-grid/data-grid.fields';
import { emailSchema, emailTypeLabel } from './email.schema';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  DataGridEditAction,
} from '../../components/Search/search-data-grid/data-grid.actions';
import useScopeProps from '../../libraries/scope/useScopeProps';
import EmailPreview from './EmailPreview';

const emailColumns = (): GridColDef[] => [
  {
    field: 'type',
    headerName: i18n.t('singles.type'),
    flex: 1,
    renderCell: (params) => emailTypeLabel(params.value),
  },
  {
    field: 'subject',
    headerName: i18n.t('statuses.emailSubject'),
    minWidth: 400,
  },
  {
    field: 'actionLabel',
    headerName: i18n.t('actions.confirmLabel'),
    minWidth: 300,
  },
  dataGridLevelColumn(),
  dataGridActionProps((params) => (
    <DataGridActionWrapper>
      <DataGridEditAction params={params} checkIfDisabledInProject />
      <EmailPreview email={params.row} title={params.row.subject} />
      <DataGridDeleteAction params={params} checkIfDisabledInProject />
    </DataGridActionWrapper>
  )),
];

export default function Emails() {
  const path = useApiUrl(SlugLevel.Project, 'emails');
  const schema = useMemo(() => emailSchema(), []);
  const columns = useMemo(() => emailColumns(), []);
  const scope = useScopeProps({
    project: AptlyScopes.ProjectEmails,
    base: AptlyScopes.Admin,
  });

  return (
    <Search
      path={path}
      patchPath={toSearchPatchPath('emails')}
      level={useScopeLevel()}
      scope={scope}
      header={{ title: i18n.t('singles.emails') }}
      post={{ title: i18n.t('singles.emails') }}
      patch={{
        title: (data) => String(data.type),
        fields: ['type', 'subject', 'content', 'actionLabel'],
      }}
      schema={schema}
      fields={['type']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name', archived: false, level: 'project' } }}
      buttonActions={() => [<SearchCreateAction key="create" />]}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns} />}
    </Search>
  );
}
