import { Dispatch, SetStateAction, useMemo, useState } from 'react';

export default function useDotState<T>(obj: any, dotKey: string): [T, Dispatch<SetStateAction<T>>] {
  const value = useDotValue<T>(obj, dotKey);
  return useState<T>(value);
}

export function useDotValue<T>(obj: any, dotKey: string) {
  return useMemo(
    () => getDotValue(obj, dotKey.split('.').filter(Boolean) as (keyof T)[]) as T,
    [obj, dotKey]
  );
}

function getDotValue<T, K extends keyof T, V extends any>(data: T, arr: K[]): V {
  const key = arr[0];

  if (key && data[key]) {
    if (typeof data[key] === 'object') {
      if (arr.length === 1) return data[key] as V;
      return getDotValue(data[key], arr.slice(1) as any) as V;
    }
  }
  return data[key] as V;
}
