import ListAltIcon from '@mui/icons-material/ListAlt';
import { SyntheticEvent, useCallback, useContext, useMemo, useState } from 'react';
import {
  AptlyUnitTemplateCategorySection,
  AptlyUnitTemplateCategorySectionAssortment,
  AptlyUnitTemplateCategorySectionPackage,
  AptlyUnitTemplateCategorySectionProduct,
} from '@aptly-as/types';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { AddButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { EditIconButton, SaveIconButton } from '../../components/actions/icons/Icons';
import { createModal } from '../../containers/Modal/ModalContext';
import { ICheckboxProps } from '../../mui/Input';
import { SelectOption } from '../../mui/Select';
import { UnitTemplateContext } from './utils/UnitTemplateContext';
import simpleRequest from '../../libraries/fetch/simpleRequest';
import { unitTemplateAssortment } from './utils/unit-template.state';
import i18n from '../../libraries/i18n';

interface AssortmentState {
  active: boolean;
  assortment: Partial<AptlyUnitTemplateCategorySectionAssortment> | null;
  products: Set<string>;
}

export interface AssortmentReturn {
  state: AssortmentState;
  toggle: () => Promise<void>;
  toggleProduct: (
    product: AptlyUnitTemplateCategorySectionProduct
  ) => (e: SyntheticEvent, checked: boolean) => void;
  checkboxProps: (product: AptlyUnitTemplateCategorySectionProduct) => ICheckboxProps | undefined;
  options: SelectOption[];
}

export function usePartOfAssortment(
  endpoint: string,
  section: AptlyUnitTemplateCategorySection,
  sectionPackage?: AptlyUnitTemplateCategorySectionPackage
): AssortmentReturn {
  const unitTemplate = useContext(UnitTemplateContext);
  const [state, setState] = useState<AssortmentState>({
    active: false,
    assortment: null,
    products: new Set(),
  });

  const assortments = useMemo(() => {
    if (!section.assortments) return [];
    if (!sectionPackage) return section.assortments.filter((x) => !x.package);
    return section.assortments.filter((x) => x.package === sectionPackage._id);
  }, [section.assortments, sectionPackage]);

  const options = useMemo(
    () => COLORS.filter((x) => !assortments.some((y) => y.color === x.value)),
    [assortments]
  );

  const spawnSelection = useCallback(() => {
    const handleOnSelect = (
      assortment: Partial<AptlyUnitTemplateCategorySectionAssortment>,
      products: string[]
    ) => {
      setState({
        active: true,
        assortment,
        products: new Set(products),
      });
    };

    if (assortments.length === 0) {
      const color = options[0];
      return setState({
        active: true,
        assortment: { color: color.value, package: sectionPackage?._id || null },
        products: new Set([]),
      });
    }

    createModal(
      <Assortments
        sectionPackage={sectionPackage}
        assortments={assortments}
        options={options}
        section={section}
        onSelect={handleOnSelect}
      />
    );
  }, [section, sectionPackage, assortments, options]);

  const toggle = useCallback(async () => {
    if ((state.active && state.products.size > 0) || state.assortment) {
      const products = [...state.products];
      let simpleEndpoint = `${endpoint}/assortments`;
      if (state.assortment?._id) {
        simpleEndpoint += `/${state.assortment?._id}`;
      } else if (products.length === 0) {
        return setState((x) => ({ ...x, assortment: null, active: false }));
      }
      if (products.length === 0) {
        await simpleRequest<AptlyUnitTemplateCategorySectionAssortment>({
          endpoint: simpleEndpoint,
          method: 'DELETE',
          onRequestDone: (assortment) => {
            unitTemplate.setState(unitTemplateAssortment(section, assortment, products, true));
            setState((x) => ({ ...x, assortment: null, active: !state.active }));
          },
        });
      } else {
        await simpleRequest<AptlyUnitTemplateCategorySectionAssortment>({
          endpoint: simpleEndpoint,
          method: state.assortment?._id ? 'PATCH' : 'POST',
          data: { ...state.assortment, products },
          onRequestDone: (assortment) => {
            unitTemplate.setState(unitTemplateAssortment(section, assortment, products));
            setState({
              assortment: null,
              active: !state.active,
              products: new Set(),
            });
          },
        });
      }
    } else {
      spawnSelection();
    }
  }, [unitTemplate, section, endpoint, state, spawnSelection]);

  const toggleProduct = useCallback(
    (product: AptlyUnitTemplateCategorySectionProduct) => (_e: SyntheticEvent, checked: boolean) => {
      setState((s) => {
        if (checked) {
          s.products.add(product._id);
        } else {
          s.products.delete(product._id);
        }
        return { ...s, products: new Set([...s.products]) };
      });
    },
    []
  );

  const checkboxProps = useCallback(
    (product: AptlyUnitTemplateCategorySectionProduct) => {
      if (!state.active) return undefined;
      return {
        checked: state.products.has(product._id),
        onChange: toggleProduct(product),
      } as ICheckboxProps;
    },
    [state, toggleProduct]
  );

  return useMemo(
    () => ({
      state,
      toggle,
      toggleProduct,
      checkboxProps,
      options,
    }),
    [state, toggle, toggleProduct, options]
  );
}

export function AssortmentToggleButton({ assortment }: { assortment: AssortmentReturn }) {
  if (assortment.state.active) {
    return (
      <SaveIconButton
        onClick={assortment.toggle}
        title={`${i18n.t('actions.save')} ${i18n.t('singles.assortment')}`}
      />
    );
  }
  return (
    <EditIconButton onClick={assortment.toggle} title={i18n.t('singles.assortment')} icon={<ListAltIcon />} />
  );
}

const COLORS: SelectOption[] = [
  { value: '#E6B8Af', label: 'Rød' },
  { value: '#D9EAD3', label: 'Grønn' },
  { value: '#C9DAF8', label: 'Blå' },
  { value: '#F4CCCC', label: 'Lyserød' },
  { value: '#D0E0E3', label: 'Cyan' },
  { value: '#D9D2E9', label: 'Lilla' },
  { value: '#FCE5CD', label: 'Orange' },
  { value: '#CFE2F3', label: 'Lyseblå' },
  { value: '#EAD1DC', label: 'Rosa' },
  { value: '#FFF2CC', label: 'Gul' },
  { value: '#efefef', label: 'Grå' },
  { value: '#dd7e6b', label: 'Rød 2' },
  { value: '#b6d7a8', label: 'Grønn 2' },
  { value: '#9fc5e8', label: 'Blå 2' },
  { value: '#ea9999', label: 'Lyserød 2' },
  { value: '#a2c4c9', label: 'Cyan 2' },
  { value: '#b4a7d6', label: 'Lilla 2' },
  { value: '#f9cb9c', label: 'Orange 2' },
  { value: '#a4c2f4', label: 'Lyseblå 2' },
  { value: '#d5a6bd', label: 'Rosa 2' },
  { value: '#ffe599', label: 'Gul 2' },
  { value: '#cccccc', label: 'Grå 2' },
  { value: '#93c47d', label: 'Grønn 3' },
  { value: '#6fa8dc', label: 'Blå 3' },
  { value: '#76a5af', label: 'Cyan 3' },
  { value: '#f6b26b', label: 'Orange 3' },
  { value: '#6d9eeb', label: 'Lyseblå 3' },
  { value: '#c27ba0', label: 'Rosa 3' },
  { value: '#ffe599', label: 'Gul 3' },
  { value: '#e06666', label: 'Lyserød 3' },
  { value: '#8e7cc3', label: 'Lilla 3' },
  { value: '#cc4125', label: 'Rød 3' },
  { value: '#999999', label: 'Grå 3' },
];

interface AssortmentsProps {
  section: AptlyUnitTemplateCategorySection;
  assortments: AptlyUnitTemplateCategorySectionAssortment[];
  onSelect: (assortment: Partial<AptlyUnitTemplateCategorySectionAssortment>, products: string[]) => void;
  onClose?: () => void;
  sectionPackage?: AptlyUnitTemplateCategorySectionPackage;
  options: SelectOption[];
}

function Assortments({ section, sectionPackage, assortments, options, onSelect, onClose }: AssortmentsProps) {
  const [color] = useState<SelectOption>(options[0]);

  const onAssortmentSelect = useCallback(
    (assortment: AptlyUnitTemplateCategorySectionAssortment) => {
      const products = section.products.filter((x) => x.partOfAssortment === assortment._id);
      onSelect(
        assortment,
        products.map((x) => x._id)
      );
      onClose!();
    },
    [section, onSelect, onClose]
  );

  const handleOnSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      onSelect({ color: color.value, package: sectionPackage?._id || null }, []);
      onClose!();
    },
    [onSelect, sectionPackage, color, onClose]
  );

  return (
    <div>
      <DialogTitle>Sortiment</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Sortiment er et nytt system for å erstatte varianter. Produkter i et sortiment vil fungere som
          varianter i produkter, med alle mulighetene som et hovedprodukt har i dag.
        </Typography>
        <Typography variant="body2">
          Velg fra eksisterende sortiment for å redigere, eller trykk Nytt sortiment +
        </Typography>
        <br />
        {assortments.length > 0 && (
          <List subheader={<Typography variant="subtitle1">Eksisterende sortiment</Typography>}>
            {assortments.map((assortment) => (
              <Assortment
                key={assortment._id}
                assortment={assortment}
                onSelect={onAssortmentSelect}
                products={section.products.filter((x) => x.partOfAssortment === assortment._id)}
              />
            ))}
          </List>
        )}
        <br />
        {color ? (
          <Grid container component="form" onSubmit={handleOnSubmit} spacing={1} alignItems="center">
            <Grid item xs={12}>
              <AddButtonWithIcon type="submit" fullWidth>
                Nytt sortiment
              </AddButtonWithIcon>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body2">Sortiment støtter ikke flere farger enn {COLORS.length}.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Button onClick={onClose}>{i18n.t('actions.close')}</Button>
        </Grid>
      </DialogActions>
    </div>
  );
}

interface AssortmentProps {
  assortment: AptlyUnitTemplateCategorySectionAssortment;
  onSelect: (assortment: AptlyUnitTemplateCategorySectionAssortment) => void;
  products: AptlyUnitTemplateCategorySectionProduct[];
}

function Assortment({ assortment, onSelect, products }: AssortmentProps) {
  const handleOnClick = useCallback(() => {
    onSelect(assortment);
  }, [onSelect, assortment]);
  const option = useMemo(() => COLORS.find((x) => x.value === assortment.color), [assortment.color]);
  const primaryText = `${option ? option.label : assortment.color} x ${products.length}`;
  return (
    <ListItemButton onClick={handleOnClick}>
      <ListItemIcon>
        <ColorLensIcon htmlColor={assortment.color} />
      </ListItemIcon>
      <ListItemText primary={primaryText} />
    </ListItemButton>
  );
}
