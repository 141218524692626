import { BaseButtonWithIconProps, UploadButtonWithIcon } from '../../actions/buttons/Buttons';
import useSimplePost, { IUsePropsCrudProps } from '../../simple/useSimplePost';
import { ICrudSchema } from '../../crud/utils/crud.utils';
import i18n from '../../../libraries/i18n';
import { useCallback, useContext } from 'react';
import { SearchCrudContext } from '../search.utils';

interface Props extends Partial<IUsePropsCrudProps<any>> {
  path: string;
  schema: ICrudSchema<any>;
  buttonProps?: Partial<BaseButtonWithIconProps>;
  disableUnshift?: boolean;
}

export function useSearchImportAction({
  title = i18n.t('actions.upload'),
  path,
  schema,
  fields = Object.keys(schema),
  disableUnshift,
  ...rest
}: Omit<Props, 'buttonProps'>) {
  const { actions } = useContext(SearchCrudContext);

  const handleOnImport = useSimplePost(
    path,
    schema,
    {
      title,
      fields,
      ...rest,
      useFieldsProps: { reachOptions: { type: 'multipart/form-data', fileKeys: ['file'] } },
    },
    {
      width: 'sm',
    }
  );

  return useCallback(() => {
    if (disableUnshift) {
      return handleOnImport(actions.refresh);
    }
    return handleOnImport(actions.unshift);
  }, [disableUnshift, handleOnImport, actions]);
}

export default function SearchImportAction({ buttonProps, ...props }: Props) {
  const { scope } = useContext(SearchCrudContext);

  const handleOnClick = useSearchImportAction(props);

  if (!scope.create) return null;
  return <UploadButtonWithIcon onClick={handleOnClick} title={props.title} {...buttonProps} />;
}
