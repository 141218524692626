import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '../../mui/Typography';
import Search, { Query } from './Search';
import Section from '../../components/Section';
import Table, { TableBody, TableHead } from '../../mui/Table';
import EmptyState from '../../containers/Error/EmptyState';
import Grid from '../../mui/Grid';
import { rawSpacing, tightSpacing } from '../../utils/spacing';
import Paper from '../../mui/Paper';
import { ButtonWithIcon } from '../../mui/Button';
import PageOverlay from '../../components/PageOverlay';
import { LinearProgresser } from '../../mui/Progresser';
import { PositionParent } from '../../components/Position';
import Centered from '../../components/Centered';
import i18n from '../../libraries/i18n';
import SubMenu from '../../components/SubMenu';

const InfiniteScrollFix: any = InfiniteScroll;

export interface SearchViewComposerSelectOption<T, S extends boolean = true> {
  label: string;
  onClick: S extends true
    ? (
        injectRow: (response: T) => any,
        refresh: () => any,
        putRow: (index: number, response: T) => any
      ) => void | Promise<void>
    : () => Promise<void> | void;
  icon?: JSX.Element;
}

export type SearchField = {
  type?: 'textfield' | 'switch' | 'reference' | 'treepicker' | 'select' | 'date';
  name: string;
  element: React.ReactElement<any>;
  autoFocus?: boolean;
};

export type SearchViewComposerProps<T = any> = {
  title: string;
  description?: string | React.ReactElement;
  endpoint: string;
  searchFields: SearchField[];
  tableHead?: React.ReactElement<typeof TableHead>;
  noInfiniteScroll?: boolean;
  children: (
    results: T[],
    outputFilter: (filter: (arg0: T) => boolean) => any,
    putRow: (index: number, response: T) => any,
    injectRow: (response: T | T[]) => any,
    deleteRow: (index: number) => any,
    refresh: () => void
  ) => React.ReactNode;
  additionalActions?: (
    injectRow: (response: T) => any,
    refresh: () => any,
    putRow: (index: number, response: T) => any,
    results: T[],
    query?: Query
  ) => React.ReactElement<any>[];
  menuActions?: (
    injectRow: (response: T) => any,
    refresh: () => any,
    putRow: (index: number, response: T) => any,
    results: T[],
    query?: Query
  ) => SearchViewComposerSelectOption<T>[];
  initialQuery?: boolean;
  limit?: number;
  dontUseTable?: boolean;
  query?: Query;
  h2?: boolean;
  noTitle?: boolean;
  hideSearch?: boolean;
  emptyStateIcon?: React.ReactNode;
  searching?: boolean;
  alwaysRender?: boolean;
};

type State = {
  searching: boolean;
};

/**
 * @deprecated use Search in v2/components instead
 */
class SearchViewComposer<T = any> extends React.Component<SearchViewComposerProps<T>, State> {
  state = {
    searching: Boolean(this.props.searching),
  };

  renderSearchFields = (_busy: boolean, query: (q1: string, q2: string) => any) => {
    const { searchFields } = this.props;

    const columnWidth = Math.ceil(12 / searchFields.length);

    return searchFields.map((s) => {
      let actionProp;
      let centered = false;

      if (s.type === 'reference') {
        actionProp = {
          onSelect: (is: any[]) => query(s.name, is.map((i) => i._id).join(', ')),
        };
      } else if (s.type === 'treepicker') {
        actionProp = {
          onSelect: (v: string) => query(s.name, v),
        };
      } else if (s.type === 'switch') {
        centered = true;
        actionProp = {
          onChange: (_v: string, checked: boolean) => query(s.name, checked.toString()),
        };
      } else {
        actionProp = {
          onChange: (e: any) => query(s.name, e.target.value),
        };
      }

      const Elem = React.cloneElement(s.element, {
        autoFocus: s.autoFocus,
        ...actionProp,
      });

      return (
        <Grid key={s.name} item sm={columnWidth as any}>
          {centered ? <Centered>{Elem}</Centered> : Elem}
        </Grid>
      );
    });
  };

  render() {
    const {
      endpoint,
      title,
      description,
      tableHead,
      children,
      additionalActions,
      menuActions,
      initialQuery,
      limit,
      dontUseTable,
      query,
      h2,
      noTitle,
      hideSearch,
      emptyStateIcon,
      noInfiniteScroll,
      alwaysRender,
    } = this.props;

    const { searching } = this.state;

    return (
      <Search<T>
        endpoint={endpoint}
        limit={limit}
        query={query}
        initSearch={initialQuery && noInfiniteScroll}
      >
        {({
          busy,
          query,
          hasQueried,
          results,
          outputFilter,
          paginate,
          hasMore,
          resultSpec,
          putRow,
          injectRow,
          deleteRow,
          refresh,
        }) => {
          let state;

          if (!initialQuery && !hasQueried && results.length < 1) {
            state = <EmptyState icon={emptyStateIcon}>{i18n.t('paragraphs.justSearch')}!</EmptyState>;
          } else if (noInfiniteScroll && !hasQueried && results.length < 1) {
            state = <EmptyState icon={emptyStateIcon}>{i18n.t('actions.searchToFind')}</EmptyState>;
          } else if (!alwaysRender && !busy && (initialQuery || hasQueried) && results.length < 1) {
            state = <EmptyState icon={emptyStateIcon}>{i18n.t('paragraphs.noResults')}</EmptyState>;
          }

          const renderedChildren = children(results, outputFilter, putRow, injectRow, deleteRow, refresh);

          const menuOptions =
            typeof menuActions === 'function'
              ? menuActions(injectRow, refresh, putRow, results, resultSpec)
              : [];

          return (
            <>
              {busy && (
                <PositionParent>
                  <LinearProgresser offset />
                </PositionParent>
              )}
              <PageOverlay open={searching}>
                <Section>
                  <Grid container spacing={rawSpacing} justifyContent="center" alignItems="center">
                    {this.renderSearchFields(busy, query)}
                  </Grid>
                </Section>
              </PageOverlay>
              <Section>
                <Grid container spacing={rawSpacing} justifyContent="space-between" alignItems="center">
                  {!noTitle && (
                    <Grid item xs={4}>
                      <Typography variant={h2 ? 'subtitle1' : 'h1'} gutterBottom>
                        {title}
                      </Typography>
                      {description && <Typography>{description}</Typography>}
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container gap={tightSpacing} alignItems="center">
                      {additionalActions
                        ? additionalActions(injectRow, refresh, putRow, results, resultSpec).map((a, k) => {
                            const actionComponent = React.cloneElement(a, {
                              query: resultSpec,
                            });

                            return (
                              <Grid key={k} item>
                                {actionComponent}
                              </Grid>
                            );
                          })
                        : []}
                      {!hideSearch && (
                        <Grid item>
                          <ButtonWithIcon
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              this.setState({
                                searching: !searching,
                              })
                            }
                          >
                            {i18n.t('singles.search')}
                            <SearchIcon />
                          </ButtonWithIcon>
                        </Grid>
                      )}
                      {menuOptions.length > 0 && (
                        <SubMenu<T>
                          options={menuOptions.map((x) => ({
                            ...x,
                            onClick: () => x.onClick(injectRow, refresh, putRow),
                          }))}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Section>
              {state}
              <InfiniteScrollFix
                initialLoad={initialQuery && !hasQueried}
                loadMore={() => {
                  !busy && hasMore && paginate();
                }}
                hasMore={!noInfiniteScroll && !busy && hasMore}
              >
                {(alwaysRender || results.length > 0) && (
                  <Section>
                    {dontUseTable ? (
                      renderedChildren
                    ) : (
                      <Paper>
                        <Table>
                          {tableHead}
                          <TableBody>{renderedChildren}</TableBody>
                        </Table>
                      </Paper>
                    )}
                  </Section>
                )}
              </InfiniteScrollFix>
            </>
          );
        }}
      </Search>
    );
  }
}

export default SearchViewComposer;
