import React from 'react';
import Section from '../../components/Section';
import Grid from '../../mui/Grid';
import Typography from '../../mui/Typography';
import { ModalButton } from '../Modal/ModalButton';
import AdminCopyProducts from './AdminCopyProducts';
import AdminSendMessage from './AdminSendMessage';

export function AdminDashboard() {
  return (
    <React.Fragment>
      <Section>
        <Typography variant="h1">Administrasjonspanel</Typography>
        <Grid container gap={2}>
          <ModalButton label="Send melding til medlemmer" variant="outlined">
            <AdminSendMessage />
          </ModalButton>
          <ModalButton label="Kopier produter mellom organisasjoner" variant="outlined">
            <AdminCopyProducts />
          </ModalButton>
        </Grid>
      </Section>
    </React.Fragment>
  );
}
