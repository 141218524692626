import { useCallback, MouseEvent } from 'react';
import deleteResource from '../../../containers/Notification/deleteResource';
import { BaseButtonWithIconProps, DeleteButtonWithIcon } from './Buttons';

export interface DeleteResourceButtonProps extends Partial<BaseButtonWithIconProps> {
  endpoint?: string;
}

export default function DeleteResourceButton({ endpoint, onClick, ...rest }: DeleteResourceButtonProps) {
  const handleOnClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      if (endpoint) {
        await deleteResource(endpoint, () => {
          if (typeof onClick === 'function') {
            onClick(event);
          }
        });
      } else if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [endpoint, onClick]
  );

  return <DeleteButtonWithIcon onClick={handleOnClick} {...rest} />;
}
