import { useCallback, useMemo } from 'react';
import { AptlyFieldType } from '@aptly-as/types';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import CrudForm, { CrudFormReference } from '../../components/simple/CrudForm';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Grid from '../../mui/Grid';

interface Props {
  onClose?: () => void;
}

interface Body {
  from: string;
  to: string;
}

const orgReference: any = {
  endpoint: 'api/v1/organizations',
  queryKey: '$name',
  labelKey: 'name',
  query: { select: '_id,name', limit: '5', as: 'array', archived: false },
};

export default function AdminCopyProducts({ onClose }: Props) {
  const path = useApiUrl(SlugLevel.Base, 'admin/products/copy');
  const data = useMemo(() => ({}), []);
  const schema = useMemo(() => {
    const _schema: ICrudSchema<Body> = {
      from: {
        type: AptlyFieldType.Reference,
        label: 'Fra organisasjon',
        defaultValue: '',
        useReference: () => orgReference,
      },
      to: {
        type: AptlyFieldType.Text,
        label: 'Til organisasjon',
        defaultValue: '',
        useReference: () => orgReference,
      },
    };
    return _schema;
  }, []);
  const handleOnEdit = useCallback(
    (data?: any) => {
      if (data && onClose) {
        onClose();
      }
    },
    [onClose]
  );
  return (
    <CrudForm path={path} data={data} schema={schema} onEdit={handleOnEdit}>
      <ModalTitle>Kopier produkter</ModalTitle>
      <ModalContent>
        <Grid container flexDirection="column" gap={2}>
          <CrudFormReference name="from" />
          <CrudFormReference name="to" />
        </Grid>
      </ModalContent>
      <ModalActions>
        <ActionButtons onClose={onClose} />
      </ModalActions>
    </CrudForm>
  );
}
