import StarBorder from '@mui/icons-material/StarBorder';
import Star from '@mui/icons-material/Star';
import { useCallback, useState, MouseEvent } from 'react';
import { FavoriteIconButton, TooltipIconButtonProps } from './Icons';

export interface FavoriteIconProps extends Omit<Partial<TooltipIconButtonProps>, 'icon'> {
  favorite?: boolean;
}

export function FavoriteIconToggle({ onClick, favorite = false, ...props }: FavoriteIconProps) {
  const [_favorite, setFavorite] = useState(favorite);
  const handleOnClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setFavorite((s) => !s);
      if (typeof onClick === 'function') {
        onClick(e);
      }
    },
    [onClick]
  );

  return (
    <FavoriteIconButton
      {...props}
      onClick={handleOnClick}
      icon={_favorite ? <Star color="primary" /> : <StarBorder />}
    />
  );
}
