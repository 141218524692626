import dayjs from 'dayjs';
import DatePickerTwo from '../../../mui/x-date-picker/DatePickerTwo';
import { ISimpleComponentProps } from './simple-components.utils';

export interface ICrudDateProps<T extends object> extends ISimpleComponentProps<T, any> {}

export default function SimpleDate<T extends object>({ field, onChange }: ICrudDateProps<T>) {
  const { id, value, defaultValue, edited, type, ...rest } = field;
  return <DatePickerTwo value={dayjs(value)} onChange={(date: any) => onChange(date)} {...rest} />;
}
