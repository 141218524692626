import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { AptlyProject } from '@aptly-as/types';
import { IUseCrudActions, IUseFieldRet, useFields } from '@ewb/reach-react';
import { createModal } from '../../containers/Modal/ModalContext';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import ApiError, { IApiError } from '../../components/ApiError';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils';
import projectSchema, { IProjectSchema } from './project.schema';
import { ISimpleCrudModalProps, SimpleCrudModal } from '../../components/simple/SimpleCrud';

type FieldProps = IUseFieldRet<IProjectSchema, IApiError, ICrudFieldData<IProjectSchema>>;

interface ProjectContextActions extends IUseCrudActions {
  spawnPatch: (
    fields: (keyof IProjectSchema)[],
    props?: Partial<ISimpleCrudModalProps<IProjectSchema>>
  ) => void;
}

export interface IProjectContext extends Omit<FieldProps, 'actions'> {
  data: AptlyProject;
  actions: ProjectContextActions;
}

export const ProjectContext = createContext<IProjectContext>({} as any);

export function useProject() {
  const { data } = useContext(ProjectContext);
  return data;
}

export function ProjectProvider({
  id,
  children,
}: PropsWithChildren<{
  id: string;
}>) {
  const path = useApiUrl(SlugLevel.Organization, 'projects');
  const crud = useFields<IProjectSchema, IApiError, ICrudFieldData<IProjectSchema>>(
    path,
    useMemo(() => ({ _id: id }), [id]),
    useMemo(() => projectSchema(), []),
    useMemo(() => ({ idKey: '_id', initWithGet: true }), [])
  );

  const spawnPatch = useCallback(
    (fields: (keyof IProjectSchema)[], props: Partial<ISimpleCrudModalProps<IProjectSchema>> = {}) => {
      return createModal(
        <SimpleCrudModal
          crud={crud}
          fields={fields}
          onCreate={crud.setData}
          data={crud.state.data}
          {...props}
        />
      );
    },
    [crud]
  );

  const actions: ProjectContextActions = useMemo(
    () => ({
      ...crud.actions,
      spawnPatch,
    }),
    [crud, spawnPatch]
  );

  if (crud.state.error) return <ApiError error={crud.state.error} />;
  if (!crud.state.data.name) return null;

  return (
    <ProjectContext.Provider value={{ data: crud.state.data, ...crud, actions }}>
      {children as any}
    </ProjectContext.Provider>
  );
}
