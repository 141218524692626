import { AptlyFieldType, AptlyUpsellTemplate } from '@aptly-as/types';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import { schemaFile, schemaName } from '../../components/crud/schema/fields.schema';
import i18n from '../../libraries/i18n';
import { toUseProjectReference } from '../../components/crud/schema/reference.schemas';
import { acceptImages, acceptJson } from '../../libraries/react-dropzone/drop-zone.utils';

export type ICrudUpsellTemplate = AptlyUpsellTemplate;

export const upsellTemplateSchema = (): ICrudSchema<ICrudUpsellTemplate> => ({
  name: schemaName({
    autoFocus: true,
  }),
  text: {
    type: AptlyFieldType.Markdown,
    defaultValue: '',
    label: '',
  },
  image: {
    type: AptlyFieldType.Image,
    defaultValue: null,
    label: i18n.t('singles.image'),
    image: { mediaKey: 'imageMedia', width: 720, height: 350, accept: acceptImages },
  },
  videoEmbed: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('paragraphs.videoEmbedCode'),
  },
  links: {
    type: AptlyFieldType.Reference,
    defaultValue: [],
    label: i18n.t('singles.links'),
    useReference: toUseProjectReference('pages', true),
  },
});

interface ICrudUpsellImport extends Pick<ICrudUpsellTemplate, 'name'> {
  file: File | null;
}

export const upsellImportSchema: ICrudSchema<ICrudUpsellImport> = {
  name: schemaName({ autoFocus: true }),
  file: schemaFile(acceptJson, { required: true }),
};
