import { useContext } from 'react';
import slugify from 'slugify';
import { JobExportDocument, JobExportDocumentProps } from '../../../containers/Job/JobExportDocument';
import { Query } from '../../../deprecated/Search/Search';
import { API_URL } from '../../../env';
import filesSaver from '../../../libraries/files-saver';
import { ButtonProps } from '../../../mui/Button';
import { SearchCrudContext } from '../../Search/search.utils';
import { DownloadButtonWithIcon } from './Buttons';

type Props = {
  endpoint: string;
  name: string;
  mime?: string;
};

/** @deprecated **/
const ExportButton = ({ endpoint, name, mime = 'json' }: Props) => (
  <DownloadButtonWithIcon
    onClick={() =>
      filesSaver({
        endpoint: `${endpoint}/export`,
        fileName: `${slugify(name).toLocaleLowerCase()}.${mime}`,
      })
    }
  />
);

interface ExportType {
  endpoint: string;
  query?: Query;
}

const useSearchQueries = (query = {}): URLSearchParams => {
  const search = useContext(SearchCrudContext);
  const data: Query = { ...query, ...search.query };

  delete data.limit;
  delete data.skip;
  delete data.select;

  return new URLSearchParams(data);
};

export interface SearchExportButtonProps extends ButtonProps {
  href: string;
  label?: string;
}
export function SearchExportButton(props: ExportType & Pick<SearchExportButtonProps, 'label'>) {
  const { endpoint, query = {}, label } = props;
  const queries = useSearchQueries(query);
  const href = `${endpoint}?${queries.toString()}`;

  return (
    <DownloadButtonWithIcon
      {...props}
      href={`${API_URL}/${href}`}
      // @ts-ignore
      download
    >
      {label}
    </DownloadButtonWithIcon>
  );
}

export function SearchStartJobButton(
  props: ExportType & Pick<JobExportDocumentProps, 'queue' | 'jobLabel' | 'label'>
) {
  const { endpoint, query = {} } = props;
  return (
    <JobExportDocument
      endpoint={endpoint}
      query={query}
      queue={props.queue}
      jobLabel={props.jobLabel}
      label={props.label}
    />
  );
}

export default ExportButton;
