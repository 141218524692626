import React, { useCallback } from 'react';
import { AptlyOrganization } from '@aptly-as/types';
import { EditIconButton } from '../../../components/actions/icons/Icons';
import { createModal } from '../../../containers/Modal/ModalContext';
import { aptlyModulesLabels } from '../../Module/module.schema';
import i18n from '../../../libraries/i18n';
import List, { ListItemEdit } from '../../../components/List/List';
import Card from '../../../mui/Card';
import CrudModules from '../../../components/simpleCruds/settings/CrudModules';

interface Props {
  organization: AptlyOrganization;
  onSave: (org?: AptlyOrganization) => void;
}

export default function OrganizationModules({ organization, onSave }: Props) {
  const modules = React.useMemo(() => organization.modules || [], [organization.modules]);
  const status = React.useMemo(
    () => (modules ? modules.map((x) => aptlyModulesLabels()[x]).join(', ') : ''),
    [modules]
  );

  const spawnModal = useCallback(() => {
    createModal(
      <CrudModules endpoint="organizations" id={organization.slug} modules={modules} onSave={onSave} />
    );
  }, [organization.slug, modules, onSave]);

  return (
    <Card>
      <List disablePadding>
        <ListItemEdit
          action={<EditIconButton title={i18n.t('singles.modules')} onClick={spawnModal} />}
          status={status}
        >
          {i18n.t('singles.modules')}
        </ListItemEdit>
      </List>
    </Card>
  );
}
