import { AptlyScopes } from '@aptly-as/types';
import { PropsWithChildren, ReactNode, useCallback, useContext } from 'react';
import { EditIconButton } from '../../components/actions/icons/Icons';
import {
  addressSchema,
  AptlyAddressSchema,
  toAddressSchemaData,
} from '../../components/crud/schema/extends.schemas';
import Section from '../../components/Section';
import { SimpleModal } from '../../components/simple/SimpleCrud';
import CrudUnitEdit, { translateUnitKeys } from '../../components/simpleCruds/unit/CrudUnitEdit';
import { createModal } from '../../containers/Modal/ModalContext';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { FragmentScope } from '../../libraries/scope/ScopeComponets';
import Grid from '../../mui/Grid';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../mui/Table';
import { UnitEditData } from './unit.types';
import { UnitContext } from './UnitContext';

export default function UnitGeneralFields() {
  const {
    data: unit,
    actions: { read: refresh },
  } = useContext(UnitContext);
  const translate = translateUnitKeys();
  const url = useApiUrl(SlugLevel.Project);

  const handleAddressEdit = useCallback(
    (key: 'shipping' | 'billing') => () => {
      const schema = addressSchema();
      const keys = [`${key}.line1`, `${key}.line2`, `${key}.zip`, `${key}.city`, `${key}.country`];
      createModal(
        <SimpleModal
          path={`${url}/units`}
          data={toAddressSchemaData(unit._id, unit[key], key)}
          schema={schema}
          fields={keys as (keyof AptlyAddressSchema)[]}
          title={i18n.t('singles.address')}
          onEdit={refresh}
        />
      );
    },
    [url, unit]
  );
  const handleOnUnitEdit = useCallback(
    (title: string, keys: (keyof UnitEditData)[]) => () => {
      createModal(<CrudUnitEdit title={title} unit={unit} onCreate={refresh} fields={keys} />);
    },
    []
  );

  if (!unit) {
    return null;
  }

  return (
    <Section>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={6} lg={3}>
          <EditTable title={i18n.t('address.shipping')} onEdit={handleAddressEdit('shipping')}>
            <Row title={i18n.t('address.line1')} value={unit.shipping?.line1} />
            <Row title={i18n.t('address.line2')} value={unit.shipping?.line2} />
            <Row title={i18n.t('address.zip')} value={unit.shipping?.zip} />
            <Row title={i18n.t('address.city')} value={unit.shipping?.city} />
            <Row title={i18n.t('address.country')} value={unit.shipping?.country} />
          </EditTable>
          <EditTable title={i18n.t('address.billing')} onEdit={handleAddressEdit('billing')}>
            <Row title={i18n.t('address.line1')} value={unit.billing?.line1} />
            <Row title={i18n.t('address.line2')} value={unit.billing?.line2} />
            <Row title={i18n.t('address.zip')} value={unit.billing?.zip} />
            <Row title={i18n.t('address.city')} value={unit.billing?.city} />
            <Row title={i18n.t('address.country')} value={unit.billing?.country} />
          </EditTable>
        </Grid>
        <Grid item xs={6} lg={3}>
          <EditTable
            title={i18n.t('singles.unitNumbers')}
            onEdit={handleOnUnitEdit(i18n.t('singles.unitNumbers'), [
              'houseNumber',
              'entrance',
              'floor',
              'unitNumber',
              'holdingNumber',
              'subHoldingNumber',
              'leaseNumber',
              'sectionNumber',
              'boligmappaNumber',
            ])}
          >
            <Row title={translate['houseNumber']} value={unit.houseNumber || ''} />
            <Row title={translate['entrance']} value={unit.entrance || ''} />
            <Row title={translate['floor']} value={unit.floor || ''} />
            <Row title={translate['unitNumber']} value={unit.unitNumber || ''} />
            <Row title={translate['holdingNumber']} value={unit.holdingNumber || ''} />
            <Row title={translate['subHoldingNumber']} value={unit.subHoldingNumber || ''} />
            <Row title={translate['leaseNumber']} value={unit.leaseNumber || ''} />
            <Row title={translate['sectionNumber']} value={unit.sectionNumber || ''} />
            <Row title={translate['boligmappaNumber']} value={unit.boligmappaNumber || ''} />
          </EditTable>
        </Grid>
        <Grid item xs={6} lg={3}>
          <EditTable
            title={i18n.t('singles.technical')}
            onEdit={handleOnUnitEdit(i18n.t('singles.technical'), [
              'tapWaterID',
              'remoteHeatID',
              'gasID',
              'powerCabinetID',
              'powerCabinetMeasureID',
              'garagePowerID',
            ])}
          >
            <Row title={translate['tapWaterID']} value={unit.tapWaterID || ''} />
            <Row title={translate['remoteHeatID']} value={unit.remoteHeatID || ''} />
            <Row title={translate['gasID']} value={unit.gasID || ''} />
            <Row title={translate['powerCabinetID']} value={unit.powerCabinetID || ''} />
            <Row title={translate['powerCabinetMeasureID']} value={unit.powerCabinetMeasureID || ''} />
            <Row title={translate['garagePowerID']} value={unit.garagePowerID || ''} />
          </EditTable>
        </Grid>
        <Grid item xs={6} lg={3}>
          <EditTable
            title={i18n.t('singles.amenities')}
            onEdit={handleOnUnitEdit(i18n.t('singles.technical'), [
              'storageNumber',
              'garageNumber',
              'garagePortNumber',
              'electricCar',
            ])}
          >
            <Row title={translate['storageNumber']} value={unit.storageNumber || ''} />
            <Row title={translate['garageNumber']} value={unit.garageNumber || ''} />
            <Row title={translate['garagePortNumber']} value={unit.garagePortNumber || ''} />
            <Row title={translate['garagePowerID']} value={unit.garagePowerID || ''} />
            <Row title={translate['electricCar']} value={unit.electricCar || ''} />
          </EditTable>
        </Grid>
      </Grid>
    </Section>
  );
}

interface EditTableProps {
  title: string;
  onEdit: () => void;
}

function EditTable({ title, onEdit, children }: PropsWithChildren<EditTableProps>) {
  const handleOnEdit = useCallback(() => {
    onEdit();
  }, [onEdit]);

  return (
    <Table style={{ width: '100%' }}>
      <TableHead>
        <TableRow>
          <TableCell>{title}</TableCell>
          <TableCell align="right">
            <FragmentScope scope={AptlyScopes.ProjectUnitExtraFields} crud="U">
              <EditIconButton onClick={handleOnEdit} />
            </FragmentScope>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
}

interface RowProps {
  title?: ReactNode;
  value: ReactNode;
}

function Row({ title, value }: RowProps) {
  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell align="right">{value ? value : i18n.t('singles.NA')}</TableCell>
    </TableRow>
  );
}
