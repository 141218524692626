import { AptlyFieldType, AptlyModule, AptlyModules } from '@aptly-as/types';
import { SelectOption } from '../../mui/Select';
import i18n from '../../libraries/i18n';
import { schemaDescription, schemaName } from '../../components/crud/schema/fields.schema';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';

export type IAptlyModules = AptlyModules;

export const aptlyModulesLabels = (): {
  [key in IAptlyModules]?: string;
} => ({
  [AptlyModules.Default]: i18n.t('singles.default'),
  [AptlyModules.Core]: i18n.t('singles.core'),
  [AptlyModules.Departments]: i18n.t('singles.departments'),
  [AptlyModules.Emails]: i18n.t('singles.emails'),
  [AptlyModules.UnitExtraOptions]: i18n.t('singles.extraOptions'),
  [AptlyModules.Recommendations]: i18n.t('singles.recommendations'),
  [AptlyModules.ProductsExtended]: i18n.t('singles.products'),
  [AptlyModules.UnitExtraFields]: i18n.t('singles.extraFields'),
  [AptlyModules.Algorithms]: i18n.t('singles.calculation'),
  [AptlyModules.OptionLabels]: i18n.t('singles.labels'),
  [AptlyModules.Booking]: i18n.t('singles.booking'),
  [AptlyModules.Pages]: i18n.t('singles.pages'),
  [AptlyModules.ThirdParty]: i18n.t('singles.thirdParty'),
  [AptlyModules.Support]: i18n.t('singles.support'),
  [AptlyModules.SupportComplaint]: i18n.t('singles.complaint'),
  [AptlyModules.Signing]: i18n.t('singles.sign'),
  [AptlyModules.Payment]: i18n.t('singles.payment'),
  [AptlyModules.Testing]: i18n.t('singles.testing'),
});
export const aptlyModules = Object.keys(aptlyModulesLabels()) as IAptlyModules[];
export const aptlyModulesOptions = () =>
  aptlyModules.reduce<SelectOption[]>((acc, key) => {
    acc.push({
      value: key,
      label: aptlyModulesLabels()[key],
    });
    return acc;
  }, []);

export const moduleSchema = (): ICrudSchema<AptlyModule> => ({
  name: schemaName({
    autoFocus: true,
  }),
  cost: {
    type: AptlyFieldType.Number,
    label: i18n.t('singles.cost'),
    defaultValue: 0,
  },
  featuredWeight: {
    type: AptlyFieldType.Number,
    defaultValue: 0,
    label: i18n.t('singles.weight'),
  },
  description: schemaDescription({}),
  modules: {
    type: AptlyFieldType.Select,
    defaultValue: [],
    label: i18n.t('singles.modules'),
    multiple: true,
    options: aptlyModulesOptions(),
  },
});
