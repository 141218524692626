import { AptlyScopes, AptlyUserClient } from '@aptly-as/types';
import { useNavigate } from 'react-router-dom';
import { CreateButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { DeleteIconButton, GoToIconButtonLink } from '../../components/actions/icons/Icons';
import Search, { SearchFieldText } from '../../components/Search/Search';
import useApiV1 from '../../hooks/useApiV1';
import { SlugLevel, useTo } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../mui/Table';

export default function UserAPIClients() {
  const url = useApiV1('base', '/oauth2/clients');
  const to = useTo(SlugLevel.Base, '/bruker/clients');
  const navigate = useNavigate();

  return (
    <Search<AptlyUserClient>
      path={url}
      patchPath={() => url}
      reach={{ query: { select: '_id,name,clientId' } }}
      header={{
        title: i18n.t('singles.oauth2'),
        description: i18n.t('paragraphs.clientDescription'),
      }}
      searchFields={[<SearchFieldText key="name" field="$name" label={i18n.t('singles.name')} autoFocus />]}
      buttonActions={() => [<CreateButtonWithIcon key="new" onClick={() => navigate(to + '/new')} />]}
      patch={{ title: '' }}
      post={{ title: i18n.t('actions.newProject') }}
      schema={{}}
      fields={['name']}
      scope={AptlyScopes.Organization}
    >
      {(clients, actions) => {
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.t('singles.name')}</TableCell>
                <TableCell>{i18n.t('singles.clientId')}</TableCell>
                <TableCell>{i18n.t('singles.clientSecret')}</TableCell>
                <TableCell align="right">{i18n.t('singles.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map((x, i) => (
                <TableRow key={x._id}>
                  <TableCell>{x.name}</TableCell>
                  <TableCell>{x.clientId}</TableCell>
                  <TableCell>{x.clientSecret || '********************************'}</TableCell>
                  <TableCell align="right">
                    <DeleteIconButton onClick={() => actions.spawnDelete(x, i)} />
                    <GoToIconButtonLink to={`${to}/${x._id}`} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        );
      }}
    </Search>
  );
}
