import {
  AptlyOrganization,
  AptlyProduct,
  AptlyProductVariant,
  AptlyScopes,
  AptlyUnitTemplateCategorySectionProduct,
} from '@aptly-as/types';
import { GridRowModel } from '@mui/x-data-grid';
import { ISimpleCrudContentGroupProps } from '../../components/simple/SimpleCrud';
import StatusText from '../../components/StatusText';
import i18n from '../../libraries/i18n';
import { matchId } from '../../libraries/mongoose';
import Scope from '../../libraries/scope/Scope';
import { SectionProduct } from '../UnitTemplate/unit-template.types';

export const productGroupProps: ISimpleCrudContentGroupProps<AptlyProduct | AptlyProductVariant> = {
  [i18n.t('singles.images')]: {
    subtitle: (p) => (String(p.images?.length) || '0') + ` ${i18n.t('singles.images')}`,
  },
  [i18n.t('singles.documents')]: {
    subtitle: (p) => (String(p.documents?.length) || '0') + ` ${i18n.t('singles.documents')}`,
  },
  [i18n.t('singles.variants')]: {
    subtitle: (p) => (String((p as AptlyProduct).products?.length) || '0') + ` ${i18n.t('singles.products')}`,
  },
};

export function getProductsName(p: SectionProduct | AptlyUnitTemplateCategorySectionProduct) {
  let productName: any = <StatusText>{i18n.t('statuses.nothing')}</StatusText>;

  if (p.product) {
    const pp = p.product as AptlyProduct;
    if (p.availableProducts?.length > 0) {
      const ap = p.availableProducts[0];
      const variant = pp.variants.find((v) => v._id === ap);

      productName = variant ? variant.name : pp.name;
    } else {
      productName = pp.name;
    }

    const variants = p.product!.variants?.filter((v) => p?.availableProducts?.some((x) => v._id === x));
    if (variants.length > 0) {
      return (
        <>
          {productName}
          {variants.map((v) => (
            <>
              <br />
              {v.name}
            </>
          ))}
        </>
      );
    }
  } else if (p.text) {
    productName = p.text;
  }

  return productName;
}

export function getProductScopeModel(row: GridRowModel<AptlyProduct>, org?: AptlyOrganization): AptlyScopes {
  if (row.project) return AptlyScopes.ProjectProducts;
  if (row.organization) return AptlyScopes.OrganizationProducts;
  if (org?.producer && row.producer && matchId(org.producer, row.producer)) {
    return AptlyScopes.OrganizationProducts;
  }
  return AptlyScopes.AdminProducts;
}

export function getProductScope(row: GridRowModel<AptlyProduct>, org?: AptlyOrganization) {
  const model = getProductScopeModel(row, org);

  return {
    update: Scope.crud(model, 'U'),
    delete: Scope.crud(model, 'D'),
  };
}

export function copyProduct(p: Partial<AptlyProduct>): Partial<AptlyProduct> {
  return {
    name: `${p.name} - kopi`,
    circumference: p.circumference,
    color: p.color,
    colorLabel: p.colorLabel,
    depth: p.depth,
    description: p.description,
    documents: p.documents,
    eanNumber: p.eanNumber,
    height: p.height,
    images: p.images,
    itemNumber: p.itemNumber,
    length: p.length,
    material: p.material,
    netCost: p.netCost,
    producer: p.producer,
    productNumber: `${p.productNumber}-kopi`,
    products: p.products,
    series: p.series,
    style: p.style,
    supplier: p.supplier,
    tags: p.tags,
    title: p.title,
    unitCost: p.unitCost,
    url: p.url,
    variants: p.variants,
    volume: p.volume,
    width: p.width,
  };
}
