import HistoryIcon from '@mui/icons-material/History';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import UndoIcon from '@mui/icons-material/Undo';
import { useParams } from 'react-router-dom';
import { EditButtonWithIcon, UploadButtonWithIcon } from '../../components/actions/buttons/Buttons';
import LinkDocument from '../../components/LinkDocument';
import Section from '../../components/Section';
import ClientIFrame from '../../containers/Client/ClientIFrame';
import { createModal } from '../../containers/Modal/ModalContext';
import { acceptCsv } from '../../libraries/react-dropzone/drop-zone.utils';
import UnitTemplateCost from './UnitTemplateCost';
import { UnitTemplateContext, UnitTemplateProvider } from './utils/UnitTemplateContext';
import { UnitTemplateCategories } from './Categories';
import ErrorBoundary from '../../components/ErrorBoundary';
import Grid from '../../mui/Grid';
import Typography from '../../mui/Typography';
import { useCallback, useContext, useMemo, useState } from 'react';
import { SlugLevel, useApiUrl, useSlugs } from '../../hooks/useGetApiUrl';
import CrudUploadDocument from '../../components/simpleCruds/document/CrudUploadDocument';
import i18n from '../../libraries/i18n';
import HistoryModal from './modal-content/HistoryModal';
import Dialog from '../../mui/Dialog';

interface Props {
  id?: string;
}

export default function UnitTemplate({ id }: Props) {
  const { unitTemplateID } = useParams();
  return (
    <UnitTemplateProvider id={id || unitTemplateID!}>
      <Section>
        <Header />
      </Section>
      <Section>
        <ErrorBoundary>
          <UnitTemplateCategories />
        </ErrorBoundary>
      </Section>
      <Footer />
    </UnitTemplateProvider>
  );
}

function Header() {
  const [slug, projectID] = useSlugs();
  const { unitTemplate, read } = useContext(UnitTemplateContext);
  const importEndpoint = useApiUrl(SlugLevel.Project, 'unit-templates');
  const endpoint = `organizations/${slug}${projectID ? `/projects/${projectID}` : ''}/unit-templates/${
    unitTemplate._id
  }`;
  const handleOnImport = useCallback(() => {
    createModal(
      <CrudUploadDocument
        endpoint={`${importEndpoint}/${unitTemplate._id}/import`}
        onCreate={read}
        hideType
        hideSource
        accept={acceptCsv}
        title={i18n.t('actions.uploadCsv')}
      >
        <LinkDocument
          label="Tilvalgliste-import.csv"
          href="https://docs.google.com/spreadsheets/d/11mYSb1wOCVpyjun-D3VEFZS9Dw_iEUV5zcl6bsasyfo/template/preview?usp=sharing"
        />
      </CrudUploadDocument>
    );
  }, [importEndpoint, unitTemplate._id, read]);
  const handleOnCost = useCallback(() => {
    createModal(<UnitTemplateCost endpoint={`${importEndpoint}/${unitTemplate._id}`} onSave={read} />, {
      width: 'lg',
    });
  }, [importEndpoint, unitTemplate._id, read]);

  return (
    <Grid container justifyContent="space-between">
      <Typography variant="h1">{unitTemplate.name}</Typography>
      <Grid item>
        <Grid container item spacing={2}>
          <Grid item>
            <EditButtonWithIcon onClick={handleOnCost} title={i18n.t('singles.netCost')} />
          </Grid>
          <Grid item>
            <UploadButtonWithIcon onClick={handleOnImport} />
          </Grid>
          <Grid item>
            <ClientIFrame endpoint={`${endpoint}/`} newWindow label={i18n.t('actions.previewOptionList')} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

type Content = 'history' | 'undo';

function Footer() {
  const { undo } = useContext(UnitTemplateContext);
  const [modalOpen, setModalOpen] = useState<Content | null>(null);
  const [open, setOpen] = useState(false);

  const handleModalOpen = useCallback((c: Content) => setModalOpen(c), []);
  const handleModalClose = useCallback(() => setModalOpen(null), []);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleSelect = useCallback(
    (c: Content) => () => {
      setOpen(false);
      handleModalOpen(c);
    },
    [handleModalOpen]
  );

  const actions = useMemo(
    () => [
      {
        icon: <UndoIcon />,
        name: i18n.t('actions.undo'),
        onClick: undo,
      },
      {
        icon: <HistoryIcon />,
        name: i18n.t('singles.history'),
        onClick: handleSelect('history'),
      },
    ],
    [undo, handleSelect]
  );

  const ModalContent = useMemo(() => {
    if (!modalOpen) return null;
    if (modalOpen === 'history') {
      return <HistoryModal onClose={handleModalClose} />;
    }
  }, [modalOpen, handleModalClose]);

  return (
    <>
      <SpeedDial
        ariaLabel="Menu"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1299 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => action.onClick()}
          />
        ))}
      </SpeedDial>
      <Dialog open={Boolean(modalOpen)} onClose={handleModalClose}>
        {ModalContent}
      </Dialog>
    </>
  );
}
