import { AptlyScopes } from '@aptly-as/types';
import { useCallback, useContext } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { ICrudPeriod, periodSchema } from './period.schema';
import {
  dataGridActions,
  dataGridSearchDateTimeColumn,
  dataGridSearchNameColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import { ProjectContext } from '../Project/ProjectContext';

const columns = (): GridColDef[] => [
  dataGridSearchNameColumn(),
  dataGridSearchDateTimeColumn('open', {
    headerName: i18n.t('singles.from'),
  }),
  dataGridSearchDateTimeColumn('close', {
    headerName: i18n.t('singles.to'),
  }),
  dataGridActions({ checkIfDisabledInProject: true }),
];

export default function Periods() {
  const { setData } = useContext(ProjectContext);
  const path = useApiUrl(SlugLevel.Project, 'periods');

  const buttonActions = useCallback(() => [<SearchCreateAction key="create" />], []);

  const handleOnChange = useCallback(
    (periods: ICrudPeriod[]) => {
      if (periods) {
        setData({ periods });
      }
    },
    [setData]
  );

  return (
    <Search<ICrudPeriod>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.ProjectPeriods}
      header={{ title: i18n.t('singles.deadlines') }}
      post={{ title: i18n.t('singles.deadlines') }}
      patch={{ title: (data) => String(data.name) }}
      schema={periodSchema()}
      fields={['name', 'open', 'close']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name' } }}
      buttonActions={buttonActions}
      onChange={handleOnChange}
      searchFields={[
        <SearchFieldText field="name" queryKey="name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns()} />}
    </Search>
  );
}
