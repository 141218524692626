import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { AptlyDocument } from '@aptly-as/types';
import { useCrudFormField } from '../../../components/simple/CrudForm';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import { getId } from '../../../libraries/mongoose';
import DocumentsUpload from '../../Document/DocumentsUpload';
import { EmailContent, HandleAptlyOrder, HandleOrderBody } from './HandleOrder.utils';

interface SignManuallyProps {
  order: HandleAptlyOrder;
}

export function HandleOrderSignManually({ order }: SignManuallyProps) {
  const documentField = useCrudFormField<HandleOrderBody, 'document'>('document');
  const documentsUrl = useApiUrl(SlugLevel.Unit, 'documents', {
    projectID: getId(order.project),
    unitID: getId(order.unit),
  });
  const handleOnDropDocument = useCallback(
    (documents: AptlyDocument[]) => {
      if (documents[0]) {
        documentField.onChange(documents[0]._id);
      } else {
        documentField.onChange(null);
      }
    },
    [documentField]
  );

  return (
    <Grid container flexDirection="column" gap={1}>
      <Grid item>
        <Typography gutterBottom>{i18n.t('paragraphs.uploadSignedDocument')}</Typography>
        <DocumentsUpload endpoint={documentsUrl} path="" onDrop={handleOnDropDocument} />
      </Grid>
      <Grid item>
        <EmailContent />
      </Grid>
    </Grid>
  );
}
