import { useContext, useMemo } from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ApiError from '../../components/ApiError';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { API_URL } from '../../env';
import { OrganizationContext } from '../Organization/OrganizationContext';
import i18n from '../../libraries/i18n';
import useApp from './useApp';
import AppCard, { AppCardActionLink } from './AppCard';
import AppsInstallList from './AppsInstallList';
import AppSettings from './AppSettings';
import { AppContext, AppProvider } from './AppContext';
import AppInject from './AppInject';
import { byAppConfigIdOrSlug } from './app.utils';

export default function OrganizationApps() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const error = params.get('error');
  return (
    <>
      {error && <ApiError error={error} />}
      <Routes>
        <Route path="" element={<AppsInstallList />} />
        <Route path=":app/*" element={<OrganizationApp />} />
      </Routes>
    </>
  );
}

export function OrganizationApp() {
  const params = useParams();
  const org = useContext(OrganizationContext);
  const config = org.data.apps?.find(byAppConfigIdOrSlug(params.app!));
  const [app] = useApp(params.app!);

  if (!app.data || !config) return null;

  return (
    <AppProvider value={{ app: app.data, config }}>
      <Routes>
        <Route path="" element={<AppSettings />} />
        <Route path="uninstall" element={<Uninstall />} />
        <Route path=":inject" element={<AppInject />} />
      </Routes>
    </AppProvider>
  );
}

function Uninstall() {
  const { app } = useContext(AppContext);
  const appPath = useApiUrl(SlugLevel.Organization, 'apps');
  const description = useMemo(() => {
    return <Typography>Trykk bekreft for å deaktivere app og fjerne alle innstillinger</Typography>;
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
      <Grid item xs={4}>
        <AppCard title={app.name} description={description}>
          <AppCardActionLink href={`${API_URL}/${appPath}/${app._id}/uninstall`}>
            {i18n.t('actions.confirm')}
          </AppCardActionLink>
        </AppCard>
      </Grid>
    </Grid>
  );
}
