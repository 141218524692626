import './libraries/i18n';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ReachProvider } from '@ewb/reach-react';
import App from './App';
import UserProvider from './models/User/UserContext';
import { unregister } from './serviceWorker';
import { reachService } from './libraries/reach/reach';

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <ReachProvider value={reachService}>
      <Suspense fallback={<div>Loading...</div>}>
        <UserProvider>
          <App />
        </UserProvider>
      </Suspense>
    </ReachProvider>
  </React.StrictMode>
);

// todo: Temp fix for admins to take them to .no domain
if (window.location.href.includes('aptly.as')) {
  window.location.href = window.location.href.replace('aptly.as', 'aptly.no');
}

unregister();
