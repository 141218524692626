import React, { useCallback } from 'react';
import MoreVert from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { IconButton } from '../mui/Button';
import i18n from '../libraries/i18n';
import { SearchViewComposerSelectOption } from '../deprecated/Search/SearchViewComposer';

export interface SubMenuProps<T> {
  options: SearchViewComposerSelectOption<T, false>[];
}

export default function SubMenu<T>({ options }: SubMenuProps<T>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  return (
    <>
      <IconButton
        aria-label={i18n.t('actions.showMeMore')}
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={() => setAnchorEl(null)}>
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={async () => {
              option.onClick();
              setAnchorEl(null);
            }}
          >
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
