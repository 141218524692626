import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import { AptlyMediaSrc } from '@aptly-as/types';
import Avatar from '../../components/Avatar';
import { Cost } from '../../components/Cost';
import { EditorText } from '../../components/EditorText';
import { CardHeader } from '../../mui/Card';
import createMediaURL from '../Media/createMediaURL';

interface IAppCardProps extends Partial<JSX.ElementChildrenAttribute> {
  title: string;
  description: JSX.Element | string;
  image?: AptlyMediaSrc | null;
  cost?: number;
}

export default function AppCard({ title, image, description, cost, children }: IAppCardProps) {
  const avatar = useMemo(
    () => (image ? <Avatar contain src={createMediaURL(image.src)} /> : undefined),
    [image]
  );
  return (
    <StyledCard>
      <CardHeader title={title} avatar={avatar} />
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={9}>
            {typeof description === 'string' ? <EditorText value={description} /> : description}
          </Grid>
          <Grid container item xs={3} justifyContent="flex-end">
            <Cost cost={cost} />
          </Grid>
        </Grid>
      </CardContent>
      {children && <CardActions>{children as any}</CardActions>}
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

interface IAppCardLinkProps extends JSX.ElementChildrenAttribute {
  to?: string;
  href?: string;
  disabled?: boolean;
}

export function AppCardActionLink({ children, ...rest }: IAppCardLinkProps) {
  return (
    <Button size="small" component={rest.to ? Link : 'a'} {...rest}>
      {children as any}
    </Button>
  );
}
