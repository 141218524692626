import { AptlyDocument, AptlyUnit } from '@aptly-as/types';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { createModal } from '../../containers/Modal/ModalContext';
import useApiRequest from '../../hooks/useApiRequest';
import useGetApiUrl from '../../hooks/useGetApiUrl';
import BusyState from '../../components/BusyState';
import Grid from '../../mui/Grid';
import ErrorState from '../../containers/Error/ErrorState';
import Typography from '../../mui/Typography';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import i18n from '../../libraries/i18n';
import apiRequest from '../../libraries/fetch/apiRequest';
import LinkDocumentToUnit from './LinkDocumentToUnit';
import { Checkbox, TextField } from '../../mui/Input';

interface Props {
  onClose?: () => void;
  refresh: () => void;
}

interface State {
  busy: boolean;
  activeUnit: AptlyUnit | null;
  index: number;
  complete: boolean;
  autoComplete: boolean;
  autoNumber: number;
  numberOfDocuments: number;
  removeFromProject: boolean;
}

export default function LinkDocumentsBot({ onClose, refresh }: Props) {
  const url = useGetApiUrl('project');
  const opts = useMemo(
    () => ({
      data: {
        simple: true,
        limit: 250,
      },
    }),
    []
  );
  const [busy, units, err] = useApiRequest<AptlyUnit[]>(`${url}/units/search`, opts);
  const [state, setState] = useState<State>({
    busy: false,
    activeUnit: null,
    index: 0,
    complete: false,
    autoComplete: false,
    autoNumber: 1,
    removeFromProject: true,
    numberOfDocuments: 0,
  });

  const handleStart = useMemo(
    () =>
      start(url, setState, units || [], state.autoComplete ? state.autoNumber : 0, state.removeFromProject),
    [state.autoComplete, state.autoNumber, state.removeFromProject, url, units]
  );
  const handleClose = useCallback(() => {
    onClose && onClose();
    refresh();
  }, [onClose, refresh]);

  if (busy || !units) {
    return <BusyState />;
  }
  if (err) {
    return <ErrorState error={err} />;
  }

  if (state.complete) {
    return (
      <Grid container direction="column">
        <ModalTitle>{i18n.t('singles.unit')}</ModalTitle>
        <ModalContent>
          {i18n.t('paragraphs.completedTransfer')} <br />
          {i18n.t('singles.units')}: {units.length} <br />
          {i18n.t('singles.documents')}: {state.numberOfDocuments} <br />
          <br />
          {i18n.t('paragraphs.closeToUpdate')}
        </ModalContent>
        <ModalActions>
          <ActionButtons onClick={handleClose} actionLabel={i18n.t('actions.close')} />
        </ModalActions>
      </Grid>
    );
  }

  return (
    <Grid container direction="column">
      <ModalTitle>{i18n.t('singles.unit')}</ModalTitle>
      <ModalContent>
        <Grid container direction="column">
          <Checkbox
            disabled={state.busy}
            checked={state.autoComplete}
            onChange={() => setState((s) => ({ ...s, autoComplete: !s.autoComplete }))}
            label={i18n.t('singles.autocomplete')}
          />
          {state.autoComplete && (
            <>
              <TextField
                type="number"
                disabled={state.busy}
                value={state.autoNumber}
                onChange={(e) => {
                  const autoNumber = Number(e.target.value);
                  setState((s) => ({ ...s, autoNumber }));
                }}
                label={`${i18n.t('paragraphs.moveUpTo')} ${state.autoNumber} ${i18n.t('singles.documents')}`}
              />
              <Checkbox
                disabled={state.busy}
                checked={state.removeFromProject}
                onChange={() =>
                  setState((s) => ({
                    ...s,
                    removeFromProject: !s.removeFromProject,
                  }))
                }
                label={i18n.t('paragraphs.removeFilesFromProject')}
              />
            </>
          )}

          <Typography>
            {i18n.t('singles.units')}: {state.index + 1} / {units && units.length}
          </Typography>
          {state.activeUnit && (
            <Typography>
              {i18n.t('statuses.active')}: {state.activeUnit.name}
            </Typography>
          )}
        </Grid>
      </ModalContent>
      <ModalActions>
        <ActionButtons
          disabled={units.length === 0 || state.busy}
          onClose={() => {
            if (state.busy) {
              setState((s) => ({ ...s, busy: false }));
            } else {
              handleClose();
            }
          }}
          onClick={() => handleStart(state.index)}
          actionLabel={i18n.t('actions.start')}
          cancelLabel={state.busy ? i18n.t('actions.pause') : undefined}
        />
      </ModalActions>
    </Grid>
  );
}

function start(
  projectUrl: string,
  setState: Dispatch<SetStateAction<State>>,
  units: AptlyUnit[],
  autoNumber: number,
  removeFromProject: boolean
) {
  return async (startIndex: number) => {
    try {
      let index = 0;
      let numberOfDocuments = 0;
      for (const unit of units) {
        if (startIndex > index) {
          ++index;
          continue;
        }
        // eslint-disable-next-line
        setState((s) => ({ ...s, activeUnit: unit, index, busy: true }));
        const documents = await apiRequest<AptlyDocument[]>(`${projectUrl}/documents`, {
          data: { name: unit.name },
        });
        if (documents.length > 0) {
          const autoClick = autoNumber ? autoNumber >= documents.length : false;
          await spawnModal(unit, documents, autoClick, removeFromProject);
          numberOfDocuments += documents.length;
        }
        index++;
      }
      setState((s) => ({
        ...s,
        index: 0,
        activeUnit: null,
        busy: false,
        complete: true,
        numberOfDocuments,
      }));
    } catch (e) {
      setState((s) => ({ ...s, busy: false }));
    }
  };
}

async function spawnModal(
  unit: AptlyUnit,
  documents: AptlyDocument[],
  autoClick: boolean,
  move: boolean = true
) {
  return new Promise((res, rej) => {
    createModal(
      <LinkDocumentToUnit
        unit={unit}
        name={unit.name}
        documents={documents}
        onCancel={rej}
        refresh={() => res(true)}
        autoClick={autoClick}
        defaultMove={move}
      />
    );
  });
}
