import { AptlyOrder, AptlyScopes } from '@aptly-as/types';
import { useContext, useMemo } from 'react';
import * as React from 'react';
import Restore from '@mui/icons-material/Restore';
import { FragmentScope } from '../../libraries/scope/ScopeComponets';
import Paper, { PaperPadded } from '../../mui/Paper';
import Typography from '../../mui/Typography';
import Section from '../../components/Section';
import useGetApiUrl from '../../hooks/useGetApiUrl';
import useApiRequest from '../../hooks/useApiRequest';
import ErrorState from '../../containers/Error/ErrorState';
import BusyState from '../../components/BusyState';
import i18n from '../../libraries/i18n';
import EmptyState from '../../containers/Error/EmptyState';
import Table, { TableBody } from '../../mui/Table';
import { UnitContext } from '../Unit/UnitContext';
import OrdersTableRow, { OrdersTableHead } from './OrdersTable';
import DeleteIconEndpoint from '../../components/actions/icons/DeleteIconEndpoint';

export default function UnitOrders() {
  const {
    data: unit,
    actions: { read: unitRefresh },
  } = useContext(UnitContext);
  const url = useGetApiUrl('project', `/units/${unit._id}/orders`);
  const [busy, orders, error, refresh] = useApiRequest<AptlyOrder[]>(
    url,
    useMemo(() => ({ data: { archived: false } }), [])
  );

  const onDelete = React.useCallback(async () => {
    await refresh();
    await unitRefresh();
  }, [unitRefresh, refresh]);

  if (busy) return <BusyState />;
  if (error) return <ErrorState error={error} />;
  if (!orders) return null;

  return (
    <Section>
      <Typography variant="h1" gutterBottom>
        {i18n.t('singles.orders')}
      </Typography>
      {orders.length === 0 && (
        <PaperPadded>
          <EmptyState tiny>{i18n.t('statuses.noOrders')}</EmptyState>
        </PaperPadded>
      )}
      <Paper>
        <Table>
          <OrdersTableHead />
          <TableBody>
            {orders.map((order) => (
              <OrdersTableRow key={order._id} order={order} unit={unit} refresh={refresh}>
                <FragmentScope scope={AptlyScopes.ProjectOrders} crud="U">
                  {order.status !== 'invalid' && (
                    <DeleteIconEndpoint
                      title={i18n.t('actions.annul')}
                      endpoint={url}
                      _id={order._id}
                      path="/revert"
                      onClick={onDelete}
                      icon={<Restore />}
                    />
                  )}
                </FragmentScope>
              </OrdersTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Section>
  );
}
