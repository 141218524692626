import { AptlyScopes } from '@aptly-as/types';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import useScopeLevel, { IScopeLevelItem } from './useScopeLevel';

export interface IUseScopePropsProps {
  base: AptlyScopes;
  organization?: AptlyScopes;
  project?: AptlyScopes;
  unit?: AptlyScopes;
}

export default function useScopeProps(
  { base, organization, project, unit }: IUseScopePropsProps,
  item?: IScopeLevelItem
): AptlyScopes {
  const level = useScopeLevel(item);
  if (level === SlugLevel.Unit && unit) return unit;
  if (level >= SlugLevel.Project && project) return project;
  if (level >= SlugLevel.Organization && organization) return organization;
  return base;
}
