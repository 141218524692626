import {
  AptlyDocument,
  AptlyDocumentType,
  AptlyOrder,
  AptlyOrderStatus,
  AptlyScopes,
  AptlyUnit,
} from '@aptly-as/types';
import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import ActionBar from '../../components/ActionBar';
import DeleteIconEndpoint from '../../components/actions/icons/DeleteIconEndpoint';
import {
  DoneIconButton,
  DownloadIconButton,
  GoToIconButtonLink,
  SignIconButton,
  UploadIconButton,
} from '../../components/actions/icons/Icons';
import { createModal } from '../../containers/Modal/ModalContext';
import sign from '../../containers/Sign/sign';
import useGetApiUrl, { SlugLevel, useTo } from '../../hooks/useGetApiUrl';
import filesSaver from '../../libraries/files-saver';
import i18n from '../../libraries/i18n';
import { intlCurrencyDecimal, intlDateTimeFormat } from '../../libraries/intl';
import { getId, matchId } from '../../libraries/mongoose';
import { acceptPdf } from '../../libraries/react-dropzone/drop-zone.utils';
import { TextLink } from '../../libraries/router/ComponentLink';
import { FragmentScope } from '../../libraries/scope/ScopeComponets';
import { useScopePropsModel } from '../../libraries/scope/useScopeModel';
import { TableCell, TableHead, TableRow } from '../../mui/Table';
import { MOBILE_BREAK } from '../../utils/themes';
import { DocumentPreviewIcon } from '../Document/DocumentPreview';
import DropDocuments from '../Document/DropDocuments';
import { UserContext } from '../User/UserContext';
import HandleOrder, { handleOrderModelOptions } from './HandleOrder/HandleOrder';
import { orderCompletedAt, orderStatusLabel } from './order.utils';

interface Props {
  order: AptlyOrder;
  refresh: () => void;
  unit: AptlyUnit;
  children?: JSX.Element;
  showDelete?: boolean;
}

export function OrdersTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>{i18n.t('singles.orderNumber')}</TableCell>
        <StyledTableCell>{i18n.t('singles.unit')}</StyledTableCell>
        <StyledTableCell>{i18n.t('singles.status')}</StyledTableCell>
        <StyledTableCell>{i18n.t('singles.totalPrice')}</StyledTableCell>
        <StyledTableCell>{i18n.t('statuses.created')}</StyledTableCell>
        <StyledTableCell>{i18n.t('statuses.completed')}</StyledTableCell>
        <TableCell align="right">{i18n.t('singles.actions')}</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function OrdersTableRow({ order, unit, refresh, children, showDelete }: Props) {
  const baseEndpoint = useGetApiUrl('project');
  const { data } = useContext(UserContext);
  const unitTo = useTo(SlugLevel.Unit, '', { unit: unit?._id });
  const orderTo = useTo(SlugLevel.Project, `/ordre/${order._id}`, { project: getId(order.project) });
  const receipt = order.receipt as AptlyDocument | null;
  const signedFile = (order.signage?.signedFile || order.signedReceipt) as AptlyDocument | null;
  const scope = useScopePropsModel({
    unit: AptlyScopes.UnitOrders,
    project: AptlyScopes.ProjectOrders,
    organization: AptlyScopes.OrganizationOrders,
    base: AptlyScopes.Admin,
  });

  const handleOnNewOrder = React.useCallback(() => {
    createModal(<HandleOrder order={order} onChange={refresh} />, handleOrderModelOptions);
  }, [order]);
  const needUserSign = useMemo(() => {
    if (order.status === AptlyOrderStatus.SignedByCustomer) {
      return true;
    }
    if ([AptlyOrderStatus.NotSigned, AptlyOrderStatus.ReadyToSign].includes(order.status)) {
      return order.signees?.some((x) => matchId(x.user, data));
    }
    return false;
  }, [order, data]);

  const handleOnUploadSignedDocument = React.useCallback(() => {
    createModal(
      <DropDocuments
        endpoint={baseEndpoint}
        path={`/orders/${order._id}/signed-receipt`}
        options={[{ value: AptlyDocumentType.SignedReceipt, label: '' }]}
        simple
        onDrop={refresh}
        accept={acceptPdf}
        label={i18n.t('actions.uploadSignedDocument')}
      />
    );
  }, [baseEndpoint, order, refresh]);

  const completedAt = useMemo(() => orderCompletedAt(order), [order]);

  const handleSign = useCallback(() => {
    sign({
      orderEndpoint: `${baseEndpoint}/orders/${order._id}`,
      initializeSigning: order.status === AptlyOrderStatus.NotSigned,
      user: data,
      unit,
      refresh,
    });
  }, [baseEndpoint, order._id, order.status, order.signees, unit, refresh, data]);

  return (
    <TableRow>
      <TableCell>{order.orderNumber}</TableCell>
      <StyledTableCell>
        <TextLink to={unitTo} color="inherit">
          {unit?.name || i18n.t('singles.NA')}
        </TextLink>
      </StyledTableCell>
      <StyledTableCell>{orderStatusLabel(order.status)}</StyledTableCell>
      <StyledTableCell>{intlCurrencyDecimal(order.totalCost)}</StyledTableCell>
      <StyledTableCell>{intlDateTimeFormat(order.created)}</StyledTableCell>
      <StyledTableCell>{completedAt ? intlDateTimeFormat(completedAt) : '-'}</StyledTableCell>
      <TableCell align="right">
        <ActionBar>
          {children}
          <DocumentPreviewIcon document={signedFile || receipt!} />
          {showDelete && (
            <FragmentScope scope={AptlyScopes.ProjectOrders} crud="D">
              <DeleteIconEndpoint endpoint={`${baseEndpoint}/orders`} _id={order._id} onClick={refresh} />
            </FragmentScope>
          )}
          {scope.update && order.status === AptlyOrderStatus.NotSigned && (
            <UploadIconButton
              onClick={handleOnUploadSignedDocument}
              title={i18n.t('actions.uploadSignedDocument')}
            />
          )}
          {signedFile?.downloadToken ? (
            <DownloadIconButton
              onClick={() => {
                filesSaver({
                  endpoint: `documents/${signedFile.downloadToken || ''}`,
                  fileName: signedFile.name,
                });
              }}
            />
          ) : (
            receipt?.downloadToken && (
              <DownloadIconButton
                title={i18n.t('actions.downloadReceipt')}
                onClick={() => {
                  filesSaver({
                    endpoint: `documents/${receipt.downloadToken || ''}`,
                    fileName: receipt.name,
                  });
                }}
              />
            )
          )}
          {scope.update && order.status === AptlyOrderStatus.New && (
            <DoneIconButton title={i18n.t('actions.complete')} onClick={handleOnNewOrder} />
          )}
          {needUserSign && (
            <FragmentScope scope={AptlyScopes.ProjectSigning} crud="U">
              <SignIconButton onClick={handleSign} />
            </FragmentScope>
          )}
          <GoToIconButtonLink to={orderTo} />
        </ActionBar>
      </TableCell>
    </TableRow>
  );
}

const StyledTableCell = styled(TableCell)`
  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    display: none;
  }
`;
