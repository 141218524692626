import React, { Fragment } from 'react';
import styled from 'styled-components';
import Close from '@mui/icons-material/Close';
import Button from '../../mui/Button';
import Avatar from '../../components/Avatar';
import Image from './Image';
import Grid from '../../mui/Grid';
import i18n from '../../libraries/i18n';
import { acceptImages, DropZoneProps } from '../../libraries/react-dropzone/drop-zone.utils';
import { FileDropzone } from '../../libraries/react-dropzone/DropZone';

type ImageFile = File & {
  preview: string;
};

interface Props extends DropZoneProps {
  images?: ImageFile[];
  profile?: boolean;
  defaultImage?: string;
  onComplete?: (arg0: ImageFile | ImageFile[]) => void;
  onDelete?: (images: ImageFile[] | string) => void;
  width?: string;
  height?: string;
  size?: string;
  disabled?: boolean;
  required?: boolean;
}

type State = {
  images: ImageFile[];
  editing: boolean;
};

class ImageUpload extends React.Component<Props, State> {
  state = {
    images: this.props.images || [],
    editing: false,
  };

  shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    const { defaultImage } = this.props;
    const { images } = this.state;

    if (images.length !== nextState.images.length || defaultImage !== nextProps.defaultImage) {
      return true;
    }

    return false;
  }

  render() {
    const { label, profile, defaultImage, onComplete, onDelete, width, height, size, multiple, required } =
      this.props;
    const { images, editing } = this.state;

    const isMultiple = !(typeof multiple === 'undefined');

    const Action = ({ index }: any) => (
      <PreviewAction>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            const { images } = this.state;
            const newImages = [...images];
            newImages.splice(index, 1);

            this.setState({
              images: newImages,
              editing: newImages.length === 0,
            });

            if (typeof onDelete === 'function') {
              onDelete(newImages);
            }
          }}
        >
          <Close />
        </Button>
      </PreviewAction>
    );

    if (!editing && (images.length > 0 || defaultImage)) {
      return (
        <Preview>
          {profile ? (
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                {images.length > 0 ? (
                  <Fragment>
                    <Avatar
                      src={images[0].preview}
                      alt={i18n.t('singles.profileImage')}
                      size={size || '10rem'}
                    />
                    <Action index={0} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <Avatar
                      src={defaultImage || '#'}
                      alt={i18n.t('actions.uploadImage')}
                      size={size || '10rem'}
                    />
                    <Action index={0} />
                  </Fragment>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems="center" justifyContent="center">
              {images.length > 0 ? (
                images.map((image, i) => (
                  <Grid item key={image.preview}>
                    <Image src={image.preview} alt={i18n.t('paragraphs.selectedImage')} width={100} />
                    <Action index={i} />
                  </Grid>
                ))
              ) : (
                <Grid>
                  <Image src={defaultImage || '#'} alt={i18n.t('actions.uploadImage')} />
                  <Action index={0} />
                </Grid>
              )}
            </Grid>
          )}
        </Preview>
      );
    }

    return (
      <Wrapper>
        <FileDropzone
          // @ts-ignore
          onDrop={async (images: ImageFile[]) => {
            this.setState({ images, editing: false });
            if (typeof onComplete === 'function') {
              onComplete(images.length === 1 ? images[0] : images);
            }
          }}
          multiple={isMultiple}
          accept={acceptImages}
          width={width}
          height={height}
          label={label}
          required={required}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Preview = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const PreviewAction = styled.div`
  display: table;
  margin: auto;
  margin-top: -1rem;
`;

export default ImageUpload;
