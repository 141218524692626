import { useEffect } from 'react';

export function useOnBeforeUnload(dirty: boolean) {
  useEffect(() => {
    if (dirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [dirty]);
}
