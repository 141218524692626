import { signal } from '@preact/signals-react';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AptlyUser } from '@aptly-as/types';
import handleError from '../../containers/Error/handleError';
import apiRequest from '../../libraries/fetch/apiRequest';
import { MOBILE_BREAK } from '../../utils/themes';

export const user = signal<AptlyUser | null>(null);

export interface UserState {
  data: AptlyUser;
  loggedIn: boolean;
  locale: string;
  busy: boolean;
  init: boolean;
  mode: 'light' | 'dark';
  mobile: boolean;
}

interface Context extends UserState {
  refresh: () => Promise<AptlyUser | null>;
  logout: () => void;
  toggleMode: () => void;
}

export const UserContext = createContext<Context>({
  data: {} as AptlyUser,
  refresh: () => Promise.resolve({} as AptlyUser),
  toggleMode: () => {},
  logout: () => {},
  busy: false,
  locale: 'no',
  loggedIn: false,
  init: false,
  mode: 'light',
  mobile: false,
});

const THEME_MODE = 'theme-mode';
export function useUser() {
  const { data } = useContext(UserContext);
  return data;
}

export default function UserProvider({ children }: PropsWithChildren<object>) {
  const init = useRef(false);
  const [state, setState] = useState<UserState>({
    data: user.value as AptlyUser,
    init: false,
    loggedIn: false,
    busy: true,
    locale: 'no',
    mode: (localStorage.getItem(THEME_MODE) || 'light') as UserState['mode'],
    mobile: window.innerWidth <= MOBILE_BREAK,
  });
  const refresh = useCallback(async () => {
    try {
      setState((s) => ({ ...s, busy: true }));
      const data = await apiRequest<AptlyUser>('users/me');
      user.value = data;
      setState((s) => ({
        data,
        busy: false,
        loggedIn: true,
        init: true,
        locale: data.language || 'no',
        mode: s.mode,
        mobile: s.mobile,
      }));
      return data;
    } catch (error) {
      user.value = null;
      setState((s) => {
        if (s.loggedIn) {
          handleError(error);
        }
        return { ...s, busy: false, loggedIn: false, init: true };
      });
      return null;
    }
  }, []);

  const logout = useCallback(() => {
    user.value = null;
    setState((s) => ({ ...s, loggedIn: false }));
  }, []);

  const toggleTheme = useCallback(() => {
    setState((s) => {
      const mode = s.mode === 'light' ? 'dark' : 'light';
      localStorage.setItem(THEME_MODE, mode);
      return { ...s, mode };
    });
  }, []);

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      refresh().then();
    }
  }, []);

  if (!state.init) {
    return null;
  }

  return (
    <UserContext.Provider value={{ ...state, refresh, logout, toggleMode: toggleTheme }}>
      {children}
    </UserContext.Provider>
  );
}
