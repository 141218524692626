import styled, { css } from 'styled-components';

import { darkerGray } from '../utils/themes';

const StatusText = styled.span<{ noUppercase?: boolean; block?: boolean }>`
  display: inline-block;
  font-size: 0.9em;
  color: ${darkerGray};
  font-weight: 300;

  ${(props) =>
    !props.noUppercase &&
    css`
      text-transform: uppercase;
    `};

  ${(props) =>
    props.block &&
    css`
      display: block;
    `};
`;

export default StatusText;
