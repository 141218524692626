import React, { createContext, PropsWithChildren, useCallback } from 'react';
import Dialog from '../../mui/Dialog';
import { ICreateModelOptions, IModal as ModalType } from './modal.types';
import { deleteModal, modals, newModal } from './modal.utils';

type ContextProps = {
  createModal: (element: React.ReactElement, opts?: ICreateModelOptions) => void;
  deleteModal: (id: string) => void;
};

export const ModalContext = createContext<ContextProps>({
  createModal: () => {},
  deleteModal: () => {},
});

export function ModalProvider({ children }: PropsWithChildren<object>) {
  const createModal = useCallback((element: React.ReactElement, opts: ICreateModelOptions = {}) => {
    newModal(element, opts);
  }, []);
  const deleteModal = useCallback((id: string) => {
    modals.value.splice(
      modals.value.findIndex((x) => x.id === id),
      1
    );
  }, []);

  return (
    <ModalContext.Provider value={{ createModal, deleteModal }}>
      {children}
      <Modals />
    </ModalContext.Provider>
  );
}

function Modals() {
  return (
    <>
      {modals.value.map((m) =>
        !m.naked ? (
          <Modal key={m.id} m={m}>
            {m.element}
          </Modal>
        ) : (
          m.element
        )
      )}
    </>
  );
}

function Modal({ m, children }: React.PropsWithChildren<{ m: ModalType }>) {
  const handleOnClose = useCallback(() => {
    if (m.disableEscape) return;
    deleteModal(m.id);
  }, [m.disableEscape]);

  return (
    <Dialog
      key={m.id}
      open={true}
      maxWidth={m.width}
      fullScreen={m.fullScreen}
      scroll={m.scroll}
      onClose={handleOnClose}
    >
      {children}
    </Dialog>
  );
}

// @deprecated. Use ModalButton or dialog directly.
function createModal(content: React.ReactElement, opts: ICreateModelOptions = {}): string {
  const modal = newModal(content, opts);
  return modal.id;
}

export { createModal };
