import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import StyledExpansionPanel, { AccordionSummary, AccordionTitle, AccordionSubtitle } from '../mui/Accordion';

export type IAccordionProps = {
  title?: React.ReactNode;
  subtitle?: string;
  expanded?: boolean;
  disabled?: boolean;
  onChange?: (expanded: boolean) => void;
  children: () => React.ReactNode;
};

type State = {
  expanded: boolean;
  defaultExpanded?: boolean;
};

class Accordion extends React.Component<IAccordionProps, State> {
  state = {
    expanded: this.props.expanded || false,
  };

  constructor(props: IAccordionProps) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IAccordionProps>) {
    if (this.props.expanded !== prevProps.expanded) {
      this.setState({ expanded: this.props.expanded || false });
    }
  }

  handleOnChange() {
    const expanded = !this.state.expanded;
    this.setState({ expanded });
    if (this.props.onChange) {
      this.props.onChange(expanded);
    }
  }

  render() {
    const { title, subtitle, disabled, children } = this.props;
    const { expanded } = this.state;
    return (
      <StyledExpansionPanel disabled={disabled} expanded={expanded} onChange={this.handleOnChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionTitle>{title}</AccordionTitle>
          {subtitle && <AccordionSubtitle>{subtitle}</AccordionSubtitle>}
        </AccordionSummary>
        {expanded && children()}
      </StyledExpansionPanel>
    );
  }
}

export default Accordion;
