import { lazy, ReactNode, Suspense } from 'react';
import { tightSpacing } from '../utils/spacing';
import Grid from '../mui/Grid';
import { Label } from '../mui/Input';
import type { IMarkdownProps } from './Markdown';
const Markdown = lazy(() => import('./Markdown'));

export { IMarkdownProps };

export interface IMarkdownEditorProps extends IMarkdownProps {
  label?: ReactNode;
  disabled?: boolean;
}

export default function MarkdownEditor({ label, disabled, ...markdownProps }: IMarkdownEditorProps) {
  return (
    <Grid container spacing={tightSpacing} direction="column">
      {label && (
        <Grid item>
          <Label>{label}</Label>
        </Grid>
      )}
      <Grid item>
        {disabled ? (
          <>Content</>
        ) : (
          <Suspense fallback={<></>}>
            <Markdown {...markdownProps} />
          </Suspense>
        )}
      </Grid>
    </Grid>
  );
}
