import { Suspense, useCallback, useMemo } from 'react';
import { draftToHtml, isStringDraftJS } from '../../../libraries/draft-js';
import { parseMarkdown } from '../../../libraries/markdown';
import MarkdownEditor, { IMarkdownEditorProps, IMarkdownProps } from '../../MarkdownEditor';
import { ISimpleComponentProps } from './simple-components.utils';

interface SimpleMarkdownProps<T extends object>
  extends ISimpleComponentProps<T, any>,
    Omit<IMarkdownProps, 'value' | 'onChange'> {}

export default function SimpleMarkdown<T extends object>({
  field,
  onChange,
  ...props
}: SimpleMarkdownProps<T>) {
  const { id, defaultValue, value, type, ...rest } = field;
  const _value = useMemo(() => {
    if (isStringDraftJS(value)) {
      return parseMarkdown(draftToHtml(value));
    }
    return value;
  }, [value]);

  const handleOnChange: IMarkdownEditorProps['onChange'] = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Suspense fallback={<>...</>}>
      <MarkdownEditor value={_value} onChange={handleOnChange} {...rest} {...props} />
    </Suspense>
  );
}
