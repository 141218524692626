import styled from 'styled-components';
import i18n from '../libraries/i18n';
import { intlCurrency } from '../libraries/intl';

interface CostProps {
  cost?: number;
}

export function Cost({ cost }: CostProps) {
  if (typeof cost !== 'number') {
    return null;
  }
  return (
    <CostCard>
      <strong>{cost ? intlCurrency(cost) : i18n.t('singles.free')}</strong>
      {cost ? <div>/ mnd</div> : null}
    </CostCard>
  );
}

const CostCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  text-align: center;
  border: 1px solid ${(props) => props.theme.palette.primary.light};
  box-shadow: ${(props) => props.theme.shadows[1]};
  border-radius: 50%;
`;
