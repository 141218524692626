import { useGet } from '@ewb/reach-react';
import { useCallback, useContext, useMemo } from 'react';
import { AptlyFieldType, AptlyJob, AptlyJobQueue, AptlyPeriod, AptlyUnit } from '@aptly-as/types';
import { Trans } from 'react-i18next';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { DoneIconButton } from '../../components/actions/icons/Icons';
import ApiError from '../../components/ApiError';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import List, { ListItemCheckbox } from '../../components/List/List';
import CrudForm, { CrudFormTextField, useCrudFormField } from '../../components/simple/CrudForm';
import { JobsContext } from '../../containers/Job/Jobs.context';

import { createModal } from '../../containers/Modal/ModalContext';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { matchId } from '../../libraries/mongoose';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Grid from '../../mui/Grid';
import { Checkbox } from '../../mui/Input';
import Paper from '../../mui/Paper';
import Table, { TableBody, TableCell, TableFooter, TableHead, TableRow } from '../../mui/Table';
import Typography from '../../mui/Typography';
import { useOrganization } from '../Organization/OrganizationContext';
import { UnitProduct } from '../UnitTemplate/unit-template.types';
import HandleOrder, { handleOrderModelOptions } from './HandleOrder/HandleOrder';

interface AddedToNextOrderResponse {
  message: 'productsMarkedForNextOrder';
}

interface Props {
  endpoint: string;
  products: UnitProduct[];
  onSave: () => void;
  unit: AptlyUnit;
  onClose?: () => void;
  onJobWait: (busy: boolean) => void;
}

interface Body {
  name: string;
  period: string;
  filter: string[];
}

const schema = (): ICrudSchema<Body> => ({
  name: {
    type: AptlyFieldType.Text,
    label: i18n.t('actions.addToOrderNumber'),
    defaultValue: '',
  },
  period: {
    type: AptlyFieldType.Text,
    label: i18n.t('singles.deadline'),
    defaultValue: '',
  },
  filter: {
    type: AptlyFieldType.Select,
    label: i18n.t('singles.filter'),
    defaultValue: [],
  },
});

export default function ExtraOrder({ unit, endpoint, onClose, onSave, products, onJobWait }: Props) {
  const { spawnJob } = useContext(JobsContext);
  const organization = useOrganization();
  const handleOnCreate = useCallback(
    (res: AddedToNextOrderResponse | AptlyJob) => {
      if ('message' in res && res.message === 'productsMarkedForNextOrder') {
        onSave();
        onClose!();
        return;
      }
      onJobWait(true);
      spawnJob(res as AptlyJob, {
        queue: AptlyJobQueue.Orders,
        label: i18n.t('actions.createOrder'),
        organization: organization?._id || '',
        action: ({ job, onClose }) => {
          onSave();
          onJobWait(false);
          return (
            <DoneIconButton
              title={i18n.t('actions.complete')}
              color="primary"
              onClick={() => {
                onClose();
                createModal(
                  <HandleOrder order={job.returnvalue.order} unit={unit} />,
                  handleOrderModelOptions
                );
              }}
            />
          );
        },
        progressSteps: [
          { progress: 25, label: i18n.t('statuses.creating') },
          { progress: 50, label: i18n.t('singles.documents') },
          { progress: 75, label: i18n.t('statuses.saving') },
        ],
      });
      onClose!();
    },
    [onSave, spawnJob, onJobWait, organization, onClose, unit]
  );

  return (
    <CrudForm<Body, AddedToNextOrderResponse | AptlyJob>
      path={endpoint}
      schema={useMemo(schema, [])}
      data={useMemo(
        () => ({
          filter: products.map((x) => x._id!),
        }),
        [products]
      )}
      onEdit={handleOnCreate}
    >
      <ModalTitle>{i18n.t('singles.extraOptions')}</ModalTitle>
      <ModalContent>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography variant="h3">{i18n.t('singles.products')}</Typography>
            <SelectProducts products={products} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3">{i18n.t('singles.actions')}</Typography>
            <SelectPeriod unit={unit} />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <ActionButtons onClose={onClose} />
      </ModalActions>
    </CrudForm>
  );
}

function SelectProducts({ products }: Pick<Props, 'products'>) {
  const { field, onChange } = useCrudFormField<Body, 'filter'>('filter');

  const handleOnChange = useCallback(
    (product: UnitProduct, checked: boolean) => {
      if (checked) {
        onChange(field.value.filter((x) => x !== product._id));
      } else {
        onChange([...field.value, product._id!]);
      }
    },
    [products, onChange, field.value]
  );

  if (!field.value) {
    return null;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{i18n.t('singles.name')}</TableCell>
          <TableCell align="right">{i18n.t('actions.add')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map((product) => (
          <SelectProductItem
            key={product._id}
            product={product}
            filter={field.value}
            onChange={handleOnChange}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell>{i18n.t('singles.total')}</TableCell>
          <TableCell align="right">{field.value.length}</TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}

interface SelectProductItemProps {
  product: UnitProduct;
  filter: string[];
  onChange: (p: UnitProduct, checked: boolean) => void;
}

function SelectProductItem({ product, filter, onChange }: SelectProductItemProps) {
  const checked = filter.includes(product._id!);
  const handleOnChange = useCallback(() => {
    onChange(product, checked);
  }, [onChange, product, checked]);

  const prod =
    product.variant && product.product?.variants
      ? product.product.variants.find((x) => x._id === product.variant) || product.product
      : product.product;
  const name = prod?.name || product.text;
  return (
    <TableRow key={product._id}>
      <TableCell>{name}</TableCell>
      <TableCell align="right">
        <Checkbox checked={checked} onChange={handleOnChange} />
      </TableCell>
    </TableRow>
  );
}

function SelectPeriod({ unit }: Pick<Props, 'unit'>) {
  const { field, onChange } = useCrudFormField<Body, 'period'>('period');
  const path = useApiUrl(SlugLevel.Unit, 'periods');
  const [periods] = useGet<AptlyPeriod[]>(
    path,
    useMemo(
      () => ({
        onGet: (res) => {
          if (res && res.length) {
            if (res.some((p) => !unit.confirmedPeriods.some((cp) => matchId(cp.period, p)))) {
              onChange(res[0]._id);
            }
          }
        },
      }),
      [onChange, unit]
    )
  );

  const activePeriods = useMemo(() => {
    if (!periods.data) return [];
    return periods.data.filter((p) => !unit.confirmedPeriods.some((cp) => matchId(cp.period, p)));
  }, [unit, periods]);

  return (
    <Grid container flexDirection="column" gap={2}>
      {periods.error && <ApiError error={periods.error} />}
      <List component={Paper}>
        {activePeriods.map((period) => {
          const periodName = period.name;
          return (
            <ListItemCheckbox
              key={period._id}
              item={period._id}
              primary={i18n.t('actions.addToCart')}
              secondary={
                <Trans
                  i18nKey="actions.addToPeriod"
                  values={{ name: periodName }}
                  components={{ 1: <strong /> }}
                  defaults="Add products to period <1>{{name}}</1>"
                />
              }
              checked={field.value === period._id}
              onClick={onChange}
            />
          );
        })}
        <ListItemCheckbox
          checked={field.value === ''}
          item=""
          onClick={onChange}
          primary={i18n.t('actions.produceNewOrder')}
          secondary={i18n.t('actions.sendDirectlyToCustomer')}
        />
      </List>
      {field.value === '' && <CrudFormTextField name="name" />}
    </Grid>
  );
}
