import { Route, Routes, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { AptlyApp } from '@aptly-as/types';
import { OrganizationContext } from '../Organization/OrganizationContext';
import AppInject from './AppInject';
import { AppProvider } from './AppContext';
import { byAppConfigIdOrSlug } from './app.utils';

export default function AppProject() {
  const { app } = useParams();
  const org = useContext(OrganizationContext);
  const config = org.data.apps?.find(byAppConfigIdOrSlug(app!));

  if (!config) {
    return <>Config not found</>;
  }

  return (
    <AppProvider value={{ config, app: config.app as AptlyApp }}>
      <Routes>
        <Route path=":inject" element={<AppInject />} />
      </Routes>
    </AppProvider>
  );
}
