import { ReachError } from '@ewb/reach-react';
import EmptyState, { EmptyStateProps } from './EmptyState';
import i18n from '../../libraries/i18n';
import { ApiError } from './createError';

interface Props extends Omit<EmptyStateProps, 'children'> {
  error?: ApiError | ReachError<ApiError>;
  text?: string;
}

const ErrorState = ({ text = i18n.t('paragraphs.somethingWentWrong'), error, ...rest }: Props) => {
  if (error instanceof ReachError) {
    const message = error.body?.error || error.message;
    return (
      <EmptyState {...rest}>
        {error.status}: ${message}
      </EmptyState>
    );
  }
  const content = error ? error.error : text;
  return <EmptyState {...rest}>{content}</EmptyState>;
};

export default ErrorState;
