import { useMemo } from 'react';
import { useAlgorithms } from '../../../models/Algorithm/useAlgorithms';
import { usePeriods } from '../../../models/Period/usePeriods';
import { Category, UnitProduct } from '../../../models/UnitTemplate/unit-template.types';
import SimpleCrud from '../../SimpleCrud';
import i18n from '../../../libraries/i18n';
import {
  crudProductAlgorithmFields,
  crudProductCustomTextDescription,
  crudProductDocument,
  crudProductFromDB,
  crudProductPrerequisitesTotalCost,
  crudProductProducer,
  crudProductProductField,
  crudProductQuantity,
  crudProductSelectVariantField,
  crudProductSimpleProductText,
  crudProductUnitCost,
  crudUnitTemplateCategory,
} from './crudCreateProduct/fields';
import { useSlugs } from '../../../hooks/useGetApiUrl';
import { crudFieldPeriod } from '../helpers/fields';

interface Props {
  endpoint: string;
  onSave: (item: any) => void;
  unitProduct?: UnitProduct;
  onClose?: () => void;
  customAuth?: string;
  showPrerequisites?: boolean;
  showDBProduct?: boolean;
  simpleProductInit?: boolean;
  disableAlgorithms?: boolean;
  disablePeriods?: boolean;
  categories?: Category[];
}

export default function CrudCreateUnitProduct({
  endpoint,
  unitProduct,
  onSave,
  onClose,
  customAuth,
  showPrerequisites,
  showDBProduct,
  disableAlgorithms,
  disablePeriods,
  simpleProductInit,
  categories,
}: Props) {
  const algorithms = useAlgorithms();
  const periods = usePeriods();
  const [organizationSlug, projectID] = useSlugs();
  const hasRevertRef = unitProduct && unitProduct.revertItemRef;
  const categoryOptions = useMemo(() => {
    if (!categories) return [];
    return categories.map((x) => ({ value: x._id, label: x.name }));
  }, [categories]);

  return (
    <SimpleCrud
      title={unitProduct ? i18n.t('actions.changeProduct') : i18n.t('actions.addProduct')}
      onClose={onClose}
      id={unitProduct ? unitProduct._id : undefined}
      endpoint={endpoint}
      onCreate={onSave}
      multipart
      actionLabel={unitProduct ? i18n.t('actions.save') : i18n.t('actions.add')}
      fields={[
        ...(showDBProduct
          ? [
              crudProductFromDB(unitProduct, !simpleProductInit),
              crudProductProductField(organizationSlug, projectID, unitProduct),
            ]
          : []),
        crudProductSelectVariantField(unitProduct),
        crudProductSimpleProductText(unitProduct),
        crudProductCustomTextDescription(unitProduct),
        crudProductProducer(unitProduct),
        crudProductQuantity(unitProduct),
        crudProductUnitCost(unitProduct),
        ...(!disablePeriods ? [crudFieldPeriod(periods, unitProduct)] : []),
        ...(!hasRevertRef && !disableAlgorithms
          ? crudProductAlgorithmFields(algorithms, unitProduct, true)
          : []),
        crudUnitTemplateCategory(categoryOptions, unitProduct),
        crudProductDocument(unitProduct),
        ...(showPrerequisites ? [crudProductPrerequisitesTotalCost(unitProduct)] : []),
      ]}
      customAuth={customAuth}
    />
  );
}
