import { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';

type ChangeEventProps<V> = ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | V;
export default function useCrudState<V>(
  defaultV: V,
  save: (value: V) => Promise<void> | void,
  autoSave?: boolean
): [V, (v: ChangeEventProps<V>) => void, () => void] {
  const ref = useRef(defaultV);
  const [state, setState] = useState(defaultV);

  useEffect(() => {
    if (ref.current !== defaultV) {
      ref.current = defaultV;
      setState(defaultV);
    }
  }, [defaultV]);

  const saveFn = useCallback(
    (e?: FormEvent) => {
      if (e && typeof e === 'object' && 'preventDefault' in e) {
        e.preventDefault();
      }
      save(state);
    },
    [state, save]
  );

  const set = useCallback(
    (v: ChangeEventProps<V>) => {
      if (v && typeof v === 'object' && 'target' in v) {
        return setState(v.target.value as any);
      }
      setState(v);
      if (autoSave) {
        save(v);
      }
    },
    [autoSave, save]
  );

  return [state, set, saveFn];
}
