import Button, { ButtonProps } from '@mui/material/Button';
import { cloneElement, forwardRef, ReactElement, useCallback, useEffect, useState } from 'react';
import Dialog, { DialogProps } from '../../mui/Dialog';

export interface IModalButtonProps extends Omit<ButtonProps, 'onClick' | 'children'> {
  children: ReactElement;
  label: string;
  dialogProps?: Omit<DialogProps, 'open' | 'onClose'>;
  onClose?: () => void;
  open?: boolean;
}

export const ModalButton = forwardRef<HTMLButtonElement, IModalButtonProps>(function ModalButton(
  { open: propsOpen, label, children, dialogProps, onClose, ...props },
  ref
) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (propsOpen) setOpen(true);
  }, [propsOpen]);

  const toggleOpen = useCallback(() => setOpen(true), [onClose]);

  const handleOnClose = useCallback(() => {
    setOpen(false);
    if (onClose) onClose();
  }, [onClose]);

  return (
    <>
      <Button variant="contained" color="primary" {...props} onClick={toggleOpen} ref={ref}>
        {label}
      </Button>
      <Dialog open={open} onClose={handleOnClose} {...dialogProps}>
        {cloneElement(children, { onClose: handleOnClose })}
      </Dialog>
    </>
  );
});
