import Grid from '@mui/material/Grid';
import Typography from '../../mui/Typography';

interface IListItemTextPrimaryWithDateProps {
  title: string;
  date?: string | null;
}

export const ListItemTextPrimaryWithDate = ({ title, date }: IListItemTextPrimaryWithDateProps) => (
  <Grid container justifyContent="space-between" alignItems="flex-start">
    <Grid item xs={date ? 8 : 12}>
      <Typography variant="body2">{title}</Typography>
    </Grid>
    {date && (
      <Grid container item xs={4} justifyContent="flex-end">
        <Typography variant="subtitle2">{date}</Typography>
      </Grid>
    )}
  </Grid>
);
