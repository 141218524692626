import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { StaticDatePickerProps } from '@mui/x-date-pickers/StaticDatePicker';
import { DatePickerCustom } from '../../mui/x-date-picker/DatePicker';
import { BookingContext } from './BookingContext';

export default function BookingDatePicker(
  props: Omit<StaticDatePickerProps<Dayjs>, 'renderInput' | 'value'>
) {
  const {
    state: { report, date },
  } = useContext(BookingContext);
  const value = dayjs(date);

  const customComponents = useMemo(
    () => [
      {
        days: report.booked,
        Component: BookedDot,
      },
      {
        days: report.available,
        Component: AvailableDot,
      },
    ],
    [report]
  );

  return (
    <DatePickerCustom
      displayStaticWrapperAs="desktop"
      customComponents={customComponents}
      value={value}
      {...props}
    />
  );
}

export const DotRoot = styled.span`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
`;

export const AvailableDot = styled(DotRoot)`
  background-color: ${(props) => props.theme.palette.primary.main};
`;

export const BookedDot = styled(DotRoot)`
  background-color: ${(props) => props.theme.palette.secondary.main};
`;
