import styled from 'styled-components';

const PositionParent = styled.div`
  position: relative;
  height: 0px;
  overflow: visible;
  z-index: 1;
`;

export { PositionParent };
