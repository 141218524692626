import { AptlyProject, AptlyScopes } from '@aptly-as/types';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useCallback, useContext, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { LogicAnimate } from 'react-logic-animate';
import { FavoriteIconToggle } from '../../components/actions/icons/FavoriteIconToggle';
import Avatar from '../../components/Avatar';
import Centered from '../../components/Centered';
import Search, { SearchFieldSelect, SearchFieldText } from '../../components/Search/Search';
import SearchCreateAction from '../../components/Search/search-actions/SearchCreateAction';
import SearchImportAction from '../../components/Search/search-actions/SearchImportAction';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import StatusText from '../../components/StatusText';
import { useAddressString } from '../../hooks/useAddressString';
import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import ComponentLink from '../../libraries/router/ComponentLink';
import Card, { CardContent, CardHeader } from '../../mui/Card';
import Grid from '../../mui/Grid';
import { LinearProgresser } from '../../mui/Progresser';
import Typography from '../../mui/Typography';
import { rawSpacing } from '../../utils/spacing';
import { firstAndFinal } from '../Period/periods.utils';
import { UserContext } from '../User/UserContext';
import { useProjectLogo, useProjectSubheader } from './project.hooks';
import projectSchema, { IProjectSchema, projectImportSchema, projectStatusOptions } from './project.schema';
import { useProjectFavorite } from './useProjectFavorite';

const StyledCard: any = Card;
const InfiniteScrollFix: any = InfiniteScroll;

function Projects() {
  const { data } = useContext(UserContext);
  const endpoint = useApiUrl(SlugLevel.Organization, 'projects');
  const favorite = useProjectFavorite();

  const buttonActions = useCallback(
    () => [
      <SearchImportAction key="import" path={`${endpoint}/import`} schema={projectImportSchema()} />,
      <SearchCreateAction key="new" title={i18n.t('actions.newProject')} />,
    ],
    [endpoint]
  );

  const searchFields = useMemo(
    () => [
      <SearchFieldText key="name" field="name" label={i18n.t('singles.name')} autoFocus />,
      <SearchFieldText key="number" field="number" label={i18n.t('singles.number')} />,
      <SearchFieldSelect
        key="status"
        field="status"
        label={i18n.t('singles.status')}
        options={projectStatusOptions(true)}
      />,
    ],
    []
  );

  return (
    <Search<IProjectSchema>
      path={endpoint}
      searchPath="/search"
      patchPath={toSearchPatchPath('projects')}
      scope={AptlyScopes.Project}
      header={{ title: i18n.t('singles.projects') }}
      reach={{ query: { archived: false } }}
      patch={{ title: '' }}
      post={{ title: i18n.t('actions.newProject') }}
      schema={projectSchema(data)}
      fields={['name', 'template', 'users']}
      searchFields={searchFields}
      buttonActions={buttonActions}
    >
      {(projects, actions, info) => {
        return (
          <InfiniteScrollFix
            loadMore={() => actions.next()}
            hasMore={!info.busy && projects.length < info.count}
          >
            <Grid container spacing={rawSpacing}>
              {projects.map((project) => (
                <Project key={project._id} project={project} onFavorite={favorite} />
              ))}
            </Grid>
          </InfiniteScrollFix>
        );
      }}
    </Search>
  );
}

interface ProjectProps {
  project: AptlyProject;
  onFavorite: (p: AptlyProject) => void;
}

function Project({ project, onFavorite }: ProjectProps) {
  const { data } = useContext(UserContext);
  const firstAndFinalPeriods = firstAndFinal(project.periods);
  const handleOnFavorite = useCallback(() => onFavorite(project), [onFavorite, project]);
  const address = useAddressString(project.address);
  const logo = useProjectLogo(project);
  const subheader = useProjectSubheader(project);
  const to = useTo(SlugLevel.Organization);

  return (
    <Grid key={project._id} item xs={12} md={6} lg={4}>
      <LogicAnimate key={project._id} animateProps={{ transitionTime: '100ms' }}>
        <StyledCard component={ComponentLink} to={`${to}/prosjekter/${project._id}`}>
          <CardHeader
            title={project.name}
            avatar={logo ? <Avatar alt="avatar" src={logo} variant="square" contain /> : undefined}
            subheader={subheader}
            action={
              <FavoriteIconToggle
                favorite={project.userFavorites?.includes(data._id)}
                onClick={handleOnFavorite}
              />
            }
          />
          <CardContent>
            <Grid container spacing={rawSpacing} direction="column">
              <Grid item>
                {address && <Typography variant="caption">{address}</Typography>}
                <LinearProgresser variant="determinate" value={firstAndFinalPeriods.percent} />
              </Grid>
              <Grid item>
                {project.periods.length > 0 ? (
                  <Grid container spacing={rawSpacing} justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="body2">
                        {firstAndFinalPeriods.first?.format('DD.MM.YY') || ''}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ArrowForward />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {firstAndFinalPeriods.final?.format('DD.MM.YY') || ''}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Centered height="28px">
                    <StatusText>{i18n.t('statuses.noPeriods')}</StatusText>
                  </Centered>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
      </LogicAnimate>
    </Grid>
  );
}

export default Projects;
