import { Fragment, ReactNode } from 'react';
import styled from 'styled-components';
import CrudModal from '../deprecated/CrudModal';
import { ModalContent, ModalTitle } from '../mui/Dialog';
import Grid from '../mui/Grid';
import { rawSpacing } from '../utils/spacing';
import { AccordionBody } from '../mui/Accordion';
import Block from './Block';
import Accordion from './Accordion';
import { Field, Subdocument } from '../deprecated/inputs';

export interface ISimpleCrudProps<T> {
  endpoint: string;
  fields: Field[];
  subdocuments?: Subdocument[];
  id?: string;
  title?: string;
  multipart?: boolean;
  actionLabel?: string;
  onClose?: () => void;
  onCreate?: (arg0: T) => void;
  onDestroy?: (arg0: T) => void;
  onError?: (error: any) => void;
  deleteAction?: boolean;
  description?: ReactNode;
  customAuth?: string;
  deleteLabel?: string;
  defaultExpandGroup?: string;
}

/**
 * @deprecated
 */
function SimpleCrud<T>(props: ISimpleCrudProps<T>) {
  const {
    title,
    endpoint,
    fields,
    subdocuments,
    id,
    onClose,
    onCreate,
    onDestroy,
    actionLabel,
    multipart,
    deleteAction,
    description,
    customAuth,
    deleteLabel,
    onError,
    defaultExpandGroup,
  } = props;

  const suppliedFields = fields;
  const suppliedSubdocuments = subdocuments;

  return (
    <CrudModal<T>
      id={id}
      endpoint={endpoint}
      onClose={onClose}
      fields={suppliedFields}
      subdocuments={suppliedSubdocuments}
      actionLabel={actionLabel}
      multipart={multipart}
      editing
      cancelCloses
      deleteAction={deleteAction}
      customAuth={customAuth}
      onSave={(response) => {
        if (typeof onCreate === 'function') {
          onCreate(response);
        }
      }}
      onDestroy={(response) => {
        if (typeof onDestroy === 'function') {
          onDestroy(response);
        }
      }}
      refresh={() => {
        if (typeof onClose === 'function') {
          onClose();
        }
      }}
      deleteLabel={deleteLabel}
      onError={onError}
    >
      {({ fields, subdocuments }) => {
        const grouped = suppliedFields.filter((f) => f.group !== undefined);
        const groups: string[] = [];
        const matches: string[] = [];

        for (let i = 0; i < grouped.length; i++) {
          const group = grouped[i];

          if (group.group && !groups.includes(group.group)) {
            groups.push(group.group);
          }
        }

        const filteredFields = suppliedFields.filter((f) => {
          if (!f.group) {
            return true;
          }

          if (matches.includes(f.group)) {
            return false;
          }

          matches.push(f.group || '');
          return true;
        });

        return (
          <Fragment>
            {title && <ModalTitle>{title}</ModalTitle>}
            <ModalContent>
              <Grid container spacing={rawSpacing} direction="column">
                {description && (
                  <Grid item style={{ whiteSpace: 'pre-wrap' }}>
                    {description}
                  </Grid>
                )}
                {filteredFields.map((f, k) => {
                  if (f.hidden) {
                    return null;
                  }

                  const renderedField = fields[f.name]();

                  return renderedField ? (
                    <StyledLayout key={k} item xs={12}>
                      {!f.group ? (
                        renderedField
                      ) : (
                        <Accordion title={f.group} expanded={defaultExpandGroup === f.group}>
                          {() => (
                            <AccordionBody>
                              <Block>
                                <Grid container spacing={rawSpacing} direction="column">
                                  {suppliedFields
                                    .filter((s) => s.group === f.group)
                                    .map((s, k) => {
                                      const renderedField = fields[s.name]();
                                      return renderedField ? (
                                        <Grid key={k} item>
                                          {renderedField}
                                        </Grid>
                                      ) : null;
                                    })}
                                </Grid>
                              </Block>
                            </AccordionBody>
                          )}
                        </Accordion>
                      )}
                    </StyledLayout>
                  ) : null;
                })}
                {suppliedSubdocuments &&
                  suppliedSubdocuments.map((s, k) => {
                    const renderedSubdocument = subdocuments[s.name]();

                    return renderedSubdocument ? (
                      <StyledLayout key={k} item xs={12}>
                        {renderedSubdocument}
                      </StyledLayout>
                    ) : null;
                  })}
              </Grid>
            </ModalContent>
          </Fragment>
        );
      }}
    </CrudModal>
  );
}

const StyledLayout = styled(Grid)`
  max-width: 100% !important;
  * {
    max-width: 100% !important;
  }
`;

export default SimpleCrud;
