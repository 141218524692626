import React from 'react';
import Grid from '../mui/Grid';
import Chip from '../mui/Chip';
import { TextField } from '../mui/Input';
import Button from '../mui/Button';
import i18n from '../libraries/i18n';

interface Props {
  defaultValue?: string[];
  value?: string[];
  onChange: (value: string[]) => void;
  hasInputField?: boolean;
}

export default function ChipSelect({ defaultValue, value, onChange, hasInputField }: Props) {
  const [state, setState] = React.useState(defaultValue || []);
  const internalState = React.useMemo(() => value || state, [value, state]);

  const remove = React.useCallback(
    (i: number) => () => {
      const newState = internalState.filter((_x, vi) => vi !== i);
      setState(newState);
      onChange(newState);
    },
    [internalState, onChange]
  );

  const add = React.useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      const v = e.target.chipinput.value;
      const newState = [...internalState, v];
      setState(newState);
      onChange(newState);
      e.target.reset();
    },
    [internalState, onChange]
  );

  return (
    <Grid container direction="column" spacing={1}>
      {hasInputField && (
        <Grid container item component="form" onSubmit={add} alignItems="center">
          <Grid item>
            <TextField name="chipinput" label={i18n.t('actions.addTag')} />
          </Grid>
          <Grid item>
            <Button type="submit">{i18n.t('actions.add')}</Button>
          </Grid>
        </Grid>
      )}
      <Grid container item spacing={1}>
        {internalState.map((x, i) => (
          <Grid item key={i}>
            <Chip onClick={remove(i)} label={x} variant="outlined" onDelete={remove(i)} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
