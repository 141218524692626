import { IUseFieldRet } from '@ewb/reach-react';
import { IApiError } from '../../ApiError';
import { ICrudFieldData } from '../../crud/utils/crud.utils';
import { ISimpleComponentProps } from './simple-components.utils';
import ReferenceInput, { toReferenceItem, toReferenceValue } from '../../ReferenceInput';

export interface ISimpleReferenceProps<T extends object> extends ISimpleComponentProps<T, any> {
  crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>;
}

export default function SimpleReference<T extends object>({
  crud,
  field,
  onChange,
}: ISimpleReferenceProps<T>) {
  const { id, value, defaultValue, useReference, ...rest } = field;

  if (!useReference) {
    throw new Error(`SimpleReference was used without reference field ${JSON.stringify(field)}`);
  }

  const referenceProps = useReference();

  let fieldValue = Array.isArray(value) ? value : value ? [value] : [];

  if (crud.state.busy && fieldValue.some((x) => typeof x === 'string')) {
    return null;
  }

  return (
    <ReferenceInput<any>
      onSelect={(is) => {
        if (referenceProps.multiple && Array.isArray(is)) {
          onChange(is.map(toReferenceValue));
        } else if (Array.isArray(is) && is[0]) {
          onChange(toReferenceValue(is[0]));
        } else {
          onChange(null);
        }
      }}
      defaultSelected={fieldValue.filter(Boolean).map((x) => toReferenceItem(x, referenceProps.labelKey))}
      singular={!referenceProps.multiple}
      {...rest}
      {...referenceProps}
    />
  );
}
