import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/Breadcrumbs';
import ErrorBoundary from '../../components/ErrorBoundary';
import Header from '../../components/Header';
import Layout from '../../components/Layout';
import Main from '../../components/Main';
import Sidebar from '../../components/Sidebar';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import SidebarMenu from '../Sidebar/SidebarMenu';
import SidebarRoutes from '../Sidebar/SidebarRoutes';
import { adminRoutes } from './admin.routes';
import createCrumbs from './createCrumbs';

function Admin() {
  const routes = useMemo(() => adminRoutes(), []);

  return (
    <Layout>
      <Header
        head={[
          <Breadcrumbs key="bc">
            {createCrumbs(window.location.pathname).map((c) => (
              <BreadcrumbsItem key={c.url}>
                <Link to={c.url}>{c.label}</Link>
              </BreadcrumbsItem>
            ))}
          </Breadcrumbs>,
        ]}
      />
      <Sidebar>
        <SidebarMenu routes={routes} level={SlugLevel.Admin} injection="organization" />
      </Sidebar>
      <Main>
        <ErrorBoundary>
          <SidebarRoutes routes={routes}></SidebarRoutes>
        </ErrorBoundary>
      </Main>
    </Layout>
  );
}

export default Admin;
