import { useContext } from 'react';
import createMediaURL from '../Media/createMediaURL';
import StatusText from '../../components/StatusText';
import Logo from '../../components/Logo';
import { TextLink } from '../../libraries/router/ComponentLink';

import i18n from '../../libraries/i18n';
import { OrganizationContext } from './OrganizationContext';

function OrganizationHeader() {
  const org = useContext(OrganizationContext);

  if (!org || !org.data) {
    return <StatusText>{i18n.t('statuses.loading')}...</StatusText>;
  }

  return org.data.logo ? (
    <Logo key="logo" gfx={createMediaURL(org.data.logo)} link={`/organisasjoner/${org.data.slug}`} />
  ) : (
    <TextLink to={`/organisasjoner/${org.data.slug}`}>{org.data.name}</TextLink>
  );
}

export default OrganizationHeader;
