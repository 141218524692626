import {
  AptlyFieldType,
  AptlyMediaSrc,
  AptlyPage,
  AptlyPageSection,
  AptlyPageSectionType,
} from '@aptly-as/types';
import Typography from '@mui/material/Typography';
import { schemaName } from '../../components/crud/schema/fields.schema';
import {
  schemaUnitTemplateReference,
  toUseProjectReference,
} from '../../components/crud/schema/reference.schemas';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import i18n from '../../libraries/i18n';
import { acceptImages } from '../../libraries/react-dropzone/drop-zone.utils';
import Grid from '../../mui/Grid';
import { StyledIconButton } from './page.styles';
import { pageIcons } from './page.types';

export type ICrudPage = AptlyPage;

export const pageSchema = (): ICrudSchema<ICrudPage> => ({
  name: schemaName({
    autoFocus: true,
  }),
  upsellTemplate: schemaUnitTemplateReference(),
  summary: {
    type: AptlyFieldType.TextArea,
    defaultValue: '',
    label: i18n.t('paragraphs.linkDescription'),
  },
  icon: {
    type: AptlyFieldType.Custom,
    defaultValue: undefined,
    label: i18n.t('singles.icon'),
    customRender: (crud, field) => {
      const active = field.value;

      return (
        <>
          <Typography variant="subtitle2">{i18n.t('singles.icon')}</Typography>
          <Grid container spacing={2} wrap="wrap">
            {pageIcons.map((icon) => (
              <Grid item key={icon.value}>
                <StyledIconButton
                  size="large"
                  active={active === icon.value}
                  onClick={() => crud.setField('icon')(icon.value)}
                >
                  {icon.label}
                </StyledIconButton>
              </Grid>
            ))}
          </Grid>
        </>
      );
    },
  },
});

export function getPageSectionSchema(type: AptlyPageSectionType) {
  switch (type) {
    case AptlyPageSectionType.Text:
      return pageSectionTextSchema();
    case AptlyPageSectionType.Card:
      return pageSectionTextSchema();
    case AptlyPageSectionType.Gallery:
      return pageSectionGallerySchema();
    case AptlyPageSectionType.Image:
      return pageSectionImageSchema();
    case AptlyPageSectionType.Spacer:
      return pageSectionSpacesSchema();
    case AptlyPageSectionType.Links:
      return pageSectionLinksSchema();
    case AptlyPageSectionType.Recommendation:
      return pageSectionRecommendationSchema();
    case AptlyPageSectionType.Iframe:
      return pageSectionIframeSchema();
  }
}

export const pageSectionSchema = (): ICrudSchema<AptlyPageSection<any>> => ({
  type: {
    type: AptlyFieldType.Hidden,
    defaultValue: AptlyPageSectionType.Text,
    label: i18n.t('singles.type'),
  },
  name: schemaName(),
});

export const pageSectionTextSchema = (): ICrudSchema<AptlyPageSection<string>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.Markdown,
    defaultValue: '',
    label: i18n.t('singles.textContent'),
  },
});

export const pageSectionImageSchema = (): ICrudSchema<AptlyPageSection<AptlyMediaSrc | null>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.Image,
    defaultValue: null,
    label: i18n.t('singles.image'),
    preOnChange: (value) => {
      if (value) return value.src;
      return value;
    },
    image: {
      width: 540,
      accept: acceptImages,
      srcKey: 'image',
      crop: 'pad',
    },
  },
});

export const pageSectionIframeSchema = (): ICrudSchema<AptlyPageSection<AptlyMediaSrc | null>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.Text,
    defaultValue: null,
    label: i18n.t('singles.embed'),
  },
});

export const pageSectionRecommendationSchema = (): ICrudSchema<AptlyPageSection<string[]>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.Reference,
    defaultValue: [],
    label: i18n.t('singles.recommendations'),
    useReference: toUseProjectReference('recommendations'),
  },
});

export const pageSectionLinksSchema = (): ICrudSchema<AptlyPageSection<string[]>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.Reference,
    defaultValue: [],
    label: i18n.t('singles.links'),
    useReference: toUseProjectReference('pages', true),
  },
});

export const pageSectionGallerySchema = (): ICrudSchema<AptlyPageSection<AptlyMediaSrc[]>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.Images,
    defaultValue: [],
    label: i18n.t('singles.gallery'),
    preOnChange: (value) => {
      if (Array.isArray(value)) return value.map((x) => (typeof x === 'string' ? x : x.src));
      return [];
    },
    image: {
      width: 540,
      accept: acceptImages,
      srcKey: 'image',
      crop: 'pad',
    },
  },
});

export const pageSectionSpacesSchema = (): ICrudSchema<AptlyPageSection<AptlyMediaSrc[]>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.Select,
    defaultValue: [],
    label: i18n.t('singles.spacing'),
    options: [
      {
        value: '1',
        label: i18n.t('actions.small'),
      },
      {
        value: '2',
        label: i18n.t('statuses.medium'),
      },
      {
        value: '3',
        label: i18n.t('statuses.large'),
      },
    ],
  },
});
