import { AptlyUnit } from '@aptly-as/types';
import { Category } from '../../models/UnitTemplate/unit-template.types';
import Table, { TableBody } from '../../mui/Table';
import {
  UnitProductTableFooter,
  UnitProductTableHead,
  UnitProductTableRow,
} from '../../models/Unit/UnitProductTable';

interface Props {
  unit: AptlyUnit;
  categories: Category[];
}

export default function ThirdPartyExtraProducts({ unit, categories }: Props) {
  const { extraOptions } = unit;

  if (!extraOptions || extraOptions.length === 0) return null;

  let baseCost = 0;
  let quantity = 0;
  let total = 0;

  return (
    <Table style={{ width: '100%' }}>
      <UnitProductTableHead />
      <TableBody>
        {extraOptions.map((option) => {
          const cost = option.unitCost || 0;
          const finalCost = cost * option.quantity;

          baseCost += cost;
          quantity += option.quantity;
          total += finalCost;

          return (
            <UnitProductTableRow
              key={option._id}
              product={option}
              cost={cost}
              finalCost={finalCost}
              categories={categories}
            />
          );
        })}
      </TableBody>
      <UnitProductTableFooter baseCost={baseCost} quantity={quantity} total={total} />
    </Table>
  );
}
