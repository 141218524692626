import { IUseFieldRet } from '@ewb/reach-react';
import { useCallback } from 'react';
import { AccordionDeleteItem, AccordionDeleteList } from '../../AccordionDeleteList';
import { IApiError } from '../../ApiError';
import { ICrudFieldData } from '../../crud/utils/crud.utils';
import { ISimpleComponentProps } from './simple-components.utils';

interface ISimpleBulkIdsProps<T extends object> extends ISimpleComponentProps<T, any> {
  crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>;
}

export default function SimpleBulkIds<T extends object>({ crud, field, onChange }: ISimpleBulkIdsProps<T>) {
  // @ts-ignore
  const data: AccordionDeleteItem[] = crud.state.data.bulkData;
  const { value = [] } = field;

  const handleOnChange = useCallback((ids: string[]) => onChange(ids), [onChange]);

  return <AccordionDeleteList ids={value} data={data} onChange={handleOnChange} />;
}
