import { useContext } from 'react';
import styled from 'styled-components';
import i18n from '../../libraries/i18n';
import { intlDateFormat } from '../../libraries/intl';
import Button from '../../mui/Button';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Grid from '../../mui/Grid';
import Typography from '../../mui/Typography';
import { BookingContext } from './BookingContext';
import BookingFromTo from './BookingFromTo';
import BookingTimes from './BookingTimes';

interface BookingTimeAddProps {
  onClose?: () => void;
}

export function BookingTimeAdd({ onClose }: BookingTimeAddProps) {
  const booking = useContext(BookingContext);
  const date = booking.state.date.toDate();

  return (
    <>
      <ModalTitle>{i18n.t('actions.addClientMeetingsForTheDay')}</ModalTitle>
      <ModalContent>
        <Grid container justifyContent="center">
          <Grid container spacing={2}>
            <HeaderLayout item xs={12}>
              <Typography variant="subtitle1">{intlDateFormat(date)}</Typography>
            </HeaderLayout>
            <Grid item xs={6}>
              <Grid container direction="column">
                <TimePickWrapper>
                  <BookingFromTo />
                </TimePickWrapper>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">{i18n.t('paragraphs.availableSlotsThisDay')}</Typography>
              <BookingTimes hideDate />
            </Grid>
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          {i18n.t('actions.close')}
        </Button>
      </ModalActions>
    </>
  );
}

const HeaderLayout = styled(Grid)`
  text-align: center;
`;

const TimePickWrapper = styled.div`
  padding: 0.5rem;
  max-width: 100%;
`;
