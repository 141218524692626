import { AptlyProducer } from '@aptly-as/types';
import StatusText from '../../components/StatusText';

import { SectionProduct } from '../UnitTemplate/unit-template.types';
import i18n from '../../libraries/i18n';

/**
 * @deprecated todo: Should be rewritten
 * Weird function. Should be a more reusable component.
 */
function renderProducer(p: SectionProduct) {
  if (p.product) {
    return p.product.producer ? (
      (p.product.producer as AptlyProducer).name
    ) : (
      <StatusText>{i18n.t('statuses.nothing')}</StatusText>
    );
  }

  return <StatusText>{i18n.t('singles.NA')}</StatusText>;
}

export default renderProducer;
