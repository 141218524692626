import React from 'react';
import { useParams } from 'react-router-dom';

export enum SlugLevel {
  Base,
  Admin,
  User,
  Organization,
  Project,
  Unit,
}

/**
 * @deprecated. used to old api endpoints. Should use useApiUrl if connected to /api/v1
 */
export default function useGetApiUrl(
  level: 'organization' | 'project' | 'unit' = 'organization',
  path: string = ''
) {
  const [organizationSlug, projectID, unitID] = useSlugs();

  return React.useMemo(() => {
    let url = 'organizations/';

    if (organizationSlug) {
      url += organizationSlug;
    }

    if (level === 'project' && projectID) {
      url += `/projects/${projectID}`;
    }

    if (level === 'unit' && unitID) {
      url += `/projects/${projectID}/units/${unitID}`;
    }

    return url + path;
  }, [level, organizationSlug, projectID, unitID, path]);
}

export function useSlugs() {
  const { slug, projectID, unitID } = useParams();
  return React.useMemo(() => [slug || '', projectID || '', unitID || ''], [slug, projectID, unitID]);
}

export interface SlugValues {
  organizationSlug?: string;
  projectID?: string;
  unitID?: string;
}
export function useApiUrl(
  level: SlugLevel = SlugLevel.Organization,
  path: string = '',
  slugs: SlugValues = {}
) {
  const [organizationSlug, projectID, unitID] = useSlugs();
  const organization = slugs.organizationSlug || organizationSlug;
  const project = slugs.projectID || projectID;
  const unit = slugs.unitID || unitID;

  return React.useMemo(() => {
    let url = 'api/v1';

    if (SlugLevel.Organization <= level && organization) {
      url += `/organizations/${organization}`;
    }

    if (SlugLevel.Project <= level && organization && project) {
      url += `/projects/${project}`;
    }

    if (SlugLevel.Unit <= level && organization && project && unit) {
      url += `/units/${unit}`;
    }

    return url + (path ? `/${path}` : '');
  }, [level, organization, project, unit, path]);
}

interface UseToOverride {
  organization?: string;
  project?: string;
  unit?: string;
}

export function useTo(
  level: SlugLevel = SlugLevel.Unit,
  path: string = '',
  override?: UseToOverride
): string {
  const slugs = useSlugs();
  const organization = override?.organization || slugs[0];
  const project = override?.project || slugs[1];
  const unit = override?.unit || slugs[2];
  let to = '';
  if (SlugLevel.Admin === level) {
    return '/admin' + path;
  }
  if (SlugLevel.User === level) {
    return '/bruker' + path;
  }
  if (SlugLevel.Unit === level && unit) {
    to = `/enheter/${unit}${to}`;
  }
  if (level >= SlugLevel.Project && project) {
    to = `/prosjekter/${project}${to}`;
  }
  if (level >= SlugLevel.Organization && organization) {
    to = `/organisasjoner/${organization}${to}`;
  }
  return to + path;
}
