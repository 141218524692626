import { AptlyUnit } from '@aptly-as/types';
import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Send from '@mui/icons-material/Send';
import { createModal } from '../../../containers/Modal/ModalContext';
import useApiRequest from '../../../hooks/useApiRequest';
import useApiUrl from '../../../hooks/useGetApiUrl';
import BusyWrapper from '../../BusyWrapper';
import ErrorState from '../../../containers/Error/ErrorState';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../../mui/Table';
import i18n from '../../../libraries/i18n';
import { ModalActions, ModalContent } from '../../../mui/Dialog';
import ActionButtons from '../../actions/buttons/ActionButtons';
import { Checkbox } from '../../../mui/Input';
import { busyNotification, successNotification } from '../../../containers/Notification/notification.utils';
import handleError from '../../../containers/Error/handleError';
import apiRequest from '../../../libraries/fetch/apiRequest';
import ConfirmModal from '../../../containers/Modal/ConfirmModal';

type UnitInvites = Pick<AptlyUnit, '_id' | 'name' | 'queueInvites' | 'invitesQueue'>;

interface Props {
  onClose?: () => void;
}

interface Item {
  email: string;
  fullName?: string;
  phone?: string;
  send: boolean;
  units: UnitInvites[];
}

export default function CrudUnitInvitations({ onClose }: Props) {
  const url = useApiUrl('project');
  const [busy, units, error] = useApiRequest<UnitInvites[]>(`${url}/units/getInvites`);
  const [requestBusy, setRequestBusy] = useState(false);
  const [unitInvites, setUnitInvites] = useState<Item[]>([]);
  let total = 0;

  useEffect(() => {
    if (units) {
      const list: Item[] = [];
      for (const unit of units) {
        for (const email of unit.queueInvites) {
          const found = list.find((x) => x.email === email);
          if (found) {
            found.units.push(unit);
          } else {
            list.push({ email, units: [unit], send: true });
          }
        }
        if (unit.invitesQueue) {
          for (const queue of unit.invitesQueue) {
            const found = list.find((x) => x.email === queue.email);
            if (found) {
              found.units.push(unit);
            } else {
              list.push({ ...queue, units: [unit], send: true });
            }
          }
        }
      }
      setUnitInvites(list);
    }
  }, [units]);

  const handleCheckChange = useCallback((i: number) => {
    return (_event: React.SyntheticEvent, checked: boolean) => {
      setUnitInvites((s) => {
        s[i].send = checked;
        return [...s];
      });
    };
  }, []);

  const handleSend = useCallback(() => {
    const activeUnits = unitInvites
      .filter((x) => x.send)
      .reduce<UnitInvites[]>((arr, x) => {
        arr.push(...x.units);
        return arr;
      }, []);
    createModal(
      <ConfirmModal
        onConfirm={async () => {
          setRequestBusy(true);
          const busy = busyNotification(i18n.t('statuses.sending'));

          try {
            await apiRequest(`${url}/units/sendInvites`, {
              method: 'POST',
              data: activeUnits.map((x) => x._id),
            });

            successNotification(i18n.t('statuses.invitesSent'));

            if (typeof onClose === 'function') {
              onClose();
            }
          } catch (e) {
            handleError(e);
          } finally {
            setRequestBusy(false);
            busy();
          }
        }}
      >
        <Typography variant="body2">{i18n.t('paragraphs.confirmSendInvitations')}</Typography>
        <ul>
          {activeUnits.map((x, i) => (
            <li key={i}>{x.name}</li>
          ))}
        </ul>
      </ConfirmModal>
    );
  }, [unitInvites]);

  if (error) return <ErrorState error={error} />;
  if (!unitInvites) return null;

  const active = unitInvites.filter((x) => x.send);

  return (
    <>
      <ModalContent>
        <Typography variant="h3">{i18n.t('actions.bulkInvites')}</Typography>
        <Typography variant="body1">{i18n.t('paragraphs.sendInvitations')}</Typography>
        <BusyWrapper busy={busy || requestBusy}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.t('singles.unit')}</TableCell>
                <TableCell>{i18n.t('singles.invitations')}</TableCell>
                <TableCell align="right">{i18n.t('actions.send')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unitInvites.map((item, i) => {
                total += item.send ? 1 : 0;
                return (
                  <TableRow key={i}>
                    <TableCell>{item.units.map((x) => x.name).join(', ')}</TableCell>
                    <TableCell>
                      {item.email}
                      {item.fullName ? (
                        <>
                          <br />
                          {item.fullName}
                        </>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox checked={item.send} onChange={handleCheckChange(i)} />
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell>
                  <strong>{i18n.t('singles.total')}</strong>
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  <strong>{total}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </BusyWrapper>
      </ModalContent>
      <ModalActions>
        <ActionButtons
          disabledSubmit={requestBusy || active.length === 0}
          onClose={onClose}
          onClick={handleSend}
          actionLabel={i18n.t('actions.send')}
          actionIcon={<Send />}
        />
      </ModalActions>
    </>
  );
}
