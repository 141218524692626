import { AptlyProject } from '@aptly-as/types';
import { useState } from 'react';
import i18n from '../../../libraries/i18n';
import useGetApiUrl from '../../../hooks/useGetApiUrl';
import Typography from '../../../mui/Typography';
import Card from '../../../mui/Card';
import { ProjectIntegrationItem } from './projectSettings.utils';
import List from '../../../components/List/List';

interface Props {
  project: AptlyProject;
}

export default function ProjectSettingsIntegration({ project }: Props) {
  const endpoint = useGetApiUrl('project') + '/integrations';
  const [integrations, setIntegrations] = useState(project ? project.integrations || [] : []);

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        {i18n.t('singles.integrations')}
      </Typography>
      <Card>
        <List disablePadding>
          <ProjectIntegrationItem
            setIntegrations={setIntegrations}
            endpoint={endpoint}
            name="checkd"
            label="CHECKD"
            integrations={integrations}
          />

          <ProjectIntegrationItem
            setIntegrations={setIntegrations}
            endpoint={endpoint}
            name="boligmappa"
            label="Boligmappa"
            integrations={integrations}
          />
        </List>
      </Card>
    </div>
  );
}
