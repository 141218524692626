import { css, keyframes } from 'styled-components';

// Keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0
  }
`;

// Timings
const short = '0.12s';

// Animations
const enter = css`
  ${fadeIn} ${short} ease-in;
`;
const leave = css`
  ${fadeOut} ${short} ease-out forwards;
`;

export { enter, leave, short };
