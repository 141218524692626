import styled from 'styled-components';

interface AptlyLogoProps {
  small?: boolean;
  isMobile?: boolean;
}

const AptlyLogo = styled.img.attrs<AptlyLogoProps>((p: AptlyLogoProps) => ({
  src: p.isMobile ? '/favicon.ico' : '/logo.png',
}))`
  max-width: 250px;
  max-height: ${(props) => (props.small ? '50px' : '150px')};
  position: relative;

  ${(props) => (props.small ? 'bottom: -2px;' : 'left: -5px;')};
`;

export default AptlyLogo;
