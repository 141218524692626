import { AptlyAppImplementation, AptlyFieldType } from '@aptly-as/types';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import { ICrudField, ICrudFieldReference } from '../utils/crud.utils';

const reference = (path: string, multiple?: boolean, query?: object) =>
  ({
    endpoint: path,
    queryKey: '$name',
    labelKey: 'name',
    query: { archived: false, ...query, as: 'array', select: '_id,name', limit: 5, sort: 'name' },
    multiple,
  }) as ICrudFieldReference;

export const toUseProjectReference = (projectPath: string, multiple?: boolean) => (): ICrudFieldReference => {
  const path = useApiUrl(SlugLevel.Project, projectPath);
  return reference(path, multiple, { level: 'project' });
};
export const useProjectReference = (projectPath: string, multiple?: boolean): ICrudFieldReference => {
  const path = useApiUrl(SlugLevel.Project, projectPath);
  return reference(path, multiple, { level: 'project' });
};

export const schemaUnitTemplateReference = <T extends object>(): ICrudField<
  T,
  string | null | undefined
> => ({
  type: AptlyFieldType.Reference,
  defaultValue: null,
  label: i18n.t('singles.upsellTemplate'),
  useReference: toUseProjectReference('upsell-templates'),
});

export const schemaUnitReference = <T extends object>(): ICrudField<T, string | null | undefined> => ({
  type: AptlyFieldType.Reference,
  defaultValue: null,
  label: i18n.t('singles.unit'),
  useReference: toUseProjectReference('units'),
});

export const schemaRecommendationReferenceFn = <T extends object>(
  props: Partial<ICrudField<T, string | null>> = {}
): ICrudField<T, string | null> => ({
  type: AptlyFieldType.Reference,
  defaultValue: null,
  label: i18n.t('singles.recommendation'),
  useReference: toUseProjectReference('recommendations'),
  ...props,
});

export const schemaOptionLabelReferenceFn = <T extends object>(
  props: Partial<ICrudField<T, string[]>> = {}
): ICrudField<T, string[]> => ({
  type: AptlyFieldType.Reference,
  defaultValue: [],
  label: i18n.t('singles.labels'),
  useReference: toUseProjectReference('option-labels', true),
  ...props,
});

export const schemaSupplierReferenceFn = <T extends object>(
  props: Partial<ICrudField<T, string | null>> = {}
): ICrudField<T, string | null> => ({
  type: AptlyFieldType.Reference,
  defaultValue: null,
  label: i18n.t('singles.supplier'),
  useReference: () => reference('suppliers'),
  ...props,
});

export const schemaAppReference = <T extends object>(
  imp: AptlyAppImplementation
): ICrudField<T, string | null> => ({
  type: AptlyFieldType.Reference,
  defaultValue: null,
  label: i18n.t('singles.app'),
  useReference: () => ({
    endpoint: 'api/v1/apps',
    queryKey: '$name',
    labelKey: 'name',
    query: { select: '_id,name', limit: 5, as: 'array', implements: imp },
  }),
});
