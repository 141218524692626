import React, { ReactNode } from 'react';
import SearchAndSelect, { Item } from '../deprecated/Search/SearchAndSelect';
import { FieldWrapper, TextField } from '../mui/Input';
import Popover from '../mui/Popover';
import apiRequest from '../libraries/fetch/apiRequest';
import handleError from '../containers/Error/handleError';
import { Query } from '../deprecated/Search/Search';

export const toReferenceValue = (item: any) => item._id;
export const toReferenceItem = (item: any, labelKey: string) => ({
  _id: item._id || item,
  label: item[labelKey],
});

export interface IReferenceInputProps<T extends Item> {
  data?: T[];
  endpoint: string;
  queryKey?: string;
  labelKey?: keyof T;
  singular?: boolean;
  defaultSelected?: T[];
  onAdd?: (item: T) => any;
  onSelect?: (items: T[]) => any;
  onRemove?: (id: string) => any;
  fieldID?: string;
  head?: React.ReactNode;
  required?: boolean;
  autoFocus?: boolean;
  label?: ReactNode;
  disabled?: boolean;
  initQuery?: string;
  query?: Query;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small';
  itemLabelKey?: keyof T;
  noInternalState?: boolean;
}

interface State<T extends Item> {
  selecting: boolean;
  target: HTMLElement | null;
  selected: T[];
  busy: boolean;
}

// todo: this should be rewritten. Can be at least halfed
class ReferenceInput<T extends Item> extends React.Component<IReferenceInputProps<T>, State<T>> {
  static defaultProps = {
    queryKey: '$name',
    labelKey: 'name',
    itemLabelKey: 'label',
  };
  state = {
    selecting: false,
    target: null,
    selected: this.props.defaultSelected || [],
    busy: false,
  };

  select = (items: T[]) => {
    const { onSelect, itemLabelKey, labelKey, noInternalState } = this.props;
    // @ts-ignore
    const selected = items.map((x) => ({ ...x, [labelKey]: x[itemLabelKey] }));

    this.setState({
      selecting: false,
      selected: noInternalState ? [] : selected,
    });
    if (typeof onSelect === 'function') {
      onSelect(selected);
    }
  };

  componentDidUpdate() {
    if ((this.props.defaultSelected?.length || 0) !== 0 && this.state.selected.length === 0) {
      this.setState({ selected: this.props.defaultSelected || [] });
    }
  }

  componentDidMount(): void {
    const { defaultSelected, onSelect, initQuery, query = {}, itemLabelKey, labelKey } = this.props;
    const item = defaultSelected ? defaultSelected[0] : defaultSelected;
    if (defaultSelected && item && typeof item.label === 'undefined') {
      const ids = defaultSelected.map((x) => x._id).join(',');
      this.setState({ busy: true });
      apiRequest<T[]>(this.props.endpoint, {
        data: { ...query, $in__id: ids },
      })
        .then((items) => {
          this.setState({
            selected: items.map((x) => ({ ...x, [itemLabelKey!]: x[labelKey!] })),
            busy: false,
          });
        })
        .catch(() => {
          this.setState({ busy: false });
        });
    }

    if (initQuery) {
      apiRequest<T[]>(this.props.endpoint, {
        data: {
          [this.props.queryKey || 'name']: initQuery,
          limit: 5,
          ...query,
        },
      })
        .then((data) => {
          if (data.length === 1) {
            const selected = [
              {
                ...data[0],
                // @ts-ignore
                [itemLabelKey]: data[0][labelKey!],
              },
            ];
            this.setState({ selected });
            if (typeof onSelect === 'function') {
              onSelect(selected);
            }
          }
        })
        .catch(handleError);
    }
  }

  render() {
    const {
      fieldID,
      endpoint,
      queryKey,
      labelKey,
      singular,
      data,
      head,
      onAdd,
      onRemove,
      required,
      autoFocus,
      label,
      disabled,
      query,
      variant,
      size,
      itemLabelKey,
    } = this.props;

    const { selecting, target, selected, busy } = this.state;

    if (busy) {
      return false;
    }

    return (
      <FieldWrapper>
        <TextField
          id={fieldID}
          fullWidth
          // @ts-ignore temp fix for stupid implementation
          value={selected.map((s) => s[itemLabelKey]).join(', ')}
          head={head}
          required={required}
          autoFocus={autoFocus}
          label={label}
          onClick={(e: any) => {
            if (!disabled && !selecting) {
              this.setState({
                target: e.currentTarget,
                selecting: true,
              });
            }
          }}
          disabled={disabled}
          variant={variant}
          size={size}
        />
        <Popover
          open={selecting}
          anchorEl={target || undefined}
          onClose={() =>
            this.setState({
              selecting: false,
            })
          }
        >
          {selecting && (
            <SearchAndSelect<T>
              endpoint={endpoint}
              labelKey={labelKey}
              queryKey={queryKey}
              onCommit={this.select}
              selectedItems={selected}
              singular={singular}
              data={data as T[]}
              autoFocus
              onAdd={onAdd}
              onRemove={onRemove}
              query={query}
            />
          )}
        </Popover>
      </FieldWrapper>
    );
  }
}

export function SingularReference<T extends Item>(
  props: Omit<IReferenceInputProps<T>, 'size' | 'singluar' | 'query'>
) {
  return (
    <ReferenceInput<T>
      size="small"
      singular
      query={{ as: 'array', limit: '5', select: '_id name' }}
      {...props}
    />
  );
}

export default ReferenceInput;
