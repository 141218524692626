import Chip from '@mui/material/Chip';
import i18n from '../libraries/i18n';

export default Chip;

interface IChipLevelProps {
  data: { project?: string; organization?: string };
}

export const ChipLevel = ({ data }: IChipLevelProps) => {
  if (data.project) return <Chip label={i18n.t('singles.project')} />;
  if (data.organization) return <Chip label={i18n.t('singles.organization')} />;
  return <Chip label="Aptly" />;
};
