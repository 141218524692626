export const intlCurrencyDecimal = (cost: number) =>
  Intl.NumberFormat('no-NB', { style: 'currency', currency: 'NOK', maximumFractionDigits: 2 }).format(cost);

export const intlCurrency = (cost: number) =>
  Intl.NumberFormat('no-NB', { style: 'currency', currency: 'NOK', maximumFractionDigits: 0 }).format(cost);

export const intlQuantity = (quantity: number) =>
  Intl.NumberFormat('no-NB', {
    maximumFractionDigits: 1,
  }).format(quantity);

export const intlDateFormat = (date: Date | string) =>
  Intl.DateTimeFormat('no-NB', {}).format(new Date(date));
export const intlDateTimeFormat = (date?: Date | string | number | null) => {
  if (!date) return '';
  return Intl.DateTimeFormat('no-NB', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));
};
export const intlTimeFormat = (date?: Date | string | number | null) => {
  if (!date) return '';
  return Intl.DateTimeFormat('no-NB', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));
};
