import { useCallback } from 'react';
import { IUseCrudProps, useFields } from '@ewb/reach-react';
import Add from '@mui/icons-material/Add';
import { ICreateModelOptions } from '../../containers/Modal/modal.types';
import { createModal } from '../../containers/Modal/ModalContext';
import { ICrudSchema, ICrudFieldData, ISimpleCrudObject } from '../crud/utils/crud.utils';
import { IApiError } from '../ApiError';
import { ISimpleCrudModalProps, SimpleCrudModal } from './SimpleCrud';
import i18n from '../../libraries/i18n';
import { SimpleCrudModalProps } from './useSimpleCrud';

export interface IUsePropsCrudProps<T extends ISimpleCrudObject>
  extends Pick<ISimpleCrudModalProps<T>, 'description'> {
  fields: (keyof T)[];
  title: string;
  useFieldsProps?: Omit<IUseCrudProps<T>, 'idKey' | 'initWithGet' | 'disableAutoSave'>;
  meta?: object;
  actionButtonProps?: SimpleCrudModalProps<T>['actionButtonProps'];
}
export type IUsePostCrudFn<T extends ISimpleCrudObject> = (
  onCreate?: (data: T) => void,
  init?: Partial<T>,
  modalKeys?: (keyof T)[]
) => void;

export default function useSimplePost<T extends ISimpleCrudObject>(
  path: string,
  schema: ICrudSchema<T>,
  props: IUsePropsCrudProps<T>,
  modalOptions: ICreateModelOptions = { width: 'md' }
) {
  return useCallback(
    (onCreate?: (data: T) => void, data?: Partial<T>, modalFields = props.fields) => {
      createModal(
        <Post<T>
          path={path}
          schema={schema}
          props={props}
          data={data}
          fields={modalFields}
          onCreate={onCreate}
        />,
        modalOptions
      );
    },
    [path, schema, props, modalOptions]
  );
}

interface PostProps<T extends ISimpleCrudObject> extends Pick<ISimpleCrudModalProps<T>, 'actionButtonProps'> {
  path: string;
  schema: ICrudSchema<T>;
  props: Omit<IUsePropsCrudProps<T>, 'fields'>;
  onCreate?: (data: T) => void;
  data?: Partial<T>;
  fields: (keyof T)[];
}

export function Post<T extends ISimpleCrudObject>({
  path,
  schema,
  props,
  data = {},
  fields,
  onCreate,
  ...rest
}: PostProps<T>) {
  const { title, useFieldsProps, meta, actionButtonProps, description } = props;
  const crud = useFields<T, IApiError, ICrudFieldData<T>>(path, data, schema, {
    idKey: '_id',
    initWithGet: false,
    disableAutoSave: true,
    ...useFieldsProps,
  });

  return (
    <SimpleCrudModal<T>
      crud={crud}
      data={data}
      fields={fields}
      onCreate={onCreate}
      title={title}
      useFieldProps={useFieldsProps}
      meta={meta}
      actionButtonProps={{
        actionLabel: i18n.t('actions.create'),
        actionIcon: <Add />,
        ...actionButtonProps,
      }}
      description={description}
      {...rest}
    />
  );
}
