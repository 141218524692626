import { ISimpleComponentProps } from './simple-components.utils';
import ColorPicker from '../../ColorPicker';

interface ISimpleColorProps<T extends object> extends ISimpleComponentProps<T, any> {}

export default function SimpleColor<T extends object>({ field, onChange }: ISimpleColorProps<T>) {
  const { value, label, edited, gridProps, defaultValue, ...rest } = field;

  return <ColorPicker value={value} label={label} onChange={onChange} {...rest} />;
}
