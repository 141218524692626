import Grid from '../../mui/Grid';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Typography from '../../mui/Typography';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import i18n from '../../libraries/i18n';

interface Props {
  fileName?: string;
  onClose?: () => void;
}

export default function DocumentError({ fileName, onClose }: Props) {
  return (
    <Grid container direction="column">
      <ModalTitle>
        {i18n.t('statuses.failedToUpload')}&nbsp;{fileName || ''}
      </ModalTitle>
      <ModalContent>
        <Typography variant="body1">
          Kunne ikke åpne dokumentet. Venligst sjekk om dokumentet kan åpnes i pdf program, eller om
          dokumentet er beskyttet med passord for lesing eller redigering.
        </Typography>
      </ModalContent>
      <ModalActions>
        <ActionButtons onClose={onClose} submitType="button" cancelLabel={i18n.t('actions.close')} />
      </ModalActions>
    </Grid>
  );
}
