import { AptlyErrorBody, AptlySearchPaginateResponse } from '@aptly-as/types';
import React from 'react';
import apiRequest from '../libraries/fetch/apiRequest';

const DEFAULT_LIMIT = 10;

export interface IUsePaginateSearchState<T> extends AptlySearchPaginateResponse<T> {
  busy: boolean;
  error?: AptlyErrorBody;
}

export interface IUsePaginateSearchOptions {
  limit?: number;
  query?: object;
}

export interface IUsePaginateSearchActions {
  refresh: () => void;
  next: () => void;
}

/**
 * @deprecated use usePaginatedSearch or useSimpleSearch instead.
 * @param path
 * @param opts
 */
export default function usePaginateSearch<T>(
  path: string,
  opts: IUsePaginateSearchOptions
): [
  boolean,
  T[],
  AptlyErrorBody | undefined,
  Pick<IUsePaginateSearchState<T>, 'skip' | 'limit' | 'count'>,
  IUsePaginateSearchActions,
] {
  const init = React.useRef(false);
  const { limit = DEFAULT_LIMIT, query } = opts;
  const [state, setState] = React.useState<IUsePaginateSearchState<T>>({
    busy: true,
    limit,
    skip: 0,
    data: [],
    count: 0,
  });

  const getData = React.useCallback(
    (limit: number, skip: number) => {
      apiRequest<AptlySearchPaginateResponse<T>>(path, {
        data: { ...(query || {}), limit, skip },
      })
        .then((res) => {
          setState((s) => ({
            limit: s.limit,
            skip: res.skip,
            data: res.skip === 0 ? res.data : [...s.data, ...res.data],
            count: res.count,
            busy: false,
          }));
        })
        .catch((error) => {
          setState((s) => ({ ...s, error, busy: false }));
        });
    },
    [path, query]
  );

  const refresh = React.useCallback(() => {
    setState((s) => ({ ...s, busy: true }));
    getData(state.limit, 0);
  }, [getData, state.limit]);

  React.useEffect(() => {
    if (!init.current) {
      init.current = true;
      getData(state.limit, state.skip);
    }
  }, [getData, refresh, state.skip, state.limit]);

  const next = React.useCallback(() => {
    setState((s) => {
      if (s.data.length >= s.count) return s;
      init.current = false;
      return { ...s, skip: s.skip + s.limit, busy: true };
    });
  }, []);

  const actions: IUsePaginateSearchActions = React.useMemo(() => ({ refresh, next }), [refresh, next]);

  return React.useMemo(
    () => [
      state.busy,
      state.data,
      state.error,
      { limit: state.limit, skip: state.skip, count: state.count },
      actions,
    ],
    [state, actions]
  );
}
