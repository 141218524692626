import Grid from '@mui/material/Grid/Grid';
import { EmailContent } from './HandleOrder.utils';

export function HandleOrderPayment() {
  return (
    <Grid container flexDirection="column">
      <Grid item>
        <EmailContent />
      </Grid>
    </Grid>
  );
}
