import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useContext, useMemo } from 'react';
import { AptlyFieldType } from '@aptly-as/types';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import CrudForm, { CrudFormSelect, CrudFormTimeField } from '../../components/simple/CrudForm';
import Button from '../../mui/Button';
import Grid from '../../mui/Grid';
import i18n from '../../libraries/i18n';
import { BookingContext } from './BookingContext';

const DAY_OPTIONS = () => [
  {
    value: '1',
    label: i18n.t('singles.monday'),
  },
  {
    value: '2',
    label: i18n.t('singles.tuesday'),
  },
  {
    value: '3',
    label: i18n.t('singles.wednesday'),
  },
  {
    value: '4',
    label: i18n.t('singles.thursday'),
  },
  {
    value: '5',
    label: i18n.t('singles.friday'),
  },
  {
    value: '6',
    label: i18n.t('singles.saturday'),
  },
  {
    value: '0',
    label: i18n.t('singles.sunday'),
  },
];

const schema = (
  activeDate: Date
): ICrudSchema<{
  from: Dayjs;
  to: Dayjs;
  days: string[];
  repeat: number;
}> => ({
  from: {
    type: AptlyFieldType.Time,
    defaultValue: dayjs(activeDate).hour(8).minute(0).second(0),
    label: i18n.t('singles.from'),
    preOnChange: (value, crud) => {
      const from = dayjs(value);
      const to = crud.getField('to').value;
      if (from.isSame(to) || from.isAfter(to)) {
        crud.setField('to')(from.add(1, 'hour'));
      }
      return value;
    },
  },
  to: {
    type: AptlyFieldType.Time,
    defaultValue: dayjs(activeDate).hour(9).minute(0).second(0),
    label: i18n.t('singles.to'),
    preOnChange: (value, crud) => {
      const from = dayjs(crud.getField('from').value);
      if (from.isAfter(value)) {
        crud.setField('from')(dayjs(value).subtract(1, 'hour'));
      }
      return value;
    },
  },
  days: {
    type: AptlyFieldType.Select,
    defaultValue: [
      dayjs(activeDate || new Date())
        .day()
        .toString(),
    ],
    label: i18n.t('singles.days'),
    options: DAY_OPTIONS(),
    multiple: true,
  },
  repeat: {
    type: AptlyFieldType.Select,
    defaultValue: 0,
    label: i18n.t('singles.repeat'),
    options: [0, 1, 2, 3, 4].map((count) => ({
      value: String(count),
      label: `${count} ${i18n.t('singles.weeks', { count })}`,
    })),
  },
});

export default function BookingFromTo() {
  const {
    calendarPath,
    getTimes,
    state: { date },
  } = useContext(BookingContext);
  const activeDate = date ? date.toDate() : new Date();
  const handleTimeCreate = useCallback(() => getTimes(date.toISOString()), [getTimes, date]);

  return (
    <CrudForm
      path={`${calendarPath}/times`}
      data={{}}
      onEdit={handleTimeCreate}
      schema={useMemo(() => schema(activeDate), [activeDate])}
    >
      <Grid container spacing={2} direction="column" justifyContent="space-between">
        <Grid item>
          <CrudFormTimeField name="from" />
        </Grid>
        <Grid item>
          <CrudFormTimeField name="to" />
        </Grid>
        <Grid item style={{ maxWidth: '100%' }}>
          <CrudFormSelect name="days" />
        </Grid>
        <Grid item>
          <CrudFormSelect name="repeat" />
        </Grid>
        <Grid item>
          <Button variant="contained" type="submit">
            {i18n.t('actions.addTime')}
          </Button>
        </Grid>
      </Grid>
    </CrudForm>
  );
}
