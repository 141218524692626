import { Component } from 'react';
import Select from '../mui/Select';
import apiRequest from '../libraries/fetch/apiRequest';
import i18n from '../libraries/i18n';

type OptionData = {
  _id: string;
  [key: string]: string;
};

type Option = {
  value: string;
  label: string;
};

type Props = {
  value: string;
  endpoint: string;
  queryKey: string;
  labelKey: string;
  onChange?: (e: any) => any;
  label?: string;
  fullWidth?: boolean;
};

type State = {
  options: Option[];
  optionValue: string;
  searchValue: string;
};

/**
 * @deprecated
 */
class ReferenceSelect extends Component<Props, State> {
  state = {
    options: [this.defaultOption('No options')],
    optionValue: '',
    searchValue: '',
  };

  componentDidMount() {
    this.fetchOptions(true).then();
  }

  defaultOption(label: string = i18n.t('paragraphs.noChoices')) {
    return {
      value: '',
      label,
    };
  }

  async fetchOptions(force = false) {
    const { value, endpoint, queryKey, labelKey } = this.props;
    const { searchValue } = this.state;

    if (force || (searchValue !== value && queryKey && value)) {
      try {
        const options: OptionData[] = await apiRequest(`${endpoint}/${value}/${queryKey}`);
        const newOptions = options.map((option) => ({
          value: option._id,
          label: option[labelKey],
        }));
        this.setOptions(newOptions, value);
      } catch (e) {
        this.setOptions([], value);
      }
    }
  }

  handleSelectChange = (e: any) => {
    const { value } = e.target;
    this.setState({ searchValue: value });
    this.props.onChange && this.props.onChange(e);
  };

  setOptions = (options: Option[], searchValue: string) => {
    if (options.length === 0) {
      options.push(this.defaultOption());
    }
    this.setState({ options, searchValue });
  };

  render() {
    const { value, ...rest } = this.props;
    const { options, optionValue } = this.state;

    return (
      <Select
        variant="standard"
        value={value || optionValue}
        onChange={this.handleSelectChange}
        options={options}
        {...rest}
      />
    );
  }
}

export default ReferenceSelect;
