import { useCallback, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  rectIntersection,
  defaultDropAnimation,
  DndContext,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core/dist/types';
import {
  AptlyUnitTemplateCategory,
  AptlyUnitTemplateCategorySection,
  AptlyUnitTemplateCategorySectionPackage,
  AptlyUnitTemplateCategorySectionProduct,
} from '@aptly-as/types';
import { errorNotification } from '../../../containers/Notification/notification.utils';
import {
  CategoryDragOverlay,
  PackageDragOverlay,
  ProductDragOverlay,
  SectionDragOverlay,
} from './DragOverlays';
import { IUnitTemplateContext, IUseSortableData } from './unit-template.utils';
import { IUnitTemplateLevel } from './unit-template.handlers';
import { hasMovedFromOrigin } from './unit-template.state';

const dropAnimation: DropAnimation = {
  ...defaultDropAnimation,
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      { opacity: 0, transform: CSS.Transform.toString(transform.final) },
    ];
  },
};

type Props = IUnitTemplateContext & JSX.ElementChildrenAttribute;

interface State {
  activeCategory?: AptlyUnitTemplateCategory;
  activeSection?: AptlyUnitTemplateCategorySection;
  activeProduct?: AptlyUnitTemplateCategorySectionProduct;
  activePackage?: AptlyUnitTemplateCategorySectionPackage;
}

type EventState = {
  level: IUnitTemplateLevel | null;
  activeData: IUseSortableData;
  overData: IUseSortableData;
};

const isSamePosition = (active: DragOverEvent['active'], over: DragOverEvent['over']) => {
  if (!over) return false;
  const activeData = active.data.current as IUseSortableData;
  const overData = over.data.current as IUseSortableData;
  if (activeData.category !== overData.category) return false;
  if (activeData.section !== overData.section) return false;
  if (activeData.product !== overData.product) return false;
  if (activeData.package !== overData.package) return false;
  return activeData.sortable.index === overData.sortable.index;
};
const getEventData = (active: DragOverEvent['active'], over: DragOverEvent['over']): EventState => {
  if (!over) return { level: null } as EventState;
  const activeData = active.data.current as IUseSortableData;
  const overData = over.data.current as IUseSortableData;
  if (!activeData || !overData) return { level: null, activeData, overData };
  if (activeData.package) {
    if (!overData.section) return { level: null, activeData, overData };
    if (!overData.product && !overData.package && !overData.expanded)
      return { level: null, activeData, overData };
    return { level: 'package-product', activeData, overData };
  }
  if (activeData.product) {
    if (!overData.section) return { level: null, activeData, overData };
    if (!overData.product && !overData.package && !overData.expanded)
      return { level: null, activeData, overData };
    return { level: 'product', activeData, overData };
  }
  if (activeData.section) {
    if (!overData.section && !overData.expanded) return { level: null, activeData, overData };
    return { level: 'section', activeData, overData };
  }
  if (activeData.category) {
    return { level: 'category', activeData, overData };
  }
  return { level: 'error', activeData, overData };
};

export function UnitTemplateSortable({ onOver, onOrder, children }: Props) {
  const [state, setState] = useState<State>({});
  const from = useRef<IUseSortableData>();
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleOnDragStart = useCallback(({ active }: DragStartEvent) => {
    const activeData = active.data.current as IUseSortableData;

    from.current = JSON.parse(JSON.stringify(activeData));

    setState((s) => {
      if (activeData.product) {
        s.activeProduct = activeData.product;
        s.activeSection = undefined;
        s.activeCategory = undefined;
      } else if (activeData.package) {
        s.activePackage = activeData.package;
        s.activeSection = undefined;
        s.activeCategory = undefined;
      } else if (activeData.section) {
        s.activeSection = activeData.section;
        s.activeCategory = undefined;
      } else if (activeData.category) {
        s.activeSection = undefined;
        s.activeCategory = activeData.category;
      }
      return { ...s };
    });
  }, []);

  const handleOnDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;
      const { level, activeData, overData } = getEventData(active, over);
      switch (level) {
        case 'package-product':
        case 'product':
        case 'package':
          if (activeData.section!._id !== overData.section?._id) {
            onOver(activeData, overData);
          }
          break;
        case 'section':
          if (activeData.category!._id !== overData.category!._id) {
            onOver(activeData, overData);
          }
          break;
        case 'error': {
          errorNotification('Feil med flytting');
          setState({});
        }
      }
    },
    [onOver]
  );

  const handleOnDragCancel = useCallback(() => {
    setState({});
  }, []);

  const handleOnDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      const { level, activeData, overData } = getEventData(active, over);

      setState({});

      if (
        level &&
        (!isSamePosition(active, over) || (from.current && hasMovedFromOrigin(from.current, overData)))
      ) {
        return onOrder(activeData, overData, from.current);
      }
    },
    [onOrder]
  );

  const dragOverlayChild = useMemo(() => {
    if (state.activeProduct) {
      return <ProductDragOverlay product={state.activeProduct} />;
    }
    if (state.activePackage) {
      return <PackageDragOverlay pack={state.activePackage} products={[]} />;
    }
    if (state.activeSection) {
      return <SectionDragOverlay section={state.activeSection} />;
    }
    if (state.activeCategory) {
      return <CategoryDragOverlay category={state.activeCategory} />;
    }
    return null;
  }, [state.activePackage, state.activeCategory, state.activeSection, state.activeProduct]);

  const DragOverlayComponent = useMemo(() => {
    return (
      <DragOverlay dropAnimation={dropAnimation} zIndex={1300}>
        {dragOverlayChild || null}
      </DragOverlay>
    );
  }, [dragOverlayChild]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragStart={handleOnDragStart}
      onDragEnd={handleOnDragEnd}
      onDragCancel={handleOnDragCancel}
      onDragOver={handleOnDragOver}
    >
      {children as any}
      {createPortal(DragOverlayComponent, document.body)}
    </DndContext>
  );
}
