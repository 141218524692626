import { IReachQuery, IReachOptions, useGet } from '@ewb/reach-react';
import { IUseGetProps, IUseGetRet } from '@ewb/reach-react/core/useGet';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AptlyErrorBody } from '@aptly-as/types';
import { useStateThrottle } from '../../hooks/useStateThrottle';
import Grid from '../../mui/Grid';
import { rawSpacing } from '../../utils/spacing';
import ApiError from '../ApiError';
import Section from '../Section';
import { ISearchHeaderProps, SearchContext } from './search.utils';
import SearchActions, { SearchActionsProps } from './SearchActions';
import SearchHeader from './SearchHeader';
import SearchLoader from './SearchLoader';
import SearchPageOverlay, { SearchPageOverlayProps } from './SearchPageOverlay';

export interface SimpleSearchProps<T> {
  path: string;
  query?: IReachQuery;
  header?: ISearchHeaderProps;
  props?: IUseGetProps<T>;
  searchFields?: SearchPageOverlayProps['searchFields'];
  subActions?: (data?: T) => SearchActionsProps<T>['subMenuOptions'];
  reachOptions?: Omit<IReachOptions, 'method'>;
  buttonActions?: (data?: T) => JSX.Element[];
  children: (...args: IUseGetRet<T, AptlyErrorBody>) => JSX.Element | null;
}

export default function SimpleSearch<T>({
  path,
  query: defaultQuery = {},
  props,
  reachOptions,
  header,
  buttonActions,
  searchFields,
  subActions,
  children,
}: SimpleSearchProps<T>) {
  const init = useRef(false);
  const [_path, setPath] = useState(
    useMemo(() => {
      const _query = new URLSearchParams(defaultQuery);
      return `${path}?${_query.toString()}`;
    }, [path, defaultQuery])
  );
  const setPathQuery = useCallback(
    (query: IReachQuery) => {
      const _query = new URLSearchParams(query);
      return setPath(`${path}?${_query.toString()}`);
    },
    [path]
  );
  const [query, setQuery] = useStateThrottle<IReachQuery>(defaultQuery, 350, setPathQuery);
  const [state, get, setState] = useGet<T>(_path, props, reachOptions);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (init.current) {
      get();
    }
    init.current = true;
  }, [_path]);

  const _buttonActions: JSX.Element[] = useMemo(() => {
    if (!buttonActions) return [];
    return buttonActions(state.data);
  }, [buttonActions, state.data]);

  const subMenuOptions: SearchActionsProps<T>['subMenuOptions'] = useMemo(() => {
    if (!subActions) return [];
    return subActions(state.data);
  }, [subActions, state.data]);

  return (
    <SearchContext.Provider value={{ path, data: state.data, refresh: get, query, setQuery }}>
      {state.busy && <SearchLoader />}
      {searchFields && <SearchPageOverlay show={show} searchFields={searchFields} />}
      {state.error && <ApiError error={state.error} />}
      <Section>
        <Grid
          container
          spacing={rawSpacing}
          justifyContent={header ? 'space-between' : 'flex-end'}
          alignItems="center"
        >
          {header && <SearchHeader {...header}>{header.description}</SearchHeader>}
          <Grid item>
            <SearchActions setShow={setShow} subMenuOptions={subMenuOptions} showSearch={!!searchFields}>
              {_buttonActions.map((Action, i) => (
                <Grid item key={i}>
                  {Action}
                </Grid>
              ))}
            </SearchActions>
          </Grid>
        </Grid>
      </Section>
      <Section>{children(state, get, setState)}</Section>
    </SearchContext.Provider>
  );
}
