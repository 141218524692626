import React from 'react';
import styled from 'styled-components';
import createMediaURL from '../models/Media/createMediaURL';
import Avatar from './Avatar';
import Popover from '../mui/Popover';

const Action = styled.a`
  display: block;
  cursor: pointer;
  text-decoration: none;
`;

type Props = {
  alt: string;
  image?: string;
  children: React.ReactNode;
};

type State = {
  open: boolean;
  target: HTMLElement | null;
};

class ExpandableAvatar extends React.Component<Props, State> {
  state = {
    open: false,
    target: null,
  };

  render() {
    const { alt, image, children } = this.props;
    const { open, target } = this.state;

    return (
      <React.Fragment>
        <Action
          href="#"
          onClick={(e: any) => {
            e.preventDefault();

            this.setState({
              open: !open,
              target: e.currentTarget,
            });
          }}
        >
          <Avatar
            alt={alt}
            src={
              image
                ? createMediaURL(image, {
                    width: 72,
                    height: 72,
                    crop: 'fill',
                  })
                : undefined
            }
          />
        </Action>
        <Popover
          open={open}
          anchorEl={target || undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={() =>
            this.setState({
              open: false,
            })
          }
        >
          {children}
        </Popover>
      </React.Fragment>
    );
  }
}

export default ExpandableAvatar;
