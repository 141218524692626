import i18n from '../../libraries/i18n';
import { PageSectionTypes } from './page.types';

export const pageSectionOptions = () => [
  {
    label: i18n.t('singles.recommendation'),
    value: 'recommendation',
  },
  {
    label: i18n.t('singles.image'),
    value: 'image',
  },
  {
    label: i18n.t('singles.gallery'),
    value: 'gallery',
  },
  {
    label: i18n.t('singles.card'),
    value: 'card',
  },
  {
    label: i18n.t('singles.links'),
    value: 'links',
  },
  {
    label: i18n.t('singles.spacing'),
    value: 'spacer',
  },
  {
    label: i18n.t('singles.richText'),
    value: 'text',
  },
  {
    label: i18n.t('singles.embed'),
    value: 'iframe',
  },
];

export function pageSectionOptionLabel(value: PageSectionTypes) {
  const type = pageSectionOptions().find((x) => x.value === value);
  return type ? type.label : `Not valid: ${value}`;
}
