import { useMemo } from 'react';
import { IUseCrudProps, useFields } from '@ewb/reach-react';
import { IApiError } from '../../components/ApiError';
import { ICrudFieldData, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { IPaginatedSearchObject } from './usePaginatedSearch';

// todo: Only used once. Should be moved out.
export default function useAptlyFields<T extends IPaginatedSearchObject>(
  path: string,
  data: T,
  schema: ICrudSchema<T>,
  props?: Partial<IUseCrudProps<T>>
) {
  const _props: any = useMemo(() => ({ idKey: '_id', ...props }), [schema, props]);
  return useFields<T, IApiError, ICrudFieldData<T>>(path, data, schema, _props);
}
