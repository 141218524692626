import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { AptlyJob } from '@aptly-as/types';
import { Job } from './Job';
import { IJobItem, IJobItemKeys, IJobItemProp, JobsContextState, JobsContextType } from './job.utils';

const initialState = {
  jobs: [],
};
export const JobsContext = createContext<JobsContextType>({
  state: initialState,
  spawnJob: () => {
    // empty
  },
});

export function JobsProvider({ children }: PropsWithChildren<object>) {
  const [state, setState] = useState<JobsContextState>(initialState);

  const spawnJob = useCallback((job: AptlyJob, item: IJobItemProp) => {
    const _job = { ...job, ...item };
    if (_job.progressSteps) {
      _job.progressSteps.sort((a, b) => {
        if (a.progress < b.progress) return 1;
        if (a.progress > b.progress) return -1;
        return 0;
      });
    }
    setState((s) => ({ ...s, jobs: [...s.jobs, _job] }));
  }, []);

  const removeJob = useCallback((index: number) => {
    setState((s) => {
      s.jobs.splice(index, 1);
      return { ...s, jobs: [...s.jobs] };
    });
  }, []);

  return (
    <JobsContext.Provider value={{ state, spawnJob }}>
      {useMemo(() => children, [children])}
      {state.jobs.map((job, i) => (
        <Job key={job.id} index={i} initialJob={job} onRemove={removeJob} />
      ))}
    </JobsContext.Provider>
  );
}

export function useJobSpawner<I extends IJobItem>() {
  const { spawnJob } = useContext(JobsContext);
  return useCallback(
    (job: Pick<I, keyof AptlyJob>, item: Pick<I, IJobItemKeys>) => spawnJob(job, item),
    [spawnJob]
  );
}
