import { AptlyUnitStatus } from '@aptly-as/types';
import i18n from '../../libraries/i18n';
import { SelectOption } from '../../mui/Select';

export const unitStatusOptions = (): SelectOption[] => [
  { value: AptlyUnitStatus.NotStarted, label: i18n.t('statuses.notStarted') },
  { value: AptlyUnitStatus.Started, label: i18n.t('statuses.started') },
  { value: AptlyUnitStatus.Confirmed, label: i18n.t('statuses.confirmed') },
  { value: AptlyUnitStatus.Signed, label: i18n.t('statuses.signed') },
  { value: AptlyUnitStatus.Payed, label: i18n.t('statuses.payed') },
  { value: AptlyUnitStatus.Completed, label: i18n.t('statuses.completed') },
];

export const unitStatusLabel = (status: AptlyUnitStatus) => {
  const found = unitStatusOptions().find((x) => x.value === status);
  return found?.label || status;
};
