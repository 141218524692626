import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { ReactNode } from 'react';
import * as React from 'react';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { AptlyDocumentType, AptlyScopes } from '@aptly-as/types';
import { EditIconButton, UploadIconButton } from '../../../components/actions/icons/Icons';
import { createModal } from '../../../containers/Modal/ModalContext';
import { byId } from '../../../libraries/mongoose';
import { acceptPdf } from '../../../libraries/react-dropzone/drop-zone.utils';
import { FragmentScope } from '../../../libraries/scope/ScopeComponets';
import { useAlgorithms } from '../../Algorithm/useAlgorithms';
import renderProducer from '../../Producer/renderProducer';
import mergePackages from '../../Product/mergePackages';
import ActionBar from '../../../components/ActionBar';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../../mui/Table';
import StatusText from '../../../components/StatusText';
import Centered from '../../../components/Centered';
import { Category, Section, SectionProduct } from '../../UnitTemplate/unit-template.types';
import { ProductOverride, SelectedOption } from '../unit.types';
import i18n from '../../../libraries/i18n';
import CrudProductOverwrite from '../../../components/simpleCruds/options/CrudProductOverwrite';
import DropDocuments from '../../Document/DropDocuments';

export interface ProductProps {
  selectedOptions?: SelectedOption[];
  unitEndpoint?: string;
  productOverrides?: ProductOverride[];
  onSave?: () => void;
  category: Category;
  canUpdate?: boolean;

  section: Section;
}

export default function Products(props: ProductProps) {
  const algorithms = useAlgorithms();
  const { onSave, selectedOptions, unitEndpoint, section, category, productOverrides, canUpdate } = props;

  const mergedProducts = mergePackages(section.products, section.packages);

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{i18n.t('singles.name')}</TableCell>
            <TableCell>{i18n.t('singles.productNumber')}</TableCell>
            <TableCell>{i18n.t('singles.producer')}</TableCell>
            <TableCell>
              <Centered>{i18n.t('singles.standard')}</Centered>
            </TableCell>
            {selectedOptions && !section.standard && (
              <TableCell>
                <Centered>{i18n.t('singles.userChoice')}</Centered>
              </TableCell>
            )}
            <FragmentScope scope={AptlyScopes.ProjectUnitTemplatesAlgorithms} crud="R">
              <TableCell align="right">{i18n.t('singles.calculation')}</TableCell>
            </FragmentScope>
            <TableCell align="right">{i18n.t('singles.quantity')}</TableCell>
            <FragmentScope scope={AptlyScopes.ProjectUnitTemplatesPrices} crud="R">
              <TableCell align="right">{i18n.t('singles.clientPrice')}</TableCell>
            </FragmentScope>
            {canUpdate && <TableCell align="right">{i18n.t('singles.actions')}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {mergedProducts.map((p) => {
            const override = productOverrides
              ? productOverrides.find((o) => o.sectionProductRef === p._id)
              : undefined;
            if (override) {
              p.unitCost = override.unitCost || p.unitCost;
              p.amount = override.amount || p.amount;
            }

            const cost = p.isPackage
              ? section.products.reduce((cost, sectionProduct) => {
                  if (
                    sectionProduct.partOfPackage === p.packageID &&
                    sectionProduct.unitCost &&
                    sectionProduct.amount
                  ) {
                    return cost + sectionProduct.unitCost * sectionProduct.amount;
                  }
                  return cost;
                }, 0)
              : p.unitCost;
            const name = getProductTableName(p);
            const algorithm = !p.isPackage && p.algorithm ? algorithms.find(byId(p.algorithm)) : null;

            const productDocumentParam = p?.params?.find((x) =>
              ['thirdParty', 'document', 'draw:dots'].includes(x.value)
            );
            const selectedProductDocument = p?.pick?.params?.find((x: any) => x.key === 'document');

            return (
              <TableRow key={p._id}>
                <TableCell>
                  {p.partOfPackage ? ' --- ' : ''}
                  {name}
                  {override && override.hide && (
                    <React.Fragment>
                      {' '}
                      <StatusText>{i18n.t('statuses.hidden')}</StatusText>
                    </React.Fragment>
                  )}
                </TableCell>
                <TableCell>
                  {p.isPackage ? '-' : p.product?.productNumber || i18n.t('statuses.nothing')}
                </TableCell>
                <TableCell>{renderProducer(p)}</TableCell>
                <TableCell>
                  <Centered>{p.standard ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlank />}</Centered>
                </TableCell>
                {selectedOptions && !section.standard && (
                  <TableCell>
                    <Centered>
                      {selectedOptions.some((s) => s.sectionProductRef === p._id) ? (
                        <CheckBoxOutlinedIcon />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                    </Centered>
                  </TableCell>
                )}
                <FragmentScope scope={AptlyScopes.ProjectAlgorithms} crud="R">
                  <TableCell align="right">{algorithm?.name || i18n.t('singles.NA')}</TableCell>
                </FragmentScope>
                <TableCell align="right">
                  {p.isPackage ? <StatusText>{i18n.t('singles.NA')}</StatusText> : p.amount}
                </TableCell>
                <FragmentScope scope={AptlyScopes.ProjectUnitTemplatesPrices} crud="R">
                  <TableCell align="right">{cost}</TableCell>
                </FragmentScope>
                {canUpdate && (
                  <TableCell align="right">
                    <ActionBar>
                      {unitEndpoint && selectedOptions && productDocumentParam && (
                        <UploadIconButton
                          title={i18n.t('actions.uploadDocument')}
                          onClick={() => {
                            const documentEndpoint = `${unitEndpoint}/categories/${category._id}/sections/${section._id}/products/${p._id}`;
                            createModal(
                              <DropDocuments
                                endpoint={documentEndpoint}
                                path="/set-document"
                                options={[{ value: AptlyDocumentType.ProductAttachment, label: '' }]}
                                onDrop={onSave}
                                accept={acceptPdf}
                                label={`${i18n.t(
                                  selectedProductDocument
                                    ? 'actions.changeDocument'
                                    : 'actions.uploadDocument'
                                )}`}
                                simple
                              />
                            );
                          }}
                        />
                      )}
                      {!p.isPackage && (
                        <EditIconButton
                          onClick={() => {
                            createModal(
                              <CrudProductOverwrite
                                override={override}
                                endpoint={unitEndpoint || ''}
                                onCreate={onSave}
                                product={p}
                                section={section}
                                algorithms={algorithms}
                              />
                            );
                          }}
                        />
                      )}
                    </ActionBar>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

function getProductTableName(product: SectionProduct): ReactNode {
  if (product.isPackage && product.packageName)
    return `${product.packageName} (${i18n.t('singles.package')})`;
  let name = product.product
    ? product.product.name
    : product.text || <StatusText>{i18n.t('singles.NA')}</StatusText>;
  const defaultName = product.availableProducts && product.availableProducts.length === 0;
  if (product.product && !defaultName) {
    const variantID = product.standardVariant ? product.standardVariant : product.availableProducts[0];
    if (variantID !== product.product._id) {
      const variant = product.product.variants.find((x) => x._id === variantID);
      if (variant) {
        name = variant.name || name;
      }
    }
  }
  return name;
}
