import { AptlyNote, AptlyUnit, AptlyUser } from '@aptly-as/types';
import { ListItem } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import { Fragment, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DeleteIconButton } from '../../components/actions/icons/Icons';
import { LoadMoreButton } from '../../components/actions/icons/LoadMoreButton';
import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl';
import { intlDateFormat } from '../../libraries/intl';
import { getId } from '../../libraries/mongoose';
import Typography, { TypographyEllipsis } from '../../mui/Typography';
import { draftToText } from '../../libraries/draft-js';
import i18n from '../../libraries/i18n';
import { noteSelect } from './note.schema';
import { IUseSimpleSearchProps, useSimpleSearch } from '../../libraries/reach/useSimpleSearch';
import { ListItemTextPrimaryWithDate } from '../../components/List/ListItemTextPrimaryWithDate';
import ApiError from '../../components/ApiError';

interface INotesProps {
  level: SlugLevel;
}

export function Notes({ level }: INotesProps) {
  const path = useApiUrl(level, 'notes');
  const props: IUseSimpleSearchProps<AptlyNote> = useMemo(
    () => ({
      limit: 5,
      query: {
        sort: '-hasReminder reminderAt -updatedAt',
        archived: false,
        select: noteSelect,
        $populate: 'unit:_id,name;author:_id,fullName',
      },
    }),
    []
  );
  const [busy, notes, error, next, info] = useSimpleSearch<AptlyNote>(path, props);

  return (
    <>
      {error && <ApiError error={error} />}
      <List
        subheader={
          <ListSubheader>
            {i18n.t('singles.notes')} ({info.count})
          </ListSubheader>
        }
      >
        {notes.map((note, i) => (
          <Fragment key={note._id}>
            <NoteListItem note={note} i={i} />
            <Divider variant="inset" component="li" />
          </Fragment>
        ))}
        {notes.length < info.count && <LoadMoreButton next={() => next()} disabled={busy} />}
      </List>
    </>
  );
}

interface INoteListItemProps {
  note: AptlyNote;
  i: number;
  onDelete?: (note: AptlyNote, i: number) => void;
  selected?: boolean;
}

export function NoteListItem({ note, i, onDelete, selected }: INoteListItemProps) {
  const to = useTo(SlugLevel.Unit, '/notater', { unit: getId(note.unit) });
  const handleOnDelete = useCallback(() => {
    if (typeof onDelete === 'function') {
      onDelete(note, i);
    }
  }, [onDelete, note, i]);
  const secondaryAction = useMemo(
    () => (onDelete ? <DeleteIconButton onClick={handleOnDelete} /> : undefined),
    [onDelete, handleOnDelete]
  );
  const secondary = useMemo(() => draftToText(note.content), [note.content]);
  const unit = note.unit as AptlyUnit;
  const author = note.author as AptlyUser;
  const caption = [unit.name || '', author?.fullName || ''].filter(Boolean).join(' - ');

  return (
    <ListItem component={Paper} disablePadding secondaryAction={secondaryAction}>
      <ListItemButton component={Link} to={`${to}/${note._id}`} selected={selected}>
        <ListItemText
          primary={
            <ListItemTextPrimaryWithDate
              title={note.name}
              date={note.reminderDate ? intlDateFormat(note.reminderDate) : undefined}
            />
          }
          secondaryTypographyProps={{ component: 'div' }}
          secondary={
            <>
              <TypographyEllipsis component="span" variant="caption" color="text.primary" lines={2}>
                {secondary}
              </TypographyEllipsis>
              <Typography variant="caption">{caption}</Typography>
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
