import { DatePicker as MUIDatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { TextFieldProps } from '@mui/material/TextField';
import { Dayjs } from 'dayjs';

export interface IDateTimePickerProps extends DatePickerProps<Dayjs> {
  textFieldProps?: TextFieldProps;
}

const DatePickerTwo = ({ textFieldProps, ...props }: IDateTimePickerProps) => (
  <MUIDatePicker {...props} slotProps={{ textField: { fullWidth: true, ...textFieldProps } }} />
);

export default DatePickerTwo;
