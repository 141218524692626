import {
  AptlyUnitTemplateCategorySectionPackage,
  AptlyUnitTemplateCategorySectionProduct,
} from '@aptly-as/types';
import { IUseFieldRet } from '@ewb/reach-react';
import { useCallback, useMemo } from 'react';
import ActionBar from '../../../components/ActionBar';
import { CancelButtonWithIcon, SaveButtonWithIcon } from '../../../components/actions/buttons/Buttons';
import { AddIconButton } from '../../../components/actions/icons/Icons';
import { IApiError } from '../../../components/ApiError';
import BusyWrapper from '../../../components/BusyWrapper';
import { ICrudFieldData } from '../../../components/crud/utils/crud.utils';
import Section from '../../../components/Section';
import SimpleCheckbox from '../../../components/simple/simple-components/SimpleCheckbox';
import SimpleImageMediaUpload from '../../../components/simple/simple-components/SimpleImageMediaUpload';
import SimpleMarkdown from '../../../components/simple/simple-components/SimpleMarkdown';
import SimpleTextField from '../../../components/simple/simple-components/SimpleTextField';
import { IUseSimplePatchFn } from '../../../components/simple/useSimplePatch';
import Slicer from '../../../components/Slicer';
import i18n from '../../../libraries/i18n';
import useAptlyFields from '../../../libraries/reach/useAptlyFields';
import { ModalActions, ModalContent, ModalForm, ModalTitle } from '../../../mui/Dialog';
import { HorizontalDivider } from '../../../mui/Divider';
import Grid from '../../../mui/Grid';
import { rawSpacing } from '../../../utils/spacing';
import { IProductSearchProps } from '../../Product/ProductSearch';
import { AddProducts } from '../AddProducts';
import { AssortmentToggleButton, usePartOfAssortment } from '../Assortments';
import { unitTemplatePackageSchema } from '../unit-template-package.schema';
import PackageProducts, { IPackageProductsProps } from './PackageProducts';

export interface IPackageContentProps extends Omit<IPackageProductsProps, 'assortment'> {
  onPackageEdit: IUseSimplePatchFn<AptlyUnitTemplateCategorySectionPackage>;
  onCreate: (p: Partial<AptlyUnitTemplateCategorySectionProduct>) => void;
  onAddProducts: IProductSearchProps['onSave'];
  index: number;
  rootPath: string;
  onClose: () => void;
}

export default function PackageContent(props: IPackageContentProps) {
  const { rootPath, onClose, onCreate, onPackageEdit, index, onAddProducts, ...packageProductsProps } = props;
  const endpoint = `${rootPath}/packages`;

  const assortment = usePartOfAssortment(rootPath, props.section, props.package);
  const crudProps = useMemo(() => ({ disableAutoSave: true }), []);
  const packCrud = useAptlyFields(endpoint, props.package, unitTemplatePackageSchema(), crudProps);

  const handleOnCreate = useCallback(() => {
    onCreate({ partOfPackage: props.package._id });
  }, [onCreate, props.package._id]);

  const handleOnSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      if (assortment.state.active) {
        await assortment.toggle();
      }
      const res = await packCrud.save();
      if (res) {
        onPackageEdit(res, index);
      }
      onClose();
    },
    [packCrud, onPackageEdit, onClose, index, assortment]
  );
  const addAs = useMemo(() => ({ package: props.package }), [props.package]);

  return (
    <ModalForm fullScreen onSubmit={handleOnSubmit}>
      <ModalTitle>{props.package.name}</ModalTitle>
      <ModalContent>
        <BusyWrapper busy={packCrud.state.busy}>
          <Grid container spacing={rawSpacing}>
            <Grid item sm={3}>
              <Left crud={packCrud} />
            </Grid>
            <Grid item sm={9}>
              <Slicer>
                <PackageProducts {...packageProductsProps} assortment={assortment} />
                <Section>
                  <ActionBar left>
                    <AddProducts onSave={onAddProducts} addAs={addAs} />
                    <AddIconButton onClick={handleOnCreate} title={i18n.t('actions.addProduct')} />
                    <HorizontalDivider />
                    <AssortmentToggleButton assortment={assortment} />
                  </ActionBar>
                </Section>
              </Slicer>
            </Grid>
          </Grid>
        </BusyWrapper>
      </ModalContent>
      <Actions busy={packCrud.state.busy} onClose={onClose} />
    </ModalForm>
  );
}

interface LeftProps {
  crud: IUseFieldRet<
    AptlyUnitTemplateCategorySectionPackage,
    IApiError,
    ICrudFieldData<AptlyUnitTemplateCategorySectionPackage>
  >;
}

function Left({ crud }: LeftProps) {
  const name = crud.getField('name');
  const standard = crud.getField('standard');
  const description = crud.getField('description');
  const image = crud.getField('image');

  return (
    <Grid container flexDirection="column" gap={2}>
      <SimpleTextField field={name} onChange={crud.setField('name')} />
      <SimpleCheckbox field={standard} onChange={crud.setField('standard')} />
      <SimpleMarkdown field={description} onChange={crud.setField('description')} />
      <SimpleImageMediaUpload crud={crud} field={image} fieldKey="image" onChange={crud.setField('image')} />
    </Grid>
  );
}

function Actions({ busy, onClose }: { busy: boolean; onClose?: () => void }) {
  return (
    <ModalActions>
      <CancelButtonWithIcon onClick={onClose} disabled={busy}>
        {i18n.t('actions.close')}
      </CancelButtonWithIcon>
      <SaveButtonWithIcon variant="contained" disabled={busy} type="submit">
        {i18n.t('actions.save')}
      </SaveButtonWithIcon>
    </ModalActions>
  );
}
