import {
  AptlyOrder,
  AptlyOrderAction,
  AptlyOrderActionItem,
  AptlyOrderActionItemSignSettings,
  AptlyUnit,
} from '@aptly-as/types';
import { useGet } from '@ewb/reach-react';
import ChevronRight from '@mui/icons-material/ChevronRight';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DoneOutline from '@mui/icons-material/DoneOutline';
import DrawOutlined from '@mui/icons-material/DrawOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import Grid from '@mui/material/Grid/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import { useCallback, useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import ActionButtons from '../../../components/actions/buttons/ActionButtons';
import ApiError from '../../../components/ApiError';
import Avatar from '../../../components/Avatar';
import List, { ListItem, ListItemIcon, ListItemText } from '../../../components/List/List';
import CrudForm, { CrudFormContext } from '../../../components/simple/CrudForm';
import { ICreateModelOptions } from '../../../containers/Modal/modal.types';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import { getId } from '../../../libraries/mongoose';
import { ModalActions, ModalContent, ModalTitle } from '../../../mui/Dialog';
import Paper from '../../../mui/Paper';
import { DocumentPreviewIcon } from '../../Document/DocumentPreview';
import createMediaURL from '../../Media/createMediaURL';
import { HandleAptlyOrder, HandleOrderBody, handleOrderBodySchema } from './HandleOrder.utils';
import { HandleOrderComplete } from './HandleOrderComplete';
import { HandleOrderPayment } from './HandleOrderPayment';
import { HandleOrderSign } from './HandleOrderSign';
import { HandleOrderSignManually } from './HandleOrderSignManually';

interface Props {
  order: HandleAptlyOrder;
  unit?: AptlyUnit;
  onChange?: (order: HandleAptlyOrder) => void;
  onClose?: () => void;
}

export const handleOrderModelOptions: ICreateModelOptions = { width: 'md' };
export default function HandleOrder({ order, unit, onChange, onClose }: Props) {
  const path = useApiUrl(SlugLevel.Project, `orders/${order._id}/actions`, {
    projectID: getId(order.project),
  });
  const [state] = useGet<AptlyOrderActionItem[]>(path);
  if (unit) {
    order.unit = unit;
  }

  if (state.busy || state.error) {
    return <ApiError error={state.error} />;
  }

  return <Body path={path} order={order} actions={state.data!} onChange={onChange} onClose={onClose} />;
}

interface BodyProps extends Props {
  path: string;
  actions: AptlyOrderActionItem[];
}

function Body({ order, path, actions, onChange, onClose }: BodyProps) {
  const schema = useMemo(() => handleOrderBodySchema(), [actions]);
  const data = useMemo(
    (): HandleOrderBody => ({
      action: null,
      emailText: '',
      emailSubject: '',
      emailMarkdown: '',
      users: [],
      deadlineInDays: 30,
    }),
    []
  );

  const handleOnEdit = useCallback(
    (data: AptlyOrder) => {
      if (typeof onChange === 'function') {
        onChange(data);
      }
      if (onClose) onClose();
    },
    [onChange, onClose]
  );

  return (
    <CrudForm<HandleOrderBody, AptlyOrder> path={path} data={data} schema={schema} onEdit={handleOnEdit}>
      <ModalTitle>{i18n.t('actions.confirmAndCompleteOrder')}</ModalTitle>
      <ModalContent>
        <Content actions={actions} order={order} />
      </ModalContent>
      <ModalActions>
        <Actions onClose={onClose} />
      </ModalActions>
    </CrudForm>
  );
}

interface ContentProps extends Pick<BodyProps, 'actions' | 'order'> {}

function Content({ actions, order }: ContentProps) {
  const crud = useContext(CrudFormContext);

  const setActive = useCallback(
    (item: AptlyOrderActionItem) => {
      const email = getEmailContent(order, item);
      crud.setData({
        action: item.action,
        app: item.app?._id,
        emailSubject: item.email?.subject || email.subject,
        emailText: email.text,
        emailMarkdown: item.email?.content || '',
      });
    },
    [crud, order]
  );

  const action = actions.find((x) => {
    const isMatch = x.action === crud.state.data.action;
    if (isMatch && crud.state.data.app && x.app) {
      return x.app._id === crud.state.data.app;
    }
    return isMatch;
  });

  if (!action) {
    return (
      <Grid container direction="column" gap={2}>
        <Grid item>
          <List dense component={Paper}>
            <ListItem
              secondaryAction={<DocumentPreviewIcon document={order.receipt} projectID={getId(order.project)} />}
            >
              <ListItemText primary={order.orderNumber} secondary={(order.unit as AptlyUnit)?.name} />
            </ListItem>
          </List>
        </Grid>
        <Grid item>
          <List dense component={Paper}>
            {actions.map((item, i) => (
              <ActionItem key={i} onClick={setActive} item={item} />
            ))}
          </List>
        </Grid>
      </Grid>
    );
  }

  switch (action.action) {
    case AptlyOrderAction.Complete:
      return <HandleOrderComplete />;
    case AptlyOrderAction.SignManually:
      return <HandleOrderSignManually order={order} />;
    case AptlyOrderAction.Payment:
      return <HandleOrderPayment />;
    case AptlyOrderAction.Sign:
      return (
        <HandleOrderSign
          order={order}
          action={action as AptlyOrderActionItem<AptlyOrderActionItemSignSettings>}
        />
      );
    default:
      return <>Action not supported</>;
  }
}

function Actions({ onClose }: Pick<Props, 'onClose'>) {
  const crud = useContext(CrudFormContext);
  const handleOnClose = useCallback(() => {
    if (!crud.state.data.action) {
      if (onClose) onClose();
      return;
    }
    crud.setData({ action: null, app: null, emailSubject: '', emailText: '', users: [] });
  }, [crud, onClose]);
  const cancelLabel = crud.state.data.action ? i18n.t('actions.back') : i18n.t('actions.close');
  // Hide the action button if the action is not set.
  const submitType = crud.state.data.action ? 'submit' : 'button';

  return (
    <ActionButtons
      disabled={crud.state.busy}
      onClose={handleOnClose}
      submitType={submitType}
      actionLabel={i18n.t('actions.confirm')}
      cancelLabel={cancelLabel}
      actionIcon={<DoneOutline />}
    />
  );
}

// todo: This should be translated. But will be replaced with a new email system where this can be fixed.
function getEmailContent(order: Pick<AptlyOrder, 'orderNumber' | 'unit'>, item?: AptlyOrderActionItem) {
  if (!item) {
    return { subject: '', text: '' };
  }
  switch (item.action) {
    case AptlyOrderAction.Payment:
      return {
        subject: 'Betal ordre fra Aptly',
        text: `Enhet ${(order.unit as AptlyUnit).name} har en ordre ${
          order.orderNumber
        } klar for betaling.  Klikk på knappen nedenfor for å betale elektronisk.`,
      };
    case AptlyOrderAction.Sign:
      return {
        subject: 'Signer ordre fra Aptly',
        text: `Enhet ${(order.unit as AptlyUnit).name} har en ordre ${
          order.orderNumber
        } klar for signering. Klikk på knappen nedenfor for å signere dokumentet elektronisk. Vi setter pris på om du signerer så snart som mulig.`,
      };
    case AptlyOrderAction.SignManually:
      return {
        subject: 'Signert ordre lastet opp',
        text: `Ordre ${
          order.orderNumber
        } er bekreftet på enhet ${(order.unit as AptlyUnit).name}. Signert dokument er lagt til på din enhet og i denne eposten.`,
      };
    case AptlyOrderAction.Complete:
      return {
        subject: 'Ordre fullført',
        text: `Ordre ${
          order.orderNumber
        } er fullført på enhet ${(order.unit as AptlyUnit).name}. Du kan finner ordren på din enhet eller lagt med i denne eposten.`,
      };
  }
}

interface ActionItemProps {
  onClick: (item: AptlyOrderActionItem) => void;
  item: AptlyOrderActionItem;
}

function ActionItem({ item, onClick }: ActionItemProps) {
  const handleOnClick = useCallback(() => onClick(item), [onClick, item]);
  const appName = item.app?.name || '';

  const icon = useMemo(() => {
    const appLogo = item.app?.logo ? createMediaURL(item.app.logo.src, { width: 80 }) : null;
    switch (item.action) {
      case AptlyOrderAction.Complete:
        return <DoneOutline />;
      case AptlyOrderAction.SignManually:
        return <CloudUploadOutlinedIcon />;
      case AptlyOrderAction.Sign:
        return appLogo ? <Avatar src={appLogo} contain /> : <DrawOutlined fontSize="large" />;
      case AptlyOrderAction.Payment:
        return appLogo ? <Avatar src={appLogo} contain /> : <PaymentIcon fontSize="large" />;
      default:
        return null;
    }
  }, [item.action]);

  const primary = useMemo(() => {
    switch (item.action) {
      case AptlyOrderAction.Payment:
        return (
          <Trans
            i18nKey="actions.payOrderWith"
            values={{ name: appName }}
            components={{ 1: <strong /> }}
            defaults="Pay with <1>{{name}}</1>"
          />
        );
      case AptlyOrderAction.Complete:
        return i18n.t('actions.confirmAndCompleteOrder');
      case AptlyOrderAction.Sign:
        return (
          <Trans
            i18nKey="actions.signDocumentWith"
            values={{ name: appName }}
            components={{ 1: <strong /> }}
            defaults="Sign with <1>{{name}}</1>"
          />
        );
      case AptlyOrderAction.SignManually:
        return i18n.t('actions.signedManually');
      default:
        return '';
    }
  }, [item.action, appName]);

  const secondary = useMemo(() => {
    switch (item.action) {
      case AptlyOrderAction.Payment:
        return i18n.t('actions.sendOrderForPayment');
      case AptlyOrderAction.Complete:
        return i18n.t('info.confirmAndCompleteOrder');
      case AptlyOrderAction.Sign:
        return i18n.t('actions.sendOrderForSigning');
      case AptlyOrderAction.SignManually:
        return i18n.t('actions.uploadSignedDocument');
      default:
        return '';
    }
  }, [item.action]);

  return useMemo(() => {
    return (
      <ListItem disablePadding>
        <ListItemButton onClick={handleOnClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={primary} secondary={secondary} />
          <ChevronRight />
        </ListItemButton>
      </ListItem>
    );
  }, [icon, primary, secondary, handleOnClick]);
}
