import { AptlyFieldType, AptlyPayment } from '@aptly-as/types';
import PaymentIcon from '@mui/icons-material/Payment';
import { Alert } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';
import ActionButtons from '../../components/actions/buttons/ActionButtons.js';
import { CaptureButtonWithIcon } from '../../components/actions/buttons/Buttons.js';
import { EditIconButton } from '../../components/actions/icons/Icons.js';
import { ICrudSchema } from '../../components/crud/utils/crud.utils.js';
import CrudForm, {
  CrudFormCheckbox,
  CrudFormContext,
  CrudFormTextField,
} from '../../components/simple/CrudForm.js';
import { useModal } from '../../containers/Modal/Modal.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n.js';
import { getId } from '../../libraries/mongoose.js';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog.js';
import Grid from '../../mui/Grid.js';

interface OrderPaymentActionProps {
  payment?: AptlyPayment;
  onSave: () => void;
}

function canCapture(payment?: AptlyPayment) {
  if (!payment || payment.captured.at) {
    return false;
  }
  if (!payment.shippingDate || dayjs(payment.shippingDate).isAfter(dayjs())) {
    return false;
  }
  return true;
}

export function PaymentCaptureAction({ payment, onSave }: OrderPaymentActionProps) {
  const spawnModal = useModal(() => <PaymentCaptureModal payment={payment!} onSave={onSave} />);
  if (!canCapture(payment)) return null;
  return <EditIconButton onClick={spawnModal} icon={<PaymentIcon />} title={i18n.t('actions.setDelivery')} />;
}

export function OrderPaymentAction({ payment, onSave }: OrderPaymentActionProps) {
  const spawnModal = useModal(() => <PaymentCaptureModal payment={payment!} onSave={onSave} />);
  if (!canCapture(payment)) {
    return null;
  }
  return <CaptureButtonWithIcon onClick={spawnModal} />;
}

interface OrderPaymentProps {
  payment: AptlyPayment;
  onSave: () => void;
  onClose?: () => void;
}

interface PaymentBody {
  amount: number;
  description: string;
  terms: boolean;
}

export function PaymentCaptureModal({ payment, onSave, onClose }: OrderPaymentProps) {
  const path = useApiUrl(SlugLevel.Project, `orders/${getId(payment.order)}/payment/capture`);
  const onEdit = useCallback(() => {
    onSave();
    onClose!();
  }, [onSave, onClose]);
  const schema = useMemo(
    (): ICrudSchema<PaymentBody> => ({
      amount: {
        type: AptlyFieldType.Number,
        defaultValue: 0,
        label: i18n.t('singles.captureAmount'),
        disabled: true,
      },
      description: {
        type: AptlyFieldType.Text,
        defaultValue: '',
        label: i18n.t('singles.description'),
      },
      terms: {
        type: AptlyFieldType.Checkbox,
        defaultValue: false,
        required: true,
        label: (
          <Trans i18nKey="paragraphs.captureTerms">
            I have read the
            <a
              href="https://aptly.no/vilkar/kjopsvilkar-for-platform"
              target="_blank"
              rel="noreferrer noopener"
            >
              terms and condition
            </a>
            and accept that the payment can be captured.
          </Trans>
        ),
      },
    }),
    []
  );
  const data = useMemo(
    (): PaymentBody => ({
      amount: payment.totalCost - (payment.captured.amount || 0),
      description: '',
      terms: false,
    }),
    [payment]
  );
  return (
    <CrudForm path={path} data={data} schema={schema} onEdit={onEdit}>
      <ModalTitle>{payment.number}</ModalTitle>
      <ModalContent>
        <Grid container gap={2}>
          <Alert color="info">
            <Trans i18nKey="info.capturePayment">
              You can only capture money within 28 days of delivery, or when the shipment is in the post.
            </Trans>
          </Alert>
          <CrudFormTextField name="amount" />
          <CrudFormTextField name="description" />
          <CrudFormCheckbox name="terms" />
        </Grid>
      </ModalContent>
      <CrudFormContext.Consumer>
        {({ state }) => (
          <ModalActions>
            <ActionButtons
              onClose={onClose}
              actionIcon={<PaymentIcon />}
              actionLabel={i18n.t('actions.capture')}
              disabled={state.busy}
            />
          </ModalActions>
        )}
      </CrudFormContext.Consumer>
    </CrudForm>
  );
}
