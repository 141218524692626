import { PickersDay as Day, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import dayjs, { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { DatePickerProps, DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  StaticDatePickerProps,
  StaticDatePicker as MUIStaticDatePicker,
} from '@mui/x-date-pickers/StaticDatePicker';

type Props = {
  customComponents: Array<{
    days: string[];
    Component: ReactNode | any;
  }>;
} & Omit<StaticDatePickerProps<Dayjs>, 'renderInput'>;

const CustomWrapper = styled.span`
  display: flex;
  position: absolute;
  top: 0.25rem;
  right: 0;
`;

const DatePicker = (props: Omit<DatePickerProps<Dayjs>, 'renderInput'>) => (
  <MUIDatePicker<Dayjs> {...props} slotProps={{ textField: props as any }} />
);

const renderDay = (customComponents: any[]) => (props: PickersDayProps<Dayjs>) => {
  const thisDay = dayjs(props.day);
  const outputDate = thisDay.date();

  const components = customComponents.map((component: any) => {
    for (const day of component.days) {
      const dayDayjs = dayjs(day);
      const isSame = thisDay.isSame(dayDayjs, 'day');
      if (isSame) {
        return <component.Component key={dayDayjs.toISOString()} />;
      }
    }
    return null;
  });

  return (
    <Day {...props}>
      {outputDate}
      {components.length > 0 && <CustomWrapper>{components}</CustomWrapper>}
    </Day>
  );
};

const DatePickerStatic = ({ customComponents, ...props }: Props) => (
  <MUIStaticDatePicker<Dayjs>
    {...props}
    slots={{
      day: renderDay(customComponents),
    }}
  />
);
const DatePickerCustom = DatePickerStatic;

export default DatePicker;
export { DatePickerCustom };
