import slugify from 'slugify';
import { useCallback, MouseEvent } from 'react';
import { API_URL } from '../../../env';
import filesSaver from '../../../libraries/files-saver';
import i18n from '../../../libraries/i18n';
import { DownloadIconButton, DownloadIconButtonHref, TooltipIconButtonProps } from './Icons';

export type IExportIconProps = {
  endpoint: string;
  _id?: string;
  name?: string;
  data?: object;
  subEndpoint?: string;
  fileType?: string;
} & Partial<TooltipIconButtonProps>;

export function DownloadOnClickIcon({
  _id,
  name,
  data,
  subEndpoint = 'export',
  fileType = 'json',
  endpoint,
  onClick,
  ...props
}: IExportIconProps) {
  const handleOnClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      await filesSaver({
        endpoint: `${endpoint}${_id ? `/${_id}` : ''}/${subEndpoint}`,
        fileName: name ? `${slugify(name).toLocaleLowerCase()}.${fileType}` : undefined,
        data,
      });
      if (typeof onClick === 'function') {
        onClick(e);
      }
    },
    [endpoint, _id, subEndpoint, name, fileType, onClick]
  );
  return <DownloadIconButton onClick={handleOnClick} {...props} />;
}

export interface IExportIconV2Props extends Partial<TooltipIconButtonProps> {
  href?: string;
}

export function DownloadHrefIconButton({ href, ...props }: IExportIconV2Props) {
  return (
    <DownloadIconButtonHref href={`${API_URL}/${href}`} download color="primary" {...props}>
      {i18n.t('actions.download')}
    </DownloadIconButtonHref>
  );
}
