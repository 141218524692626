import { AptlySearchPaginateResponse } from '@aptly-as/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IReachQuery } from '@ewb/reach-react';
import { ICreateModelOptions } from '../../containers/Modal/modal.types';
import { IPaginatedSearchObject } from '../../libraries/reach/usePaginatedSearch';
import Section from '../Section';
import Grid from '../../mui/Grid';
import { rawSpacing } from '../../utils/spacing';
import ApiError from '../ApiError';
import { useStateThrottle } from '../../hooks/useStateThrottle';
import {
  ISearchCrudActions,
  ISearchCrudContext,
  ISearchHeaderProps,
  ISearchInfo,
  SearchContext,
  SearchCrudContext,
  SubAction,
} from './search.utils';
import useCrudSearch, { IUseCrudSearchProps } from '../../libraries/reach/useCrudSearch';
import { ICrudSchema } from '../crud/utils/crud.utils';
import useScopeModel, { IUseScopeProps } from '../../libraries/scope/useScopeModel';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import SearchActions from './SearchActions';
import SearchHeader from './SearchHeader';
import SearchLoader from './SearchLoader';
import SearchPageOverlay, { SearchPageOverlayProps } from './SearchPageOverlay';

export * from './search-fields/SearchFields';

export type ISearchSubActions<T extends IPaginatedSearchObject> = (
  items: T[],
  actions: ISearchCrudActions<T>
) => SubAction<T>[];

export interface ISearchProps<T extends IPaginatedSearchObject>
  extends Pick<ISearchCrudContext<T>, 'path' | 'patchPath'>,
    IUseCrudSearchProps<T> {
  searchPath?: string;
  scope: IUseScopeProps;
  schema?: ICrudSchema<T>;
  options?: ISearchCrudContext<T>['options'];
  searchFields?: SearchPageOverlayProps['searchFields'];
  buttonActions?: (items: T[], actions: ISearchCrudActions<T>) => JSX.Element[];
  subActions?: ISearchSubActions<T>;
  header: ISearchHeaderProps;
  children: (
    items: T[],
    actions: ISearchCrudActions<T>,
    info: ISearchInfo<AptlySearchPaginateResponse<T>>,
    query: IReachQuery
  ) => JSX.Element;
  onChange?: (items: T[]) => void;
  modalProps?: ICreateModelOptions;
  level?: SlugLevel;
}

export default function Search<T extends IPaginatedSearchObject>({
  path,
  patchPath,
  schema = {},
  scope: scopeProps,
  fields,
  reach = {},
  searchFields,
  buttonActions,
  subActions,
  header,
  children,
  options = {},
  onChange,
  modalProps,
  level = SlugLevel.Base,
  searchPath = '',
  ...rest
}: ISearchProps<T>) {
  const init = useRef(false);
  const scope = useScopeModel(scopeProps);
  const [show, setShow] = useState(Boolean(options.defaultShow));
  const { query: propsQuery = {} } = reach;
  const useCrudSearchProps = useMemo(
    () => ({
      fields,
      ...rest,
      reach,
      searchPath,
    }),
    [fields, rest, reach, searchPath]
  );
  const [busy, items, error, next, info, actions] = useCrudSearch<T>(
    path,
    patchPath,
    schema,
    useCrudSearchProps,
    modalProps
  );

  const [query, setQuery] = useStateThrottle<IReachQuery>(propsQuery, 350, actions.search);

  const searchActions: ISearchCrudActions<T> = useMemo(
    () => ({
      ...actions,
      setLimit: (limit: number) => setQuery((s) => ({ ...s, limit })),
      refresh: () => setQuery((s) => ({ ...s })),
      next: (q, page) => next({ ...query, ...q }, page),
    }),
    [actions, setQuery, next, query]
  );

  const searchInfo: ISearchInfo<AptlySearchPaginateResponse<T>> = useMemo(
    () => ({
      ...info,
      busy,
    }),
    [busy, info]
  );

  useEffect(() => {
    if (init.current && typeof onChange === 'function') {
      onChange(items);
    }
  }, [onChange, items]);

  useEffect(() => {
    if (!busy && !init.current) {
      init.current = true;
    }
  }, [busy]);

  const subMenuOptions = useMemo(
    () => (subActions ? (subActions(items, searchActions) as any[]) : []),
    [subActions, items, searchActions]
  );

  return (
    <SearchContext.Provider value={{ path, data: items, refresh: searchActions.refresh, query, setQuery }}>
      <SearchCrudContext.Provider
        value={{
          path,
          items,
          query,
          setQuery,
          actions: searchActions,
          options,
          info: searchInfo,
          scope,
          patchPath,
          level,
        }}
      >
        {busy && <SearchLoader />}
        {searchFields && <SearchPageOverlay show={show} searchFields={searchFields} />}
        {error && <ApiError error={error} />}
        <Section>
          <Grid container spacing={rawSpacing} justifyContent={header ? 'space-between' : 'flex-end'}>
            {header && <SearchHeader {...header}>{header.description}</SearchHeader>}
            <Grid item xs={5} md={6} lg={8}>
              {buttonActions && (
                <SearchActions setShow={setShow} subMenuOptions={subMenuOptions} showSearch={!!searchFields}>
                  {buttonActions(items, searchActions).map((Action, i) => (
                    <Grid item key={i}>
                      {Action}
                    </Grid>
                  ))}
                </SearchActions>
              )}
            </Grid>
          </Grid>
        </Section>
        <Section>{children(items, searchActions, searchInfo, query)}</Section>
      </SearchCrudContext.Provider>
    </SearchContext.Provider>
  );
}
