import { PropsWithChildren, useContext } from 'react';
import { UserContext } from '../models/User/UserContext';

export function HideMobileFragment({ children }: PropsWithChildren<{}>) {
  const mobile = useMobile();
  if (mobile) return null;
  return children;
}

export function useMobile() {
  const { mobile } = useContext(UserContext);
  return mobile;
}
