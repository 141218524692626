import { AptlyScopes } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import Search, { SearchFieldSelect } from '../../components/Search/Search';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import i18n from '../../libraries/i18n';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { dataGridSearchTextColumn } from '../../components/Search/search-data-grid/data-grid.fields';
import {
  ICrudAptlyQueueDownload,
  queueDownloadSchema,
  queueDownloadStatusOptions,
  queueDownloadTypeOptions,
} from '../../components/crud/schema/queue-downloads';
import DataGridSelectEdit from '../../components/Search/search-data-grid/DataGridSelectEdit';

const columns = (): GridColDef[] => [
  {
    type: 'string',
    field: 'status',
    headerName: i18n.t('singles.status'),
    flex: 0.25,
    renderCell: ({ value }) => {
      const found = queueDownloadStatusOptions().find((x) => x.value === value);
      return found?.label || value;
    },
    editable: true,
    renderEditCell: (params) => <DataGridSelectEdit params={params} options={queueDownloadStatusOptions()} />,
  },
  {
    type: 'string',
    field: 'type',
    headerName: i18n.t('singles.type'),
    flex: 0.25,
    renderCell: ({ value }) => {
      const found = queueDownloadTypeOptions().find((x) => x.value === value);
      return found?.label || value;
    },
  },
  {
    type: 'string',
    field: 'product',
    headerName: i18n.t('singles.product'),
    renderCell: (params) => (params.value ? params.value.name : 'N/A'),
    flex: 0.5,
  },
  {
    ...dataGridSearchTextColumn('url', i18n.t('singles.url')),
    renderCell: (params) => (
      <a href={params.value} target="_blank" rel="noopener noreferrer">
        {params.value}
      </a>
    ),
    flex: 0.5,
  },
];

function QueueDownloads() {
  const path = useApiUrl(SlugLevel.Organization, 'queue-downloads');

  return (
    <Search<ICrudAptlyQueueDownload>
      path={path}
      patchPath={toSearchPatchPath('queue-downloads')}
      scope={AptlyScopes.Admin}
      header={{ title: i18n.t('singles.downloadQueue') }}
      post={{ title: i18n.t('singles.downloadQueue') }}
      patch={{ title: (data) => String(data.url) }}
      schema={queueDownloadSchema()}
      fields={['status', 'url']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: '-status', $populate: 'product:_id,name' } }}
      searchFields={[
        <SearchFieldSelect
          field="status"
          label={i18n.t('singles.status')}
          options={[{ value: '', label: i18n.t('singles.all') }, ...queueDownloadStatusOptions()]}
        />,
      ]}
    >
      {() => <SearchDataGrid columns={columns()} />}
    </Search>
  );
}

export default QueueDownloads;
