import { useCallback } from 'react';
import i18n from '../../libraries/i18n';
import { useSimpleCrudFields } from '../../components/simple/useSimpleCrud';
import { useOrganization } from '../Organization/OrganizationContext';
import { useProject } from '../Project/ProjectContext';
import inquirySchema, { IInquirySchema } from './inquiry.schema';
import { SimpleCrudContent, SimpleCrudForm } from '../../components/simple/SimpleCrud';
import ApiError from '../../components/ApiError';

type Props = {
  onCreate: (res: any) => void;
  unitID?: string;
  onClose?: () => void;
};

export default function InquiryNew(props: Props) {
  const organization = useOrganization();
  const project = useProject();

  const { unitID, onClose, onCreate } = props;
  const path = `organizations/${organization.slug}/projects/${project._id}/inquiries`;
  const crudFields: (keyof IInquirySchema)[] = ['title', 'message', 'files'];
  if (!unitID) {
    crudFields.unshift('unit');
  }

  const [crud, fields] = useSimpleCrudFields<IInquirySchema, keyof IInquirySchema>(
    path,
    unitID ? { unit: unitID } : {},
    inquirySchema(),
    crudFields,
    {
      reachOptions: {
        type: 'multipart/form-data',
        fileKeys: ['files'],
      },
      dontSetStateOnPost: true,
    }
  );

  const handleOnSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const res = await crud.save();
      if (res) {
        onCreate(res as any);
        if (onClose) {
          onClose();
        }
      }
    },
    [crud, onCreate, onClose]
  );

  return (
    <SimpleCrudForm
      title={i18n.t('actions.newMessage')}
      onClose={onClose}
      actionButtonProps={{ disabled: crud.state.busy }}
      onSubmit={handleOnSubmit}
    >
      <SimpleCrudContent crud={crud} schema={fields} />
      {crud.state.error && <ApiError error={crud.state.error} />}
    </SimpleCrudForm>
  );
}
