import styled, { css } from 'styled-components';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';

interface StyledAvatarProps {
  size?: string;
  image?: string | null;
  contain?: boolean;
  variant?: MuiAvatarProps['variant'];
}

const StyledAvatar = styled.div<StyledAvatarProps>`
  width: ${(props) => (props.size ? props.size : '40px')};
  height: ${(props) => (props.size ? props.size : '40px')};
  background-color: ${(props) => props.theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.primary.contrastText};

  ${(props) =>
    props.image &&
    css`
      background-color: transparent;
      background-image: url(${props.image});
      background-size: ${props.contain ? 'contain' : 'cover'};
      background-position: center;
      background-repeat: no-repeat;
    `};

  border-radius: ${(props) => {
    switch (props.variant) {
      case 'square':
        return '0px';
      case 'rounded':
        return '4px';
      default:
        return '50%';
    }
  }};
`;

const Label = styled.span`
  font-size: 1.25rem;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
`;

interface AvatarProps extends Partial<Omit<MuiAvatarProps, 'src'>> {
  src?: string | null;
  size?: string;
  contain?: boolean;
}

function Avatar(props: AvatarProps) {
  const { src, alt, size, contain, ...rest } = props;

  if (src && !contain) {
    return <MuiAvatar src={src} variant="rounded" {...rest} />;
  }

  return (
    <div>
      <StyledAvatar image={src} size={size} contain={contain} variant={rest.variant}>
        {!src ? <Label>{alt ? alt[0] : 'U'}</Label> : null}
      </StyledAvatar>
    </div>
  );
}

export default Avatar;
