import { ReactNode } from 'react';
import { SelectOption } from '../../../mui/Select';
import { ListItem } from '../../List/List';
import ExpandableButton from '../../ExpandableButton';

interface ActionSelectOption extends SelectOption {
  icon?: ReactNode;
}

interface SelectProps {
  options: ActionSelectOption[];
  onChange?: (value: ActionSelectOption['value']) => void;
  title: string;
}

export default function SelectButton({ onChange, options, title }: SelectProps) {
  return (
    <ExpandableButton label={title} color="primary">
      {(close) =>
        options.map((option) => (
          <ListItem
            key={option.value}
            button
            icon={option.icon || undefined}
            onClick={() => {
              if (typeof onChange === 'function') {
                onChange(option.value);
              }
              close();
            }}
          >
            {option.label}
          </ListItem>
        ))
      }
    </ExpandableButton>
  );
}
