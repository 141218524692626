import i18n from 'i18next';
import { forwardRef, ReactElement, useCallback, useContext, useState } from 'react';
import { AptlyJobQueue } from '@aptly-as/types';
import { DownloadButtonWithIcon } from '../../components/actions/buttons/Buttons';
import simpleRequest from '../../libraries/fetch/simpleRequest';
import { OrganizationContext } from '../../models/Organization/OrganizationContext';
import { ButtonProps } from '../../mui/Button';
import { ConfirmFormModal } from '../Modal/ConfirmModal';

import { createModal } from '../Modal/ModalContext';
import { IJobItem } from './job.utils';
import { JobsContext } from './Jobs.context';

export interface JobExportDocumentProps extends Omit<ButtonProps, 'onClick'> {
  endpoint: string;
  label?: string;
  jobLabel: string;
  queue?: AptlyJobQueue;
  onComplete?: (returnValue: any) => void;
  confirm?: ReactElement;
  query?: object;
}

export const JobExportDocument = forwardRef<HTMLButtonElement, JobExportDocumentProps>(
  (
    {
      endpoint,
      queue = AptlyJobQueue.Documents,
      label = i18n.t('actions.export'),
      jobLabel,
      onComplete,
      disabled,
      confirm,
      query,
      ...props
    },
    ref
  ) => {
    const [_disabled, setDisabled] = useState(disabled);
    const org = useContext(OrganizationContext);
    const { spawnJob } = useContext(JobsContext);
    const orgId = org.data._id;

    const handleOnClick = useCallback(() => {
      const doJob = async (data?: FormData) => {
        setDisabled(true);
        await simpleRequest<IJobItem>({
          endpoint,
          query: {
            ...query,
            ...(data ? Object.fromEntries(data) : {}),
          },
          onRequestDone: (d) =>
            spawnJob(d, {
              queue,
              organization: orgId,
              label: jobLabel,
              onComplete,
            }),
        });
      };
      if (confirm) {
        return createModal(
          <ConfirmFormModal onConfirm={doJob} actionLabel={i18n.t('actions.confirm')}>
            {confirm}
          </ConfirmFormModal>
        );
      } else {
        return doJob();
      }
    }, [endpoint, query, queue, orgId, jobLabel, spawnJob, onComplete, confirm]);

    return (
      <DownloadButtonWithIcon disabled={_disabled} {...props} ref={ref} onClick={handleOnClick}>
        {label}
      </DownloadButtonWithIcon>
    );
  }
);
