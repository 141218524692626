import { createContext } from 'react';
import { AptlyApp, AptlyOrganizationAppConfig } from '@aptly-as/types';

interface IAppContext {
  app: AptlyApp;
  config: AptlyOrganizationAppConfig;
}

export const AppContext = createContext<IAppContext>({
  app: {} as AptlyApp,
  config: {} as AptlyOrganizationAppConfig,
});
export const AppProvider = AppContext.Provider;
