import dayjs, { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import { ICheckboxProps, ITextFieldProps, Switch, TextField } from '../../../mui/Input';
import Select, { ISelectProps } from '../../../mui/Select';
import DatePickerTwo, { IDateTimePickerProps } from '../../../mui/x-date-picker/DatePickerTwo';
import { ISearchFieldActionProps, useSearchQueryState } from './search-field.utils';

export * from './search-field.utils';
export * from './SearchFieldReference';

type SearchFieldProps = ISearchFieldActionProps &
  Omit<ITextFieldProps, 'onChange' | 'fullWidth' | 'defaultValue' | 'value'>;
export function SearchFieldText({ field, queryKey, ...rest }: SearchFieldProps) {
  const [value, setValue] = useSearchQueryState(field, queryKey);
  return <TextField fullWidth defaultValue={value} onChange={setValue} {...rest} />;
}

export type ISearchFieldSelectProps = ISearchFieldActionProps & ISelectProps;
export function SearchFieldSelect({ field, queryKey, ...rest }: ISearchFieldSelectProps) {
  const [value, setValue] = useSearchQueryState(field, queryKey);
  // @ts-ignore
  return <Select fullWidth defaultValue={value} onChange={setValue} {...rest} />;
}

export interface ISearchSwitchProps
  extends ISearchFieldActionProps,
    Omit<ICheckboxProps, 'onChange' | 'checked'> {
  onValue?: string;
  offValue?: string;
}
export function SearchSwitch({
  field,
  queryKey,
  onValue = 'true',
  offValue = '',
  ...rest
}: ISearchSwitchProps) {
  const [value, setValue] = useSearchQueryState(field, queryKey);
  return (
    <Switch
      checked={value === onValue}
      onChange={(_e, checked) => setValue(checked ? onValue : offValue)}
      {...rest}
    />
  );
}

type SearchFieldDateProps = ISearchFieldActionProps &
  Omit<IDateTimePickerProps, 'onChange' | 'fullWidth' | 'defaultValue' | 'value'> & {
    endDate?: boolean;
  };
export function SearchFieldDate({ field, queryKey, endDate, ...rest }: SearchFieldDateProps) {
  const [value, setValue] = useSearchQueryState<Date | null>(field, queryKey);
  const handleOnChange = useCallback(
    (value: Dayjs | null) => {
      if (endDate && value) {
        setValue(value.endOf('day').toDate());
        return;
      }
      setValue(value ? value.toDate() : value);
    },
    [endDate, setValue]
  );
  return <DatePickerTwo defaultValue={dayjs(value)} onChange={handleOnChange} {...rest} />;
}
