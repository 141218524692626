import { useCallback, useEffect, useState } from 'react';
import apiRequest, { RequestOpts } from '../libraries/fetch/apiRequest';
import handleError from '../containers/Error/handleError';
import { ApiError } from '../containers/Error/createError';

interface State<T> {
  busy: boolean;
  data: T | null;
  error: any;
}

/**
 * @deprecated. Use useGet instead.
 */
export default function useApiRequest<T>(
  path: string,
  opts?: RequestOpts
): [boolean, T | null, ApiError, () => void] {
  const dontFetch = opts ? opts.dontFetch : false;
  const [state, setState] = useState<State<T>>({
    busy: !dontFetch,
    data: null,
    error: null,
  });

  const getData = useCallback(
    async function () {
      try {
        const data = await apiRequest<T>(path, opts);
        setState((s) => ({ ...s, data, busy: false }));
      } catch (e) {
        handleError(e);
        setState((s) => ({ ...s, busy: false, error: e }));
      }
    },
    [path, opts]
  );

  useEffect(() => {
    if (!dontFetch) {
      getData().then();
    }
  }, [getData, dontFetch]);

  return [state.busy, state.data, state.error, getData];
}
