import {
  AptlyApp,
  AptlyAppImplementationSetting,
  AptlyAppSetting,
  AptlyOrganizationAppConfig,
} from '@aptly-as/types';
import { useMemo } from 'react';

export const byAppConfigIdOrSlug = (param: string) => (config: AptlyOrganizationAppConfig) => {
  if (config.app && typeof config.app === 'object') {
    if (config.app._id === param) return true;
    if (config.app.slug === param) return true;
  }
  return config.app === param;
};

interface IAppSettingData<T extends object, K extends keyof T> {
  setting?: AptlyAppSetting;
  defaultValue?: T[K];
}
export function getAppSetting<T extends object, K extends keyof T>(
  app: Pick<AptlyApp, 'settings'>,
  settings: T | undefined,
  key: K
): IAppSettingData<T, K> {
  const setting = app.settings.find((x) => x.key == AptlyAppImplementationSetting.SignMethod);
  if (setting) {
    let defaultValue = setting.defaultValue || setting.options[0]?.value;
    if (settings && key in settings && settings[key]) {
      defaultValue = settings[key];
    }
    return {
      setting,
      defaultValue,
    };
  }
  return {};
}

export function useAppSetting<T extends object, K extends keyof T>(
  app: Pick<AptlyApp, 'settings'> | undefined | null,
  settings: T | undefined,
  key: K
): [T[K] | undefined, AptlyAppSetting | undefined] {
  return useMemo(() => {
    if (!app) return [undefined, undefined];
    const { defaultValue, setting } = getAppSetting(app, settings, key);
    return [defaultValue, setting];
  }, [app, settings, key]);
}
