import { AptlyJobQueue, AptlyScopes } from '@aptly-as/types';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useCallback, useContext } from 'react';
import Report, { ReportProps } from '../../containers/Admin/Report';
import { IJobItem } from '../../containers/Job/job.utils';
import { JobsContext } from '../../containers/Job/Jobs.context';
import { SlugLevel, useApiUrl, useSlugs } from '../../hooks/useGetApiUrl';
import simpleRequest from '../../libraries/fetch/simpleRequest';
import i18n from '../../libraries/i18n';
import Scope from '../../libraries/scope/Scope';
import { ButtonWithIcon } from '../../mui/Button';

export default function UnitReports() {
  const [organization, , unitID] = useSlugs();
  const v1Base = useApiUrl(SlugLevel.Unit);
  const { spawnJob } = useContext(JobsContext);

  const handleOnReportCreate = useCallback(() => {
    simpleRequest<IJobItem>({
      endpoint: `${v1Base}/report-job`,
      onRequestDone: (data) => {
        spawnJob(data, {
          label: i18n.t('singles.report'),
          organization,
          queue: AptlyJobQueue.Units,
        });
      },
    });
  }, [spawnJob, organization]);

  const additionalActions: ReportProps['additionalActions'] = useCallback(() => {
    return [
      <ButtonWithIcon key="report" color="primary" variant="outlined" onClick={handleOnReportCreate}>
        {i18n.t('singles.report')}.pdf
        <CloudDownloadOutlinedIcon />
      </ButtonWithIcon>,
    ];
  }, [v1Base, handleOnReportCreate]);

  return (
    <Report
      unit={unitID}
      additionalActions={additionalActions}
      showPrice={Scope.crud(AptlyScopes.ProjectReportPrice, 'R')}
    />
  );
}
