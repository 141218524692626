import dayjs from 'dayjs';
import TimePicker from '../../../mui/x-date-picker/TimePicker';
import { ISimpleComponentProps } from './simple-components.utils';

export interface ICrudDateProps<T extends object> extends ISimpleComponentProps<T, any> {}

export default function SimpleTime<T extends object>({ field, onChange }: ICrudDateProps<T>) {
  const { id, value, defaultValue, edited, type, ...rest } = field;
  return (
    <TimePicker
      value={dayjs(value)}
      onChange={(date) => onChange(date ? date.toISOString() : null)}
      {...rest}
    />
  );
}
