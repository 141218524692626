import { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import Grid from '@mui/material/Grid';
import { Alert, LinearProgress } from '@mui/material';
import Upload from '@mui/icons-material/Upload';
import { CropIconButton, DeleteIconButton } from '../../components/actions/icons/Icons';
import useFileUploader, { FileUploaderFn } from '../../hooks/useFileUploader';
import i18n from '../i18n';
import { dropZoneCss } from './drop-zone.utils';
import AptlyMedia, { IAptlyMediaProps } from '../../components/AptlyMedia';
import { IImageCropperProps, ImageCropper } from '../../models/Media/ImageCropper';

export interface IDropZoneProps<M extends boolean> extends Omit<IFileDropzoneProps, 'onDrop'> {
  multiple?: M;
  onDrop: FileUploaderFn<M>;
}

export default function DropZone<M extends boolean = false>({
  multiple,
  onDrop,
  ...rest
}: IDropZoneProps<M>) {
  const [percent, handleOnDrop] = useFileUploader(Boolean(multiple), onDrop);

  return (
    <Grid container flexDirection="column">
      {percent ? (
        <Grid item>
          <LinearProgress variant="buffer" value={percent} valueBuffer={percent * 2} />
        </Grid>
      ) : null}
      <Grid item>
        <FileDropzone onDrop={handleOnDrop} multiple={Boolean(multiple)} {...rest} />
      </Grid>
    </Grid>
  );
}

export interface IFileDropzoneProps
  extends Pick<DropzoneOptions, 'accept' | 'multiple' | 'disabled'>,
    Partial<Pick<IImageCropperProps, 'aspect' | 'onCrop'>>,
    Pick<IAptlyMediaProps, 'media' | 'src'> {
  onDrop: (files: File[]) => void;
  required?: boolean;
  onDelete?: () => void;
  label?: string;
  dark?: boolean;
}
export function FileDropzone({
  media,
  src,
  onDrop,
  required,
  onDelete,
  label = i18n.t('actions.upload'),
  onCrop,
  aspect,
  dark,
  ...rest
}: IFileDropzoneProps) {
  const [invalid, setInvalid] = useState(false);
  const handleDrop = useCallback(
    async (acceptedFiles: any) => {
      onDrop(fileListToArray(acceptedFiles));
      setInvalid(false);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    ...rest,
  });

  const handleOnDelete = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (window.confirm(i18n.t('paragraphs.areYouSure'))) {
        onDelete!();
      }
    },
    [onDelete]
  );

  return (
    <>
      <Dropzone {...getRootProps()} active={isDragActive} dark={dark}>
        <input {...getInputProps()} required={required} onInvalid={() => setInvalid(true)} />
        <Grid container justifyContent="center" alignItems="center" style={{ position: 'relative' }}>
          {src || media ? (
            <>
              <AptlyMedia
                src={src}
                media={media}
                opts={{ disableFullWidth: true, transforms: 'c_fit' }}
                width={320}
              />
            </>
          ) : (
            <>
              <Upload />
              {label}
            </>
          )}
        </Grid>
        {(src || media) && onDelete && <StyledDeleteButton onClick={handleOnDelete} />}
        {media && onCrop && aspect && <MediaCropperIcon media={media} aspect={aspect} onCrop={onCrop} />}
      </Dropzone>
      {invalid && <Alert severity="warning">Velg fil</Alert>}
    </>
  );
}

export function fileListToArray(fileList: FileList) {
  return Object.keys(fileList).map((k: any) => fileList[k]);
}

const Dropzone = styled.div<{ active: boolean; dark?: boolean }>`
  max-width: 100%;
  position: relative;

  ${dropZoneCss};
  min-height: 12rem;
  padding: 1rem;

  ${(props) =>
    props.dark &&
    css`
      background-color: ${props.theme.palette.secondary.dark};
      color: ${props.theme.palette.secondary.contrastText};
      svg {
        fill: ${props.theme.palette.secondary.contrastText};
      }
    `};

  img {
    width: auto;
    height: 320px;
    max-width: 100%;
  }
`;

const StyledDeleteButton = styled(DeleteIconButton)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export function MediaCropperIcon({
  media,
  aspect,
  onCrop,
}: Pick<IImageCropperProps, 'media' | 'aspect' | 'onCrop'>) {
  const [show, setShow] = useState(false);

  const handleOnClick = useCallback((e: any) => {
    e.stopPropagation();
    setShow((s) => !s);
  }, []);

  return (
    <>
      <StyledCropButton onClick={handleOnClick} />
      {show && (
        <ImageCropper
          media={media}
          area={media.area}
          location={media.location}
          aspect={aspect}
          onCrop={(a, l) => {
            setShow(false);
            onCrop(a, l);
          }}
        />
      )}
    </>
  );
}

const StyledCropButton = styled(CropIconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
