import { AptlyAlgorithm, AptlyFieldType, AptlyUnitTemplateCategorySectionPackage } from '@aptly-as/types';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import {
  schemaMediaImage,
  schemaName,
  schemaSelectAlgorithm,
} from '../../components/crud/schema/fields.schema';
import i18n from '../../libraries/i18n';
import { ICrudImport, importSchema } from '../../components/crud/schema/extends.schemas';
import { acceptImages } from '../../libraries/react-dropzone/drop-zone.utils';

export const packageCrudFields: (keyof AptlyUnitTemplateCategorySectionPackage)[] = [
  'name',
  'standard',
  'description',
  'image',
];

interface ICrudPackageImport extends ICrudImport, Pick<AptlyUnitTemplateCategorySectionPackage, 'standard'> {
  algorithm: string;
}

export const unitTemplatePackageSchema = (): ICrudSchema<AptlyUnitTemplateCategorySectionPackage> => ({
  name: schemaName({ autoFocus: true }),
  description: {
    type: AptlyFieldType.Markdown,
    defaultValue: '',
    label: i18n.t('singles.description'),
    // @ts-ignore Is there, but to lazy to type when only used once
    left: true,
  },
  image: {
    type: AptlyFieldType.Image,
    defaultValue: null,
    label: i18n.t('singles.image'),
    image: {
      accept: acceptImages,
      mediaKey: 'imageMedia',
      width: 1080,
    },
  },
  imageMedia: schemaMediaImage(),
  standard: {
    type: AptlyFieldType.Checkbox,
    defaultValue: false,
    label: i18n.t('singles.standard'),
  },
});

export const unitTemplateImportPackageSchema = (
  algorithms: AptlyAlgorithm[]
): ICrudSchema<ICrudPackageImport> => ({
  ...(importSchema() as ICrudSchema<ICrudPackageImport>),
  algorithm: schemaSelectAlgorithm(algorithms) as ICrudSchema<ICrudPackageImport>['algorithm'],
  standard: {
    type: AptlyFieldType.Checkbox,
    defaultValue: false,
    label: i18n.t('singles.standard'),
  },
});
