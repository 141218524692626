import { TimePicker as MUITimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import { Dayjs } from 'dayjs';

const TimePicker = (props: Omit<TimePickerProps<Dayjs>, 'renderInput'>) => (
  <MUITimePicker
    {...props}
    slotProps={{ textField: { autoComplete: 'off', fullWidth: true, type: 'text', ...(props as any) } }}
  />
);

export default TimePicker;
