import { useCallback, useMemo } from 'react';
import { AptlyUnitTemplateCategorySectionProduct } from '@aptly-as/types';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { DeleteIconButton, EditIconButton } from '../../../components/actions/icons/Icons';
import Section from '../../../components/Section';
import Typography from '../../../mui/Typography';
import i18n from '../../../libraries/i18n';
import { byOrderIds } from '../../../utils/array';
import { AssortmentReturn } from '../Assortments';
import { ProductHead, ProductRow, ProductTable } from '../product/ProductRow';
import { TableBody } from '../../../mui/Table';
import { ISortableDataProps, useUnitTemplateSortable } from '../utils/unit-template.sortable';
import { IUseSimpleDeleteFn } from '../../../components/simple/useSimpleDelete';
import { IUseSimplePatchFn } from '../../../components/simple/useSimplePatch';

export interface IPackageProductsProps extends Omit<PackageProductProps, 'product' | 'index'> {
  products: AptlyUnitTemplateCategorySectionProduct[];
}

export default function PackageProducts({ products, onDelete, ...rest }: IPackageProductsProps) {
  const items = useMemo(() => {
    const ps = products
      .filter((x) => x.partOfPackage === rest.package._id)
      .map((x) => ({ ...x, id: `product-${x._id}` }));
    return rest.package._product_order ? ps.sort(byOrderIds(rest.package._product_order)) : ps;
  }, [products, rest.package._product_order, rest.package._id]);

  const handleOnDelete = useCallback(
    (p: any, i: any) => {
      const index = products.findIndex((x) => x._id === p._id);
      onDelete(p, index > -1 ? index : i);
    },
    [products, onDelete]
  );

  return (
    <Section>
      <Typography variant="subtitle1">{i18n.t('singles.products')}</Typography>
      <ProductTable>
        <ProductHead checkbox={rest.assortment.state.active} hideStandard />
        <TableBody>
          <SortableContext items={items} strategy={rectSortingStrategy}>
            {items.map((product, i) => (
              <PackageProduct
                {...rest}
                key={product._id}
                onDelete={handleOnDelete}
                product={product}
                index={i}
              />
            ))}
          </SortableContext>
        </TableBody>
      </ProductTable>
    </Section>
  );
}

interface PackageProductProps extends Omit<ISortableDataProps, 'product'> {
  product: AptlyUnitTemplateCategorySectionProduct;
  index: number;
  onDelete: IUseSimpleDeleteFn<AptlyUnitTemplateCategorySectionProduct>;
  onEdit: IUseSimplePatchFn<AptlyUnitTemplateCategorySectionProduct>;
  assortment: AssortmentReturn;
}

function PackageProduct(props: PackageProductProps) {
  const { index, onDelete, onEdit, product, assortment, section } = props;
  const [{ style, ref }, , { attributes, listeners }] = useUnitTemplateSortable(props);
  const sectionAssortment = useMemo(() => {
    if (!product.partOfAssortment || !section.assortments) return undefined;
    return section.assortments.find((x) => x._id === product.partOfAssortment);
  }, [section, product.partOfAssortment]);

  return (
    <ProductRow
      attributes={attributes}
      listeners={listeners}
      style={style}
      ref={ref}
      product={product}
      assortment={sectionAssortment}
      checkbox={assortment.checkboxProps(product)}
    >
      <EditIconButton onClick={() => onEdit(product, index)} size="medium" />
      <DeleteIconButton onClick={() => onDelete(product, index)} size="medium" />
    </ProductRow>
  );
}
