import { AptlyUnit } from '@aptly-as/types';
import Email from '@mui/icons-material/Email';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { MouseEvent } from 'react';
import { CLIENT_URL } from '../../../env';
import i18n from '../../../libraries/i18n';
import { SendIconButton } from '../../actions/icons/Icons';
import SimpleCrud from '../../SimpleCrud';

interface Props {
  unit: AptlyUnit;
  endpoint: string;
  onCreate: (unit: any) => void;
  onClose?: () => void;
}

export default function CrudUnitInvite({ unit, endpoint, onCreate, onClose }: Props) {
  const crudEndpoint = `${endpoint}/${unit._id}/invites/?clientUrl=${CLIENT_URL || ''}`;
  return (
    <SimpleCrud
      title={unit.name + ': ' + i18n.t('actions.sendInvitation')}
      actionLabel={i18n.t('actions.sendInvitation')}
      endpoint={crudEndpoint}
      onCreate={onCreate}
      onClose={onClose}
      fields={[
        {
          type: 'checkbox',
          name: 'queue',
          value: false,
          label: '',
          helpField: i18n.t('actions.queueInvite'),
        },
        {
          type: 'email',
          name: 'email',
          value: null,
          label: '',
          helpField: i18n.t('singles.email'),
          required: true,
        },
        {
          type: 'text',
          name: 'fullName',
          value: null,
          label: '',
          helpField: i18n.t('singles.name'),
        },
        {
          type: 'text',
          name: 'phone',
          value: null,
          label: '',
          helpField: i18n.t('singles.phone'),
        },
        {
          type: 'customComponent',
          name: 'invites',
          value: (unit.invites || []).filter((x) => !x.claim.claimed),
          label: '',
          helpField: i18n.t('singles.invites'),
          renderComponent: (set, get) => {
            const invites: AptlyUnit['invitesQueue'] = [
              ...(get('invites').value as AptlyUnit['invites']).map((x) => x.claim),
              ...(unit.queueInvites?.map((email) => ({ email })) || []),
              ...unit.invitesQueue,
            ];
            const addToEmail = async (e: MouseEvent<HTMLButtonElement>) => {
              const email = e.currentTarget.name;
              const invite = invites.find((x) => x.email === email);
              const form = e.currentTarget.form;
              await set('queue', false, 'false');
              await set('email', email, email);
              if (invite?.fullName) {
                await set('fullName', invite.fullName, invite.fullName);
              }
              if (invite?.phone) {
                await set('phone', invite.phone, invite.phone);
              }
              if (form) form.requestSubmit();
            };
            if (invites.length === 0) return null;
            return (
              <List dense>
                {invites.map((invite, i) => (
                  <ListItem
                    key={i}
                    secondaryAction={<SendIconButton name={invite.email} onClick={addToEmail} />}
                  >
                    <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                    <ListItemText primary={invite.email} secondary={invite.fullName} />
                  </ListItem>
                ))}
              </List>
            );
          },
        },
      ]}
    />
  );
}
