import isEqual from 'lodash.isequal';
import React from 'react';
import apiRequest from '../libraries/fetch/apiRequest';
import handleError from '../containers/Error/handleError';
import { notificationRequestProtocol } from '../containers/Notification/notification.utils';
import confirm from '../utils/confirm';
import { ErrorResponse } from '../libraries/cloudinary/cloudinary';
import i18n from '../libraries/i18n';

export type UpdateDeleteResponse = {
  [key: string]: any;
};

type SubdocumentObject = {
  [key: string]: any;
};

export type Value = Object | string | File | SubdocumentObject | boolean | number | any[] | null;
export type Label = string;

export type Field = {
  name: string;
  value: Value;
  label: Label;
  forceSave?: boolean;
  changed?: boolean;
  meta?: any;
};

type SubdocumentData = {
  _id: string;
  label: string;
  value: Value;
};

export type Subdocument = {
  endpoint: string;
  name: string;
  previews: SubdocumentData[];
  delete?: string[];
  create?: SubdocumentData[];
  changed?: boolean;
};

export type GetField = (str: string) => Field;
export type SetValue = (name: string, value: Value, label: Label, meta?: any) => Promise<void>;
export type GetSubdocument = (str: string) => Subdocument;
export type AddToSubdocument = (name: string, data: SubdocumentData) => void;
export type DeleteFromSubdocument = (name: string, id: string) => void;

type UpdateDeleteInterface = {
  busy: boolean;
  save: (event?: any) => any;
  destroy: () => any;
  getField: GetField;
  setValue: SetValue;
  getSubdocument: GetSubdocument;
  addToSubdocument: AddToSubdocument;
  deleteFromSubdocument: DeleteFromSubdocument;
  changesHaveBeenMade: boolean;
  reset: () => boolean;
};

type BaseProps = {
  endpoint: string;
  id?: string;
  onSuccessfulSave?: (res: UpdateDeleteResponse) => void;
  onSuccessfulDelete?: (res: UpdateDeleteResponse) => void;
  onError?: (error: any) => void;
  fields?: Field[];
  subdocuments?: Subdocument[];
  dontPromtDelete?: boolean;
  dontPromptReset?: boolean;
  resetFieldsOnSave?: boolean;
  multipart?: boolean;
  children: (res: UpdateDeleteInterface) => React.ReactNode;
  forceSaveAllField?: boolean;
  customAuth?: string;
};

type UpdateDeleteProps = BaseProps & {
  onUpdateRequestComplete: (err?: ErrorResponse | any) => void;
  onDeleteRequestComplete: (err?: ErrorResponse | any) => void;
};

type State = {
  busy: boolean;
  internalFields: Field[];
  internalSubdocuments: Subdocument[];
  startedEditing: boolean;
};

/**
 * @deprecated Old crud
 */
class UpdateDelete extends React.Component<UpdateDeleteProps, State> {
  state = {
    startedEditing: false,
    busy: false,
    ...syncFields(this.props.fields, this.props.subdocuments),
  };

  componentDidUpdate(prevProps: BaseProps) {
    const { fields, subdocuments } = this.props;

    if (!isEqual(fields, prevProps.fields) || !isEqual(subdocuments, prevProps.subdocuments)) {
      this.setState(syncFields(fields, subdocuments));
    }
  }

  save = async (event?: any) => {
    const {
      endpoint,
      id,
      onUpdateRequestComplete,
      onError,
      onSuccessfulSave,
      fields,
      multipart,
      forceSaveAllField,
      resetFieldsOnSave,
      customAuth,
    } = this.props;

    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }

    const { internalFields, internalSubdocuments, busy } = this.state;

    if (busy) {
      return;
    }

    if (id && internalSubdocuments) {
      await this.handleSubdocuments(`${endpoint}/${id}`, internalSubdocuments);
    }

    let data = {};

    internalFields.forEach((element: Field) => {
      let eligibleForSave = true;

      if (!forceSaveAllField && id && fields) {
        const field = fields.find((f) => f.name === element.name);

        if (field && !field.forceSave && field.value === element.value) {
          eligibleForSave = false;
        }
      }

      if (eligibleForSave) {
        // @ts-ignore
        data[element.name] = element.value;
      }
    });

    try {
      this.setState({
        busy: true,
      });

      const response: any = await apiRequest(id ? `${endpoint}/${id}` : endpoint, {
        method: id ? 'PUT' : 'POST',
        data,
        notificationMessage: id ? i18n.t('statuses.saving') : i18n.t('statuses.creating'),
        multipart,
        customAuth,
      });

      if (!id && '_id' in response && internalSubdocuments) {
        await this.handleSubdocuments(`${endpoint}/${response._id}`, internalSubdocuments);
      }

      this.setState({
        busy: false,
        ...syncFields(internalFields, internalSubdocuments),
      });

      onUpdateRequestComplete();

      if (typeof onSuccessfulSave === 'function') {
        onSuccessfulSave(response);
        if (resetFieldsOnSave) {
          this.reset();
        }
      }
    } catch (error) {
      this.setState({ busy: false });
      onUpdateRequestComplete(error);
      if (typeof onError === 'function') onError(error);
      handleError(error);
    }
  };

  handleSubdocuments = async (baseEndpoint: string, subdocuments: Subdocument[]) => {
    const requests = [];

    for (let i = 0; i < subdocuments.length; i++) {
      const doc = subdocuments[i];

      if (Array.isArray(doc.create)) {
        for (let j = 0; j < doc.create.length; j++) {
          const createData = doc.create[j];

          requests.push(
            (async () => {
              return await apiRequest(`${baseEndpoint}/${doc.endpoint}`, {
                method: 'POST',
                data: {
                  [doc.name]: createData.value,
                },
              });
            })()
          );
        }
      }

      if (Array.isArray(doc.delete)) {
        for (let j = 0; j < doc.delete.length; j++) {
          const deleteID = doc.delete[j];

          requests.push(
            (async () => {
              return await apiRequest(`${baseEndpoint}/${doc.endpoint}/${deleteID}`, {
                method: 'DELETE',
              });
            })()
          );
        }
      }
    }

    return Promise.all(requests);
  };

  destroy = async () => {
    const { id, endpoint, onSuccessfulDelete, onDeleteRequestComplete, dontPromtDelete } = this.props;

    const { busy } = this.state;

    if (!id || busy) {
      return;
    }

    if (!dontPromtDelete && !confirm(i18n.t('paragraphs.areYouSureToDelete'))) {
      return;
    }

    try {
      this.setState({
        busy: true,
      });

      const response: any = await apiRequest(`${endpoint}/${id}`, {
        method: 'DELETE',
        notificationMessage: i18n.t('actions.deleting'),
      });

      this.setState(
        {
          busy: false,
        },
        () => {
          if (typeof onSuccessfulDelete === 'function') {
            onSuccessfulDelete(response);
          }
        }
      );

      onDeleteRequestComplete();
    } catch (error) {
      onDeleteRequestComplete(error);
      handleError(error);
    }
  };

  getField = (name: string): Field => {
    const { internalFields } = this.state;

    return (
      internalFields.find((f) => f.name === name) || {
        name: '',
        value: '',
        label: '',
      }
    );
  };

  setValue = async (name: string, value: Value, label: Label, meta?: any) => {
    const { fields } = this.props;
    const { internalFields } = this.state;

    const defaultField = (fields || []).find((f) => f.name === name);

    await this.setState({
      startedEditing: true,
      internalFields: internalFields.map((f) => {
        const field = { ...f };

        if (field.name === name) {
          field.value = value;
          field.label = label;
          field.meta = meta;

          if (defaultField) {
            if (value !== defaultField.value) {
              field.changed = true;
            } else {
              field.changed = false;
            }
          }
        }

        return field;
      }),
    });
  };

  getSubdocument = (name: string): Subdocument => {
    const { internalSubdocuments } = this.state;

    return (
      internalSubdocuments.find((s) => s.name === name) || {
        endpoint: '',
        name: '',
        previews: [],
        delete: [],
        create: [],
      }
    );
  };

  addToSubdocument = (name: string, data: SubdocumentData) => {
    const { subdocuments } = this.props;
    const { internalSubdocuments } = this.state;

    this.setState({
      startedEditing: true,
      internalSubdocuments: internalSubdocuments.map((s) => {
        const doc = { ...s };

        if (doc.name === name) {
          let commit = true;

          if (subdocuments) {
            const defaultDoc = subdocuments.find((d) => d.name === name);

            if (defaultDoc && defaultDoc.previews.some((p) => p._id === data._id)) {
              commit = false;
            }
          }

          if (commit) {
            doc.changed = true;
            // @ts-ignore
            doc.create.push(data);
          } else {
            doc.changed = false;
          }

          doc.previews.push(data);
          doc.delete = doc.delete.filter((d) => d !== data._id);
        }

        return doc;
      }),
    });
  };

  deleteFromSubdocument = (name: string, id: string) => {
    const { subdocuments } = this.props;
    const { internalSubdocuments } = this.state;

    this.setState({
      startedEditing: true,
      internalSubdocuments: internalSubdocuments.map((s) => {
        const doc = { ...s };

        if (doc.name === name) {
          let commit = true;

          if (subdocuments) {
            const defaultDoc = subdocuments.find((d) => d.name === name);

            if (defaultDoc && !defaultDoc.previews.some((p) => p._id === id)) {
              commit = false;
            }
          }

          if (commit) {
            // @ts-ignore
            doc.delete.push(id);
            doc.changed = true;
          } else {
            doc.changed = false;
          }

          doc.previews = doc.previews.filter((d) => d._id !== id);
          // @ts-ignore
          doc.create = doc.create.filter((d) => d._id !== id);
        }

        return doc;
      }),
    });
  };

  reset = (): boolean => {
    const { fields, subdocuments, dontPromptReset } = this.props;

    if (this.hasMadeChanges() && !dontPromptReset && !confirm(i18n.t('paragraphs.areYouSureToCancel'))) {
      return false;
    }

    this.setState(syncFields(fields, subdocuments));
    return true;
  };

  hasMadeChanges = () => {
    const { startedEditing, internalFields, internalSubdocuments } = this.state;

    if (!startedEditing) {
      return false;
    } else if (internalFields.some((f) => f.changed)) {
      return true;
    } else if (internalSubdocuments.some((f) => f.changed)) {
      return true;
    }

    return false;
  };

  render() {
    const { children } = this.props;
    const { busy } = this.state;
    const {
      save,
      destroy,
      getField,
      setValue,
      getSubdocument,
      addToSubdocument,
      deleteFromSubdocument,
      reset,
      hasMadeChanges,
    } = this;

    return children({
      busy,
      save,
      destroy,
      getField,
      setValue,
      getSubdocument,
      addToSubdocument,
      deleteFromSubdocument,
      reset,
      changesHaveBeenMade: hasMadeChanges(),
    });
  }
}

function syncFields(fields?: Field[], subdocuments?: Subdocument[]) {
  return {
    internalFields: (fields || []).map((f) => ({
      ...f,
      changed: false,
    })),
    internalSubdocuments: (subdocuments || []).map((s) => ({
      ...s,
      previews: [...s.previews],
      create: [],
      delete: [],
      changed: false,
    })),
  };
}

type UpdateDeleteWithNotificationsProps = BaseProps & {
  updateSuccessMessage?: string;
  deleteSuccessMessage?: string;
};

/**
 * @deprecated Old crud
 */
function UpdateDeleteWithNotifications(props: UpdateDeleteWithNotificationsProps) {
  return (
    <UpdateDelete
      {...props}
      onUpdateRequestComplete={notificationRequestProtocol(props.updateSuccessMessage)}
      onDeleteRequestComplete={notificationRequestProtocol(props.deleteSuccessMessage)}
    />
  );
}

export default UpdateDeleteWithNotifications;
