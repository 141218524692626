import i18n from '../../libraries/i18n';

interface SizeParamsTranslations {
  [key: string]: string;
}

export const sizeParamTranslations = (): SizeParamsTranslations => ({
  kvmGulv: i18n.t('paragraphs.squareMetersFloor'),
  kvmVegg: i18n.t('paragraphs.squareMetersWall'),
  kvmTak: i18n.t('paragraphs.squareMeterCeiling'),
  lmList: i18n.t('paragraphs.lengthMetersMoldings'),
  dimmers: i18n.t('singles.dimmers'),
  switches: i18n.t('singles.switches'),
  downlights: i18n.t('singles.downlights'),
  outlets: i18n.t('singles.outlets'),
  doors: i18n.t('singles.doors'),
});

export const sizeParamPredefs = Object.keys(sizeParamTranslations());
export const sizeParamOptions = () => {
  const translations = sizeParamTranslations();
  const opts = sizeParamPredefs.map((value) => ({ value, label: translations[value] }));
  opts.push({
    value: '__custom',
    label: i18n.t('singles.custom'),
  });
  return opts;
};

export function sizeParamLabel(key: string) {
  if (key.startsWith('kvm') || key.startsWith('lm')) return key;
  const translations = sizeParamTranslations();
  if (translations[key]) return translations[key];
  return key;
}
